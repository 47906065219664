define('coloradoTitleModel',["knockout", "modelMetaData"],
    function (ko, modelMetaData) {

        function subjectProperty() {
            var self = this;
            self.viewName = "orderModule/coloradoTitleOrder/_propertyInformation.html"
            self.title = "Property Information"
            self.ModelId = "subjectProperty"
            self.index = ko.observable(-1)

            self.address = ko.observable("")
            .extend({ required: { message: 'Property Address is required.' } })
            .extend({ maxLength: { params: 50, message: 'Property Address must be 50 characters or less.' } })

            self.city = ko.observable(undefined)
            .extend({ required: { message: 'Property City is required.' } })

            self.state = ko.observable(undefined)
            .extend({ required: { message: 'Property State is required.' } })

            self.county = ko.observable(undefined)
            .extend({ required: { message: 'Property County is required.' } })

            self.zip = ko.observable("")
            .extend({ required: { message: 'Property Zip is required.' } })
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
            .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
            .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.typeOfProperty = ko.observable("Commercial");
            self.typeOfPropertyList = ko.observableArray([
                                        { text: "Residential", value: "Residential" },
                                        { text: "Commercial", value: "Commercial" }
            ]);

            //---------------------------------------
            self.buyerName = ko.observable("")
            self.buyeraddress = ko.observable("")
            .extend({ maxLength: { params: 50, message: 'Buyer Address must be 50 characters or less.' } })
            self.buyerCity = ko.observable(undefined)
            self.buyerState = ko.observable(undefined)
            self.buyerZip = ko.observable("")
            //---------------------------------------
            self.sellerName = ko.observable("")
            self.sellerAddress = ko.observable("")
            .extend({ maxLength: { params: 50, message: 'Seller Address must be 50 characters or less.' } })
            self.sellerCity = ko.observable(undefined)
            self.sellerState = ko.observable(undefined)
            self.sellerZip = ko.observable("")
            //---------------------------------------
            self.chicagoToClose = ko.observable(true)
            self.selectedEscrowLocation = ko.observable("");


            self.update = function (data) {
                if (data) {
                    self.typeOfProperty(data.typeOfProperty())

                    self.address(data.Address())
                    self.zip(data.Zip())
                    self.city(data.City())
                    self.state(data.State())
                    self.county(data.county())
                    //---------------------------------------
                    self.buyerName(data.buyerName)
                    self.buyeraddress(data.buyeraddress)
                    self.buyerZip(data.buyerZip)
                    self.buyerCity(data.buyerCity)
                    self.buyerState(data.buyerState)
                    //---------------------------------------
                    self.sellerName(data.sellerName)
                    self.sellerAddress(data.sellerAddress)
                    self.sellerZip(data.sellerZip)
                    self.sellerCity(data.sellerCity)
                    self.sellerState(data.sellerState)
                    //---------------------------------------
                    self.chicagoToClose(data.chicagoToClose(true))

                }

            }

            self.reset = function () {
              
                self.typeOfProperty('')

                self.address("")
                self.city(undefined)
                self.state(undefined)
                self.county(undefined)
                self.zip("")
                //---------------------------------------
                self.buyerName("")
                self.buyeraddress("")
                self.buyerCity(undefined)
                self.buyerState(undefined)
                self.buyerZip("")
                //---------------------------------------
                self.sellerName("")
                self.sellerAddress("")
                self.sellerCity(undefined)
                self.sellerState(undefined)
                self.sellerZip("")
                //---------------------------------------
                self.chicagoToClose(true)

                self.selectedEscrowLocation("")


                ko.validation.group(self).showAllMessages(false);
            }

            self.resetPropertyAddress = function () {
                self.address("");
                self.city(undefined);
                self.state(undefined);
                self.county(undefined);
                self.zip("");
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);

                self.modelData.properties.push(new modelMetaData.propertyData().create("Type of Property", self.typeOfProperty()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Address", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Zip", self.zip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property State", self.state()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property County", self.county()));

                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer's Name", self.buyerName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer's Mailing Address", self.buyeraddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer's Zip", self.buyerZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer's City", self.buyerCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer's State", self.buyerState()));

                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller's Name", self.sellerName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller's Mailing Address", self.sellerAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller's Zip", self.sellerZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller's City", self.sellerCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller's State", self.sellerState()));

                self.modelData.properties.push(new modelMetaData.propertyData().create("Chicago To Close", modelMetaData.reportBooleanValue(self.chicagoToClose())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Escrow Locations", self.selectedEscrowLocation()));

            }
        }


        function endorsementInformation() {
            var self = this;
            self.viewName = "orderModule/coloradoTitleOrder/_endorsement.html"
            self.title = "Endorsement"
            self.ModelId = "endorsement"
            self.index = ko.observable(-1)

            self.endorsementNeeded = ko.observable("")
            self.selectedEndorsement = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.endorsementNeeded(data.endorsementNeeded)
                }
            }

            self.reset = function () {
                self.endorsementNeeded("")
                self.selectedEndorsement("")
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Endorsement", self.selectedEndorsement()));

            }
        }


        function typeofPolicyInformation() {
            var self = this;
            self.viewName = "orderModule/coloradoTitleOrder/_typeofPolicy.html"
            self.title = "Type of Policy"
            self.ModelId = "typeofPolicy"
            self.index = ko.observable(-1)

            self.policyTypeList = ko.observable("")
            self.loanAmount = ko.observable("").extend({ number: true }).extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Loan Amount. (format: ####.##)'
                }
            });
            self.saleAmount = ko.observable("").extend({ number: true }).extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Sale Amount. (format: ####.##)'
                }
            });



            self.update = function (data) {
                if (data) {
                    self.policyTypeList(data.policyTypeList)
                    self.loanAmount(data.loanAmount)
                    self.saleAmount(data.saleAmount)
                }

            }
            self.reset = function () {

                self.policyTypeList("")
                self.loanAmount("")
                self.saleAmount("")
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Policy Type", "A.L.T.A. Owner's"));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Loan Amount", self.loanAmount()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Sale Amount", self.saleAmount()));

            }
        }


        function referralInformation()
        {
            var self = this;
            self.viewName = "orderModule/coloradoTitleOrder/_referralInformation.html"
            self.title = "Referral Information"
            self.ModelId = "referralInformation"
            self.index = ko.observable(-1)

            self.listingOffice = ko.observable("")
            self.agentName1 = ko.observable("")
            self.sellingOffice = ko.observable("")
            self.agentName2 = ko.observable("")

            self.typeOfReferral = ko.observable("No Broker");
            self.typeOfReferralList = ko.observableArray([
                                        { text: "No Broker", value: "No Broker" },
                                        { text: "Escrow", value: "Escrow" },
                                        { text: "Lender", value: "Lender" }
            ]);

            self.update = function (data) {
                if (data) {
                    self.listingOffice(data.listingOffice)
                    self.agentName1(data.agentName1)
                    self.sellingOffice(data.sellingOffice)
                    self.agentName2(data.agentName2)
                    self.typeOfReferral(data.typeOfReferral)
                }
            }

            self.reset = function () {
              
                self.listingOffice("")
                self.agentName1("")
                self.sellingOffice("")
                self.agentName2("")
                self.typeOfReferral("");
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Office", self.listingOffice()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Agent's Name", self.agentName1()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Office", self.sellingOffice()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Agent's Name", self.agentName2()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Referral Type", self.typeOfReferral()));

            }

        }

        var vm = {
            subjectProperty: subjectProperty,
            referralInformation: referralInformation,
            typeofPolicyInformation: typeofPolicyInformation,
            endorsementInformation: endorsementInformation
        }

        return vm;
    });
