define('managerInfoModel',["knockout", 'utlities'],
    function (ko, utils) {

        console.log('Manager Profile Model called...');


        function ManagerProfileModel() {

            var self = this;
            self.title = ko.observable("Manager Profile");

            self.pic = ko.observable("");
            self.type = ko.observable("");
            self.dept = ko.observable("");
            self.office = ko.observable("");

            self.socialMedia = ko.observableArray([]);
            self.links = ko.observableArray([]);

            self.fName = ko.observable("");
            self.lName = ko.observable("");
            self.email = ko.observable("");
            self.phone = ko.observable("");
            self.extn = ko.observable("");
            self.fax = ko.observable("");
            self.cell = ko.observable("");
            self.web = ko.observable("");
            self.service = ko.observable("");
            self.team = ko.observable("");


           //self.Reset = function () {
           //    self.fName("")
           //    self.lName("")
           //    self.email("")
           //    self.phone("")
           //    self.extn("")
           //    self.fax("")
           //}


            self.Update = function (data) {
                if (data && data.Profile && data.Profile.UserInformation) {
                    self.pic(data.ProfileImageUrl);
                    self.type(data.Profile.UserInformation.UserType.Name);
                    self.dept(data.Department);
                    self.office(data.Office);

                    if (data.Profile.SocialMedias) {
                        $.each(data.Profile.SocialMedias, function (i, value) {
                            value.ImageUrl = value.ImageUrl.substring(2, value.ImageUrl.length);
                        });
                    }
                    self.socialMedia(data.Profile.SocialMedias);

                    self.links(data.Links);
                    self.fName(data.Profile.UserInformation.FirstName);
                    self.lName(data.Profile.UserInformation.LastName);
                    self.email(data.Profile.UserInformation.EmailAddress);
                    self.phone(data.Profile.UserInformation.PhoneWork);
                    self.extn(data.Profile.UserInformation.PhoneWorkExt);
                    self.phone(utils.getUSPhoneFormatWithExtn(self.phone(), self.extn(), 'x'));
                    self.fax(utils.getUSPhoneFormat(data.Profile.UserInformation.PhoneFax));
                    self.cell(utils.getUSPhoneFormat(data.Profile.UserInformation.PhoneCell));
                    self.service(utils.getUSPhoneFormat(data.Profile.UserInformation.PhoneService));
                    self.web(data.Profile.UserInformation.WebsiteUrl);
                    self.team(data.Profile.UserInformation.TeamAccount);
                    if (self.fax() == "")
                        self.fax("Not Available");
                }
            };
           
        }

        function TeamProfileModel() {

            var self = this;
            self.title = ko.observable("Manager Team Profile");

            self.pic = ko.observable("");
            self.fName = ko.observable("");
            self.lName = ko.observable("");
            self.phone = ko.observable("");
            self.extn = ko.observable("");
            self.fax = ko.observable("");
            self.email = ko.observable("");
            self.pic = ko.observable("");
            self.dept = ko.observable("");


            self.Update = function (data) {
                if (data) {
                    self.pic(data.ProfileImageUrl);

                    self.fName(data.TeamProfile.FirstName);
                    self.lName(data.TeamProfile.LastName);
                    self.email(data.TeamProfile.Email);
                    self.phone(data.TeamProfile.PhoneWork);
                    self.extn(data.TeamProfile.PhoneWorkExt);
                    self.phone(utils.getUSPhoneFormatWithExtn(self.phone(), self.extn(), 'x'));
                    self.fax(utils.getUSPhoneFormatWithExtn(data.TeamProfile.PhoneFax, data.TeamProfile.PhoneFaxExt, 'x'));
                    self.pic(data.TeamProfile.PhotoUrl);
                    self.dept(data.TeamProfile.Department);

                    if (self.fax() === "")
                        self.fax("Not Available");

                    if (self.phone() === "")
                        self.phone("Not Available");
                }
            };

        }

        var vm = {

            ManagerProfileModel: ManagerProfileModel,
            TeamProfileModel: TeamProfileModel

        };



        console.log('Manager Profile Model returned...');

        return vm;
    });
