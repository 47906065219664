define('viewmodels/onlineDocuments/onlineDocuments',['durandal/app', 'knockout', 'services/logger', 'documentSearchModel', 'Q', 'plugins/http', 'config', 'metisMenu', 'jquery.slimscroll', 'global/session', 'plugins/router', 'alertNotification', 'commonDialogModel', 'koPaging', 'utlities'],
    function (app, ko, logger, model, Q, https, config, metisMenu, slimscroll, session, router, alert, commonDialog, koPaging, utlities) {

        var searchResult = ko.observable('');
        var apnModel = new model.ApnSearch();
        var addressModel = new model.AddressSerach();
        var documentNumberModel = new model.DocumentNumberSearch();

        var isSearchable = ko.observable(true);
        var stateList = ko.observableArray();

        var apnCountyList = ko.observableArray();
        var apnfips = ko.observableArray();
        var apnfipsNo = ko.observable('');

        var docCountyList = ko.observableArray();
        var docfips = ko.observableArray();
        var docfipsNo = ko.observable('');

        var propertyListing = ko.observableArray();
        var hasZipFocused = ko.observable(false);
        var documentDetailResponse = ko.observable(undefined);
        var reportsContainer = ko.observable('');
        var selectedProperty = new model.PropertyAddress();
        var selectedDocument = new model.DocumentInfo();
        var documentListing = ko.observableArray([]);
        var isShowNoDocumentsFound = ko.observable(false);
        var isShowNoResultsFound = ko.observable(false);
        var isShowDocumentsFound = ko.observable(false);
        var isShowAddressSearchResults = ko.observable(false);
        var isShowNoCreditsAvailable = ko.observable(false);
        var limitDocProp = ko.observable();
        var isOnlyDocNumber = ko.observable(false);
        var serviceTitle = ko.observable('Online Documents Search');
        var prevSrchDocs = ko.observableArray([]);
        var prevSrchDocsFiltered = ko.observableArray([]);
        var onlineDocDetail = ko.observable(null);

        var searchedByList = ko.observableArray([
            { Key: "Address/APN", Value: "" }
        ]);

        var searchedByFilter = ko.observable('');
        hasZipFocused.subscribe(function (value) {
            //isSearchable(!value)
        });

        session.observeRouterActiveInstruction.subscribe(function (value) {
            clearSearch();
        });


        session.creditsDocProp.subscribe(function (newValue) {
            //console.log("The person's new name is " + newValue);
            setActiveTab();
        });
        function setActiveTab() {

            if (session.creditsDocProp() > 0 && !($("#docSearchTabs > #docSearchMenu > a").hasClass("active"))) {
                $("#dvDocumentsSearch > #menu2").removeClass("show active");
            }

            if (session.creditsDocProp() <= 0) {
                $("#dvDocumentsSearch > #menu2").addClass("show active");
            }

            if ($("#docSearchTabs > #docSearchMenu > a").hasClass("active")) {
                $('#dvDocumentsSearch > #menu2').removeClass("show active").addClass("show active");
            }



        }

        var map, searchManager, poll;
        var suspendBusyCheck = false;
        var limitsbeforeDocOpen;
        var docClicked = false, pollStart = false;

        var vm = {
            suspendBusyCheck: suspendBusyCheck,
            canActivate: canActivate,
            activate: activate,
            deactivate: deactivate,
            searchResult: searchResult,
            //compositionComplete: initMapModule,
            //bindingComplete: SetupDocumentGrid,
            addressModel: addressModel,
            apnModel: apnModel,
            documentNumberModel: documentNumberModel,
            clearSearch: clearSearch,
            clearSearchDocumentNumber: clearSearchDocumentNumber,
            searchDocumentByDocumentNumber: searchDocumentByDocumentNumber,
            apnfips: apnfips,
            apnfipsNo: apnfipsNo,
            docfips: docfips,
            docfipsNo: docfipsNo,
            GetStatesList: GetStatesList,
            stateList: stateList,
            apnCountyList: apnCountyList,
            docCountyList: docCountyList,
            isSearchable: isSearchable,
            hasZipFocused: hasZipFocused,
            selectedProperty: selectedProperty,
            documentListing: documentListing,
            resetSearchResults: resetSearchResults,
            selectedDocument: selectedDocument,
            isShowNoDocumentsFound: isShowNoDocumentsFound,
            isShowNoResultsFound: isShowNoResultsFound,
            isShowDocumentsFound: isShowDocumentsFound,
            isShowAddressSearchResults: isShowAddressSearchResults,
            isShowNoCreditsAvailable: isShowNoCreditsAvailable,
            session: session,
            openWindow: openWindow,
            isOnlyDocNumber: isOnlyDocNumber,
            getPreviousSearchDocuments: getPreviousSearchDocuments,
            serviceTitle: serviceTitle,
            searchedByList: searchedByList,
            searchedByFilter: searchedByFilter,
            prevSrchDocs: prevSrchDocs,
            prevSrchDocsFiltered: prevSrchDocsFiltered,
            onlineDocDetail: onlineDocDetail,
            getDocOrBookAndPageNumber: getDocOrBookAndPageNumber,
            viewDoc: viewDoc,
            downloadDoc: downloadDoc
        };

        vm.searchedByFilter.subscribe(function (newValue) {
            if (newValue != '') {
                var filteredSearches = ko.utils.arrayFilter(prevSrchDocs(), function (item) {
                    if (item.SearchedBy == newValue) {
                        return item;
                    }
                });
                prevSrchDocsFiltered(filteredSearches);
            }
            else {
                prevSrchDocsFiltered(prevSrchDocs());
            }
            prevSrchDocsFiltered.pageNumber(1);
        });

        vm.prevSrchDocs.subscribe(function (newValue) {
            //            console.log(newValue);
            prevSrchDocsFiltered([]);
            prevSrchDocsFiltered(newValue);
            //console.log(prevSrchDocsFiltered());

        });

        vm.DocumentNumberErrors = ko.validation.group([vm.documentNumberModel]);
        vm.documentNumberModel.state.subscribe(function (state) {
            if (state != undefined && state != '') {
                getDocModelCounties(state);
            }
        });

        return vm;


        function resetFlagValues() {
            isShowNoResultsFound(false);
            isShowNoDocumentsFound(false);
            isShowDocumentsFound(false);
            isShowAddressSearchResults(false);
            isShowNoCreditsAvailable(false);

        }

        

        function GetStatesList() {
            var url = config.stateUrl;
            stateList([]);
            Q.when(https.get(url, {
                format: 'json'
            })
                .then(function (data) {
                    stateList(data);
                })
                .fail(Failed));
        }

        function Failed(error) {
            alert.error(error.statusText);
        }


        function getDocModelCounties(state) {
            var url = config.countyUrl;
            isSearchable(false);
            Q.when(https.get(url, {
                stateAbbr: state,
                format: 'json'
            })
                .then(function (data) {
                    docCountyList(data);
                    if (docfips().Fips != undefined && docfips().Fips != '') {
                        docModel.county(docfips().Fips);
                    }
                }).always(function () {
                    isSearchable(true);
                })
                .fail(Failed)
            );
        }

        function clearSearch() {
            apnModel.reset();
            searchResult(undefined);
            selectedProperty.reset();
            documentListing([]);
            selectedDocument.reset();
            resetFlagValues();
            onlineDocDetail(null);
        }

        function clearSearchDocumentNumber() {
            documentNumberModel.reset();
            searchResult(undefined);
            selectedProperty.reset();
            documentListing([]);
            selectedDocument.reset();
            resetFlagValues();
            onlineDocDetail(null);
        }

        function getUsageDocForProp() {
            var url = config.getUsageUrl;
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                serviceId: '94',
                format: 'json'
            });
        }

        function getCombinedServiceCreditLimts() {
            var url = config.getCombinedServiceCreditLimtsUrl;
            https.get(url, {
                "ParentId": session.bkSlsParentId(),
                "Token": session.bkSlsToken()
            }).then(function (result) {
                setDocumentCredits(result);
                window.clearInterval(poll);
                pollStart = false;
                $("#mdLoading").modal('hide');
            });
        }

        function getUsageOnline() {
            var url = config.getUsageUrl;
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                serviceId: session.activeRoutingContext().config.service,
                format: 'json'
            });
        }

        function getUsageForOnlineDoc() {
            var url = config.getUsageUrl;

            https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                serviceId: session.activeRoutingContext().config.service,
                format: 'json'
            }).then(function (result) {
                setDocumentCredits(result);
                $("#mdLoading").modal('hide');
            });
        }

        function setDocumentCredits(creditData) {
            creditData.forEach(function (data) {
                switch (data.ServiceId) {
                    case 89:
                        session.limitLpsDoc(data.UsageLimits);
                        session.creditsLpsDoc(data.Credits);
                        break;
                    case 92:
                        session.limitOnlineDoc(data.UsageLimits);
                        session.creditsOnlineDoc(data.Credits);
                        break;
                    case 94:
                        session.limitDocProp(data.UsageLimits);
                        session.creditsDocProp(data.Credits);
                        break;

                }
            });

        }


        function searchDocumentByDocumentNumber() {
            if (vm.DocumentNumberErrors().length > 0) {
                vm.DocumentNumberErrors.showAllMessages();
                return;
            }

            onlineDocDetail(null);
            isSearchable(false);
            console.log('searchDocumentByDocumentNumber called');
            console.log(documentNumberModel);
            documentNumberDocSearch(searchResult, documentNumberModel);
        }

        function documentNumberDocSearch(parentResultHolder, documentNumberModel) {
            var url = config.getDocumentDetailUrl;
            var DocumentInfo = {
                "Fips": documentNumberModel.county(),
                "DocumentNumber": documentNumberModel.documentnumber() || null,
                "RecordingDate": getRecordingDate(documentNumberModel.recordingdate()),
                "BookNumber": documentNumberModel.book() || null,
                "PageNumber": documentNumberModel.page() || null
            };
            //console.log(DocumentInfo.RecordingDate);
            return https.post(url, DocumentInfo).then(function (data) {
                //isShowNoDocumentsFound(false);
                if (data.DocumentNumber != null || data.BookNumber != null) {
                    //console.log(data)
                    //parentResultHolder(data);
                    onlineDocDetail(data);
                    console.log(onlineDocDetail());
                }
                else
                    alert.warning("We were unable to find the document matching your search parameters.");
            }).always(function () {
                isSearchable(true);
                getCombinedServiceCreditLimts();
            })
                .fail(function () {
                    alert.error('Internal Server Error.');
                });
        }

        function getRecordingDate(dateStr) {
            const [month, day, year] = dateStr.split("/");
            return `${year}-${month}-${day}`;
        }

        function getDocOrBookAndPageNumber(data) {
            if (data.BookNumber != null && data.PageNumber != null) return `BK: ${data.BookNumber}, PG: ${data.PageNumber}`;
            return data.DocumentNumber;
        }

        function canActivate() {
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return false;
            }
            return true;
        }

        function activate() {
            searchedByFilter('');
            documentNumberModel.reset();
            var onlineDocs = session.productMenuList().filter(function (item) {
                return (item.CpUrl.indexOf('#documents') !== -1);
            });


            if (onlineDocs && onlineDocs.length > 0) {
                serviceTitle(onlineDocs[0].Name + ' Search');
            }
            
            isOnlyDocNumber(router.activeInstruction().config.route == 'documentSearchByNumber');
            resetFlagValues();
            console.log(isShowNoDocumentsFound());
            GetStatesList();
            window.addEventListener('focus', refreshPoll);
            // window.addEventListener("load", openWindow);
            window.suspendBusy = function () {
                if (vm.suspendBusyCheck === true) {
                    vm.suspendBusyCheck = false;
                    return true;
                }
                else {
                    return false;
                }

            }
            //SetupDocumentGrid();

            prevSrchDocsFiltered.extend({
                paged: {
                    pageNumber: 1,
                    pageSize: 10,
                    pageGenerator: 'sliding'
                }
            });
        }

        // does the actual opening
        function openWindow(options, data, prePrcsdUrl) {

            var ctrl = options.currentTarget;
            event = event || window.event;

            var newTitle = data.DocumentNumber;

            // find the url and title to set
            var href = (prePrcsdUrl == true ? data.Url : data.Url + '&' + newTitle);
            // or if you work the title out some other way...
            // var newTitle = "Some constant string";

            // open the window
            var newWin = window.open(href, "_blank");
            if (newWin) {
                // add a load listener to the window so that the title gets changed on page load
                try {
                    newWin.addEventListener("load", setTimeout(function () {
                        newWin.document.title = newTitle;
                    }, 500));
                }
                catch (loadErr) { }
            } else {
                var warningMessageIfPopupBlocked = "Please disable your popup blocker and try again.";
                alert.warning(warningMessageIfPopupBlocked);
            }
            // stop the default `a` link or you will get 2 new windows!
            options.returnValue = false;
            return false;
        }

        function refreshPoll() {
            if (docClicked === true) {
                vm.suspendBusyCheck = true;
                limitsbeforeDocOpen = session.creditsOnlineDoc();
                startPoll();
            }
            docClicked = false;
        }

        function deactivate() {
            clearSearch();
        }

        function viewDoc(refData) {
            var docUid = refData.Uid || onlineDocDetail().Uid;
            
            var url = config.getDocumentByIdUrl;

            var docRequest = {
                DocumentId: docUid
            };

            //console.log(docRequest);

            https.get(url, docRequest)
                .done(function (data) {
                    utlities.viewFileFromStream(data.FileName, data.FileBytes);
                }).fail(function () {
                    alert.warning('Cannot download this document.');
                });
        }

        function downloadDoc(refData) {
            var docUid = refData.Uid || onlineDocDetail().Uid;
            var url = config.getDocumentByIdUrl;

            var docRequest = {
                DocumentId: docUid
            };

            //console.log(docRequest);

            https.get(url, docRequest)
                .done(function (data) {
                    utlities.downloadFileFromStream(data.FileName, data.FileBytes);
                }).fail(function () {
                    alert.warning('Cannot download this document.');
                });
        }

        function getPreviousSearchDocuments() {
            onlineDocDetail(null);
            prevSrchDocs([]);
            resetSearchResults();
            var url = config.getOnlineDocsPreviousSearchUrl;

            https.get(url).then(function (result) {
                //console.log(result);
                prevSrchDocs(result.Data);
                prevSrchDocsFiltered.pageNumber(1);
            });
        }


        function startPoll() {
            if (!pollStart) {
                poll = window.setInterval(getCombinedServiceCreditLimts, 6000);
                pollStart = true;
            }
        }

        function resetSearchResults(selectedItem, event) {
            addressModel.reset();
            apnModel.reset();
            documentNumberModel.reset();
            searchResult(undefined);
            selectedProperty.reset();
            documentListing([]);
            selectedDocument.reset();
            resetFlagValues();
            prevSrchDocs([]);
            prevSrchDocsFiltered([]);
            onlineDocDetail(null);
        }


    });
