define('viewmodels/userProfileModule/busCardLinks',['durandal/app', 'plugins/router', 'knockout', 'models/userProfile/busCardLinksModel', 'userInfoModel', 'config', 'global/session', 'plugins/http', 'alertNotification', 'utlities'],
    function (app, router, ko, model, usermodel, config, session, https, alert, utlities) {

        console.log('Bus Card Links VM - called');
        var userInfo = new usermodel.userInfo();
        var busCardLinkModel = new model.busCardLink();
        var busCardLinksList = ko.observableArray([]);
        var serverState = {};
        var busCardLinksList = ko.observableArray([]);
        var isEdit = ko.observable(false);
        var isDataLoad = ko.observable(false);
        var _deletingBusCardObject = new model.busCardLink();

        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            userInfo: userInfo,
            busCardLinkModel: busCardLinkModel,
            busCardLinksList: busCardLinksList,
            saveObject: saveObject,
            deleteObject: deleteObject,
            deletObjectConfirmed: deletObjectConfirmed,
            updateObject: updateObject,
            addNewObject: addNewObject,
            editObject: editObject,
            closeDialog: closeDialog,
            isEdit: isEdit,
            compositionComplete: compositionComplete,
        }
        vm.errors = ko.validation.group([vm]);
        return vm;

        function compositionComplete() {
            session.validateInput();
        }

        function activate() {
            console.log('Bus Card Links VM - activated');
            getUserProfileInformation();
            getBusCardLinks();

        }

        function canDeactivate() {
            busCardLinkModel.reset();
            return true;
        }

        function savetoDB(cardModel) {
            var url = config.saveUserBusCardLinkUrl;
            return https.post(url, {
                id: cardModel.id(),
                linkName: cardModel.linkName(),
                linkUrl: cardModel.linkUrl(),
                order: cardModel.order()
            }).then(function (data) {
                if (data.HasErrors == true) {
                    if (data.Messages["0"].Message) {
                        alert.error(data.Messages["0"].Message);
                    }
                }
                else
                    alert.success("Your request was successfully processed.");
                getBusCardLinks();
                busCardLinkModel.reset();
            });
        }

        function saveObject() {
            if (vm.objectErrors().length > 0) {
                vm.objectErrors.showAllMessages();
                setTimeout(function () {
                    $('.modal-body', '#mdCreateBusCardLink').scrollTop(500);
                }, 300);
                return;
            }

            _objectUsed = new model.busCardLink;
            busCardLinkModel.order(busCardLinksList.length);
            _objectUsed.update(busCardLinkModel);
            //busCardLinksList.push(_objectUsed);
            //_objectUsed.id(0);
            savetoDB(_objectUsed);

            $("#mdCreateBusCardLink").modal('hide');
        }

        function updateObject() {

            _objectUsed = new model.busCardLink();
            _objectUsed.update(busCardLinkModel);

            //busCardLinkModel.splice(busCardLinkModel.id(), 1, _objectUsed);



            if (!busCardLinkModel.isValid()) {
                return;
            }
            //if (vm.objectErrors().length > 0) {
            //    vm.objectErrors.showAllMessages();
            //    return;
            //}

            savetoDB(_objectUsed);

            busCardLinkModel.reset();

            getBusCardLinks();


            $("#mdCreateBusCardLink").modal('hide');
        }

        function addNewObject() {
            busCardLinkModel.reset();
            isEdit(false);
            $("#mdCreateBusCardLink").modal('show');
            vm.objectErrors = ko.validation.group([busCardLinkModel]);
            vm.objectErrors.showAllMessages(false);
        }

        function editObject(item, event) {

            vm.busCardLinkModel.reset();
            vm.busCardLinkModel.id(item.id());
            vm.busCardLinkModel.userId(item.userId());
            vm.busCardLinkModel.operationId(item.operationId());
            vm.busCardLinkModel.order(item.order());
            vm.busCardLinkModel.linkName(item.linkName());
            vm.busCardLinkModel.linkUrl(item.linkUrl());
            isEdit(true);
            $("#mdCreateBusCardLink").modal('show');

        }

        function deleteObject(item, event) {
            $("#mdDeleteItem").modal('show');
            _deletingBusCardObject.reset();
            _deletingBusCardObject.id(item.id());
            _deletingBusCardObject.userId(item.userId());
            _deletingBusCardObject.operationId(item.operationId());
            _deletingBusCardObject.order(item.order());
            _deletingBusCardObject.linkName('');
            _deletingBusCardObject.linkUrl(item.linkUrl());

            //deletObjectConfirmed(item);

        }

        function deletObjectConfirmed() {

            savetoDB(_deletingBusCardObject);
            busCardLinkModel.reset();
            //busCardLinksModel.reset();
            getBusCardLinks();
            $("#mdDeleteItem").modal('hide');
        }

        function getBusCardLinks() {
            var url = config.getUserBusCardLinksUrl;
            busCardLinkModel.reset();

            return https.get(url)
                .done(function (result) {
                    linksData = result;
                    busCardLinksList([]);
                    if (linksData.UserBusCardLinks != null) {
                        for (var c = 0; c < linksData.UserBusCardLinks.length; c++) {
                            var bc = new model.busCardLink();
                            bc.id(linksData.UserBusCardLinks[c].Id);
                            bc.userId(linksData.UserBusCardLinks[c].UserId);
                            bc.operationId(linksData.UserBusCardLinks[c].OperationId);
                            bc.linkName(linksData.UserBusCardLinks[c].LinkName);
                            bc.linkUrl(linksData.UserBusCardLinks[c].LinkURL);
                            bc.order(linksData.UserBusCardLinks[c].Order);

                            busCardLinksList.push(bc);
                        }
                    }
                });

        }

        function closeDialog() {
            //_objectModel.reset();
            $("#mdCreateBusCardLink").modal('hide');
        }

        function getUserProfileInformation() {
            isDataLoad(true);
            var url = config.getUserProfileInfoUrl;
            return https.get(url, { UserId: session.userId, OperationId: session.defaultOpertionId() })
                .done(function (result) {
                    serverState = result;
                    userInfo.update(serverState.UserInformation);
                    userInfo.manager(serverState.UserManagerFullName);

                }).always(function () {
                    isDataLoad(false);
                    session.isAjaxPost(true);
                })
                .fail(function () {
                    //alert.error('Error fetching User Infomation', 'User Infomation');
                });
        }

        //function changeBusCardLinksOrder(Id, moveUp) {
        //    var url = config.changeBusCardLinksOrderUrl;

        //    return https.get(url, {
        //        Id: Id,
        //        moveUp: moveUp
        //    }).then(function (data) {
        //        getBusCardLinks();
        //        busCardLinkModel.reset();

        //        //session.getFavorites().done(function (result) {
        //        //    if (session.isLoggedIn() == true) {
        //        //        session.favoritesList(result.FavoritesList);
        //        //        showButton(result.ShowAddButton);
        //        //        populateModel();
        //        //        favoriteModel.reset();
        //        //    }
        //        //});
        //    });
        //}

        //function moveUp(item, event) {
        //    changeBusCardLinksOrder(item.id, true);

        //}

        //function moveDown(item, event) {
        //    changeBusCardLinksOrder(item.id, false);

        //}




        //#####








    });
