define('models/order/commonOrderFields',["knockout"],
    function (ko) {
        function commonOrderFields() {
            var self = this;
            self.listingAgent = ko.observable("")
            self.sellingAgent = ko.observable("")
            self.lender1 = ko.observable("")
            self.lender2 = ko.observable("")
            self.lender3 = ko.observable("")
            self.lender4 = ko.observable("")
            self.specialInstructions = ko.observable("")
        }
        var vm = {
            commonOrderFields: commonOrderFields
        }

        return vm;
        });
