define('paymentProcessModel',["knockout", 'utlities', 'alertNotification', 'global/session'],
    function (ko, utlities, alert, session) {
        function CreditCardInformation() {
            var self = this;

            self.viewName = "paymentProcess/_cardInfo.html"
            self.title = "Credit Card Information"
            self.ModelId = "CreditCardInformation"
            self.index = ko.observable(-1)

            self.editable = ko.observable(true);

            self.nameOnCard = ko.observable('')
                .extend({ required: { message: 'Name on Card is required.' } })
            .extend({ maxLength: { params: 100, message: 'Name on Card must be 100 characters or less' } });

            self.cardNumber = ko.observable('')
                .extend({ required: { message: 'Card Number is required.' } })
                .extend({ maxLength: { params: 16, message: 'Only 16 digit Card Number is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only integers are allowed.' })
                .extend({ creditCard: { params: { required: true, message: 'Invalid Card' } } });

            self.expirationMonth = ko.observable(undefined).extend({ required: { message: 'Expiration Month is required.' } });
            self.expirationYear = ko.observable(undefined).extend({ required: { message: 'Expiration Year is required.' } });

            self.monthList = ko.observableArray([
                                        { key: "January", value: "1" },
                                        { key: "February", value: "2" },
                                        { key: "March", value: "3" },
                                        { key: "April", value: "4" },
                                        { key: "May", value: "5" },
                                        { key: "June", value: "6" },
                                        { key: "July", value: "7" },
                                        { key: "August", value: "8" },
                                        { key: "September", value: "9" },
                                        { key: "October", value: "10" },
                                        { key: "November", value: "11" },
                                        { key: "December", value: "12" }
            ]);

            self.yearList = ko.computed(function () {
                var currentYear = new Date().getFullYear();
                var yearsToFill = currentYear + 15;
                var list = ko.observableArray();
                for (var i = currentYear; i < yearsToFill; i++) {
                    list.push({ key: i, value: i })
                }
                return list;
            }, self);

            //self.expirationDate = ko.computed(function () {
            //    if (self.expirationYear() === '' || self.expirationMonth() === '')
            //        return '';

            //    return new Date(self.expirationYear() + '-' + self.expirationMonth() + '-' + '01');
            //}, self);


            self.verificationCodeCVC = ko.observable('')
                .extend({ required: { message: 'Verification Code/CVC is required.' } })
                .extend({ maxLength: { params: 4, message: 'Verification Code/CVC must be 4 digits.' } })
                .extend({ pattern: '^[0-9]', message: 'Only integers are allowed.' })
                .extend({ creditCardCVC: { params: { required: true, creditCardNumber: self.cardNumber, message: 'Invalid CVC' } } });

            self.phoneNumber = ko.observable('')
                .extend({ required: { message: 'Phone Number is required.' } })
                .extend({ phoneUS: true });

            self.whatIsThisLink = ko.observable('');

            self.zip = ko.observable('')
                .extend({ required: { message: 'Zip is required.' } })
                .extend({ maxLength: { params: 5, message: 'Only 5 digit Zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit Zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit Zip code is allowed.' });

            self.zip.subscribe(function (NewZip) {
                if (NewZip != undefined && NewZip != '') {
                    return utlities.getZipInfo(NewZip).done(function (data) {
                        if (data != undefined && data != null && data.HasErrors == false) {
                            if (data.StateAbbr != null || data.City != null) {
                                self.city(data.City);
                                self.state(data.StateAbbr);
                            }
                            else {
                                //session.isAjaxPost(false);
                                alert.error("Invalid Zip.");
                                self.zip('');
                                self.city('');
                                self.state('');
                            }
                        }
                        else
                            session.zipCodeServiceDown();

                    });
                }
            });

            self.billingAddress = ko.observable('').extend({ required: { message: 'Billing Address is required.' } });
            self.city = ko.observable('').extend({ required: { message: 'City is required.' } });
            self.state = ko.observable(undefined).extend({ required: { message: 'State is required.' } });
            self.stateList = ko.observableArray();

            utlities.getStatesList().done(function (result) {
                self.stateList([]);
                self.stateList(result);
            });

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.nameOnCard(data.nameOnCard())
                    self.cardNumber(data.cardNumber())
                    //self.expirationDate(data.expirationDate())
                    self.phoneNumber(data.phoneNumber())
                    self.expirationMonth(data.expirationMonth())
                    self.expirationYear(data.expirationYear())
                    self.verificationCodeCVC(data.verificationCodeCVC())
                    self.whatIsThisLink(data.whatIsThisLink())
                    self.zip(data.zip())
                    self.billingAddress(data.billingAddress())
                    self.city(data.city())
                    self.state(data.state())
                }
            };

            self.reset = function () {
                self.nameOnCard('')
                self.cardNumber('')
                //self.expirationDate('')
                self.phoneNumber('')
                self.expirationMonth(undefined)
                self.expirationYear(undefined)
                self.verificationCodeCVC('')
                self.whatIsThisLink('')
                self.zip('')
                self.billingAddress('')
                self.city('')
                self.state(undefined)
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }
        }

        function OrderSummary() {
            var self = this;

            self.viewName = "paymentProcess/_orderItems.html"
            self.title = "Order Summary"
            self.ModelId = "OrderSummary"
            self.index = ko.observable(-1);

            self.orderList = ko.observableArray([
                                        { productName: "January", price: "1", address: "1 33 WATER LILY IRVINE, ORANGE, CA 92602" },
                                        { productName: "February", price: "2", address: "2 33 WATER LILY IRVINE, ORANGE, CA 92602" },
                                        { productName: "March", price: "3", address: "3 33 WATER LILY IRVINE, ORANGE, CA 92602" },
                                        { productName: "April", price: "4", address: "4 33 WATER LILY IRVINE, ORANGE, CA 92602" },
                                        { productName: "May", price: "5", address: "5 33 WATER LILY IRVINE, ORANGE, CA 92602" },
                                        { productName: "June", price: "6", address: "6 33 WATER LILY IRVINE, ORANGE, CA 92602" }
            ]);

            self.subTotal = ko.observable(0);
            self.tax = ko.observable(0);
            self.total = ko.observable(0);

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.subTotal(data.subTotal())
                    self.tax(data.tax())
                    self.total(data.total())
                }
            };

            self.reset = function () {
                self.subTotal(0)
                self.tax(0)
                self.total(0)
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }
        }

        function Wallet() {
            var self = this;

            self.viewName = "paymentProcess/_wallet.html"
            self.title = "Wallet"
            self.ModelId = "Wallet"
            self.index = ko.observable(-1);

            self.walletBalance = ko.observable(0);

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.walletBalance(data.walletBalance())
                }
            };

            self.reset = function () {
                self.walletBalance(0)
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }
        }

        var vm = {
            CreditCardInformation: CreditCardInformation,
            OrderSummary: OrderSummary,
            Wallet: Wallet
        }
        return vm;
    });
