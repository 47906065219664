define('models/order/principalSeller',["knockout"],
    function (ko) {

        function principalSeller() {
            var self = this;
            self.viewName = "orderModule/_principalSeller.html";
            self.title = "Principal Seller";


            self.sellerFirstName = ko.observable("")
            self.sellerLastName = ko.observable("")
            self.sellerWorkPhone = ko.observable("")
            self.workPhonExt = ko.observable("")
            self.sellerHomePhone = ko.observable("")
            self.homePhonExt = ko.observable("")
            self.coSellerFirstName = ko.observable("")
            self.coSellerLastName = ko.observable("")

            self.reset = function () {
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }
        }

        function principalSellers()
        {
            var self = this;
            self.viewName = "orderModule/_principalSellers.html";
            self.title = "Principal Sellers";
            self.index = ko.observable(-1);
            self.SellersList = [];

// test code
            var seller = new principalSeller();
            seller.sellerFirstName("Shawn");
            seller.sellerLastName("Michaels");
            seller.sellerWorkPhone("949-254-2025");           
            seller.sellerHomePhone("978-452-5885");
            
            seller.coSellerFirstName("CoFname");
            seller.coSellerLastName("CoLname");
            self.SellersList.push(seller);
            // test code
            
            self.reset = function () {
                self.SellersList = [];
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

        }

        var vm = {
            principalSeller: principalSeller,
            principalSellers:principalSellers
        }

        return vm;
    });
