/// <reference path="../../views/orderModule/viewOnlineOrders.html" />
define('models/userProfile/contactsModel',["knockout"],
    function (ko) {

        console.log('Contacts Model called...');


        function ContactsModel() {

            var self = this;
            self.title = ko.observable("Frequent Contacts");
            self.ContactsList = ko.observableArray([]);
            self.contactId = ko.observable("");
            self.fName = ko.observable("")
                .extend({ required: { message: 'First Name is required.' } });
            self.lName = ko.observable("")
                .extend({ required: { message: 'Last Name is required.' } });
            self.email = ko.observable("")
                .extend({ email: { message: 'Invalid Email Address.' } });
            self.address = ko.observable("")
                .extend({ required: { message: 'Address is required.' } });
            self.company = ko.observable("")
                .extend({ required: { message: 'Company is required.' } });
            self.phone = ko.observable("")
                .extend({ required: { message: 'Phone is required.' } })
                .extend({
                    phoneUS: {
                        params: true,
                        message: 'Please specify a valid phone number'
                    }
                });
            self.extn = ko.observable("")
                .extend({ digit: true });
            self.zip = ko.observable("")
                .extend({ required: { message: 'Zip is required.' } });
            self.city = ko.observable("")
                .extend({ required: { message: 'City is required.' } });
            self.state = ko.observable()
                .extend({ required: { params: true, message: 'State is required.' } });
            self.fax = ko.observable("")
                .extend({
                    phoneUS: {
                        params: true,
                        message: 'Please specify a valid fax number'
                    }
                });
            self.mortgageBroker = ko.observable(false);
            self.contactType = ko.observable(-1)
                .extend({
                    required: {
                        params: true, message: 'Contact Type is required.'
                    }
                });

            self.contactTypes = ko.observableArray([]),

                self.Reset = function () {
                    self.fName("");
                    self.lName("");
                    self.email("");
                    self.address("");
                    self.company("");
                    self.phone("");
                    self.extn("");
                    self.zip("");
                    self.city("");
                    self.state("");
                    self.fax("");
                    self.contactType(-1);
                    self.mortgageBroker(false);

                    ko.validation.group(self).showAllMessages(false);

                };
            self.Update = function (data) {
                if (data) {
                    self.fName(data.FirstName);
                    self.lName(data.LastName);
                    self.email(data.Email);
                    self.address(data.Address);
                    self.company(data.Company);
                    self.phone(data.Phone);
                    self.extn(data.PhoneExt)
                    self.zip(data.Zip);
                    self.city(data.City);
                    self.state(data.State);
                    self.fax(data.Fax);
                    self.contactType(data.FreqContactTypeId);
                    self.mortgageBroker(data.MortgageBroker);
                }
            };

            self.errors = ko.validation.group(self);
            self.isValid = ko.computed(function () {
                return self.errors().length === 0;
            });
        }

        var vm = {
            ContactsModel: ContactsModel
        };
        return vm;
    });
