define('viewmodels/userModule/inHereFinishNew',['plugins/router', 'durandal/app', 'services/security', 'knockout', 'jquery', 'plugins/http', 'global/session', 'config', 'alertNotification', 'migrationModel', 'jquerycapslock', 'services/logger'],
    function (router, app, security, ko, $, https, session, config, alerter, migrationModel, jquerycapslock, logger) {
        var isLoggedIn = ko.observable(false);


        var vm = {
            activate: activate,
            title: 'login',
            session: session,
            loaded: false,
            cancelUpgrade: cancelUpgrade,
            session: session,
            accountActivation: accountActivation
        };

        return vm;

        function activate() {


            ////var oktaActivationUrl = session.oktaActivationUrl().replace(':fromUri', '');
            //session.oneTimeCodeUid(oktaActivationUrl);

            //var brandServicesName = session.getBrandServicesName(session.brandId);


        }

        function accountActivation() {
            window.location.replace(session.oktaActivationUrl());
        }

        //function setup() {

        //    loginError('');
        //    session.isAjaxPost(true);
        //    session.clearAccessToken();
        //    session.clearACSFToken();
        //    session.clearRecentSearchesFromLocalStorage();

        //    window.location.href = session.oktaActivationUrl();
        //}

        function cancelUpgrade() {

            window.location = window.location.origin;

        }

        
    });
