define('models/order/residentialEscrow',["knockout", "modelMetaData"],
    function (ko, modelMetaData) {
        function EscrowInformation() {
            var self = this;

            self.viewName = "orderModule/residentialEscrow/_escrowInformation.html"
            self.title = "Escrow Information"
            self.ModelId = "EscrowInformation"
            self.index = ko.observable(-1)

            self.escrowOfficer = ko.observable("")
            self.escrowCloseDate = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.escrowOfficer(data.escrowOfficer)
                    self.escrowCloseDate(data.escrowCloseDate)
                }

            }

            self.reset = function () {
                self.escrowOfficer('')
                self.escrowCloseDate('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Name of Escrow Officer", self.escrowOfficer()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Date for Close of Escrow", self.escrowCloseDate()));
            }
        }

        function SellerLenderInformation() {
            var self = this;

            self.viewName = "orderModule/residentialEscrow/_sellerLenderInformation.html"
            self.title = "Seller/Lender Information "
            self.ModelId = "SellerLenderInformation"
            self.index = ko.observable(-1)

            self.sellerLender = ko.observable("")
            .extend({ required: { message: 'Seller/Lender is required.' } })
            self.sellerLenderAddress = ko.observable("")
            .extend({ required: { message: 'Seller/Lender Address is required.' } })
            .extend({ maxLength: { params: 200, message: 'Seller/Lender Address must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.sellerLenderCity = ko.observable(undefined)
            .extend({ required: { message: 'Seller/Lender City is required.' } })
            self.sellerLenderState = ko.observable(undefined)
            .extend({ required: { message: 'Seller/Lender State is required.' } })
            self.sellerLenderZip = ko.observable("")
            .extend({ required: { message: 'Seller/Lender Zip is required.' } })
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.sellerLenderPhone = ko.observable("")
            .extend({
                required: {
                    message: "Seller/Lender Phone is required",
                    onlyIf: function () {
                        return self.ext().length > 0;
                    }
                }
            })
            .extend({ phoneUS: true })

            self.ext = ko.observable("")
            .extend({ digit: true });

            self.sellerLenderFax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });
            self.sellerLenderEmailAddress = ko.observable("")
             .extend({ email: { message: 'Invalid Seller/Lender Email Address.' } })
                .extend({ maxLength: { params: 100, message: 'Invalid Seller/Lender Email Address.' } })
            self.update = function (data) {
                if (data) {
                    self.sellerLender(data.sellerLender)
                    self.sellerLenderAddress(data.sellerLenderAddress)
                    self.sellerLenderCity(data.sellerLenderCity)
                    self.sellerLenderState(data.sellerLenderState)
                    self.sellerLenderZip(data.sellerLenderZip)
                    self.sellerLenderPhone(data.sellerLenderPhone)
                    self.ext(data.ext)
                    self.sellerLenderFax(data.sellerLenderFax)
                    self.sellerLenderEmailAddress(data.sellerLenderEmailAddress)
                }


            }

            self.reset = function () {
                self.sellerLender("")
                self.sellerLenderAddress('')
                self.sellerLenderCity(undefined)
                self.sellerLenderState(undefined)
                self.sellerLenderZip('')
                self.sellerLenderPhone('')
                self.ext('')
                self.sellerLenderFax('')
                self.sellerLenderEmailAddress('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Lender", self.sellerLender()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Lender Address", self.sellerLenderAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Lender Zip", self.sellerLenderZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Lender City", self.sellerLenderCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Lender State", self.sellerLenderState()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Lender Phone", modelMetaData.appendPhoneWithExtn(self.sellerLenderPhone(), self.ext(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Lender Fax", self.sellerLenderFax()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Lender Email Address", self.sellerLenderEmailAddress()));
            }

        }

        function BuyerBorrowerInformation() {
            var self = this;

            self.viewName = "orderModule/residentialEscrow/_buyerBorrowerInformation.html"
            self.title = "Buyer/Borrower Information "
            self.ModelId = "BuyerBorrowerInformation"
            self.index = ko.observable(-1)

            self.buyerBorrower = ko.observable("")
             .extend({ required: { message: 'Buyer/Borrower is required.' } })
            self.buyerBorrowerAddress = ko.observable("")
             .extend({ required: { message: 'Buyer/Borrower Address is required.' } })
            .extend({ maxLength: { params: 200, message: 'Buyer/Borrower Address must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.buyerBorrowerCity = ko.observable(undefined)
             .extend({ required: { message: 'Buyer/Borrower City is required.' } })
            self.buyerBorrowerState = ko.observable(undefined)
             .extend({ required: { message: 'Buyer/Borrower State is required.' } })
            self.buyerBorrowerZip = ko.observable("")
            .extend({ required: { message: 'Buyer/Borrower Zip is required.' } })
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.buyerBorrowerPhone = ko.observable("")
            .extend({ phoneUS: true })
            .extend({
                required: {
                    message: "Buyer/Borrower Phone is required",
                    onlyIf: function () {
                        return self.ext().length > 0;
                    }
                }
            })
            self.ext = ko.observable("")
            .extend({ digit: true });
            self.buyerBorrowerFax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });
            self.buyerBorrowerEmailAddress = ko.observable("")
             .extend({ email: { message: 'Invalid Buyer/Borrower Email Address.' } })
                .extend({ maxLength: { params: 100, message: 'Invalid Buyer/Borrower Email Address.' } })
            self.update = function (data) {
                if (data) {
                    self.buyerBorrower(data.buyerBorrower)
                    self.buyerBorrowerAddress(data.buyerBorrowerAddress)
                    self.buyerBorrowerCity(data.buyerBorrowerCity)
                    self.buyerBorrowerState(data.buyerBorrowerState)
                    self.buyerBorrowerZip(data.buyerBorrowerZip)
                    self.buyerBorrowerPhone(data.buyerBorrowerPhone)
                    self.ext(data.ext)
                    self.buyerBorrowerFax(data.buyerBorrowerFax)
                    self.buyerBorrowerEmailAddress(data.buyerBorrowerEmailAddress)
                }

            }

            self.reset = function () {
                self.buyerBorrower('')
                self.buyerBorrowerAddress('')
                self.buyerBorrowerCity(undefined)
                self.buyerBorrowerState(undefined)
                self.buyerBorrowerZip('')
                self.buyerBorrowerPhone('')
                self.ext('')
                self.buyerBorrowerFax('')
                self.buyerBorrowerEmailAddress('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }


            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Borrower", self.buyerBorrower()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Borrower Address", self.buyerBorrowerAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Borrower Zip", self.buyerBorrowerZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Borrower City", self.buyerBorrowerCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Borrower State", self.buyerBorrowerState()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Borrower Phone", modelMetaData.appendPhoneWithExtn(self.buyerBorrowerPhone(), self.ext(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Borrower Fax", self.buyerBorrowerFax()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Borrower Email Address", self.buyerBorrowerEmailAddress()));
            }

        }

        function AdditionalInformation() {
            var self = this;

            self.viewName = "orderModule/residentialEscrow/_additionalInformation.html"
            self.title = "Additional Information "
            self.ModelId = "AdditionalInformation"
            self.index = ko.observable(-1)

            self.buyerHoldtitle = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Manner in which buyer is to hold title must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.propertyAddress = ko.observable("").extend({ maxLength: { params: 200, message: 'Property address, brief legal must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.update = function (data) {
                if (data) {

                    self.buyerHoldtitle(data.buyerHoldtitle)
                    self.propertyAddress(data.propertyAddress)

                }

            }

            self.reset = function () {
                self.buyerHoldtitle('')
                self.propertyAddress('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            
            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Manner in which buyer is to hold title", self.buyerHoldtitle()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property address, brief legal description or parcel number", self.propertyAddress()));
            }
        }

        function FinancingInformation() {
            var self = this;

            self.viewName = "orderModule/residentialEscrow/_financingInformation.html"
            self.title = "Financing Information"
            self.ModelId = "FinancingInformation"
            self.index = ko.observable(-1)


            self.SalePrice = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Sale Price. (format: ####.##)'
                }
            })
            .extend({ number: true })
            //.extend({ digit: true });
            self.InitialDeposit = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Initial Deposit. (format: ####.##)'
                }
            })
            .extend({ number: true })
            //.extend({ digit: true });


            self.Financing = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Financing must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.fha = ko.observable("")
            self.va = ko.observable("")
            self.conv = ko.observable("")
            self.calVet = ko.observable("")
            self.sellercarryback = ko.observable("")
            self.other = ko.observable("")
            self.describe = ko.observable("").extend({ maxLength: { params: 200, message: 'Other description must be 200 characters or less.' } })
                .extend({
                    required: {
                        message: "Description is required",
                        onlyIf: function () {
                            return self.other();
                        }
                    }
                })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.update = function (data) {
                if (data) {

                    self.SalePrice(data.SalePrice)
                    self.InitialDeposit(data.InitialDeposit)
                    self.Financing(data.Financing)
                    self.fha(data.fha)
                    self.va(data.va)
                    self.conv(data.conv)
                    self.calVet(data.calVet)
                    self.sellercarryback(data.sellercarryback)
                    self.other(data.other)
                    self.describe(data.describe)

                }


            }

            self.reset = function () {
                self.SalePrice('')
                self.InitialDeposit('')
                self.Financing('')
                self.fha('')
                self.va('')
                self.conv('')
                self.calVet('')
                self.sellercarryback('')
                self.other('')
                self.describe('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Sale Price. ($)", self.SalePrice()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Initial Deposit. ($)", self.InitialDeposit()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Financing", self.Financing()));

                self.modelData.properties.push(new modelMetaData.propertyData().create("FHA", modelMetaData.reportBooleanValue(self.fha())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("VA", modelMetaData.reportBooleanValue(self.va())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Conv", modelMetaData.reportBooleanValue(self.conv())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Cal-Vet", modelMetaData.reportBooleanValue(self.calVet())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller carry back", modelMetaData.reportBooleanValue(self.sellercarryback())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other", modelMetaData.reportBooleanValue(self.other())));

                self.modelData.properties.push(new modelMetaData.propertyData().create("If other, Please describe", self.describe()));

            }

        }

        function InstructionsInformation() {
            var self = this;

            self.viewName = "orderModule/residentialEscrow/_instructionsInformation.html"
            self.title = "Instructions Information "
            self.ModelId = "InstructionsInformation"
            self.index = ko.observable(-1)

            self.closeEscrow = ko.observable("")
            self.taxes = ko.observable("")
            self.interest = ko.observable("")
            self.rents = ko.observable("")
            self.other = ko.observable("")
            self.describe = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Other description must be 200 characters or less.' } })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            })
            .extend({
                required: {
                    message: "Description is required",
                    onlyIf: function () {
                        return self.other();
                    }
                }
            });
            self.reimbursedtoSeller = ko.observable(false)
            self.transferredtoBuyer = ko.observable(false)
            self.realEstateBrokers = ko.observable("")
           .extend({
               pattern: {
                   params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                   message: 'Invalid Real estate brokers commission(s) in the amount of. ($). (format: ####.##)'
               }
           });

            self.tobePaidto = ko.observable("")
            self.otherterms = ko.observable("").extend({ maxLength: { params: 200, message: 'Other terms and conditions of the escrow must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.update = function (data) {
                if (data) {

                    self.closeEscrow(data.closeEscrow)
                    self.taxes(data.taxes)
                    self.interest(data.interest)
                    self.rents(data.rents)
                    self.other(data.other)
                    self.describe(data.describe)
                    self.reimbursedtoSeller(data.reimbursedtoSeller)
                    self.transferredtoBuyer(data.transferredtoBuyer)
                    self.realEstateBrokers(data.realEstateBrokers)
                    self.tobePaidto(data.tobePaidto)
                    self.otherterms(data.otherterms)
                }
            }

            self.reset = function () {
                self.closeEscrow("")
                self.taxes("")
                self.interest("")
                self.rents("")
                self.other("")
                self.describe("")
                self.reimbursedtoSeller(false)
                self.transferredtoBuyer(false)
                self.realEstateBrokers("")
                self.tobePaidto("")
                self.otherterms("")

                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Close of Escrow", modelMetaData.reportBooleanValue(self.closeEscrow())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Taxes, based on the latest tax bill", modelMetaData.reportBooleanValue(self.taxes())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Interest on any existing loan", modelMetaData.reportBooleanValue(self.interest())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Rents, based on rental statement to be provided by the seller approved by the buyer", modelMetaData.reportBooleanValue(self.rents())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other", modelMetaData.reportBooleanValue(self.other())));

                self.modelData.properties.push(new modelMetaData.propertyData().create("If other, Please describe", self.describe()));

                self.modelData.properties.push(new modelMetaData.propertyData().create("Reimbursed to seller", modelMetaData.reportBooleanValue(self.reimbursedtoSeller())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Transferred to buyer without reimbursement to seller", modelMetaData.reportBooleanValue(self.transferredtoBuyer())));

                self.modelData.properties.push(new modelMetaData.propertyData().create("Real estate brokers commission(s) in the amount of. ($)", self.realEstateBrokers()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("To be paid to ", self.tobePaidto()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other terms and conditions of the escrow", self.otherterms()));
            }


        }
        var vm = {
            EscrowInformation: EscrowInformation,
            SellerLenderInformation: SellerLenderInformation,
            BuyerBorrowerInformation: BuyerBorrowerInformation,
            AdditionalInformation: AdditionalInformation,
            FinancingInformation: FinancingInformation,
            InstructionsInformation: InstructionsInformation
        }

        return vm;
    });
