define('models/order/viewOnlineOrders',["knockout", 'moment'],
    function (ko, moment) {
        function OnlineOrderSearch() {
            var self = this;



            self.orderRefNumber = ko.observable("")
            self.apnNumber = ko.observable("")
            self.customerName = ko.observable("")
            self.fromDate = ko.observable("")
            self.toDate = ko.observable("")
            self.customerList = ko.observableArray([])


            self.reset = function () {

                self.orderRefNumber("")
                self.apnNumber("")
                self.customerName(self.customerList()[0].Key)
                var date = new Date();
                self.fromDate(moment(date.setDate(date.getDate() - 14)).format('MM/DD/YYYY'));
                self.toDate(moment(new Date()).format('MM/DD/YYYY'));
            }

            self.errors = ko.validation.group(self);
            self.isValid = ko.computed(function () {
                return self.errors().length === 0;
            });

        }

        function OrderSearchResults() {
            var self = this;
            self.orderId = ko.observable("")
            self.firstName = ko.observable("")
            self.lastName = ko.observable("")
            self.fullName = ko.observable("")
            self.email=ko.observable("")
            self.propertyAddress = ko.observable("")
            self.propertyCity = ko.observable("")
            self.propertyState = ko.observable("")
            self.propertyCounty = ko.observable("")
            self.propertyZip = ko.observable("")
            self.apn = ko.observable("")
            self.orderStatus=ko.observable("")
            self.orderDate = ko.observable("")
            self.detailPage = ko.observable("")
        }
        function OnlineOrderDetails() {
            var self = this;
            self.name = ko.observable("");
            self.orderNumber = ko.observable("");
            self.sections = ko.observableArray([]);
        }
        var vm = {
            OnlineOrderSearch: OnlineOrderSearch,
            OrderSearchResults: OrderSearchResults,
            OnlineOrderDetails: OnlineOrderDetails
        }

        return vm;
    });
