define('hoaModel',["knockout", 'plugins/http', 'global/session', 'config', 'utlities'],
    function (ko, https, session, config, utlities) {

        function hoaSearch() {
            var self = this;
            self.hoaId = ko.observable("")
            self.state = ko.observable("")
            self.county = ko.observable("")
            self.name = ko.observable("")
            self.isPageReset = ko.observable(true);

            self.stateList = ko.observableArray([]);
            self.countyList = ko.observableArray([]);
            self.exportSearchResult = ko.observable("");

            //self.stateList = ko.observableArray([
            //   { key: "-1", value: "Any" },
            //   { key: "6", value: "Colorado" }
              
            //]);
            //self.countyList = ko.observableArray([
            //  { key: "-1", value: "Any" },
            //  { key: "129", value: "Boulder" },
            //  { key: "13880", value: "Broomfield" },
            //]);

            utlities.getStatesListWithStateId().done(function (result) {
                self.stateList([]);
                self.stateList(result);
                self.stateList.unshift({
                    Value: "-1", Key: "Any"
                });
                var urlOpeartionStatesList = config.getOpeartionStatesByOpeartionId;
                https.get(urlOpeartionStatesList, {
                    OperationId: session.defaultOpertionId(),
                    format: 'json'
                }).then(function (data) {
                    if (data != null && data.length > 0) {
                        self.state(data[0].StateId);
                    }
                    else {
                        self.state("6");
                    }
                });
            });

            self.state.subscribe(function (stateId) {
                if (stateId != null && stateId != "" && stateId != "-1") {
                    utlities.getCountyListWithCountyIdByStateId(parseInt(stateId)).done(function (result) {
                        self.countyList([]);
                        self.countyList(result);
                        self.countyList.unshift({
                            Value: "-1", Key: "Any"
                        });
                        self.county(self.countyList()[0].Value);
                    });
                }
                else {
                    self.countyList([]);
                    self.countyList.push
                        ({
                            Value: "-1", Key: "Any"
                        });
                    self.county(self.countyList()[0].Value);
                }
            });

            self.reset = function () {
                var urlOpeartionStatesList = config.getOpeartionStatesByOpeartionId;
                https.get(urlOpeartionStatesList, {
                    OperationId: session.defaultOpertionId(),
                    format: 'json'
                }).then(function (data) {
                    if (data != null && data.length > 0) {
                        self.state(data[0].StateId);
                    }
                    else {
                        self.state("6");
                    }
                });
                self.county("-1")
                self.name("")
                self.isPageReset(true);
                self.exportSearchResult("");
            }
        }

        function hoaResults() {
            var self = this;
            self.hoaId = ko.observable("")
            self.name = ko.observable("")
            self.managementCompany = ko.observable("")
            self.city = ko.observable("")
        }

        function hoaDetails() {
            var self = this;
            self.name = ko.observable("");
            self.president = ko.observable("");
            self.secretary = ko.observable("");
            self.managementCompany = ko.observable("");
            self.streetAddress1 = ko.observable("");
            self.streetAddress2 = ko.observable("");
            self.city = ko.observable("");
            self.zip = ko.observable("");
            self.state = ko.observable("");
            self.county = ko.observable("");
            self.phone = ko.observable("");
            self.fax = ko.observable("");
            self.websiteURL = ko.observable("");
            self.period = ko.observable("");
            self.dues = ko.observable("");
            self.notes = ko.observable("");
            self.misc = ko.observable("");
        }

        var vm = {
            hoaSearch: hoaSearch,
            hoaResults: hoaResults,
            hoaDetails: hoaDetails
        }

        return vm;
    });
