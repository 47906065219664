define('deviceDetect',['durandal/app', 'jquery', 'knockout', 'config', 'alertNotification'],
    function (app, $, ko, config, alert) {
        function DeviceDetect(agentInfo) {

            var deviceType = "";

            // Check if user agent is a smart TV - http://goo.gl/FocDk
            if (agentInfo.match(/GoogleTV|SmartTV|Internet.TV|NetCast|NETTV|AppleTV|boxee|Kylo|Roku|DLNADOC|CE\-HTML/i)) {
                deviceType = "tv";
            }
                // Check if user agent is a TV Based Gaming Console
            else if (agentInfo.match(/Xbox|PLAYSTATION.3|Wii/i)) {
                deviceType = "tv";
            }
                // Check if user agent is a Tablet
            else if ((agentInfo.match(/iP(a|ro)d/i)) || (agentInfo.match(/tablet/i)) && (!agentInfo.match(/RX-34/i)) || (agentInfo.match(/FOLIO/i))) {
                deviceType = "tablet";
            }
                // Check if user agent is an Android Tablet
            else if ((agentInfo.match(/Linux/i)) && (agentInfo.match(/Android/i)) && (!agentInfo.match(/Fennec|mobi|HTC.Magic|HTCX06HT|Nexus.One|SC-02B|fone.945/i))) {
                deviceType = "tablet";
            }
                // Check if user agent is a Kindle or Kindle Fire
            else if ((agentInfo.match(/Kindle/i)) || (agentInfo.match(/Mac.OS/i)) && (agentInfo.match(/Silk/i))) {
                deviceType = "tablet";
            }
                // Check if user agent is a pre Android 3.0 Tablet
            else if ((agentInfo.match(/GT-P10|SC-01C|SHW-M180S|SGH-T849|SCH-I800|SHW-M180L|SPH-P100|SGH-I987|zt180|HTC(.Flyer|\_Flyer)|Sprint.ATP51|ViewPad7|pandigital(sprnova|nova)|Ideos.S7|Dell.Streak.7|Advent.Vega|A101IT|A70BHT|MID7015|Next2|nook/i)) || (agentInfo.match(/MB511/i)) && (agentInfo.match(/RUTEM/i))) {
                deviceType = "tablet";
            }
                // Check if user agent is unique Mobile User Agent
            else if ((agentInfo.match(/BOLT|Fennec|Iris|Maemo|Minimo|Mobi|mowser|NetFront|Novarra|Prism|RX-34|Skyfire|Tear|XV6875|XV6975|Google.Wireless.Transcoder/i))) {
                deviceType = "mobile";
            }
                // Check if user agent is an odd Opera User Agent - http://goo.gl/nK90K
            else if ((agentInfo.match(/Opera/i)) && (agentInfo.match(/Windows.NT.5/i)) && (agentInfo.match(/HTC|Xda|Mini|Vario|SAMSUNG\-GT\-i8000|SAMSUNG\-SGH\-i9/i))) {
                deviceType = "mobile";
            }
                // Check if user agent is Windows Desktop
            else if ((agentInfo.match(/Windows.(NT|XP|ME|9)/i)) && (!agentInfo.match(/Phone/i)) || (agentInfo.match(/Win(9|.9|NT)/i))) {
                deviceType = "desktop";
            }
                // Check if agent is Mac Desktop
            else if ((agentInfo.match(/Macintosh|PowerPC/i)) && (!agentInfo.match(/Silk/i))) {
                deviceType = "desktop";
            }
                // Check if user agent is a Linux Desktop
            else if ((agentInfo.match(/Linux/i)) && (agentInfo.match(/X11/i))) {
                deviceType = "desktop";
            }
                // Check if user agent is a Solaris, SunOS, BSD Desktop
            else if ((agentInfo.match(/Solaris|SunOS|BSD/i))) {
                deviceType = "desktop";
            }
                // Check if user agent is a Desktop BOT/Crawler/Spider
            else if ((agentInfo.match(/Bot|Crawler|Spider|Yahoo|ia_archiver|Covario-IDS|findlinks|DataparkSearch|larbin|Mediapartners-Google|NG-Search|Snappy|Teoma|Jeeves|TinEye/i)) && (!agentInfo.match(/Mobile/i))) {
                deviceType = "desktop";
            }
                // Otherwise assume it is a Mobile Device
            else {
                deviceType = "mobile";
            }

            return deviceType;
        }

        var vm = {
            DeviceDetect: DeviceDetect,
        };
        return vm;
    });
