define('models/order/offlineFarmOrder',["knockout", "modelMetaData"],
    function (ko, modelMetaData) {
        function SearchCriteria() {
            var self = this;

            self.viewName = "orderModule/offline/_searchCriteria.html"
            self.title = "Search Criteria"
            self.ModelId = "SearchCriteria"
            self.index = ko.observable(-1)

            self.propertyAddress = ko.observable("")
               .extend({ maxLength: { params: 200, message: 'Property Address must be 200 characters or less' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.parcelNumbers = ko.observable("")
            self.city = ko.observable("")
            self.state = ko.observable("")
            self.zipCodes = ko.observable("")
             .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.city = ko.observable("")
            self.thomasBrothersGuide = ko.observable("")
            self.tract = ko.observable("")
            self.ownerNames = ko.observable("")
            self.additionalInformation = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Additional Information must be 200 characters or less' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.propertyAddress(data.propertyAddress())
                    self.parcelNumbers(data.parcelNumbers())
                    self.city(data.city())
                    self.state(data.state())
                    self.zipCodes(data.zipCodes())
                    self.thomasBrothersGuide(data.thomasBrothersGuide())
                    self.tract(data.tract())
                    self.ownerNames(data.ownerNames())
                    self.additionalInformation(data.additionalInformation())
                }

            };

            self.reset = function () {
                self.propertyAddress('')
                self.parcelNumbers('')
                self.city('')
                self.state('')
                self.zipCodes('')
                self.thomasBrothersGuide('')
                self.tract('')
                self.ownerNames('')
                self.additionalInformation('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.resetPropertyAddress = function () {
                self.propertyAddress('')
                self.parcelNumbers('')
                self.city('')
                self.state('')
                self.zipCodes('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Address", self.propertyAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Parcel Numbers", self.parcelNumbers()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip", self.zipCodes()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Thomas Brothers Guide", self.thomasBrothersGuide()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Tract (number or name)", self.tract()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Owner Names", self.ownerNames()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Additional Information", self.additionalInformation()));
            }

        }
        function SecondarySearch() {
            var self = this;

            self.viewName = "orderModule/offline/_secondarySearch.html"
            self.title = "Secondary Search"
            self.ModelId = "SecondarySearch"
            self.index = ko.observable(-1)

            self.bedroom = ko.observable("")
            self.bath = ko.observable("")
            self.squareFootage = ko.observable("")
            //property type
            self.useCode = ko.observable("")
            self.isOwnerOccupied = ko.observable("Owner Occupied")
            self.ownerOccupied = ko.observable("")
            self.absentee = ko.observable("")
            self.priceRange = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.bedroom(data.bedroom())
                    self.bath(data.bath())
                    self.squareFootage(data.squareFootage())
                    //property type
                    self.useCode(data.useCode())
                    self.ownerOccupied(data.ownerOccupied())
                    self.absentee(data.absentee())
                    self.priceRange(data.priceRange())
                }

            };

            self.reset = function () {
                self.bedroom('')
                self.bath('')
                self.squareFootage('')
                //property type
                self.useCode('')
                self.isOwnerOccupied("Owner Occupied")
                self.ownerOccupied('')
                self.absentee('')
                self.priceRange('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Bedroom(s)", self.bedroom()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Bath(s)", self.bath()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Square Footage", self.squareFootage()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Use Code (property type)", self.useCode()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Owner Occupied", self.isOwnerOccupied()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Price Range", self.priceRange()));
            }

        }
        function ReportType() {
            var self = this;

            self.viewName = "orderModule/offline/_reportType.html"
            self.title = "Report Type"
            self.ModelId = "ReportType"
            self.index = ko.observable(-1)
            self.brandId = ko.observable("")

            self.line6 = ko.observable("")
            self.line5 = ko.observable("")
            self.line3 = ko.observable("")
            self.line1 = ko.observable("")
            //Raw Format on Disk (e-mail)
            self.rawFormat = ko.observable("")
            self.ascii = ko.observable("")
            self.converted = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.line6(data.line6)
                    self.line5(data.line5())
                    self.line3(data.line5())
                    self.line1(data.line1())
                    //Raw Format on Disk (e-mail)
                    self.rawFormat(data.rawFormat())
                    self.ascii(data.ascii())
                    self.converted(data.converted())

                }

            };

            self.reset = function () {
                self.line6('')
                self.line5('')
                self.line3('')
                self.line1('')
                //Raw Format on Disk (e-mail)
                self.rawFormat('')
                self.ascii('')
                self.converted('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                if (self.brandId != 4 && self.brandId != 16)
                    self.modelData.properties.push(new modelMetaData.propertyData().create("6-Line", modelMetaData.reportBooleanValue(self.line6())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("5-Line", modelMetaData.reportBooleanValue(self.line5())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("3-Line", modelMetaData.reportBooleanValue(self.line3())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("1-Line", modelMetaData.reportBooleanValue(self.line1())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Raw Format on Disk (e-mail)", modelMetaData.reportBooleanValue(self.rawFormat())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("ASCII", modelMetaData.reportBooleanValue(self.ascii())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Converted", modelMetaData.reportBooleanValue(self.converted())));
            }

        }
        function Labels() {
            var self = this;

            self.viewName = "orderModule/offline/_labels.html"
            self.title = "Labels"
            self.ModelId = "Labels"
            self.index = ko.observable(-1)
            self.brandId = ko.observable("")

            self.digit5 = ko.observable(false)
            self.additionalInformation = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Additional Information must be 200 characters or less' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.carrierRoute = ko.observable(false)
            self.barcode = ko.observable(false)
            self.ecrllot = ko.observable(false)
            self.crtOrbarcode = ko.observable(false)
            self.permitNumber = ko.observable("")
            self.postOffice = ko.observable("")
            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.digit5(data.digit5())
                    self.additionalInformation(data.additionalInformation())
                    self.carrierRoute(data.carrierRoute())
                    self.barcode(data.barcode())
                    self.ecrllot(data.ecrllot())
                    self.crtOrbarcode(data.crtOrbarcode())
                    self.permitNumber(data.permitNumber())
                    self.postOffice(data.postOffice())

                }

            };

            self.reset = function () {
                self.digit5('')
                self.additionalInformation('')
                self.carrierRoute('')
                self.barcode('')
                self.ecrllot('')
                self.crtOrbarcode('')
                self.permitNumber('')
                self.postOffice('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);

                if (self.brandId == 4 || self.brandId == 16) {
                    self.modelData.properties.push(new modelMetaData.propertyData().create("5 Digit", modelMetaData.reportBooleanValue(self.digit5())));
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Additional Information", self.additionalInformation()));
                }
                else {
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Carrier Route", modelMetaData.reportBooleanValue(self.carrierRoute())));
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Barcode", modelMetaData.reportBooleanValue(self.barcode())));
                    self.modelData.properties.push(new modelMetaData.propertyData().create("ECRLLOT", modelMetaData.reportBooleanValue(self.ecrllot())));
                    self.modelData.properties.push(new modelMetaData.propertyData().create("5 Digit", modelMetaData.reportBooleanValue(self.digit5())));
                    self.modelData.properties.push(new modelMetaData.propertyData().create("CRT/Barcode", modelMetaData.reportBooleanValue(self.crtOrbarcode())));
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Permit Number", self.permitNumber()));
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Post Office", self.postOffice()));
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Additional Information", self.additionalInformation()));
                }

            }

        }
        var vm = {
            SearchCriteria: SearchCriteria,
            SecondarySearch: SecondarySearch,
            ReportType: ReportType,
            Labels: Labels
        }

        return vm;
    });
