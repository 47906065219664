define('viewmodels/userModule/login2',['plugins/router', 'durandal/app', 'knockout', 'services/logger', 'global/session', 'header'],
    function (router, app, ko, logger, session, header) {

 
        var vm = {
            activate: activate,
        //    goBack: goBack,
            bindingComplete: configOKTA,
        //    currentStep: currentStep,
        //    getStartedNow: getStartedNow,
        //    session: session,
        //    checkLLAgreement: checkLLAgreement,
        //    checkLLAgreementWithREiSourceAccount: checkLLAgreementWithREiSourceAccount,
        //    title: 'Premium Leads',
        //    canDeactivate: cleanUP,
            //compositionComplete: configOKTA

        };

        return vm;

        

        function activate() {
            //TODO: Initialize lookup data here.

            //TODO: Get the data for this viewmodel, return a promise.
            console.log("login 2");

         
            //oktaSignIn.authClient.token.getUserInfo().then(function (user) {

            //}, function (error) {
            //    oktaSignIn.showSignInToGetTokens({
            //        el: '#okta-login-container'
            //    }).then(function (tokens) {
            //        oktaSignIn.authClient.tokenManager.setTokens(tokens);
            //        oktaSignIn.remove();

            //        const idToken = tokens.idToken;

            //    }).catch(function (err) {
            //        console.error(err);
            //    });
            //});

        }

        function configOKTA() {
            var oktaConfig = {
                //issuer: "https://dev-84809560.okta.com/oauth2/default",
                baseUrl: 'https://dev-84809560.okta.com',
                redirectUri: 'https://local.premier.ctic.com/login',
                clientId: "0oa3wkyczlIoBrh2h5d7",
                authParams: {
                    issuer: 'https://dev-84809560.okta.com/oauth2/default'
                }
            }
          
            const oktaSignIn = new OktaSignIn(oktaConfig);
          
            oktaSignIn.showSignInToGetTokens({
                el: '#okta-login-container'
            }).then(function (tokens) {
                oktaSignIn.authClient.tokenManager.setTokens(tokens);
                oktaSignIn.remove();

                const idToken = tokens.idToken;
              
            }).catch(function (err) {
                console.error(err);
            });

        }

        function cleanUP() {
            return true;
        }



        function AfterLoad() {
            
        }

        function goBack() {
            router.navigateBack();
        }

        function checkLLAgreement() {
            
        }

        function checkLLAgreementWithREiSourceAccount() {
            
        }

        
            
    });
