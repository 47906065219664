define('viewmodels/businessCard/businessCard',['durandal/app', 'plugins/router', 'knockout', 'services/logger', 'Q', 'plugins/http', 'config', 'global/session', 'alertNotification', 'utlities', 'commonDialogModel', 'deviceDetect', 'businessCardModel'],
 function (app, router, ko, logger, Q, https, config, session, alert, utlities, commonDialog, deviceDetect, businessCardModel) {
     var deviceType = ko.observable("");

     var userId = ko.observable("");
     var userBrandId = ko.observable(0);
     var userFullName = ko.observable("");
     var imagePath = ko.observable("Content/images/Photos/no_image.jpg");
     var officePhone = ko.observable("");
     var cellPhone = ko.observable("");
     var textMePhone = ko.observable("");
     var emailAddress = ko.observable("");
     var myWebsiteURL = ko.observable("");
     var myAddressDetails = ko.observable("");
     var allowTexting = ko.observable(false);
     var customerServicePhone = ko.observable("");
     var userNotAvailable = ko.observable(false);

     var socialMediaList = ko.observableArray();
     var myOtherWebsitesList = ko.observableArray();

     function canActivate(UserId, Brand) {
         userId(UserId);
         if (Brand != undefined & Brand != null) {
             if (Brand.indexOf("/") != -1)
                 userBrandId(Brand.substring(1));
         }
         return true;
     }

     function activate() {
         console.log(userId());
         console.log(userBrandId());
         userNotAvailable(false);
         app.title = "";
         return getUserBusinessCardInfo();
     }

     function compositionComplete() {
         session.validateInput();
         var agent = navigator.userAgent;
         deviceType(deviceDetect.DeviceDetect(agent));
     }

     function canDeactivate() {
         return true;
     }

     function deactivate() {
         return true;
     }

     function getUserBusinessCardInfo() {
         var url = config.getUserBusinessCardInfo;
         return https.get(url, { UserGUID: userId(), BrandId: userBrandId() })
             .done(function (result) {
                 if (result != undefined && result != null && !result.HasErrors) {
                     console.log(result);
                     userFullName(result.FullName);
                     if (result.Phone != undefined && result.Phone != null && result.Phone.length > 0) {
                         var tempval = utlities.getUSPhoneFormatWithoutExt(result.Phone);
                         officePhone("+1 " + tempval);
                     }
                     else
                         officePhone("");


                     if (result.Cell != undefined && result.Cell != null && result.Cell.length > 0) {
                         var tempval2 = utlities.getUSPhoneFormatWithoutExt(result.Cell);
                         cellPhone("+1 " + tempval2);
                         textMePhone("+1 " + tempval2);
                     }
                     else {
                         cellPhone("");
                         textMePhone("");
                     }
                     emailAddress(result.Email);
                     myWebsiteURL(result.Website);
                     myAddressDetails(result.Address);
                     allowTexting(result.AllowTexting);
                     customerServicePhone(result.CustomerService);
                     if (result.PhotoPath != null && result.PhotoPath != "")
                         imagePath(result.PhotoPath);
                     else
                         imagePath("Content/images/Photos/no_image.jpg");
                     if (result.UserSocialMediaInfo != undefined && result.UserSocialMediaInfo != null)
                         getSocialMediaDetailsList(result.UserSocialMediaInfo);
                     if (result.OtherWebsites != undefined && result.OtherWebsites != null)
                         getMyOtherWebsitesList(result.OtherWebsites);
                     router.activeInstruction().config.title = result.FullName;
                 }
                 else {
                     userNotAvailable(true);
                     router.activeInstruction().config.title = "Business Card";
                 }
             })
            .always(function () {
                //isDataLoad(false);
                //session.isAjaxPost(true);
            })
            .fail(function () {
                //alert.error('Error fetching User Business Card Infomation', 'User Business Card Infomation');
                userNotAvailable(true);
                router.activeInstruction().config.title = "Business Card";
            });
     }

     function callMyOffice() {
         if (deviceType() == "mobile")
             window.location = 'tel:' + officePhone();
         else
             alert.info(officePhone());
     }

     function callMyCell() {
         if (deviceType() == "mobile")
             window.location = 'tel:' + cellPhone();
         else
             alert.info(cellPhone());
     }

     function textMe() {
         if (allowTexting()) {
             if (deviceType() == "mobile")
                 window.location = 'sms:' + textMePhone();
             else
                 alert.info(textMePhone());
         }
     }

     function emailMe() {
         window.location = 'mailto:' + emailAddress() + '?subject=Question';
     }

     function myWebsite() {
         window.open = myWebsiteURL();
     }

     function myAddress() {
         var href = 'http://maps.google.com/maps?q=' + myAddressDetails();
         window.open(href, '_blank');
     }

     function callCustomerService() {
         if (deviceType() == "mobile")
             window.location = 'tel:+1' + customerServicePhone();
         else
             alert.info('+1' + customerServicePhone());
     }

     function getSocialMediaDetailsList(UserSocialMediaInfo) {
         socialMediaList([]);
         var list = [];
         for (var i = 0; i < UserSocialMediaInfo.length; i++) {
             if (UserSocialMediaInfo[i].SiteUrl != null && UserSocialMediaInfo[i].SiteUrl != "") {
                 var item = new businessCardModel.bCardUserSocialMedia();
                 item.socialMediaId(UserSocialMediaInfo[i].SocialMediaId);
                 item.name(UserSocialMediaInfo[i].Name);
                 item.siteUrl(UserSocialMediaInfo[i].SiteUrl);
                 item.imageUrl(UserSocialMediaInfo[i].ImageUrl.substring(2, UserSocialMediaInfo[i].ImageUrl.length));
                 item.active(UserSocialMediaInfo[i].Active);
                 list.push(item);
             }
         }
         socialMediaList(list);
     }

     function getMyOtherWebsitesList(myOtherWebsites) {
         myOtherWebsitesList([]);
         var list = [];
         for (var prop in myOtherWebsites) {
             //console.log(prop, myOtherWebsites[prop]);
             var item = new businessCardModel.bCardUserMyOtherWebLink();
             item.webLinkName(myOtherWebsites[prop].m_Item1);
             var url = myOtherWebsites[prop].m_Item2;
             if (url.indexOf("http://") != -1 || url.indexOf("https://") != -1)
                 item.webLinkURL(url);
             else
                 item.webLinkURL("http://" + url);
             list.push(item);
         }
         myOtherWebsitesList(list);
     }

     var vm = {
         canActivate: canActivate,
         activate: activate,
         compositionComplete: compositionComplete,
         canDeactivate: canDeactivate,
         deactivate: deactivate,
         deviceType: deviceType,

         userFullName: userFullName,
         imagePath: imagePath,
         officePhone: officePhone,
         cellPhone: cellPhone,
         textMePhone: textMePhone,
         emailAddress: emailAddress,
         myWebsiteURL: myWebsiteURL,
         myAddressDetails: myAddressDetails,
         allowTexting: allowTexting,
         customerServicePhone: customerServicePhone,
         userNotAvailable: userNotAvailable,

         socialMediaList: socialMediaList,
         myOtherWebsitesList: myOtherWebsitesList,

         callMyOffice: callMyOffice,
         callMyCell: callMyCell,
         textMe: textMe,
         emailMe: emailMe,
         myWebsite: myWebsite,
         myAddress: myAddress,
         callCustomerService: callCustomerService,
     }
     return vm;
 });
