define('viewmodels/userProfileModule/userInformation',['durandal/app', 'jquery', 'knockout', 'validate', 'knockout.validation', 'userInfoModel', 'global/session', 'config', 'plugins/http', 'utlities', 'Q', 'alertNotification', 'moment'],
    function (app, $, ko, validate, validation, model, session, config, https, utils, Q, alert, moment) {

        var userInfo = new model.userInfo();
        var socialMedia = new model.socialMedia();
        var socialMediaList = ko.observableArray();//.extend({ validSocialMedia: true });

        var serverState = {};
        var linksData = {};
        var userName = ko.observable("");
        var streetfips = ko.observableArray();
        var streetfipsNo = ko.observable('');
        var isDataLoad = ko.observable(false);
        var isEdit = ko.observable(false);

        var showButton = ko.observable(true);


        function activate() {
            isEdit(false);

            showButton(true);
            return getUserProfileInformation();
            _deletingObjectIndex(-1);
            _deletingObjectTitle("");
        }

        function canDeactivate() {
            userInfo.reset();
            socialMedia.reset();

        };

        userInfo.zipCode.subscribe(function (Zip) {
            if (isDataLoad()) {

                if (Zip != undefined && Zip != '') {
                    userInfo.countyList([]);
                    streetfips([]);
                    var url = config.getZipInfoUrl;
                    Q.when(https.get(url, {
                        zipCode: Zip.substring(0, 5),
                        format: 'json'
                    }).then(function (data) {
                        if (data != undefined && data != null && data.HasErrors == false) {
                            if (data.CountyName != null && data.Fips != null) {
                                streetfips(data);
                                if (streetfips().Fips != null) {
                                    streetfipsNo = streetfips().Fips;
                                }
                                userInfo.state(data.StateAbbr);
                                getStreetLookupCounties(data.StateAbbr);
                            }
                        }
                        else
                            session.zipCodeServiceDown();
                    }).always(function () {
                        session.isAjaxPost(true);
                    })
                    );
                }

                return;
            }


            if (Zip != undefined && Zip != '') {
                userInfo.countyList([]);
                streetfips([]);
                var url = config.getZipInfoUrl;
                Q.when(https.get(url, {
                    zipCode: Zip.substring(0, 5),
                    format: 'json'
                }).then(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.CountyName != null && data.Fips != null) {
                            streetfips(data);
                            if (streetfips().Fips != null) {
                                streetfipsNo = streetfips().Fips;
                            }
                            userInfo.state(data.StateAbbr);
                            userInfo.city(data.City);
                            getStreetLookupCounties(data.StateAbbr);
                        }
                        else {
                            alert.error("Invalid Zip.");
                            userInfo.zipCode('');
                            userInfo.state('');
                            userInfo.city('');
                            userInfo.county('');
                            userInfo.fips('');
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                }).always(function () {
                    session.isAjaxPost(true);
                })
                );
            }
        });

        function getUserProfileInformation() {
            isDataLoad(true);
            var url = config.getUserProfileInfoUrl;
            return https.get(url, { UserId: session.userId, OperationId: session.defaultOpertionId() })
                .done(function (result) {
                    serverState = result;
                    userInfo.update(serverState.UserInformation);
                    userInfo.manager(serverState.UserManagerFullName);
                    socialMediaList([]);
                    for (var i = 0; i < serverState.SocialMedias.length; i++) {
                        var item = new model.socialMedia();
                        item.socialMediaId(serverState.SocialMedias[i].SocialMediaId);
                        item.name(serverState.SocialMedias[i].Name);
                        item.siteUrl(serverState.SocialMedias[i].SiteUrl);
                        item.imageUrl(serverState.SocialMedias[i].ImageUrl.substring(2, serverState.SocialMedias[i].ImageUrl.length));
                        item.active(serverState.SocialMedias[i].Active);
                        socialMediaList.push(item);
                    }

                }).always(function () {
                    isDataLoad(false);
                    session.isAjaxPost(true);
                })
                .fail(function () {
                    //alert.error('Error fetching User Infomation', 'User Infomation');
                });
        }



        function getStreetLookupCounties(state) {
            var url = config.countyUrl;
            session.isAjaxPost(true);
            Q.when(https.get(url, {
                stateAbbr: state,
                format: 'json'
            }).then(function (data) {
                userInfo.countyList(data);
                if (streetfips().Fips != undefined && streetfips().Fips != '') {
                    userInfo.county(streetfips().Fips);
                    userInfo.fips(streetfips().Fips);
                }
            }).always(function () {
                session.isAjaxPost(true);
            })
            );
        }

        userInfo.state.subscribe(function (state) {
            if (state != undefined && state != '') {
                getStreetLookupCounties(state);
            }
        });

        //socialMediaList.subscribe(function (changes) {
        //    console.log(changes);
        //    console.log(ko.validation.group(socialMediaList(), { deep: true }));
        //    //changes.forEach(function (change) {
        //    //    console.log(change.Status);
        //    //});

        //});




        function updateProfile() {
            //if (vm.validationErrors().length > 0) {
            //    vm.validationErrors.showAllMessages();
            //    return;
            //}
            vm.socialMediaListValidationErrors = ko.validation.group([vm.socialMediaList]);

            if (vm.errors != null && vm.errors != 'undefined' && vm.errors().length > 0) {
                vm.errors.showAllMessages();
                return;
            }
            if (vm.socialMediaListValidationErrors != null && vm.socialMediaListValidationErrors != 'undefined' && vm.socialMediaListValidationErrors().length > 0) {
                vm.socialMediaListValidationErrors.showAllMessages();
                return;
            }

            var socialMediaListJSONObject = ko.toJS(socialMediaList);
            var selectedCounty = $('#county option:selected').text();
            var PhoneServiceWithExtention = utils.unmaskPhoneNumber(userInfo.phCustService);
            if (userInfo.phCustServiceExt() != null && userInfo.phCustServiceExt() != '') {
                PhoneServiceWithExtention = PhoneServiceWithExtention + 'x' + userInfo.phCustServiceExt();
            }
            if (userInfo.birthdayMonth() == "" || userInfo.birthdayMonth() == null || userInfo.birthdayMonth() == undefined) {
                userInfo.birthDate("");
            }

            if (userInfo.birthdayDate() == "" || userInfo.birthdayDate() == null || userInfo.birthdayDate() == undefined) {
                userInfo.birthDate("");
            }

            if (userInfo.birthdayMonth() != "" && userInfo.birthdayMonth() != null && userInfo.birthdayMonth() != undefined && userInfo.birthdayDate() != "" && userInfo.birthdayDate() != null && userInfo.birthdayDate() != undefined) {
                var dt = moment(new Date(userInfo.birthDate()));
                dt = dt.date(userInfo.birthdayDate());
                dt = dt.month(userInfo.birthdayMonth() - 1);

                if (moment(userInfo.birthDate()).year() < 1900) {
                    dt = dt.year(1900);
                }
                userInfo.birthDate(moment(dt.toDate()).format("YYYY-MM-DD"));
            }

            var url = config.updateUserProfileUrl;
            var request = {
                "UserInformation": {
                    "UserId": session.userId,
                    "OnlineOrderId": userInfo.clientID,
                    "FirstName": userInfo.fName,
                    "MiddleName": userInfo.mName,
                    "LastName": userInfo.lName,
                    "Birthday": userInfo.birthDate,
                    "EmailAddress": userInfo.email,
                    "AcceptsBulkEmail": userInfo.acceptsBulkEmail,
                    "AcceptsTitleProNotifications": userInfo.enableTitlePro247CustActivityEmailNotifications,
                    "AcceptsLowLimitNotifications": userInfo.enableProfileLowLimitEmailNotifications,
                    "WellsFargoResetExempt": userInfo.exemptFromWellsFargoResetProcess,
                    "LowLimitNotificationSetting": userInfo.lowLimitSetting,
                    "StreetAddress1": userInfo.address,
                    "StreetAddress2": userInfo.suiteNo,
                    "Zip": userInfo.zipCode,
                    "City": userInfo.city,
                    "County": selectedCounty,
                    "Fips": userInfo.fips,
                    "State": userInfo.state,
                    "CompanyName": userInfo.company,
                    "OccupationId": userInfo.occupation,
                    "PhoneWork": utils.unmaskPhoneNumber(userInfo.phWork),
                    "PhoneWorkExt": userInfo.phWorkExt,
                    "PhoneHome": utils.unmaskPhoneNumber(userInfo.phHome),
                    "PhoneHomeExt": userInfo.phHomeExt,
                    "PhoneCell": utils.unmaskPhoneNumber(userInfo.phCell),
                    "AllowSMS": userInfo.allowSms,
                    "PhoneFax": utils.unmaskPhoneNumber(userInfo.phFax),
                    "PhoneOther": utils.unmaskPhoneNumber(userInfo.phOther),
                    "PhoneOtherExt": userInfo.phOtherExt,
                    "PhoneOtherType": userInfo.phOtherType,
                    "WebsiteUrl": userInfo.websiteUrl,
                    "PhoneService": PhoneServiceWithExtention,
                    //"PhoneService": userInfo.phCustServiceExt,
                    "BirthdayEmail": userInfo.sendBirthDayEmailsToCustomers

                    //"Manager": userInfo.manager
                },
                "SocialMedias": socialMediaListJSONObject
            };
            return https.post(url, request)
                .then(function (data) {
                    if (data.HasErrors == false) {
                        getUserProfileInformation();

                        //This is to set in Advance Search
                        session.userAddressZip(userInfo.zipCode());
                        session.userAddressCity(userInfo.city());
                        session.userAddressStateAbbr(userInfo.state());
                        if (session.userFIPS() != userInfo.fips()) {
                            session.userFIPS(userInfo.fips());
                            session.setUserCountyLabel();
                        }
                        alert.success("User Information successfully updated", "User Information");
                    }
                    else if (data.HasErrors == true) {
                        if (data.Messages["0"].Message) {
                            alert.error(data.Messages["0"].Message);
                        }
                    }
                    else {
                        alert.error('Error while updating User Information', "User Information");
                        //alert.error(data.Messages[0].Message + "   " + data.Messages[0].ExceptionStackTrace, "User Information");
                    }
                })
                .fail(function () {
                    getUserProfileInformation();
                    alert.error('Error while updating User Information', "User Information");
                });
        }


        function cancel() {
            userInfo.update(serverState.UserInformation);
            socialMediaList([]);
            for (var i = 0; i < serverState.SocialMedias.length; i++) {
                var item = new model.socialMedia();
                item.socialMediaId(serverState.SocialMedias[i].SocialMediaId);
                item.name(serverState.SocialMedias[i].Name);
                item.siteUrl(serverState.SocialMedias[i].SiteUrl);
                item.imageUrl(serverState.SocialMedias[i].ImageUrl.substring(2, serverState.SocialMedias[i].ImageUrl.length));
                item.active(serverState.SocialMedias[i].Active);
                socialMediaList.push(item);
            }
        }



        function deactivate() {
            //session.showAddLinkButton(showButton());
        }

        function compositionComplete() {
            session.validateInput();
        }

        var vm = {
            userName: userName,
            userInfo: userInfo,
            socialMedia: socialMedia,
            socialMediaList: socialMediaList,
            activate: activate,
            updateProfile: updateProfile,
            cancel: cancel,
            deactivate: deactivate,
            isEdit: isEdit,
            compositionComplete: compositionComplete

        };

        //vm.validationErrors = ko.validation.group([vm.userInfo]);
        //vm.socialMediaValidationErrors = ko.validation.group([vm.socialMedia]);
        vm.errors = ko.validation.group([vm]);
        return vm;
    });
