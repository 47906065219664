define('viewmodels/orderModule/coloradoTitleOrder',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'commonOrderModel', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'commonDialogModel', 'coloradoTitleModel', 'customerInformationModel', 'utlities'],
    function (app, router, $, ko, validate, iCheck, commonOrderModel, config, validation, Q, https, session, alert, commonDialog, model, CustomerInformation, utlities) {

        var customerInformationModel = new CustomerInformation();
        var subjectPropertyModel = new model.subjectProperty();
        var typeofPolicyInfoModel = new model.typeofPolicyInformation();
        var referralInfoModel = new model.referralInformation();
        var endorsementInfoModel = new model.endorsementInformation();
        var submitOrderModel = new commonOrderModel.SubmitOrder();

        var commonDialogModel = new commonDialog("orderSubmitDialog");
        var orderSubmitted = ko.observable(false);
        var orderTitle = ko.observable('Colorado Title Order');

        var currentView = ko.observable('');
        var currentOrderModels = ko.observableArray([]);
        var currentViewIndex = ko.observable(-1);
        var currentOrderModel = ko.observable();
        var selectedOrderType = ko.observable(0);
        var selectedOrderTypeName = ko.observable("");
        var countyList = ko.observableArray();
        var stateList = ko.observableArray();

        //var selectedEndorsement = ko.observable("");
        var endorsementsList = ko.observableArray();

        //var selectedEscrowLocation = ko.observable("");
        var escrowLocationsList = ko.observableArray();

        var policyTypeList = ko.observableArray();
        var propertyInfoPopulateFromSession = ko.observable(false);
        var recentSearches = ko.observable(undefined);
        var recentSearchSelectedAPN = ko.observable("");
        var propertyInfoPopulateFromRecentSearch = ko.observable(false);

        currentOrderModel.subscribe(function (model) {
            vm.validationErrors = ko.validation.group([model]).extend({
                notify: 'always'
            });
            currentView(model.viewName);
            selectedOrderTypeName(model.title);
            session.handleFocusOnTabChange(model.ModelId, model.viewName);
        });

        currentViewIndex.subscribe(function (index) {
            currentOrderModel(currentOrderModels()[index]);
        });
        subjectPropertyModel.zip.subscribe(function (zip) {
            if (zip != undefined && zip != '') {
                countyList([]);
                utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        var selectedCounty = data.CountyName;
                        if (data.StateAbbr != null || data.City != null) {
                            utlities.getCountyList(data.StateAbbr).done(function (result) {
                                countyList(result);
                                subjectPropertyModel.county(selectedCounty);
                            });
                            subjectPropertyModel.city(data.City);
                            subjectPropertyModel.state(data.StateAbbr);

                            if (propertyInfoPopulateFromSession() === true && session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null && session.orderForPropertyAddress.city() != undefined && session.orderForPropertyAddress.city() != null && session.orderForPropertyAddress.city() != "") {
                                subjectPropertyModel.city(session.orderForPropertyAddress.city());
                                propertyInfoPopulateFromSession(false);
                            }
                            else if (propertyInfoPopulateFromRecentSearch() === true) {
                                propertyInfoPopulateFromRecentSearch(false);
                            }
                            else {

                            }
                        }
                        else {
                            alert.error("Invalid Zip.")
                            subjectPropertyModel.zip('')
                            subjectPropertyModel.city('')
                            subjectPropertyModel.state('')
                            subjectPropertyModel.county('');
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })
            }
        })
        subjectPropertyModel.buyerZip.subscribe(function (zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            subjectPropertyModel.buyerCity(data.City)
                            subjectPropertyModel.buyerState(data.StateAbbr)
                        }
                        else {
                            alert.error("Invalid Zip.")
                            subjectPropertyModel.buyerZip('')
                            subjectPropertyModel.buyerCity('')
                            subjectPropertyModel.buyerState('')
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })
            }
        })
        subjectPropertyModel.sellerZip.subscribe(function (zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            subjectPropertyModel.sellerCity(data.City)
                            subjectPropertyModel.sellerState(data.StateAbbr)
                        }
                        else {
                            alert.error("Invalid Zip.")
                            subjectPropertyModel.sellerZip('')
                            subjectPropertyModel.sellerCity('')
                            subjectPropertyModel.sellerState('')
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })
            }
        })

        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            goBack: goBack,
            title: 'Colorado Title Order',

            orderTitle: orderTitle,

            currentView: currentView,
            currentOrderModels: currentOrderModels,
            currentViewIndex: currentViewIndex,
            currentOrderModel: currentOrderModel,
            selectedOrderTypeName: selectedOrderTypeName,

            moveNext: moveNext,
            movePrevious: movePrevious,
            submitOrder: submitOrder,
            navigateButtonClick: navigateButtonClick,
            commonDialogModel: commonDialogModel,

            customerInformationModel: customerInformationModel,
            subjectPropertyModel: subjectPropertyModel,
            typeofPolicyInfoModel: typeofPolicyInfoModel,
            referralInfoModel: referralInfoModel,
            endorsementInfoModel: endorsementInfoModel,
            submitOrderModel: submitOrderModel,
            getUSPhoneFormatWithExtn: utlities.getUSPhoneFormatWithExtn,
            getUSPhoneFormat: utlities.getUSPhoneFormat,
            stateList: stateList,
            countyList: countyList,
            endorsementsList: endorsementsList,
            escrowLocationsList: escrowLocationsList,
            policyTypeList: policyTypeList,
            session: session,
            compositionComplete: compositionComplete,
            recentSearches: recentSearches,
            recentResult_click: recentResult_click,
            recentSearchSelectedAPN: recentSearchSelectedAPN,
            clearSelectedProperty: clearSelectedProperty,
            compositionComplete: function () {
                session.handleFocusOnTabChange(customerInformationModel.ModelId, customerInformationModel.viewName);
            }
        }
        return vm;

        function activate(orderType) {
            recentSearches(session.getRecentSearchesFromLocalStorage());
            orderSubmitted(false);
            selectedOrderType(parseInt(orderType));
            setupOrder();
            currentViewIndex(0);

            utlities.getStatesList().done(function (result) {
                stateList([]);
                stateList(result);

            });

            utlities.getEndorsements().done(function (result) {
                endorsementsList([]);
                endorsementsList(result);

            });

            utlities.getEscrowLocations().done(function (result) {
                escrowLocationsList([]);
                escrowLocationsList(result);

            });

            utlities.getPolicyTypes().done(function (result) {
                policyTypeList([]);
                policyTypeList(result);

            });
            session.isBusy(false);
            return getCustomerInfo(session.userId, session.defaultOpertionId());
        }

        function canDeactivate() {
            if (!session.hasLoggedOut() && !orderSubmitted()) {
                var title = 'Cancel Order?';
                var msg = 'Navigate away and cancel the order? ';
                session.isLogoutVisible(false);
                return app.showMessage(msg, title, ['Yes', 'No '])
                    .then(function (selectedOption) {
                        if (selectedOption === 'Yes') {
                            // Call some cancel function
                            recentSearchSelectedAPN('');
                            session.clearOrderForPropertyAddress();
                            subjectPropertyModel.reset();
                        }
                        session.isLogoutVisible(true);
                        return selectedOption;
                    });
            }
            else {
                recentSearchSelectedAPN('');
                session.clearOrderForPropertyAddress();
                subjectPropertyModel.reset();
                return true;
            }
        };

        function recentResult_click(result) {
            recentSearchSelectedAPN(result.APN);
            propertyInfoPopulateFromSession(false);
            subjectPropertyModel.address(result.Address);
            subjectPropertyModel.city(result.City);

            if (subjectPropertyModel.zip() != result.ZIP.slice(0, 5)) {
                propertyInfoPopulateFromRecentSearch(true);
                subjectPropertyModel.zip(result.ZIP.slice(0, 5));
            }
            else
                propertyInfoPopulateFromRecentSearch(false);
        }
        function clearSelectedProperty() {
            recentSearchSelectedAPN('');
            subjectPropertyModel.resetPropertyAddress();
        }

        function setupOrder() {
            currentOrderModels([]);
            currentOrderModels.push(customerInformationModel);
            currentOrderModels.push(referralInfoModel);
            currentOrderModels.push(typeofPolicyInfoModel);
            currentOrderModels.push(endorsementInfoModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(submitOrderModel);
            resetModels();
        }

        function moveNext() {
            // commented for development, this need to be removed.
            vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
            if (vm.validationErrors().length > 0) {
                vm.validationErrors.showAllMessages();
                return;
            }
            currentViewIndex(currentViewIndex() + 1);
            if (currentViewIndex() == currentOrderModels().length - 1)
                buildPreviewModel();
        }

        function movePrevious() {
            currentViewIndex(currentViewIndex() - 1);
        }

        function navigateButtonClick(item, event) {
            if (item.index() > currentViewIndex()) {

                for (var i = 0; i < item.index(); i++) {
                    // commented for development, this need to be removed.
                    vm.validationErrors = ko.validation.group(currentOrderModels()[i]);
                    if (vm.validationErrors().length > 0) {
                        vm.validationErrors.showAllMessages();
                        return;
                    }

                }
            }
            currentViewIndex(item.index());
            if (currentViewIndex() == currentOrderModels().length - 1)
                buildPreviewModel();

        }

        function goBack(complete) {
            router.navigateBack();
        }

        function compositionComplete() {
            if (session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null) {
                propertyInfoPopulateFromSession(true);
                subjectPropertyModel.address(session.orderForPropertyAddress.address());
                subjectPropertyModel.zip(session.orderForPropertyAddress.zip())
            }
        }

        function resetModels() {
            for (var i = 0; i < currentOrderModels().length; i++) {
                currentOrderModels()[i].index(i);

                // skip order information view reset
                if (i > 0)
                    currentOrderModels()[i].reset();
            }
        }
        function getCustomerInfo(userId, opertionId) {
            return utlities.getOperationUserInfo(userId, opertionId).done(function (data) {
                var currdate = new Date();
                var currdate = (currdate.getMonth() + 1) + '/' + currdate.getDate() + '/' + currdate.getFullYear();
                customerInformationModel.orderDate(currdate);
                utlities.getManagerFullName(data.OperationId, data.UserId).done(function (result) {
                    customerInformationModel.salesRep(result);
                })
                customerInformationModel.fullName(data.FirstName + String.fromCharCode(160) + data.LastName);
                customerInformationModel.email(data.Email);
                customerInformationModel.company(data.Company);
                customerInformationModel.address(data.StreetAddress1 + String.fromCharCode(160) + data.StreetAddress2 + String.fromCharCode(160) + data.City + ',' + String.fromCharCode(160) + data.State + String.fromCharCode(160) + data.Zip);
                customerInformationModel.phon(data.PhoneWork);
                customerInformationModel.ext(data.PhoneWorkExt);
                customerInformationModel.fax(data.PhoneFax);

            })

        }

        function buildPreviewModel() {
            for (var i = 0; i < currentOrderModels().length; i++) {
                if (currentOrderModels()[i].GetModelData) {
                    currentOrderModels()[i].GetModelData();
                }
            }
        }


        function submitOrder() {
            session.isBusy(true);
            console.log(session.isBusy());
            console.log('Colorado Title Order submitted');
            var url = config.SubmitColoradoTitleOrderUrl;
            var request = {

                //Order Model Information -- Required
                "BrandId": session.brandId,
                "OrderId": "-1",
                "UserId": session.userId,
                "ServiceId": "66",
                "OperationId": session.defaultOpertionId(),
                "CreateDate": new Date(),

                //Order Model Information -- Fill If Present

                "BackendSystem": "",
                "EscrowOfficerId": "",
                "TitleOfficerId": "",
                "IsBackendOrder": "",
                "PaymentId": "",

                //Customer Information

                "SalesRep": customerInformationModel.salesRep,
                "UserFullName": customerInformationModel.fullName,
                "UserEmail": customerInformationModel.email,
                "UserCompanyName": customerInformationModel.company,

                "UserAddress":
                {
                    "Address1": customerInformationModel.address(),
                    "Address2": "",
                    "City": "",
                    "County": "",
                    "State": "",
                    "Zip": "",
                    "Zip4": ""
                },
                "UserPhone":
                {
                    "PhoneNumber": customerInformationModel.phon(),
                    "PhoneType": "Business",
                    "Extension": customerInformationModel.ext()
                },
                "UserFax":
                {
                    "PhoneNumber": customerInformationModel.fax(),
                    "PhoneType": "Fax",
                    "Extension": ""
                },

                //Subject Property Information

                "PropertyType": subjectPropertyModel.typeOfProperty,

                "PropertyAddress": subjectPropertyModel.address,
                "PropertyZip": subjectPropertyModel.zip,
                "PropertyCity": subjectPropertyModel.city,
                "PropertyState": subjectPropertyModel.state,
                "PropertyCounty": subjectPropertyModel.county,

                "BorrowerName": subjectPropertyModel.buyerName,
                "BorrowerAddress": subjectPropertyModel.buyeraddress,
                "BorrowerZip": subjectPropertyModel.buyerZip,
                "BorrowerCity": subjectPropertyModel.buyerCity,
                "BorrowerState": subjectPropertyModel.buyerState,

                "SellerName": subjectPropertyModel.sellerName,
                "SellerAddress": subjectPropertyModel.sellerAddress,
                "SellerZip": subjectPropertyModel.sellerZip,
                "SellerCity": subjectPropertyModel.sellerCity,
                "SellerState": subjectPropertyModel.sellerState,

                "ChicagoToClose": subjectPropertyModel.chicagoToClose,
                "EscrowLocation": subjectPropertyModel.selectedEscrowLocation,

                //Referral Information

                "ListingOffice": referralInfoModel.listingOffice,
                "ListingAgentName": referralInfoModel.agentName1,
                "SellingOffice": referralInfoModel.sellingOffice,
                "SellingAgentName": referralInfoModel.agentName2,
                "ReferralType": referralInfoModel.typeOfReferral,

                //Type of Policy Information 

                "PolicyType": "A.L.T.A. Owner's",
                "LoanAmount": typeofPolicyInfoModel.loanAmount,
                "SaleAmount": typeofPolicyInfoModel.saleAmount,

                //Endorsement

                "EndorsementNeeded": endorsementInfoModel.selectedEndorsement
            }

            request.NoBroker = referralInfoModel.typeOfReferral() == "No Broker";
            request.Escrow = referralInfoModel.typeOfReferral() == "Escrow";
            request.Lender = referralInfoModel.typeOfReferral() == "Lender";

            https.post(url, request)
                .then(function (data) {
                    if (data.HasErrors == false) {
                        orderSubmitted(true);
                        commonDialogModel.IsOrderDialog(true);
                        commonDialogModel.orderId(data.OrderId);
                        if (data.IntendedRecpients)
                            commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                        commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                    }
                    //else {
                    //    for (var i = 0; i < data.Messages.length; i++) {
                    //        if (data.Messages[i].ExceptionMessage != "" || data.Messages[i].Messages != "") {
                    //            console.log("Error:" + data.Messages[i].ExceptionMessage, orderTitle());
                    //            alert.error('Internal Error. Please contact Administrator.');
                    //            orderSubmitted(false);
                    //            session.isBusy(false);
                    //        }
                    //    }
                    //}
                    else {
                        orderSubmitted(false);
                        session.isBusy(false);
                        var msgSuccess = "";
                        var msgError = "";

                        for (var i = 0; i < data.Messages.length; i++) {
                            if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                                //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                                //alert.error('Internal Error. Please contact Administrator.');
                                if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                    msgError = msgError + data.Messages[i].Message + "\n";
                                }
                            }
                            else {
                                msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                            }
                        }
                        //alert.error(msgSuccess, orderTitle());
                        //alert.error(msgError, orderTitle());
                        if (msgSuccess.length > 0) {
                            orderSubmitted(true);
                            commonDialogModel.IsOrderDialog(true);
                            commonDialogModel.orderId(data.OrderId);
                            msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                            commonDialogModel.messagehtml(msgError);
                            if (data.IntendedRecpients)
                                commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                            commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                        }
                        else if (msgError.length > 0) {
                            alert.error(msgError, orderTitle());
                        }

                    }
                })
                .fail(function (data) {
                    var error = JSON.parse(data.responseText)
                    console.log(error);
                    session.isBusy(false);
                })
        }


    });
