define('models/officeLocator/officeLocatorModel',["knockout", 'utlities'],
    function (ko, utlities) {

        function OfficeLocator() {
            var self = this;

            self.state = ko.observable(undefined)
            .extend({ required: { params: true, message: 'State is required.' } });
            self.stateList = ko.observableArray([]);

            self.county = ko.observable('All Counties')
            .extend({ required: { params: true, message: 'County is required.' }, notify: 'always' });
            self.countyList = ko.observableArray([]);

            self.office = ko.observable(undefined)
            .extend({ required: { params: true, message: 'Office is required.' } });
            self.officeList = ko.observableArray([]);
            self.officeName = function (item) {
                for (var i = 0; i < self.officeList().length; i++) {
                    var stateKeyValue = self.officeList()[i];
                    if (stateKeyValue.Key === item) {
                        return stateKeyValue.Value;
                    }
                }
            };

            self.noOfficeMessage = ko.observable("").extend({ notify: 'always'});

            self.reset = function () {
                self.state(undefined);
                self.county('All Counties');
                self.countyList({ Key: 0, Value: 'All Counties' });
                self.office(undefined);
                self.noOfficeMessage("");
                ko.validation.group(self).showAllMessages(false);
            }

            self.showAllValdiationMessages = function (trueFalse) {
                if (trueFalse !== null && trueFalse !== undefined)
                    ko.validation.group(self).showAllMessages(trueFalse);
            };
        }

        function OfficeLocatorDetails() {
            var self = this;

            self.address1 = ko.observable("");
            self.address2 = ko.observable("");
            self.city = ko.observable("");
            self.stateName = ko.observable("");
            self.county = ko.observable("");
            self.officeLink = ko.observable("");
            self.zip = ko.observable("");
            self.stateAbbr = ko.observable("");
            self.officeName = ko.observable("");
            self.photoName = ko.observable("");
            self.photoUrl = ko.observable("");
            self.departments = ko.observable([]);
            self.officeEmployeeInfo = ko.observable();
            self.googlesErrorMsg = ko.observable("");

            self.fullAddress = ko.computed(function () {
                if(self.address1() != undefined || self.address1() != '') {
                    self.address1(self.address1() + ' ');
                }

                if (self.address2() != undefined || self.address2() != '') {
                    self.address2(self.address2() + ' ');
                }
                
                return self.address1().trim() + ' ' + self.address2().trim() + ' ' + self.city().trim() + ', ' + self.stateAbbr().trim() + ' ' + self.zip().trim();
            }, self);

            self.phone = ko.observable("");
            self.phoneNo = ko.computed(function () {

                if (self.phone() && self.phone().length > 0) {
                    return utlities.getUSPhoneFormat(self.phone());
                }
                else
                    return "Not Available";
            });

            self.tollFree = ko.observable("");
            self.tollFreeNo = ko.computed(function () {

                if (self.tollFree() && self.tollFree().length > 0) {
                    return utlities.getUSPhoneFormat(self.tollFree());
                }
                else
                    return "";
            });

            self.fax = ko.observable("");
            self.faxNo = ko.computed(function () {

                if (self.fax() && self.fax().length > 0) {
                    return utlities.getUSPhoneFormat(self.fax());
                }
                else
                    return "Not Available";
            });

            self.reset = function () {
                self.address1("");
                self.address2("");
                self.city("");
                self.stateName("");
                self.county("");
                self.officeLink("");
                self.zip("");
                self.stateAbbr("");
                self.tollFree("");
                self.fax("");
                self.phone("");
                self.officeEmployeeInfo();
                self.photoName("");
                self.photoUrl("");
                self.departments([]);
                self.officeName("");
            }
        }

        function OfficeEmployeeInfo() {
            var self = this;
            self.fullName = ko.observable("");
            self.title = ko.observable("");
            self.department = ko.observable("");
            self.office = ko.observable("");
            self.email = ko.observable("");
            self.photoName = ko.observable("");
            self.photoUrl = ko.observable("");
            self.employeeType = ko.observable("");


            self.phone = ko.observable("");
            self.phoneNo = ko.computed(function () {

                if (self.phone() && self.phone().length > 0) {
                    return utlities.getUSPhoneFormat(self.phone());
                }
                else
                    return "Not Available";
            });

            self.fax = ko.observable("");
            self.faxNo = ko.computed(function () {

                if (self.fax() && self.fax().length > 0) {
                    return utlities.getUSPhoneFormat(self.fax());
                }
                else
                    return "Not Available";
            });

            self.computedTitle = ko.computed(function () {

                if (self.title() && self.title().length > 0) {
                    return self.title();
                }
                else
                    return self.employeeType();
            });

            self.reset = function () {
                self.fullName("");
                self.title("");
                self.department("");
                self.office("");
                self.phone("");
                self.fax("");
                self.email("");
                self.photoUrl("");
                self.photoName("");
            }

        }

        
        var vm = {
            OfficeLocator: OfficeLocator,
            OfficeLocatorDetails: OfficeLocatorDetails,
            OfficeEmployeeInfo: OfficeEmployeeInfo
        }
        vm.validationErrors = ko.validation.group([vm.OfficeLocator]);

        return vm;
    }
);
