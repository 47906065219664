define('viewmodels/userModule/inHereVerifyCode',['plugins/router', 'Q', 'durandal/app', 'services/security', 'knockout', 'jquery', 'plugins/http', 'global/session', 'config', 'alertNotification', 'migrationModel', 'jquerycapslock', 'services/logger', 'register'],
    function (router, Q, app, security, ko, $, https, session, config, alerter, migrationModel, jquerycapslock, logger, registerModule) {
        var migrationModel = new migrationModel.migrate();
        var validationCodeError = ko.observable('');
        var validationCodeAttempts = ko.observable(0);
        var isResetting = ko.observable(false);


        var vm = {
            activate: activate,
            session: session,
            setFocus: ko.observable(true),
            migrationModel: migrationModel,
            sendcode: sendcode,
            handleKeyUp: handleKeyUp,
            validationCodeError: validationCodeError,
            onBlurText: onBlurText,
            session: session,
            showError: showError,
            cancelUpgrade: cancelUpgrade,
            validationCodeAttempts: validationCodeAttempts,
            isResetting: isResetting,
            register: register

        };

        vm.validationErrors = ko.validation.group([vm.migrationModel.validationCode]);

        return vm;

        function onBlurText() {
            validationCodeError('');
        }

        function handleKeyUp(d, e) {
            if (e.keyCode == 13) {
                sendcode();
            }
        }



        //function activate(Uid) {

        //    var uid = Uid.replace(':', '');
        //    session.oneTimeCodeUid(uid);
        //}

        function activate() {


        }

        function register() {
            registerModule.resetForm();
            router.navigate('register', { replace: true, trigger: true });
        }

        function sendcode() {
            if (isResetting() == true) {
                return;
            }

            validationCodeError('');

            $("#mdProcessing").modal({ backdrop: 'static', keyboard: false });

            if ($('#ValidationCode').val().length > 0) {
                vm.migrationModel.userName(session.inHereUpgradeUserEmail());
                vm.migrationModel.validationCode($('#ValidationCode').val());

            }

            session.isBusy(true);
            session.isAjaxPost(true);
            session.clearAccessToken();
            session.clearACSFToken();
            session.clearRecentSearchesFromLocalStorage();
            validationCodeError('');


            var request = {
                Email: session.inHereUpgradeUserEmail(),
                UidCode: session.oneTimeCodeUid(),
                Code: vm.migrationModel.validationCode()
            }

            var upgradeUrl = config.registerOktaUserUrl;


            https.post(upgradeUrl, request)
                .done(function (data) {

                    if (data.CodeVerified == true) {
                        validationCodeError('');
                        session.oktaActivationUrl(data.ActivationUrl);
                        
                        $("#mdProcessing").modal('hide');
                        if (data.ExistingUserAddedToGroup) {
                            router.navigate('inHereFinishExisting', { replace: true, trigger: true });
                        }
                        else {
                            router.navigate('inHereFinishNew', { replace: true, trigger: true });
                        }
                    }

                    if (data.CodeVerified == false && validationCodeAttempts() < 3) {
                        validationCodeError("Unable to proceed, your validation code has been used, is expired or is incorrect.");
                        $("#mdProcessing").modal('hide');
                        validationCodeAttempts(validationCodeAttempts() + 1);
                        return;
                    }
                    else if (validationCodeAttempts() >= 3) {
                        validationCodeError("Too many attempts.  Begin again at Sign In");
                        $("#mdProcessing").modal('hide');

                        /*if (isResetting == false) {*/
                        isResetting(true);
                        $("#LoginUserName").attr("readonly", true);
                        $('#submit').attr('disable', true);
                        setTimeout(function () {
                                
                            cancelUpgrade();

                        }, 6000);
                    }
                })
                .fail(function (data) {
                    session.isBusy(false);
                    /*alerter.error("There was an error sending the upgrade code email. Please try again.", { timeOut: 0 });*/
                })
                .always(function () {
                    session.isBusy(false);
                });

        }


        function showError(err) {
            const errorEl = document.createElement('div');
            errorEl.textContent = 'Error! ' + err.error_description;
            document.body.insertBefore(
                errorEl,
                document.body.firstChild
            );
        }

        function cancelUpgrade() {

            window.location = window.location.origin;

        }
    });

        
    
