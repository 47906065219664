define('offLineTitleInformationModels',["knockout", "modelMetaData"],
    function (ko, modelMetaData) {
        function TypeofInformationRequested() {
            var self = this;

            self.viewName = "orderModule/offline/_typeofInformationRequested.html"
            self.title = "Type of Information Requested"
            self.ModelId = "TypeofInformationRequested"
            self.index = ko.observable(-1)

            self.propertyCharacteristics = ko.observable("")
            self.salesComps = ko.observable("")
            self.platMaps = ko.observable("")
            self.deed = ko.observable("")
            self.deedsofTrust = ko.observable("")
            self.nod = ko.observable("")
          
            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.propertyCharacteristics(data.propertyCharacteristics())
                    self.salesComps(data.salesComps())
                    self.platMaps(data.platMaps())
                    self.deed(data.deed())
                    self.deedsofTrust(data.deedsofTrust())
                    self.nod(data.nod())
                }

            };

            self.reset = function () {
                self.propertyCharacteristics('')
                self.salesComps('')
                self.platMaps('')
                self.deed('')
                self.deedsofTrust('')
                self.nod('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Characteristics (Profile)", modelMetaData.reportBooleanValue(self.propertyCharacteristics())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Sales Comps", modelMetaData.reportBooleanValue(self.salesComps())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Plat Maps", modelMetaData.reportBooleanValue(self.platMaps())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Deed", modelMetaData.reportBooleanValue(self.deed())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Deeds of Trust", modelMetaData.reportBooleanValue(self.deedsofTrust())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("NOD (Notice of Default)", modelMetaData.reportBooleanValue(self.nod())));
            }

        }

        function SearchInformation() {
            var self = this;

            self.viewName = "orderModule/offline/_searchInformation.html"
            self.title = "Search Information"
            self.ModelId = "SearchInformation"
            self.index = ko.observable(-1)

            self.county = ko.observable("")
            self.parcelNumber = ko.observable("")
            self.ownerName = ko.observable("")
            self.propertyAddress = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Property Address must be 200 characters or less' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.street = ko.observable("")
            .extend({ required: { message: 'Street is required.' } })
            self.city = ko.observable(undefined)
            .extend({ required: { message: 'City is required.' } })
            self.state = ko.observable(undefined)
             .extend({ required: { params: true, message: 'State is required.' } });
              
            self.zipCodes = ko.observable("")
                .extend({ required: { message: 'Zip is required.' } })
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.propertyInformation = ko.observable("")
            self.deliveryMethod = ko.observable("")
            //E-mail delivery may not yet be available in all regions, check with your local sales rep.
            self.message= ko.observable("")
            self.deliveryList = ko.observableArray([
    { key: "Fax", value: "Fax" },
     { key: "Messenger", value: "Messenger" },
    { key: "E-mail", value: "E-mail" },

            ]);
            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.county(data.county)
                    self.parcelNumber(data.parcelNumber)
                    self.ownerName(data.ownerName)
                    self.propertyAddress(data.propertyAddress)
                    self.street(data.street)
                    self.city(data.city)
                    self.state(data.state)
                    self.zipCodes(data.zipCodes)
                    self.propertyInformation(data.propertyInformation)
                    self.deliveryMethod(data.deliveryMethod)
                    //E-mail delivery may not yet be available in all regions, check with your local sales rep.
                    self.message(data.message)
                }

            };

            self.reset = function () {
                self.county('')
                self.parcelNumber('')
                self.ownerName('')
                self.propertyAddress('')
                self.street('')
                self.city(undefined)
                self.state(undefined)
                self.zipCodes('')
                self.propertyInformation('')
                self.deliveryMethod('')
                //E-mail delivery may not yet be available in all regions, check with your local sales rep.
                self.message('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.resetPropertyAddress = function () {
                self.county('')
                self.parcelNumber('')
                self.street('')
                self.city(undefined)
                self.state(undefined)
                self.zipCodes('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Parcel Number", self.parcelNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Owner's Name", self.ownerName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip", self.zipCodes()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("County", self.county()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Street", self.street()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Address", self.propertyAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Information", modelMetaData.reportBooleanValue(self.propertyInformation())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Delivery Method", self.deliveryMethod()));
            }

        }
      
        var vm = {
            TypeofInformationRequested: TypeofInformationRequested,
            SearchInformation: SearchInformation
        }

        return vm;
    });
