define('viewmodels/addFundsToWallet/addFundsToWallet',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'utlities', 'paymentProcessModel', 'commonDialogModel'],
 function (app, router, $, ko, validate, iCheck, config, validation, Q, https, session, alert, utlities, paymentProcessModel, commonDialog) {

     var paymentModeView = ko.observable('paymentProcess/_cardInfo.html');
     var creditCardInformationModel = new paymentProcessModel.CreditCardInformation();
     var amountToAdd = ko.observable(0.0)
                        .extend({ required: { message: 'Please enter amount.' } })
                         .extend({
                             pattern: {
                                 params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                                 message: 'Invalid Amount. (format: ###.##)'
                             }
                         })
                        .extend({ max: { params: 200.00, message: "Max amount : $200.00" } })
                        .extend({ min: { params: 1.00, message: "Min amount : $1.00" } });


     var amountToAddString = ko.computed(function () {
         return '$' + parseFloat(amountToAdd()).toFixed(2);
     });


     var commonDialogModel = new commonDialog("AddFundsToWallet");

     function activate() {
         reset();
         return true;
     };

     function calculateTotal() {
         return true;
     }
     function canDeactivate() {
         window.history.pushState(null, "", window.location.href);
         window.onpopstate = function () {
             window.history.pushState(null, "", window.location.href);
             return false;
         };
         return true;
     };

     function reset() {
         creditCardInformationModel.reset();
         amountToAdd(0.0);
         return true;
     }

     function AddFundsToWallet() {

         if (vm.errors != null && vm.errors != 'undefined' && vm.errors().length > 0) {
             vm.errors.showAllMessages();
             return;
         }
         $('#addFundsToWallet').modal('hide');

         var request = {
             "Products": null,
             "PropertyId": null,
             "UserId": session.userId,
             "OperationId": session.defaultOpertionId(),
             "BrandId": session.brandId,
             "CardHolderAddress":
             {
                 "Address1": creditCardInformationModel.billingAddress(),
                 "Address2": null,
                 "City": creditCardInformationModel.city(),
                 "State": creditCardInformationModel.state(),
                 "County": '',
                 "CountyIsOther": false,
                 "Zip": creditCardInformationModel.zip(),
                 "Zip4": null,
                 "Apn": null,
                 "Fips": null,
                 "Latitude": null,
                 "Longitude": null,
             },
             "PaymentTransaction":
             {
                 "PaymentType": 0,
                 "CardNumber": creditCardInformationModel.cardNumber,
                 "CardExpirationDate": creditCardInformationModel.expirationYear() + '-' + creditCardInformationModel.expirationMonth() + '-' + '01',
                 "CardCVS": creditCardInformationModel.verificationCodeCVC,
                 "NameOnCard": creditCardInformationModel.nameOnCard,
                 "ChargeAmount": amountToAdd,
             },

             "AmountToAdd": amountToAdd
         };

         var url = config.addFundsToWalletUrl;
         session.isBusy(true);
         return https.post(url, request)
         .then(function (data) {
             session.isBusy(false);
             if (data.HasErrors == false) {

                 switch (data.Status) {
                     case 10://Success
                         if (data.Messages.length > 0) {
                             var messageObj = data.Messages.filter(function (i) {
                                 return i.Level === 0;
                             });
                             var messageText = "";
                             $.each(messageObj, function (i, value) {
                                 messageText = messageText + " " + value.Message;
                             });
                             commonDialogModel.IsOrderDialog(false);
                             commonDialogModel.show("Add Funds To Wallet", messageText.trim());
                         }
                         else {
                             commonDialogModel.IsOrderDialog(false);
                             commonDialogModel.show("Add Funds To Wallet", "Funds were added to your wallet successfully");
                         }
                         break;

                     case 20: alert.error(data.StatusMessage, "Add Funds To Wallet");//UnableToProcessPayment
                         alert.error("Unable To ProcessPayment : Error while adding funds to your wallet.", "Add Funds To Wallet");
                         break;
                     case 30: alert.error("No Available Products? Error while adding funds to your wallet.", "Add Funds To Wallet");//NoAvailableProducts
                         break;
                     case 0://UnKnown
                     case 100://Failed
                     default: alert.error("Error while adding funds to your wallet.", "Add Funds To Wallet");
                         break;
                 }
             }
             else {
                 if (data.Messages.length > 0) {
                     for (var i = 0; i < data.Messages.length; i++) {
                         alert.error('Error while adding funds to your wallet : ' + data.Messages[i].Message, "Add Funds To Wallet");
                     }
                 }
                 else {
                     alert.error("Error while adding funds to your wallet.", "Add Funds To Wallet");
                 }
             }
             reset();
         })
         .fail(function () {
             alert.error('Error while adding funds to your wallet', "Add Funds To Wallet");
             session.isBusy(false);
             reset();
         });
         reset();
     }

     function Cancel() {
         reset();
         //router.navigateBack();
     }

     function placeOrder() {
         if (vm.creditCardValidationErrors != null && vm.creditCardValidationErrors != 'undefined' && vm.creditCardValidationErrors().length > 0) {
             vm.creditCardValidationErrors.showAllMessages();
             if (vm.errors != null && vm.errors != 'undefined' && vm.errors().length > 0) {
                 vm.errors.showAllMessages();
             }
             $('#addFundsToWallet').modal('hide');
             return;
         }
         if (vm.errors != null && vm.errors != 'undefined' && vm.errors().length > 0) {
             vm.errors.showAllMessages();
             $('#addFundsToWallet').modal('hide');
             return;
         }
         $('#addFundsToWallet').modal('show');
     }

     function compositionComplete() {
         session.validateInput();
     }

     var vm = {
         session: session,
         activate: activate,
         canDeactivate: canDeactivate,
         title: 'Order Summary',
         paymentModeView: paymentModeView,
         creditCardInformationModel: creditCardInformationModel,
         AddFundsToWallet: AddFundsToWallet,
         Cancel: Cancel,
         amountToAdd: amountToAdd,
         placeOrder: placeOrder,
         commonDialogModel: commonDialogModel,
         amountToAddString: amountToAddString,
         compositionComplete: compositionComplete
     }
     vm.creditCardValidationErrors = ko.validation.group([vm.creditCardInformationModel]);
     vm.errors = ko.validation.group([vm]);

     return vm;


     function Failed(error) {
         alert.error(error.statusText);
     }
 });
