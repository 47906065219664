define('viewmodels/productServices/productOrdersSearch',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'config', 'alertNotification', 'commonDialogModel', 'productOrderModel', 'utlities',  'koPaging', 'moment', 'orderCommon'],
    function (app, router, $, ko, validate, iCheck, validation, Q, https, session, config, alert, commonDialog, model, utlities,  koPaging, moment, orderCommon) {
        var productOrdersSearchModel = new model.ProductOrdersSearch();
        var productOrdersResultsModel = new model.ProductOrdersResults();
        var productOrdersDetailsModel = new model.ProductOrdersDetails();
        var searchResults = ko.observableArray([]);
        var orderDetail = ko.observableArray([]);
        var dateError = ko.observable(false);
        var fiteredCount = ko.observable(0);

        var gridParams = {
            pageIndex: ko.observable(1),
            pageSize: ko.observable(10),
            totalRows: ko.observable(0),
            totalPages: ko.observable(0),
            requestedPage: ko.observable(0),
        };

        //var dataRows = ko.observableArray();
        var selectedPageSizeOption = ko.observable(gridParams.pageSize);
        gridParams.requestedPage.subscribe(function (newVal) {
            flipPageDirect(newVal);

        });
        selectedPageSizeOption.subscribe(function () {
            changePageSize();
        });

        productOrdersSearchModel.fromDate.subscribe(function () {
            validateDate();
        });

        productOrdersSearchModel.toDate.subscribe(function () {
            validateDate();
        });

        function validateDate() {
            if (moment(productOrdersSearchModel.fromDate()) > moment(productOrdersSearchModel.toDate()))
                dateError(true);
            else
                dateError(false);
        }

        // Server Side Pagging
        //function DataGridAjax(url, pageSize) {
        //    var self = this;
        //    getDataUrl = url;

        //    self.SelectedPageSizeOption = ko.observable(pageSize);
        //    self.GridParams.requestedPage.subscribe(self.FlipPageDirect, self);
        //    self.SelectedPageSizeOption.subscribe(self.ChangePageSize, self);
        //}

        // function getData () {
        //    var self = this;
        //    RIT.eW.Services.AjaxPostCall(getDataUrl, self.GridParams, $.proxy(self.OnGetDataDone, this));
        //};
        //DataGridAjax.prototype.OnGetDataDone = function (data) {
        //    var self = this;
        //    self.DataRows(RIT.eW.Utils.GetJson(data.result));
        //    self.GridParams.totalRows(RIT.eW.Utils.GetJson(data.totalRows));
        //    var totalPages = Math.ceil(self.GridParams.totalRows() / self.GridParams.pageSize());
        //    self.GridParams.totalPages(totalPages);
        //    self.GridParams.requestedPage(self.GridParams.pageIndex());
        //};
        function searchOrders() {

            if (dateError())
                return;

            var url = config.ecommerceOrderSearchUrl;
            var param = {

                "TransactionConfirmationNumber": productOrdersSearchModel.confirmation,
                "OrderDateRangeStart": productOrdersSearchModel.fromDate,
                "OrderDateRangeEnd": productOrdersSearchModel.toDate,
                "UserId": session.userId,
                "OperationId": session.defaultOpertionId(),
                "BrandId": session.brandId,
                "PageNumber": gridParams.pageIndex,
                "PageSize": gridParams.pageSize

            }

            return https.post(url, param)
                .then(function (data) {
                    searchResults([]);
                    productOrdersResultsModel.totalRecordsFound(data.TotalRecordsFound);
                    //fiteredCount(data.TotalRecordsFound);
                    console.log('data.TotalRecordsFound ' + data.TotalRecordsFound);
                    var converted = loadResultToKOArray(data);
                    searchResults(converted);
                    gridParams.totalRows(data.TotalRecordsFound);
                    var totalPages = Math.ceil(gridParams.totalRows() / gridParams.pageSize());
                    gridParams.totalPages(totalPages);
                    gridParams.requestedPage(gridParams.pageIndex());

                })
                .fail(orderCommon.Failed);

        }

        //function flipPage(newPageNo) {
        //   if (parseInt(newPageNo) > 0 && parseInt(newPageNo) <= gridParams.totalPages()) {
        //        gridParams.pageIndex(newPageNo);
        //        self.searchOrders();
        //    }
        //};

        function firstPage() {
            var newPageNo = 1;

            if (parseInt(newPageNo) > 0 && parseInt(newPageNo) <= gridParams.totalPages()) {
                gridParams.pageIndex(newPageNo);
                searchOrders();
            }
        };
        function previousPage(newPageNo) {
            var newPageNo = gridParams.pageIndex() - 1;
            if (parseInt(newPageNo) > 0 && parseInt(newPageNo) <= gridParams.totalPages()) {
                gridParams.pageIndex(newPageNo);
                searchOrders();
            }
        };
        function nextPage(newPageNo) {
            var newPageNo = gridParams.pageIndex() + 1;
            if (parseInt(newPageNo) > 0 && parseInt(newPageNo) <= gridParams.totalPages()) {
                gridParams.pageIndex(newPageNo);
                searchOrders();
            }
        };

        function lastPage(newPageNo) {
            var newPageNo = gridParams.totalPages()
            if (parseInt(newPageNo) > 0 && parseInt(newPageNo) <= gridParams.totalPages()) {
                gridParams.pageIndex(newPageNo);
                searchOrders();
            }
        };

        function flipPageDirect(newValue) {
            var ri = parseInt(gridParams.requestedPage());
            if (ri == NaN) {
                gridParams.requestedPage(gridParams.pageIndex());
                return;
            }
            if (ri > 0 && ri <= gridParams.totalPages()) {
                gridParams.pageIndex(ri);
                searchOrders();
                return;
            }
            gridParams.requestedPage(gridParams.pageIndex());
            return;
        };
        function changePageSize() {
            if (gridParams.pageSize() != selectedPageSizeOption()) {
                gridParams.pageSize(selectedPageSizeOption());
                gridParams.pageIndex(1);
                gridParams.requestedPage(1);
                searchOrders();
            }
        };
        //DataGridAjax.prototype.Sort = function (col) {
        //    var self = this;
        //    if (self.GridParams.sortField() === col) {
        //        if (self.GridParams.sortOrder() === 'ASC') {
        //            self.GridParams.sortOrder('DESC');
        //        } else {
        //            self.GridParams.sortOrder('ASC');
        //        }
        //    } else {
        //        self.GridParams.sortOrder('ASC');
        //        self.GridParams.sortField(col);
        //    }
        //    self.GetData();
        //};


        function activate() {
            //vm.validationErrors = ko.validation.group([productOrdersSearchModel]).extend({
            //    notify: 'always'
            //});
            searchResults([]);
            productOrdersSearchModel.reset();

            loaddefaultDates();
            return searchOrders();

        }

        function loaddefaultDates() {
            var date = new Date();
            dateError(false);

            productOrdersSearchModel.fromDate(moment.utc(date.setDate(date.getDate() - 14)).format('MM/DD/YYYY'));
            productOrdersSearchModel.toDate(moment.utc(new Date()).format('MM/DD/YYYY'));
        }



        function loadResultToKOArray(data) {
            var results = [];

            for (var i = 0; i < data.Items.length; i++) {

                productOrdersResultsModel = new model.ProductOrdersResults();
                productOrdersResultsModel.propertyInfo(data.Items[i].AddressText)
                productOrdersResultsModel.ecommerceId(data.Items[i].EcommerceId)
                productOrdersResultsModel.orderDate(moment(data.Items[i].OrderDate).format('MM/DD/YYYY'));
                productOrdersResultsModel.total(data.Items[i].TotalAmountCredit);
                //productOrdersResultsModel.products(data.Items[i].Products);
                productOrdersResultsModel.productsHtmlText(data.Items[i].ProductsHtmlText);


                results.push(productOrdersResultsModel);

            }
            return results;
        }
        function clearSearch() {
            //searchResults([]);
            productOrdersSearchModel.reset();
            //loaddefaultDates();
        }
        function getSelectedValue(selectedItem, event) {

            return getOrderDetail(selectedItem.ecommerceId()).done(function (data) {
                var converted = loadDetailToKOArray(data);
                orderDetail(converted);
                propertyProfilePopup();
            })

        }

        function loadDetailToKOArray(data) {

            //productOrdersDetailsModel = new model.ProductOrdersDetails();

            productOrdersDetailsModel.orderItems([]);
            productOrdersDetailsModel.invalidOrderItems([]);

            productOrdersDetailsModel.totalValue(0);

            productOrdersDetailsModel.orderStatus(data.OrderStatus);
            productOrdersDetailsModel.orderDate(data.OrderDateString);
            productOrdersDetailsModel.confirmationNumber(data.ConfirmationNumber);
            productOrdersDetailsModel.propertyAddressHTML(data.PropertyAddressHTML);
            productOrdersDetailsModel.subTotal(data.SubTotalString);
            productOrdersDetailsModel.tax(data.TaxString);
            productOrdersDetailsModel.total(data.TotalString);
            productOrdersDetailsModel.usedCredits(data.UsedCredits);
            productOrdersDetailsModel.totalCreditString(data.TotalCreditString);
            productOrdersDetailsModel.stateTaxString(data.StateTaxString);
            productOrdersDetailsModel.systemProductTypeId(data.SystemProductTypeId);
            productOrdersDetailsModel.totalValue(data.Total);
            productOrdersDetailsModel.creditsOnly(data.CreditsOnly);



            //productOrdersDetailsModel.orderItems 

            var productOrderItemModel = null;

            for (var i = 0; i < data.OrderItems.length; i++) {
                productOrderItemModel = new model.ProductOrderItem();

                productOrderItemModel.productName(data.OrderItems[i].ProductName);
                productOrderItemModel.price(data.OrderItems[i].PriceString);
                productOrderItemModel.expiredDate(data.OrderItems[i].ExpiredDateString);
                productOrderItemModel.URL(data.OrderItems[i].URL);
                productOrderItemModel.propertyAddressHTML(data.OrderItems[i].PropertyAddressHTML);
                productOrderItemModel.expired(data.OrderItems[i].Expired);
                productOrdersDetailsModel.orderItems.push(productOrderItemModel);
            }


            for (var i = 0; i < data.InvalidOrderItems.length; i++) {
                productOrderItemModel = new model.ProductOrderItem();
                productOrderItemModel.productName(data.InvalidOrderItems[i].ProductName);
                productOrderItemModel.propertyAddressHTML(data.InvalidOrderItems[i].PropertyAddressHTML);
                productOrdersDetailsModel.invalidOrderItems.push(productOrderItemModel);
            }



            return productOrdersDetailsModel;

            //var detailResults = [];
            //for (var i = 0; i < data.Orders.length; i++) {
            //    productOrdersDetailsModel = new model.ProductOrdersDetails();
            //    productOrdersDetailsModel.orderDate(moment(data.Orders[i].OrderDate).format('MM/DD/YYYY'));
            //    productOrdersDetailsModel.confirmationNumber(data.Orders[i].PaymentTransactionInfo.ConfirmationNumber);
            //    productOrdersDetailsModel.subTotal("$"+ data.Orders[i].PriceTotal);
            //    productOrdersDetailsModel.tax("$"+ data.Orders[i].TaxTotal);
            //    productOrdersDetailsModel.total("$" + data.Orders[i].OrderTotal);
            //    productOrdersDetailsModel.productsHtmlText(data.Orders[i].ProductsHtmlText);

            //    //for (var j = 0; j < data.Orders[i].OrderItems.length; j++) {
            //    //    productOrdersDetailsModel.productName(data.Orders[i].OrderItems[j].Name);
            //    //    productOrdersDetailsModel.price("$" + data.Orders[i].OrderItems[j].OrderInformation.Price);
            //    //    productOrdersDetailsModel.propertyInfo(data.Orders[i].OrderItems[j].OrderInformation.PropertyAddressString);

            //    //}
            //    detailResults.push(productOrdersDetailsModel);

            //}
            //return detailResults;
        }
        function getOrderDetail(ecommerceOrderId) {
            var url = config.getOrderUrl;
            return https.get(url, {
                EcommerceOrderId: ecommerceOrderId,
                UserId: session.userId,
                format: 'json'
            });
        }

        function propertyProfilePopup() {
            $('#mdOrderDetails').modal('show');

        }

        function compositionComplete() {
            session.validateInput();
        }

        var vm = {
            activate: activate,
            productOrdersSearchModel: productOrdersSearchModel,
            searchResults: searchResults,
            searchOrders: searchOrders,
            clearSearch: clearSearch,
            productOrdersResultsModel: productOrdersResultsModel,
            getSelectedValue: getSelectedValue,
            getOrderDetail: getOrderDetail,
            orderDetail: orderDetail,
            productOrdersDetailsModel: productOrdersDetailsModel,
            fiteredCount: fiteredCount,
            dateError: dateError,
            gridParams: gridParams,
            selectedPageSizeOption: selectedPageSizeOption,
            //flipPage: flipPage,
            flipPageDirect: flipPageDirect,
            changePageSize: changePageSize,
            firstPage: firstPage,
            previousPage: previousPage,
            nextPage: nextPage,
            lastPage: lastPage,
            Failed: orderCommon.Failed,
            getProductReport: orderCommon.getProductReport,
            compositionComplete: compositionComplete
        };
        return vm;
    });
