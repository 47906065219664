define('viewmodels/orderModule/pirtOrder',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'commonDialogModel', 'pirtModel', 'customerInformationModel', 'utlities', 'commonOrderModel'],
 function (app, router, $, ko, validate, iCheck, config, validation, Q, https, session, alert, commonDialog, model, CustomerInformation, utlities,  commonOrderModel) {
     var customerInformationModel = new CustomerInformation();
     var orderInformationModel = new model.OrderInformation();
     var additionalAppraisalInfoModel = new model.AdditionalAppraisalInfo();
     var interiorModel = new model.Interior();
     var fixturesModel = new model.Fixtures();

     var submitOrderModel = new commonOrderModel.SubmitOrder();

     var commonDialogModel = new commonDialog("orderSubmitDialog");
     var orderSubmitted = ko.observable(false);
     var orderTitle = ko.observable('PIRT Order');

     var currentView = ko.observable('');
     var currentOrderModels = ko.observableArray([]);
     var currentViewIndex = ko.observable(-1);
     var currentOrderModel = ko.observable();
     var selectedOrderType = ko.observable(0);
     var selectedOrderTypeName = ko.observable("");
     var stateList = ko.observableArray();
     var countyList = ko.observableArray();
     var propertyInfoPopulateFromSession = ko.observable(false);
     var recentSearches = ko.observable(undefined);
     var recentSearchSelectedAPN = ko.observable("");
     var propertyInfoPopulateFromRecentSearch = ko.observable(false);

     currentOrderModel.subscribe(function (model) {
         vm.validationErrors = ko.validation.group([model]).extend({
             notify: 'always'
         });
         currentView(model.viewName);
         selectedOrderTypeName(model.title);
         session.handleFocusOnTabChange(model.ModelId, model.viewName);
     });

     currentViewIndex.subscribe(function (index) {
         currentOrderModel(currentOrderModels()[index]);
     });



     orderInformationModel.zipCode.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             countyList([]);
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     var selectedCounty = data.CountyName;
                     if (data.StateAbbr != null || data.City != null) {
                         utlities.getCountyList(data.StateAbbr).done(function (result) {
                             countyList(result);
                             orderInformationModel.county(selectedCounty);
                         });
                         orderInformationModel.city(data.City);
                         orderInformationModel.state(data.StateAbbr);
                         if (propertyInfoPopulateFromSession() === true && session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null && session.orderForPropertyAddress.city() != undefined && session.orderForPropertyAddress.city() != null && session.orderForPropertyAddress.city() != "") {
                             orderInformationModel.city(session.orderForPropertyAddress.city());
                             propertyInfoPopulateFromSession(false);
                         }
                         else if (propertyInfoPopulateFromRecentSearch() === true) {
                             propertyInfoPopulateFromRecentSearch(false);
                         }
                         else {
                         }
                     }
                     else {
                         alert.error("Invalid Zip.")
                         orderInformationModel.zipCode('');
                         orderInformationModel.city('');
                         orderInformationModel.state('');
                         orderInformationModel.county('');
                     }
                 }
                 else
                     session.zipCodeServiceDown();
             })
         }
     })

     var vm = {
         activate: activate,
         canDeactivate: canDeactivate,
         goBack: goBack,
         title: 'PIRT Order',

         orderTitle: orderTitle,

         currentView: currentView,
         currentOrderModels: currentOrderModels,
         currentViewIndex: currentViewIndex,
         currentOrderModel: currentOrderModel,
         selectedOrderTypeName: selectedOrderTypeName,

         moveNext: moveNext,
         movePrevious: movePrevious,
         submitOrder: submitOrder,
         navigateButtonClick: navigateButtonClick,
         commonDialogModel: commonDialogModel,

         customerInformationModel: customerInformationModel,
         orderInformationModel: orderInformationModel,
         additionalAppraisalInfoModel: additionalAppraisalInfoModel,
         interiorModel: interiorModel,
         fixturesModel: fixturesModel,
         submitOrderModel: submitOrderModel,
         stateList: stateList,
         countyList: countyList,
         getUSPhoneFormat: utlities.getUSPhoneFormat,
         getUSPhoneFormat: utlities.getUSPhoneFormat,
         getUSPhoneFormatWithExtn: utlities.getUSPhoneFormatWithExtn,
         session: session,
         compositionComplete: compositionComplete,
         recentSearches: recentSearches,
         recentResult_click: recentResult_click,
         recentSearchSelectedAPN: recentSearchSelectedAPN,
         clearSelectedProperty: clearSelectedProperty,
         compositionComplete: function () {
             session.handleFocusOnTabChange(customerInformationModel.ModelId, customerInformationModel.viewName);
         }
     }
     return vm;

     function activate(orderType) {
         recentSearches(session.getRecentSearchesFromLocalStorage());
         orderSubmitted(false);
         selectedOrderType(parseInt(orderType));
         setupOrder();
         currentViewIndex(0);
         if (session.userName() != undefined && session.userName() != '') {
             getCustomerInfo(session.userId, session.defaultOpertionId());
         }
         utlities.getStatesList().done(function (result) {
             stateList([]);
             stateList(result);

         });
         session.isBusy(false);
     }

     function compositionComplete() {
         if (session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null) {
             propertyInfoPopulateFromSession(true);
             orderInformationModel.address(session.orderForPropertyAddress.address());
             orderInformationModel.zipCode(session.orderForPropertyAddress.zip())
             if (session.orderForPropertyAddress.apn().length > 0) {
                 orderInformationModel.apn(session.orderForPropertyAddress.apn());
             }
         }
     }

     function canDeactivate() {
         if (!session.hasLoggedOut() && !orderSubmitted()) {
             var title = 'Cancel Order?';
             var msg = 'Navigate away and cancel the order? ';
             session.isLogoutVisible(false);
             return app.showMessage(msg, title, ['Yes', 'No '])
                 .then(function (selectedOption) {
                     if (selectedOption === 'Yes') {
                         // Call some cancel function
                         recentSearchSelectedAPN('');
                         session.clearOrderForPropertyAddress();
                         orderInformationModel.reset();
                     }
                     session.isLogoutVisible(true);
                     return selectedOption;
                 });
         }
         else {
             recentSearchSelectedAPN('');
             session.clearOrderForPropertyAddress();
             orderInformationModel.reset();
             return true;
         }
     };

     function recentResult_click(result) {
         recentSearchSelectedAPN(result.APN);
         propertyInfoPopulateFromSession(false);
         orderInformationModel.address(result.Address);
         orderInformationModel.city(result.City);
         orderInformationModel.apn(result.APN);

         if (orderInformationModel.zipCode() != result.ZIP.slice(0, 5)) {
             propertyInfoPopulateFromRecentSearch(true);
             orderInformationModel.zipCode(result.ZIP.slice(0, 5));
         }
         else
             propertyInfoPopulateFromRecentSearch(false);
     }
     function clearSelectedProperty() {
         recentSearchSelectedAPN('');
         orderInformationModel.resetPropertyAddress();
     }

     function setupOrder() {
         currentOrderModels([]);
         currentOrderModels.push(customerInformationModel);
         currentOrderModels.push(orderInformationModel);
         currentOrderModels.push(additionalAppraisalInfoModel);
         currentOrderModels.push(interiorModel);
         currentOrderModels.push(fixturesModel);
         currentOrderModels.push(submitOrderModel);
         resetModels();
     }

     function moveNext() {
         // commented for development, this need to be removed.
         vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
         if (vm.validationErrors().length > 0) {
             vm.validationErrors.showAllMessages();
             return;
         }
         currentViewIndex(currentViewIndex() + 1);

         if (currentViewIndex() == currentOrderModels().length - 1)
             buildPreviewModel();
     }

     function movePrevious() {
         currentViewIndex(currentViewIndex() - 1);
     }

     function navigateButtonClick(item, event) {
         if (item.index() > currentViewIndex()) {

             for (var i = 0; i < item.index() ; i++) {
                 // commented for development, this need to be removed.
                 vm.validationErrors = ko.validation.group(currentOrderModels()[i]);
                 if (vm.validationErrors().length > 0) {
                     vm.validationErrors.showAllMessages();
                     return;
                 }
             }
         }
         currentViewIndex(item.index());

         if (currentViewIndex() == currentOrderModels().length - 1)
             buildPreviewModel();
     }

     function goBack(complete) {
         router.navigateBack();
     }

     function resetModels() {
         for (var i = 0; i < currentOrderModels().length; i++) {
             currentOrderModels()[i].index(i);

             // skip order information view reset
             if (i > 0)
                 currentOrderModels()[i].reset();
         }
     }

     function getCustomerInfo(userId, opertionId) {
         return utlities.getOperationUserInfo(userId, opertionId).done(function (data) {
             var currdate = new Date();
             var currdate = (currdate.getMonth() + 1) + '/' + currdate.getDate() + '/' + currdate.getFullYear();
             customerInformationModel.orderDate(currdate);
             utlities.getManagerFullName(data.OperationId, data.UserId).done(function (result) {
                 customerInformationModel.salesRep(result);
             })
             customerInformationModel.fullName(data.FirstName + String.fromCharCode(160) + data.LastName);
             customerInformationModel.email(data.Email);
             customerInformationModel.company(data.Company);
             customerInformationModel.address(data.StreetAddress1 + String.fromCharCode(160) + data.StreetAddress2 + String.fromCharCode(160) + data.City + ',' + String.fromCharCode(160) + data.State + String.fromCharCode(160) + data.Zip);
             customerInformationModel.phon(data.PhoneWork);
             customerInformationModel.ext(data.PhoneWorkExt);
             customerInformationModel.fax(data.PhoneFax);

         })

     }

     function getZipInfo(zip) {
         var url = config.getZipInfoUrl;
         return https.get(url, {
             zipCode: zip,
             format: 'json'
         })
     }

     function buildPreviewModel() {
         for (var i = 0; i < currentOrderModels().length; i++) {
             if (currentOrderModels()[i].GetModelData) {
                 currentOrderModels()[i].GetModelData();
             }
         }
     }

     function submitOrder() {
         session.isBusy(true);
         var url = config.submitPirtOrderUrl;
         var request = {

             //Order Model Information -- Required
             "BrandId": session.brandId,
             "OrderId": "-1",
             "UserId": session.userId,
             "ServiceId": "58",
             "OperationId": session.defaultOpertionId(),
             "CreateDate": new Date(),
             "PropertyAddress": "",
             "PropertyCity": "",
             "PropertyState": "",
             "PropertyZip": "",
             "PropertyCounty": "",

             //Order Model Information -- Fill If Present
             "BackendSystem": "",
             "EscrowOfficerId": "",
             "TitleOfficerId": "",
             "IsBackendOrder": "",
             "PaymentId": "",

             //Customer Information
             "SalesRep": customerInformationModel.salesRep,
             "UserFullName": customerInformationModel.fullName,
             "UserEmail": customerInformationModel.email,
             "UserCompanyName": customerInformationModel.company,
             "UserAddress":
                        {
                            "Address1": customerInformationModel.address(),
                            "Address2": "",
                            "City": "",
                            "County": "",
                            "State": "",
                            "Zip": "",
                            "Zip4": ""
                        },

             "UserPhone":
                {
                    "PhoneNumber": customerInformationModel.phon(),
                    "PhoneType": "Business",
                    "Extension": customerInformationModel.ext()
                },

             "UserFax":
             {
                 "PhoneNumber": customerInformationModel.fax(),
                 "PhoneType": "Fax",
                 "Extension": ""
             },

             //Order Information
             "Pirt": "",
             "PirtOrderType": orderInformationModel.pirt,
             "FAV": orderInformationModel.fav,
             "FHLMC": orderInformationModel.fhlmc,
             "PirtUpdate": orderInformationModel.pirtUpdate,
             "FullAppraisal": orderInformationModel.fullAppraisal,
             "PirtSubEscrow": orderInformationModel.pirtSubEscrow,
             "FullEscrow": orderInformationModel.fullEscrow,
             "ALTA": orderInformationModel.alta,
             "FloodCert": orderInformationModel.floodCertificate,
             "FloodCertStandard": orderInformationModel.floodCertificateStandard,
             "FloodCertLifeOfLoan": orderInformationModel.floodCertificateLifeLoan,

             "OwnerName": orderInformationModel.ownerName,
             "Street": orderInformationModel.address,
             "City": orderInformationModel.city,
             "State": orderInformationModel.state,
             "Zip": orderInformationModel.zipCode,
             "County": orderInformationModel.county,
             "OwnerHomePhone":
                 {
                     "PhoneNumber": orderInformationModel.homePhone(),
                     "PhoneType": "Home",
                     "Extension": ""
                 },
             "OwnerWorkPhone":
                 {
                     "PhoneNumber": orderInformationModel.ownerWorkPhone(),
                     "PhoneType": "Business",
                     "Extension": orderInformationModel.ownerExt()
                 },
             "SpouseWorkPhone":
                 {
                     "PhoneNumber": orderInformationModel.spouseWorkPhone(),
                     "PhoneType": "Business",
                     "Extension": orderInformationModel.spouseExt()
                 },
             "APN": orderInformationModel.apn,

             //Additional Appraisal Information
             "TypeOfStructure": additionalAppraisalInfoModel.typeofStructure,

             //Interior Information
             "LivingRoom": interiorModel.livingRoom,
             "FamilyRoom": interiorModel.familyRoom,
             "Kitchen": interiorModel.kitchen,
             "Den": interiorModel.den,
             "GuestHome": interiorModel.guestHome,
             "Bedrooms": interiorModel.bedrooms,
             "Baths": interiorModel.baths,
             "StandardBuiltIns": interiorModel.standardBuiltins,
             "Other": interiorModel.other,

             //Fixtures Information
             "CentralAC": fixturesModel.centralAC,
             "PoolInGround": fixturesModel.poolInGround,
             "PoolAboveGround": fixturesModel.poolAboveGround,
             "SpaInGround": fixturesModel.spaInGround,
             "SpaAboveGround": fixturesModel.spaAboveGround,
             "DoubleGarage": fixturesModel.doubleGarage,
             "Fireplace": fixturesModel.fireplace,
             "CentralHeating": fixturesModel.centralHeating,
             "SingleGarage": fixturesModel.singleGarage,
             "WoodBurningStove": fixturesModel.woodBurningStove,
             "EvaporativeCooler": fixturesModel.evaporativeCooler,
             "Carport": fixturesModel.carport,
             "Deck": fixturesModel.deck,
             "CoveredPatio": fixturesModel.coveredPatio,
             "WindowOrWallHeater": fixturesModel.windowOrWallHeater,
             "OtherFixture": fixturesModel.other,
             "Additional": fixturesModel.additionalInformation,
             "ApproxLivingArea": fixturesModel.approxLivingArea,
             "ApproxLotSize": fixturesModel.approxLotSize,
             "PurchasePrice": fixturesModel.purchasePrice,
             "Comments": fixturesModel.comments,

         }

         https.post(url, request)
            .then(function (data) {
                if (data.HasErrors == false) {
                    orderSubmitted(true);
                    commonDialogModel.IsOrderDialog(true);
                    commonDialogModel.orderId(data.OrderId);
                    if (data.IntendedRecpients)
                        commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                    commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                }
                //else {
                //    for (var i = 0; i < data.Messages.length; i++) {
                //        if (data.Messages[i].ExceptionMessage != "") {
                //            console.log("Error:" + data.Messages[i].ExceptionMessage, orderTitle());
                //            alert.error('Internal Error. Please contact Administrator.');
                //            orderSubmitted(false);
                //            session.isBusy(false);
                //        }
                //    }
                //}
                else {
                    orderSubmitted(false);
                    session.isBusy(false);
                    var msgSuccess = "";
                    var msgError = "";

                    for (var i = 0; i < data.Messages.length; i++) {
                        if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                            //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                            //alert.error('Internal Error. Please contact Administrator.');
                            if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                msgError = msgError + data.Messages[i].Message + "\n";
                            }
                        }
                        else {
                            msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                        }
                    }
                    //alert.error(msgSuccess, orderTitle());
                    //alert.error(msgError, orderTitle());
                    if (msgSuccess.length > 0) {
                        orderSubmitted(true);
                        commonDialogModel.IsOrderDialog(true);
                        commonDialogModel.orderId(data.OrderId);
                        msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                        commonDialogModel.messagehtml(msgError);
                        if (data.IntendedRecpients)
                            commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                        commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                    }
                    else if (msgError.length > 0) {
                        alert.error(msgError, orderTitle());
                    }
                }
            })
         .fail(function (data) {
             var error = JSON.parse(data.responseText)
             console.log(error);
             session.isBusy(false);
         })
     }

 });
