define('advanceAddressSearch',['durandal/app', 'knockout', 'plugins/http', 'config', 'alertNotification', 'Q', 'propertySearchCommon'], function (app, ko, https, config, alert, Q, propertySearchCommon) {
    var searchResults = ko.observableArray();

    var vm = {

        addressSearch: addressSearch,
        searchResults: searchResults,
        compositionComplete: compositionComplete
    };


    vm.searchResults.subscribe(propertySearchCommon.alertMultiplePropertyWarning);

    function compositionComplete() {
        session.validateInput();
    }

    function addressSearch(parentResultHolder, addressModel, isSearchable) {
        if (addressModel.streetNumber && addressModel.streetNumber.length > 0) {
            addressModel.streetNumber = addressModel.streetNumber.trim();
        }

        if (addressModel.streetName && addressModel.streetName.length > 0) {
            addressModel.streetName = addressModel.streetName.trim();
        }

        if (addressModel.unitNumber && addressModel.unitNumber.length > 0) {
            addressModel.unitNumber = addressModel.unitNumber.trim();
        }

        if (addressModel.city && addressModel.city.length > 0) {
            addressModel.city = addressModel.city.trim();
        }

        var url = config.propertySearchUrl;

        addressModel.additionalAddressLine


        var request = {
            Zip: addressModel.zip,
            State: addressModel.state,
            City: addressModel.city,
            HouseNumber: addressModel.streetNumber,
            Address1: addressModel.streetName,
            Address2: addressModel.unitNumber,
            AdditionalAddressLine: addressModel.additionalAddressLine,
            SearchType: 'Address'

        };

        return https.post(url, request)
            .then(function (data) {
                searchResults(data);
                parentResultHolder(data);
            })
            .always(function () {
                isSearchable(true);
            })


        //https.post(url, request).then(function (data) {
        //    console.log(data);
        //});

        // Q.when(https.post(url, {
        //     request: request
        // })
        //       .then(function (data) {
        //           parentResultHolder(data);
        //       })
        //       .fail(Failed)
        //);

        //  .then(succeeded)
        //  .fail(Failed);
        //parentResultHolder.push(searchResults);

    }

    function succeeded(data) {
        searchResults(data);
    }

    return vm;
});
