//TODO: Inject dependencies
define('viewmodels/userModule/registerStep1',['plugins/router', 'registerAccountInfoModel'],
    function (router, registerAccountInfoModel) {

        var registerAccountInfo = new registerAccountInfoModel.registerAccountInfo();
        // Internal properties and functions
       
        // Reveal the bindable properties and functions
        var vm = {
            activate: activate,
            registerAccountInfo: registerAccountInfo,
            goBack: goBack,
            title: 'registerStep1'
        };

        return vm;
        
        function activate (id, querystring) {
            //TODO: Initialize lookup data here.
            console.log('registerStep1 activated');
            //TODO: Get the data for this viewmodel, return a promise.
        }

        function goBack(complete) {
            router.navigateBack();
        }
    });
