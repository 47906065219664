define('viewmodels/userProfileModule/userOperations',['durandal/app', 'knockout', 'config', 'plugins/http', 'global/session', 'alertNotification', 'userOperationModel'],
function (app, ko, config, https, session, alert, userOperationModel) {

    var UserDefaultOperationCheckBoxValue = ko.observable(0);
    var userOperationsList = ko.observable([]);
    var userType = ko.observable("");
    var QAChange = ko.observable(false);
    var passwordChange = ko.observable(false);
    var savedQuestion = ko.observable("");
    var pluginFocus = ko.observable(false);


    var vm = {
        canActivate: canActivate,
        activate: activate,
        bindingComplete: bindingComplete,
        compositionComplete: compositionComplete,
        canDeactivate: canDeactivate,
        session: session,
        ChangeUserDefaultOperation: ChangeUserDefaultOperation,
        userOperationsList: userOperationsList,
    };
    return vm;

    function canActivate() {
        if (session.userTypeIsEmployee() != undefined && session.userTypeIsEmployee() != null && session.userTypeIsEmployee() && session.userAssignedOperationsList() != undefined && session.userAssignedOperationsList() != null && session.userAssignedOperationsList().length > 1)
            return true;
        return false;
    }
    function activate() {
        buildOperationList(session.userAssignedOperationsList(), session.userDefaultOperationId());
    }

    function buildOperationList(list, defaultOpnId) {
        userOperationsList([]);
        var buildOperationsList = [];
        for (i = 0; i < list.length; i++) {
            var item = new userOperationModel.UserOperationModel();
            item.OperationId(list[i].OperationId);
            item.BrandId(list[i].BrandId);
            item.OperationName(list[i].Name);
            item.ValueCheckUserId(list[i].VCheckUid);
            if (list[i].OperationId === defaultOpnId)
                item.IsDefaultOperation(true);
            else
                item.IsDefaultOperation(false);
            buildOperationsList.push(item);
        }
        userOperationsList(buildOperationsList);
    }


    function canDeactivate() {
        return true;
    }

    function compositionComplete() {
        session.validateInput();
    }

    function bindingComplete() {
    }

    function ChangeUserDefaultOperation(elementValue) {
        console.log(elementValue);
        if (elementValue === session.userDefaultOperationId()) {
            alert.info('This is already your Default Operation', 'User Operations');
            return false;
        }
        return https.post(config.updateUserDefaultOperationURL + "?OperationId=" + elementValue)
          .done(function (Result) {
              if (Result.HasErrors == false) {
                  buildOperationList(Result.Operations, Result.DefaultOperation.OperationId);
                  session.clearRecentSearchesFromLocalStorage();
                  location.reload();
                  //session.getUserInfoByEmail(session.email());  //this is without refresh
              }
              else {
                  alert.error('Error while updating User Operation', 'User Operations');
              }
          }).always(function () {
              session.isAjaxPost(true);
          })
          .fail(function () {
              alert.error('Error changing user operation', 'User Operations');
          });
    }
});
