define('viewmodels/orderModule/orderProcess',[
        'durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'commonOrderModel', 'config',
        'knockout.validation', 'Q', 'plugins/http', 'commonOrderFieldsModel', 'global/session', 'alertNotification',
        'commonDialogModel', 'utlities',  'utlities','moment', 'bootstrapDatepicker'
    ],
    function(app,
        router,
        $,
        ko,
        validate,
        iCheck,
        commonOrderModel,
        config,
        validation,
        Q,
        https,
        commonModel,
        session,
        alert,
        commonDialog,
        utlities,
        utils,
        moment,
        bootstrapDatepicker) {

        var orderInformationModel = new commonOrderModel.orderInformation();
        var subjectPropertyModel = new commonOrderModel.SubjectProperty();
        var orderRequestModel = new commonOrderModel.OrderRequester();


        var principalBorrowerModel = new commonOrderModel.PrincipalBorrower();
        var principalBorrowersModel = new commonOrderModel.PrincipalBorrowers();


        var principalSellerModel = new commonOrderModel.PrincipalSeller();
        var principalSellersModel = new commonOrderModel.principalSellers();

        var commonOrderFieldsModel = new commonModel.commonOrderFields();

        var lenderModel = new commonOrderModel.Lender();
        var lendersModel = new commonOrderModel.Lenders();
        var singleLenderModel = new commonOrderModel.SingleLender();

        var principalBuyersModel = new commonOrderModel.PrincipalBuyers();
        var principalBuyerModel = new commonOrderModel.PrincipalBuyer();

        var outsideTitleModel = new commonOrderModel.OutsideTitle();
        var specialInstructions = new commonOrderModel.SpecialInstructions();
        var submitOrderModel = new commonOrderModel.SubmitOrder();


        var listingAgentModel = new commonOrderModel.listingAgent();
        var sellingAgentModel = new commonOrderModel.sellingAgent();

        var independentEscrowModel = new commonOrderModel.IndependentEscrow();

        var wesbPrincipalSellerModel = new commonOrderModel.WesbPrincipalSeller();
        var wesbprincipalSellersModel = new commonOrderModel.WesbprincipalSellers();


        var wesbPrincipalBuyerModel = new commonOrderModel.WesbPrincipalBuyer();
        var wesbprincipalBuyersModel = new commonOrderModel.WesbprincipalBuyers();

        var wesbBorrowerModel = new commonOrderModel.WesbBorrower();
        var wesbBorrowersModel = new commonOrderModel.WesbBorrowers();


        var commonDialogModel = new commonDialog("orderSubmitDialog");
        var orderSubmitted = ko.observable(false);

        var isLenderEdit = ko.observable(false);


        var _objectModel = null;
        var _objectDialogId = null;
        var _objectsModel = null;
        var _objectModelId = null;
        var _objectUsed = null;
        var _deletingObjectIndex = ko.observable(-1);
        var _deletingObjectTitle = ko.observable("");
        var recentSearchSelectedAPN = ko.observable("");

        var recentSearches = ko.observable(undefined);


        
        var selectedOrder = ko.observable('');
        var stateList = ko.observableArray();
        var financeTypes = ko.observableArray();
        var countyList = ko.observableArray();
        var fipsInfo = ko.observableArray();
        var parentUserList = ko.observableArray();
        var monthList = ko.observableArray();
        var selectedState = ko.observable('');
        var selectedCounty = ko.observable('');
        var currentView = ko.observable('');
        var currentOrderModels = ko.observableArray([]);
        var currentViewIndex = ko.observable(-1).extend({ notify: 'always' });
        var currentOrderModel = ko.observable();
        var selectedOrderTypeId = ko.observable(0);
        var orderType = ko.observable('');
        var selectedOrderTypeName = ko.observable('');
        var userProfile = ko.observableArray();
        var orderTitle = ko.observableArray('');
        var wesbOrderTitle = ko.observableArray('');
        var icProviderInfoEscrow = ko.observableArray([]);
        var icProviderInfoTitle = ko.observableArray([]);
        var icProviderInfoSalesReps = ko.observable([]);
        var transactionParties = ko.observableArray([]);
        var limitedCoveragePolicy = orderInformationModel.lcp() == "Yes" ? true : false;


        var orderTitleOfficesList = ko.observableArray([]);
        var orderEscrowOfficesList = ko.observableArray([]);
        var orderTransactionList = ko.observableArray([]);
        var orderEscrowOfficersList = ko.observableArray([]);
        var orderTitleOfficersList = ko.observableArray([]);
        var orderSalesRepresentativeList = ko.observableArray([]);
        var orderCustomerServicesList = ko.observableArray([]);
        var customerServicesRequested = ko.observableArray([]);
        var selectedTransactionTypeText = ko.observableArray('');

        var lenderFreqDrpList = ko.observableArray('');
        var lenderList = ko.observableArray('');

        var agentFreqDrpList = ko.observableArray('');
        var agentList = ko.observableArray('');

        var outsideFreqDrpList = ko.observableArray('');
        var IndependentEscrowFreqContacts = ko.observableArray('');

        var outsideList = ko.observableArray('');

        var mySelfProfile = ko.observableArray('');
        // Enable Desable control on FrequentContact selection
        var outsideTitleFreqContact = ko.observable(true);
        var lenderFreqContact = ko.observable(true);
        var independentEscrowFreqContact = ko.observable(true);
        var listingAgentFreqContact = ko.observable(true);
        var sellingAgentFreqContact = ko.observable(true);
        var singleLenderFreqContact = ko.observable(true);
        var orderTypeServiceLabel = ko.observable('');
        var successOrderID = ko.observable('');
        var currentViewSkippable = ko.observable(false);

        var propertyInfoPopulateFromSession = ko.observable(false);
        var propertyInfoPopulateFromRecentSearch = ko.observable(false);
        var salesRepresentativeList = ko.observable([]);
        var salesRepresentative = ko.observable('');
        var iCloseSalesRepresentativeId = ko.observable('');
        var iCloseSalesRepresentativeName = ko.observable('');

        //End 


        //ko.bindingHandlers.stopBubble = {
        //    init: function (element) {
        //        ko.utils.registerEventHandler(element, "click", function (event) {
        //            event.cancelBubble = true;
        //            if (event.stopPropagation) {
        //                event.stopPropagation();
        //            }
        //        });
        //    }
        //};

        session.isICOrder.subscribe(function(isICOrder) {
                if (isICOrder) {
                    subjectPropertyModel.isIcPropertyType(true);
                    subjectPropertyModel.isOfflinePropertyType(false);
                    subjectPropertyModel.isOnlinePropertyType(false); //was true
                }
            
    });

        orderInformationModel.isIcloseOrder.subscribe(function(ic) {
            if (ic != session.isICOrder()) {
                orderInformationModel.isIcloseOrder(session.isICOrder());
            }
        });

        currentOrderModel.subscribe(function(model) {
            vm.validationErrors = ko.validation.group([model]).extend({
                notify: 'always'
            });
            currentView(model.viewName);
            selectedOrderTypeName(model.title);
            setupLocalObject(model.ModelId);
            vm.validationErrors.showAllMessages(false);
            if (model.title == "Property Information") {
                console.log(session.getRecentSearchesFromLocalStorage());
            }
            session.handleFocusOnTabChange(model.ModelId, model.viewName);
        });

        currentViewIndex.subscribe(function(index) {
            if (index > -1) {
                currentOrderModel(currentOrderModels()[index]);
                if (currentOrderModels()[index].isSkippable != undefined)
                    currentViewSkippable(currentOrderModels()[index].isSkippable);
                else
                    currentViewSkippable(false);
            }


        });

       

        orderInformationModel.transactionType.subscribe(function(transType) {
            var name = "";
            console.log('selected transaction' + transType);
            if ((transType != undefined && transType != '')) {

                ko.utils.arrayFilter(orderTransactionList(),
                    function(item) {
                        if (item.Value == transType) {
                            name = item.Key;
                        }
                    });
                selectedTransactionTypeText(name);
                orderInformationModel.selectedTransactionTypeName(name);
                setupWizard(transType);
                recentSearchSelectedAPN('');
                setSubjectPropertyFromSelectedProperty();
                if (orderInformationModel.isIcloseOrder()) {
                    //orderInformationModel.TransactionType = 0;
                    orderInformationModel.TransactionTypeCode = 'UNKN';
                }
                orderInformationModel.TransactionTypeId(transType);
                orderInformationModel.TransactionTypeName(name);
                

            }
        });

        subjectPropertyModel.zip.subscribe(function(zip) {
            if (zip != undefined && zip != '') {
                countyList([]);
                return utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        selectedCounty = data.CountyName;
                        subjectPropertyModel.state(data.StateAbbr);
                        if (propertyInfoPopulateFromSession() === true &&
                            session.orderForPropertyAddress != undefined &&
                            session.orderForPropertyAddress != null &&
                            session.orderForPropertyAddress.city() != undefined &&
                            session.orderForPropertyAddress.city() != null &&
                            session.orderForPropertyAddress.city() != "") {
                            subjectPropertyModel.city(session.orderForPropertyAddress.city());
                            propertyInfoPopulateFromSession(false);
                        } else if (propertyInfoPopulateFromRecentSearch() === true) {
                            propertyInfoPopulateFromRecentSearch(false);
                        } else {
                            subjectPropertyModel.city(data.City);
                        }
                        if (selectedCounty != null) {
                            return utlities.getCountyList(data.StateAbbr).done(function(result) {
                                countyList(result);
                                subjectPropertyModel.county(selectedCounty);

                            });
                        } else {
                            //session.isAjaxPost(false);
                            alert.error("Invalid Zip.");
                            subjectPropertyModel.zip('');
                            subjectPropertyModel.city('');
                            subjectPropertyModel.state('');
                            subjectPropertyModel.county('');
                        }
                    } else
                        session.zipCodeServiceDown();
                });
            }
            
        });

        subjectPropertyModel.propertyType.subscribe(function() {
            subjectPropertyModel.selectedOnlinePropertyType($('#propertyType option:selected').text());
        });

        subjectPropertyModel.offlinePropertyType.subscribe(function () {
           subjectPropertyModel.selectedOfflinePropertyType($('#offlinePropertyType option:selected').text());
        });
        subjectPropertyModel.icPropertyType.subscribe(function() {
            subjectPropertyModel.selectedIcPropertyType($('#icPropertyType option:selected').text());
        });

        singleLenderModel.contact.subscribe(function(val) {
            if (val != undefined && val != '') {
                singleLenderModel.newFrequentContact(false)
                var userId = session.userId;
                $('.phone').val("");
                if (val == "0") {
                    singleLenderModel.reset();
                    singleLenderFreqContact(true);
                    singleLenderModel.newFrequentContact(true);
                } else if (val == "1") {
                    singleLenderFreqContact(false);
                    getUserInfoByEmail(session.userName()).done(function(data) {
                        mySelfProfile(data);

                        //singleLenderModel.update(mySelfProfile);

                        singleLenderModel.firstName(mySelfProfile().FirstName); //
                        singleLenderModel.lastName(mySelfProfile().LastName); //
                        singleLenderModel.company(mySelfProfile().Company);
                        singleLenderModel.address(mySelfProfile().StreetAddress1 +
                            String.fromCharCode(160) +
                            mySelfProfile().StreetAddress2);
                        singleLenderModel.zip(mySelfProfile().Zip);
                        singleLenderModel.city(mySelfProfile().City);
                        singleLenderModel.state(mySelfProfile().State);
                        singleLenderModel.phone(mySelfProfile().PhoneWork);
                        singleLenderModel.phoneExt(mySelfProfile().PhoneWorkExt);
                        singleLenderModel.fax(mySelfProfile().PhoneFax);
                        singleLenderModel.faxExt(mySelfProfile().PhoneFaxExt);
                        singleLenderModel.email(mySelfProfile().Email);

                    })
                } else {

                    var contactType = 2
                    singleLenderFreqContact(false);
                    getFrequentContacts(userId, contactType).done(function(data) {
                        lenderList(data)
                        ko.utils.arrayFilter(lenderList(),
                            function(item) {
                                if (item.FreqContactId == val) {
                                    // lenderModel.update(item);
                                    // singleLenderModel.update(item);
                                    singleLenderModel.firstName(item.FirstName);
                                    singleLenderModel.lastName(item.LastName);
                                    singleLenderModel.company(item.Company);
                                    singleLenderModel.address(item.Address)
                                    singleLenderModel.zip(item.Zip)
                                    singleLenderModel.city(item.City)
                                    singleLenderModel.state(item.State)
                                    singleLenderModel.phone(item.Phone)
                                    singleLenderModel.phoneExt(item.PhoneExt)
                                    singleLenderModel.fax(item.Fax)
                                    singleLenderModel.faxExt(item.FaxExt)
                                    singleLenderModel.email(item.Email)

                                }
                            });

                    })

                }
            }
        });

        lenderModel.contact.subscribe(function(val) {
            if (val != undefined && val != '') {
                lenderModel.newFrequentContact(false);
                var userId = session.userId;
                $('.phone').val("");
                lenderList([]);

                if (val == "0") {
                    if (!isLenderEdit()) {
                        lenderModel.reset();
                    } else {
                        lenderModel.newFrequentContact(true);
                    }


                    lenderFreqContact(true);


                    // ko.validation.group([lenderModel]).showAllMessages(false);

                } else if (val == "1") {
                    lenderFreqContact(false);
                    lenderModel.newFrequentContact(false);
                    getUserInfoByEmail(session.userName()).done(function(data) {
                        mySelfProfile(data);
                        lenderModel.firstName(mySelfProfile().FirstName); //
                        lenderModel.lastName(mySelfProfile().LastName); //
                        lenderModel.company(mySelfProfile().Company);
                        lenderModel.address(mySelfProfile().StreetAddress1 +
                            String.fromCharCode(160) +
                            mySelfProfile().StreetAddress2)
                        lenderModel.zip(mySelfProfile().Zip)
                        lenderModel.city(mySelfProfile().City)
                        lenderModel.state(mySelfProfile().State)
                        lenderModel.phone(mySelfProfile().PhoneWork)
                        lenderModel.phoneExt(mySelfProfile().PhoneWorkExt)
                        lenderModel.fax(mySelfProfile().PhoneFax)
                        lenderModel.faxExt(mySelfProfile().PhoneFaxExt)
                        lenderModel.email(mySelfProfile().Email)

                    })
                } else {

                    var contactType = 2
                    lenderFreqContact(false);
                    lenderModel.newFrequentContact(false);
                    getFrequentContacts(userId, contactType).done(function(data) {
                        lenderList(data)
                        ko.utils.arrayFilter(lenderList(),
                            function(item) {
                                if (item.FreqContactId == val) {
                                    // lenderModel.update(item);
                                    lenderModel.firstName(item.FirstName);
                                    lenderModel.lastName(item.LastName);
                                    lenderModel.company(item.Company);
                                    lenderModel.address(item.Address)
                                    lenderModel.zip(item.Zip)
                                    lenderModel.city(item.City)
                                    lenderModel.state(item.State)
                                    lenderModel.phone(item.Phone)
                                    lenderModel.phoneExt(item.PhoneExt)
                                    lenderModel.fax(item.Fax)
                                    lenderModel.faxExt(item.FaxExt)
                                    lenderModel.email(item.Email)

                                }
                            });

                    })

                }

                if (parseInt(val) > 0) {
                    $('.modal-body', '#mdCreateLender').scrollTop(400);
                }
            }
            isLenderEdit(false);
        });

        listingAgentModel.contact.subscribe(function(val) {
            if (val != undefined && val != '') {
                listingAgentModel.newFrequentContact(true);
                var userId = session.userId;
                agentList([]);
                $('.phone').val('');
                mySelfProfile([]);
                if (val == "0") {
                    listingAgentModel.MyselfSet(false);
                    lendersModel.MyselfSet(sellingAgentModel.MyselfSet());
                    listingAgentModel.reset();
                    listingAgentFreqContact(true);
                    listingAgentModel.newFrequentContact(true);
                } else if (val == "1") {
                    listingAgentModel.newFrequentContact(false);
                    listingAgentFreqContact(false);
                    listingAgentModel.MyselfSet(true);
                    lendersModel.MyselfSet(true);
                    getUserInfoByEmail(session.userName()).done(function(data) {
                        mySelfProfile(data);
                        listingAgentModel.firstName(mySelfProfile().FirstName); //
                        listingAgentModel.lastName(mySelfProfile().LastName); //
                        listingAgentModel.company(mySelfProfile().Company);
                        listingAgentModel.address(mySelfProfile().StreetAddress1 +
                            String.fromCharCode(160) +
                            mySelfProfile().StreetAddress2)
                        listingAgentModel.zip(mySelfProfile().Zip)
                        listingAgentModel.city(mySelfProfile().City)
                        listingAgentModel.state(mySelfProfile().State)
                        listingAgentModel.phone(mySelfProfile().PhoneWork)
                        listingAgentModel.phoneExt(mySelfProfile().PhoneWorkExt)
                        listingAgentModel.fax(mySelfProfile().PhoneFax)
                        listingAgentModel.faxExt(mySelfProfile().PhoneFaxExt)
                        listingAgentModel.email(mySelfProfile().Email)

                    })
                } else {
                    listingAgentModel.MyselfSet(false);
                    lendersModel.MyselfSet(sellingAgentModel.MyselfSet());
                    var contactType = 1
                    listingAgentFreqContact(false);
                    getFrequentContacts(userId, contactType).done(function(data) {
                        agentList(data)
                        ko.utils.arrayFilter(agentList(),
                            function(item) {
                                if (item.FreqContactId == val) {
                                    listingAgentModel.firstName(item.FirstName);
                                    listingAgentModel.lastName(item.LastName);
                                    listingAgentModel.company(item.Company);
                                    listingAgentModel.address(item.Address)
                                    listingAgentModel.zip(item.Zip)
                                    listingAgentModel.city(item.City)
                                    listingAgentModel.state(item.State)
                                    listingAgentModel.phone(item.Phone)
                                    listingAgentModel.phoneExt(item.PhoneExt)
                                    listingAgentModel.fax(item.Fax)
                                    listingAgentModel.faxExt(item.FaxExt)
                                    listingAgentModel.email(item.Email)
                                }
                            });

                    })
                }


            }
        });
        sellingAgentModel.contact.subscribe(function(val) {
            if (val != undefined && val != '') {
                sellingAgentModel.newFrequentContact(false)
                var userId = session.userId;
                $('.phone').val('');
                agentList([]);
                mySelfProfile([]);
                //alert.info(val);
                if (val == "0") {
                    sellingAgentModel.MyselfSet(false);
                    lendersModel.MyselfSet(listingAgentModel.MyselfSet());
                    sellingAgentModel.reset();
                    sellingAgentFreqContact(true);
                    sellingAgentModel.newFrequentContact(true);
                } else if (val == "1") {
                    sellingAgentModel.MyselfSet(true);
                    lendersModel.MyselfSet(true);
                    sellingAgentFreqContact(false);

                    getUserInfoByEmail(session.userName()).done(function(data) {
                        mySelfProfile(data);
                        sellingAgentModel.firstName(mySelfProfile().FirstName); //
                        sellingAgentModel.lastName(mySelfProfile().LastName); //
                        sellingAgentModel.company(mySelfProfile().Company);
                        sellingAgentModel.address(mySelfProfile().StreetAddress1 +
                            String.fromCharCode(160) +
                            mySelfProfile().StreetAddress2)
                        sellingAgentModel.zip(mySelfProfile().Zip)
                        sellingAgentModel.city(mySelfProfile().City)
                        sellingAgentModel.state(mySelfProfile().State)
                        sellingAgentModel.phone(mySelfProfile().PhoneWork)
                        sellingAgentModel.phoneExt(mySelfProfile().PhoneWorkExt)
                        sellingAgentModel.fax(mySelfProfile().PhoneFax)
                        sellingAgentModel.faxExt(mySelfProfile().PhoneFaxExt)
                        sellingAgentModel.email(mySelfProfile().Email)

                    })
                } else {
                    sellingAgentModel.MyselfSet(false);
                    lendersModel.MyselfSet(listingAgentModel.MyselfSet());
                    var contactType = 1
                    sellingAgentFreqContact(false);
                    getFrequentContacts(userId, contactType).done(function(data) {
                        agentList(data)
                        ko.utils.arrayFilter(agentList(),
                            function(item) {
                                if (item.FreqContactId == val) {
                                    // lenderModel.update(item);
                                    sellingAgentModel.firstName(item.FirstName);
                                    sellingAgentModel.lastName(item.LastName);
                                    sellingAgentModel.company(item.Company);
                                    sellingAgentModel.address(item.Address)
                                    sellingAgentModel.zip(item.Zip)
                                    sellingAgentModel.city(item.City)
                                    sellingAgentModel.state(item.State)
                                    sellingAgentModel.phone(item.Phone)
                                    sellingAgentModel.phoneExt(item.PhoneExt)
                                    sellingAgentModel.fax(item.Fax)
                                    sellingAgentModel.faxExt(item.FaxExt)
                                    sellingAgentModel.email(item.Email)

                                }
                            });

                    })
                }
            }
        });
        outsideTitleModel.contact.subscribe(function(val) {
            if (val != undefined && val != '') {
                outsideTitleModel.newFrequentContact(false);
                var userId = session.userId;
                outsideList([]);
                $('.phone').val('');
                mySelfProfile([]);
                if (val == "0") {
                    outsideTitleModel.reset();
                    outsideTitleFreqContact(true);
                    outsideTitleModel.newFrequentContact(true);
                } else if (val == "1") {
                    outsideTitleFreqContact(false);
                    getUserInfoByEmail(session.userName()).done(function(data) {
                        mySelfProfile(data);
                        outsideTitleModel.firstName(mySelfProfile().FirstName); //
                        outsideTitleModel.lastName(mySelfProfile().LastName); //
                        outsideTitleModel.company(mySelfProfile().Company);
                        outsideTitleModel.address(mySelfProfile().StreetAddress1 +
                            String.fromCharCode(160) +
                            mySelfProfile().StreetAddress2)
                        outsideTitleModel.zip(mySelfProfile().Zip)
                        outsideTitleModel.city(mySelfProfile().City)
                        outsideTitleModel.state(mySelfProfile().State)
                        outsideTitleModel.phone(mySelfProfile().PhoneWork)
                        outsideTitleModel.phoneExt(mySelfProfile().PhoneWorkExt)
                        outsideTitleModel.fax(mySelfProfile().PhoneFax)
                        outsideTitleModel.faxExt(mySelfProfile().PhoneFaxExt)
                        outsideTitleModel.email(mySelfProfile().Email)

                    })
                } else {
                    var contactType = 3
                    outsideTitleFreqContact(false);
                    getFrequentContacts(userId, contactType).done(function(data) {
                        outsideList(data)
                        ko.utils.arrayFilter(outsideList(),
                            function(item) {
                                if (item.FreqContactId == val) {
                                    outsideTitleModel.firstName(item.FirstName);
                                    outsideTitleModel.lastName(item.LastName);
                                    outsideTitleModel.company(item.Company);
                                    outsideTitleModel.address(item.Address)
                                    outsideTitleModel.zip(item.Zip)
                                    outsideTitleModel.city(item.City)
                                    outsideTitleModel.state(item.State)
                                    outsideTitleModel.phone(item.Phone)
                                    outsideTitleModel.phoneExt(item.PhoneExt)
                                    outsideTitleModel.fax(item.Fax)
                                    outsideTitleModel.faxExt(item.FaxExt)
                                    outsideTitleModel.email(item.Email)

                                }
                            });

                    })
                }
            }
        });

        independentEscrowModel.contact.subscribe(function(val) {
            if (val != undefined && val != '') {
                independentEscrowModel.newFrequentContact(false);
                var userId = session.userId;
                outsideList([]);
                $('.phone').val('');
                mySelfProfile([]);
                if (val == "0") {
                    independentEscrowModel.reset();
                    independentEscrowFreqContact(true);
                    independentEscrowModel.newFrequentContact(true);
                } else if (val == "1") {
                    independentEscrowFreqContact(false);
                    getUserInfoByEmail(session.userName()).done(function(data) {
                        mySelfProfile(data);
                        independentEscrowModel.firstName(mySelfProfile().FirstName); //
                        independentEscrowModel.lastName(mySelfProfile().LastName); //
                        independentEscrowModel.company(mySelfProfile().Company);
                        independentEscrowModel.address(mySelfProfile().StreetAddress1 +
                            String.fromCharCode(160) +
                            mySelfProfile().StreetAddress2)
                        independentEscrowModel.zip(mySelfProfile().Zip)
                        independentEscrowModel.city(mySelfProfile().City)
                        independentEscrowModel.state(mySelfProfile().State)
                        independentEscrowModel.phone(mySelfProfile().PhoneWork)
                        independentEscrowModel.phoneExt(mySelfProfile().PhoneWorkExt)
                        independentEscrowModel.fax(mySelfProfile().PhoneFax)
                        independentEscrowModel.faxExt(mySelfProfile().PhoneFaxExt)
                        independentEscrowModel.email(mySelfProfile().Email)

                    })
                } else {
                    var contactType = 4
                    independentEscrowFreqContact(false);
                    getFrequentContacts(userId, contactType).done(function(data) {
                        outsideList(data)
                        ko.utils.arrayFilter(outsideList(),
                            function(item) {
                                if (item.FreqContactId == val) {
                                    independentEscrowModel.firstName(item.FirstName);
                                    independentEscrowModel.lastName(item.LastName);
                                    independentEscrowModel.company(item.Company);
                                    independentEscrowModel.address(item.Address)
                                    independentEscrowModel.zip(item.Zip)
                                    independentEscrowModel.city(item.City)
                                    independentEscrowModel.state(item.State)
                                    independentEscrowModel.phone(item.Phone)
                                    independentEscrowModel.phoneExt(item.PhoneExt)
                                    independentEscrowModel.fax(item.Fax)
                                    independentEscrowModel.faxExt(item.FaxExt)
                                    independentEscrowModel.email(item.Email)

                                }
                            });

                    })
                }
            }
        });

        lenderModel.zip.subscribe(function(zip) {
            if (lenderModel.index() > -1 && lenderModel.isLoading) {
                lenderModel.isLoading = false;
                return;
            }

            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            lenderModel.city(data.City);
                            lenderModel.state(data.StateAbbr);
                        } else {

                            alert.error("Invalid Zip.");
                            lenderModel.zip('');
                            lenderModel.city('');
                            lenderModel.state('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }

        })

        singleLenderModel.zip.subscribe(function(zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            singleLenderModel.city(data.City);
                            singleLenderModel.state(data.StateAbbr);
                        } else {

                            alert.error("Invalid Zip.");
                            singleLenderModel.zip('');
                            singleLenderModel.city('');
                            singleLenderModel.state('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }

        })

        wesbPrincipalSellerModel.zip.subscribe(function(zip) {
            if (wesbPrincipalSellerModel.index() > -1 && wesbPrincipalSellerModel.isLoading) {
                wesbPrincipalSellerModel.isLoading = false;
                return;
            }

            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            wesbPrincipalSellerModel.city(data.City);
                            wesbPrincipalSellerModel.state(data.StateAbbr);
                        } else {

                            alert.error("Invalid Zip.");
                            wesbPrincipalSellerModel.zip('');
                            wesbPrincipalSellerModel.city('');
                            wesbPrincipalSellerModel.state('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }

        })

        wesbPrincipalSellerModel.zipIsEmpty.subscribe(function(zip) {
            wesbPrincipalSellerModel.isLoading = false;
        });

        wesbPrincipalBuyerModel.zip.subscribe(function(zip) {
            if (wesbPrincipalBuyerModel.index() > -1 && wesbPrincipalBuyerModel.isLoading) {
                wesbPrincipalBuyerModel.isLoading = false;
                return;
            }

            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            wesbPrincipalBuyerModel.city(data.City);
                            wesbPrincipalBuyerModel.state(data.StateAbbr);
                        } else {

                            alert.error("Invalid Zip.");
                            wesbPrincipalBuyerModel.zip('');
                            wesbPrincipalBuyerModel.city('');
                            wesbPrincipalBuyerModel.state('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }

        })

        wesbPrincipalBuyerModel.zipIsEmpty.subscribe(function(zip) {
            wesbPrincipalBuyerModel.isLoading = false;
        });

        wesbBorrowerModel.zip.subscribe(function(zip) {
            if (wesbBorrowerModel.index() > -1 && wesbBorrowerModel.isLoading) {
                wesbBorrowerModel.isLoading = false;
                return;
            }

            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            wesbBorrowerModel.city(data.City);
                            wesbBorrowerModel.state(data.StateAbbr);
                        } else {

                            alert.error("Invalid Zip.");
                            wesbBorrowerModel.zip('');
                            wesbBorrowerModel.city('');
                            wesbBorrowerModel.state('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }

        })

        wesbBorrowerModel.zipIsEmpty.subscribe(function(zip) {
            wesbBorrowerModel.isLoading = false;
        });

        outsideTitleModel.zip.subscribe(function(zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            outsideTitleModel.city(data.City);
                            outsideTitleModel.state(data.StateAbbr);
                        } else {
                            alert.error("Invalid Zip.");
                            outsideTitleModel.zip('');
                            outsideTitleModel.city('');
                            outsideTitleModel.state('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }
        })
        listingAgentModel.zip.subscribe(function(zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            listingAgentModel.city(data.City);
                            listingAgentModel.state(data.StateAbbr);
                        } else {
                            alert.error("Invalid Zip.");
                            listingAgentModel.zip('');
                            listingAgentModel.city('');
                            listingAgentModel.state('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }
        })

        sellingAgentModel.zip.subscribe(function(zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            sellingAgentModel.city(data.City);
                            sellingAgentModel.state(data.StateAbbr);
                        } else {
                            alert.error("Invalid Zip.");
                            sellingAgentModel.zip('');
                            sellingAgentModel.city('');
                            sellingAgentModel.state('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }
        })
        principalBuyerModel.buyerZipCode.subscribe(function(zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            principalBuyerModel.buyerCity(data.City);
                            principalBuyerModel.buyerState(data.StateAbbr);
                        } else {
                            alert.error("Invalid Zip.");
                            principalBuyerModel.buyerZipCode('');
                            principalBuyerModel.buyerCity('');
                            principalBuyerModel.buyerState('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }
        })

        independentEscrowModel.zip.subscribe(function(zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            independentEscrowModel.city(data.City);
                            independentEscrowModel.state(data.StateAbbr);
                        } else {
                            alert.error("Invalid Zip.");
                            independentEscrowModel.zip('');
                            independentEscrowModel.city('');
                            independentEscrowModel.state('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }
        })
        principalBorrowerModel.borrowerZipCode.subscribe(function(zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function(data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            principalBorrowerModel.borrowerCity(data.City);
                            principalBorrowerModel.borrowerState(data.StateAbbr);
                        } else {
                            alert.error("Invalid Zip.");
                            principalBorrowerModel.borrowerZipCode('');
                            principalBorrowerModel.borrowerCity('');
                            principalBorrowerModel.borrowerState('');
                        }
                    } else
                        session.zipCodeServiceDown();
                })
            }
        })

        orderInformationModel.scrowOffice.subscribe(function(officeId) {
            orderInformationModel.scrowOfficeName($('#scrowOffice option:selected').text());
            orderInformationModel.scrowOffice(officeId);
            if (officeId != undefined && officeId != '') {
                if (orderInformationModel.isIcloseOrder()) {
                    getIcEscrowOfficers(officeId);
                } else {
                    getOrderFormOfficers(officeId, session.defaultOpertionId(), 'Escrow Officer');
                }

            }
        

        });

        orderInformationModel.titleOffice.subscribe(function(officeId) {
            orderInformationModel.titleOfficeName($('#titleOffice option:selected').text());
            orderInformationModel.titleOffice(officeId);
            if (officeId != undefined && officeId != '') {
                if (orderInformationModel.isIcloseOrder()) {
                    getIcTitleOfficers(officeId);
                } else {
                    getOrderFormOfficers(officeId, session.defaultOpertionId(), 'Title Officer');
                }

            }
            

        });

        function getOfficeIdByProfitCostCenter(id) {
            var dataList = orderTitleOfficesList();
            ko.utils.arrayFilter(dataList,
                function(item) {
                    filteredArr = ko.utils.arrayFilter(dataList,
                        function(item) {
                            if (item.CostProfitCenterId == id) {
                                return item;
                            }
                        })
                });
        }

        //orderInformationModel.titleOffice.subscribe(function(officeId) {
        //    orderInformationModel.titleOfficeName($('#titleOffice option:selected').text());
        //    if (officeId != undefined && officeId != '')
        //        getTitleOfficers(officeId, session.defaultOpertionId(), 'Title Officer');
        //});

        orderInformationModel.scrowOfficer.subscribe(function (officerId) {
            if (!orderInformationModel.isIcloseOrder()) {
                orderInformationModel.scrowOfficerName($('#scrowOfficer option:selected').text());
                orderInformationModel.scrowOfficer(officerId);
            } else {
                orderInformationModel.iCloseEscrowOfficerName($('#scrowOfficer option:selected').text());
                orderInformationModel.iCloseEscrowOfficerId(officerId);
            }
        });

        orderInformationModel.titleOfficer.subscribe(function(officerId) {
            if (!orderInformationModel.isIcloseOrder()) {
                orderInformationModel.titleOfficerName($('#titleOfficer option:selected').text());
                orderInformationModel.titleOfficer(officerId);
            } else {
                orderInformationModel.iCloseTitleOfficerName($('#titleOfficer option:selected').text());
                orderInformationModel.iCloseTitleOfficerId(officerId);
            }
            
        });

        orderInformationModel.salesRepresentative.subscribe(function(repId) {
            orderInformationModel.iCloseSalesRepresentativeId(repId);
            orderInformationModel.iCloseSalesRepresentativeName($('#salesRepresentative option:selected').text());
        });

        recentSearchSelectedAPN.subscribe(function(APN) {
            subjectPropertyModel.offlinePropertyType('');
        });


        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            orderTitle: orderTitle,
            wesbOrderTitle: wesbOrderTitle,
            selectedTransactionTypeText: selectedTransactionTypeText,
            orderInformationModel: orderInformationModel,
            subjectPropertyModel: subjectPropertyModel,
            orderRequestModel: orderRequestModel,
            principalSellerModel: principalSellerModel,
            principalBorrowersModel: principalBorrowersModel,
            principalBorrowerModel: principalBorrowerModel,
            principalBuyersModel: principalBuyersModel,
            commonOrderFieldsModel: commonOrderFieldsModel,
            principalSellersModel: principalSellersModel,
            principalBuyersModel: principalBuyersModel,
            principalBuyerModel: principalBuyerModel,
            outsideTitleModel: outsideTitleModel,
            lenderModel: lenderModel,
            wesbPrincipalSellerModel: wesbPrincipalSellerModel,
            wesbprincipalSellersModel: wesbprincipalSellersModel,
            wesbPrincipalBuyerModel: wesbPrincipalBuyerModel,
            wesbprincipalBuyersModel: wesbprincipalBuyersModel,
            wesbBorrowerModel: wesbBorrowerModel,
            wesbBorrowersModel: wesbBorrowersModel,
            selectedOrder: selectedOrder,
            stateList: stateList,
            countyList: countyList,
            fipsInfo: fipsInfo,
            selectedState: selectedState,
            selectedCounty: selectedCounty,
            parentUserList: parentUserList,
            currentOrderModels: currentOrderModels,
            currentView: currentView,
            currentViewIndex: currentViewIndex,
            moveNext: moveNext,
            movePrevious: movePrevious,
            submitOrder: submitOrder,
            selectedOrderTypeName: selectedOrderTypeName,
            navigateButtonClick: navigateButtonClick,
            lendersModel: lendersModel,
            specialInstructions: specialInstructions,
            submitOrderModel: submitOrderModel,
            
            transactionParties: transactionParties,
            orderTitleOfficesList: orderTitleOfficesList,
            orderEscrowOfficesList: orderEscrowOfficesList,
            orderTransactionList: orderTransactionList,
            updateCustomerServices: updateCustomerServices,
            addNewObject: addNewObject,
            editObject: editObject,
            deleteObject: deleteObject,
            closeDialog: closeDialog,
            saveObject: saveObject,
            updateObject: updateObject,
            orderEscrowOfficersList: orderEscrowOfficersList,
            orderTitleOfficersList: orderTitleOfficersList,
            orderSalesRepresentativeList: orderSalesRepresentativeList,
            orderCustomerServicesList: orderCustomerServicesList,
            openOnePage: openOnePage,
            listingAgentModel: listingAgentModel,
            sellingAgentModel: sellingAgentModel,
            lenderFreqDrpList: lenderFreqDrpList,
            lenderList: lenderList,
            agentFreqDrpList: agentFreqDrpList,
            agentList: agentList,
            outsideFreqDrpList: outsideFreqDrpList,
            IndependentEscrowFreqContacts: IndependentEscrowFreqContacts,
            outsideList: outsideList,
            mySelfProfile: mySelfProfile,
            userProfile: userProfile,
            commonDialogModel: commonDialogModel,
            deletObjectConfirmed: deletObjectConfirmed,
            _deletingObjectTitle: _deletingObjectTitle,
            cancelOrder: cancelOrder,
            getWesbOrderTransactionTypes: getWesbOrderTransactionTypes,
            singleLenderModel: singleLenderModel,
            getPhoneWithExtn: utlities.getPhoneWithExtn,
            //getAddressString: getAddressString,
            independentEscrowModel: independentEscrowModel,
            outsideTitleFreqContact: outsideTitleFreqContact,
            lenderFreqContact: lenderFreqContact,
            independentEscrowFreqContact: independentEscrowFreqContact,
            listingAgentFreqContact: listingAgentFreqContact,
            sellingAgentFreqContact: sellingAgentFreqContact,
            singleLenderFreqContact: singleLenderFreqContact,
            formatCurrency: formatCurrency,
            orderTypeServiceLabel: orderTypeServiceLabel,
            financeTypes: financeTypes,
            successOrderID: successOrderID,
            orderType: orderType,
            session: session,
            currentViewSkippable: currentViewSkippable,
            skipNext: skipNext,
            recentSearches: recentSearches,
            recentResult_click: recentResult_click,
            recentSearchSelectedAPN: recentSearchSelectedAPN,
            clearSelectedProperty: clearSelectedProperty,
            iCloseCredPopup: iCloseCredPopup,
            compositionComplete: compositionComplete,
            //icUidValue: icUidValue,
            //icPassValue: icPassValue,
            salesRepresentativeList: salesRepresentativeList,
            salesRepresentative: salesRepresentative,
            setOrderForIClose: setOrderForIClose,
            getIcEscrowOfficers: getIcEscrowOfficers,
            getIcTitleOfficers: getIcTitleOfficers,
            getIcTransactionTypes: getIcTransactionTypes,
            getIcPropertyTypes: getIcPropertyTypes,
            closeICloseDialog: closeICloseDialog,
            resetOfflinePropTypes: resetOfflinePropTypes
           
        };

        function compositionComplete() {
            session.validateInput();
            if (session.backendSystem() == 'iClose')
                iCloseCredPopup();
            
        }


        function recentResult_click(result) {
            recentSearchSelectedAPN(result.APN);
            subjectPropertyModel.addressStreetName(result.Address);
            subjectPropertyModel.apn(result.APN);
            subjectPropertyModel.city(result.City);
            propertyInfoPopulateFromSession(false);

            if (subjectPropertyModel.zip() != result.ZIP.slice(0, 5)) {
                propertyInfoPopulateFromRecentSearch(true);
                subjectPropertyModel.zip(result.ZIP.slice(0, 5));
            } else
                propertyInfoPopulateFromRecentSearch(false);
        }

        function clearSelectedProperty() {
            recentSearchSelectedAPN('');
            subjectPropertyModel.reset();
        }

        function skipNext() {
            currentViewIndex(currentViewIndex() + 1);
        }

        function formatCurrency(value) {
            return '$' + value();
        }


        function openOnePage() {
            //$('#mdOrderOnePage').modal('show');
        }

        function cancelOrder() {
            session.isICOrder(false);
            orderInformationModel.isIcloseOrder(false);
            subjectPropertyModel.isOfflinePropertyType(false);
            subjectPropertyModel.isOnlinePropertyType(false);
            subjectPropertyModel.isIcPropertyType(false);
            session.icUid('');
            session.icPass('');
            router.navigate("/#orders");
            
        }


        // mapping current context object to generic objects to perform CRUD operations
        function setupLocalObject(modelId) {

            _objectModel = null;
            _objectDialogId = null;
            _objectsModel = null;
            _objectModelId = modelId;
            switch (modelId) {
            case "principalSellers":
                _objectModel = principalSellerModel;
                _objectDialogId = "#mdCreateSeller";
                _objectsModel = principalSellersModel;
                break;


            case "wesbprincipalSellers":
                _objectModel = wesbPrincipalSellerModel;
                _objectDialogId = "#mdCreateWesbSeller";
                _objectsModel = wesbprincipalSellersModel;
                break;

            case "PrincipalBorrowers":
                _objectModel = principalBorrowerModel;
                _objectDialogId = "#mdCreateBorrower";
                _objectsModel = principalBorrowersModel;
                break;

            case "Lenders":
                if (orderType() == 'wesbOrder') {
                    lenderModel.isWesbOrder(true);
                } else {
                    lenderModel.isWesbOrder(false);
                }
                _objectModel = lenderModel;
                _objectDialogId = "#mdCreateLender";
                _objectsModel = lendersModel;
                break;

            case "PrincipalsBuyers":
                _objectModel = principalBuyerModel;
                _objectDialogId = "#mdCreateBuyer";
                _objectsModel = principalBuyersModel;
                break;

            case "wesbprincipalbuyers":
                _objectModel = wesbPrincipalBuyerModel;
                _objectDialogId = "#mdCreateWesbBuyer";
                _objectsModel = wesbprincipalBuyersModel;
                break;

            case "wesbborrowers":
                _objectModel = wesbBorrowerModel;
                _objectDialogId = "#mdCreateWesbBorrower";
                _objectsModel = wesbBorrowersModel;
                break;


            }
        }

        // responsible for creating new objects which will be used in Add & Update.
        function getNewObject(modelId) {
            switch (modelId) {
            case "principalSellers":
                return new commonOrderModel.PrincipalSeller();
                break;

            case "wesbprincipalSellers":
                return new commonOrderModel.WesbPrincipalSeller()
                break;

            case "PrincipalBorrowers":
                return new commonOrderModel.PrincipalBorrower();
                break;

            case "Lenders":
                var newLender = new commonOrderModel.Lender();
                if (orderType() == 'wesbOrder') {
                    newLender.isWesbOrder(true);
                } else {
                    newLender.isWesbOrder(false);
                }
                return newLender;
                break;

            case "PrincipalsBuyers":
                return new commonOrderModel.PrincipalBuyer();
                break;

            case "wesbprincipalbuyers":
                return new commonOrderModel.WesbPrincipalBuyer();
                break;

            case "wesbborrowers":
                return new commonOrderModel.WesbBorrower();
                break;

            }
        }

        function canDeactivate() {
            recentSearchSelectedAPN('');
            console.log('orderSubmitted ' + orderSubmitted())
            if (!session.hasLoggedOut() && !orderSubmitted()) {
                var title = 'Cancel Order?';
                var msg = 'Navigate away and cancel the order? ';
                session.isLogoutVisible(false);
               
                return app.showMessage(msg, title, ['Yes', 'No '])
                    .then(function(selectedOption) {
                        if (selectedOption === 'Yes') {
                            // Call some cancel function
                            session.clearOrderForPropertyAddress();
                            subjectPropertyModel.reset();
                            session.isICOrder(false);
                            session.icUid('');
                            session.icPass('');
                            //orderInformationModel.isIcloseOrder(false);
                            session.isBusy(false);
                        }

                        session.isLogoutVisible(true);
                        return selectedOption;
                    });
            } else {
                session.clearOrderForPropertyAddress();
                subjectPropertyModel.reset();
                session.isICOrder(false);
                session.icUid('');
                session.icPass('');
                session.isBusy(false);
                return true;
            }
        };

        ////////////////////--Core Methods--//////////////////////////////

        function addNewObject() {
            $(_objectDialogId).modal('show');
            _objectModel.reset();
            $('.phone').val("");
            $('.phoneExt').val("");
            vm.objectErrors = ko.validation.group([_objectModel]);
            vm.objectErrors.showAllMessages(false);
        }

        function editObject(item, event) {
            if (_objectModel.hasOwnProperty('isLoading'))
                _objectModel.isLoading = true;
            _objectModel.update(_objectsModel.List()[item.index()]);
            $(_objectDialogId).modal('show');
            isLenderEdit(true);
            //vm.objectErrors = ko.validation.group([_objectModel]);
        }


        function deleteObject(item, event) {
            $("#mdDeleteItem").modal('show');
            _deletingObjectIndex(item.index())
            _deletingObjectTitle(item.title);
        }

        function deletObjectConfirmed() {
            _objectsModel.List.splice(_deletingObjectIndex(), 1);
            for (var i = 0; i < _objectsModel.List().length; i++) {
                _objectsModel.List()[i].index(i);
            }
            $("#mdDeleteItem").modal('hide');
        }

        function closeDialog() {
            //_objectModel.reset();
            $(_objectDialogId).modal('hide');
        }

        function closeICloseDialog() {
            if ($('#mdiCloseCred').hasClass('show')) {
                orderInformationModel.isIcloseOrder(false);
                session.isICOrder(false);
                resetOfflinePropTypes();
                $("#mdiCloseCred").modal('hide');
            }
        }

        function saveObject() {


            if (vm.objectErrors().length > 0) {
                vm.objectErrors.showAllMessages();
                setTimeout(function() {
                        $('.modal-body', '#mdCreateLender').scrollTop(500);
                    },
                    300);
                return;
            }
            _objectUsed = getNewObject(_objectModelId);
            _objectModel.index(_objectsModel.List().length);
            _objectUsed.update(_objectModel);
            _objectsModel.List.push(_objectUsed);
            //if (lenderModel.newFrequentContact() == true) {
            //    var lender = 2;
            //    if (_objectModel != null) {
            //        saveFrequentContact(_objectModel, lender).done(function (data) {
            //            console.log(data);
            //            if (data.HasErrors == false) {
            //                if (data.Messages["0"].Message) {
            //                    alert.success(data.Messages["0"].Message);
            //                   
            //                }
            //            }
            //            else alert.error("An error occurred while saving contact");
            //        });
            //    }
            //}


            _objectModel.reset();
            $(_objectDialogId).modal('hide');
        }

        function updateObject() {
            if (vm.objectErrors().length > 0) {
                vm.objectErrors.showAllMessages();
                return;
            }

            _objectUsed = getNewObject(_objectModelId);
            _objectUsed.update(_objectModel);
            _objectsModel.List.splice(_objectModel.index(), 1, _objectUsed);

            _objectModel.reset();
            $(_objectDialogId).modal('hide');
        }

       

        function navigateButtonClick(item, event) {
            if (item.index() > currentViewIndex()) {

                for (var i = 0; i < item.index(); i++) {
                    if (currentOrderModels()[i].isSkippable == undefined ||
                        currentOrderModels()[i].isSkippable == false) {
                        //  commented for development, this need to be removed.
                        vm.validationErrors = ko.validation.group(currentOrderModels()[i]);
                        if (vm.validationErrors().length > 0) {
                            vm.validationErrors.showAllMessages();
                            return;
                        } else {
                            if (!currentOrderModels()[i].isCustomValid()) {
                                return;
                            }
                        }
                    }

                }
            } else {
                vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
                vm.objectErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
            }

            currentViewIndex(item.index());

            if (item.index() == currentOrderModels().length - 1)
                buildPreviewModel();
        }


        return vm;


        function activate(orderType) {
            recentSearches(session.getRecentSearchesFromLocalStorage());
            //orderInformationModel.isIcloseOrder(false);
            orderSubmitted(false);
            var userId = session.userId;
            console.log('activate called');
            _deletingObjectIndex(-1);
            _deletingObjectTitle("");
            orderSubmitted(false);
            utlities.getStatesList().done(function(result) {
                stateList([]);
                countyList([]);
                stateList(result);

            });
            GetFinanceTypes();
            selectedOrderTypeId(parseInt(orderType));
            setupOrder();
            //orderInformationModel.date(moment().format('MM/DD/YYYY'));
            orderInformationModel.date(moment.utc());
            orderInformationModel.isEscrowOrder(false);
            orderInformationModel.isTitleOrder(false);
           
           
            

            //any profileID available, get property information and set information to the subject property model
            //Once model is set, empty out session and temp property model
            //if (session.profileId != undefined && session.profileId != null) {
            //    getPropertyInformationFromCurrentProfile(session.profileId)
            //}


            switch (selectedOrderTypeId()) {
            case 47:
            case 98:
                orderInformationModel.isTitleOrder(true);
                break;
            case 48:
            case 99:
                orderInformationModel.isEscrowOrder(true);
                break;
            case 17:
            case 97:
                orderInformationModel.isEscrowOrder(true);
                orderInformationModel.isTitleOrder(true);
                break;
            default:
                orderInformationModel.isEscrowOrder(false);
                orderInformationModel.isTitleOrder(false);
                break;
            }


            agentDropdown(userId, 1);
            lenderDropdown(userId, 2);
            outsideDropdown(userId, 3);


            populateIndependentEscrowFreqContacts(userId);
            if (orderInformationModel.isEscrowOrder()) {
                 getEscrowOffice(session.defaultOpertionId());
            }
            if (orderInformationModel.isTitleOrder()) {
                getTitleOffice(session.defaultOpertionId());
            }
            

            LoadTransactionTypes();
            
            currentViewIndex(0);
            
            
        }


        function iCloseCredPopup() {
            $('#mdiCloseCred').modal('show');
        }

        function setSubjectPropertyFromSelectedProperty() {
            if (session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null) {
                propertyInfoPopulateFromSession(true);
                //subjectPropertyModel.presentOwner(session.orderForPropertyAddress.owner());
                subjectPropertyModel.reset();
                subjectPropertyModel.addressStreetName(session.orderForPropertyAddress.address());
                subjectPropertyModel.zip(session.orderForPropertyAddress.zip())
                if (session.orderForPropertyAddress.apn().length > 0) {
                    recentSearchSelectedAPN(session.orderForPropertyAddress.apn());
                    subjectPropertyModel.apn(session.orderForPropertyAddress.apn());
                }
            }
        }

        function LoadTransactionTypes() {
            subjectPropertyModel.title = 'Subject Property';
            //WESB/SP360 
            if (selectedOrderTypeId() == 97 || selectedOrderTypeId() == 98 || selectedOrderTypeId() == 99) {
                orderType('wesbOrder');
                subjectPropertyModel.title = 'Property Information';
                getWesbOrderTransactionTypes();
                orderTypeServiceLabel('Order Type');
                listingAgentModel.IsWesbOrder(true);
                sellingAgentModel.IsWesbOrder(true);
                lenderModel.isWesbOrder(true);
                subjectPropertyModel.isOnlinePropertyType(false);
                subjectPropertyModel.isOfflinePropertyType(true);
                
                
                
                subjectPropertyModel.viewName = "orderModule/_wesbSubjectProperty.html"

            } else {
                orderType('online');
                getOrderTransactionTypes();
                orderTypeServiceLabel('Service');
                listingAgentModel.IsWesbOrder(false);
                sellingAgentModel.IsWesbOrder(false);
                lenderModel.isWesbOrder(false);
                subjectPropertyModel.isOfflinePropertyType(false);
                subjectPropertyModel.isOnlinePropertyType(true); 
                subjectPropertyModel.isIcPropertyType(false);
                subjectPropertyModel.viewName = "orderModule/_subjectProperty.html"
            }
        }


        function moveNext() {
            // commented for development, this need to be removed.
            vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
            if (vm.validationErrors().length > 0) {
                vm.validationErrors.showAllMessages();
                return;
            }
            if (currentOrderModel().isCustomValid()) {
                session.isICOrder(orderInformationModel.isIcloseOrder());
                currentViewIndex(currentViewIndex() + 1);

            }

            
            if (currentViewIndex() == currentOrderModels().length - 1) {
                buildPreviewModel();
            }

        }

        function buildPreviewModel() {

            for (var i = 0; i < currentOrderModels().length; i++) {
                if (currentOrderModels()[i].GetModelData) {
                    currentOrderModels()[i].GetModelData();
                }
            }
        }

        function movePrevious() {
            currentViewIndex(currentViewIndex() - 1);
            vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
            vm.objectErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
            if (orderInformationModel.isIcloseOrder() != session.isICOrder()) {
                orderInformationModel.isIcloseOrder(session.isICOrder());
            }
        }

        function submitOrder() {
            session.isBusy(true);
            console.log(session.isBusy())
            console.log('order submitted');
            console.log(selectedOrderTypeId());
            console.log(orderInformationModel.transactionType());
            transactionParties([]);
            var office = ko.observableArray([]);
            var customerServices = ko.observableArray([]);
            // for this release order will not be created. so return it back from this point.
            //return;

            var url = config.commonOrderUrl;

            var addToFrequentContactsChecked = false;
            var frequentContactsId = 0;

            /////Lender
            if (lendersModel.List().length > 0) {
                for (var i = 0; i < lendersModel.List().length; i++) {
                    addToFrequentContactsChecked = false;
                    frequentContactsId = 0;
                    var lenderPhoneNumber =
                    {
                        "PhoneNumber": utils.unmaskPhoneNumber(lendersModel.List()[i].phone),
                        "Extension": lendersModel.List()[i].phoneExt(),
                        "PhoneType": "Business"
                    }
                    var lenderFaxNumber =
                    {
                        "Extension": lendersModel.List()[i].faxExt(),
                        "PhoneType": "Fax",
                        "PhoneNumber": utils.unmaskPhoneNumber(lendersModel.List()[i].fax),
                    }
                    var lenderPhone = ko.observableArray([]);
                    //console.log("lenderPhoneNumber");
                    //console.log(lenderPhoneNumber);
                    //console.log("lenderFaxNumber");
                    //console.log(lenderFaxNumber);
                    lenderPhone.push(lenderPhoneNumber);
                    lenderPhone.push(lenderFaxNumber);


                    if (lendersModel.List()[i].addfreqContacts() != null &&
                        lendersModel.List()[i].addfreqContacts() != undefined) {
                        if (lendersModel.List()[i].contact() == "0") {
                            addToFrequentContactsChecked = lendersModel.List()[i].addfreqContacts();
                            frequentContactsId = 2;
                        }
                    }
                    //console.log("lenderPhone");
                    //console.log(lenderPhone);
                    
                    
                    var lenders =
                    {
                        "Name":
                        {
                            "FirstName": lendersModel.List()[i].firstName(),
                            "LastName": lendersModel.List()[i].lastName(),
                        },
                        "Address":
                        {
                            "Address1": lendersModel.List()[i].address(),
                            "Zip": lendersModel.List()[i].zip(),
                            "City": lendersModel.List()[i].city(),
                            "State": lendersModel.List()[i].state(),
                        },
                        "LoanNumber": lendersModel.List()[i].accountNumber(),
                        "LoanAmount": lendersModel.List()[i].loanAmount(),
                        "Email": lendersModel.List()[i].email(),
                        "Company": lendersModel.List()[i].company(),
                        "LoanType": lendersModel.List()[i].loanType(),
                        "LoanFinancingType": lendersModel.List()[i].financeType(),
                        "PhoneNumbers": lenderPhone(),
                        "TransactionPartyType": "Lender",
                        "AddToFrequentContacts": addToFrequentContactsChecked,
                        "FrequentContactsId": frequentContactsId,
                    }
                    if (lendersModel.List()[i].mortgageBroker()) {
                        lenders.TransactionPartyType = "MortgageBroker";
                    }
                    transactionParties.push(lenders);
                }

            }

            if (wesbBorrowersModel.List().length > 0) {
                for (var i = 0; i < wesbBorrowersModel.List().length; i++) {
                    var borrowerCellPhoneNumber =
                    {
                        "PhoneNumber": wesbBorrowersModel.List()[i].cellPhone(),
                        "PhoneType": "Mobile"
                    };

                    var borrowerHomePhone =
                    {
                        "PhoneNumber": wesbBorrowersModel.List()[i].borrowerHomePhone(),
                        "PhoneType": "Home"
                    };

                    var borrowerPhoneNumbers = [];
                    borrowerPhoneNumbers.push(borrowerCellPhoneNumber);
                    borrowerPhoneNumbers.push(borrowerHomePhone);

                    var borrower =
                    {
                        "Name":
                        {
                            "FirstName": wesbBorrowersModel.List()[i].borrowerFirstName(),
                            "LastName": wesbBorrowersModel.List()[i].borrowerLastName(),
                        },
                        "Address":
                        {
                            "Address1": wesbBorrowersModel.List()[i].address(),
                            "Zip": wesbBorrowersModel.List()[i].zip(),
                            "City": wesbBorrowersModel.List()[i].city(),
                            "State": wesbBorrowersModel.List()[i].state(),
                        },
                        "PhoneNumbers": borrowerPhoneNumbers,
                        "Email": wesbBorrowersModel.List()[i].email(),
                        "TransactionPartyType": "BuyerBorrower"
                    }
                    transactionParties.push(borrower);
                }
            }


            //////Indepdent Escrow

            var indepdentEscrowPhone =
            {
                "PhoneNumber": utils.unmaskPhoneNumber(independentEscrowModel.phone),
                "Extension": independentEscrowModel.phoneExt,
                "PhoneType": "Business"
            }
            var indepdentEscrowFax =
            {
                "Extension": "",
                "PhoneType": "Fax",
                "PhoneNumber": utils.unmaskPhoneNumber(independentEscrowModel.fax),
            }
            var indepdentPhone = ko.observableArray([]);
            indepdentPhone.push(indepdentEscrowPhone);
            indepdentPhone.push(indepdentEscrowFax);

            var independentEscrow =
            {
                "Name":
                {
                    "FirstName": independentEscrowModel.firstName,
                    "LastName": independentEscrowModel.lastName,
                },
                "Address":
                {
                    "Address1": independentEscrowModel.address,
                    "Zip": independentEscrowModel.zip,
                    "City": independentEscrowModel.city,
                    "State": independentEscrowModel.state,
                },
                "Email": independentEscrowModel.email,
                "Company": independentEscrowModel.company,
                "PhoneNumbers": indepdentPhone(),
                "TransactionPartyType": "IndependentEscrow"
            }
            transactionParties.push(independentEscrow);
            //////Buyer 
            if (principalBuyersModel.List().length > 0) {
                for (var i = 0; i < principalBuyersModel.List().length; i++) {

                    var orderBuyerWorkPhone =
                    {
                        "Extension": principalBuyersModel.List()[i].workPhoneExt(),
                        "PhoneType": "Business",
                        "PhoneNumber": utils.unmaskPhoneNumber(principalBuyersModel.List()[i].buyerWorkPhone)

                    }
                    var orderBuyerHomePhone =
                    {
                        "Extension": principalBuyersModel.List()[i].homePhoneExt(),
                        "PhoneType": "Home",
                        "PhoneNumber": utils.unmaskPhoneNumber(principalBuyersModel.List()[i].buyerHomePhone),
                    }

                    var buyerPhone = ko.observableArray([]);
                    buyerPhone.push(orderBuyerWorkPhone);
                    buyerPhone.push(orderBuyerHomePhone);

                    var buyers =
                    {
                        "Name":
                        {
                            "FirstName": principalBuyersModel.List()[i].buyerFirstName(),
                            "LastName": principalBuyersModel.List()[i].buyerLastName()
                        },
                        "Email": principalBuyersModel.List()[i].buyerEmail(),
                        "Address": {
                            "Address1": principalBuyersModel.List()[i].buyerAddress(),
                            "Zip": principalBuyersModel.List()[i].buyerZipCode(),
                            "City": principalBuyersModel.List()[i].buyerCity(),
                            "State": principalBuyersModel.List()[i].buyerState(),
                        },

                        "PhoneNumbers": buyerPhone(),
                        "CoSellerFirstName ": principalBuyersModel.List()[i].coBuyerFirstName(),
                        "CoSellerLastName ": principalBuyersModel.List()[i].cobuyerLastName(),
                        "TransactionPartyType": "BuyerBorrower"
                    }
                    console.log(buyers);
                    transactionParties.push(buyers);
                }
            }
            //////Sellers
            if (principalSellersModel.List().length > 0) {
                for (var i = 0; i < principalSellersModel.List().length; i++) {

                    var orderSellerWorkPhone =
                    {
                        "Extension": principalSellersModel.List()[i].workPhonExt(),
                        "PhoneType": "Business",
                        "PhoneNumber": utils.unmaskPhoneNumber(principalSellersModel.List()[i].sellerWorkPhone)

                    }
                    var orderSellerHomePhone =
                    {
                        "Extension": principalSellersModel.List()[i].homePhonExt(),
                        "PhoneType": "Home",
                        "PhoneNumber": utils.unmaskPhoneNumber(principalSellersModel.List()[i].sellerHomePhone),
                    }

                    var sellerPhone = ko.observableArray([]);
                    sellerPhone.push(orderSellerWorkPhone);
                    sellerPhone.push(orderSellerHomePhone);
                    var sellers =
                    {
                        "Name":
                        {
                            "FirstName": principalSellersModel.List()[i].sellerFirstName(),
                            "LastName": principalSellersModel.List()[i].sellerLastName()
                        },
                        "PhoneNumbers": sellerPhone,
                        "CoSellerFirstName": principalSellersModel.List()[i].coSellerFirstName(),
                        "CoSellerLastName": principalSellersModel.List()[i].coSellerLastName(),
                        "TransactionPartyType": "Seller"
                    }
                    console.log(sellers);
                    transactionParties.push(sellers);
                }

            }

            //WESB Principal Sellers
            if (wesbprincipalSellersModel.List().length > 0) {
                for (var i = 0; i < wesbprincipalSellersModel.List().length; i++) {
                    var wesbSellerWorkPhone =
                    {
                        "Extension": "",
                        "PhoneType": "Home",
                        "PhoneNumber": utils.unmaskPhoneNumber(wesbprincipalSellersModel.List()[i].sellerHomePhone)

                    }
                    var wesbSellerHomePhone =
                    {
                        "Extension": "",
                        "PhoneType": "Business",
                        "PhoneNumber": utils.unmaskPhoneNumber(wesbprincipalSellersModel.List()[i].cellPhone),
                    }

                    var wesbsellerPhone = ko.observableArray([]);
                    wesbsellerPhone.push(wesbSellerWorkPhone);
                    wesbsellerPhone.push(wesbSellerHomePhone);
                    var wesbsellers =
                    {
                        "Name":
                        {
                            "FirstName": wesbprincipalSellersModel.List()[i].sellerFirstName(),
                            "LastName": wesbprincipalSellersModel.List()[i].sellerLastName()
                        },
                        "PhoneNumbers": wesbsellerPhone,
                        "Email": wesbprincipalSellersModel.List()[i].email(),
                        "Address":
                        {
                            "Address1": wesbprincipalSellersModel.List()[i].address(),
                            "City": wesbprincipalSellersModel.List()[i].city(),
                            "State": wesbprincipalSellersModel.List()[i].state(),
                            "Zip": wesbprincipalSellersModel.List()[i].zip()
                        },
                        "TransactionPartyType": "Seller"
                    }
                    console.log(wesbsellers);
                    transactionParties.push(wesbsellers);
                }
            }
            //WESB Principal Buyers
            if (wesbprincipalBuyersModel.List().length > 0) {
                for (var i = 0; i < wesbprincipalBuyersModel.List().length; i++) {
                    var wesbBuyersHomePhone =
                    {
                        "Extension": "",
                        "PhoneType": "Home",
                        "PhoneNumber": utils.unmaskPhoneNumber(wesbprincipalBuyersModel.List()[i].buyerHomePhone)

                    }
                    var wesbBuyersCellPhone =
                    {
                        "Extension": "",
                        "PhoneType": "Business",
                        "PhoneNumber": utils.unmaskPhoneNumber(wesbprincipalBuyersModel.List()[i].cellPhone),
                    }

                    var wesbBuyersPhone = ko.observableArray([]);
                    wesbBuyersPhone.push(wesbBuyersHomePhone);
                    wesbBuyersPhone.push(wesbBuyersCellPhone);
                    var wesbBuyers =
                    {
                        "Name":
                        {
                            "FirstName": wesbprincipalBuyersModel.List()[i].buyerFirstName(),
                            "LastName": wesbprincipalBuyersModel.List()[i].buyerLastName()
                        },
                        "PhoneNumbers": wesbBuyersPhone,
                        "Email": wesbprincipalBuyersModel.List()[i].email(),
                        "Address":
                        {
                            "Address1": wesbprincipalBuyersModel.List()[i].address(),
                            "City": wesbprincipalBuyersModel.List()[i].city(),
                            "State": wesbprincipalBuyersModel.List()[i].state(),
                            "Zip": wesbprincipalBuyersModel.List()[i].zip()
                        },
                        "TransactionPartyType": "BuyerBorrower"
                    }
                    console.log(wesbBuyers);
                    transactionParties.push(wesbBuyers);
                }
            }

            ////////Order Requester 
            //var orderRequesterPhone =
            //{
            //    "PhoneNumbers":
            //        {
            //            "Extension": orderRequestModel.phoneExt(),
            //            "PhoneType": "Business",
            //            "PhoneNumber": orderRequestModel.phone()
            //        }

            //}
            //var orderRequesterFax =
            //    {
            //        "PhoneNumbers":
            //        {
            //            "Extension": orderRequestModel.faxExt(),
            //            "PhoneType": "Fax",
            //            "PhoneNumber": orderRequestModel.fax()
            //        }
            //    }
            //var requesterPhone = ko.observableArray([]);
            //requesterPhone.push(orderRequesterPhone);
            //requesterPhone.push(orderRequesterFax);

            //var orderRequester =
            //     {
            //         "Address": {
            //             "Company": orderRequestModel.company(),
            //             "Address1": orderRequestModel.address(),
            //             "Zip": orderRequestModel.zip(),
            //             "City": orderRequestModel.city(),
            //             "State": orderRequestModel.state(),
            //         },
            //         "Name":
            //            {
            //                "FirstName": orderRequestModel.firstName(),
            //                "LastName": orderRequestModel.lastName(),
            //            },
            //         "PhoneNumbers": requesterPhone(),
            //         "Email": orderRequestModel.email(),
            //         "TransactionPartyType": "SalesRep",
            //     }
            //transactionParties.push(orderRequester);


            ////// Listing Agent
            var listingAgentPhone =
            {
                "Extension": listingAgentModel.phoneExt(),
                "PhoneType": "Business",
                "PhoneNumber": utils.unmaskPhoneNumber(listingAgentModel.phone)
            }
            var listingAgentFax =
            {
                "Extension": "",
                "PhoneType": "Fax",
                "PhoneNumber": utils.unmaskPhoneNumber(listingAgentModel.fax)
            }
            var lstingAgentPhone = ko.observableArray([]);
            lstingAgentPhone.push(listingAgentPhone);
            lstingAgentPhone.push(listingAgentFax);

            addToFrequentContactsChecked = false;
            frequentContactsId = 0;

            if (listingAgentModel.addfreqContacts() != null && listingAgentModel.addfreqContacts() != undefined) {
                if (listingAgentModel.contact() == "0") {
                    addToFrequentContactsChecked = listingAgentModel.addfreqContacts();
                    frequentContactsId = 1;
                }
            }

            var listingAgentRequest =
            {
                "Address": {
                    "Company": listingAgentModel.company(),
                    "Address1": listingAgentModel.address(),
                    "Zip": listingAgentModel.zip(),
                    "City": listingAgentModel.city(),
                    "State": listingAgentModel.state(),
                },
                "Name":
                {
                    "FirstName": listingAgentModel.firstName(),
                    "LastName": listingAgentModel.lastName(),
                },
                "Company": listingAgentModel.company(),
                "PhoneNumbers": lstingAgentPhone(),
                "Email": listingAgentModel.email(),
                "TransactionPartyType": "ListingBroker",
                "AddToFrequentContacts": addToFrequentContactsChecked,
                "FrequentContactsId": frequentContactsId,
            }
            transactionParties.push(listingAgentRequest);

            ////// Selling Agent
            var sellingAgentPhone =
            {
                "Extension": sellingAgentModel.phoneExt(),
                "PhoneType": "Business",
                "PhoneNumber": utils.unmaskPhoneNumber(sellingAgentModel.phone)
            }
            var sellingAgentFax =
            {
                "Extension": "",
                "PhoneType": "Fax",
                "PhoneNumber": utils.unmaskPhoneNumber(sellingAgentModel.fax)
            }
            var sellingAgentPhNumber = ko.observableArray([]);
            sellingAgentPhNumber.push(sellingAgentPhone);
            sellingAgentPhNumber.push(sellingAgentFax);

            addToFrequentContactsChecked = false;
            frequentContactsId = 0;

            if (sellingAgentModel.addfreqContacts() != null && sellingAgentModel.addfreqContacts() != undefined) {
                if (sellingAgentModel.contact() == "0") {
                    addToFrequentContactsChecked = sellingAgentModel.addfreqContacts();
                    frequentContactsId = 1;
                }
            }

            var sellingAgentRequest =
            {
                "Address": {
                    "Company": sellingAgentModel.company(),
                    "Address1": sellingAgentModel.address(),
                    "Zip": sellingAgentModel.zip(),
                    "City": sellingAgentModel.city(),
                    "State": sellingAgentModel.state(),
                },
                "Name":
                {
                    "FirstName": sellingAgentModel.firstName(),
                    "LastName": sellingAgentModel.lastName(),
                },
                "PhoneNumbers": sellingAgentPhNumber(),
                "Company": sellingAgentModel.company(),
                "Email": sellingAgentModel.email(),
                "TransactionPartyType": "SellingBroker",
                "AddToFrequentContacts": addToFrequentContactsChecked,
                "FrequentContactsId": frequentContactsId,
            }
            transactionParties.push(sellingAgentRequest);

            /////Outside Title

            var outSidePhone =
            {
                "Extension": outsideTitleModel.phoneExt(),
                "PhoneType": "Business",
                "PhoneNumber": utils.unmaskPhoneNumber(outsideTitleModel.phone)

            }
            var outSideFax =
            {
                "Extension": "",
                "PhoneType": "Fax",
                "PhoneNumber": utils.unmaskPhoneNumber(outsideTitleModel.fax)
            }
            var outSideTitlePhone = ko.observableArray([]);
            outSideTitlePhone.push(outSidePhone);
            outSideTitlePhone.push(outSideFax);

            var outSideTitleRequest =
            {
                "Address": {
                    "Company": outsideTitleModel.company(),
                    "Address1": outsideTitleModel.address(),
                    "Zip": outsideTitleModel.zip(),
                    "City": outsideTitleModel.city(),
                    "State": outsideTitleModel.state(),
                },
                "Name":
                {
                    "FirstName": outsideTitleModel.firstName(),
                    "LastName": outsideTitleModel.lastName(),
                },
                "PhoneNumbers": outSideTitlePhone(),
                "Email": outsideTitleModel.email(),
                "TransactionPartyType": "OutsideTitle",
            }
            transactionParties.push(outSideTitleRequest);

            ///// Single Lender

            var singleLenderPhone = {
                "Extension": singleLenderModel.phoneExt(),
                "PhoneType": "Business",
                "PhoneNumber": utils.unmaskPhoneNumber(singleLenderModel.phone)

            }
            var singleLenderFax =
            {
                "Extension": "",
                "PhoneType": "Fax",
                "PhoneNumber": utils.unmaskPhoneNumber(singleLenderModel.fax)
            }
            var singleLenderPhNumber = ko.observableArray([]);
            singleLenderPhNumber.push(singleLenderPhone);
            singleLenderPhNumber.push(singleLenderFax);

            var singleLenderRequest =
            {
                "Address": {
                    "Address1": singleLenderModel.address(),
                    "Zip": singleLenderModel.zip(),
                    "City": singleLenderModel.city(),
                    "State": singleLenderModel.state(),
                },
                "Name":
                {
                    "FirstName": singleLenderModel.firstName(),
                    "LastName": singleLenderModel.lastName(),
                },
                "Company": singleLenderModel.company(),
                "PhoneNumbers": singleLenderPhNumber(),
                "Email": singleLenderModel.email(),
                "LoanType": singleLenderModel.loanType(),
                "LoanAmount": singleLenderModel.loanAmount(),
                "LoanNumber": singleLenderModel.accountNumber(),
                "TransactionPartyType": "Lender"
                }
            if (singleLenderModel.mortgageBroker()) {
                singleLenderRequest.TransactionPartyType = "MortgageBroker";
            }
            transactionParties.push(singleLenderRequest);
            var propertyType;
            if (session.isICOrder()) {
                propertyType = subjectPropertyModel.icPropertyType();
            } else {
                if (subjectPropertyModel.offlinePropertyType() != 'undefined') {
                    propertyType = subjectPropertyModel.offlinePropertyType();
                } else {
                    propertyType = subjectPropertyModel.propertyType();
                }
            }

            

            if (!orderInformationModel.isIcloseOrder()) {
                if (orderInformationModel.titleOffice() != undefined) {
                    var titleOffice = {
                        "OfficeId": orderInformationModel.titleOffice(),
                        "OperationId": "",
                        "BrandId": "",
                        "CostProfitCenterId": orderInformationModel.titleCostProfitCenterId(),
                        "IsTitle": true
                    }
                    office.push(titleOffice);
                }
                if (orderInformationModel.scrowOffice() != undefined) {
                    var scrowOffice =
                    {
                        "OfficeId": orderInformationModel.scrowOffice(),
                        "OperationId": "",
                        "BrandId": "",
                        "CostProfitCenterId": orderInformationModel.escrowCostProfitCenterId(),
                        "IsEscrow": true
                    }
                    office.push(scrowOffice);
                }
            } else {
                if (orderInformationModel.titleOffice() != undefined) {
                    var titleOffice = {
                        "OfficeId": orderInformationModel.titleOffice(),
                        "Name": orderInformationModel.titleOfficeName(),
                        "IsTitle": true
                    }
                    office.push(titleOffice);
                }
                if (orderInformationModel.scrowOffice() != undefined) {
                    var scrowOffice =
                    {
                        "OfficeId": orderInformationModel.scrowOffice(),
                        "Name": orderInformationModel.scrowOfficeName(),
                        "IsEscrow": true
                    }
                    office.push(scrowOffice);
                }
            }
            

            var limitedCoveragePolicy = orderInformationModel.lcp() == "Yes" ? true : false;

            var request = {
                "UserId:": session.userId,
                "OrderId": -1,
                "BrandId": session.brandId,
                "OperationId": session.defaultOpertionId,
                "ServiceId": selectedOrderTypeId(),
                "EscrowOfficerId": orderInformationModel.scrowOfficer(),
                "IsICloseOrder": orderInformationModel.isIcloseOrder(),
                "ICloseEscrowOfficerId": orderInformationModel.iCloseEscrowOfficerId(),
                "ICloseEscrowOfficerName": orderInformationModel.iCloseEscrowOfficerName(),
                "ICloseSalesRepresentativeName": orderInformationModel.iCloseSalesRepresentativeName(),
                "ICloseSalesRepresentativeId": orderInformationModel.iCloseSalesRepresentativeId(),
                "TitleOfficerId": orderInformationModel.titleOfficer(),
                "ICloseTitleOfficerId": orderInformationModel.iCloseTitleOfficerId(),
                "ICloseTitleOfficerName": orderInformationModel.iCloseTitleOfficerName(),
                "BackendSystem": "",
                "IsBackendOrder": true,//This is being set to show its a online order. If false, its offline order.
                "PaymentId": "",
                "PropertyAddress": "",
                "PropertyAddressNumber": subjectPropertyModel.addressNumber,
                "PropertyAddressStreetName": subjectPropertyModel.addressStreetName,
                "PropertyCity": subjectPropertyModel.city,
                "PropertyState": subjectPropertyModel.state,
                "PropertyZip": subjectPropertyModel.zip,
                "PropertyCounty": subjectPropertyModel.county(),
                "ProviderType": "",
                "OrderType": orderInformationModel.orderType(), //need to replace
                "TransactionType": orderInformationModel.transactionType(),
                "TransactionTypeCode": orderInformationModel.transactionType(),
                "TransactionTypeName": orderInformationModel.TransactionTypeName(),
                "TransactionTypeId": orderInformationModel.TransactionTypeId(),
                "OrderStatus": "Submitted",
                "PropertyApn": subjectPropertyModel.apn,
                "PropertyType": propertyType, //subjectPropertyModel.propertyType(),
                "PropertyTypeCode": propertyType,
                "PrimaryOfficeRoutingNumber": "",
                "TransactionAmount": orderInformationModel.transactionAmount().replace(',', ''),
                "Instructions": "",
                "OrderNumber": "",
                "LimitedCoveragePolicy": limitedCoveragePolicy,
                "CashSale": "",
                "SubmittedByEmail": orderRequestModel.email,
                "SubmittedByFirstName": orderRequestModel.firstName,
                "SubmittedByLastName": orderRequestModel.lastName,
                "SubmittedByUserGuid": session.userId,
                "Comments": specialInstructions.instructions,
                "EstimatedCOEDate": orderInformationModel.estimatedCloseDate(),
                "CreateDate": orderInformationModel.date,
                "Offices": office(),
                "TransactionParties": transactionParties(),
                "CustomerServices": customerServicesRequested(),
                "ProviderUsername": "",
                "ProviderPassword": ""

            };

            switch (session.backendSystem()) {
                case "iClose":
                    if (session.isICOrder()) {
                        request.BackendSystem = "";
                        request.ProviderType = "40";
                        break;
                    } else {
                        request.BackendSystem = "0";
                        request.ProviderType = "0";
                        break;
                    }
                case "SP360":
                    request.BackendSystem = "";//"10";
                    request.ProviderType = "30";
                    break;
                case "None":
                    request.BackendSystem = "";
                    request.ProviderType = "0";
                    break;
                
            
            };
            
            if (orderInformationModel.isIcloseOrder()) {
                request.TransactionType = 0;
                request.TransactionTypeCode = 'UNKN';
                request.ProviderUsername = session.icUid;
                request.ProviderPassword = session.icPass;
            }
            
            //request.TransactionTypeId(transType);
            //request.TransactionTypeName(name);

            console.log("transactionParties() : ");
            console.log(transactionParties());

            console.log("request : ");
            console.log(request);

            https.post(url, request)
                .then(function(data) {
                    if (data.Result.Status == 1) {
                        successOrderID = null;
                        orderSubmitted(true);
                        var message = '';
                        commonDialogModel.orderId(data.OrderId);
                        commonDialogModel.showSpText(false);
                        commonDialogModel.showIcText(false);
                        commonDialogModel.showManualText(false);
                        if (orderType() == 'online' || session.backendSystem() == '' || session.backendSystem() == null) {
                            commonDialogModel.showManualText(true);
                            commonDialogModel.showSpText(false);
                            commonDialogModel.showIcText(false);
                            message = orderTitle() + " submitted successfully";
                        }
                        if (session.backendSystem() == 'SP360') {
                            commonDialogModel.showSpText(true);
                            commonDialogModel.showIcText(false);
                            commonDialogModel.showManualText(false);
                            message = wesbOrderTitle() + " submitted successfully";
                        }
                        if (session.backendSystem() == 'iClose' && orderInformationModel.isIcloseOrder()) {
                            commonDialogModel.showManualText(false);
                            commonDialogModel.showSpText(false);
                            commonDialogModel.showIcText(true);
                            message = wesbOrderTitle() + " submitted successfully";
                        }
                        if (session.backendSystem() == 'iClose' && !orderInformationModel.isIcloseOrder()) {
                            commonDialogModel.showSpText(false);
                            commonDialogModel.showIcText(false);
                            commonDialogModel.showManualText(true);
                            message = wesbOrderTitle() + " submitted successfully";
                        }

                        if (data.HasErrors == true) {
                            var msgError = "";
                            for (var i = 0; i < data.Messages.length; i++) {
                                if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                                    //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                                    //alert.error('Internal Error. Please contact Administrator.');
                                    if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                        msgError = msgError + data.Messages[i].Message + "\n";
                                    }
                                }
                            }
                            msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                            commonDialogModel.messagehtml(msgError);
                        }
                        commonDialogModel.IsOrderDialog(true);
                        commonDialogModel.show("Order Submission Status",
                            "Should you have any questions, please contact your " +
                            session.brandName() +
                            " Sales Executive.");
                        orderInformationModel.isIcloseOrder(false);
                        subjectPropertyModel.isOfflinePropertyType(false);
                        subjectPropertyModel.isOnlinePropertyType(false);
                        subjectPropertyModel.isIcPropertyType(false);
                        orderCustomerServicesList([]);
                        customerServicesRequested([]);
                        session.isICOrder(false);
                        session.icUid('');
                        session.icPass('');
                        resetOfflinePropTypes();
                        //router.navigate("/#orders");
                    } else {
                        for (var i = 0; i < data.Result.AllEventMessages.length; i++) {

                            if (data.Result.AllEventMessages[i].EventType == 0) {
                                alert.info(data.Result.AllEventMessages[i].Description);
                                orderSubmitted(false);
                                session.isBusy(false);
                            }
                            if (data.Result.AllEventMessages[i].EventType == 1) {
                                console.log("Error:" + data.Result.AllEventMessages[i].Description);
                                alert.error("Internal Error. Please contact Administrator.");
                                orderSubmitted(false);
                                session.isBusy(false);
                            }
                            if (data.Result.AllEventMessages[i].EventType == 2) {
                                alert.error("Validation Error:" + data.Result.AllEventMessages[i].Description,
                                    orderTitle());
                                orderSubmitted(false);
                                session.isBusy(false);
                            }


                            //if (data.Messages[i].ExceptionMessage != "") {
                            //    console.log("Error:" + data.Messages[i].ExceptionMessage, orderTitle());
                            //    alert.error('Internal Error. Please contact Administrator.');
                            //    orderSubmitted(false);
                            //    session.isBusy(false);
                            //}
                        }
                        session.icUid('');
                        session.icPass('');
                    }

                })
                .fail(function(data) {
                    var error = JSON.parse(data.responseText)
                    console.log(error);
                    session.isBusy(false);
                    session.icUid('');
                    session.icPass('');
                })

        }

        function resetOfflinePropTypes() {
            subjectPropertyModel.offlinePropertyTypeList = ko.observableArray([

                { key: "FamilyResidential14", value: "1-4 Family Residential" },
                { key: "AgriculturalLand", value: "Agriculture" },
                { key: "Commercial", value: "Commercial" },
                { key: "Condominium", value: "Condominium" },
                { key: "CoOp", value: "Cooperative" },
                { key: "Industrial", value: "Industrial" },
                { key: "ManufacturedHome", value: "Manufactured Housing" },
                { key: "MultiFamily24", value: "Multi Family" },
                { key: "Other", value: "Other" },
                { key: "RetailStructureStripCenter", value: "Retail Structure/Strip Center" },
                { key: "ShoppingCenterMall", value: "Shopping Center/Mall" },
                { key: "SingleFamily", value: "Single Family" },
                { key: "TimeShare", value: "Time Share (including recreational land)" },
                { key: "UnimprovedLots", value: "Unimproved Lots" },
                { key: "Vacant", value: "Vacant Land" },
            ]);
        }

        function setupOrder() {
            currentOrderModels([]);
            setupOrderResetModels();


            orderInformationModel.reset();
            icProviderInfoEscrow([]);
            icProviderInfoTitle([]);
            icProviderInfoSalesReps([]);
            orderEscrowOfficesList([]);
            orderEscrowOfficersList([]);
            orderTitleOfficesList([]);
            orderTitleOfficersList([]);
            orderSalesRepresentativeList([]);
            orderCustomerServicesList([]);
            currentOrderModels.push(orderInformationModel);
            orderInformationModel.isIcloseOrder(false);

            switch (selectedOrderTypeId()) {
                //ONLINE TITLE AND ESCROW ORDER
            case 17:
                orderTitle('Online Title and Escrow Order');
                orderType("Title");
                break;
            //Online Escrow Order
            case 48:
                orderTitle('Online Escrow Order');
                orderType("Escrow");
                break;
            //ONLINE TITLE ORDER
            case 47:
                orderTitle('Online Title Order');
                orderType("Title");
                break;
            case 99:
                orderTitle('Escrow Only');
                wesbOrderTitle('Escrow Order');
                orderType("Escrow");
                break;
            case 97:
                wesbOrderTitle('Title and Escrow Order');
                orderTitle('Title and Escrow');
                orderType("TitleEscrow");
                break;
            case 98:
                wesbOrderTitle('Title Order');
                orderTitle('Title Only');
                orderType("Title");
                break;
            default:

            }
            orderInformationModel.service(orderTitle());
            orderInformationModel.orderType(orderType());

        }


        function setupWizard(transactionType) {
            if (currentOrderModels().length > 1)
                currentOrderModels().splice(1, currentOrderModels().length);
            if (session.userName() != null && session.defaultOpertionId() != null) {
                getOrderRequest(session.userId, session.defaultOpertionId());
            }

            singleLenderModel.contact("0");

            //ONLINE ESCROW ORDER
            if (selectedOrderTypeId() == 48) {
                setupOnlineEscrowTransactionType(transactionType);
            }

            //Online Title and Escrow Order
            if (selectedOrderTypeId() == 17) {
                setupOnlineTitleAndEscrowTransactionType(transactionType);
            }

            //Online Title Order
            if (selectedOrderTypeId() == 47) {
                setupOnlineTitleOrderTransactionType(transactionType);
            }

            //Escrow Order
            if (selectedOrderTypeId() == 99) {
                setupEscrowOrderTransactionType(transactionType);
            }

            //Title and Escrow Order 
            if (selectedOrderTypeId() == 97) {
                setupTitleAndEscrowOrderTransactionType(transactionType);
            }

            //Title Order
            if (selectedOrderTypeId() == 98) {
                setupTitleOrderTransactionType(transactionType);
            }


            currentOrderModels.push(submitOrderModel);
            currentViewIndex(-1);
            currentViewIndex(0);

            resetModels();

            if (selectedOrderTypeId() == 97 || selectedOrderTypeId() == 98 || selectedOrderTypeId() == 99)
                setSkippables();

            session.handleFocusOnModalInOrder(selectedOrderTypeId());
        }

        function setupOnlineEscrowTransactionType(transactionType) {
            switch (transactionType) {
                //Auction
            case 'AUCT':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                //                    
                break;
            //Commercial Loan
            case 'CMLO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Commercial New Construction
            case 'CMNC':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Commercial Sale / Re-Sale
            case 'CMSA':
                //Subject Property,Order Requester,Principals Buyers,Principals Sellers,Listing Agent,Selling Agent,Lender1,Lender2,Lender3,Lender4,Outside Title,Special Instructions,
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Equity (Loan)
            case 'EQUI':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Foreclosure
            case 'FORE':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Manufactured Home Refinance
            case 'MHRE':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);

                break;

            //Manufactured Home Sale / Re-Sale
            case 'MHSA':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);

                break;

            //New Construction
            case 'NWCO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;

            //New Construction Loan
            case 'NCLO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Refinance
            case 'REFI':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Relocation
            case 'RELO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;

            //REO
            case 'REO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Reverse Mortgage
            case 'RVMG':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                //singleLenderModel.contact("1");
                currentOrderModels.push(singleLenderModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Sale / Re-Sale
            case 'SALE':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Short Sale
            case 'SHSA':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Subdivisions
            case 'SUBD':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }


        function setupOnlineTitleAndEscrowTransactionType(transactionType) {
            switch (transactionType) {
                //Auction
            case 'AUCT':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                //                    
                break;
            //Commercial Loan
            case 'CMLO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Commercial New Construction
            case 'CMNC':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Commercial Sale / Re-Sale
            case 'CMSA':
                //Subject Property,Order Requester,Principals Buyers,Principals Sellers,Listing Agent,Selling Agent,Lender1,Lender2,Lender3,Lender4,Outside Title,Special Instructions,
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Equity (Loan)
            case 'EQUI':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Foreclosure
            case 'FORE':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Manufactured Home Refinance
            case 'MHRE':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);

                break;

            //Manufactured Home Sale / Re-Sale
            case 'MHSA':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);

                break;

            //New Construction
            case 'NWCO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //New Construction Loan
            case 'NCLO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Refinance
            case 'REFI':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Relocation
            case 'RELO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //REO
            case 'REO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Reverse Mortgage
            case 'RVMG':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                //singleLenderModel.contact("1");
                currentOrderModels.push(singleLenderModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Sale / Re-Sale
            case 'SALE':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Short Sale
            case 'SHSA':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Subdivisions
            case 'SUBD':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }


        function setupOnlineTitleOrderTransactionType(transactionType) {
            switch (transactionType) {
                //Auction
            case 'AUCT':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                //                    
                break;
            //Commercial Loan
            case 'CMLO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Commercial New Construction
            case 'CMNC':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Commercial Sale / Re-Sale
            case 'CMSA':
                //Subject Property,Order Requester,Principals Buyers,Principals Sellers,Listing Agent,Selling Agent,Lender1,Lender2,Lender3,Lender4,Outside Title,Special Instructions,
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Equity (Loan)
            case 'EQUI':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Foreclosure
            case 'FORE':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Manufactured Home Refinance
            case 'MHRE':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);

                break;

            //Manufactured Home Sale / Re-Sale
            case 'MHSA':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);

                break;

            //New Construction
            case 'NWCO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;

            //New Construction Loan
            case 'NCLO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Refinance
            case 'REFI':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Relocation
            case 'RELO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;

            //REO
            case 'REO':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Reverse Mortgage
            case 'RVMG':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                //singleLenderModel.contact("1");
                currentOrderModels.push(singleLenderModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Sale / Re-Sale
            case 'SALE':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Short Sale
            case 'SHSA':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Subdivisions
            case 'SUBD':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(independentEscrowModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }


        function setupEscrowOrderTransactionType(transactionType) {
            switch (transactionType) {
                //Auction
            case 'AUCT':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case 'BULK':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '153':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Commercial Loan
            case 'CMLOAN':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '195':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '196':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '198':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '199':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Commercial New Construction
            case 'CMNC':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Commercial Sale / Re-Sale
            case 'CMSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '155':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '158':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '159':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '161':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Equity (Loan)
            case 'EQUITY':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Foreclosure
            case 'FORE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '178':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Manufactured Home Refinance
            case 'MHREFI':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Manufactured Home Sale / Re-Sale
            case 'MHSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //New Construction
            case 'NCSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //New Construction Loan
            case 'NCLOAN':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Refinance
            case 'REFI':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '154':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '157':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '160':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '179':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '182':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '202':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '203':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Relocation
            case 'RELO':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //REO
            case 'REO':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '189':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '190':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '191':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Reverse Mortgage
            case 'RVMTG':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                //singleLenderModel.contact("1");
                currentOrderModels.push(singleLenderModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Sale / Re-Sale
            case 'SALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '163':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '180':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '183':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '200':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Short Sale
            case 'SHSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '193':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '194':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '201':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Subdivisions
            case 'SUBDIV':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '162':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '166':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '181':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }

        

        function setupTitleAndEscrowOrderTransactionType(transactionType) {
            switch (transactionType) {
                //Auction
            case 'AUCT':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case 'BULK':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '153':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Commercial Loan
            case 'CMLOAN':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '195':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '196':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '198':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '199':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Commercial New Construction
            case 'CMNC':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Commercial Sale / Re-Sale
            case 'CMSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '155':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '158':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '159':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '161':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Equity (Loan)
            case 'EQUITY':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Foreclosure
            case 'FORE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '178':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Manufactured Home Refinance
            case 'MHREFI':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Manufactured Home Sale / Re-Sale
            case 'MHSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //New Construction
            case 'NCSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //New Construction Loan
            case 'NCLOAN':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Refinance
            case 'REFI':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '154':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '157':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '160':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '179':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '182':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '202':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '203':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Relocation
            case 'RELO':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //REO
            case 'REO':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '189':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '190':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '191':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Reverse Mortgage
            case 'RVMTG':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                //singleLenderModel.contact("1");
                currentOrderModels.push(singleLenderModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Sale / Re-Sale
            case 'SALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '163':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '180':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '183':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '200':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Short Sale
            case 'SHSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '193':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '194':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '201':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Subdivisions
            case 'SUBDIV':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '162':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '166':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '181':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            }

        }

        

        function setupTitleOrderTransactionType(transactionType) {
            switch (transactionType) {
                //Auction
            case 'AUCT':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case 'BULK':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '153':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Commercial Loan
            case 'CMLOAN':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
                
            case '195':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '196':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '198':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '199':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Commercial New Construction
            case 'CMNC':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Commercial Sale / Re-Sale
            case 'CMSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '155':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '158':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '159':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '161':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Equity (Loan)
            case 'EQUITY':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Foreclosure
            case 'FORE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '178':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Manufactured Home Refinance
            case 'MHREFI':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Manufactured Home Sale / Re-Sale
            case 'MHSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //New Construction
            case 'NCSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //New Construction Loan
            case 'NCLOAN':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //Refinance
            case 'REFI':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '154':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '157':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '160':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '179':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '182':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
            break;
            case '202':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
            break;

            case '203':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Relocation
            case 'RELO':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            //REO
            case 'REO':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '189':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '190':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '191':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Reverse Mortgage
            case 'RVMTG':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbBorrowersModel);
                //singleLenderModel.contact("1");
                currentOrderModels.push(singleLenderModel);
                currentOrderModels.push(specialInstructions);
                break;

            
            //Sale / Re-Sale
            case 'SALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '163':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '180':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            case '183':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '200':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Short Sale
            case 'SHSALE':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '193':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '194':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '201':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            //Subdivisions
            case 'SUBDIV':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '162':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '166':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            case '181':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(wesbprincipalBuyersModel);
                currentOrderModels.push(wesbprincipalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;

            }
        }

        

        function AUCT_Type() {
            switch (orderType()) {
            case 'online':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(lendersModel);
                if (selectedOrderTypeId() == 17)
                    currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            case 'wesbOrder':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);

            }
        }

        function CMLO_Type() {
            switch (orderType()) {
            case 'online':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                if (selectedOrderTypeId() == 17)
                    currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            case 'wesbOrder':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }

        function CMNC_Type() {
            switch (orderType()) {
            case 'online':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(lendersModel);
                if (selectedOrderTypeId() == 17)
                    currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            case 'wesbOrder':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }

        function CMSA_Type() {
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(principalBuyersModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            if (selectedOrderTypeId() == 17)
                currentOrderModels.push(outsideTitleModel);
            currentOrderModels.push(specialInstructions);
        }

        function CMSALE_Type() {
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(principalBuyersModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            currentOrderModels.push(specialInstructions);
        }

        function EQUITY_Type() {
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(principalBorrowersModel);
            currentOrderModels.push(lendersModel);
            currentOrderModels.push(specialInstructions);
        }

        function EQUI_Type() {
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(principalBorrowersModel);
            currentOrderModels.push(lendersModel);
            if (selectedOrderTypeId() == 17)
                currentOrderModels.push(outsideTitleModel);
            currentOrderModels.push(specialInstructions);
        }

        function FORE_Type() {
            switch (orderType()) {
            case 'online':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                if (selectedOrderTypeId() == 17)
                    currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            case 'wesbOrder':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }

        function MHREFI_Type() {
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(principalBorrowersModel);
            currentOrderModels.push(lendersModel);
            currentOrderModels.push(specialInstructions);

        }

        //Manufactured Home Sale / Re-Sale
        function MHSALE_Type() {
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(principalBuyersModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            currentOrderModels.push(specialInstructions);
        }

        //New Construction
        function NCSALE_Type() {
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            currentOrderModels.push(specialInstructions);
        }

        //New Construction Loan
        function NCLOAN_Type() {
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(principalBorrowersModel);
            currentOrderModels.push(lendersModel);
            currentOrderModels.push(specialInstructions);
        }

        function MHRE_Type() {
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(principalBorrowersModel);
            currentOrderModels.push(lendersModel);
            if (selectedOrderTypeId() == 17)
                currentOrderModels.push(outsideTitleModel);
            currentOrderModels.push(specialInstructions);
        }

        function MHSA_Type() {
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(principalBuyersModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            if (selectedOrderTypeId() == 17)
                currentOrderModels.push(outsideTitleModel);
            currentOrderModels.push(specialInstructions);
        }


        function NWCO_Type() {
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(principalBuyersModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            if (selectedOrderTypeId() == 17)
                currentOrderModels.push(outsideTitleModel);
            currentOrderModels.push(specialInstructions);
        }


        function NCLO_Type() {
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(principalBorrowersModel);
            currentOrderModels.push(lendersModel);
            if (selectedOrderTypeId() == 17)
                currentOrderModels.push(outsideTitleModel);
            currentOrderModels.push(specialInstructions);
        }


        function REFI_Type() {
            switch (orderType()) {
            case 'online':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                if (selectedOrderTypeId() == 17)
                    currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            case 'wesbOrder':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(principalBorrowersModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }


        function RELO_Type() {
            switch (orderType()) {
            case 'online':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                if (selectedOrderTypeId() == 17)
                    currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            case 'wesbOrder':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }

        function REO_Type() {
            switch (orderType()) {
            case 'online':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                if (selectedOrderTypeId() == 17)
                    currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            case 'wesbOrder':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }

        //Reverse Mortgage
        function RVMTG_Type() {
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(principalBorrowersModel);
            currentOrderModels.push(singleLenderModel);
            //lenderModel.contact("1");
            //lenderModel.isReset(false);
            currentOrderModels.push(specialInstructions);
        }

        function RVMG_Type() {
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(principalBorrowersModel);
            currentOrderModels.push(singleLenderModel);
            currentOrderModels.push(specialInstructions);
        }

        function SALE_Type() {
            switch (orderType()) {
            case 'online':
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                if (selectedOrderTypeId() == 17)
                    currentOrderModels.push(outsideTitleModel);
                currentOrderModels.push(specialInstructions);
                break;
            case 'wesbOrder':
                currentOrderModels.push(orderRequestModel);
                currentOrderModels.push(subjectPropertyModel);
                currentOrderModels.push(principalBuyersModel);
                currentOrderModels.push(principalSellersModel);
                currentOrderModels.push(listingAgentModel);
                currentOrderModels.push(sellingAgentModel);
                currentOrderModels.push(lendersModel);
                currentOrderModels.push(specialInstructions);
                break;
            }
        }

        //Wesb Short Sale
        function SHSALE_Type() {
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(principalBuyersModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            currentOrderModels.push(specialInstructions);
        }

        function SHSA_Type() {
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(principalBuyersModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            if (selectedOrderTypeId() == 17)
                currentOrderModels.push(outsideTitleModel);
            currentOrderModels.push(specialInstructions);
        }


        function SUBDIV_Type() {
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(principalBuyersModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            currentOrderModels.push(specialInstructions);
        }

        function SUBD_Type() {
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(principalBuyersModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            if (selectedOrderTypeId() == 17)
                currentOrderModels.push(outsideTitleModel);
            currentOrderModels.push(specialInstructions);
        }

        function BULK_Type() {

            currentOrderModels.push(orderRequestModel);
            currentOrderModels.push(subjectPropertyModel);
            currentOrderModels.push(principalBuyersModel);
            currentOrderModels.push(principalSellersModel);
            currentOrderModels.push(listingAgentModel);
            currentOrderModels.push(sellingAgentModel);
            currentOrderModels.push(lendersModel);
            currentOrderModels.push(specialInstructions);

        }

        function setupOrderResetModels() {
            principalBorrowerModel.reset();
            principalBorrowersModel.reset();
            principalSellerModel.reset();
            principalSellersModel.reset();
            lenderModel.reset();
            lendersModel.reset();
            singleLenderModel.reset();
            principalBuyersModel.reset();
            principalBuyerModel.reset();
            outsideTitleModel.reset();
            specialInstructions.reset();
            submitOrderModel.reset();
            listingAgentModel.reset();
            sellingAgentModel.reset();
            independentEscrowModel.reset();
            wesbPrincipalSellerModel.reset();
            wesbprincipalSellersModel.reset();
            wesbPrincipalBuyerModel.reset();
            wesbprincipalBuyersModel.reset();
            wesbBorrowerModel.reset();
            wesbBorrowersModel.reset();
        }


        function resetModels() {

            for (var i = 0; i < currentOrderModels().length; i++) {
                currentOrderModels()[i].index(i);
                if (currentOrderModels()[i].isSkippable)
                    currentOrderModels()[i].isSkippable = false;

                // skip order information view reset
                if (i > 0)
                    currentOrderModels()[i].reset();
            }
            listingAgentModel.newFrequentContact(true)
            sellingAgentModel.newFrequentContact(true)
            singleLenderModel.newFrequentContact(true)
            lenderModel.newFrequentContact(true)
            outsideTitleModel.newFrequentContact(true)
            independentEscrowModel.newFrequentContact(true)

            lendersModel.orderTypeId(selectedOrderTypeId());
            lendersModel.transactionTypeId(orderInformationModel.transactionType());

            principalBorrowersModel.orderTypeId(selectedOrderTypeId());
            principalBorrowersModel.transactionTypeId(orderInformationModel.transactionType());

            principalBuyersModel.orderTypeId(selectedOrderTypeId());
            principalBuyersModel.transactionTypeId(orderInformationModel.transactionType());

            principalSellersModel.orderTypeId(selectedOrderTypeId());
            principalSellersModel.transactionTypeId(orderInformationModel.transactionType());

            wesbprincipalSellersModel.orderTypeId(selectedOrderTypeId());
            wesbprincipalSellersModel.transactionTypeId(orderInformationModel.transactionType());

            wesbprincipalBuyersModel.orderTypeId(selectedOrderTypeId());
            wesbprincipalBuyersModel.transactionTypeId(orderInformationModel.transactionType());

            wesbBorrowersModel.orderTypeId(selectedOrderTypeId());
            wesbBorrowersModel.transactionTypeId(orderInformationModel.transactionType());


            singleLenderModel.orderTypeId(selectedOrderTypeId());
            singleLenderModel.transactionTypeId(orderInformationModel.transactionType());
            //If profileId, then setting property information from profile report


            if (session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null) {
                propertyInfoPopulateFromSession(true);
                subjectPropertyModel.addressStreetName(session.orderForPropertyAddress.address());
                subjectPropertyModel.zip(session.orderForPropertyAddress.zip())
                if (session.orderForPropertyAddress.apn().length > 0) {
                    recentSearchSelectedAPN(session.orderForPropertyAddress.apn());
                    subjectPropertyModel.apn(session.orderForPropertyAddress.apn());
                }
            }
            //if (session.profileId() != undefined && session.profileId() != null) {

            //    getPropertyInformationFromCurrentProfile(session.profileId()).done(function(data) {

            //        subjectPropertyModel.addressStreetName(data.Property.Address);
            //        subjectPropertyModel.city(data.Property.City);
            //        subjectPropertyModel.state(data.Property.State);
            //        subjectPropertyModel.zip(data.Property.ZIP);
            //        subjectPropertyModel.apn(data.Property.APN);

            //    });

            //}
        }

        // should be called to mark models which have skippable property
        function setSkippables() {
            for (var i = 0; i < currentOrderModels().length; i++) {
                if (currentOrderModels()[i].isSkippable != undefined) {
                    currentOrderModels()[i].isSkippable = true;
                    //currentOrderModels()[i].title = currentOrderModels()[i].title + ' (Optional)';
                }


            }
        }

        function GetFinanceTypes() {
            financeTypes([]);
            var url = config.getFinanceTypesUrl;
            Q.when(https.get(url, { format: 'json' })
                .then(function(data) {
                    financeTypes(data);
                })
                .fail(Failed));
        }


        function GetStatesList() {
            var url = config.stateUrl;
            stateList([]);
            countyList([]);
            Q.when(https.get(url, { format: 'json' })
                .then(function(data) {
                    stateList(data);
                })
                .fail(Failed));
        }

        function getCountiesListByState(state) {
            var url = config.countyUrl;
            countyList([]);
            return https.get(url,
                {
                    stateAbbr: state,
                    format: 'json'
                })

        }

        function getUserInfoByEmail(email) {
            var url = config.getUserInfoByEmailUrl;
            return https.get(url,
                {
                    email: email,
                    format: 'json'
                })
        }

        function getOrderRequest(userId, operationId) {
            utlities.getOperationUserInfo(userId, operationId).done(function(data) {

                orderRequestModel.firstName(data.FirstName);
                orderRequestModel.lastName(data.LastName);
                orderRequestModel.company(data.Company);
                orderRequestModel.address(data.StreetAddress1 + String.fromCharCode(160) + data.StreetAddress2);
                orderRequestModel.zip(data.Zip);
                orderRequestModel.city(data.City);
                orderRequestModel.state(data.State);
                orderRequestModel.phone(data.PhoneWork);
                orderRequestModel.phoneExt(data.PhoneWorkExt);
                orderRequestModel.fax(data.PhoneFax);
                orderRequestModel.faxExt(data.PhoneFaxExt);
                orderRequestModel.email(data.Email);

            })

        }


        function getFrequentContacts(userId, contactType) {
            var url = config.getFrequentContacts;
            return https.get(url,
                {
                    userId: userId,
                    contactType: contactType,
                    format: 'json'
                })
        }

        function lenderDropdown(userId, contactType) {

            getFrequentContacts(userId, contactType).done(function(data) {
                lenderFreqDrpList(data);
                lenderFreqDrpList.splice(0,
                    0,
                    { FreqContactId: "0", FullNameLastNameFirst: "Enter Information" },
                    { FreqContactId: "1", FullNameLastNameFirst: "Myself" });
            })
        }

        function agentDropdown(userId, contactType) {
            getFrequentContacts(userId, contactType).done(function(data) {
                agentFreqDrpList(data);
                agentFreqDrpList.splice(0,
                    0,
                    { FreqContactId: "0", FullNameLastNameFirst: "Enter Information" },
                    { FreqContactId: "1", FullNameLastNameFirst: "Myself" });
            })
        }

        function outsideDropdown(userId, contactType) {
            getFrequentContacts(userId, contactType).done(function(data) {
                outsideFreqDrpList(data);
                outsideFreqDrpList.splice(0,
                    0,
                    { FreqContactId: "0", FullNameLastNameFirst: "Enter Information" },
                    { FreqContactId: "1", FullNameLastNameFirst: "Myself" });
            })
        }

        function populateIndependentEscrowFreqContacts(userId) {
            getFrequentContacts(userId, 4).done(function(data) {
                IndependentEscrowFreqContacts(data);
                IndependentEscrowFreqContacts.splice(0,
                    0,
                    {
                        FreqContactId: "0",
                        FullNameLastNameFirst: "Enter Information"
                    },
                    {
                        FreqContactId: "1",
                        FullNameLastNameFirst: "Myself"
                    });
            })
        }

              

        function setOrderForIClose() { 
            var url = config.getICloseOfficeInfoUrl;
            var params = null;
            
            if (orderInformationModel.orderType() == 'Title') {
                params = {
                    "OfficeType": "Title",
                    "OperationId": session.defaultOpertionId().toString(),
                    "Password": session.icPass().toString(),
                    "Provider": "iClose",
                    "UserName": session.icUid().toString()
                };
                getIcTitleOffices(params);
            } else if (orderInformationModel.orderType() == 'Escrow') {
                params = {
                    "OfficeType": "Escrow",
                    "OperationId": session.defaultOpertionId().toString(),
                    "Password": session.icPass().toString(),
                    "Provider": "iClose",
                    "UserName": session.icUid().toString()
                };
                getIcEscrowOffices(params);
            } else if (orderInformationModel.orderType() == 'TitleEscrow') {
                params = {
                    "OfficeType": "Title",
                    "OperationId": session.defaultOpertionId().toString(),
                    "Password": session.icPass().toString(),
                    "Provider": "iClose",
                    "UserName": session.icUid().toString()
                };
                getIcTitleOffices(params);
                params = {
                    "OfficeType": "Escrow",
                    "OperationId": session.defaultOpertionId().toString(),
                    "Password": session.icPass().toString(),
                    "Provider": "iClose",
                    "UserName": session.icUid().toString()
                };
                getIcEscrowOffices(params);

            }

            

        };

        function getIcEscrowOffices(params) {
            icProviderInfoEscrow([]);
            icProviderInfoSalesReps([]);
            var url = config.getICloseOfficeInfoUrl;
            Q.when(https.post(url, params)
                .then(function(data) {
                    if (data != null &&
                        data.ProviderOffices.length > 0 &&
                        data.StatusMessage.toLowerCase() != "invalid login and password") {
                        icProviderInfoEscrow(data);
                        if (data.ProviderOffices.length > 0)
                            orderEscrowOfficesList([]);
                        var sourceArr = [];
                        var index;

                        for (index = 0; index < data.ProviderOffices.length; ++index) {
                            sourceArr.push({
                                OfficeId: data.ProviderOffices[index].Id,
                                CostCenterAlias: data.ProviderOffices[index].Name,
                                Index: index
                            });
                        }
                        orderEscrowOfficesList(sourceArr);
                        
                        if (data.SalesReps.length > 0) {
                            getIcSalesReps(data);
                        }
                        if (data.CustomerServices.length > 0) {
                            getIcCustomerServices(data);
                        }
                        if (data.TransactionTypes.length > 0) {
                            getIcTransactionTypes(data);
                        }
                        if (data.PropertyTypes.length > 0) {
                            getIcPropertyTypes(data);
                        }
                        orderInformationModel.isIcloseOrder(true);
                        session.isICOrder(true);
                        subjectPropertyModel.isIcPropertyType(true);
                        subjectPropertyModel.isOfflinePropertyType(false);
                        subjectPropertyModel.isOnlinePropertyType(false);
                        $('#mdiCloseCred').modal('hide');

                        
                    } else {
                        if (data.StatusMessage.toLowerCase() == "invalid login and password") {
                            alert.error("Invalid credentials");
                        } else {
                            alert.error("iClose processing is not available at this time, please try again later.")
                        }
                    }
                }));
        }

        function getIcTitleOffices(params) {
            icProviderInfoTitle([]);
            icProviderInfoSalesReps([]);
            var url = config.getICloseOfficeInfoUrl;
            Q.when(https.post(url, params)
                .then(function(data) {
                    if (data != null &&
                        data.ProviderOffices.length > 0 &&
                        data.StatusMessage.toLowerCase() != "invalid login and password") {
                        icProviderInfoTitle(data); 
                        if (data.ProviderOffices.length > 0)
                            orderTitleOfficesList([]);
                        var sourceArr = [];
                        var index;

                        for (index = 0; index < data.ProviderOffices.length; ++index) {
                            sourceArr.push({
                                OfficeId: data.ProviderOffices[index].Id,
                                CostCenterAlias: data.ProviderOffices[index].Name,
                                Index: index
                            });
                        }
                        orderTitleOfficesList(sourceArr);
                        if (data.SalesReps.length > 0) {
                            getIcSalesReps(data);
                        }
                        if (data.CustomerServices.length > 0) {
                            getIcCustomerServices(data);
                        }
                        if (data.TransactionTypes.length > 0) {
                            getIcTransactionTypes(data);
                        }
                        if (data.PropertyTypes.length > 0) {
                            getIcPropertyTypes(data);
                        }
                        
                        orderInformationModel.isIcloseOrder(true);
                        session.isICOrder(true);
                        subjectPropertyModel.isIcPropertyType(true);
                        subjectPropertyModel.isOfflinePropertyType(false);
                        subjectPropertyModel.isOnlinePropertyType(false);
                        
                        $('#mdiCloseCred').modal('hide');
                    } else {
                        if (data.StatusMessage.toLowerCase() == "invalid login and password") {
                            alert.error("Invalid credentials");
                        } else {
                            alert.error("iClose processing is not available at this time, please try again later.");
                        }
                        
                    }
                }));
        }

        function getIcSalesReps(data) {
            //orderSalesRepresentativeList([]);
            var repArr = [];
            var index;

            for (index = 0; index < data.SalesReps.length; ++index) {
                if (data.SalesReps[index].Name != undefined && data.SalesReps[index].Name != "" && data.SalesReps[index].Name != "(None)") {
                    repArr.push({
                        Name: data.SalesReps[index].Name,
                        Id: data.SalesReps[index].Id
                    });
                }
            }
            orderSalesRepresentativeList(repArr);
            //orderSalesRepresentativeList.unshift({ Name: "Unknown", Id: "0" });
        }

        function getIcCustomerServices(data) {
            orderCustomerServicesList([]);
            var repArr = [];
            var index;

            for (index = 0; index < data.CustomerServices.length; ++index) {
                if (data.CustomerServices[index].Name != undefined && data.CustomerServices[index].Name != "" && data.CustomerServices[index].Name != "(None)") {
                    repArr.push({
                        Name: data.CustomerServices[index].Name,
                        Id: data.CustomerServices[index].Id,
                        Checked: false
                    });
                }
            }
            orderCustomerServicesList(repArr);
           
            
        }

        function updateCustomerServices(item) {

            var id = 0;
            var index = 0;
            for (index = 0; index < orderCustomerServicesList().length; ++index) {
                id = orderCustomerServicesList()[index].Id;
                if (item.Id == id) {
                    if (document.getElementById('cb' + item.Id).checked) {
                        customerServicesRequested.push({
                            Id: item.Id,
                            Name: item.Name
                        });
                        return true;
                        //document.getElementById('cb' + item.Id).checked = true;
                    } else
                        customerServicesRequested.remove(function (remove) {
                            return remove.Id == item.Id;
                        });
                    return true;

                   
                }
            }
           
        }

        function getIcTransactionTypes(data) {
            orderTransactionList([]);
            var repArr = [];
            var index;

            for (index = 0; index < data.TransactionTypes.length; ++index) {
                if (data.TransactionTypes[index].Name != undefined && data.TransactionTypes[index].Name != "" && data.TransactionTypes[index].Name != "(None)"
                    && data.TransactionTypes[index].Category == orderInformationModel.orderType() || data.TransactionTypes[index].Category == 'All' ) {
                    repArr.push({
                        Key: data.TransactionTypes[index].Name,
                        Value: data.TransactionTypes[index].Code
                            
                            
                        
                    });
                }
            }
            orderTransactionList(repArr);
        }

        function getIcPropertyTypes(data) {
            subjectPropertyModel.icPropertyTypeList([]);   
            //subjectPropertyModel.propertyTypeList([]);
            var propArr = [];
            var index;
            for (index = 0; index < data.PropertyTypes.length; ++index) {
                
                propArr.push({
                    key: data.PropertyTypes[index].Name,
                    value: data.PropertyTypes[index].Value
                    });
            }
            subjectPropertyModel.icPropertyTypeList(propArr);
        }

        function getIcEscrowOfficers(officeId) {
            var officerArr = [];
            var arrIndex;
            orderEscrowOfficersList([]);
            var data = icProviderInfoEscrow();

            if (officeId != 0) {
                for (var i = 0; i < data.ProviderOffices.length; ++i) {
                    if (data.ProviderOffices[i].Id == officeId) {
                        arrIndex = i;
                        {
                            break;
                        }
                    }
                }

                for (var x = 0; x < data.ProviderOffices[arrIndex].Officers.length; ++x) {
                    officerArr.push({
                        FullName: data.ProviderOffices[arrIndex].Officers[x].Name,
                        UserId: data.ProviderOffices[arrIndex].Officers[x].Id
                    });
                }
                orderEscrowOfficersList(officerArr);
                orderEscrowOfficersList.valueHasMutated();
            } else {
                orderEscrowOfficersList.valueHasMutated();
            }

            
        };

        function getIcTitleOfficers(officeId) {
            var officerArr = [];
            var arrIndex;
            orderTitleOfficersList([]);
            var data = icProviderInfoTitle();

            if (officeId != 0) {
                for (var i = 0; i < data.ProviderOffices.length; ++i) {
                    if (data.ProviderOffices[i].Id == officeId) {
                        arrIndex = i;
                        {
                            break;
                        }
                    }
                }

                for (var x = 0; x < data.ProviderOffices[arrIndex].Officers.length; ++x) {
                    officerArr.push({
                        FullName: data.ProviderOffices[arrIndex].Officers[x].Name,
                        UserId: data.ProviderOffices[arrIndex].Officers[x].Id
                    });
                }

                ko.utils.arrayPushAll(orderTitleOfficersList, officerArr);
                orderTitleOfficersList.valueHasMutated();
            } else {
                orderTitleOfficersList.valueHasMutated();
            }

            
        };

       

        function getEscrowOffice(operationId) {
            var url = config.getICloseOfficeInfoUrl;
            var params = {
                "OfficeType": "Escrow",
                "OperationId": session.defaultOpertionId().toString(),
                "Provider": "SP360"
            };
            Q.when(https.post(url, params)
                .then(function (data) {
                    if (data != null &&
                        data.ProviderOffices.length > 0 &&
                        data.StatusMessage.toLowerCase() != "invalid login and password") {
                        icProviderInfoEscrow(data);
                        if (data.ProviderOffices.length > 0) {
                            orderEscrowOfficesList([]);
                            var sourceArr = [];
                            var index;

                            for (index = 0; index < data.ProviderOffices.length; ++index) {
                                sourceArr.push({
                                    OfficeId: data.ProviderOffices[index].Id,
                                    CostCenterAlias: data.ProviderOffices[index].Name,
                                    Index: index
                                });
                            }
                            orderEscrowOfficesList(sourceArr);
                            orderEscrowOfficesList.splice(sourceArr.length + 1, 0, { OfficeId: "0", CostCenterAlias: "Other Office" });
                        } else {
                            orderEscrowOfficesList.unshift({ OfficeId: "0", CostCenterAlias: "Other Office" });        
                        }
                        
                    } else {
                        orderEscrowOfficesList.unshift({ OfficeId: "0", CostCenterAlias: "Other Office" });        
                    }
                    
                })
                .fail(Failed));
        }

        function getTitleOffice(operationId) {
            var url = config.getICloseOfficeInfoUrl;
            var params = {
                "OfficeType": "Title",
                "OperationId": session.defaultOpertionId().toString(),
                "Provider": "SP360"
            };
            Q.when(https.post(url, params)
                .then(function(data) {
                    if (data != null &&
                        data.ProviderOffices.length > 0 &&
                        data.StatusMessage.toLowerCase() != "invalid login and password") {
                        icProviderInfoTitle(data);
                        if (data.ProviderOffices.length > 0) {
                            orderTitleOfficesList([]);
                            var sourceArr = [];
                            var index;

                            for (index = 0; index < data.ProviderOffices.length; ++index) {
                                sourceArr.push({
                                    OfficeId: data.ProviderOffices[index].Id,
                                    CostCenterAlias: data.ProviderOffices[index].Name,
                                    Index: index
                                });
                            }
                            orderTitleOfficesList(sourceArr);
                            orderTitleOfficesList.splice(sourceArr.length + 1, 0, { OfficeId: "0", CostCenterAlias: "Other Office" });
                        } else {
                            orderTitleOfficesList.unshift({ OfficeId: "0", CostCenterAlias: "Other Office" });        
                        }
                        
                    } else {
                        orderTitleOfficesList.unshift({ OfficeId: "0", CostCenterAlias: "Other Office" });
                    }
                    
                })
                .fail(Failed));
        }

        

        


                function getOrderTransactionTypes() {
                    var url = config.getICloseOfficeInfoUrl;
                    var params = {
                        "OfficeType": "Title",
                        "OperationId": session.defaultOpertionId().toString(),
                        "Provider": "Manual"
                    };
                    Q.when(https.post(url, params)
                        .then(function(data) {
                            if (data != null && data.TransactionTypes.length > 0) {
                                orderTransactionList([]);
                                var transArr = [];
                                var index;

                                for (index = 0; index < data.TransactionTypes.length; ++index) {
                                    if (data.TransactionTypes[index].Name != undefined &&
                                        data.TransactionTypes[index].Name != "" &&
                                        data.TransactionTypes[index].Name != "(None)" &&
                                        (data.TransactionTypes[index].Category == orderInformationModel.orderType() ||
                                        data.TransactionTypes[index].Category == 'ALL')) {
                                        transArr.push({
                                            Key: data.TransactionTypes[index].Name,
                                            Value: data.TransactionTypes[index].Code


                                        });
                                    }
                                }
                                transArr.sort();
                                orderTransactionList(transArr);

                            }

                        })
                        .fail(Failed));
                }

                

            function getWesbOrderTransactionTypes() {
                var url = config.getICloseOfficeInfoUrl;
                var params = {
                    "OfficeType": "Title",
                    "OperationId": session.defaultOpertionId().toString(),
                    "Provider": "SP360"
                };
                Q.when(https.post(url, params)
                    .then(function (data) {
                        if (data != null && data.TransactionTypes.length > 0) {
                            orderTransactionList([]);
                            var transArr = [];
                            var index;

                            for (index = 0; index < data.TransactionTypes.length; ++index) {
                                if (data.TransactionTypes[index].Name != undefined &&
                                    data.TransactionTypes[index].Name != "" &&
                                    data.TransactionTypes[index].Name != "(None)" &&
                                    (data.TransactionTypes[index].Category == orderInformationModel.orderType() ||
                                    data.TransactionTypes[index].Category == 'ALL')) {
                                    transArr.push({
                                        Key: data.TransactionTypes[index].Name,
                                        Value: data.TransactionTypes[index].Code


                                    });
                                }
                            }
                            transArr.sort();
                            orderTransactionList(transArr);

                        }

                    })
                    .fail(Failed));
        }

            function getOrderFormOfficers(officeId, operationId, roleName) {
                var officerArr = [];
                var arrIndex;
                var data = [];
                if (roleName == "Escrow Officer") {
                    orderEscrowOfficersList([]);
                    data = icProviderInfoEscrow();
                } else {
                    orderTitleOfficersList([]);
                    data = icProviderInfoTitle();
                }
                
                if (officeId != 0 && data.ProviderOffices != undefined) {
                    for (var i = 0; i < data.ProviderOffices.length; ++i) {
                        if (data.ProviderOffices[i].Id == officeId) {
                            arrIndex = i;
                            {
                                break;
                            }
                        }
                    }

                    for (var x = 0; x < data.ProviderOffices[arrIndex].Officers.length; ++x) {
                        officerArr.push({
                            FullName: data.ProviderOffices[arrIndex].Officers[x].Name,
                            UserId: data.ProviderOffices[arrIndex].Officers[x].Guid
                        });
                    }

                    
                    if (roleName == "Escrow Officer") {
                        ko.utils.arrayPushAll(orderEscrowOfficersList, officerArr);
                        orderEscrowOfficersList.splice(0, 0, { FullName: "Other Officer", UserId: "other" });
                        orderEscrowOfficersList.valueHasMutated();
                    } else {
                        ko.utils.arrayPushAll(orderTitleOfficersList, officerArr);
                        orderTitleOfficersList.splice(0, 0, { FullName: "Other Officer", UserId: "other" });
                        orderTitleOfficersList.valueHasMutated();
                    }
                    
                } else {
                    if (roleName == "Escrow Officer") {
                        orderEscrowOfficersList.splice(0, 0, { FullName: "Other Officer", UserId: "other" });
                        orderEscrowOfficersList.valueHasMutated();
                    } else {
                        orderTitleOfficersList.splice(0, 0, { FullName: "Other Officer", UserId: "other" });
                        orderTitleOfficersList.valueHasMutated();
                    }
                }
            }

            

            function getTitleOfficers(officeId, operationId, roleName) {

                var url = config.getOrderFormOfficers;
                Q.when(https.get(url,
                        {
                            officeId: officeId,
                            operationId: operationId,
                            roleName: roleName,
                            format: 'json'
                        })
                    .then(function(data) {
                        orderTitleOfficersList(data);
                        orderTitleOfficersList.splice(0, 0, { UserId: "other", FullName: "Unknown" });

                    })
                    .fail(Failed));
            }

            function Failed(error) {

                alert.error(error.statusText);

            }


            
        
    });
