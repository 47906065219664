define('viewmodels/llaModule/llaAcceptancePublic',['plugins/router', 'durandal/app', 'knockout', 'services/logger', 'Q', 'plugins/http', 'config', 'global/session', 'alertNotification', 'utlities', 'commonDialogModel', 'llaModel', 'moment'],
    function (router, app, ko, logger, Q, http, config, session, alert, utlities, commonDialog, model, moment) {

        var acceptanceLLAInfo = new model.acceptanceLLAInfo();
        var stateList = ko.observableArray();
        var countyList = ko.observableArray();
        var enableIAgree = ko.observable(true);


        acceptanceLLAInfo.Zip.subscribe(function (zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            acceptanceLLAInfo.City(data.City);
                            acceptanceLLAInfo.State(data.StateAbbr);
                            setCounty(data.StateAbbr, data.CountyName);
                        }
                        else {
                            alert.error("Invalid Zip.")
                            acceptanceLLAInfo.Zip('');
                            acceptanceLLAInfo.City('');
                            acceptanceLLAInfo.State(undefined);
                            acceptanceLLAInfo.County(undefined);
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })
            }
        })


        function setCounty(state, county) {
            utlities.getCountyList(state).done(function (result) {
                countyList([]);
                countyList(result);
                acceptanceLLAInfo.County(county);
            });
        }

        function populateStates() {
            utlities.getStatesList().done(function (result) {
                stateList([]);
                stateList(result);
            });
        }

        function resetLLAFields() {
            acceptanceLLAInfo.reset();
            enableIAgree(true);
        }

        function saveLLADetails() {
            if (vm.errors != null && vm.errors != 'undefined' && vm.errors().length > 0) {
                vm.errors.showAllMessages();
                return;
            }
            enableIAgree(false);
            http.post(config.postLLAUrl, acceptanceLLAInfo)
                .done(function (result) {
                    if (result.HasErrors == true) {
                        if (result.Messages["0"].Message) {
                            alert.error(result.Messages["0"].Message);
                        }
                    }
                    else if (result.HasErrors == false && result.LiabilityAgreement == null) {
                        if (result.Messages["0"].Message) {
                            alert.warning(result.Messages["0"].Message);
                        }
                    } else {
                        alert.success("Customer acceptance successfully recorded on " +
                            moment(result.LiabilityAgreement.Updated).format("MM-DD-YYYY hh:mm:ss"));
                        resetLLAFields();
                    }
                })
                .fail(function () {
                    alert.error("Customer acceptance could not be recorded");
                });
        }

        function agreeClicked() {
            acceptanceLLAInfo.AgreeStatus("Agree");
            acceptanceLLAInfo.Phone(utlities.unmaskPhoneNumber(acceptanceLLAInfo.Phone));
            saveLLADetails();
        }

        function activate(data) {

            console.log("Showing generic LLA acceptance page");
            session.genericLLABrand('');
            if (data) {
                console.log(data);
                session.genericLLABrand(data);
                //if (data.first)
                //    acceptanceLLAInfo.FirstName(data.first);

                //if (data.last)
                //    acceptanceLLAInfo.LastName(data.last);

                //if (data.email) {
                //    acceptanceLLAInfo.UserName(data.email);
                //    acceptanceLLAInfo.confirmEmail(data.email);
                //}

            }



            //var hostname = window.location.hostname;
            //var protocol = window.location.protocol;
            //var llaPageLegacy = "";
            //var isCP = hostname.indexOf("cp.");
            //if (isCP >= 0) {
            //    var isLocal = hostname.indexOf("local.cp.");
            //    if (isLocal >= 0) {
            //        hostname = hostname.replace("local", "dev");
            //    }
            //    hostname = hostname.replace("cp.", "");
            //    llaPageLegacy = protocol + "//" + hostname + "/lla";
            //}
            //else {
            //    llaPageLegacy = protocol + "//admin-" + hostname + "/lla";
            //}
            //console.log(llaPageLegacy);
            //window.location.href = llaPageLegacy;
            populateStates();
        }

        function goBack() {
            router.navigateBack();
        }

        function compositionComplete() {
            session.validateInput();
        }

        var vm = {
            activate: activate,
            goBack: goBack,
            acceptanceLLAInfo: acceptanceLLAInfo,
            stateList: stateList,
            countyList: countyList,
            agreeClicked: agreeClicked,
            enableIAgree: enableIAgree,
            compositionComplete: compositionComplete,
            title: 'LLA Acceptance Page'
        };

        vm.errors = ko.validation.group([vm]);

        return vm;

    });
