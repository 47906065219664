define('viewmodels/customPage/getDocuments',["knockout", 'plugins/http', 'global/session', 'config', 'utlities','getDocumentsModel'],
    function (ko, https, session, config, utlities,model) {

        var folderGUID = ko.observable("")
        var previousFolderGUID = ko.observable("")
        var webSide = ko.observable("https://gps.fntg.com");
        var brandSite = ko.observable("ntiweb")
        var documentsResults = ko.observableArray([]);
        var foldersResults = ko.observableArray([]);
        var folderName = ko.observable("");
        var getDocModel = new model.getDocuments();
        var pageNo =ko.observable(1);
        var isBackPageVisible = ko.observable(false);
        var vm = {
            activate: activate,
            folderGUID: folderGUID,
            webSide:webSide,
            brandSite: brandSite,
            documentsResults: documentsResults,
            foldersResults: foldersResults,
            folderName: folderName,
            getSelectedValue: getSelectedValue,
            getDocModel: getDocModel,
            previousPage: previousPage,
            isBackPageVisible: isBackPageVisible,
            deactivate: deactivate,
            previousFolderGUID: previousFolderGUID,
            pageNo: pageNo,
            compositionComplete: compositionComplete
        }
        return vm;

        function compositionComplete() {
            session.validateInput();
        }

        function activate(FolderGUID) {

            folderGUID(FolderGUID)
            previousFolderGUID("")
            getDocuments(folderGUID, brandSite, webSide).done(function (documentResult) {
                if (documentResult.length > 0) {
                    getDocModel.folderName(documentResult[0].FolderName)
                    documentsResults(documentResult)
                }
               
            })
            getFolders(folderGUID, brandSite, webSide).done(function (folderResult) {
                if (folderResult.length > 0) {
                    getDocModel.parentFolderName(folderResult[0].ParentFolderName);
                    previousFolderGUID(folderResult[0].ParentFolderGUID);
                    foldersResults(folderResult)
                }
            })
            getFolderInfo(folderGUID, brandSite, webSide).done(function (folderInfoResult) {
                folderName(folderInfoResult.FolderName)
            })
        }

        function getDocuments(folderGUID, brandSite, webSide) {
            var url = config.getDocumentsUrl;
            return https.get(url, {
                folderGUID: folderGUID,
                brandSite:brandSite,
                docReqFrom:webSide,
                format: 'json'
            });
        }
        function getFolders(folderGUID, brandSite, webSide) {
            var url = config.getFoldersUrl;
            return https.get(url, {
                folderGUID: folderGUID,
                brandSite: brandSite,
                docReqFrom: webSide,
                format: 'json'
            });
        }
        function getFolderInfo(folderGUID, brandSite, webSide) {
            var url = config.getFolderInfoUrl;
            return https.get(url, {
                folderGUID: folderGUID,
                brandSite: brandSite,
                docReqFrom: webSide,
                format: 'json'
            });
        }

        function getSelectedValue(selectedItem, event) {
            isBackPageVisible(true);
            previousFolderGUID(selectedItem.FolderGUID);
            pageNo(pageNo() + 1);
            getDocuments(selectedItem.FolderGUID, brandSite, webSide).done(function (documentResult) {
                if (documentResult.length > 0) {
                    getDocModel.folderName(documentResult[0].FolderName)
                    documentsResults(documentResult)
                }
            })
            getFolders(selectedItem.FolderGUID, brandSite, webSide).done(function (folderResult) {
                foldersResults(folderResult)
            })
        }

        function deactivate() {
            previousFolderGUID("");
        }
        function previousPage()
        {
            var guid;
            getFolderInfo(previousFolderGUID, brandSite, webSide).done(function (folderInfoResult) {
                guid = folderInfoResult[0].ParentFolderGUID

                if (folderGUID != null) {

                    getFolders(guid, brandSite, webSide).done(function (folderResult) {
                        if (folderResult.length > 0) {
                            isBackPageVisible(true);
                            previousFolderGUID(folderResult[0].ParentFolderGUID);
                            getDocModel.parentFolderName(folderResult[0].ParentFolderName);
                            foldersResults(folderResult)
                            documentsResults([])
                            pageNo(pageNo() - 1);
                            if (pageNo() == 1) {
                                isBackPageVisible(false);
                            }
                        }

                    })
                }
            })
            
        }
        
    });
