define('viewmodels/orderModule/viewOfflineOrderDetail',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'config', 'alertNotification', 'commonDialogModel', 'viewOfflineModel', 'utlities', 'koPaging', 'nameValue', 'nameList'],
    function (app, router, $, ko, validate, iCheck, validation, Q, https, session, config, alert, commonDialog, model, utlities,  koPaging, nameValue, nameList) {

        var selectedOrder = ko.observable('');
        var OfflineOrderDetailsModel = new model.OfflineOrderDetails();
        var emailMessage = ko.observable("").extend({ required: { message: 'Email Message is required.' } });
        var emailSubject = ko.observable("");//.extend({ required: { message: 'Email Subject is required.' } });
        var orderSubmittedByEmail = ko.observable("");

        function activate(OrderId) {

            AfterLoad();
            resetModelData();
            selectedOrder(parseInt(OrderId));
            getOrderDetail(selectedOrder()).done(function (result) {
                if (result != null) {
                    if (result.HasErrors == true) {
                        for (var i = 0; i < result.Messages.length; i++) {
                            alert.error(result.Messages[i].Message, "Offline Order Details");
                        }
                        goBack();
                    }
                    else {
                        OfflineOrderDetailsModel.name(result.Name)
                        OfflineOrderDetailsModel.orderNumber(selectedOrder());
                        OfflineOrderDetailsModel.sections(loadToKOArray(result.Sections));
                        return OfflineOrderDetailsModel;
                    }
                }
                else {
                    alert.error("There was an error retriving your Order Details.", "Offline Order Details");
                    goBack();
                }
            }).fail(function (error) {
                //session.orderId(OrderId);
                //window.location.href = "#login";
                //return { redirect: '#login' };
            })

        }
        function getOrderDetail(selectedItem) {
            var url = config.getOfflineOrderDetailsUrl;
            return https.get(url, {
                orderId: selectedItem,
                format: 'json'
            });
        }
        function AfterLoad() {
            if ($('#hdnIsLogin').val() == "true") {
                $('#page-wrapper').attr('style', 'margin-left:220!important');
            }
        }

        function resetModelData() {
            OfflineOrderDetailsModel.reset();
        }
        //load to local array.
        function loadToKOArray(webAPICollectionObject) {
            var mappedLocalKOArray = [];
            var value;

            for (var j = 0; j < webAPICollectionObject.length; j++) {
                var lst = new nameList();

                var mappedSectionDataArray = [];
                for (var i = 0; i < webAPICollectionObject[j].Data.length; i++) {
                    var item = new nameValue(); //nameValue = a common model

                    item.Name(webAPICollectionObject[j].Data[i].Name);
                    value = webAPICollectionObject[j].Data[i].Value;
                    value = (value == 'True') ? 'Yes' : (value == 'False') ? 'No' : value;
                    if (value == "1/1/0001") {
                        value = "";
                    }
                    item.Value(value);

                    if ((webAPICollectionObject[j].Data[i].Name === "Client Email") || (webAPICollectionObject[j].Data[i].Name === "Email")) {
                        orderSubmittedByEmail(value)
                    }
                    mappedSectionDataArray.push(item);
                }

                lst.Name(webAPICollectionObject[j].Name);
                lst.List.push(mappedSectionDataArray);
                mappedLocalKOArray.push(lst);
            }



            return mappedLocalKOArray;
        }

        function itemClicked(index) {

            var id = "div" + index;
            var el = document.getElementById(id);
            if (el != null) {
                if (el.style.display != 'none') {
                    el.style.display = 'none';
                }
                else {
                    el.style.display = '';
                }
            }

            changeimage(id.replace('div', 'img'));
        }

        function changeimage(id) {
            var img = document.getElementById(id);
            if (img != null) {
                var bExpand = img.className.indexOf('up') >= 0;
                if (!bExpand)
                    img.className = 'fa fa-arrow-up';
                else
                    img.className = 'fa fa-arrow-down';
            }
        }
        function printOrderDetail() {
            var print = document.getElementById("print");
            print.style.display = 'none';

            var reply = document.getElementById("reply");
            reply.style.display = 'none';

            var backbtn = document.getElementById("btnBack");
            backbtn.style.display = 'none';


            //get the modal box content and load it into the printable div
            var elementId = "printOfflineOrder";
            var divToPrint = document.getElementById(elementId);
            var printableDiv = document.getElementById("printable");
            printableDiv.style = "margin-top:20px";
            printableDiv.innerHTML = divToPrint.innerHTML;
            window.print();
            printableDiv.innerHTML = "";

            print.style.display = 'block';
            reply.style.display = 'block';
            backbtn.style.display = 'block';
        }


        function EmailOfflineOrderDetails() {
            console.log("EmailOfflineOrderDetails : Pop-up open");
            emailMessage("");
            var sub = "Offline Order Id : " + vm.OfflineOrderDetailsModel.orderNumber();
            emailSubject(sub);
            $('#mdEmailAcknowledgmentOfflineOrderDetails').modal('show');
            $('.modal-body', '#mdEmailAcknowledgmentOfflineOrderDetails').scrollTop(0);
        }


        function SendEmailAcknowledgment() {
            if (vm.emailMessageError != null && vm.emailMessageError != 'undefined' && vm.emailMessageError().length > 0) {
                vm.emailMessageError.showAllMessages();
                return;
            }

            //var dictEmailTo = [{ orderSubmittedByEmail(): orderSubmittedByEmail()}];
            //dictEmailTo[orderSubmittedByEmail()] = orderSubmittedByEmail();
            //var dictEmailTo = {
            //    "Key": orderSubmittedByEmail(), "Value": orderSubmittedByEmail()
            //};
            //var to = [];
            //to.push(dictEmailTo);
            var simpleEmailRequest = {
                "SenderName": session.fullName(),
                "SenderEmail": session.email(),
                "Subject": emailSubject(),
                "EmailHTML": emailMessage(),
                "To": orderSubmittedByEmail()
            }

            var url = config.emailAcknowledgmentForOrderDetailsUrl;

            return https.post(url, simpleEmailRequest)
                .done(function (data) {
                    $('#mdEmailAcknowledgmentOfflineOrderDetails').modal('hide');
                    if (data != null) {
                        if (data.HasErrors == false) {
                            alert.success("Email Sent", "Email Acknowledgment For Offline Order Details");
                        }
                        else {
                            alert.error(data.Messages[0].Message, "Email Acknowledgment For Offline Order Details");
                        }
                    }
                    else {
                        alert.error('Internal Server Error', "Email Acknowledgment For Offline Order Details");
                    }
                }).fail(function () {
                    $('#mdEmailAcknowledgmentOfflineOrderDetails').modal('hide');
                    alert.error('Error while sending email', "Email Acknowledgment For Offline Order Details");
                });
        }



        function goBack() {
            router.navigate('#viewOrders', { replace: false, trigger: true });
        }
        function canDeactivate() {
            session.clearOrderForPropertyAddress();
            orderSubmittedByEmail("");
            return true;
        }
        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            selectedOrder: selectedOrder,
            OfflineOrderDetailsModel: OfflineOrderDetailsModel,
            getOrderDetail: getOrderDetail,
            loadToKOArray: loadToKOArray,
            itemClicked: itemClicked,
            changeimage: changeimage,
            printOrderDetail: printOrderDetail,
            goBack: goBack,
            EmailOfflineOrderDetails: EmailOfflineOrderDetails,
            SendEmailAcknowledgment: SendEmailAcknowledgment,
            emailMessage: emailMessage,
            emailSubject: emailSubject,
            orderSubmittedByEmail: orderSubmittedByEmail,
        }

        vm.emailMessageError = ko.validation.group([vm.emailMessage]);
        return vm;

    });
