define('viewmodels/mobileApp/androidApp',["knockout", 'plugins/http', 'global/session', 'config', 'utlities'],
    function (ko, https, session, config, utlities) {
        var vm = {
            activate: activate,

        }
        return vm;

        function activate() {
           
        }


    });
