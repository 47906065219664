define('viewmodels/featureUpdatesModule/featureUpdates',['plugins/router', 'knockout', 'durandal/app', 'plugins/http', 'global/session', 'config', 'featureUpdate', 'koPaging'],
    function (router, ko, app, https, session, config, model, koPaging) {

        var featureUpdateModel = new model.featureUpdate();
        var featureUpdatesList = ko.observableArray([]);
        featureUpdatesList.extend({
            paged: {
                pageSize: 7,
                pageGenerator: 'default'
            }
        });
        //function checkForLLA(data) {
        //    if (data.LLACheckReq) {
        //        if (!session.llaAccepted()) {
        //            session.showLLAAgreement();
        //            return false;
        //        }
        //    }
        //    return true;
        //}

        // Reveal the bindable properties and functions
        var vm = {
            activate: activate,
            goBack: goBack,
            title: 'Feature Updates',
            //checkForLLA: checkForLLA,
            canDeactivate: canDeactivate,
            featureUpdateModel: featureUpdateModel,
            featureUpdatesList: featureUpdatesList,
            router: router,
            compositionComplete: compositionComplete
        };
        return vm;

        function compositionComplete() {
            session.validateInput();
        }

        function activate() {
            session.showTopSearchControl(true);
            getFeatureUpdates(); //return?
        }

        //function compositionComplete() {
        //    if (session.profileId() != undefined && session.profileId() != null && session.profileId() != "") {
        //        getPropertyInformationFromCurrentProfile(session.profileId()).done(function (data) {
        //            session.orderForPropertyAddress.reset();//Do not reset if session.profileId() is null, Address may be coming from Profile Page
        //            session.orderForPropertyAddress.updateFromURL(data);
        //            console.log(session.orderForPropertyAddress);
        //        }).then(function () {
        //            session.profileId("");
        //        });
        //    }
        //    else {
        //        session.orderForPropertyAddress.addressInURL(false);
        //    }
        //}

        function getFeatureUpdates() {
            featureUpdatesList([]);
            console.log('getFeatureUpdates called');
            var url = config.getFeatureUpdatesUrl;
            featureUpdateModel.reset();
            return https.get(url).done(function (data) {
                if (data != null) {
                    for (var i = 0; i < data.length; i++) {
                        var featureUpdate = new model.featureUpdate();
                        featureUpdate.Id(data[i].Id);
                        featureUpdate.BrandId(data[i].BrandIdId);
                        featureUpdate.OperationId(data[i].OperationId);
                        featureUpdate.IsNetSellersUser(data[i].IsNetSellersUser);
                        featureUpdate.IsEmployee(data[i].IsEmployee);
                        featureUpdate.Name(data[i].Name);
                        featureUpdate.Description(data[i].Description);
                        featureUpdate.VideoFileUrl(data[i].VideoFileUrl);
                        featureUpdate.DocumentFileUrl(data[i].DocumentFileUrl);
                        featureUpdate.DateAdded(data[i].DateAdded);
                        featureUpdate.IsActive(data[i].IsActive);
                        featureUpdatesList.push(featureUpdate);
                    }
                }
            }).always(function(data) {
                //featureUpdatesList = featureUpdatesList.reverse();
                featureUpdatesList.toFirstPage();
            });
        }

        function canDeactivate() {
            //session.clearOrderForPropertyAddress();
            //if (!(router.activeInstruction().config.moduleId.indexOf("viewmodels/orderModule") >= 0))
            //    session.clearOrderForPropertyAddress();
            return true;
        }


        //function getPropertyInformationFromCurrentProfile(profileId) {
        //    var url = config.getPropertyInformationFromCurrentProfileUrl;
        //    return https.get(url,
        //        {
        //            profileId: profileId,
        //            format: 'json'
        //        });
        //}

        function goBack(complete) {
            router.navigateBack();
        }
    });
