define('models/payment/viewPayment',["knockout", 'moment'],
    function (ko, moment) {
        function PaymentPurchaseRequest() {
            var self = this;

            self.firstName = ko.observable("");
            self.lastName = ko.observable("");
            self.userName = ko.observable("");
            self.fromDate = ko.observable("");
            self.toDate = ko.observable("");

            self.reset = function () {

                self.firstName("");
                self.lastName("");
                self.userName("");
                
                var date = new Date();
                self.fromDate(moment(date.setDate(date.getDate() - 14)).format('MM/DD/YYYY'));
                self.toDate(moment(new Date()).format('MM/DD/YYYY'));
            }

            self.errors = ko.validation.group(self);
            self.isValid = ko.computed(function () {
                return self.errors().length === 0;
            });

        }

        function PaymentPurchaseResult() {
            var self = this;

            self.itemNumber = ko.observable("");
            self.cartValue = ko.observable("");
            self.tranDate = ko.observable("");
            self.tranStatus = ko.observable("");
            self.firstName = ko.observable("");
            self.lastName = ko.observable("");
            self.userName = ko.observable("");
            self.orderGuid = ko.observable("");
            self.isPosted = ko.observable(false);
        }


        function OrderedProduct(){
            var self = this;
            self.slNo = ko.observable(0);
            self.name = ko.observable("");
            self.useCredit = ko.observable(false);
            self.price = ko.observable(0.0);
            self.credits = ko.observable(0);
            self.isAvailable = ko.observable(true);

        }

        function TransactionExtract() {
            var self = this;

            self.fullName = ko.observable("");
            self.userName = ko.observable("");
            self.tranDate = ko.observable("");
            self.itemNumber = ko.observable("");
            self.cartValue = ko.observable("");
            self.propertyAddress = ko.observable("");
            self.APN = ko.observable("");
            self.tranStatus = ko.observable("");
            self.orderNumber = ko.observable("");
            self.orderTax = ko.observable("");
            self.stateTax = ko.observable("");
            self.products = ko.observable([]);
            self.isPostedToFTNI = ko.observable(false);

        }

        var vm = {
            PaymentPurchaseRequest: PaymentPurchaseRequest,
            PaymentPurchaseResult: PaymentPurchaseResult,
            TransactionExtract: TransactionExtract,
            OrderedProduct: OrderedProduct
        };
        return vm;
    }
);
