define('models/order/coOEOrder',["knockout", "modelMetaData", "utlities"],
    function (ko, modelMetaData, utlities) {

        function CustomerSearch() {
            var self = this;
            self.viewName = "orderModule/coOEOrder/customerSearch.html"
            self.title = "Customer Search"
            self.ModelId = "customerSearch"
            self.index = ko.observable(-1)
            self.firstName  = ko.observable("")
            self.lastName =ko.observable("")
            self.email = ko.observable("")
             .extend({ email: { message: 'Invalid Email Address.' } })
                .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })
            self.assignedTo =ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.firstName(data.firstName)
                    self.lastName(data.firstName)
                    self.email(data.firstName)
                    self.assignedTo(data.assignedTo)
                }

            };

            self.reset = function () {
                self.firstName("")
                self.lastName("")
                self.email("")
                self.assignedTo("")
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("First Name", self.firstName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Last Name", self.lastName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Email", self.email()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Assigned To", self.assignedTo()));
            }

            self.assignedToList  = ko.observableArray([
                { key: "NA", value: "All Employees" },
                { key: "fd3c9f6c-914d-48b8-b97c-7a0a0295366a", value: "Tomlinson, Shawn" },
               
            ]);
        }


        function OrderType() {
            var self = this;

            self.viewName = "orderModule/coOEOrder/orderType.html"
            self.title = "Order Type"
            self.ModelId = "orderType"
            self.index = ko.observable(-1)
            self.orderType = ko.observable(undefined)
             .extend({ required: { message: 'Order Type is required.' } })
            self.update = function (data) {
                if (data) {
                    self.orderType(data.orderType)
                }

            };

            self.reset = function () {
                self.orderType(undefined)
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }
            self.orderTypeList = ko.observableArray([

               { key: "73", value: "Vesting Deed (No Charge) ($0.00)" },
               { key: "74", value: "O&E With Plat and CC&amp;R's Per Parcel ($9.00)" },
               { key: "78", value: "O&E Without Plat and CC&amp;R's Per Parcel ($1.00)" },
               { key: "80", value: "O&E With Plat ($7.00)" }
                
            ]);
           
        }
        function propertyInformation() {
            var self = this;

            self.viewName = "orderModule/coOEOrder/propertyInformation.html"
            self.title = "Property Information"
            self.ModelId = "propertyInformation"
            self.index = ko.observable(-1)
            self.address = ko.observable("")
             .extend({ required: { message: 'Address is required.' } })
            self.zipCode = ko.observable("")
             .extend({ required: { message: 'Zip Code is required.' } })
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.city = ko.observable("")
             .extend({ required: { message: 'City is required.' } })
            self.state = ko.observable(undefined)
             .extend({ required: { message: 'State is required.' } })
            self.county = ko.observable(undefined)
            .extend({ required: { message: 'County is required.' } })
            self.propertyPIN=ko.observable("")
            self.legalDescription = ko.observable("")
                    .extend({ maxLength: { params: 200, message: 'Legal Description must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.firstName=ko.observable("")
            self.lastName=ko.observable("")
            self.specialInstructions=ko.observable("")
             .extend({ maxLength: { params: 200, message: 'Special Instructions must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.update = function (data) {
                if (data) {
                    self.address(data.Address)
                    self.zipCode(data.zipCode)
                    self.city(data.city)
                    self.state(data.state)
                    self.county(data.county)
                    self.propertyPIN(data.propertyPIN)
                    self.legalDescription(data.legalDescription)
                    self.firstName(data.firstName)
                    self.lastName(data.lastName)
                    self.specialInstructions(data.specialInstructions)
                }

            };

            self.reset = function () {
                self.address("")
                self.zipCode("")
                self.city("")
                self.state(undefined)
                self.county(undefined)
                self.propertyPIN("")
                self.legalDescription("")
                self.firstName("")
                self.lastName("")
                self.specialInstructions("")
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }
            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Address", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip Code", self.zipCode()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("County", self.county()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property PIN", self.propertyPIN()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Legal Description", self.legalDescription()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("First Name", self.firstName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Last Name", self.lastName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Special Instructions", self.specialInstructions()));
            }

        }

        function SearchResult() {
            var self = this;
            self.email = ko.observable("")
            self.operationId = ko.observable("")
            self.name = ko.observable("")
            self.company = ko.observable("")
            self.assignedTo = ko.observable("")
            self.userId = ko.observable("")
            self.reset = function () {
                
            }
          
        }
        function CustomerInformation() {
            var self = this;

            self.viewName = "orderModule/coOEOrder/_customerInformation.html"
            self.title = "Customer Information"
            self.ModelId = "CustomerInformation"
            self.index = ko.observable(-1)

            self.orderDate = ko.observable("")
            self.salesRep = ko.observable("")
            self.fullName = ko.observable("")
            self.email = ko.observable("")
            self.company = ko.observable("")
            self.address = ko.observable("")
            self.phon = ko.observable("")
            self.ext = ko.observable("")
            self.fax = ko.observable("")

            self.phoneWithExtn = ko.computed(function () {

                if (self.phon() && self.phon().length>0) {
                    return utlities.getUSPhoneFormatWithExtn(self.phon(), self.ext(), 'x');
                }
                else
                    return "Not Available";

            });

            self.faxFormatted = ko.computed(function () {
                if(self.fax() && self.fax().length>0)
                {
                    return utlities.getUSPhoneFormat(self.fax());
                }
                else
                {
                    return "Not Available";
                }
            });

            self.update = function (data) {
                if (data) {
                    self.index(data.index());
                    self.orderDate(data.orderDate());
                    self.salesRep(data.salesRep());
                    self.fullName(data.county());
                    self.email(data.email());
                    self.company(data.company());
                    self.address(data.address());
                    self.phon(data.phon());
                    self.ext(data.ext());
                    self.fax(data.fax());
                }

            };

            self.reset = function () {
                self.orderDate('');
                self.salesRep('');
                self.fullName('');
                self.email('');
                self.company('')
                self.address('')
                self.phon('')
                self.ext('')
                self.fax('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            //self.isValid = function () {
            //    return ko.validation.group(self).length == 0;
            //}
        }


        function SubmitOrder() {
            var self = this;
            self.viewName = "orderModule/_submitCOEOrder.html"
            self.title = "Submit Order"
            self.index = ko.observable(-1)
            self.ModelId = "SubmitOrder"

            self.update = function (data) {
                if (data) {
                }
            }

            self.reset = function () {
                //self.index(-1)
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

        }

        var vm = {
            CustomerSearch: CustomerSearch,
            OrderType: OrderType,
            propertyInformation: propertyInformation,
            SearchResult: SearchResult,
            CustomerInformation: CustomerInformation,
            SubmitOrder: SubmitOrder
        }

        return vm;
    });
