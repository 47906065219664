define('pendingApprovalService',['plugins/router', 'knockout', 'durandal/app', 'plugins/http', 'global/session', 'config', 'moment', 'alertNotification', 'koPaging', 'pendingApprovalModel'],
    function (router, ko, app, https, session, config, moment, alerter, koPaging, models) {

        var arePendingApprovals = ko.observable(false);
        var pendingApprovals = ko.observableArray([]);
        var service = {
            checkPendingApproval: checkPendingApproval,
            arePendingApprovals: arePendingApprovals,
            pendingApprovals: pendingApprovals,
            openAdmin: sso,
            session: session
        };

        function checkPendingApproval() {
            var url = config.getPendingApprovalUsersURL;
            pendingApprovals([]);
            return https.get(url)
                .then(function (data) {
                    if (data && data.length > 0) {
                        var converted = loadResultToKOArray(data);
                        pendingApprovals(converted);
                        arePendingApprovals(pendingApprovals().length > 0);
                    }
                })
                .fail(function (data) {
                    if (data != null && data != undefined) {
                        if (data.responseJSON != null && data.responseJSON.ExceptionMessage != null && data.responseJSON != undefined && data.responseJSON.ExceptionMessage != null) {
                            alerter.warning(data.responseJSON.ExceptionMessage);
                        }
                    }
                });
        }

        function loadResultToKOArray(data) {
            var results = [];
            var space = String.fromCharCode(160);
            for (var i = 0; i < data.length; i++) {

                result = new models.PendingApprovalModel();

                result.userId(data[i].UserId);
                result.firstName(data[i].FirstName);
                result.lastName(data[i].LastName);
                result.email(data[i].Email);
                result.company(data[i].CompanyName);

                results.push(result);

            }
            return results;
        }

        function sso() {
            var url = config.getSsoUrl;
            https.get(url, {
                encPw: session.pass,
                ssoTarget: 'OpenApplication',
                format: 'json'
            }).then(function (data) {
                var redirectUrl = data;
                session.CheckPopUpBlock(redirectUrl);
            });
        }

        return service;
    });
