define('orderCommon',['durandal/app', 'jquery', 'config', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'commonDialogModel', 'urlparams'],
    function (app, $, config, Q, https, session, alert, commonDialog, urlParams) {

        function getProductReport(data, options) {
            if (data != null && data.URL() != null) {
                $("#mdLoading").modal('show');
                URL.current = '?url=' + data.URL().replace('?', '&');
                var reqObject = {
                    reportGuid: URL.Parameters.get('reportGuid'),
                    convertHtmlToPdf: URL.Parameters.get('ConvertHtmlToPdf'),
                    propertyReportRequestType: URL.Parameters.get('propertyReportRequestType')
                };
                Q.when(https.post(data.URL())
                    .then(
                        function (reportResponse) {
                            $("#mdLoading").modal('hide');
                            getPropertyReportsByRefKey(reportResponse, reqObject, options);
                        }).fail(Failed));
                return false;
            }
        }

        function getPropertyReportsByRefKey(reportResponse, reqObject, options) {
            if (reportResponse == null || reportResponse.Key == undefined) {
                alert.warning("Failed to get a valid report. Please try after some time or contact Sales Executive");
            }
            else if (reportResponse.Key === 'fail') {
                alert.warning(reportResponse.Value);
            }
            else {
                var keys = reportResponse.Value.split(':');
                reqObject.Url = '/api/PropertyReport/' + keys[0] + '/' + reqObject.convertHtmlToPdf + '/' + keys[1];
                session.CheckPopUpBlock(reqObject.Url, "_blank");
            }
            return false;
        }

        function Failed(error) {
            $("#mdLoading").modal('hide');
        }

        return {
            Failed: Failed,
            getProductReport: getProductReport
        };
    }
 );
