define('viewmodels/payment/valuecheckcallback',['plugins/router', 'durandal/app', 'knockout', 'services/logger', 'global/session', 'header', 'config', 'plugins/http', 'alertNotification', 'commonDialogModel'],
    function (router, app, ko, logger, session, header, config, https, alert, commonDialog) {

        var payResponseId = ko.observable();
        var payResponseAction = ko.observable();
        var commonDialogModel = new commonDialog("orderSubmitDialog");
        var orderSubmitted = ko.observable(false);
        var responseMessage = ko.observable("");

        var vm = {
            activate: activate,
            canActivate: canActivate,
            deactivate: deactivate,
            canDeactivate: canDeactivate,
            commonDialogModel: commonDialogModel,
            compositionComplete: compositionComplete,
            responseMessage: responseMessage
        };

        return vm;

        function activate() {
            var url = config.getFTNIOrderStatusResponseUrl;
            var request = {
                "OrderNumber": payResponseId(),
            };

            https.get(url, request)
                .then(function (data) {
                    if (data.HasErrors == false) {
                        console.log(data);
                        payResponseAction(data.ResponseMode);
                        responseMessage(data.Message);
                    }
                });
        }

        function compositionComplete() {

            //payResponseId(session.payResponseId());
            //payResponseAction(session.payResponseAction());
            console.log(payResponseId() + " - " + payResponseAction());
            if (payResponseAction() == "Cancelled") {
                HandleNonSuccessFlow("Your Order got cancelled successfully.", "Order Cancel");
            } else if (payResponseAction() == "Invalid") {
                HandleNonSuccessFlow('Something went wrong', 'Invalid Request');
            } else if (payResponseAction() == "Paid") {
                var url = config.getStoredVCTransactionResponseUrl;
                var request = {
                    "OrderNumber": payResponseId(),
                };
                https.get(url, request)
                    .then(function (data) {
                        if (data.HasErrors == false) {
                            console.log(data);
                            orderSubmitted(true);
                            commonDialogModel.IsOrderDialog(true);
                            commonDialogModel.IsOrderCancelDialog(false);
                            if (data.IntendedRecpients) {
                                commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                            }
                            commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                            // $('#orderSubmitDialog').modal('show');
                        }
                        //else {
                        //    app.showMessage("Something went wrong.", "Error").then(function (dialogResult) {
                        //        router.navigate("#orders");
                        //        return;
                        //    });
                        //}
                        else {
                            orderSubmitted(false);
                            session.isBusy(false);
                            var msgSuccess = "";
                            var msgError = "";

                            for (var i = 0; i < data.Messages.length; i++) {
                                if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                                    //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                                    //alert.error('Internal Error. Please contact Administrator.');
                                    if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                        msgError = msgError + data.Messages[i].Message + "\n";
                                    }
                                }
                                else {
                                    msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                                }
                            }
                            //alert.error(msgSuccess, orderTitle());
                            //alert.error(msgError, orderTitle());
                            if (msgSuccess.length > 0) {
                                orderSubmitted(true);
                                commonDialogModel.IsOrderDialog(true);
                                msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                                commonDialogModel.messagehtml(msgError);
                                if (data.IntendedRecpients)
                                    commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                                commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                            }
                            else if (msgError.length > 0) {
                                alert.error(msgError, orderTitle());
                            }
                        }
                    });
            } else if (payResponseAction() == "Error" || payResponseAction() == "PayFailed") {
                HandleNonSuccessFlow(responseMessage(), 'Error');

            }
            else if (payResponseAction() == "ZeroCost") {
                var url = config.submitColoradoOandEOrderUrl;
                var request = {
                    "ResponseId": payResponseId(),
                    "ResponseAction": payResponseAction(),
                };
                https.post(url, request)
                    .then(function (data) {
                        if (data.HasErrors == false) {
                            console.log(data);
                            orderSubmitted(true);
                            commonDialogModel.IsOrderDialog(true);
                            commonDialogModel.orderId(data.OrderId);
                            if (!data.OrderCancel) {
                                commonDialogModel.IsOrderDialog(true);
                                commonDialogModel.IsOrderCancelDialog(false);
                                if (data.IntendedRecpients)
                                    commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                                commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                            }
                            else {
                                //app.showMessage('Order cancelled successfully.', 'Order Cancel');
                                //commonDialogModel.IsOrderDialog(false);
                                //commonDialogModel.IsOrderCancelDialog(true);
                                //commonDialogModel.show("Order Status", "Order cancelled successfully.");
                                //setTimeout(function () {
                                //    console.log(commonDialogModel);
                                //    $('#orderCancelDialog').modal('show');
                                //}, 800);

                            }

                        }
                        else {
                            orderSubmitted(false);
                            session.isBusy(false);
                            var msgSuccess = "";
                            var msgError = "";

                            for (var i = 0; i < data.Messages.length; i++) {
                                if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                                    //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                                    //alert.error('Internal Error. Please contact Administrator.');
                                    if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                        msgError = msgError + data.Messages[i].Message + "\n";
                                    }
                                }
                                else {
                                    msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                                }
                            }
                            //alert.error(msgSuccess, orderTitle());
                            //alert.error(msgError, orderTitle());
                            if (!data.OrderCancel) {
                                orderSubmitted(true);
                                commonDialogModel.IsOrderDialog(true);
                                commonDialogModel.orderId(data.OrderId);
                                commonDialogModel.IsOrderCancelDialog(false);
                                msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                                commonDialogModel.messagehtml(msgError);
                                if (data.IntendedRecpients)
                                    commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                                commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                            }
                            else if (msgError.length > 0) {
                                alert.error(msgError, orderTitle());
                            }

                            var exception = ko.utils.arrayFilter(data.Messages, function (item) {
                                if (item.Level == 500) {
                                    return item;
                                }
                            });
                            console.log(exception[0].Message);
                            //alert.error(exception[0].Message);
                            session.isBusy(false);
                        }
                    }).fail(Failed);
            }
        }

        function Failed(error) {
            alert.error(error.statusText);
        }

        function HandleNonSuccessFlow(Message, Title) {
            var url = config.getColoradoOAndECartUrl;
            var request = {
                "OrderGuid": payResponseId(),
            };

            https.get(url, request)
                .then(function (data) {
                    session.savedCart(data);
                    console.log(data);
                    console.log(session.savedCart());
                    if (data.ServiceId) {
                        router.navigate("#paidorder/" + data.ServiceId);
                    }
                });

            app.showMessage(Message, Title).then(function (dialogResult) {
                //router.navigate("#orders");
                //return;
            });
        }

        function deactivate() {

        }

        function canDeactivate() {
            payResponseId('');
            payResponseAction('');
            return true;
        }

        function canActivate(OrderId) {
            payResponseId(OrderId);

            if (payResponseId() != "") {
                return true;
            }
            else {
                return false;
            }


        }
    });
