define('viewmodels/orderModule/bulkSaleOrder',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'commonDialogModel', 'bulkSaleOrderModel', 'customerInformationModel', 'utlities', 'commonOrderModel'],
 function (app, router, $, ko, validate, iCheck, config, validation, Q, https, session, alert, commonDialog, model, CustomerInformation, utlities,  commonOrderModel) {
     var customerInformationModel = new CustomerInformation();
     var escrowModel = new model.Escrow();
     var listingBrokerModel = new model.ListingBroker();
     var buyerOrTransfereeModel = new model.BuyerOrTransferee();
     var sellingBrokerModel = new model.SellingBroker();
     var considerationModel = new model.Consideration();
     var allocationModel = new model.Allocation();
     var noteandSecurityAgreementModel = new model.NoteandSecurityAgreement();
     var prorateasofModel = new model.Prorateasof();
     var submitOrderModel = new commonOrderModel.SubmitOrder();

     var commonDialogModel = new commonDialog("orderSubmitDialog");
     var orderSubmitted = ko.observable(false);
     var orderTitle = ko.observable('Bulk Sale/Liquor License Transfer Escrow');

     var currentView = ko.observable('');
     var currentOrderModels = ko.observableArray([]);
     var currentViewIndex = ko.observable(-1);
     var currentOrderModel = ko.observable();
     var selectedOrderType = ko.observable(0);
     var selectedOrderTypeName = ko.observable("");
     var stateList = ko.observableArray();

     currentOrderModel.subscribe(function (model) {
         vm.validationErrors = ko.validation.group([model]).extend({
             notify: 'always'
         });
         currentView(model.viewName);
         selectedOrderTypeName(model.title);

         session.handleFocusOnTabChange(model.ModelId, model.viewName);

     });

     currentViewIndex.subscribe(function (index) {
         currentOrderModel(currentOrderModels()[index]);
     });

     escrowModel.sellerOrTransferorZip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     if (data.StateAbbr != null || data.City != null) {
                         escrowModel.sellerTransferorCity(data.City);
                         escrowModel.sellerTransferorState(data.StateAbbr);
                     }
                     else {
                         alert.error("Invalid Zip.")
                         escrowModel.sellerOrTransferorZip('');
                         escrowModel.sellerTransferorCity('');
                         escrowModel.sellerTransferorState('');
                     }
                 }
                 else
                     session.zipCodeServiceDown();

             })
         }
     })

     listingBrokerModel.listingBrokerZip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     if (data.StateAbbr != null || data.City != null) {
                         listingBrokerModel.listingBrokerCity(data.City);
                         listingBrokerModel.listingBrokerState(data.StateAbbr);
                     }
                     else {
                         alert.error("Invalid Zip.")
                         listingBrokerModel.listingBrokerZip('');
                         listingBrokerModel.listingBrokerCity('');
                         listingBrokerModel.listingBrokerState('');
                     }
                 }
                 else
                     session.zipCodeServiceDown();
             })
         }
     })

     buyerOrTransfereeModel.buyerOrTransfereeZip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     if (data.StateAbbr != null || data.City != null) {
                         buyerOrTransfereeModel.buyerOrTransfereeCity(data.City);
                         buyerOrTransfereeModel.buyerOrTransfereeState(data.StateAbbr);
                     }
                     else {
                         alert.error("Invalid Zip.")
                         buyerOrTransfereeModel.buyerOrTransfereeZip('');
                         buyerOrTransfereeModel.buyerOrTransfereeCity('');
                         buyerOrTransfereeModel.buyerOrTransfereeState('');
                     }
                 }
                 else
                     session.zipCodeServiceDown();
             })
         }
     })

     sellingBrokerModel.sellingBrokerZip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     if (data.StateAbbr != null || data.City != null) {

                         sellingBrokerModel.sellingBrokerCity(data.City);
                         sellingBrokerModel.sellingBrokerState(data.StateAbbr);
                     }
                     else {
                         alert.error("Invalid Zip.")
                         sellingBrokerModel.sellingBrokerZip('');
                         sellingBrokerModel.sellingBrokerCity('');
                         sellingBrokerModel.sellingBrokerState('');
                     }
                 }
                 else
                     session.zipCodeServiceDown();
             })
         }
     })

     sellingBrokerModel.businessZip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     if (data.StateAbbr != null || data.City != null) {

                         sellingBrokerModel.businessCity(data.City);
                         sellingBrokerModel.businessState(data.StateAbbr);
                     }
                     else {
                         alert.error("Invalid Zip.")
                         sellingBrokerModel.businessZip('');
                         sellingBrokerModel.businessCity('');
                         sellingBrokerModel.businessState('');
                     }
                 }
                 else
                     session.zipCodeServiceDown();
             })
         }
     })

     sellingBrokerModel.chiefExecutiveOfficeZip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     if (data.StateAbbr != null || data.City != null) {

                         sellingBrokerModel.chiefExecutiveOfficeCity(data.City)
                         sellingBrokerModel.chiefExecutiveOfficeState(data.StateAbbr)
                     }
                     else {
                         alert.error("Invalid Zip.")
                         sellingBrokerModel.chiefExecutiveOfficeZip('');
                         sellingBrokerModel.chiefExecutiveOfficeCity('')
                         sellingBrokerModel.chiefExecutiveOfficeState('')
                     }
                 }
                 else
                     session.zipCodeServiceDown();
             })
         }
     })

     prorateasofModel.noteHolderZip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     if (data.StateAbbr != null || data.City != null) {
                         prorateasofModel.noteHolderCity(data.City)
                         prorateasofModel.noteHolderState(data.StateAbbr)
                     }
                     else {
                         alert.error("Invalid Zip.")
                         prorateasofModel.noteHolderZip('');
                         prorateasofModel.noteHolderCity('')
                         prorateasofModel.noteHolderState('')
                     }
                 }
                 else
                     session.zipCodeServiceDown();
             })
         }
     })


     noteandSecurityAgreementModel.agreementType.subscribe(function (val) {
         if (val == 'New') {
             noteandSecurityAgreementModel.isAgreementTypeNew(true);
             noteandSecurityAgreementModel.isAgreementTypeExisting(false);
         }
         else {
             noteandSecurityAgreementModel.isAgreementTypeExisting(true);
             noteandSecurityAgreementModel.isAgreementTypeNew(false);
         }

     })


     var vm = {
         activate: activate,
         canDeactivate: canDeactivate,
         goBack: goBack,
         title: 'Bulk Sale/Liquor License Transfer Escrow',

         orderTitle: orderTitle,

         currentView: currentView,
         currentOrderModels: currentOrderModels,
         currentViewIndex: currentViewIndex,
         currentOrderModel: currentOrderModel,
         selectedOrderTypeName: selectedOrderTypeName,

         moveNext: moveNext,
         movePrevious: movePrevious,
         submitOrder: submitOrder,
         navigateButtonClick: navigateButtonClick,
         commonDialogModel: commonDialogModel,

         customerInformationModel: customerInformationModel,
         escrowModel: escrowModel,
         listingBrokerModel: listingBrokerModel,
         buyerOrTransfereeModel: buyerOrTransfereeModel,
         sellingBrokerModel: sellingBrokerModel,
         considerationModel: considerationModel,
         allocationModel: allocationModel,
         noteandSecurityAgreementModel: noteandSecurityAgreementModel,
         prorateasofModel: prorateasofModel,
         submitOrderModel: submitOrderModel,
         stateList: stateList,
         getUSPhoneFormat: utlities.getUSPhoneFormat,
         getUSPhoneFormat: utlities.getUSPhoneFormat,
         getUSPhoneFormatWithExtn: utlities.getUSPhoneFormatWithExtn,
         session: session,
         compositionComplete: function () {
             session.handleFocusOnTabChange(customerInformationModel.ModelId, customerInformationModel.viewName);
         }
     }
     return vm;



     function activate(orderType) {
         orderSubmitted(false);
         selectedOrderType(parseInt(orderType));
         setupOrder();
         currentViewIndex(0);
         if (session.userName() != undefined && session.userName() != '') {
             getCustomerInfo(session.userId, session.defaultOpertionId());
         }
         utlities.getStatesList().done(function (result) {
             stateList([]);
             stateList(result);

         });
         noteandSecurityAgreementModel.isAgreementTypeExisting(true);
         session.isBusy(false);
     }

     function canDeactivate() {

         if (!session.hasLoggedOut() && !orderSubmitted()) {
             var title = 'Cancel Order?';
             var msg = 'Navigate away and cancel the order? ';
             session.isLogoutVisible(false);
             return app.showMessage(msg, title, ['Yes', 'No '])
                 .then(function (selectedOption) {
                     if (selectedOption === 'Yes') {
                         // Call some cancel function
                     }
                     session.isLogoutVisible(true);
                     return selectedOption;
                 });
         }
         else {
             return true;
         }
     };


     function setupOrder() {
         currentOrderModels([]);
         currentOrderModels.push(customerInformationModel);
         currentOrderModels.push(escrowModel);
         currentOrderModels.push(listingBrokerModel);
         currentOrderModels.push(buyerOrTransfereeModel);
         currentOrderModels.push(sellingBrokerModel);
         currentOrderModels.push(considerationModel);
         currentOrderModels.push(allocationModel);
         currentOrderModels.push(noteandSecurityAgreementModel);
         currentOrderModels.push(prorateasofModel);
         currentOrderModels.push(submitOrderModel);

         resetModels();
     }

     function moveNext() {
         // commented for development, this need to be removed.
         vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);

         if (vm.validationErrors().length > 0) {
             vm.validationErrors.showAllMessages();
             return;
         }
         currentViewIndex(currentViewIndex() + 1);

         if (currentViewIndex() == currentOrderModels().length - 1)
             buildPreviewModel();
     }

     function movePrevious() {
         currentViewIndex(currentViewIndex() - 1);
     }

     function navigateButtonClick(item, event) {
         if (item.index() > currentViewIndex()) {

             for (var i = 0; i < item.index() ; i++) {
                 // commented for development, this need to be removed.
                 vm.validationErrors = ko.validation.group(currentOrderModels()[i]);
                 if (vm.validationErrors != null && vm.validationErrors != 'undefined' && vm.validationErrors().length > 0) {
                     vm.validationErrors.showAllMessages();
                     return;
                 }
             }
         }
         currentViewIndex(item.index());

         if (currentViewIndex() == currentOrderModels().length - 1)
             buildPreviewModel();
     }

     function goBack(complete) {
         router.navigateBack();
     }

     function resetModels() {
         for (var i = 0; i < currentOrderModels().length; i++) {
             currentOrderModels()[i].index(i);

             // skip order information view reset
             if (i > 0)
                 currentOrderModels()[i].reset();
         }
     }

     function getCustomerInfo(userId, opertionId) {
         return utlities.getOperationUserInfo(userId, opertionId).done(function (data) {
             var currdate = new Date();
             var currdate = (currdate.getMonth() + 1) + '/' + currdate.getDate() + '/' + currdate.getFullYear();
             customerInformationModel.orderDate(currdate);
             utlities.getManagerFullName(data.OperationId, data.UserId).done(function (result) {
                 customerInformationModel.salesRep(result);
             })
             customerInformationModel.fullName(data.FirstName + String.fromCharCode(160) + data.LastName);
             customerInformationModel.email(data.Email);
             customerInformationModel.company(data.Company);
             customerInformationModel.address(data.StreetAddress1 + String.fromCharCode(160) + data.StreetAddress2 + String.fromCharCode(160) + data.City + ',' + String.fromCharCode(160) + data.State + String.fromCharCode(160) + data.Zip);
             customerInformationModel.phon(data.PhoneWork);
             customerInformationModel.ext(data.PhoneWorkExt);
             customerInformationModel.fax(data.PhoneFax);

         })

     }

     function getZipInfo(zip) {
         var url = config.getZipInfoUrl;
         return https.get(url, {
             zipCode: zip,
             format: 'json'
         })
     }

     function buildPreviewModel() {

         for (var i = 0; i < currentOrderModels().length - 1; i++) {
             if (currentOrderModels()[i].GetModelData) {
                 currentOrderModels()[i].GetModelData();
             }

         }
     }

     function submitOrder() {
         session.isBusy(true);
         console.log(session.isBusy());
         var url = config.submitBulkSaleLiquorLicenseUrl;
         var request = {
             //Order Model Information -- Required
             "BrandId": session.brandId,
             "OrderId": "-1",
             "UserId": session.userId,
             "ServiceId": "59",
             "OperationId": session.defaultOpertionId(),
             "CreateDate": new Date(),
             "PropertyAddress": escrowModel.sellerOrTransferorAddress,
             "PropertyCity": escrowModel.sellerTransferorCity,
             "PropertyState": escrowModel.sellerTransferorState,
             "PropertyZip": escrowModel.sellerOrTransferorZip,

             //Order Model Information -- Fill If Present
             "BackendSystem": "",
             "EscrowOfficerId": "",
             "TitleOfficerId": "",
             "IsBackendOrder": "",
             "PaymentId": "",

             // Customer Information
             "CustomerSalesRep": customerInformationModel.salesRep,
             "CustomerName": customerInformationModel.fullName,
             "CustomerEmail": customerInformationModel.email,
             "CustomerCompany": customerInformationModel.company,
             "CustomerAddress": customerInformationModel.address,
             //"CustomerPhone": customerInformationModel.phon,
             //"CustomerFax": customerInformationModel.fax,
             "CustomerPhone":
               {
                   "PhoneNumber": customerInformationModel.phon(),
                   "Extension": customerInformationModel.ext(),
                   "PhoneType": "Business"
               },
             "CustomerFax":
               {
                   "PhoneNumber": customerInformationModel.fax,
                   "Extension": "",
                   "PhoneType": "Fax"
               },

             //Escrow Information

             "EscrowCloseDate": escrowModel.closeEscrowDate,
             "Company": escrowModel.companyName,
             "EscrowNumber": escrowModel.escrowNumber,
             "EscrowOfficer": escrowModel.escrowOfficer,
             "BulkSaleOnly": escrowModel.bulksaleOnly,
             "LiquorLicenseOnly": escrowModel.liquorLicenseOnly,
             "BulkSaleAndLiquorLicense": escrowModel.bulkSaleLiquorLicense,
             "LicenseType": escrowModel.licenseType,
             "LicenseNumber": escrowModel.licenseNumber,
             "Seller": escrowModel.sellerOrTransferor,
             "SellerAddress": escrowModel.sellerOrTransferorAddress,
             "SellerCity": escrowModel.sellerTransferorCity,
             "SellerState": escrowModel.sellerTransferorState,
             "SellerZip": escrowModel.sellerOrTransferorZip,
             "SellerPhone": escrowModel.sellerOrTransferorPhone,
             "SellerPhoneExt": escrowModel.sellerOrTransferorExt,
             "SellerFax": escrowModel.sellerOrTransferorFax,

             //Listing Broker
             "ListingBrokerName": listingBrokerModel.listingBroker,
             "ListingBrokerAddress": listingBrokerModel.listingBrokerAddress,
             "ListingBrokerCity": listingBrokerModel.listingBrokerCity,
             "ListingBrokerState": listingBrokerModel.listingBrokerState,
             "ListingBrokerZip": listingBrokerModel.listingBrokerZip,
             "ListingBrokerPhone": listingBrokerModel.listingBrokerPhone,
             "ListingBrokerPhoneExt": listingBrokerModel.ext,
             "ListingBrokerFax": listingBrokerModel.listingBrokerFax,

             //Buyer/Transferee
             "BuyerName": buyerOrTransfereeModel.buyerOrTransferee,
             "BuyerAddress": buyerOrTransfereeModel.buyerOrTransfereeAddress,
             "BuyerCity": buyerOrTransfereeModel.buyerOrTransfereeCity,
             "BuyerState": buyerOrTransfereeModel.buyerOrTransfereeState,
             "BuyerZip": buyerOrTransfereeModel.buyerOrTransfereeZip,
             "BuyerPhone": buyerOrTransfereeModel.buyerOrTransfereePhone,
             "BuyerPhoneExt": buyerOrTransfereeModel.ext,
             "BuyerFax": buyerOrTransfereeModel.buyerOrTransfereeFax,

             //Selling Broker
             "SellingBrokerName": sellingBrokerModel.sellingBroker,
             "SellingBrokerAddress": sellingBrokerModel.sellingBrokerAddress,
             "SellingBrokerCity": sellingBrokerModel.sellingBrokerCity,
             "SellingBrokerState": sellingBrokerModel.sellingBrokerState,
             "SellingBrokerZip": sellingBrokerModel.sellingBrokerZip,
             "SellingBrokerPhone": sellingBrokerModel.sellingBrokerPhone,
             "SellingBrokerPhoneExt": sellingBrokerModel.ext,
             "SellingBrokerFax": sellingBrokerModel.sellingBrokerFax,
             //Business Information
             "BusinessName": sellingBrokerModel.nameofBusiness,
             "BusinessType": sellingBrokerModel.typeofBusiness,
             "BusinessAddress": sellingBrokerModel.businessAddress,
             "BusinessCity": sellingBrokerModel.businessCity,
             "BusinessState": sellingBrokerModel.businessState,
             "BusinessZip": sellingBrokerModel.businessZip,
             //CEO Information
             "CEOAddress": sellingBrokerModel.chiefExecutiveOfficeAddress,
             "CEOCity": sellingBrokerModel.chiefExecutiveOfficeCity,
             "CEOState": sellingBrokerModel.chiefExecutiveOfficeState,
             "CEOZip": sellingBrokerModel.chiefExecutiveOfficeZip,
             //Consideration Information

             "InitialDeposit": considerationModel.initialDeposit,
             "AmountPrior": considerationModel.amountPriortoclose,
             "NoteSecurityAgreement": considerationModel.noteSecurityAgreement,
             "NoteSecurityAgreement2": considerationModel.noteSecurityAgreement2,
             "ExistingLoan": considerationModel.existingLoan,
             "OtherConsideration1": considerationModel.other,
             "OtherConsideration2": considerationModel.other1,
             "EmploymentDevDeptNumber": considerationModel.employmentDevelopmentDeptNumber,

             //Allocation Information
             "FixturesEquipment": allocationModel.fixturesandEquipment,
             "LeaseholdInterest": allocationModel.leaseholdInterest,
             "Goodwill": allocationModel.goodwill,
             "Inventory": allocationModel.inventory,
             "Leasehold": allocationModel.leaseholdImprovement,
             "Covenant": allocationModel.covenant,
             "OtherAllocation": allocationModel.other,

             //Note and Security Information
             "AgreementType": noteandSecurityAgreementModel.agreementType,
             "InFavorOf": noteandSecurityAgreementModel.ifNewinfavorof,
             "Amount": noteandSecurityAgreementModel.amount,
             "InterestRate": noteandSecurityAgreementModel.interestRate,
             "PaymentDue": noteandSecurityAgreementModel.paymentDue,
             "Beginning": noteandSecurityAgreementModel.beginning,
             "AllDue": noteandSecurityAgreementModel.allDue,
             "PaymentAmount": noteandSecurityAgreementModel.paymentAmount,
             "PaymentType": noteandSecurityAgreementModel.paymentType,
             "AdditionalTerms": noteandSecurityAgreementModel.additionalTerms,

             //Proration Information
             "ProrateAsOf": prorateasofModel.prorateasof,
             "OtherProrate": prorateasofModel.other,
             "PersonalPropertyTaxes": prorateasofModel.personalPropertyTaxes,
             "LeasePayments": prorateasofModel.leasePayments,
             "Rents": prorateasofModel.rents,
             "InterestOnLoan": prorateasofModel.interestOnLoan,
             "OtherPayment1": prorateasofModel.other1,
             "OtherPayment2": prorateasofModel.other2,
             "SellerCredited": prorateasofModel.sellerCredited,
             "RentIsPaidTo": prorateasofModel.rentispaidTo,
             "InsuranceAgent": prorateasofModel.insuranceAgent,
             "InsuranceAgentPhone": prorateasofModel.insuranceAgentPhone,
             "InsuranceAgentPhoneExt": prorateasofModel.ext,
             "NoteHolder": prorateasofModel.existingNoteHolder,
             "NoteHolderAddress": prorateasofModel.noteHolderAddress,
             "NoteHolderCity": prorateasofModel.noteHolderCity,
             "NoteHolderState": prorateasofModel.noteHolderState,
             "NoteHolderZip": prorateasofModel.noteHolderZip,
             "ExistingContracts": prorateasofModel.existingContractstobeassumed

         }

         https.post(url, request)
            .then(function (data) {
                if (data.HasErrors == false) {
                    orderSubmitted(true);
                    commonDialogModel.IsOrderDialog(true);
                    commonDialogModel.orderId(data.OrderId);
                    if (data.IntendedRecpients)
                        commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                    commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                }
                //else {
                //    for (var i = 0; i < data.Messages.length; i++) {
                //        if (data.Messages[i].ExceptionMessage != "") {
                //            console.log("Error:" + data.Messages[i].ExceptionMessage, orderTitle());
                //            alert.error('Internal Error. Please contact Administrator.');
                //            orderSubmitted(false);
                //            session.isBusy(false);
                //        }
                //    }
                //}
                else {
                    orderSubmitted(false);
                    session.isBusy(false);
                    var msgSuccess = "";
                    var msgError = "";

                    for (var i = 0; i < data.Messages.length; i++) {
                        if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                            //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                            //alert.error('Internal Error. Please contact Administrator.');
                            if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                msgError = msgError + data.Messages[i].Message + "\n";
                            }
                        }
                        else {
                            msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                        }
                    }
                    //alert.error(msgSuccess, orderTitle());
                    //alert.error(msgError, orderTitle());
                    if (msgSuccess.length > 0) {
                        orderSubmitted(true);
                        commonDialogModel.IsOrderDialog(true);
                        commonDialogModel.orderId(data.OrderId);
                        msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                        commonDialogModel.messagehtml(msgError);
                        if (data.IntendedRecpients)
                            commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                        commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                    }
                    else if (msgError.length > 0) {
                        alert.error(msgError, orderTitle());
                    }

                }
            })
           .fail(function (data) {
               var error = JSON.parse(data.responseText)
               console.log(error);
               session.isBusy(false);
           })

     }

 });
