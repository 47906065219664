define('viewmodels/orderModule/viewOnlineOrders',['plugins/router', 'knockout', 'durandal/app', 'plugins/http', 'global/session', 'viewOnlineModel', 'config', 'nameValue', 'nameList', 'moment', 'alertNotification', 'koPaging', 'commonOrderModel', 'viewOrder', 'alertNotification', 'viewOrderService', 'printThis'],
    function (router, ko, app, https, session, model, config, nameValue, nameList, moment, alerter, koPaging, commonOrderModel, viewOnlyOrder, alert, viewOrderService, printThis) {

        var onlineOrderSearchModel = new model.OnlineOrderSearch();
        var searchResults = ko.observableArray([]);
        var orderDetail = ko.observable(undefined);
        var viewOrder = new viewOnlyOrder.ViewOrder();
        var emailMessage = ko.observable("").extend({ required: { message: 'Email Message is required.' } });
        var emailSubject = ko.observable("");//.extend({ required: { message: 'Email Subject is required.' } });
        var orderSubmittedByEmail = ko.observable("");
        var vm = {
            canActivate: canActivate,
            activate: activate,
            canDeactivate: canDeactivate,
            onlineOrderSearchModel: onlineOrderSearchModel,
            searchOrders: searchOrders,
            clearSearch: clearSearch,
            searchResults: searchResults,
            getSelectedValue: getSelectedValue,
            changeimage: changeimage,
            itemClicked: itemClicked,
            recentResult_click: recentResult_click,
            viewOrder: viewOrder,
            PrintOnlineOrderDetails: PrintOnlineOrderDetails,
            EmailOnlineOrderDetails: EmailOnlineOrderDetails,
            SendEmailAcknowledgment: SendEmailAcknowledgment,
            emailMessage: emailMessage,
            emailSubject: emailSubject,
            orderSubmittedByEmail: orderSubmittedByEmail,
            compositionComplete: function () {
                session.validateInput();
                $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('hide');
                $("#panel1Content").on('shown.bs.collapse', function () {
                    $(".panel1Icon").removeClass("fa-arrow-down").addClass("fa-arrow-up");
                });
                $("#panel1Content").on('hidden.bs.collapse', function () {
                    $(".panel1Icon").removeClass("fa-arrow-up").addClass("fa-arrow-down");
                });
            }
        };

        //vm.emailSubjectError = ko.validation.group([vm.emailSubject]);
        vm.emailMessageError = ko.validation.group([vm.emailMessage]);

        return vm;

        function PrintOnlineOrderDetails() {
            //get the modal box content and load it into the printable div
            //var elementId = "onlineOrderDetails";
            //var divToPrint = document.getElementById(elementId);
            //var printableDiv = document.getElementById("printable");
            //printableDiv.style = "margin-top:20px";
            //printableDiv.innerHTML = divToPrint.innerHTML;
            //printableDiv.insertAdjacentHTML('afterbegin', '<h3 class="text-center mt-3">Online Order Details</h3>');

            //window.print();

            //printableDiv.innerHTML = "";

            $('#onlineOrderDetails').printThis(
                {
                    header: '<h3 class="text-center mt-1">Online Order Details</h1>'
                });
        }

        function EmailOnlineOrderDetails() {
            console.log("EmailOnlineOrderDetails : Pop-up open");
            orderSubmittedByEmail(vm.viewOrder.SubmittedByEmail());
            emailMessage("");
            var sub = "Online Order Id : " + vm.viewOrder.Id();
            emailSubject(sub);
            $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('show');
            $('.modal-body', '#mdEmailAcknowledgmentOnlineOrderDetails').scrollTop(0);
        }


        function SendEmailAcknowledgment() {
            if (vm.emailMessageError != null && vm.emailMessageError != 'undefined' && vm.emailMessageError().length > 0) {
                vm.emailMessageError.showAllMessages();
                return;
            }

            
            //var dictEmailTo = {
            //    "Key": vm.viewOrder.SubmittedByEmail(), "Value": vm.viewOrder.SubmittedByEmail()
            //};
            //var to = [];
            //to.push(dictEmailTo);
            var url = config.emailAcknowledgmentForOrderDetailsUrl;

            var emailRequest = {
                "SenderName": session.fullName(),
                "SenderEmail": session.email(),
                "Subject": emailSubject(),
                "EmailHTML": emailMessage(),
                "To": vm.viewOrder.SubmittedByEmail()
            }

            return https.post(url, emailRequest)
                .done(function (data) {
                    $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('hide');
                    if (data != null) {
                        if (data.HasErrors == false) {
                            alert.success("Email Sent", "Email Acknowledgment For Online Order Details");
                        }
                        else {
                            alert.error(data.Messages[0].Message, "Email Acknowledgment For Online Order Details");
                        }
                    }
                    else {
                        alert.error('Internal Server Error', "Email Acknowledgment For Online Order Details");
                    }
                }).fail(function () {
                    $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('hide');
                    alert.error('Error while sending email', "Email Acknowledgment For Online Order Details");
                });
        }

        //function SendEmailAcknowledgment() {
        //    console.log("SendEmailAcknowledgment : Inside send email");
        //}


        function recentResult_click(result, event) {
            console.log(result);
            loadOrderDetail(result.orderId());
        }

        function canActivate() {
            $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('hide');
            if (!session.isLoggedIn()) {
                router.navigate('#login', { replace: false, trigger: true });
                return false;
            }
            return true;
        }

        function loadOrderDetail(orderId) {
            viewOrderService.getViewOrder(orderId).done(function (orderDetail) {
                if (orderDetail.HasErrors()) {
                    for (var i = 0; i < orderDetail.ErrorMessages().length; i++) {
                        alert.error(orderDetail.ErrorMessages()[i]);
                    }
                }
                else {

                    viewOrder.Id(orderDetail.Id());
                    viewOrder.OrderNumber(orderDetail.OrderNumber());
                    viewOrder.OrderStatus(orderDetail.OrderStatus());
                    viewOrder.OrderStatusName(orderDetail.OrderStatusName());
                    viewOrder.OrderTypeName(orderDetail.OrderTypeName());
                    viewOrder.CreateDate(orderDetail.CreateDate());
                    viewOrder.EstimatedCOEDate(orderDetail.EstimatedCOEDate());

                    if (orderDetail.TransactionTypeCode == 'UNKN') {
                        viewOrder.TransactionTypeCode(orderDetail.TransactionTypeName());
                    } else {
                        viewOrder.TransactionTypeCode(orderDetail.TransactionTypeCode());
                    }
                    viewOrder.IsSP360ManualOrder(orderDetail.IsSP360ManualOrder);

                    viewOrder.ProviderType(orderDetail.ProviderType());
                    viewOrder.TransactionTypeCode(orderDetail.TransactionTypeCode());
                    viewOrder.TransactionAmount(orderDetail.TransactionAmount());
                    viewOrder.LimitedCoveragePolicy(orderDetail.LimitedCoveragePolicy());
                    viewOrder.PropertyAddressHTML(orderDetail.PropertyAddressHTML());
                    viewOrder.PropertyTypeName(orderDetail.PropertyTypeName());
                    viewOrder.SubmittedByEmail(orderDetail.SubmittedByEmail());
                    viewOrder.SubmittedByFullName(orderDetail.SubmittedByFullName());
                    viewOrder.SubmittedBySalesRep(orderDetail.SubmittedBySalesRep());
                    viewOrder.SubmittedBySalesRepEmail(orderDetail.SubmittedBySalesRepEmail());
                    viewOrder.SpecialInstructions(orderDetail.SpecialInstructions());
                    viewOrder.Principals(orderDetail.Principals());
                    viewOrder.Logs(orderDetail.Logs());
                    viewOrder.Offices(orderDetail.Offices());
                    viewOrder.Statuses(orderDetail.Statuses());
                    viewOrder.TransactionParties(orderDetail.TransactionParties());

                    $('#mdonlineOrderDetails').modal('show');
                    //$('.modal-body', '#mdonlineOrderDetails').scrollTop(0);
                }

            }).fail(function () {
                alert.error("Error in getting Order details");
            });
        }

        function activate(OrderId) {
            $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('hide');
            viewOrder.reset();
            AfterLoad();
            searchResults.extend({
                paged: {
                    pageNumber: 1,
                    pageSize: 10,
                    pageGenerator: 'default'
                }
            });


            if (session.onlineSearchResults().length > 1) {
                onlineOrderSearchModel.orderRefNumber(session.orderRefNumber()),
                    onlineOrderSearchModel.escrowOrderNumber(session.escrowOrderNumber()),
                    onlineOrderSearchModel.titleOrderNumber(session.titleOrderNumber()),
                    onlineOrderSearchModel.customerName(session.customerName()),
                    onlineOrderSearchModel.propertyAddress(session.propertyAddress()),
                    onlineOrderSearchModel.fromDate(session.fromDate()),
                    onlineOrderSearchModel.toDate(session.toDate()),
                    searchResults(session.onlineSearchResults());
            }
            else {
                GetCustomers();
                var date = new Date();
                onlineOrderSearchModel.fromDate(moment(date.setDate(date.getDate() - 14)).format('MM/DD/YYYY'));
                onlineOrderSearchModel.toDate(moment(new Date()).format('MM/DD/YYYY'));
            }
            searchOrders().done(function () {
                if (OrderId != null && OrderId != '') {
                    loadOrderDetail(OrderId);
                    //onlineOrderSearchModel.orderRefNumber(OrderId);
                    //searchOrders();
                }
            });
        }

        function canDeactivate() {
            searchResults([]);
            clearSearch();
            session.clearOrderForPropertyAddress();
            return true;
        }
        function AfterLoad() {
            if ($('#hdnIsLogin').val() == "true") {
                $('#page-wrapper').attr('style', 'margin-left:220!important');
            }
        }

        function GetCustomers() {
            var url = config.getCustomersUrl;
            https.get(url, {
                //OperationId: session.defaultOpertionId(), TopUserId: session.userId
            }).then(function (data) {
                vm.onlineOrderSearchModel.customerList([]);
                vm.onlineOrderSearchModel.customerList(data);
                if (data.length > 1) {
                    vm.onlineOrderSearchModel.customerList.unshift({
                        Key: "00000000-0000-0000-0000-000000000000", Value: "Any Customer"
                    });
                }
                vm.onlineOrderSearchModel.customerName(vm.onlineOrderSearchModel.customerList()[0].Key);
                console.log(data);
            }).fail(function (data) {
                console.log(data);
            });
        }

        function isValidateDateRange(fromDate, toDate) {
            var fromDate = moment(fromDate, "MM-DD-YYYY");
            var toDate = moment(toDate, "MM-DD-YYYY");
            var result = (fromDate > toDate);
            return result;

        };
        function searchOrders() {
            //if (!session.llaAccepted()) {
            //    session.showLLAAgreement();
            //    return;
            //}
            if (!vm.onlineOrderSearchModel.isValid()) {
                vm.onlineOrderSearchModel.errors.showAllMessages();
                return;
            }

            if (isValidateDateRange(onlineOrderSearchModel.fromDate(), onlineOrderSearchModel.toDate())) {
                alerter.warning("From date cannot be later than To date");
                return;
            }

            searchResults([]);
            var url = config.searchOnlineOrdersUrl;
            var searchParams = {
                "OrderId": onlineOrderSearchModel.orderRefNumber(),
                "Apn": onlineOrderSearchModel.apnNumber(),
                "UserId": onlineOrderSearchModel.customerName(),
                "CreateDateFrom": onlineOrderSearchModel.fromDate(),
                "CreateDateTo": onlineOrderSearchModel.toDate(),
                "OperationId": session.defaultOpertionId()
            }

            return https.post(url, searchParams)
                .then(function (data) {
                    console.log(data);
                    if (data && data.length > 0) {
                        searchResults([]);
                        var converted = loadResultToKOArray(data);
                        searchResults(converted);
                        //session.setOnlineSearchData(param, searchResults());
                    }
                })
                .fail(function (data) {
                    if (data != null && data != undefined) {
                        if (data.responseJSON != null && data.responseJSON.ExceptionMessage != null && data.responseJSON != undefined && data.responseJSON.ExceptionMessage != null) {
                            alerter.warning(data.responseJSON.ExceptionMessage);
                        }
                    }
                    console.log(data);
                });
        }

        function loadResultToKOArray(data) {
            var results = [];
            var space = String.fromCharCode(160);
            for (var i = 0; i < data.length; i++) {

                orderSearchResultsModel = new model.OrderSearchResults();
                orderSearchResultsModel.orderId(data[i].Id);
                orderSearchResultsModel.propertyAddress(data[i].FullAddressText);
                orderSearchResultsModel.propertyCity(data[i].City);
                orderSearchResultsModel.propertyState(data[i].State);
                orderSearchResultsModel.propertyZip(data[i].Zip);
                orderSearchResultsModel.propertyCounty(data[i].County);
                orderSearchResultsModel.apn(data[i].apn);
                orderSearchResultsModel.firstName(data[i].SubmittedByFirstName);
                orderSearchResultsModel.lastName(data[i].SubmittedByLastName);
                orderSearchResultsModel.fullName(data[i].SubmittedByLastName + ', ' + data[i].SubmittedByFirstName);
                orderSearchResultsModel.email(data[i].SubmittedByFirstName + data[i].SubmittedByEmail);
                orderSearchResultsModel.orderStatus(data[i].OrderStatusName);
                orderSearchResultsModel.orderDate(moment(data[i].CreateDate).format('MM/DD/YYYY'));
                orderSearchResultsModel.detailPage("viewOnlineOrderDetail")
                results.push(orderSearchResultsModel);

            }
            return results;
        }

        function getSelectedValue(selectedItem, event) {
            var OrderId = selectedItem.orderId;
            router.navigate("/viewOnlineOrderDetail" + '/' + OrderId);
        }

        //load to local array.
        function loadToKOArray(webAPICollectionObject) {
            var mappedLocalKOArray = [];

            for (var j = 0; j < webAPICollectionObject.length; j++) {
                var lst = new nameList();

                var mappedSectionDataArray = [];
                for (var i = 0; i < webAPICollectionObject[j].Data.length; i++) {
                    var item = new nameValue(); //nameValue = a common model

                    item.Name(webAPICollectionObject[j].Data[i].Name);
                    item.Value(webAPICollectionObject[j].Data[i].Value);
                    mappedSectionDataArray.push(item);
                }

                lst.Name(webAPICollectionObject[j].Name);
                lst.List.push(mappedSectionDataArray);
                mappedLocalKOArray.push(lst);
            }
            return mappedLocalKOArray;
        }

        function getOrderDetail(selectedItem) {
            var url = config.getOfflineOrderDetailsUrl;
            return https.get(url, {
                orderId: selectedItem,
                format: 'json'
            });
        }

        function clearSearch() {
            onlineOrderSearchModel.reset();
        }

        function itemClicked(index) {

            var id = "div" + index;
            var el = document.getElementById(id);
            if (el != null) {
                if (el.style.display != 'none') {
                    el.style.display = 'none';
                }
                else {
                    el.style.display = '';
                }
            }

            changeimage(id.replace('div', 'img'), "arrow_up.gif", "arrow_down.gif");
        }

        function changeimage(id, sMinus, sPlus) {
            var img = document.getElementById(id);
            if (img != null) {
                var bExpand = img.src.indexOf(sPlus) >= 0;
                if (!bExpand)
                    img.src = '/Content/images/' + sPlus;
                else
                    img.src = '/Content/images/' + sMinus;
            }
        }
        ko.bindingHandlers.uniqueId = {
            init: function (element, valueAccessor) {
                var value = valueAccessor();
                value.id = value.id || ko.bindingHandlers.uniqueId.prefix + (ko.bindingHandlers.uniqueId.counter++);

                element.id = value.id;
                value.id = 0;
                counter = 0;
            },
            counter: 0,
            prefix: "div"
        };

    });
