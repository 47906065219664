define('helper/alertNotification',['toastr', 'config'], function (toastr, config) {
    var
        success = function (message) {
            init();
            toastr.success(message);
        },
        error = function (message) {
            init();
            toastr.error(message);
        },
        info = function (message) {
            init();
            toastr.info(message);
        },
        warning = function (message, timeout) {
            init();
            toastr.warning(message, '', timeout);
        },
        init = function () {

            toastr.options.positionClass = config.toastr.positionClass;
            toastr.options.extendedTimeOut = 0;  //config.toastr.extendedTimeOut;
            toastr.options.fadeIn = config.toastr.fadeIn;
            toastr.options.fadeOut = config.toastr.fadeOut;
            toastr.options.timeOut = 0;//config.toastr.timeOut;
            toastr.options.showEasing = config.toastr.showEasing;
            toastr.options.hideEasing = config.toastr.hideEasing;
            toastr.options.closeButton = true; //config.toastr.closeButton;
        };
    init();
    return {
        success: success,
        error: error,
        info: info,
        warning: warning
    }

});
