define('models/user/salesExecutiveModel',["knockout", "global/session"],
    function (ko, session) {
        function salesExecutiveModel() {
            var self = this;
            self.IsSalesExecutiveAvailable = ko.observable(false);
            self.FullName = ko.observable("");
            self.Email = ko.observable("");
            self.Phone = ko.observable("");
            self.PhoneExt = ko.observable("");
            self.Address = ko.observable("");
            self.City = ko.observable("");
            self.Zip = ko.observable("");
            self.PhotoUrl = ko.observable("");
            self.UserType = ko.observable("");
            self.displayUserType = ko.computed(function () {
                if (session.userIsInRole("Customer"))
                    return "Your Sales Executive";
                else
                    return "Your " + self.UserType();
            }, this);
            self.displayCityZip = ko.computed(function () {
                return self.City() + " - " + self.Zip();
            }, this);
        }

        var vm = {
            SalesExecutiveModel: salesExecutiveModel
        }

        return vm;
    });
