define('viewmodels/mobileApp/mobileApp',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'knockout.validation', 'plugins/http', 'global/session', 'config', 'alertNotification', 'utlities'],
    function (app, router, $, ko, validate, validation, https, session, config, alert, utlities)
    {
        var iTunesUrl = ko.observable('');
        var playStoreUrl = ko.observable('');
        var iTunesUrlForTheBrand = ko.observable('');
        var playStoreUrlForTheBrand = ko.observable('');
        function activate(brand)
        {
            var title = app.title;
            var currentBrand = title.substr(0, title.indexOf(' '));
            GetBrandMobileAppLinks(brand);
            
        }


        function SetMobileAppUrls(brand)
        {
            switch (brand)
            {
                case 'Ticor':
                    //Ticor
                    //iTunesUrl('https://itunes.apple.com/us/app/ticor-title-pocket-profile/id392092455?mt=8')
                    //playStoreUrl('https://play.google.com/store/apps/details?id=com.fntg.ticor.mobile.PocketProfile&hl=en');
                    iTunesUrl(iTunesUrlForTheBrand());
                    playStoreUrl(playStoreUrlForTheBrand());
                    break;
                case 'Fntic':
                    //FNTIC
                    iTunesUrl('https://itunes.apple.com/us/app/fidelity-national-title-1-2-3/id392082723?mt=8')
                    playStoreUrl('https://play.google.com/store/apps/details?id=com.fntg.fntic.mobile.Title123&hl=en');
                    break;
                case 'Fntg':
                    //iTunesUrl('https://itunes.apple.com/us/app/fntg-profile-now/id507194923?mt=8')
                    //playStoreUrl('https://play.google.com/store/apps/details?id=com.fntg.mobile.FNTGProfile&hl=en');
                    iTunesUrl(iTunesUrlForTheBrand());
                    playStoreUrl(playStoreUrlForTheBrand());
                    break;
                case 'Commonwealth':
                    //iTunesUrl('https://itunes.apple.com/us/app/commonwealth-mobile-profile/id392077888?mt=8')
                    //playStoreUrl('https://play.google.com/store/apps/details?id=com.fntg.cltic.mobile.MobileProfile&hl=en');
                    app.title = 'Commonwealth Land Title Insurance Company';
                    iTunesUrl(iTunesUrlForTheBrand());
                    playStoreUrl(playStoreUrlForTheBrand());
                    break;
                case 'Chicago':
                    ////targetUrl('http://itunes.apple.com/us/app/chicago-title-now/id388750158?mt=8')
                    ////app.title = 'Chicago Title Premier Services';
                    //var redirectUrl = 'http://www.chicagotitlemi.com/MarketingServices.htm';
                    //session.CheckPopUpBlock(redirectUrl);
                    ////window.open(redirectUrl, '_blank');
                    //router.navigate('#login');
                    iTunesUrl(iTunesUrlForTheBrand());
                    playStoreUrl(playStoreUrlForTheBrand());
                    app.title = 'Chicago Title Premier Services';
                    break;
                case 'Alamo':
                    iTunesUrl('https://itunes.apple.com/us/app/alamo-title-mobile-advantage/id804811968?mt=8')
                    playStoreUrl('https://play.google.com/store/apps/details?id=com.fnf.gps.alamo.mobileAdvantage&hl=en');
                    app.title = 'Alamo Title Company';
                    break;
                case 'Grand':
                    var redirectUrl = 'http://www.gctaagent.com/';
                    session.CheckPopUpBlock(redirectUrl);
                    //window.open(redirectUrl, '_blank');
                    router.navigate('#login');
                    break;
                case 'Lawyers':
                    //LTIC
                    iTunesUrl('https://itunes.apple.com/us/app/lawyers-title-property-now/id392089381?mt=8')
                    playStoreUrl('https://play.google.com/store/apps/details?id=com.fntg.ltic.mobile.PropertyNow&hl=en');
                    app.title = 'Lawyers Title';
                    break;
                case 'Security':
                    iTunesUrl('https://itunes.apple.com/us/app/security-title-gateway/id414345999?l=es&mt=8');
                    playStoreUrl('https://play.google.com/store/apps/details?id=com.fntg.sta.mobile.Gateway&hl=en');
                    app.title = 'Security Title Agency';
                    break;
                case 'Austin':
                    iTunesUrl('https://itunes.apple.com/us/app/austin-title-access/id824401823?mt=8')
                    playStoreUrl('https://play.google.com/store/apps/details?id=com.fnf.gps.austinMobileProfile&hl=en');
                    app.title = 'Austin Title';
                    break;
            }
            console.log("iTunesUrl " + iTunesUrl());
            console.log("playStoreUrl " + playStoreUrl());
        }

        function GetBrandMobileAppLinks(brand)
        {
            var url = config.getBrandMobileAppLinksUrl;
            https.get(url, {
                format: 'json'
            }).done(function (result)
            {
                if (result != undefined && result != null && !result.HasErrors)
                {
                    console.log(result);

                    if (!result.HasErrors)
                    {
                        iTunesUrlForTheBrand(result.MobileAppUrlIOS);
                        playStoreUrlForTheBrand(result.MobileAppUrlAndroid);
                        SetMobileAppUrls(brand);
                    }
                    else
                    {
                        alert.error(data.Messages[0].Message, "Mobile Apps");
                    }
                }
                else
                {
                    alert.error('Internal Server Error', "Mobile Apps");
                }
            });
        }

        //function isZipValid() {
        //    if (calculatorsModel.zip() == '' || calculatorsModel.zip() == undefined)
        //        alert.error('Zip is required');
        //    if (calculatorsModel.zip().length == 5)
        //        return true;
        //}
        var vm = {
            //isZipValid: isZipValid,
            activate: activate,
            iTunesUrl: iTunesUrl,
            playStoreUrl: playStoreUrl,
            session: session,
        }
        return vm;

    })



;
