define('models/user/changePasswordModel',["knockout"],
    function (ko) {
        function PasswordModel() {
            var self = this;

            self.title = ko.observable("Security")

            self.currentPassword = ko.observable("")
                .extend({ required: { message: 'Current Password is required.' } })

            self.newPassword = ko.observable("")
                .extend({ required: { params: true, message: 'New Password is required.' } })
                .extend({ minLength: { params: 8, message: 'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.' } })
                .extend({ maxLength: { params: 128, message: 'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.' } })
                .extend({
                    pattern: {
                        params: /\d{1}/,
                        message: 'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                    }
                })
                .extend({
                    pattern: {
                        params: /[a-zA-Z]/,
                        message: 'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                    }
                })
                .extend({
                    pattern: {
                        params: /(?=.*[a-z])(?=.*[A-Z])/,
                        message: 'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                    }
                })



            self.newPassword.subscribe(function (newValue) {
                if (newValue.length >= 128) {
                    $("#maxPasswordLength").show();
                } else {
                    $("#maxPasswordLength").hide();
                }
            })

            self.confirmPassword = ko.observable("")
                .extend({ required: { params: true, message: 'Confirm Password is required.' } })
                //.extend({ minLength: { params: 8, message: 'Password should be of minimum 8 characters length.' } })
                .extend({ equal: { params: self.newPassword, message: 'New Password and New Password Confirmation must match.' } })

            self.updatePassword = function (data) {
                if (data) {
                    self.currentPassword(data.currentPassword);
                    self.newPassword(data.newPassword);
                    self.confirmPassword(data.confirmPassword);
                }
            };

            self.reset = function () {
                self.currentPassword("");
                self.newPassword("");
                self.confirmPassword("");
                ko.validation.group(self).showAllMessages(false);
            }

            self.errors = ko.validation.group(self);
            self.isValid = ko.computed(function () {
                return self.errors().length === 0;
            });
            self.token = ko.observable('');
        }

        var vm = {
            PasswordModel: PasswordModel
        }
        return vm;
    });
