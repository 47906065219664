define('models/feedback/feedbackModel',["knockout"],
    function (ko) {

        function feedback() {
            var self = this;
            self.BrandId = ko.observable(0);
            self.BrandName = ko.observable("");
            self.OperationId = ko.observable("");
            self.Name = ko.observable("")
                .extend({ required: { message: 'Name is required.' } });
            self.Email = ko.observable("")
                .extend({ required: { message: 'Email Address is required.' } })
                .extend({ email: { message: 'Invalid Email Address.' } });
            self.ConfirmEmail = ko.observable("")
                .extend({ required: { message: 'Confirm Email is required.' } })
                .extend({ equal: { params: self.Email, message: 'Email and Confirm Email must match.' } })
                .extend({ email: { message: 'Invalid Confirm Email' } });

            self.Subject = ko.observable("")
                .extend({ required: { message: 'Email Subject is required.' } })

            self.Message = ko.observable("")
                .extend({ required: { message: 'Email Message is required.' } })
                .extend({
                    pattern: {
                        message: "Email Message must be 500 characters or less. Only Alphanumeric and some special characters (.|*?+$!&@,/()[]'-) allowed.",
                        params: "^[\\w\\s\\.\\-\\'\\|\\*\\^\\%\\#\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,500}$"
                    }
                });

            self.reset = function () {
                self.BrandId(0);
                self.BrandName("");
                self.OperationId("");
                self.Name("");
                self.Email("");
                self.ConfirmEmail("");
                self.Subject("");
                self.Message("");
                ko.validation.group(self).showAllMessages(false);
            }
        }

        var vm = {
            feedback: feedback,
        }

        return vm;
    });
