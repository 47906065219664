define('viewmodels/userModule/InHereFinishExisting',['plugins/router', 'durandal/app', 'services/security', 'knockout', 'jquery', 'plugins/http', 'global/session', 'config', 'alertNotification', 'migrationModel', 'jquerycapslock', 'services/logger'],
    function (router, app, security, ko, $, https, session, config, alerter, migrationModel, jquerycapslock, logger) {

        var isLoggedIn = ko.observable(false);


        var vm = {
            activate: activate,
            title: 'login',
            session: session,
            setup: setup

        }



        return vm;




        function activate() {
            //reset();
            /*$('#LoginUserName').val(session.inHereUpgradeUserEmail());*/
        }


        function setup() {

            window.location.href = session.oktaActivationUrl();
        }

    });
