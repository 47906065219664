define('viewmodels/propertySearchModule/radiusSearch1',['durandal/app', 'knockout', 'bootstrap', 'bootstrapSlider', 'global/session', 'propertySearchCommon'],
	function (app, ko, bootstrap, bootstrapSlider, session, propertySearchCommon) {

	    var self = this;
	    var defaultRadius = 0.5;
	    var radius = ko.observable(defaultRadius);
	    var searchRequest;

	    initSearchParams = function () {
	        if (searchRequest == undefined) {
	            searchRequest = {
	                Fips: ko.observable(),
	                latitude: ko.observable(),
	                longitude: ko.observable(),
	                radius: ko.observable(),
	                Zip: ko.observable(),
	                State: ko.observable(),
	                County: ko.observable(),
	                Apn: ko.observable(),
	                currentRadius: ko.observable(defaultRadius)
	            };
	        }

	        return searchRequest;
	    }

	    initSearchParams();
	    var initCompleted = false;

	    //// This event fires when the slider is dragged
	    //.on(slide, callback);
	    //// This event fires when dragging starts
	    //.on(slideStart, callback);
	    //// This event fires when the dragging stops or has been clicked on
	    //.on(slideStop, callback);
	    //// This event fires when the slider value has changed
	    //.on(change, callback);
	    //// This event fires when the slider is enabled
	    //.on(slideEnabled, callback);
	    //// This event fires when the slider is disabled
	    //.on(slideDisabled, callback);
	    var sliderCtrl;
	    var radiusChangeCallbackFn;
	    var eventsInitialized = false;

	    //this is not the actual init but extension
	    init = function (sliderCtrlId, radiusChangeCallback) {

	        //sliderCtrl = $("#" + sliderCtrlId);
	        //if (sliderCtrl != undefined) {
	        //    var rngSlider = sliderCtrl.bootstrapSlider({
	        //        enabled: false,
	        //        formatter: function (value) {
	        //            return value + ' mi.';
	        //        }
	        //    });
	        //    rngSlider.unbind('slideStop');
	        //    rngSlider.on('slideStop', onRadiusChange);

	        //    initSearchParams();
	        //    initCompleted = true;
	        //    radiusChangeCallbackFn = radiusChangeCallback;
	        //}




	        console.log('radiusSearch Init:');
	        var dvSldr = $('#' + sliderCtrlId + 'Slider');
	        initCompleted = dvSldr && dvSldr.length > 0;

	        if (initCompleted == false) {
	            console.log('radiusSearch Init: failed');
	        }

	        if (initCompleted == true && eventsInitialized == false) {
	            sliderCtrl = $("#" + sliderCtrlId);
	            if (sliderCtrl != undefined) {
	                var rngSlider = sliderCtrl.bootstrapSlider({
	                    enabled: false,
	                    formatter: function (value) {
	                        return value + ' mi.';
	                    }
	                });
	                rngSlider.unbind('slideStop');
	                rngSlider.on('slideStop', onRadiusChange);

	                initSearchParams();
	                initCompleted = true;
	                self.radiusChangeCallbackFn = radiusChangeCallback;
	            }
	        }
	        if (initCompleted & session.isRadiusSearchActive() == false) {
	            sliderCtrl = $("#" + sliderCtrlId);
	            sliderCtrl.bootstrapSlider('disable');
	        }

	        if (dvSldr == null || dvSldr.length == 0) {
	            if ($('#radiusRange') != null) {
	                self.radiusChangeCallbackFn = radiusChangeCallback;
	                $('#radiusRange').on('change', onratechange);
	            }
	        }
            
	    };

	    radius.subscribe(function (newValue) {
	       // console.log(' radius changing to ' + newValue);
	        onRadiusChange(newValue);
	    });

	    onRadiusChange = function (sliderValue) {
	        // console.log('onRadiusChange: ' +  sliderValue.value)
	        searchRequest.currentRadius(sliderValue);
	        searchRequest.radius(sliderValue);
	        if (self.radiusChangeCallbackFn)
	            self.radiusChangeCallbackFn(searchRequest);
	    };

	    session.isRadiusSearchActive.subscribe(function (newVal) {
	        setSliderActive(newVal);
	        if (newVal == false) {
	            var request = {
	                Zip: "",
	                State: "",
	                County: "",
	                Apn: "",
	                SearchType: 'APN',
	                Fips: "",
	                latitude: "",
	                longitude: "",
	                landusecode: "",
	                radius: defaultRadius
	            };
	            setRequestParameters(request);
	        }
	    });

	    setSliderActive = function (newValue) {
	        //console.log('setSliderActive: ' + newValue);
	        var sldr = $('#radiusRangeSlider');
	        if (sliderCtrl != undefined && sliderCtrl.length > 0) {
	            if (newValue == true)
	                sliderCtrl.bootstrapSlider("enable");
	            else if (isRadiusSearchActive()) {
	                sliderCtrl.bootstrapSlider('disable');
	            }

	            if (isRadiusSearchActive() == false && newValue == false) {
	                sliderCtrl.bootstrapSlider('setValue', defaultRadius);
	            }
	        }
	        else if ($('#radiusRange') != null) {
	            sldr = $('#radiusRange');
	            if (newValue == true)
	                sldr.removeAttr('disabled');
	            else {
	                sldr.attr('disabled', 'disabled');
	            }

	            if (isRadiusSearchActive() == false && newValue == false) {
	                sldr.val(defaultRadius);
	            }
	        }
	        //console.log('setSliderActive: ' + sldr != null ? sldr.hasClass('slider-disabled'):'not found');
	    }

	    isRadiusSearchActive = function () {
	        return $('#radiusRange') != null && $('#radiusRange').is('disabled');
	    }

	    setRequestParameters = function (request) {
	        searchRequest.Fips(request.Fips);
	        searchRequest.latitude(request.latitude);
	        searchRequest.longitude(request.longitude);
	        searchRequest.radius(request.radius);
	        searchRequest.Zip(request.Zip);
	        searchRequest.State(request.State);
	        searchRequest.County(request.County);
	        searchRequest.Apn(request.Apn);
	        searchRequest.currentRadius(searchRequest.radius());
	    };

	    canActivate = function () {
	        ///console.log('VM - canActivate');
	        return true;
	    };

	    refreshRangeSlider = function (sliderCtrlName) {
	        console.log('RadiusSearch - refreshRangeSlider');
	        if (sliderCtrl != undefined && sliderCtrl.length > 0) {
	            init(sliderCtrlName);
	            sliderCtrl.bootstrapSlider('refresh');
	            searchRequest.currentRadius(sliderCtrl.val());

	            setSliderActive(session.isRadiusSearchActive());
	        }
	        else if ($('#radiusRange') != null)
	        {
	            $('#radiusRange').val(defaultRadius);
	            searchRequest.currentRadius(defaultRadius);
	            searchRequest.radius(defaultRadius)
	            setSliderActive(session.isRadiusSearchActive());
	        }
	    };


	    attached = function (view, parent) {
	        //console.log('VM - attached');
	    };


	    activate = function (data) {
	        //console.log('VM - Activated');
	        return true;
	    };

	    canDeactivate = function (isClose) {
	        //console.log('VM - canActivate');
	        return true;
	    };

	    deactivate = function (isClose) {
	        //console.log('VM - deactivate');
	        return true;
	    };


	    self.onRadiusChange = onRadiusChange;

        function compositionComplete() {
            session.validateInput();
        }

	    return {
	        canActivate: canActivate,
	        activate: activate,
	        canDeactivate: canDeactivate,
	        currentRadius: radius,  // used to set the control initial value only
	        searchRequest: searchRequest,
	        setRequestParameters: setRequestParameters,
	        init: init,
	        initSearchParams: initSearchParams,
	        sliderCtrl: sliderCtrl,
	        refreshRangeSlider: refreshRangeSlider,
	        setSliderActive: setSliderActive,
	        isRadiusSearchActive: isRadiusSearchActive,
            self: self,
            compositionComplete: compositionComplete,
	        defaultRadius: function () { return defaultRadius; }
	    }
	});
