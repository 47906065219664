define('viewmodels/userModule/iFrameLogin',['plugins/router', 'durandal/app', 'services/security', 'global/session', 'services/logger', 'jquery', 'knockout', 'loginModel', 'knockout.validation', 'plugins/http', 'jquerycapslock', 'config', 'shell'],
    function (router, app, security, session, logger, $, ko, loginModel, validation, https, jquerycapslock, config, shell) {
        var loginModel = new loginModel.login();

        var carouselView = ko.observable('AlamoCarousel.html');

        var sublinkData = ko.observable();
        var isApproved = ko.observable('');
        var userInfo;
        var userSecurityInfo;
        var isNewLLAEntry;

        var carouselFullPath = ko.computed(function () {
            return 'carousel/' + carouselView();
        }, this);

        // Internal properties and functions
        function ExternalLoginProviderViewModel(data) {
            var self = this;

            // Data
            self.name = ko.observable(data.name);

            // Operations
            self.login = function () {
                sessionStorage["state"] = data.state;
                sessionStorage["loginUrl"] = data.url;
                sessionStorage["externalLogin"] = true;
                // IE doesn't reliably persist sessionStorage when navigating to another URL. Move sessionStorage temporarily
                // to localStorage to work around this problem.
                session.archiveSessionStorageToLocalStorage();
                window.location = data.url;
            };
        }

        function reset() {

            //session.llaAccepted(false);
            session.clearAccessToken();
            session.clearACSFToken();
            vm.loginModel.userName("");
            vm.loginModel.password("");
            vm.loginModel.rememberMe(false);
            //vm.loginModel.userName.clearError();
            vm.setFocus(true);
            vm.validationErrors.showAllMessages(false);
            session.iframeLoginError('');
        }



        // Reveal the bindable properties and functions
        var vm = {
            canActivate:canActivate,
            activate: activate,
            canDeactivate: canDeactivate,
            title: 'iFrameLogin',
            session: session,
            setFocus: ko.observable(true),
            loginModel: loginModel,
            brands: [new Brand('Chicago Title', '1'), new Brand('Ticor Title', '2')],
            brand: ko.observable(),
            externalLoginProviders: ko.observableArray(),
            loaded: false,
            login: login,
            password_mouseout: password_mouseout,
            forgotpassword_mouseout: forgotpassword_mouseout,
            handleKeyUp: handleKeyUp,
            compositionComplete: BindCarousel,
            carouselFullPath: carouselFullPath,
            sublinkData: sublinkData,
            onBlurEmail: onBlurEmail,
            isApproved: isApproved,
            LLAAccepted: LLAAccepted,
            LLANotAccepted: LLANotAccepted,
            getPasswordChangeAlert: getPasswordChangeAlert,
            updateUserLastActivity: updateUserLastActivity
        };

        vm.validationErrors = ko.validation.group([vm.loginModel.userName, vm.loginModel.password]);
        vm.hasExternalLogin = ko.computed(function () {
            return vm.externalLoginProviders().length > 0;
        });

        return vm;

        function onBlurEmail() {
            session.iframeLoginError('');
        }

        function handleKeyUp(d, e) {
            if (e.keyCode == 13) {
                login();
            }
        }

        function Brand(name, id) {
            this.name = name;
            this.id = id;
        };

        function password_mouseout() {
            $('.loginPassword').blur();
        }

        function forgotpassword_mouseout() {
            $('.forgotPasswordlnk').blur();
        }

        function getLoginSubLinks() {

            console.log('getLoginSubLinks() called');
            // console.log('sublinkData length ' + sublinkData.length);
            https.get('durandal/GetSubLinks').done(function (data) {
                console.log(data);
                sublinkData(data);
                console.log(sublinkData().Links);
            });

        }

        function canActivate()
        {
            var token = localStorage["accessToken"];
            var tokenExpiresOn = localStorage["accessTokenExpiresOn"];
            if (token != undefined && token != '' && token != null) {
                if (tokenExpiresOn != undefined && tokenExpiresOn != '' && tokenExpiresOn != null) {
                    var now = (new Date()).getTime();
                    if (now < tokenExpiresOn)
                        return { redirect: '#bingAddressSearch' };
                }
            }
            return true;
        }

        function canDeactivate() {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, "", window.location.href);
            };
            getPasswordChangeAlert().done(function (result) {
                if (result == true) {
                    session.isLogoutVisible(false);
                    var title = 'Password Policy Alert';
                    var msg = 'Your password is more than 90 days old. For security reasons we strongly recommend to change the password immediately. Would you like to change the password now?';
                    return app.showMessage(msg, title, ['Yes', 'No'], true)
                        .then(function (option) {
                            if (option === 'Yes') {
                                router.navigate('security', { replace: true, trigger: true });

                            }
                            if (option === 'No') {
                                var url = config.updatePasswordHistoryUrl
                                https.post(url)
                                .then(function (data) {
                                    if (data.HasErrors == false) {
                                        router.navigate('bingAddressSearch', { replace: true, trigger: true });
                                    }
                                    else {
                                        alert.error("Internal Error. Please contact Administrator.");
                                    }
                                })
                                 .fail(function (data) {
                                     var error = JSON.parse(data.responseText)
                                     console.log(error);
                                     alert.error("Internal Error. Please contact Administrator.");
                                 })
                            }
                            session.isLogoutVisible(true);
                        });



                }
            });
            return true;
        }

        $(document).ready(function () {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, "", window.location.href);
            };
        });

        function delete_cookie(name) {
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }

        function getCookie(cookieName) {
            var cookieValue = document.cookie;
            var cookieStart = cookieValue.indexOf(" " + cookieName + "=");
            if (cookieStart == -1) {
                cookieStart = cookieValue.indexOf("=");
            }
            if (cookieStart == -1) {
                cookieValue = null;
            }
            else {
                cookieStart = cookieValue.indexOf("=", cookieStart) + 1;
                var cookieEnd = cookieValue.indexOf(";", cookieStart);
                if (cookieEnd == -1) {
                    cookieEnd = cookieValue.length;
                }
                cookieValue = unescape(cookieValue.substring(cookieStart, cookieEnd));
            }
            return cookieValue;
        }

        function activate() {

            getLoginSubLinks();
            var dfd = $.Deferred();

            session.isBusy(true);

            reset();
            //  carouselView('GCTACarousel.html');
            console.log('Current Brand is ' + $('.currentBrand').val());
            SetCarousel(parseInt($('.currentBrand').val()));

            var userId = getCookie('UserName');
            var password = getCookie('Password');

            if (userId != null && password != null) {
                vm.loginModel.userName(userId);
                vm.loginModel.password(password);
                delete_cookie('UserName');
                delete_cookie('Password');
                login();
            }
            else {
                router.navigate('#login');
            }
        }

        function deactivate() {
            vm.setFocus(false);
        }

        function login() {
            session.iframeLoginError('');
            vm.loginModel.validatePassword(true);
            vm.loginModel.validateEmail(true);
            if (vm.validationErrors().length > 0) {
                vm.validationErrors.showAllMessages();
                router.navigate('#login');
            }
			if (localStorage["logout"] == undefined || localStorage["logout"] == null)
			    localStorage["logout"] = true;

            session.isBusy(true);
            session.isAjaxPost(true);
            session.clearAccessToken();
            session.clearACSFToken();
            session.clearRecentSearchesFromLocalStorage();
            session.iframeLoginError('');
            security.login({
                grant_type: "password",
                username: vm.loginModel.userName(),
                password: vm.loginModel.password()
            }).done(function (data) {
                if (data.userName && data.access_token) {
					localStorage["logout"] = false;
                    session.setAccessToken(data.access_token, false);
                    userSecurityInfo = data;
                    session.iframeLoginError('');
                    checkforLLAAgreement(data.userName);
                    updateUserLastActivity();
                } else {
                    logger.log({
                        message: "Error logging in.",
                        data: "",
                        showToast: false,
                        type: "error"
                    });
                    session.iframeLoginError('Error logging in.');
                    router.navigate('#login');
                }
            }).fail(function (data) {
                var error = JSON.parse(data.responseText)
                console.log(error);
                session.iframeLoginError(error.error_description);
                vm.loginModel.password("");
                vm.setFocus(true);
                vm.validationErrors.showAllMessages(false);
                router.navigate('#login');
            }).always(function () {
                vm.loginModel.validatePassword(false);
                vm.loginModel.validateEmail(false);
                session.isBusy(false);
            }).failJSON(function (data) {
                if (data && data.error_description) {
                    logger.log({
                        message: data.error_description,
                        data: data.error_description,
                        showToast: false,
                        type: "error"
                    });
                } else {
                    logger.log({
                        message: "Error logging in.",
                        data: "",
                        showToast: false,
                        type: "error"
                    });
                }
                router.navigate('#login');
            })
        }

        function setToken(callbackfunc) {
            return https.get(config.getTokenUrl)
             .done(function (result) {
                 if (result) {
                     session.storeACSFToken(result);
                     $.ajaxSetup({
                         headers:
                             {
                                 "X-XSRF-Token": result.formToken + ":" + result.cookieToken
                             }
                     });
                     callbackfunc();
                 }
             }).fail(function () {
                 alerter.error('Error getting token information', 'User Information');
             });
        }

        function SetCarousel(currentBrandId) {
            switch (currentBrandId) {

                case 2:
                    carouselView('TicorCarousel.html');
                    app.title = 'Ticor Title';
                    break;
                case 3:
                    carouselView('FNTIC_Carousel.html');
                    app.title = 'Fidelity National Title';
                    break;
                case 7:
                    carouselView('FNTG_Carousel.html');
                    app.title = 'Fidelity National Title Group';
                    break;
                case 15:
                    carouselView('CLTIC_Carousel.html');
                    app.title = 'Commonwealth Land Title Insurance Company';
                    break;
                case 4:
                    carouselView('CTICCarousel.html');
                    app.title = 'Chicago Title Premier Services';
                    break;
                case 5:
                    carouselView('AlamoCarousel.html');
                    app.title = 'Alamo Title Company';
                    break;
                case 12:
                    carouselView('GCTACarousel.html');
                    app.title = 'Grand Canyon Title Agency';
                    break;

                case 16:
                    carouselView('LTICCarousel.html');
                    app.title = 'Lawyers Title';
                    break;
                case 19:
                    carouselView('STACarousel.html');
                    app.title = 'Security Title Agency';
                    break;

                case 21:
                    carouselView('AustinCarousel.html');
                    app.title = 'Austin Title';
                    break;
					
				 case 23:
                    carouselView('WesternCarousel.html');
                    app.title = 'Western Title';
                    break;
                case 24:
                    carouselView('DCTC.html');
                    app.title = 'All Access Dane County Title';
                    break;
                case 26:
                    carouselView('CTFNT_Carousel.html');
                    app.title = 'Chicago Title & Fidelity National Title Group';
                    break;

            }
        }

        function BindCarousel() {
            console.log('BindCarousel called.');
            session.validateInput();
            $('.carousel').carousel(
                {
                    interval: 6000
                }
                );
        }

        function updateUserLastActivity() {
            var url = $.getBasePath() + "api/User/UpdateUserLastActivity";

            return https.get(url,
            {
                userName: vm.loginModel.userName()
            });
        }

        function checkforLLAAgreement(name) {
            var url = $.getBasePath() + "api/Site/GetLiabilityAgreementByEmail";

            return https.get(url, {
                emailAddress: vm.loginModel.userName()
            })
            .done(function (result) {
                if (result.LiabilityAgreement) {
                    console.log("Checking for LLA agreement status");
                    userInfo = result.LiabilityAgreement;
                    isNewLLAEntry = false;

                    if (result.LiabilityAgreement.AgreeStatus === 1) {
                        session.llaAccepted(true);
                    }
                    else {
                        session.llaAccepted(false);
                    }

                    if (!session.llaAccepted()) {
                        showLLAAgreementModal();
                    }
                    else {
                        setToken(function () {
                            session.setUser(userSecurityInfo, vm.loginModel.rememberMe(), vm.brand(), vm.loginModel.password());
                        });
                    }
                    //})
                    //.fail(function () {
                    //    console.log("Couldn't retrieve LLA status");
                    //});
                }
                else {
                    isNewLLAEntry = true;
                    var url = $.getBasePath() + "api/User/GetUserInfoByEmail";
                    return https.get(url, {
                        email: vm.loginModel.userName()
                    })
                    .done(function (result) {
                        userInfo = result;
                        showLLAAgreementModal();
                    })
                    .fail(function () {
                        console.log("Couldn't retrieve User information");
                    });

                }
            })
            .fail(function () {
                console.log("Couldn't retrieve LLA for " + vm.loginModel.userName());
            });
        }

        function showLLAAgreementModal() {
            $('#mdLLAAgreementWithAgreeDisagree').modal({
                backdrop: 'static',
                keyboard: false
            });
            $('#mdLLAAgreementWithAgreeDisagree').modal('show');
        }

        function LLAAccepted() {
            console.log("Saving LLA accept status");
            updateLLA("Agree");
        }

        function LLANotAccepted() {
            console.log("Saving LLA not accept status");
            updateLLA("NotAgree");
        }

        function updateLLA(LLAStatus) {
            var url = $.getBasePath() + "api/Site/UpdateLLA";

            return https.post(url, {
                AgreeStatus: LLAStatus
            })
             .done(function (result) {
                 if (result.HasErrors != false) {
                     console.log("Something went wrong saving the LLA agreement status.");
                 }
                 else {
                     console.log("LLA status saved succesfully");
                     if (result.LiabilityAgreement.AgreeStatus === 1) {
                         session.llaAccepted(true);
                         setToken(function () {
                             session.setUser(userSecurityInfo, vm.loginModel.rememberMe(), vm.brand(), vm.loginModel.password());
                         });
                         router.navigate('#addressSearch', { replace: false, trigger: true });
                     }
                     else {
                         session.llaAccepted(false);
                         session.clearAccessToken();
                         session.clearACSFToken();
                         router.navigate('#login');
                     }
                 }
             })
             .fail(function () {
                 console.log("Couldn't retrieve LLA status");
             });
        }

        function saveLLAStatus(agreementStatus) {
            var url = $.getBasePath() + "api/Site/SaveLiabilityAgreement";
            var agreement;

            if (isNewLLAEntry) {
                agreement = generateNewLLAJson(agreementStatus);
            }
            else {
                agreement = generateLLAJSon(agreementStatus);
            }

            return https.post(url, {
                agreement: agreement
            })
             .done(function (result) {
                 if (result.HasErrors != false) {
                     console.log("Something went wrong saving the LLA agreement status.");
                 }
                 else {
                     console.log("LLA status saved succesfully");
                     if (agreementStatus) {
                         setToken(function () {
                             session.setUser(userSecurityInfo, vm.loginModel.rememberMe(), vm.brand(), vm.loginModel.password());
                         });
                     }
                 }
             })
             .fail(function () {
                 console.log("Couldn't retrieve LLA status");
             });
        }

        function generateLLAJSon(status) {
            if (status == true) {
                agreement = {
                    "Id": userInfo.Id,
                    "UserGuid": userInfo.UserGuid,
                    "Username": userInfo.Username,
                    "FirstName": userInfo.FirstName,
                    "LastName": userInfo.LastName,
                    "Company": userInfo.Company,
                    "AgreeStatus": "Agree",
                    "Updated": userInfo.Updated,
                    "IPAddress": userInfo.IPAddress,
                    "Notes": userInfo.Notes,
                    "Address": userInfo.Address,
                    "City": userInfo.City,
                    "State": userInfo.State,
                    "Zip": userInfo.Zip,
                    "Phone": userInfo.Phone,
                    "Version": userInfo.Version
                }
            }
            else {
                agreement = {
                    "Id": userInfo.Id,
                    "UserGuid": userInfo.UserGuid,
                    "Username": userInfo.Username,
                    "FirstName": userInfo.FirstName,
                    "LastName": userInfo.LastName,
                    "Company": userInfo.Company,
                    "AgreeStatus": "NotAgree",
                    "Updated": userInfo.Updated,
                    "IPAddress": userInfo.IPAddress,
                    "Notes": userInfo.Notes,
                    "Address": userInfo.Address,
                    "City": userInfo.City,
                    "State": userInfo.State,
                    "Zip": userInfo.Zip,
                    "Phone": userInfo.Phone,
                    "Version": userInfo.Version
                }
            }
            return agreement;
        }

        function generateNewLLAJson(status) {
            if (status == true) {
                agreement = {
                    "Id": 0,
                    "UserGuid": userInfo.UserId,
                    "Username": userInfo.Email,
                    "FirstName": userInfo.FirstName,
                    "LastName": userInfo.LastName,
                    "Company": userInfo.Company,
                    "AgreeStatus": "Agree",
                    "Updated": "",
                    "IPAddress": "",
                    "Notes": "",
                    "Address": userInfo.StreetAddress1,
                    "City": userInfo.City,
                    "State": userInfo.State,
                    "Zip": userInfo.Zip,
                    "Phone": userInfo.PhoneWork,
                    "Version": ""
                }
            }
            else {
                agreement = {
                    "Id": 0,
                    "UserGuid": userInfo.UserId,
                    "Username": userInfo.Email,
                    "FirstName": userInfo.FirstName,
                    "LastName": userInfo.LastName,
                    "Company": userInfo.Company,
                    "AgreeStatus": "NotAgree",
                    "Updated": "",
                    "IPAddress": "",
                    "Notes": "",
                    "Address": userInfo.StreetAddress1,
                    "City": userInfo.City,
                    "State": userInfo.State,
                    "Zip": userInfo.Zip,
                    "Phone": userInfo.PhoneWork,
                    "Version": ""
                }
            }
            return agreement;
        }

        function getPasswordChangeAlert() {
            var url = config.getPasswordChangeAlertUrl
            return https.get(url, {
                format: 'json'
            });
        }

    });
