define('viewmodels/propertyDetailsModule/propertyProfile',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'utlities', 'propertyProfileModel', 'propertyProfileService'],
 function (app, router, $, ko, validate, iCheck, config, validation, Q, https, session, alert, utlities, propertyProfileModel, propertyProfileService) {

     var propertyProfileModel = new propertyProfileModel();

     var vm = {
         activate: activate,
         canDeactivate: canDeactivate,
         goBack: goBack,
         title: 'Property Profile',
         propertyProfileModel: propertyProfileModel,
         compositionComplete: compositionComplete
     }
     return vm;

     function compositionComplete() {
         session.validateInput();
     }

     function activate(fips, apn, profileId) {
         //TODO: Initialize lookup data here.
         propertyProfileService.getPropertyProfileInfo(fips, apn, profileId).done(function (result) {
             propertyProfileModel = result;
         });
     }

     function canDeactivate() {
         //var title = 'Cancel Order?';
         //var msg = 'Navigate away and cancel the order? ';
         //return app.showMessage(msg, title, ['Yes', 'No '])
         //    .then(function (selectedOption) {
         //        if (selectedOption === 'Yes') {
         //            // Call some cancel function
         //        }
         //        return selectedOption;
         //    });
     };

     function goBack(complete) {
         router.navigateBack();
     }

     function Failed(error) {
         alert.error(error.statusText);
     }
 });
