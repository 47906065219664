define('models/order/chicagoTitleOrder',["knockout", 'utlities', 'alertNotification', "modelMetaData"],
    function (ko, utlities, alert, modelMetaData) {
        function OrderInformation() {
            var self = this;

            self.viewName = "orderModule/chicagoTitle/_orderInformation.html"
            self.title = "Order Information"
            self.ModelId = "OrderInformation"
            self.index = ko.observable(-1)

            self.escrowNumber = ko.observable("")
            .extend({
                maxLength: {
                    params: 100, message: 'Escrow Number must be 100 characters or less'
                }
            });
            self.orderNumber = ko.observable("")
                .extend({
                    maxLength: {
                        params: 100, message: 'Order Number must be 100 characters or less'
                    }
                });

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.escrowNumber(data.escrowNumber())
                    self.orderNumber(data.orderNumber())
                }
            };

            self.reset = function () {
                self.escrowNumber('')
                self.orderNumber('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Escrow Number", self.escrowNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Order Number", self.orderNumber()));
            }
        }

        function ReferralInformation() {
            var self = this;

            self.viewName = "orderModule/chicagoTitle/_referralInformation.html"
            self.title = "Referral Information"
            self.ModelId = "ReferralInformation"
            self.index = ko.observable(-1)

            self.referralType = ko.observable(false);
            self.referralTypeList = ko.observableArray([
                { text: "No Broker", value: "No Broker" },
                { text: "Escrow", value: "Escrow" },
                { text: "Lender's", value: "Lender's" }
            ]);

            self.listingOffice = ko.observable("")
            .extend({
                maxLength: {
                    params: 100, message: 'Listing Office must be 100 characters or less'
                }
            });
            self.listingOfficeAgentName = ko.observable("")
            .extend({
                maxLength: {
                    params: 100, message: 'Listing Office Agent Name must be 100 characters or less'
                }
            });
            self.sellingOffice = ko.observable("")
                .extend({
                    maxLength: {
                        params: 100, message: 'Selling Office must be 100 characters or less'
                    }
                });
            self.sellingOfficeAgentName = ko.observable("")
            .extend({
                maxLength: {
                    params: 100, message: 'Selling Office Agent Name must be 100 characters or less'
                }
            });

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.referralType(data.referralType())
                    self.listingOffice(data.listingOffice())
                    self.listingOfficeAgentName(data.listingOfficeAgentName())
                    self.sellingOffice(data.sellingOffice())
                    self.sellingOfficeAgentName(data.sellingOfficeAgentName())
                }
            };

            self.reset = function () {
                self.referralType('');
                self.listingOffice('')
                self.listingOfficeAgentName('')
                self.sellingOffice('')
                self.sellingOfficeAgentName('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Referral Type", self.referralType()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Office", self.listingOffice()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Agent's Name", self.listingOfficeAgentName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Office", self.sellingOffice()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Agent's Name", self.sellingOfficeAgentName()));
            }
        }

        function TypeOfPolicy() {
            var self = this;

            self.viewName = "orderModule/chicagoTitle/_typeOfPolicy.html"
            self.title = "Type Of Policy"
            self.ModelId = "TypeOfPolicy"
            self.index = ko.observable(-1)

            self.policyType = ko.observable(false);
            self.policyTypeList = ko.observableArray([
                                    { text: "C.L.T.A. Joint Protection", value: "C.L.T.A. Joint Protection" },
                                    { text: "C.L.T.A. Owner's", value: "C.L.T.A. Owner's" },
                                    { text: "A.L.T.A. Owner's", value: "A.L.T.A. Owner's" },
                                    { text: "A.L.T.A. Lender's", value: "A.L.T.A. Lender's" }
            ]);
            self.policyAmount = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Amount. (format: ####.##)'
                }
            });
            self.withEndorsementNumber = ko.observable("");
            self.policyEndorsement = ko.observable(false);
            self.policyEndorsementList = ko.observableArray([
                                            { text: "Lender's", value: "Lender's" },
                                            { text: "L.P. 9", value: "L.P. 9" },
                                            { text: "L.P. 10", value: "L.P. 10" },
                                            { text: "T.S.G.", value: "T.S.G." },
                                            { text: "Leasehold", value: "Leasehold" },
                                            { text: "Binder", value: "Binder" }
            ]);
            self.endorsementAmount = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Amount. (format: ####.##)'
                }
            });

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.policyType(data.policyType())
                    self.policyAmount(data.policyAmount())
                    self.withEndorsementNumber(data.withEndorsementNumber())
                    self.policyEndorsement(data.policyEndorsement())
                    self.endorsementAmount(data.endorsementAmount())
                }
            };

            self.reset = function () {
                self.policyType('')
                self.policyAmount("")
                self.withEndorsementNumber('')
                self.policyEndorsement('')
                self.endorsementAmount("")
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Policy Type", self.policyType()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Amount. ($)", "$ " + self.policyAmount()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("With Endorsement Number", self.withEndorsementNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Policy Endorsement", self.policyEndorsement()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Amount. ($)", "$ " + self.endorsementAmount()));
            }
        }

        function PropertyInformation() {
            var self = this;

            self.viewName = "orderModule/chicagoTitle/_propertyInformation.html"
            self.title = "Property Information"
            self.ModelId = "PropertyInformation"
            self.index = ko.observable(-1)

            self.propertyAddress = ko.observable("").extend({ required: { message: 'Property Address is required.' } })
                .extend({ maxLength: { params: 200, message: 'Property Address must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.propertyCity = ko.observable(undefined).extend({ required: { message: 'Property City is required.' } });

            self.propertyState = ko.observable(undefined).extend({ required: { message: 'Property State is required.' } });

            self.stateList = ko.observableArray();

            utlities.getStatesList().done(function (result) {
                self.stateList([]);
                self.stateList(result);
            });

            self.propertyZip = ko.observable("")
                .extend({ required: { message: 'Property Zip is required.' } })
                .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
                .extend({ number: true });

            self.typeOfProperty = ko.observable(false);
            self.typeOfPropertyList = ko.observableArray([
                                        { text: "Single Residence", value: "Single Residence" },
                                        { text: "Multiple Residence", value: "Multiple Residence" },
                                        { text: "Commercial", value: "Commercial" }
            ]);
            self.propertyDescription = ko.observable("").extend({ maxLength: { params: 200, message: 'Property Description must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.presentOwner = ko.observable("");
            self.newOwner = ko.observable("").extend({ maxLength: { params: 200, message: 'New Owner, title to be vested in must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.lenderInfo = ko.observable("").extend({ maxLength: { params: 200, message: 'Lender name, address, to attention of must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.specialInstructions = ko.observable("").extend({ maxLength: { params: 200, message: 'Special Instructions must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.update = function (data) {
                if (data) {
                    self.index(data.index())

                    self.propertyAddress(data.propertyAddress())
                    self.propertyCity(data.propertyCity())
                    self.propertyState(data.propertyState())
                    self.propertyZip(data.propertyZip())
                    self.typeOfProperty(data.typeOfProperty())
                    self.propertyDescription(data.propertyDescription())
                    self.presentOwner(data.presentOwner())
                    self.newOwner(data.newOwner())
                    self.lenderInfo(data.lenderInfo())
                    self.specialInstructions(data.specialInstructions())
                }
            };

            self.reset = function () {
                self.propertyAddress('')
                self.propertyCity(undefined)
                self.propertyState(undefined)
                self.propertyZip('')
                self.typeOfProperty('')
                self.propertyDescription('')
                self.presentOwner('')
                self.newOwner('')
                self.lenderInfo('')
                self.specialInstructions('')

                ko.validation.group(self).showAllMessages(false);
            }

            self.resetPropertyAddress = function ()
            {
                self.propertyAddress('')
                self.propertyCity(undefined)
                self.propertyState(undefined)
                self.propertyZip('')
                ko.validation.group(self).showAllMessages(false);
            }
            
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Zip", self.propertyZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property City", self.propertyCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property State", self.propertyState()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Address", self.propertyAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Type", self.typeOfProperty()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Description", self.propertyDescription()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Present Owner", self.presentOwner()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("New Owner, title to be vested in", self.newOwner()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Lender name, address, to attention of", self.lenderInfo()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Special Instructions", self.specialInstructions()));
            }
        }

        var vm = {
            OrderInformation: OrderInformation,
            ReferralInformation: ReferralInformation,
            TypeOfPolicy: TypeOfPolicy,
            PropertyInformation: PropertyInformation,
        }
        return vm;
    });
