define('models/order/viewOfflineOrders',["knockout", 'moment'],
    function (ko, moment) {

        function OfflineOrderSearch() {
            var self = this;
            self.orderRefNumber = ko.observable("");
            self.customerName = ko.observable("");
            self.fromDate = ko.observable();
            self.toDate = ko.observable();
            self.customerList = ko.observableArray([]);

            self.reset = function () {

                self.orderRefNumber("");
                self.customerName("");
                var date = new Date();
                self.fromDate(moment.utc(date.setDate(date.getDate() - 14)).format('MM/DD/YYYY'));
                self.toDate(moment.utc(new Date()).format('MM/DD/YYYY'));
            };

        }

        function OrderSearchResults() {
            var self = this;
            self.orderType = ko.observable("");
            self.orderNumber = ko.observable("");
            self.customerName = ko.observable("");
            self.orderPlaced = ko.observable("");
            self.fullName = ko.observable("");
            self.serviceName = ko.observable("");

            self.reset = function () {
                self.orderType("");
                self.orderNumber("");
                self.customerName("");
                self.orderPlaced("");
                self.fullName("");
                self.serviceName("");
            };
        }
        function OfflineOrderDetails() {
            var self = this;
            self.name = ko.observable("");
            self.orderNumber = ko.observable("");
            self.sections = ko.observableArray([]);
            self.reset = function () {
                self.name("");
                self.orderNumber("");
                self.sections([]);
            };
        }
        var vm = {
            OfflineOrderSearch: OfflineOrderSearch,
            OrderSearchResults: OrderSearchResults,
            OfflineOrderDetails: OfflineOrderDetails
        };

        return vm;
    });
