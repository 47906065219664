define('viewmodels/externalSiteRedirect/productRedirect',["knockout", 'plugins/http', 'global/session', 'config', 'utlities', 'plugins/router', 'durandal/app'],
    function (ko, https, session, config, utlities, router, app) {
        var vm = {
            session: session,
            canActivate: function () {
                //console.log();

                if (session.selectedProduct() === "eFarm2") {
                    if (session.slsEnabled()) {
                        getTitleProURL().done(function (data) {
                            if (session.slsEnabled()) {
                                if (data && !data.HasErrors && (data.LoginURL !== undefined || data.LoginURL !== null) && data.LoginURL.length > 0) {
                                    session.CheckPopUpBlock(data.LoginURL); { }
                                }
                                else {
                                    app.showMessage('Required URL is not configured. Please contact Administrator.', 'URL not available', ['OK']);
                                }
                            } else {
                                if (data && !data.HasErrors && (data.UrlAndKey !== undefined || data.UrlAndKey !== null) && data.UrlAndKey.length > 0) {
                                    session.CheckPopUpBlock(data.UrlAndKey);
                                } else {
                                    app.showMessage('Required URL is not configured. Please contact Administrator.', 'URL not available', ['OK']);
                                }
                            }
                        });
                        return false;
                    //} else {
                    //    getProductURL().done(function (redirectUrl) {
                    //        if (redirectUrl && !redirectUrl.HasErrors && (redirectUrl.UrlAndKey !== undefined || redirectUrl.UrlAndKey !== null) && redirectUrl.UrlAndKey.length > 0) {
                    //            session.eFarmUrl(redirectUrl.UrlAndKey);
                    //            session.CheckPopUpBlock(redirectUrl.UrlAndKey, '_blank', null);
                    //        }
                    //        else
                    //            app.showMessage('Required URL is not configured. Please contact Administrator.', 'URL not available', ['OK']);
                    //        //router.navigateBack();
                    //    });
                    //    return false;
                    }
                    


                } else 
                    getProductURL().done(function (redirectUrl) {
                        if (redirectUrl && !redirectUrl.HasErrors && (redirectUrl.UrlAndKey !== undefined || redirectUrl.UrlAndKey !== null) && redirectUrl.UrlAndKey.length > 0) {
                            switch (session.selectedProduct()) {
                                case 'eFarm':
                                    session.eFarmUrl(redirectUrl.UrlAndKey);
                                    break;
                            case 'foreclosureSearch':
                                session.foreclosureSearchUrl(redirectUrl.UrlAndKey);
                                break;
                            case 'leadLocator':
                                session.leadLocatorUrl(redirectUrl.UrlAndKey);
                                break;
                            }
                            session.CheckPopUpBlock(redirectUrl.UrlAndKey, '_blank', null);
                        }
                        else
                            app.showMessage('Required URL is not configured. Please contact Administrator.', 'URL not available', ['OK']);
                        //router.navigateBack();
                    });
                    return false;
            }
        }

        function getProductURL() {
            var serviceID = 0;
            switch (session.selectedProduct()) {
                case 'eFarm':
                    serviceID = 3;
                    break;
                case 'foreclosureSearch':
                    serviceID = 4;
                    break;
                case 'leadLocator':
                    serviceID = 5;
                    break;
            }
            var url = config.GetUrlAndKeyForFnds
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                brandId: session.brandId,
                serviceId: serviceID,
                format: 'json'
            });

           
        }

        function getTitleProURL() {
            console.log('getTitleProURL');
            if (session.slsEnabled()) {
                return https.get(config.getAccessTitleProAccountSlsUrl, {
                    operationId: session.defaultOpertionId(),
                    brandId: session.brandId,
                    format: 'json'
                });
            } else {
                return https.get(config.getAccessTitleProAccount, {
                    operationId: session.defaultOpertionId(),
                    brandId: session.brandId,
                    format: 'json'
                });
            }

        }

        return vm;
    });
