define('viewmodels/customPage/displayCustomPage',["knockout", 'plugins/http', 'global/session', 'config', 'utlities', 'customPageModel', 'nameList'],
    function (ko, https, session, config, utlities, model, nameList) {
        var customPageModel = new model.CustomPageCulture();
        var customPageArray = new model.DetailList();
        var custPageCategory = ko.observableArray([]);
        var customPageLink = ko.observableArray([]);
        var custpageId = ko.observable("");
        var isPanelVisible = ko.observable(false);
        customPageModel.customPageCategoryId.subscribe(function (categoryId) {
            isPanelVisible(true);
            customPageLink([]);
            var customPageId = categoryId;
            getTableData(customPageId, 1, customPageModel.categoryDisplayType()).done(function (categoryResult) {
                if (categoryResult.length > 0) {
                    customPageLink(categoryResult);
                    
                }
            })
        });
        var vm = {
            activate: activate,
            customPageModel: customPageModel,
            custPageCategory: custPageCategory,
            customPageLink: customPageLink,
            custpageId: custpageId,
            loadToKOArray: loadToKOArray,
            customPageArray: customPageArray,
            isPanelVisible: isPanelVisible,
            getDocById: getDocById,
            compositionComplete: compositionComplete                
        }
        return vm;

        function compositionComplete() {
            session.validateInput();
        }

        function getDocById(item, event) {
            useXHR(item);
            return;

            console.log(item.Url, item.Name);
            var url = config.getDocByIdUrl;
            https.get(url, {
                doc: item.Url,
                docName: item.Name
            }).done(function(data) {
                var blob = new Blob([data], { type: "application/octetstream" });

                //Check the Browser type and download the File.
                var isIE = false || !!document.documentMode;
                if (isIE) {
                    window.navigator.msSaveBlob(blob, item.Url);
                } else {
                    var url = window.URL || window.webkitURL;
                    link = url.createObjectURL(blob);
                    var a = $("<a />");
                    a.attr("download", item.Url);
                    a.attr("href", link);
                    $("body").append(a);
                    a[0].click();
                    $("body").remove(a);
                }
            });
        }

        function useXHR(item) {
            //var url = config.getDocByIdUrl + "?doc=" + item.Url + "&docName=" + item.Name;
            //https.getBlob(url);
            $("#mdLoading").modal('show');

            var xhr = new XMLHttpRequest();
            var url = config.getDocByIdUrl +"?doc="+ item.Url + "&docName=" + item.Name;

            xhr.open('GET', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + (sessionStorage["accessToken"] || localStorage["accessToken"]));
            xhr.responseType = 'blob';
            xhr.onload = function(e) {
                 if (this.status === 200) {
                     let blob = this.response;
                     let a = document.createElement('a');
                     a.style.display = 'none';
                     let url = window.URL.createObjectURL(blob);
                     a.href = url;
                     a.download = item.Url;
                     document.body.appendChild(a);
                     a.click();
                     window.URL.revokeObjectURL(url);
                     $("#mdLoading").modal('hide');
                 }
            }
            xhr.send();
         
            //$("#mdLoading").modal('hide');
        }

        function activate(custompageid) {
            if (custompageid != -1) {
                customPageLink([]);
                getCustomPageCulture(custompageid, 1).done(function(result) {
                    if (result.length > 0) {
                        customPageModel.clientObject(result[0]);
                        custpageId(custompageid)
                        if (customPageModel.categoryDisplayType() == "Repeater") {
                            isPanelVisible(false);
                            getTableData(custompageid, 1, customPageModel.categoryDisplayType()).done(
                                function(categoryResult) {
                                    if (categoryResult.length > 0) {
                                        customPageLink(categoryResult);
                                    }
                                })

                        } else {
                            isPanelVisible(true);
                            getCustomPageCategory(custompageid, 1).done(function(categoryResult) {
                                if (categoryResult.length > 0) {
                                    custPageCategory(categoryResult);

                                }
                            });
                        }
                    }

                });
            }
          
        }

        //load to local array.
        function loadToKOArray(webAPICollectionObject) {
            var mappedLocalKOArray = [];
            for (var i = 0; i < webAPICollectionObject.length; i++) {
                var lst = new nameList();
                lst.Name(webAPICollectionObject[i].Name);
                getCustomPageLinkCulture(webAPICollectionObject[i].CustomPageCategoryId, 1).done(function (result) {
                    if (result.length > 0) {
                        for (var j = 0; j < result.length; j++) {
                            var item = new model.CustomPageCulture();
                            item.cultureId(result[j].CultureId);
                            item.customPageLinkId(result[j].CustomPageLinkId);
                            item.customPageCategoryId(result[j].CustomPageCategoryId);
                            item.embedded(result[j].Embedded);
                            item.isDocument(result[j].IsDocument);
                            item.url(result[j].Url);
                            item.cultureName(result[j].CultureName);
                            item.cultureShortName(result[j].CultureShortName);
                            item.name(result[j].Name);
                            item.description(result[j].Description);
                            lst.List.push(item);
                            mappedLocalKOArray.push(lst);
                        }
                    }
                      
                })
            }

            return mappedLocalKOArray;
        }

        function getCustomPageCulture(customPageId, currentCultureId) {
            var url = config.getCustomPageCultureUrl;
            return https.get(url, {
                customPageId: customPageId,
                currentCultureId: currentCultureId,
                format: 'json'
            });
        }
        function getCustomPageCategory(customPageId, currentCultureId) {
            var url = config.getCustomPageCategoryUrl;
            return https.get(url, {
                customPageId: customPageId,
                currentCultureId: currentCultureId,
                format: 'json'
            });
        }
        function getCustomPageLinkCulture(categoryId, currentCultureId) {
            var url = config.getCustomPageLinkCultureUrl;
            return https.get(url, {
                categoryId: categoryId,
                currentCultureId: currentCultureId,
                format: 'json'
            });
        }
        function getTableData(customPageId, currentCultureId, catType) {
            var url = config.getTableDataUrl;
            return https.get(url, {
                customPageId: customPageId,
                currentCultureId: currentCultureId,
                catType:catType,
                format: 'json'
            });
        }
        
    });
