define('models/administration/pendingApprovalModel',["knockout"],
    function (ko) {
        console.log('Pending Approval Model called...');

        function PendingApprovalModel() {

            var self = this;

            self.userId = ko.observable("");
            self.firstName = ko.observable("");
            self.lastName = ko.observable("");
            self.email = ko.observable("");
            self.company = ko.observable("");

            self.Reset = function () {
                self.userId("");
                self.firstName("");
                self.lastName("");
                self.email("");
                self.company("");
            }

            self.errors = ko.validation.group(self);
        }
        var vm = {
            PendingApprovalModel: PendingApprovalModel,
        }
        return vm;
    });
