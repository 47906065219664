define('viewmodels/valueCheck/valueCheck',["knockout", 'plugins/http', 'global/session', 'config', 'utlities', 'alertNotification', 'plugins/router'],
    function (ko, https, session, config, utlities,alert,router) {
        var vm = {
            activate: activate,
            session : session,
        }
        return vm;

        function activate() {
            var operationId = session.defaultOpertionId();
            if (session.vcheckuid) {
                var redirectUrl = 'http://www.valuecheckonline.com/propertysearch/propertysearch.aspx?siteid=' + session.vcheckuid + '&ref=' + session.userId
                session.CheckPopUpBlock(redirectUrl);
                //window.open(redirectUrl, '_blank');
                router.navigateBack();
            }
            else {
                alert.error("You do not have access to this page, please contact administrator if you think this is an error. ", "Access denied");
                router.navigateBack();
            }
        }

       
    });
