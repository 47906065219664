define('viewmodels/administration/viewproductpurchases',['plugins/router', 'knockout', 'durandal/app', 'plugins/http', 'global/session', 'config', 'moment', 'alertNotification', 'koPaging', 'paymentPurchases'],
    function (router, ko, app, https, session, config, moment, alerter, koPaging, models) {

        var request = new models.PaymentPurchaseRequest();
        var searchResults = ko.observableArray([]);
        var transactionExtract = new models.TransactionExtract();
        var orderedProduct = new models.OrderedProduct();
        var vm = {
            canActivate: canActivate,
            activate: activate,
            canDeactivate: canDeactivate,
            request: request,
            searchResults: searchResults,
            searchOrders: searchOrders,
            clearSearch: clearSearch,
            transactionExtract: transactionExtract,
            getStoredOrder: getStoredOrder,
            viewDetails_click: viewDetails_click
        };

        return vm;

        function canActivate() {
            // $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('hide');

            if (!session.userIsInRole("TransactionViewer"))
                return false;

            if (!session.isLoggedIn()) {
                router.navigate('#login', { replace: false, trigger: true });
                return false;
            }
            return true;
        }

        function activate() {
            searchResults([]);
            clearSearch();
            searchResults.extend({
                paged: {
                    pageNumber: 1,
                    pageSize: 10,
                    pageGenerator: 'default'
                }
            });
            //request.fromDate('05/06/2020');
            //request.toDate('12/06/2020');
        }

        function canDeactivate() {
            searchResults([]);
            //clearSearch();
            //session.clearOrderForPropertyAddress();
            return true;
        }

        function searchOrders() {
            
            if (!vm.request.isValid()) {
                vm.request.errors.showAllMessages();
                return;
            }

            if (isValidateDateRange(request.fromDate(), request.toDate())) {
                alerter.warning("From date cannot be later than To date");
                return;
            }

            searchResults([]);
            var url = config.getProductPurchasesUrl;

            var requestParam = {
                "FirstName": request.firstName(),
                "LastName": request.lastName(),
                "UserName": request.userName(),
                "PurchaseDateFrom": request.fromDate(),
                "PurchaseDateTo": request.toDate()
                
            }

            return https.post(url, requestParam)
                .then(function (data) {
                    console.log(data);
                    if (data && data.List && data.List.length > 0) {
                        searchResults([]);
                        var converted = loadResultToKOArray(data.List);
                        searchResults(converted);
                        console.log(data);
                    }
                })
                .fail(function (data) {
                    if (data != null && data != undefined) {
                        if (data.responseJSON != null && data.responseJSON.ExceptionMessage != null && data.responseJSON != undefined && data.responseJSON.ExceptionMessage != null) {
                            alerter.warning(data.responseJSON.ExceptionMessage);
                        }
                    }
                    console.log(data);
                });
        }

        function loadResultToKOArray(data) {
            var results = [];
            var space = String.fromCharCode(160);
            for (var i = 0; i < data.length; i++) {

                result = new models.PaymentPurchaseResult();
                result.itemNumber(data[i].ItemNumber);
                result.cartValue(data[i].CartValueFormatted);
                result.tranDate(moment(data[i].PostDateTime).format("MM/DD/YYYY, h:mm:ss a"));
                result.isPosted(data[i].IsPosted);
                result.firstName(data[i].OrderyByFirstName);
                result.lastName(data[i].OrderyByLastName);
                result.userName(data[i].OrderyByUserName);
                result.orderGuid(data[i].OrderGuid);
                results.push(result);

            }
            return results;
        }

        function isValidateDateRange(fromDate, toDate) {
            var fromDate = moment(fromDate, "MM-DD-YYYY");
            var toDate = moment(toDate, "MM-DD-YYYY");
            var result = (fromDate > toDate);
            return result;

        };

        function clearSearch() {
            request.reset();
        }

        function viewDetails_click(result, event) {
            console.log(result);
            getStoredOrder(result.orderGuid());
        }

        function getStoredOrder(orderGuid) {
            var url = config.getFTNITransactionExtractUrl;
            https.get(url, {
                orderGuid: orderGuid,
                format: 'json'
            }).then(function (data) {
                console.log(data);

                if (data) {

                    if (data.HasErrors) {

                    }
                    else {
                      //  transactionExtract.reset();

                        transactionExtract.fullName(data.FullName);
                        transactionExtract.userName(data.UserName);
                        transactionExtract.tranDate(moment(data.TranDateTime).format("MM/DD/YYYY, h:mm:ss a"));
                        transactionExtract.itemNumber(data.ItemNumber);
                        transactionExtract.cartValue(data.CartValueFormatted);
                        transactionExtract.propertyAddress(data.PropertyAddress);
                        transactionExtract.APN(data.APN);
                        transactionExtract.tranStatus(data.Status);
                        transactionExtract.orderNumber(data.OrderNumber);
                        transactionExtract.orderTax(data.OrderTaxFormatted);
                        transactionExtract.stateTax(data.StateTaxFormatted);
                        transactionExtract.isPostedToFTNI(data.IsPostedToFTNI);
                        loadProducts(data.Products);

                        $('#mdOrderDetail').modal('show');
                    }
                }

            });
        }

        function loadProducts(products) {
            transactionExtract.products([]);
            var product = null;
            var prodArrary = [];
            for (var i = 0; i < products.length; i++) {
                orderedProduct = new models.OrderedProduct();
                product = products[i];
                orderedProduct.slNo(i + 1);
                orderedProduct.name(product.Name);
                orderedProduct.useCredit(product.UseCredit);
                orderedProduct.price(product.PriceFormatted);
                orderedProduct.credits(product.Credits);
                orderedProduct.isAvailable(product.IsAvailable);
                prodArrary.push(orderedProduct);
            }
            transactionExtract.products(prodArrary);
            console.log(transactionExtract.products());
        }
    });
