//TODO: Inject dependencies

define('viewmodels/orderModule/offlineOrder',['plugins/router', 'durandal/app', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'customerInformationModel', 'offLineTitleInformationModels', 'offlineFarmOrderModels', 'offlinePropertyProfileModels', 'commonOrderModel', 'commonDialogModel', 'utlities', 'alertNotification'],
    function (router, app, $, ko, validate, iCheck, config, validation, Q, https, session, CustomerInformation, offLineTitleInformationModels, offlineFarmOrderModels, offlinePropertyProfileModels, commonOrderModel, commonDialog, utlities, alert) {
        // Internal properties and functions

        var customerInformationModel = new CustomerInformation();
        var submitOrderModel = new commonOrderModel.SubmitOrder();

        var typeofInformationRequestedModel = new offLineTitleInformationModels.TypeofInformationRequested();
        var searchInformationModel = new offLineTitleInformationModels.SearchInformation();


        var searchCriteriaModel = new offlineFarmOrderModels.SearchCriteria();
        var secondarySearchModel = new offlineFarmOrderModels.SecondarySearch();
        var reportTypeModel = new offlineFarmOrderModels.ReportType();
        reportTypeModel.brandId = session.brandId;
        var labelsModel = new offlineFarmOrderModels.Labels();
        labelsModel.brandId = session.brandId;



        var propertyInformationModel = new offlinePropertyProfileModels.PropertyInformation();
        propertyInformationModel.brandId = session.brandId;

        var commonDialogModel = new commonDialog("orderSubmitDialog");
        var orderSubmitted = ko.observable(false);

        var currentView = ko.observable('');
        var currentOrderModels = ko.observableArray([]);
        var currentViewIndex = ko.observable(-1);
        var currentOrderModel = ko.observable();
        var selectedOrderType = ko.observable(0);
        var selectedOrderTypeName = ko.observable('');
        var orderTitle = ko.observable('');
        var userProfile = ko.observableArray();
        var stateList = ko.observableArray();
        var countyList = ko.observableArray();
        var rptCtrlVisible = ko.observable(false);
        var labelsCtrlVisible = ko.observable(false);
        var propertInfoVisible = ko.observable(false);

        var propertyInfoPopulateFromSession = ko.observable(false);
        var recentSearches = ko.observable(undefined);
        var recentSearchSelectedAPN = ko.observable("");
        var propertyInfoPopulateFromRecentSearch = ko.observable(false);

        currentOrderModel.subscribe(function (model) {
            vm.validationErrors = ko.validation.group([model]).extend({
                notify: 'always'
            });
            currentView(model.viewName);
            selectedOrderTypeName(model.title);
            session.handleFocusOnTabChange(model.ModelId, model.viewName);
            //            setupLocalObject(model.ModelId);
        });

        currentViewIndex.subscribe(function (index) {
            currentOrderModel(currentOrderModels()[index]);
        });

        propertyInformationModel.zipCodes.subscribe(function (zip) {
            if (zip != undefined && zip != '') {
                countyList([]);
                utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        var selectedCounty = data.CountyName;
                        if (data.StateAbbr != null || data.City != null) {
                            utlities.getCountyList(data.StateAbbr).done(function (result) {
                                countyList(result);
                                propertyInformationModel.county(selectedCounty);
                            });
                            propertyInformationModel.city(data.City);
                            propertyInformationModel.state(data.StateAbbr);
                            if (propertyInfoPopulateFromSession() === true && session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null && session.orderForPropertyAddress.city() != undefined && session.orderForPropertyAddress.city() != null && session.orderForPropertyAddress.city() != "") {
                                propertyInformationModel.city(session.orderForPropertyAddress.city());
                                propertyInfoPopulateFromSession(false);
                            }
                            else if (propertyInfoPopulateFromRecentSearch() === true) {
                                propertyInfoPopulateFromRecentSearch(false);
                            }
                            else {

                            }
                        }
                        else {
                            alert.error("Invalid Zip.")
                            propertyInformationModel.zipCodes('');
                            propertyInformationModel.city('');
                            propertyInformationModel.state('');
                            propertyInformationModel.county('');
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })
            }
        })


        searchCriteriaModel.zipCodes.subscribe(function (zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            searchCriteriaModel.city(data.City);
                            searchCriteriaModel.state(data.StateAbbr);
                            if (propertyInfoPopulateFromSession() === true && session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null && session.orderForPropertyAddress.city() != undefined && session.orderForPropertyAddress.city() != null && session.orderForPropertyAddress.city() != "") {
                                searchCriteriaModel.city(session.orderForPropertyAddress.city());
                                propertyInfoPopulateFromSession(false);
                            }
                            else if (propertyInfoPopulateFromRecentSearch() === true) {
                                propertyInfoPopulateFromRecentSearch(false);
                            }
                            else {

                            }
                        }
                        else {
                            alert.error("Invalid Zip.")
                            searchCriteriaModel.zipCodes('');
                            searchCriteriaModel.city('');
                            searchCriteriaModel.state('');
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })
            }
        })
        searchInformationModel.zipCodes.subscribe(function (zip) {
            if (zip != undefined && zip != '') {
                countyList([]);
                utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            var selectedCounty = data.CountyName;
                            utlities.getCountyList(data.StateAbbr).done(function (result) {
                                countyList(result);
                                searchInformationModel.county(selectedCounty);
                            });
                            searchInformationModel.city(data.City);
                            searchInformationModel.state(data.StateAbbr);
                            if (propertyInfoPopulateFromSession() === true && session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null && session.orderForPropertyAddress.city() != undefined && session.orderForPropertyAddress.city() != null && session.orderForPropertyAddress.city() != "") {
                                searchInformationModel.city(session.orderForPropertyAddress.city());
                                propertyInfoPopulateFromSession(false);
                            }
                            else if (propertyInfoPopulateFromRecentSearch() === true) {
                                propertyInfoPopulateFromRecentSearch(false);
                            }
                            else {

                            }
                        }
                        else {
                            alert.error("Invalid Zip.")
                            searchInformationModel.zipCodes('');
                            searchInformationModel.city('');
                            searchInformationModel.state('');
                            searchInformationModel.county('');
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })
            }
        })
        // Reveal the bindable properties and functions
        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            goBack: goBack,
            title: 'Off-line Farm Order',

            typeofInformationRequestedModel: typeofInformationRequestedModel,
            searchInformationModel: searchInformationModel,
            searchCriteriaModel: searchCriteriaModel,
            secondarySearchModel: secondarySearchModel,
            reportTypeModel: reportTypeModel,
            labelsModel: labelsModel,
            propertyInformationModel: propertyInformationModel,
            customerInformationModel: customerInformationModel,
            orderTitle: orderTitle,

            currentView: currentView,
            currentOrderModels: currentOrderModels,
            currentViewIndex: currentViewIndex,
            currentOrderModel: currentOrderModel,
            selectedOrderTypeName: selectedOrderTypeName,

            moveNext: moveNext,
            movePrevious: movePrevious,
            submitOrder: submitOrder,
            navigateButtonClick: navigateButtonClick,
            userProfile: userProfile,
            commonDialogModel: commonDialogModel,

            showOrderSummary: showOrderSummary,
            stateList: stateList,
            countyList: countyList,
            getUSPhoneFormat: utlities.getUSPhoneFormat,
            getUSPhoneFormatWithExtn: utlities.getUSPhoneFormatWithExtn,
            rptCtrlVisible: rptCtrlVisible,
            labelsCtrlVisible: labelsCtrlVisible,
            propertInfoVisible: propertInfoVisible,
            session: session,
            compositionComplete: compositionComplete,
            recentSearches: recentSearches,
            recentResult_click: recentResult_click,
            recentSearchSelectedAPN: recentSearchSelectedAPN,
            clearSelectedProperty: clearSelectedProperty,
        };

        return vm;

        function activate(orderType) {
            recentSearches(session.getRecentSearchesFromLocalStorage());
            InitView(orderType);
            orderSubmitted(false);
            currentViewIndex(0);
            commonDialogModel.IsOrderDialog(false);
            session.isBusy(false);
            return getCustomerInfo(session.userId, session.defaultOpertionId());
        }

        function InitView(orderType) {
            selectedOrderType(parseInt(orderType));
            setupOrder(selectedOrderType());



            brandfieldVisibility(session.brandId);

            return utlities.getStatesList().done(function (result) {
                stateList([]);
                stateList(result);

            });
        }


        function canDeactivate() {
            if (!session.hasLoggedOut() && !orderSubmitted()) {
                var title = 'Cancel Order?';
                var msg = 'Navigate away and cancel the order? ';
                session.isLogoutVisible(false);
                return app.showMessage(msg, title, ['Yes', 'No '])
                    .then(function (selectedOption) {
                        if (selectedOption === 'Yes') {
                            // Call some cancel function
                            session.clearOrderForPropertyAddress();
                            recentSearchSelectedAPN('');
                            switch (selectedOrderType()) {
                                case 49:
                                    searchCriteriaModel.reset();
                                    break;
                                case 50:
                                    searchInformationModel.reset();
                                    break;
                                case 51:
                                    propertyInformationModel.reset();
                                    break;
                                default:
                            }
                        }
                        session.isLogoutVisible(true);
                        return selectedOption;
                    });
            }
            else {
                session.clearOrderForPropertyAddress();
                recentSearchSelectedAPN('');
                switch (selectedOrderType()) {
                    case 49:
                        searchCriteriaModel.reset();
                        break;
                    case 50:
                        searchInformationModel.reset();
                        break;
                    case 51:
                        propertyInformationModel.reset();
                        break;
                    default:
                }
                return true;
            }
        };


        function recentResult_click(result) {
            recentSearchSelectedAPN(result.APN);
            propertyInfoPopulateFromSession(false);

            switch (selectedOrderType()) {
                case 49:
                    searchCriteriaModel.propertyAddress(result.Address);
                    searchCriteriaModel.city(result.City);
                    searchCriteriaModel.parcelNumbers(result.APN);
                    if (searchCriteriaModel.zipCodes() != result.ZIP.slice(0, 5)) {
                        propertyInfoPopulateFromRecentSearch(true);
                        searchCriteriaModel.zipCodes(result.ZIP.slice(0, 5));
                    }
                    else
                        propertyInfoPopulateFromRecentSearch(false);
                    //searchCriteriaModel.reset();
                    break;
                case 50:
                    searchInformationModel.street(result.Address);
                    searchInformationModel.city(result.City);
                    searchInformationModel.parcelNumber(result.APN);
                    if (searchInformationModel.zipCodes() != result.ZIP.slice(0, 5)) {
                        propertyInfoPopulateFromRecentSearch(true);
                        searchInformationModel.zipCodes(result.ZIP.slice(0, 5));
                    }
                    else
                        propertyInfoPopulateFromRecentSearch(false);
                    //searchInformationModel.reset();
                    break;
                case 51:
                    propertyInformationModel.street(result.Address);
                    propertyInformationModel.city(result.City);
                    propertyInformationModel.parcelNumber(result.APN);
                    if (propertyInformationModel.zipCodes() != result.ZIP.slice(0, 5)) {
                        propertyInfoPopulateFromRecentSearch(true);
                        propertyInformationModel.zipCodes(result.ZIP.slice(0, 5));
                    }
                    else
                        propertyInfoPopulateFromRecentSearch(false);
                    //propertyInformationModel.reset();
                    break;
                default:
            }
        }
        function clearSelectedProperty() {
            recentSearchSelectedAPN('');
            switch (selectedOrderType()) {
                case 49:
                    searchCriteriaModel.resetPropertyAddress();
                    break;
                case 50:
                    searchInformationModel.resetPropertyAddress();
                    break;
                case 51:
                    propertyInformationModel.resetPropertyAddress();
                    break;
                default:
            }
        }

        function moveNext() {
            // commented for development, this need to be removed.
            vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
            if (vm.validationErrors().length > 0) {
                vm.validationErrors.showAllMessages();
                session.isBusy(false);
                return;
            }
            currentViewIndex(currentViewIndex() + 1);
            if (currentViewIndex() == currentOrderModels().length - 1)
                buildPreviewModel();
        }

        function movePrevious() {
            currentViewIndex(currentViewIndex() - 1);
        }

        function navigateButtonClick(item, event) {
            if (item.index > currentViewIndex()) {

                for (var i = 0; i < item.index; i++) {
                    // commented for development, this need to be removed.
                    //if (!currentOrderModels()[i].isValid())
                    //    return;
                    vm.validationErrors = ko.validation.group(currentOrderModels()[i]);
                    if (vm.validationErrors().length > 0) {
                        vm.validationErrors.showAllMessages();
                        return;
                    }
                }
            }
            currentViewIndex(item.index);
            if (currentViewIndex() == currentOrderModels().length - 1)
                buildPreviewModel();
        }

        function setupOrder(orderType) {
            currentOrderModels([]);

            switch (orderType) {
                case 49:
                    orderTitle('Farm Request');
                    currentOrderModels.push(customerInformationModel);

                    currentOrderModels.push(searchCriteriaModel);
                    currentOrderModels.push(secondarySearchModel);
                    currentOrderModels.push(reportTypeModel);
                    currentOrderModels.push(labelsModel);
                    break;
                case 50:
                    orderTitle('Title Info Request');
                    currentOrderModels.push(customerInformationModel);

                    currentOrderModels.push(typeofInformationRequestedModel);
                    currentOrderModels.push(searchInformationModel);
                    break;
                case 51:
                    orderTitle('Property Profile Request');
                    currentOrderModels.push(customerInformationModel);

                    currentOrderModels.push(propertyInformationModel);
                    break;
                default:
            }

            currentOrderModels.push(submitOrderModel);
            resetModels();
        }

        function resetModels() {

            for (var i = 0; i < currentOrderModels().length; i++) {
                currentOrderModels()[i].index = i;
                currentOrderModels()[i].reset();
            }
        }

        function showOrderSummary() {
            console.log(currentOrderModels().length);
            //$('#mdOrderOnePage').modal('show');
        }

        function compositionComplete() {
                session.handleFocusOnTabChange(customerInformationModel.ModelId, customerInformationModel.viewName);
            if (session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null) {
                propertyInfoPopulateFromSession(true);
                switch (selectedOrderType()) {
                    case 49:
                        searchCriteriaModel.propertyAddress(session.orderForPropertyAddress.address());
                        searchCriteriaModel.zipCodes(session.orderForPropertyAddress.zip());
                        searchCriteriaModel.parcelNumbers(session.orderForPropertyAddress.apn());
                        break;
                    case 50:
                        searchInformationModel.street(session.orderForPropertyAddress.address());
                        searchInformationModel.zipCodes(session.orderForPropertyAddress.zip());
                        searchInformationModel.parcelNumber(session.orderForPropertyAddress.apn());
                        break;
                    case 51:
                        propertyInformationModel.street(session.orderForPropertyAddress.address());
                        propertyInformationModel.zipCodes(session.orderForPropertyAddress.zip());
                        propertyInformationModel.parcelNumber(session.orderForPropertyAddress.apn());
                        break;
                    default:
                }
            }
        }

        function buildPreviewModel() {
            for (var i = 0; i < currentOrderModels().length; i++) {
                if (currentOrderModels()[i].GetModelData) {
                    currentOrderModels()[i].GetModelData();
                }
            }
        }

        function submitOrder() {
            session.isBusy(true);
            console.log(session.isBusy());
            console.log('Offline Order submitted');
            console.log(selectedOrderType());
            // commonDialogModel.show("Order submit status", orderTitle() + " submitted successfully");

            // var url = config.submitOfflineOrderUrl;
            var request;
            switch (selectedOrderType()) {
                case 49:
                    var url = config.submitOfflineFarmUrl;
                    request = {

                        //Order Model Information -- Required
                        "BrandId": session.brandId,
                        "OrderId": "-1",
                        "UserId": session.userId,
                        "ServiceId": "49",
                        "OperationId": session.defaultOpertionId(),
                        "CreateDate": customerInformationModel.orderDate,
                        "PropertyAddress": "",
                        "PropertyCity": "",
                        "PropertyState": "",
                        "PropertyZip": "",
                        //Order Model Information -- Fill If Present
                        "BackendSystem": "",
                        "EscrowOfficerId": "",
                        "TitleOfficerId": "",
                        "IsBackendOrder": "",
                        "PaymentId": "",
                        //Customer Information
                        "SalesRep": customerInformationModel.salesRep,
                        "UserFullName": customerInformationModel.fullName,
                        "Email": customerInformationModel.email,
                        "CompanyName": customerInformationModel.company,
                        "CompanyAddress": customerInformationModel.address,
                        "CompanyPhone":
                        {
                            "PhoneNumber": customerInformationModel.phon(),
                            "Extension": customerInformationModel.ext(),
                            "PhoneType": "Business"
                        },
                        "CompanyFax":
                        {
                            "PhoneNumber": customerInformationModel.fax,
                            "Extension": "",
                            "PhoneType": "Fax"
                        },
                        //Order Information
                        "ParcelNumbers": searchCriteriaModel.parcelNumbers,
                        "Address": searchCriteriaModel.propertyAddress,
                        "City": searchCriteriaModel.city,
                        "State": searchCriteriaModel.state,
                        "ZipCodes": searchCriteriaModel.zipCodes,
                        "ThomasBrothersGuide": searchCriteriaModel.thomasBrothersGuide,
                        "Tract": searchCriteriaModel.tract,
                        "OwnerNames": searchCriteriaModel.ownerNames,
                        "AdditionalInformation1": searchCriteriaModel.additionalInformation,
                        "Bedroom": secondarySearchModel.bedroom,
                        "Bath": secondarySearchModel.bath,
                        "SquareFootage": secondarySearchModel.squareFootage,
                        "UseCode": secondarySearchModel.useCode,
                        "OwnerOccupied": secondarySearchModel.isOwnerOccupied,
                        "PriceRange": secondarySearchModel.priceRange,
                        "Line6": reportTypeModel.line6,
                        "Line5": reportTypeModel.line5,
                        "Line3": reportTypeModel.line3,
                        "Line1": reportTypeModel.line1,
                        "RawFormat": reportTypeModel.rawFormat,
                        "Ascii": reportTypeModel.ascii,
                        "Converted": reportTypeModel.converted,
                        "Digit5": labelsModel.digit5,
                        "AdditionalInformation2": labelsModel.additionalInformation,
                        "Carrier": labelsModel.carrierRoute,
                        "Barcode": labelsModel.barcode,
                        "ECRLLOT": labelsModel.ecrllot,
                        "CRT": labelsModel.crtOrbarcode,
                        "Permit": labelsModel.permitNumber,
                        "PostOffice": labelsModel.postOffice
                    }
                    https.post(url, request)
                   .then(function (data) {
                       if (data.HasErrors == false) {
                           orderSubmitted(true);
                           commonDialogModel.IsOrderDialog(true);
                           commonDialogModel.orderId(data.OrderId);
                           if (data.IntendedRecpients)
                               commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                           commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                       }
                       //else {
                       //    for (var i = 0; i < data.Messages.length; i++) {
                       //        if (data.Messages[i].ExceptionMessage != "") {
                       //            console.log("Error:" + data.Messages[i].ExceptionMessage, orderTitle());
                       //            alert.error('Internal Error. Please contact Administrator.');
                       //            orderSubmitted(false);
                       //            session.isBusy(false);
                       //        }
                       //    }
                       //}
                       else {
                           orderSubmitted(false);
                           session.isBusy(false);
                           var msgSuccess = "";
                           var msgError = "";

                           for (var i = 0; i < data.Messages.length; i++) {
                               if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                                   //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                                   //alert.error('Internal Error. Please contact Administrator.');
                                   if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                       msgError = msgError + data.Messages[i].Message + "\n";
                                   }
                               }
                               else {
                                   msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                               }
                           }
                           //alert.error(msgSuccess, orderTitle());
                           //alert.error(msgError, orderTitle());
                           if (msgSuccess.length > 0) {
                               orderSubmitted(true);
                               commonDialogModel.IsOrderDialog(true);
                               commonDialogModel.orderId(data.OrderId);
                               msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                               commonDialogModel.messagehtml(msgError);
                               if (data.IntendedRecpients)
                                   commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                               commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                           }
                           else if (msgError.length > 0) {
                               alert.error(msgError, orderTitle());
                           }

                       }
                   })
             .fail(function (data) {
                 var error = JSON.parse(data.responseText)
                 console.log(error);
                 session.isBusy(false);
             })
                    break;
                case 50:
                    var url = config.submitOfflineTitleUrl;
                    request = {
                        "BrandId": session.brandId,
                        "OrderId": "-1",
                        "UserId": session.userId,
                        "ServiceId": 50,
                        "OperationId": session.defaultOpertionId(),
                        "CreateDate": customerInformationModel.orderDate,
                        "BackendSystem": "",
                        "EscrowOfficerId": "",
                        "TitleOfficerId": "",
                        "IsBackendOrder": "",
                        "PaymentId": "",
                        "PropertyAddress": searchInformationModel.propertyAddress,
                        "PropertyAddressforOrder": searchInformationModel.propertyAddress,
                        "PropertyCity": searchInformationModel.city,
                        "PropertyState": searchInformationModel.state,
                        "PropertyZip": searchInformationModel.zipCodes,
                        "SalesRep": customerInformationModel.salesRep,
                        "UserFullName": customerInformationModel.fullName,
                        "Email": customerInformationModel.email,
                        "CompanyName": customerInformationModel.company,
                        "Address": customerInformationModel.address,
                        "Phone":
                        {
                            "PhoneNumber": customerInformationModel.phon(),
                            "Extension": customerInformationModel.ext(),
                            "PhoneType": "Business"
                        },
                        "Fax":
                        {
                            "PhoneNumber": customerInformationModel.fax(),
                            "Extension": "",
                            "PhoneType": "Fax"
                        },
                        "PropCharacteristics": typeofInformationRequestedModel.propertyCharacteristics,
                        "SalesComps": typeofInformationRequestedModel.salesComps,
                        "PlatMaps": typeofInformationRequestedModel.platMaps,
                        "Deed": typeofInformationRequestedModel.deed,
                        "DeedsOfTrust": typeofInformationRequestedModel.deedsofTrust,
                        "NoticeOfDefault": typeofInformationRequestedModel.nod,
                        "ParcelNumber": searchInformationModel.parcelNumber,
                        "OwnerName": searchInformationModel.ownerName,
                        "Street": searchInformationModel.street,
                        "City": searchInformationModel.city,
                        "State": searchInformationModel.state,
                        "ZipCodes": searchInformationModel.zipCodes,
                        "County": searchInformationModel.county,
                        "PropertyInformation": searchInformationModel.propertyInformation,
                        "DeliveryMethod": searchInformationModel.deliveryMethod
                    }
                    https.post(url, request)
                    .then(function (data) {
                        if (data.HasErrors == false) {
                            orderSubmitted(true);
                            commonDialogModel.IsOrderDialog(true);
                            commonDialogModel.orderId(data.OrderId);
                            if (data.IntendedRecpients)
                                commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                            commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                        }
                        //else {
                        //    alert.error(data.Messages[0].Message + "   " + data.Messages[0].ExceptionStackTrace, orderTitle());
                        //}
                        else {
                            orderSubmitted(false);
                            session.isBusy(false);
                            var msgSuccess = "";
                            var msgError = "";

                            for (var i = 0; i < data.Messages.length; i++) {
                                if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                                    //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                                    //alert.error('Internal Error. Please contact Administrator.');
                                    if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                        msgError = msgError + data.Messages[i].Message + "\n";
                                    }
                                }
                                else {
                                    msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                                }
                            }
                            //alert.error(msgSuccess, orderTitle());
                            //alert.error(msgError, orderTitle());
                            if (msgSuccess.length > 0) {
                                orderSubmitted(true);
                                commonDialogModel.IsOrderDialog(true);
                                commonDialogModel.orderId(data.OrderId);
                                msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                                commonDialogModel.messagehtml(msgError);
                                if (data.IntendedRecpients)
                                    commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                                commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                            }
                            else if (msgError.length > 0) {
                                alert.error(msgError, orderTitle());
                            }

                        }

                    })
               .fail(function (data) {
                   var error = JSON.parse(data.responseText)
                   console.log(error);
                   session.isBusy(false);
               })

                    break;
                case 51:
                    var url = config.submitOfflinePropertyProfileUrl;
                    request = {
                        //Order Model Information -- Required
                        "BrandId": session.brandId,
                        "OrderId": "-1",
                        "UserId": session.userId,
                        "ServiceId": "51",
                        "OperationId": session.defaultOpertionId(),
                        "CreateDate": customerInformationModel.orderDate,
                        "IsBackendOrder": "",
                        "PropertyAddress": "",
                        "PropertyCity": "",
                        "PropertyState": "",
                        "PropertyZip": "",
                        "BackendSystem": "",
                        "EscrowOfficerId": "",
                        "TitleOfficerId": "",
                        "PaymentId": "",
                        //Customer Information
                        "OrderDate": customerInformationModel.orderDate,
                        "SalesRep": customerInformationModel.salesRep,
                        "UserFullName": customerInformationModel.fullName,
                        "Email": customerInformationModel.email,
                        "CompanyName": customerInformationModel.company,
                        "CompanyAddress": customerInformationModel.address,
                        "CompanyPhone":
                        {
                            "PhoneNumber": customerInformationModel.phon(),
                            "Extension": customerInformationModel.ext(),
                            "PhoneType": "Business"
                        },

                        "CompanyFax":
                        {
                            "PhoneNumber": customerInformationModel.fax(),
                            "Extension": "",
                            "PhoneType": "Fax"
                        },
                        //Order Information
                        "County": propertyInformationModel.county,
                        "ParcelNumber": propertyInformationModel.parcelNumber,
                        "OwnerName": propertyInformationModel.ownerName,
                        "PropertyDescription": propertyInformationModel.propertyDescription,
                        "Street": propertyInformationModel.street,
                        "City": propertyInformationModel.city,
                        "State": propertyInformationModel.state,
                        "ZipCodes": propertyInformationModel.zipCodes,
                        "CoverPage": propertyInformationModel.coverPage,
                        "PropertyInformation": propertyInformationModel.propertyInformation,
                        "SalesComps": propertyInformationModel.salesComps,
                        "NearbyOwners": propertyInformationModel.nearbyOwners,
                        "Demographics": propertyInformationModel.demographics,
                        "Schools": propertyInformationModel.schools,
                        "PlatMap": propertyInformationModel.platMap,
                        "Documents": propertyInformationModel.documents,
                        "DeliveryMethod": propertyInformationModel.deliveryMethod
                    }
                    https.post(url, request)
                   .then(function (data) {
                       if (data.HasErrors == false) {
                           orderSubmitted(true);
                           commonDialogModel.IsOrderDialog(true);
                           commonDialogModel.orderId(data.OrderId);
                           if (data.IntendedRecpients)
                               commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                           commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                       }
                       //else {
                       //    alert.error(data.Messages[0].Message + "   " + data.Messages[0].ExceptionStackTrace, orderTitle());
                       //}
                       else {
                           orderSubmitted(false);
                           session.isBusy(false);
                           var msgSuccess = "";
                           var msgError = "";

                           for (var i = 0; i < data.Messages.length; i++) {
                               if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                                   //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                                   //alert.error('Internal Error. Please contact Administrator.');
                                   if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                       msgError = msgError + data.Messages[i].Message + "\n";
                                   }
                               }
                               else {
                                   msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                               }
                           }
                           //alert.error(msgSuccess, orderTitle());
                           //alert.error(msgError, orderTitle());
                           if (msgSuccess.length > 0) {
                               orderSubmitted(true);
                               commonDialogModel.IsOrderDialog(true);
                               commonDialogModel.orderId(data.OrderId);
                               msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                               commonDialogModel.messagehtml(msgError);
                               if (data.IntendedRecpients)
                                   commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                               commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                           }
                           else if (msgError.length > 0) {
                               alert.error(msgError, orderTitle());
                           }

                       }

                   })
              .fail(function (data) {
                  var error = JSON.parse(data.responseText)
                  console.log(error);
                  session.isBusy(false);
              })

                    break;
                default:
            }
        }


        function goBack(complete) {
            router.navigateBack();
        }
        function Failed(error) {
            alert.error(error.statusText);
        }

        function getUserInfoByEmail(email) {
            var url = config.getUserInfoByEmailUrl;
            return https.get(url, {
                email: email,
                format: 'json'
            })
        }

        function getCustomerInfo(userId, opertionId) {
            return utlities.getOperationUserInfo(userId, opertionId).done(function (data) {
                var currdate = new Date();
                var currdate = (currdate.getMonth() + 1) + '/' + currdate.getDate() + '/' + currdate.getFullYear();
                customerInformationModel.orderDate(currdate);
                utlities.getManagerFullName(data.OperationId, data.UserId).done(function (result) {
                    customerInformationModel.salesRep(result);
                })
                customerInformationModel.fullName(data.FirstName + String.fromCharCode(160) + data.LastName);
                customerInformationModel.email(data.Email);
                customerInformationModel.company(data.Company);
                customerInformationModel.address(data.StreetAddress1 + String.fromCharCode(160) + data.StreetAddress2 + String.fromCharCode(160) + data.City + ',' + String.fromCharCode(160) + data.State + String.fromCharCode(160) + data.Zip);
                customerInformationModel.phon(data.PhoneWork);
                customerInformationModel.ext(data.PhoneWorkExt);
                customerInformationModel.fax(data.PhoneFax);

            })

        }

        function getZipInfo(zip) {
            var url = config.getZipInfoUrl;
            return https.get(url, {
                zipCode: zip,
                format: 'json'
            })
        }

        function brandfieldVisibility(currentBrandId) {

            switch (currentBrandId) {

                case 2:
                    //Ticor Title
                    labelsCtrlVisible(true);
                    rptCtrlVisible(true);
                    propertInfoVisible(true)
                    break;
                case 3:
                    //Fidelity National Title
                    rptCtrlVisible(true);
                    labelsCtrlVisible(true);
                    propertInfoVisible(true)
                    break;
                case 7:
                    //Fidelity National Title Group;
                    rptCtrlVisible(true);
                    labelsCtrlVisible(true);
                    propertInfoVisible(true)
                    break;
                case 15:
                    //Commonwealth Land Title Insurance Company
                    rptCtrlVisible(true);
                    labelsCtrlVisible(true);
                    propertInfoVisible(true)
                    break;
                case 4:
                    //Chicago Title Premier Services
                    break;
                case 5:
                    //Alamo Title Company
                    labelsCtrlVisible(true);
                    rptCtrlVisible(true);
                    propertInfoVisible(true)
                    break;
                case 12:
                    //Grand Canyon Title Agency
                    break;

                case 16:
                    //Lawyers Title
                    //rptCtrlVisible(true);
                    break;
                case 19:
                    //Security Title Agency
                    rptCtrlVisible(true);
                    labelsCtrlVisible(true);
                    propertInfoVisible(true)
                    break;

                case 21:
                    //Austin Title
                    labelsCtrlVisible(true);
                    rptCtrlVisible(true);
                    propertInfoVisible(true)
                    break;

            }
        }
    });
