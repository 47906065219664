define('models/order/offlinePropertyProfile',["knockout", "modelMetaData"],
    function (ko, modelMetaData) {
        function PropertyInformation() {
            var self = this;

            self.viewName = "orderModule/offline/_propertyInformation.html"
            self.title = "Property Information"
            self.ModelId = "PropertyInformation"
            self.index = ko.observable(-1)

            self.brandId = ko.observable("")
            self.county = ko.observable("")
            self.parcelNumber = ko.observable("")
            self.ownerName = ko.observable("")
            self.propertyDescription = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Property Description must be 200 characters or less' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.street = ko.observable("")
             .extend({ required: { message: 'Street is required.' } })
            self.city = ko.observable(undefined)
             .extend({ required: { message: 'City is required.' } })
            self.state = ko.observable(undefined)
             .extend({ required: { message: 'State is required.' } })
            self.zipCodes = ko.observable("")
                .extend({ required: { message: 'Zip is required.' } })
                 .extend({ required: { message: 'Zip is required.' } })
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.coverPage = ko.observable("")
            self.propertyInformation = ko.observable("")
            self.salesComps = ko.observable("")
            self.nearbyOwners = ko.observable("")
            self.demographics = ko.observable("")
            self.schools = ko.observable("")
            self.platMap = ko.observable("")
            self.documents = ko.observable("")
            self.deliveryMethod = ko.observable("")
            self.deliveryList = ko.observableArray([
    { key: "Fax", value: "Fax" },
     { key: "Messenger", value: "Messenger" },
    { key: "E-mail", value: "E-mail" },

            ]);

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.county(data.county())
                    self.parcelNumber(data.parcelNumber())
                    self.ownerName(data.ownerName())
                    self.propertyDescription(data.propertyDescription())
                    self.street(data.street())
                    self.city(data.city())
                    self.state(data.state())
                    self.zipCodes(data.zipCodes())
                    self.coverPage(data.coverPage())
                    self.propertyInformation(data.propertyInformation())
                    self.salesComps(data.salesComps())
                    self.nearbyOwners(data.nearbyOwners())
                    self.demographics(data.demographics)
                    self.schools(data.schools)
                    self.platMap(data.plotMap())
                    self.documents(data.documents())
                    self.deliveryMethod(data.deliveryMethod())
                }

            };

            self.reset = function () {
                self.county('')
                self.parcelNumber('')
                self.ownerName('')
                self.propertyDescription('')
                self.street('')
                self.city(undefined)
                self.state(undefined)
                self.zipCodes('')
                self.coverPage('')
                self.propertyInformation('')
                self.salesComps('')
                self.nearbyOwners('')
                self.demographics('')
                self.schools('')
                self.platMap('')
                self.documents('')
                self.deliveryMethod('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.resetPropertyAddress = function () {
                self.county('')
                self.parcelNumber('')
                self.street('')
                self.city(undefined)
                self.state(undefined)
                self.zipCodes('')
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Parcel Number", self.parcelNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Owner's Name", self.ownerName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip", self.zipCodes()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("County", self.county()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Street", self.street()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Description", self.propertyDescription()));

                self.modelData.properties.push(new modelMetaData.propertyData().create("Cover Page", modelMetaData.reportBooleanValue(self.coverPage())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Information", modelMetaData.reportBooleanValue(self.propertyInformation())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Sales Comps", modelMetaData.reportBooleanValue(self.salesComps())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Nearby Owners", modelMetaData.reportBooleanValue(self.nearbyOwners())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Plat Map", modelMetaData.reportBooleanValue(self.platMap())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Documents", modelMetaData.reportBooleanValue(self.documents())));

                if (self.brandId != 4 && self.brandId != 16) {
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Demographics", modelMetaData.reportBooleanValue(self.demographics())));
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Schools", modelMetaData.reportBooleanValue(self.schools())));
                }

                self.modelData.properties.push(new modelMetaData.propertyData().create("Delivery Method", self.deliveryMethod()));
            }

        }

        var vm = {
            PropertyInformation: PropertyInformation
        }

        return vm;
    });
