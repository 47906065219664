define('viewmodels/salesExecutive',['durandal/app', 'knockout', 'services/logger', 'plugins/http', 'global/session', 'config', 'utlities', 'salesExecutiveModel', 'managerInfoModel'],
    function (app, ko, logger, https, session, config, utlities, model, profileModel) {

        var salesExecModel = new model.SalesExecutiveModel();
        var managerProfile = new profileModel.ManagerProfileModel();
        var teamProfile = new profileModel.TeamProfileModel();


        var vm = {
            activate: activate,
            salesExecModel: salesExecModel,
            formatPhoneNo: formatPhoneNo,
            showManagerProfile: showManagerProfile
        };

        function activate() {

            $('#contentToggle').hide();
            setupNav();
            console.log('Sales Executive activated');
            return fetchSalesExecutiveInfo();
        }


        function showManagerProfile() {

            var url = config.getManagerProfileUrl;
            https.get(url)
                .then(function (data) {
                    console.log(data);
                    managerProfile.Update(data);
                    teamProfile.Update(data);
                    session.managerProfile(managerProfile);
                    session.managerTeamProfile(teamProfile);
                    $('#popupManagerProfile').modal('show');

                })
                .fail(function (data) {
                    console.log(data);
                });

        }

        function fetchSalesExecutiveInfo() {
            return https.get(config.getSalesExecutiveDetails)
                .then(function (data) {
                    if (data != null) {
                        salesExecModel.IsSalesExecutiveAvailable(true);
                        salesExecModel.FullName(data.FirstName + " " + data.LastName);
                        salesExecModel.Email(data.EmailAddress);
                        salesExecModel.Phone(data.PhoneWork);
                        salesExecModel.Address(data.StreetAddress1 + " " + data.StreetAddress2);
                        salesExecModel.City(data.City);
                        salesExecModel.Zip(data.Zip);
                        salesExecModel.PhotoUrl(data.PhotoUrl);
                        salesExecModel.UserType(data.UserType);
                        if (data.PhoneWorkExt) {
                            salesExecModel.PhoneExt(data.PhoneWorkExt);
                        }
                    }
                    else {
                        salesExecModel.IsSalesExecutiveAvailable(false);
                    }
                })
                .fail(function () {
                    salesExecModel.IsSalesExecutiveAvailable(false);
                });
    }


        function setupNav() {
            if (session) {
                if (session.brandId != 4) {
                    var parent = document.getElementById('side-menu');
                    var product = document.getElementById("liProducts");
                    var property = document.getElementById("liProperty");
                    parent.removeChild(product);
                    parent.insertBefore(product, property);
                }
            }
        }


        function formatPhoneNo(phone, ext, extSym) {
            if (phone) {
                return utlities.getUSPhoneFormatWithExtn(phone, ext, extSym);
            }
            else
                return "Not Available";
        }

        return vm;
    });
