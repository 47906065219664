//TODO: Inject dependencies

define('viewmodels/_SideNav',['durandal/app','plugins/router', 'knockout', 'plugins/http', 'global/session', 'inspinia', 'config', 'utlities', 'salesExecutiveModel', 'managerInfoModel', 'metisMenu', 'jquery'],
    function(app, router, ko, https, session, inspinia, config, utlities, model, profileModel, metisMenu, jquery) {

        var salesExecModel = new model.SalesExecutiveModel();
        var managerProfile = new profileModel.ManagerProfileModel();
        var teamProfile = new profileModel.TeamProfileModel();
        var downloadUrl = ko.observable();

        // Internal properties and functions

        // Reveal the bindable properties and functions
        var vm = {
            activate: activate,
            eFarmClick: eFarmClick,
            eFarm2Click: eFarm2Click,
            leadLocatorClick: leadLocatorClick,
            foreclosureSearchClick: foreclosureSearchClick,
            attachClickHandler: attachClickHandler,
            eFarm3Click: eFarm3Click,
            attached: function() {
                if ($('body').hasClass('mini-navbar')) {
                    toggleSideMenu();
                }

                $('#dvChatDiv').on("keypress",
                    function(event) {
                        var keyCode = (event.which ? event.which : event.keyCode);
                        console.log('keypress');
                        if (keyCode === 13) {
                            $('img', '#dvChatDiv').trigger("click");
                        }
                    });


                //$('.navbar-minimalize').off();

                //$('.navbar-minimalize').on("click", function () {
                //    toggleSideMenu();
                //    return false;
                //});

                attachClickHandler();
                setupNav();
                scrollNavToTop();
                console.log("session.isMobile() : " + session.isMobile());
            },
            //compositionComplete: function (a, b, c) {
            //    new ResizeSensor($('#siteContent'), function () {
            //        SetSideNavHeight();
            //    });
            //},
            compositionComplete: function(a, b, c) {
                var sensor = new ResizeSensor($('#siteContent'),
                    function() {
                        //var vp_h = $(window).height();
                        //var minimumRequiredContentBodyHeight = vp_h - $('#header').height() - $('#footer').height();
                        //var actual = $('#siteContent').height();
                        //if (actual < minimumRequiredContentBodyHeight)
                        //    $('#siteContent').attr('style', 'min-height:' + minimumRequiredContentBodyHeight + 'px');
                        //else
                        //    $('#siteContent').attr('style', 'max-height:' + actual + 'px');

                        session.SetSideNavigationHeight();


                    });

                if (session.isChatEnable()) {
                    var dvChatDiv = document.getElementById('dvChatDiv');
                    var observer = new MutationObserver(function(mutations) {
                        $('img', '#dvChatDiv').attr('alt', 'click here to get live help now.');
                    });
                    observer.observe(dvChatDiv, { childList: true, subtree: true });
                }
            },
            goBack: goBack,
            session: session,
            checkLLAgreement: checkLLAgreement,
            title: 'Off-line Farm Order',
            salesExecModel: salesExecModel,
            formatPhoneNo: formatPhoneNo,
            showManagerProfile: showManagerProfile,
            bindingComplete: setupMetisMenu,
            checkLLAgreementWithREiSourceAccount: checkLLAgreementWithREiSourceAccount,
            showPremiumLeadsPage: showPremiumLeadsPage,
            showNgPremLeadsPage: showNgPremLeadsPage,
            downloadUrl: downloadUrl,
            downloadContact: downloadContact,
            checkPremiumLeadsPopUp: checkPremiumLeadsPopUp
        };

        return vm;

        function SetSideNavHeight() {
            session.SetSideNavigationHeight();
        }

        function scrollNavToTop() {
            $('ul#side-menu > li').on("click",
                function(e) {
                    $('[aria-expanded="true"]').attr('aria-expanded', 'false');
                    if ($('ul#side-menu > li.active').length) {
                        var pos = $('ul#side-menu > li.active').offset().top - ($('.sideNavBar').offset().top);
                        $('#SideMenu').animate({
                                scrollTop: pos
                            },
                            1500);
                        $('ul#side-menu > li.active > a').attr('aria-expanded', 'true');
                        $('ul#side-menu > li.active > ul').attr('aria-expanded', 'true');
                        //$('ul#side-menu > li.active > a').removeAttr('aria-expanded');
                    }
                });
        }

        function toggleSideMenu() {
            session.observableData.contentChanged(Date());
            $("body").toggleClass("mini-navbar");
            //$("#SideMenu").toggleClass("sideNavBarActive sideNavBarDisable");
            //$("#SideMenu").toggleClass(function () {
            //    return $(this).is('.sideNavBarActive, .sideNavBarDisable') ? 'sideNavBarActive sideNavBarDisable' : 'sideNavBarActive';
            //});
            // save state in local storage 
            localStorage.setItem('mini-navbar', $('.mini-navbar').is(":visible"));
            SetSideNavHeight();
            SmoothlyMenu();
        }

        function setupMetisMenu() {
            console.log('setupMetisMenu() called');

            SetSideNavHeight();
            $('.slimScrollDiv').attr('style', 'width:100%;');
            $('.slimScrollDiv').addClass('repositionSearch');

            $('#side-menu').metisMenu();

            //ContactAtOnce chat Code 
            //window.lpTag = window.lpTag || {}, void 0 === window.lpTag._tagCount
            //    ? (window.lpTag = {
            //        site: "p-18412",
            //        env: "us",
            //        providerId: "18412",
            //        section: lpTag.section || "",
            //        autoStart: !1 !== lpTag.autoStart,
            //        ovr: lpTag.ovr || { domain: "tag.contactatonce.com", tagjs: "" },
            //        _v: "1.0",
            //        _tagCount: 1,
            //        protocol: "https:",
            //        events: {
            //            bind: function(t, e, i) { lpTag.defer(function() { lpTag.events.bind(t, e, i) }, 0) },
            //            trigger: function(t, e, i) { lpTag.defer(function() { lpTag.events.trigger(t, e, i) }, 1) }
            //        },
            //        defer: function(t, e) {
            //            0 == e
            //                ? (this._defB = this._defB || [], this._defB.push(t))
            //                : 1 == e
            //                ? (this._defT = this._defT || [], this._defT.push(t))
            //                : (this._defL = this._defL || [], this._defL.push(t))
            //        },
            //        sdk: {
            //            exec: function(t, e) {
            //                lpTag._defExec = lpTag._defExec || {}, lpTag._defExec[t] =
            //                    lpTag._defExec[t] || [], lpTag._defExec[t].push(e)
            //            }
            //        },
            //        load: function(t, e, i) {
            //            var n = this;
            //            setTimeout(function() { n._load(t, e, i) }, 0)
            //        },
            //        _load: function(t, e, i) {
            //            var n = t;
            //            t ||
            //            (n = this.protocol +
            //                "//" +
            //                (this.ovr && this.ovr.domain ? this.ovr.domain : "tag.contactatonce.com") +
            //                "/tag/tag.js");
            //            var a = document.createElement("script");
            //            a.setAttribute("charset", e || "UTF-8"), i && a.setAttribute("id", i), a.setAttribute("src", n),
            //                document.getElementsByTagName("head").item(0).appendChild(a)
            //        },
            //        init: function() {
            //            this._timing = this._timing || {}, this._timing.start = (new Date).getTime();
            //            var t = this;
            //            window.attachEvent
            //                ? window.attachEvent("onload", function() { t._domReady("domReady") })
            //                : (window.addEventListener("DOMContentLoaded", function() { t._domReady("contReady") }, !1),
            //                    window.addEventListener("load", function() { t._domReady("domReady") }, !1)), void 0 ===
            //                window._caotStop &&
            //                this.load()
            //        },
            //        start: function() { this.autoStart = !0 },
            //        _domReady: function(t) {
            //            this.isDom || (this.isDom = !0, this.events.trigger("CAOT", "DOM_READY", { t: t })),
            //                this._timing[t] = (new Date).getTime()
            //        },
            //        vars: lpTag.vars || [],
            //        dbs: lpTag.dbs || [],
            //        ctn: lpTag.ctn || [],
            //        sdes: lpTag.sdes || [],
            //        ev: lpTag.ev || [],
            //        tagletCommands: lpTag.tagletCommands || []
            //    }, lpTag.init())
            //    : window.lpTag._tagCount += 1;

        }

        function attachImage() {
            //setTimeout(function () {
            //    $('img', '#dvChatDiv').attr('alt', 'click here');
            //}, 20);
        }

        function showNgPLeadsPopUp() {

            app.showMessage(session.ngPopUpText(),
                'Important Announcement',
                'Continue',
                true,
                {
                    "secondaryButtonClass": "btn-primary",
                    style: {
                        'max-width': "600px",
                        'max-height': "50vh!important"
                    }
                });
            return;
        }

        function checkPremiumLeadsPopUp() {
            if (session.ngPopUpEnabled) {
                showNgPLeadsPopUp();
            } else {
                showPremiumLeadsPage();
            }
        }
    
        function showPremiumLeadsPage() {
            if (document.getElementById("preLeads").getAttribute("aria-expanded") !== "true")
                session.CheckPopUpBlock(session.accessTitleProUrl(), null, null);
            //window.open(session.accessTitleProUrl());
            return;
        }
        function showNgPremLeadsPage() {
            if (document.getElementById("ngPremLeads").getAttribute("aria-expanded") !== "true")
                session.CheckPopUpBlock(session.accessTitleProUrl(), null, null);
            //window.open(session.accessTitleProUrl());
            return;
        }

        //function eFarmClick() {
        //    //var classicEFarmName = session.classicEfarmName();
        //    var classicEFarmName = 'Continue';
        //    var nextGenEFarmName = session.nextGenEfarmName();
        //    if (session.isPromptClassicEfarmMessage()) {
        //        app.showMessage(session.promptClassicEfarmMessage(), 'Important Announcement', [classicEFarmName, nextGenEFarmName, 'Cancel '], true, { "secondaryButtonClass": "btn-primary", style: { 'max-width': "600px", 'max-height': "50vh!important" } })
        //            .then(function (selectedOption) {
        //                if (selectedOption == classicEFarmName)
        //                    openProduct('eFarm');
        //                else if (selectedOption == nextGenEFarmName)
        //                    eFarm2Click();
        //            });
        //    } else {
        //        openProduct('eFarm');
        //    }

        //}
        

        function attachClickHandler() {
            //$('.active', '.metismenu').attr('class', '');
            $('a', '.NavchildLink').off();

            //$('[aria-expanded="true"]').each(function (item) {
            //    //$(item).attr("aria-expanded", "false");
            //});

            $('a', '.NavchildLink').on("click", function () {
                session.observableData.contentChanged(Date());
                if ($('#sideNavContainer').hasClass('show')) {
                    //$('#sideNavContainer').removeClass('show');
                    $('#sideNavContainer').collapse('hide');
                }
            });
        }

        function activate() {
            $('#contentToggle').hide();
            console.log('sidenav activated');
            downloadUrl(config.getManagerContactFileUrl);
            return fetchSalesExecutiveInfo();
        }

        function showFile(blob)
        {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([blob], { type: "application/vcf" })

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, "contact.vcf");
                return;
            }

            var link = document.createElement('a');
            var windowUrl = window.URL || window.webkitURL;
            var url = windowUrl.createObjectURL(newBlob);
            // Add the element to the DOM
            document.body.appendChild(link);
            link.setAttribute("type", "hidden"); // make it hidden if needed
            link.download = 'contact.vcf';
            link.href = url;
            link.click();


            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            //const data = window.URL.createObjectURL(newBlob);
            //var link = document.createElement('a');
            //link.href = data;
            //link.download = "contact.vcf";
            //link.click();
            //setTimeout(function () {
            //    // For Firefox it is necessary to delay revoking the ObjectURL
            //    window.URL.revokeObjectURL(data)
            //        , 100
            //});

           // var windowUrl = window.URL || window.webkitURL;
           // var url = windowUrl.createObjectURL(newBlob);
           // var anchor = document.createElement('a');
           // anchor.href = url;
           // anchor.download = 'contact.vcf';
           // anchor.click();
           // setTimeout(function () {
           ////     document.body.removeChild(anchor);
           //     //window.URL.revokeObjectURL(url);
           //     windowUrl.revokeObjectURL(url);
           // }, 100);  
        //    anchor.parentNode.removeChild(anchor);
            

}


        function downloadContact() {
            $.ajax({
                url: config.getManagerContactFileUrl,
                type: "POST",
                headers: {
                    "Authorization": 'Bearer ' + (sessionStorage["accessToken"] || localStorage["accessToken"])
                },
                success: function (data) {
                    var fileData = [data];
                    blobObject = new Blob(fileData);
                    showFile(data);
                },
                error: function (jqxhr, textStatus, errorThrown) {
                    console.log(textStatus, errorThrown)
                }
            });
        }

        function checkLLAgreement() {
            if (session.llaAccepted()) {
                return true;
            }
            else {
                session.showLLAAgreement();
                return false;
            }
        }

        function checkLLAgreementWithREiSourceAccount() {
            if (!session.hasAccessReiSourceAccount()) {
                session.noREiSourceAccount();
            }
            if (session.llaAccepted()) {
                return true;
            }
            else {
                session.showLLAAgreement();
                return false;
            }
        }

        function goBack(complete) {
            router.navigateBack();
        }

        function showManagerProfile() {

            var url = config.getManagerProfileUrl;
            https.get(url)
                .then(function (data) {
                    console.log(data);
                    managerProfile.Update(data);
                    teamProfile.Update(data);
                    session.managerProfile(managerProfile);
                    session.managerTeamProfile(teamProfile);
                    $('#popupManagerProfile').modal('show');
                }).fail(function (data) {
                    console.log(data);
                });
        }

        //function eFarmClick() {
        //    if (session.isPromptClassicEfarmMessage()) {
        //        app.showMessage(session.promptClassicEfarmMessage(), 'Important Announcement', ['OK'])
        //            .then(function (selectedOption) {
        //                openProduct('eFarm');
        //            });
        //    } else {
        //        openProduct('eFarm');
        //    }
            
        //}

        function eFarmClick() {
            //var classicEFarmName = session.classicEfarmName();
            var classicEFarmName = 'Continue';
            var nextGenEFarmName = session.nextGenEfarmName();
            if (session.isPromptClassicEfarmMessage()) {
                app.showMessage(session.promptClassicEfarmMessage(), 'Important Announcement', [classicEFarmName, nextGenEFarmName, 'Cancel '], true, { "secondaryButtonClass": "btn-primary", style: { 'max-width': "600px", 'max-height': "50vh!important" } })
                    .then(function (selectedOption) {
                        if (selectedOption == classicEFarmName)
                            openProduct('eFarm');
                        else if (selectedOption == nextGenEFarmName)
                            eFarm2Click();
                    });
            } else {
                openProduct('eFarm');
            }

        }

        function eFarm2Click() {
            //if (session.isUsageUpdated()) {
            //    //var flagUrl = config.setUsageUpdatedFlagBkSlsUrl;
            //    //var request = { "FlagValue": false };
            //    //https.post(flagUrl, request).done(function() {
            //    //    session.isUsageUpdated(false);
            //    //    console.log("isUsageUpdated: " + session.isUsageUpdated());
            //    //});
                

            //}
            //session.isUsageUpdated(false);
            openProduct('eFarm2');
            
            
        }
        function eFarm3Click() {
            var url = config.getFarmingAppSsoUrl;
            https.get(url, {
                format: 'json'
            }).then(function (data) {
                console.log(data);
                if (data.HasErrors == false) {
                    if (data.FarmingSsoUrl != null && data.FarmingSsoUrl != '') {
                        session.CheckPopUpBlock(data.FarmingSsoUrl);
                        return;
                    }
                }
                else {
                    app.showMessage('Required URL is not configured. Please contact Administrator.', 'URL not available', ['OK']);
                }
            }).fail(function (data) {
                console.log(data);
            });
        }

        function leadLocatorClick() {
            openProduct('leadLocator');
        }

        function foreclosureSearchClick() {
            openProduct('foreclosureSearch');
        }

        function openProduct(type) {
            switch (type) {
                case 'eFarm':
                    
                    if (session.eFarmUrl().length > 0) {
                        session.CheckPopUpBlock(session.eFarmUrl(), '_blank', null);
                        return;
                    }
                    break;
            
                case 'eFarm2':
                    session.isBKEfarmNavigationMade(true);
            break;

                    

                case 'foreclosureSearch':
                    if (session.foreclosureSearchUrl().length > 0) {
                        session.CheckPopUpBlock(session.foreclosureSearchUrl(), '_blank', null);
                        return;
                    }
                    break;
                case 'leadLocator':
                    if (session.leadLocatorUrl().length > 0) {
                        session.CheckPopUpBlock(session.leadLocatorUrl(), '_blank', null);
                        return;
                    }
                    break;
            }
            session.selectedProduct(type);
            router.navigate('#productRedirect');
        }


        function fetchSalesExecutiveInfo() {
            return https.get(config.getSalesExecutiveDetails)
                .then(function (data) {
                    if (data != null) {
                        salesExecModel.IsSalesExecutiveAvailable(true);
                        salesExecModel.FullName(data.FirstName + " " + data.LastName);
                        salesExecModel.Email(data.EmailAddress);
                        salesExecModel.Phone(data.PhoneWork);
                        salesExecModel.Address(data.StreetAddress1 + " " + data.StreetAddress2);
                        salesExecModel.City(data.City);
                        salesExecModel.Zip(data.Zip);
                        salesExecModel.PhotoUrl(data.PhotoUrl);
                        salesExecModel.UserType(data.UserType);
                        if (data.PhoneWorkExt) {
                            salesExecModel.PhoneExt(data.PhoneWorkExt);
                        }
                    }
                    else {
                        salesExecModel.IsSalesExecutiveAvailable(false);
                    }
                })
                .fail(function () {
                    salesExecModel.IsSalesExecutiveAvailable(false);
                });
        }


        function setupNav() {
            if (session) {
                if (session.brandId !== 4) {
                    var parent = document.getElementById('side-menu');
                    var product = document.getElementById("liProducts");
                    var property = document.getElementById("liProperty");
                    parent.removeChild(product);
                    parent.insertBefore(product, property);
                }
            }
        }


        function formatPhoneNo(phone, ext, extSym) {
            if (phone) {
                return utlities.getUSPhoneFormatWithExtn(phone, ext, extSym);
            }
            else
                return "Not Available";
        }
    });
