define('helper/utlities',['durandal/app', 'jquery', 'knockout', 'config', 'Q', 'plugins/http', 'global/session', 'alertNotification'],
    function (app, $, ko, config, Q, https, session, alert) {

        function getStatesList() {
            var url = config.stateUrl;
            return https.get(url, { format: 'json' })

        }

        function getStatesListWithStateId() {
            var url = config.getStatesListWithStateIdUrl;
            return https.get(url, { format: 'json' })
        }

        function getZipInfo(zip) {
            var url = config.getZipInfoUrl;
            return Q.when(https.get(url, {
                zipCode: zip,
                format: 'json'
            }));
        }
        function getCountyList(state) {
            var url = config.countyUrl;
            return Q.when(https.get(url, {
                stateAbbr: state,
                format: 'json'
            }));

        }

        function getCountyListWithCountyIdByStateId(stateId) {
            var url = config.getCountiesListWithCountyIdByStateIdUrl;
            return Q.when(https.get(url, {
                stateId: stateId,
                format: 'json'
            }));
        }
        function getOperationUserInfo(userId, operationId) {
            var url = config.getOperationUserInfoUrl;
            return https.get(url, {
                userId: userId,
                operationId: 0,
                format: 'json'
            })

        }

        function getEndorsements() {
            var url = "api/Site/GetEndorsements";
            return https.get(url, {})

        }

        function getEscrowLocations() {
            var url = "api/Orders/GetCoEscrowLocations";
            return https.get(url, {})

        }

        function getPolicyTypes() {
            var url = "api/site/GetPolicyTypes";
            return https.get(url, {})

        }

        function getDMKey() {
            var url = "api/Site/getDMKey";

            return https.get(url, {

            })


        }

        function getPhoneWithExtn(Phone, Extn, ExtnSymbol) {
            if (Extn.trim().length > 0) {
                if (ExtnSymbol.length > 0)
                    return Phone.trim() + ' ' + ExtnSymbol + Extn;
                else
                    return Phone.trim() + ' x' + ExtnSymbol + Extn;
            }
            else {
                return Phone.trim();
            }
        }

        function getUSPhoneFormat(phone) {
            if (phone) {
                //formatting with ext 3034436064x105
                if (phone.indexOf('x') != -1) {
                    var phoneWithExt = phone.split("x")
                    if (phoneWithExt[0] != null && phoneWithExt[1] != null) {
                        phone = phoneWithExt[0];
                        if (phone && phone.length == 10) {
                            var formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                            return formattedPhone + String.fromCharCode(160) + "ext." + String.fromCharCode(160) + phoneWithExt[1];
                        }
                    }
                }
                else if (phone.length == 10) {
                    var formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                    return formattedPhone;
                }
                else if (phone.length == 11) {
                    var formattedPhone = phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4")
                    return formattedPhone;
                }
                else {
                    return phone;
                }
            }
            return phone;
        }

        function getUSPhoneFormatWithoutExt(phone) {
            if (phone) {
                //formatting with ext 3034436064x105
                if (phone.indexOf('x') != -1) {
                    var phoneWithExt = phone.split("x")
                    if (phoneWithExt[0] != null) {
                        phone = phoneWithExt[0];
                        if (phone && phone.length == 10) {
                            var formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                            return formattedPhone + String.fromCharCode(160);
                        }
                    }
                }
                else if (phone.length == 10) {
                    var formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                    return formattedPhone;
                }
                else if (phone.length == 11) {
                    var formattedPhone = phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4")
                    return formattedPhone;
                }
                else {
                    return phone;
                }
            }
            return phone;
        }

    function appendPhoneWithExtn(phone, Extn, ExtnSymbol) {
        if (phone && phone.trim().length > 0) {
            if (Extn &&  Extn.trim().length > 0) {
                if (ExtnSymbol.length > 0)
                    return phone + ' ' + ExtnSymbol + Extn;
                else
                    return phone + ' x '  + Extn;
            }
            else {
                return phone;
            }
        }
        else {
            return phone;
        }
    }

        function getUSPhoneFormatWithExtn(phone, Extn, ExtnSymbol) {
            if (phone && phone.trim().length == 10) {
                var formattedPhone = phone.trim().replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                if (Extn && Extn.trim().length > 0) {
                    if (ExtnSymbol.length > 0)
                        return formattedPhone + ' ' + ExtnSymbol + Extn;
                    else
                        return formattedPhone + ' x' + ExtnSymbol + Extn;
                }
                else {
                    return formattedPhone;
                }
            }
            else {
                return '';
            }
        }


        function formatCurrency(value) {
            return "$" + value.toFixed(2);
        }


        function reportBooleanValue(value) {
            var result = 'No'
            if (value) {
                if (value === 'true' || value === true)
                result = 'Yes'
            }
            return result;
        }

        function unmaskPhoneNumber(phoneNumber) {
            var phone = phoneNumber();
            if (phone != undefined && phone != '') {
                return phone.replace(/[^\d\+]/g, '');
            }
            else
                return phone;
        }

        function getAddressString(Address, Zip, City, State) {
            var strAddress = '';
            if (Address && Address.length > 0)
                strAddress = Address + ' ';

            if (City && City.length > 0)
                strAddress = strAddress + City + ', ';

            if (State && State.length > 0)
                strAddress = strAddress + State + ' ';

            if (Zip && Zip.length > 0)
                strAddress = strAddress + Zip + ' ';

            return strAddress;
        }

        function getManagerFullName(OperationId, UserId) {
            var url = config.getUserManagerFullNameUrl;
            return https.get(url, {
                OperationId: OperationId, UserId: UserId
            })

        }

        // download file from filestream using byte array and filename.
        function downloadFileFromStream(fileName, fileBytes) {

            if (!fileBytes || fileBytes.length === 0) {
                console.error('No filebytes found');
                alert.warning('Cannot download this document.');
                return;
            }
            var arrayBuffer = base64ToUint8Array(fileBytes);
            //console.log('download called!');

            const blob = new Blob([arrayBuffer]);

            var wURL = window.URL || window.webkitURL;
            const url = wURL.createObjectURL(blob);

            const anchorElement = document.createElement('a');
            anchorElement.href = url;
            anchorElement.download = fileName;
            anchorElement.click();

            anchorElement.remove();
            wURL.revokeObjectURL(url);
        };

        // Convert base64 to Uint8Array
        function base64ToUint8Array(base64) {
            const binaryStr = window.atob(base64);
            const len = binaryStr.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryStr.charCodeAt(i);
            }
            return bytes;
        }

        // view file from filestream using byte array and filename.
        function viewFileFromStream(fileName, fileBytes) {

            if (!fileBytes || fileBytes.length === 0) {
                console.error('No filebytes found');
                alert.warning('Cannot download this document.');
                return;
            }
            var arrayBuffer = base64ToUint8Array(fileBytes);
            //console.log(arrayBuffer);

            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

            var wURL = window.URL || window.webkitURL;
            const url = wURL.createObjectURL(blob);

            window.open(url);

            //wURL.revokeObjectURL(url);
        };

        var vm = {
            getStatesList: getStatesList,
            getZipInfo: getZipInfo,
            getCountyList: getCountyList,
            getOperationUserInfo: getOperationUserInfo,
            getPhoneWithExtn: getPhoneWithExtn,
            formatCurrency: formatCurrency,
            getUSPhoneFormat: getUSPhoneFormat,
            getUSPhoneFormatWithExtn: getUSPhoneFormatWithExtn,
            getDMKey: getDMKey,
            getEndorsements: getEndorsements,
            getEscrowLocations: getEscrowLocations,
            getPolicyTypes: getPolicyTypes,
            unmaskPhoneNumber: unmaskPhoneNumber,
            getManagerFullName: getManagerFullName,
            getStatesListWithStateId: getStatesListWithStateId,
            getCountyListWithCountyIdByStateId: getCountyListWithCountyIdByStateId,
            reportBooleanValue: reportBooleanValue,
            appendPhoneWithExtn: appendPhoneWithExtn,
            getUSPhoneFormatWithoutExt: getUSPhoneFormatWithoutExt,
            getAddressString: getAddressString,
            downloadFileFromStream: downloadFileFromStream,
            viewFileFromStream: viewFileFromStream
        };
        return vm;

    });
