define('viewmodels/learning/learn',['plugins/router', 'knockout', 'durandal/app', 'plugins/http', 'global/session', 'config'],
    function(router, ko, app, https, session, config) {
        var orderTypes = ko.observableArray();
        var type = ko.observable();
        var vm = {
            activate: activate,
            canActivate: canActivate,
            canDeactivate: canDeactivate,
            compositionComplete: compositionComplete,
            orderTypes: orderTypes,
            type: type,
            addType: addType
        };
        function canActivate() {
            console.log('canActivate');
            return true;
        }
        function activate() {
            console.log('Activate');
            console.log(orderTypes().length);
            return  getOrderTypes();
        }

        function compositionComplete() {
            console.log('compositionComplete');
        }
        function canDeactivate() {
            console.log('canDeactivate');
            return true;
        }

        function getOrderTypes() {
            orderTypes([]);
            //orderTypes.push('Type1');
            //orderTypes.push('Type2');
            //orderTypes.push('Type3');
        }

        function addType() {
            var newType = type();
            orderTypes.push(newType);
            type('');
        }
        return vm;
    });
