define('models/order/bulkSaleOrder',["knockout", "modelMetaData"],
    function (ko, modelMetaData) {

        function Escrow() {
            var self = this;

            self.viewName = "orderModule/bulkSale/_escrow.html"
            self.title = "Escrow "
            self.ModelId = "escrow "
            self.index = ko.observable(-1)
            self.dateforCloseofEscrow = ko.observable("")
            self.closeEscrowDate = ko.observable("")
            self.companyName = ko.observable("")
            self.escrowNumber = ko.observable("")
            self.escrowOfficer = ko.observable("")
            self.bulksaleOnly = ko.observable("")
            self.liquorLicenseOnly = ko.observable("")
            self.bulkSaleLiquorLicense = ko.observable("")
            self.licenseType = ko.observable("")
            .extend({ required: { message: 'License Type is required.' } })
            self.licenseNumber = ko.observable("")
             .extend({ required: { message: 'License # is required.' } })
            self.sellerOrTransferor = ko.observable("")
             .extend({ required: { message: 'Seller/Transferor is required.' } })
            self.sellerOrTransferorAddress = ko.observable("")
                    .extend({ required: { message: 'Seller/Transferor Address is required.' } })
                    .extend({ maxLength: { params: 200, message: 'Seller/Transferor Address must be 200 characters or less.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });



            self.sellerTransferorCity = ko.observable(undefined)
            .extend({ required: { message: 'Seller/Transferor City is required.' } })
            self.sellerTransferorState = ko.observable(undefined)
             .extend({ required: { message: 'Seller/Transferor State is required.' } })
            self.sellerOrTransferorZip = ko.observable("")
                .extend({ required: { message: 'Seller/Transferor Zip is required.' } })
                .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })


            self.sellerOrTransferorExt = ko.observable("")
            .extend({ digit: true });

            self.sellerOrTransferorPhone = ko.observable("")
            .extend({
                required: {
                    message: "Seller/Transferor Phone is required",
                    onlyIf: function () {
                        return self.sellerOrTransferorExt().length > 0;
                    }
                }
            })
            .extend({ phoneUS: true })


            self.sellerOrTransferorFax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });



            self.update = function (data) {
                if (data) {
                    self.dateforCloseofEscrow(data.dateforCloseofEscrow);
                    self.companyName(data.companyName);
                    self.escrowNumber(data.escrowNumber);
                    self.escrowOfficer(data.escrowOfficer);
                    self.bulksaleOnly(data.bulksaleOnly);
                    self.liquorLicenseOnly(data.liquorLicenseOnly);
                    self.bulkSaleLiquorLicense(data.bulkSaleLiquorLicense);
                    self.licenseType(data.licenseType);
                    self.licenseNumber(data.licenseNumber);
                    self.sellerOrTransferor(data.sellerOrTransferor);
                    self.sellerOrTransferorAddress(data.sellerOrTransferorAddress);
                    self.sellerTransferorCity(data.sellerTransferorCity);
                    self.sellerTransferorState(data.sellerTransferorState);
                    self.sellerOrTransferorZip(data.sellerOrTransferorZip);
                    self.sellerOrTransferorPhone(data.sellerOrTransferorPhone);
                    self.sellerOrTransferorExt(data.sellerOrTransferorExt);
                    self.sellerOrTransferorFax(data.sellerOrTransferorFax);
                }

            };

            self.reset = function () {
                self.dateforCloseofEscrow("");
                self.companyName("");
                self.escrowNumber("");
                self.escrowOfficer("");
                self.bulksaleOnly("");
                self.liquorLicenseOnly("");
                self.bulkSaleLiquorLicense("");
                self.licenseType("");
                self.licenseNumber("");
                self.sellerOrTransferor("");
                self.sellerOrTransferorAddress("");
                self.sellerTransferorCity(undefined);
                self.sellerTransferorState(undefined);
                self.sellerOrTransferorZip("");
                self.sellerOrTransferorPhone("");
                self.sellerOrTransferorExt("");
                self.sellerOrTransferorFax("");
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Date for Close of Escrow", self.closeEscrowDate()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Company Name", self.companyName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Escrow #", self.escrowNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Bulk Sale and Liquor License", modelMetaData.reportBooleanValue(self.bulkSaleLiquorLicense())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Bulk Sale Only", modelMetaData.reportBooleanValue(self.bulksaleOnly())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Liquor License Only", modelMetaData.reportBooleanValue(self.liquorLicenseOnly())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Escrow Officer", self.escrowOfficer()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("License Type", self.licenseType()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("License #", self.licenseNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Transferor", self.sellerOrTransferor()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Transferor Address", self.sellerOrTransferorAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Transferor Zip", self.sellerOrTransferorZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Transferor City", self.sellerTransferorCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Transferor State", self.sellerTransferorState()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Transferor Phone", modelMetaData.appendPhoneWithExtn(self.sellerOrTransferorPhone(), self.sellerOrTransferorExt(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller/Transferor Fax", self.sellerOrTransferorFax()));

            }

        }
        function ListingBroker() {
            var self = this;

            self.viewName = "orderModule/bulkSale/_listingBroker.html"
            self.title = "Listing Broker"
            self.ModelId = "listingBroker"
            self.index = ko.observable(-1)

            self.listingBroker = ko.observable("")
            self.listingBrokerAddress = ko.observable("")
             .extend({
                 maxLength: {
                     params: 200, message: 'Listing Broker Address must be 200 characters or less'
                 }
             })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.listingBrokerCity = ko.observable(undefined)
            self.listingBrokerState = ko.observable(undefined)
            self.listingBrokerZip = ko.observable("")

            self.ext = ko.observable("")
            .extend({ digit: true });

            self.listingBrokerPhone = ko.observable("")
            .extend({
                required: {
                    message: "Listing Broker Phone is required",
                    onlyIf: function () {
                        return self.ext().length > 0;
                    }
                }
            })
            .extend({ phoneUS: true })

            self.listingBrokerFax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });

            self.update = function (data) {
                if (data) {
                    self.listingBroker(data.listingBroker)
                    self.listingBrokerAddress(data.listingBrokerAddress)
                    self.listingBrokerCity(data.listingBrokerCity)
                    self.listingBrokerState(data.listingBrokerState)
                    self.listingBrokerZip(data.listingBrokerZip)
                    self.listingBrokerPhone(data.listingBrokerPhone)
                    self.ext(data.ext)
                    self.listingBrokerFax(data.listingBrokerFax)
                }

            };

            self.reset = function () {
                self.listingBroker("")
                self.listingBrokerAddress("")
                self.listingBrokerCity(undefined)
                self.listingBrokerState(undefined)
                self.listingBrokerZip("")
                self.listingBrokerPhone("")
                self.ext("")
                self.listingBrokerFax("")
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Broker", self.listingBroker()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Broker Address", self.listingBrokerAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Broker Zip", self.listingBrokerZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Broker City", self.listingBrokerCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Broker State", self.listingBrokerState()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Broker Phone", modelMetaData.appendPhoneWithExtn(self.listingBrokerPhone(), self.ext(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Broker Fax", self.listingBrokerFax()));
            }
       
        }
        function BuyerOrTransferee() {
            var self = this;

            self.viewName = "orderModule/bulkSale/_buyerOrTransferee.html"
            self.title = "Buyer/Transferee"
            self.ModelId = "buyerTransferee"
            self.index = ko.observable(-1)

            self.buyerOrTransferee = ko.observable("")
            self.buyerOrTransfereeAddress = ko.observable("")
            .extend({
                maxLength: {
                    params: 200, message: 'Buyer/Transferee Address must be 200 characters or less'
                }
            })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.buyerOrTransfereeCity = ko.observable(undefined)
            self.buyerOrTransfereeState = ko.observable(undefined)
            self.buyerOrTransfereeZip = ko.observable("")

            self.ext = ko.observable("")
            .extend({ digit: true });

            self.buyerOrTransfereePhone = ko.observable("")
            .extend({
                required: {
                    message: "Buyer/Transferee Phone is required",
                    onlyIf: function () {
                        return self.ext().length > 0;
                    }
                }
            })
            .extend({ phoneUS: true })

            self.buyerOrTransfereeFax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });

            self.update = function (data) {
                if (data) {
                    self.buyerOrTransferee(data.buyerOrTransferee)
                    self.buyerOrTransfereeAddress(data.buyerOrTransfereeAddress)
                    self.buyerOrTransfereeCity(data.buyerOrTransfereeCity)
                    self.buyerOrTransfereeState(data.buyerOrTransfereeState)
                    self.buyerOrTransfereeZip(data.buyerOrTransfereeZip)
                    self.buyerOrTransfereePhone(data.buyerOrTransfereePhone)
                    self.ext(data.ext)
                    self.buyerOrTransfereeFax(data.buyerOrTransfereeFax)
                }

            };

            self.reset = function () {
                self.buyerOrTransferee("")
                self.buyerOrTransfereeAddress("")
                self.buyerOrTransfereeCity(undefined)
                self.buyerOrTransfereeState(undefined)
                self.buyerOrTransfereeZip("")
                self.buyerOrTransfereePhone("")
                self.ext("")
                self.buyerOrTransfereeFax("")
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Transferee", self.buyerOrTransferee()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Transferee Address", self.buyerOrTransfereeAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Transferee Zip", self.buyerOrTransfereeZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Transferee City", self.buyerOrTransfereeCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Transferee State", self.buyerOrTransfereeState()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Transferee Phone", modelMetaData.appendPhoneWithExtn(self.buyerOrTransfereePhone(), self.ext(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer/Transferee Fax", self.buyerOrTransfereeFax()));
            }
        }
        function SellingBroker() {
            var self = this;

            self.viewName = "orderModule/bulkSale/_sellingBroker.html"
            self.title = "Selling Broker "
            self.ModelId = "sellingBroker "
            self.index = ko.observable(-1)

            self.sellingBroker = ko.observable("")
            self.sellingBrokerAddress = ko.observable("")
            .extend({
                maxLength: {
                    params: 200, message: 'Selling Broker Address must be 200 characters or less'
                }
            })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.sellingBrokerCity = ko.observable(undefined)
            self.sellingBrokerState = ko.observable(undefined)
            self.sellingBrokerZip = ko.observable("")

            self.ext = ko.observable("")
            .extend({ digit: true });

            self.sellingBrokerPhone = ko.observable("")
            .extend({
                required: {
                    message: "Selling Broker Phone is required",
                    onlyIf: function () {
                        return self.ext().length > 0;
                    }
                }
            })
        .extend({ phoneUS: true })





            self.sellingBrokerFax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });

            self.nameofBusiness = ko.observable("")
            self.typeofBusiness = ko.observable("")
            self.businessAddress = ko.observable("")
            .extend({
                maxLength: {
                    params: 200, message: 'Business Address must be 200 characters or less'
                }
            })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.businessCity = ko.observable(undefined)
            self.businessState = ko.observable(undefined)
            self.businessZip = ko.observable("")
            // Address of Chief Executive Office if different from above
            self.chiefExecutiveOfficeAddress = ko.observable("")
            .extend({
                maxLength: {
                    params: 200, message: 'Address of Chief Executive Office must be 200 characters or less'
                }
            })
        .extend({
            pattern: {
                message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
            }
        });
            self.chiefExecutiveOfficeCity = ko.observable(undefined)
            self.chiefExecutiveOfficeState = ko.observable(undefined)
            self.chiefExecutiveOfficeZip = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.sellingBroker(data.sellingBroker)
                    self.sellingBrokerAddress(data.sellingBrokerAddress)
                    self.sellingBrokerCity(data.sellingBrokerCity)
                    self.sellingBrokerState(data.sellingBrokerState)
                    self.sellingBrokerZip(data.sellingBrokerZip)
                    self.ext(data.ext)
                    self.sellingBrokerPhone(data.sellingBrokerPhone)
                    self.sellingBrokerFax(data.sellingBrokerFax)
                    self.nameofBusiness(data.nameofBusiness)
                    self.typeofBusiness(data.typeofBusiness)
                    self.businessAddress(data.businessAddress)
                    self.businessCity(data.businessCity)
                    self.businessState(data.businessState)
                    self.businessZip(data.businessZip)
                    // Address of Chief Executive Office if different from above
                    self.chiefExecutiveOfficeAddress(data.chiefExecutiveOfficeAddress)
                    self.chiefExecutiveOfficeCity(data.chiefExecutiveOfficeCity)
                    self.chiefExecutiveOfficeState(data.chiefExecutiveOfficeState)
                    self.chiefExecutiveOfficeZip(data.chiefExecutiveOfficeZip)
                }

            };

            self.reset = function () {
                self.sellingBroker("")
                self.sellingBrokerAddress("")
                self.sellingBrokerCity(undefined)
                self.sellingBrokerState(undefined)
                self.sellingBrokerZip("")
                self.sellingBrokerPhone("")
                self.ext("")
                self.sellingBrokerFax("")
                self.nameofBusiness("")
                self.typeofBusiness("")
                self.businessAddress(undefined)
                self.businessCity(undefined)
                self.businessState("")
                self.businessZip("")
                // Address of Chief Executive Office if different from above
                self.chiefExecutiveOfficeAddress("")
                self.chiefExecutiveOfficeCity(undefined)
                self.chiefExecutiveOfficeState(undefined)
                self.chiefExecutiveOfficeZip("")
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Broker", self.sellingBroker()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Broker Address", self.sellingBrokerAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Broker Zip", self.sellingBrokerZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Broker City", self.sellingBrokerCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Broker State", self.sellingBrokerState()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Broker Phone", modelMetaData.appendPhoneWithExtn(self.sellingBrokerPhone(), self.ext(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Broker Fax", self.sellingBrokerFax()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Name of Business", self.nameofBusiness()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Type of Business", self.typeofBusiness()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Business Address", self.businessAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Business Zip", self.businessZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Business City", self.businessCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Business State", self.businessState()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Address of Chief Executive Office if different from above", self.chiefExecutiveOfficeAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Chief Executive Office Zip", self.chiefExecutiveOfficeZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Chief Executive Office City", self.chiefExecutiveOfficeCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Chief Executive Office State", self.chiefExecutiveOfficeState()));
            }
        }
        function Consideration() {
            var self = this;

            self.viewName = "orderModule/bulkSale/_consideration.html"
            self.title = "Consideration"
            self.ModelId = "consideration"
            self.index = ko.observable(-1)

            self.initialDeposit = ko.observable("")
           .extend({
               pattern: {
                   params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                   message: 'Invalid Initial Deposit format. (format: ####.##)'
               }
           });

            self.amountPriortoclose = ko.observable("")
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Amount prior to close format. (format: ####.##)'
                }
            });

            self.noteSecurityAgreement = ko.observable("")
            self.noteSecurityAgreement2 = ko.observable("")
            self.existingLoan = ko.observable("")
            self.other = ko.observable("")
            self.other1 = ko.observable("")
            self.employmentDevelopmentDeptNumber = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.initialDeposit(data.initialDeposit)
                    self.amountPriortoclose(data.amountPriortoclose)
                    self.noteSecurityAgreement(data.noteSecurityAgreement)
                    self.noteSecurityAgreement2(data.noteSecurityAgreement2)
                    self.existingLoan(data.existingLoan)
                    self.Other(data.other)
                    self.Other1(data.other1)
                    self.employmentDevelopmentDeptNumber(data.employmentDevelopmentDeptNumber)
                }

            };

            self.reset = function () {
                self.initialDeposit("")
                self.amountPriortoclose("")
                self.noteSecurityAgreement("")
                self.noteSecurityAgreement2("")
                self.existingLoan("")
                self.other("")
                self.other1("")
                self.employmentDevelopmentDeptNumber("")
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Initial Deposit. ($)", self.initialDeposit()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Amount prior to close. ($)", self.amountPriortoclose()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Note and Security Agreement", self.noteSecurityAgreement()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Note and Security Agreement 2", self.noteSecurityAgreement2()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Existing Loan", self.existingLoan()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other", self.other()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other", self.other1()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Employment Development Dept #", self.employmentDevelopmentDeptNumber()));
            }
        }
        function Allocation() {
            var self = this;

            self.viewName = "orderModule/bulkSale/_allocation.html"
            self.title = "Allocation"
            self.ModelId = "allocation"
            self.index = ko.observable(-1)

            self.fixturesandEquipment = ko.observable("")
            self.leaseholdInterest = ko.observable("")
            self.goodwill = ko.observable("")
            self.inventory = ko.observable("")
            self.leaseholdImprovement = ko.observable("")
            self.covenant = ko.observable("")
            self.other = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.fixturesandEquipment(data.fixturesandEquipment)
                    self.leaseholdInterest(data.leaseholdInterest)
                    self.goodwill(data.goodwill)
                    self.inventory(data.inventory)
                    self.leaseholdImprovement(data.leaseholdImprovement)
                    self.covenant(data.covenant)
                    self.other(data.Other)
                }

            };

            self.reset = function () {
                self.fixturesandEquipment("")
                self.leaseholdInterest("")
                self.goodwill("")
                self.inventory("")
                self.leaseholdImprovement("")
                self.covenant("")
                self.other("")
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Fixtures and Equipment", self.fixturesandEquipment()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Leasehold Interest", self.leaseholdInterest()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Goodwill", self.goodwill()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Inventory", self.inventory()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Leasehold Improvement", self.leaseholdImprovement()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Covenant", self.covenant()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other", self.other()));
            }
        }
        function NoteandSecurityAgreement() {
            var self = this;

            self.viewName = "orderModule/bulkSale/_noteSecurityAgreement.html"
            self.title = "Note and Security Agreement"
            self.ModelId = "NoteandSecurityAgreement"
            self.index = ko.observable(-1)

            //AgreementType is caption
            self.existing = ko.observable(false)
            self.new = ko.observable(false)
            self.agreementType = ko.observable("");
            self.isAgreementTypeNew = ko.observable(false);
            self.isAgreementTypeExisting = ko.observable(false);
            self.ifNewinfavorof = ko.observable("")
            .extend({
                required: {
                    message: "In favor of is required for New Note and Security Agreement.",
                    onlyIf: function () {
                        return self.isAgreementTypeNew();
                    }
                }
            });
            self.amount = ko.observable("")
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Amount format. (format: ####.##)'
                }
            });

            self.interestRate = ko.observable("")
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Interest Rate. (format: ####.##)'
                }
            });
            self.paymentDue = ko.observable("")
            self.paymentDueList = ko.observableArray([
                                       { text: "Monthly", value: "Monthly" },
                                       { text: "Quarterly", value: "Quarterly" },
                                       { text: "Annually", value: "Annually" }
            ]);


            self.monthly = ko.observable(false)
            self.quarterly = ko.observable(false)
            self.annually = ko.observable(false)

            self.beginning = ko.observable("")

            self.allDue = ko.observable("")
            .extend({
                min: {
                    params: self.beginning,
                    message: 'Beginning Date can not be later than the All Due Date.'
                }
            })

            self.paymentAmount = ko.observable("")
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Payment Amount. (format: ####.##)'
                }
            });
            self.paymentType = ko.observable("")
            //self.paymentTypeList = ko.observableArray([
            //                           { text: "Payment Type", value: "Payment Type" },
            //                           { text: "Principal Only", value: "PrincipalOnly" },
            //                           { text: "P & I", value: "PI" },
            //]);

            self.principalOnly = ko.observable("")
            self.pi = ko.observable("")
            self.additionalTerms = ko.observable("")
                .extend({
                    maxLength: {
                        params: 200, message: 'Additional Terms must be 200 characters or less'
                    }
                })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });

            self.update = function (data) {
                if (data) {
                    self.existing(data.existing)
                    self.new(data.new)
                    self.ifNewinfavorof(data.ifNewinfavorof)
                    self.amount(data.amount)
                    interestRate(data.interestRate)
                    self.monthly(data.monthly)
                    self.quarterly(data.quarterly)
                    self.annually(data.annually)
                    self.beginning(data.beginning)
                    self.allDue(data.allDue)
                    self.paymentAmount(data.paymentAmount)
                    self.principalOnly(data.principalOnly)
                    self.pi(data.pi)
                    self.additionalTerms(data.additionalTerms)

                }

            };

            self.reset = function () {
                self.existing("")
                self.new("")
                self.isAgreementTypeNew(false)
                self.isAgreementTypeExisting(false)
                self.agreementType("")
                self.ifNewinfavorof("")
                self.amount("")
                self.interestRate("")
                self.monthly("")
                self.quarterly("")
                self.annually("")
                self.beginning("")
                self.allDue("")
                self.paymentAmount("")
                self.principalOnly("")
                self.pi("")
                self.additionalTerms("")
                self.paymentDue("")
                self.paymentType("")
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Agreement Type", self.agreementType()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("If New, in favor of", self.ifNewinfavorof()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Amount. ($)", self.amount()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Interest Rate. (%)", self.interestRate()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Payment Due", self.paymentDue()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Beginning Date", self.beginning()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("All Due Date", self.allDue()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Payment Amount. ($)", self.paymentAmount()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Payment Type", self.paymentType()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Additional Terms", self.additionalTerms()));
            }
        }
        function Prorateasof() {
            var self = this;

            self.viewName = "orderModule/bulkSale/_prorateasof.html"
            self.title = "Prorate as of"
            self.ModelId = "prorateasof"
            self.index = ko.observable(-1)

            self.prorateasof = ko.observable("")
            //self.prorateasofList = ko.observableArray([
            //                           { text: "Prorate as of", value: "Prorate as of" },
            //                           { text: "Close of Escrow", value: "CloseofEscrow" },
            //                           { text: "Date of possession", value: "Dateofpossession" },
                                      
            //]);

            self.closeofEscrow = ko.observable("")
            self.dateofPossession = ko.observable("")

            self.other = ko.observable("").extend({
                maxLength: {
                    params: 200, message: 'Other must be 200 characters or less'
                }
            })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.personalPropertyTaxes = ko.observable("")
            self.leasePayments = ko.observable("")
            self.rents = ko.observable("")
            self.interestOnLoan = ko.observable("")
            self.other1 = ko.observable("")
            self.other2 = ko.observable("")
            //Seller will be credited with any prepaid rents or security deposits
            self.sellerCredited = ko.observable("")
            self.yes = ko.observable("")
            self.no = ko.observable("")
            self.rentispaidTo = ko.observable("")
            self.insuranceAgent = ko.observable("")

            self.ext = ko.observable("")
            .extend({ digit: true });

            self.insuranceAgentPhone = ko.observable("")
            .extend({
                required: {
                    message: "Insurance Agent Phone is required",
                    onlyIf: function () {
                        return self.ext().length > 0;
                    }
                }
            })
            .extend({ phoneUS: true })

            self.existingNoteHolder = ko.observable("")
            self.noteHolderAddress = ko.observable("")
            .extend({
                maxLength: {
                    params: 200, message: "Note Holder's Address must be 200 characters or less"
                }
            })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                    }
                });
            self.noteHolderCity = ko.observable(undefined)
            self.noteHolderState = ko.observable(undefined)
            self.noteHolderZip = ko.observable("")
            self.existingContractstobeassumed = ko.observable("")
            .extend({
                maxLength: {
                    params: 200, message: 'Existing Contracts to be assumed must be 200 characters or less'
                }
            })
                      .extend({
                          pattern: {
                              message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                              params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                          }
                      });

            self.update = function (data) {
                if (data) {
                    self.closeofEscrow(data.closeofEscrow)
                    self.dateofPossession(data.closeofEscrow)
                    self.other(data.closeofEscrow)
                    self.personalPropertyTaxes(data.closeofEscrow)
                    self.leasePayments(data.closeofEscrow)
                    self.rents(data.closeofEscrow)
                    self.interestOnLoan(data.closeofEscrow)
                    self.other1(data.closeofEscrow)
                    self.other2(data.closeofEscrow)
                    //Seller will be credited with any prepaid rents or security deposits
                    self.yes(data.closeofEscrow)
                    self.no(data.closeofEscrow)
                    self.rentispaidTo(data.closeofEscrow)
                    self.insuranceAgent(data.closeofEscrow)
                    self.insuranceAgentPhone(data.closeofEscrow)
                    self.ext(data.closeofEscrow)
                    self.existingNoteHolder(data.closeofEscrow)
                    self.noteHolderAddress(data.closeofEscrow)
                    self.noteHolderCity(data.closeofEscrow)
                    self.noteHolderState(data.closeofEscrow)
                    self.noteHolderZip(data.closeofEscrow)
                    self.existingContractstobeassumed(data.closeofEscrow)
                }

            };

            self.reset = function () {
                self.closeofEscrow('')
                self.dateofPossession('')
                self.other('')
                self.personalPropertyTaxes('')
                self.leasePayments('')
                self.rents('')
                self.interestOnLoan('')
                self.other1('')
                self.other2('')
                //Seller will be credited with any prepaid rents or security deposits
                self.yes('')
                self.no('')
                self.rentispaidTo('')
                self.insuranceAgent('')
                self.insuranceAgentPhone('')
                self.ext('')
                self.existingNoteHolder('')
                self.noteHolderAddress('')
                self.noteHolderCity(undefined)
                self.noteHolderState(undefined)
                self.noteHolderZip('')
                self.existingContractstobeassumed('')
                self.prorateasof('')
                self.sellerCredited('')

                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Prorate as of", self.prorateasof()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other", self.other()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Personal Property Taxes", self.personalPropertyTaxes()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Lease payments", self.leasePayments()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Rents", self.rents()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Interest on loan", self.interestOnLoan()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other", self.other1()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other", self.other2()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller will be credited with any prepaid rents or security deposits", self.sellerCredited()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Rent is paid to", self.rentispaidTo()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Insurance Agent", self.insuranceAgent()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Insurance Agent Phone", modelMetaData.appendPhoneWithExtn(self.insuranceAgentPhone(), self.ext(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Existing Note Holder", self.existingNoteHolder()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Note Holder's Address", self.noteHolderAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Note Holder's Zip", self.noteHolderZip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Note Holder's City", self.noteHolderCity()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Note Holder's State", self.noteHolderState()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Existing Contracts to be assumed", self.existingContractstobeassumed()));
            }
        }
        var vm = {
            Escrow: Escrow,
            ListingBroker: ListingBroker,
            BuyerOrTransferee: BuyerOrTransferee,
            SellingBroker: SellingBroker,
            Consideration: Consideration,
            Allocation: Allocation,
            NoteandSecurityAgreement: NoteandSecurityAgreement,
            Prorateasof: Prorateasof
        }

        return vm;
    });
