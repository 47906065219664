define('viewmodels/userModule/login',['plugins/router', 'durandal/app', 'services/security', 'global/session', 'services/logger', 'jquery', 'knockout', 'loginModel', 'knockout.validation', 'plugins/http', 'jquerycapslock', 'config', 'shell', 'register', 'header', 'siteAnnouncementModel', 'pendingApprovalService', 'alertNotification'],
    function (router, app, security, session, logger, $, ko, loginModel, validation, https, jquerycapslock, config, shell, registerModule, header, siteAnnouncementModel, pendingApprovalService, alerter) {
        var loginModel = new loginModel.login();
        var announcementsList = ko.observable([]);
        var pendingApprovals = ko.observable([]);
        var carouselView = ko.observable('AlamoCarousel.html');
        var sublinkData = ko.observable();
        var subLinkDatadivClass = ko.observable(0);
        var orderId = ko.observable();
        var loginError = ko.observable('');
        var isApproved = ko.observable('');
        var userInfo;
        var userSecurityInfo;
        var isNewLLAEntry;
        var showSiteAnnouncements = ko.observable(false);
        var isLoggedIn = ko.observable(false);
        var oktaSignIn = null;
        
        var errorMessage = sessionStorage.getItem('errorKey');

        if (errorMessage != null && errorMessage != undefined) {
            showRedirectError(errorMessage);
        }

        var subLinkDataCSS = ko.computed(function () {
            switch (subLinkDatadivClass()) {
                case 4:
                    return 'col-6 col-sm-6 col-md-3';
                case 3:
                    return 'col-6 col-sm-4';
                case 2:
                    return 'col-6 col-sm-6';
            }
        });

        var brandServicesName = ko.computed(function () {
            var brandId = $('.currentBrand').val();
            switch (parseInt(brandId)) {
                case 4:
                    return 'Chicago Title Premier Services';
                case 1:
                    return 'Security Union OneSource';
                case 2:
                    return 'Ticor Title Express';
                case 3:
                    return 'Fidelity Passport';
                case 5:
                    return 'Alamo Title Advantage';
                case 7:
                    return 'Global Premier Services';
                case 12:
                    return 'Grand Canyon Title Agency';
                case 15:
                    return 'Commonwealth Land Title';
                case 16:
                    return 'Lawyers Title';
                case 19:
                    return 'Security Title Gateway';
                case 21:
                    return 'Austin Title Access';
                case 23:
                    return 'Western Title Profile';
                case 24:
                    return 'Dane County Title All Access';
                case 26:
                    return 'Global Premier Services';
                case 27:
                    return 'Title Guaranty All Access';
                case 28:
                    return 'Heritage Title Company';
            }
        });

        var carouselFullPath = ko.computed(function () {
            return 'carousel/' + carouselView();
        }, this);

        // Internal properties and functions
        function ExternalLoginProviderViewModel(data) {
            var self = this;

            // Data
            self.name = ko.observable(data.name);

            // Operations
            self.login = function () {
                sessionStorage["state"] = data.state;
                sessionStorage["loginUrl"] = data.url;
                sessionStorage["externalLogin"] = true;
                // IE doesn't reliably persist sessionStorage when navigating to another URL. Move sessionStorage temporarily
                // to localStorage to work around this problem.
                session.archiveSessionStorageToLocalStorage();
                window.location = data.url;
            };
        }

        function reset() {

            session.clearAccessToken();
            session.clearACSFToken();
            vm.loginModel.userName("");
            vm.loginModel.password("");
            vm.loginModel.rememberMe(false);
            vm.setFocus(true);
            vm.validationErrors.showAllMessages(false);
            loginError('');
        }


        function resetControls() {
            $('#LoginUserName').val('');
            $('#LoginPassword').val('');
            vm.loginModel.userName("");
            vm.loginModel.password("");
        }

        // Reveal the bindable properties and functions
        var vm = {
            activate: activate,
            bindingComplete: configOKTA,
            canActivate: canActivate,
            deactivate: resetControls,
            canDeactivate: canDeactivate,
            goBack: goBack,
            title: 'login',
            session: session,
            setFocus: ko.observable(true),
            loginModel: loginModel,
            brands: [new Brand('Chicago Title', '1'), new Brand('Ticor Title', '2')],
            brand: ko.observable(),
            externalLoginProviders: ko.observableArray(),
            loaded: false,
            login: login,
            oktaLogin: oktaLogin,
            register: register,
            attached: AfterLoad,
            forgotPassword: forgotPassword,
            password_mouseout: password_mouseout,
            forgotpassword_mouseout: forgotpassword_mouseout,
            handleKeyUp: handleKeyUp,
            compositionComplete: BindCarousel,
            carouselFullPath: carouselFullPath,
            sublinkData: sublinkData,
            loginError: loginError,
            onBlurEmail: onBlurEmail,
            isApproved: isApproved,
            orderId: orderId,
            LLAAccepted: LLAAccepted,
            LLANotAccepted: LLANotAccepted,
            getPasswordChangeAlert: getPasswordChangeAlert,
            updateUserLastActivity: updateUserLastActivity,
            subLinkDataCSS: subLinkDataCSS,
            displayAnnouncements: displayAnnouncements,
            //announcementModel: announcementModel,
            announcementsList: announcementsList,
            showAnnouncementsModal: showAnnouncementsModal,
            showSiteAnnouncements: showSiteAnnouncements,
            pendingApprovals: pendingApprovals,
            session: session,
            showError: showError,
            brandServicesName: brandServicesName



        };

        vm.validationErrors = ko.validation.group([vm.loginModel.userName, vm.loginModel.password]);
        vm.hasExternalLogin = ko.computed(function () {
            return vm.externalLoginProviders().length > 0;
        });

        return vm;

        function onBlurEmail() {
            loginError('');
        }

        function handleKeyUp(d, e) {
            if (e.keyCode == 13) {
                login();
            }
        }

        function Brand(name, id) {
            this.name = name;
            this.id = id;
        };

        function password_mouseout() {
            $('.loginPassword').trigger("blur");
        }

        function forgotpassword_mouseout() {
            $('.forgotPasswordlnk').trigger("blur");
        }

        function getLoginSubLinks() {

            //console.log('getLoginSubLinks() called');
            // console.log('sublinkData length ' + sublinkData.length);
            https.get('durandal/GetSubLinks').done(function (data) {
                //console.log(data);
                sublinkData(data);
                subLinkDatadivClass(data.divClass);
                try {
                    var element = $('#loginSublinks')[0];
                    ko.applyBindings(sublinkData(), element);
                    // console.log(sublinkData().Links);
                }
                catch (x) { }
            });

        }

        function attached() {
            //var img = $('.logo').css('background-image');
            //$("#imgBrandLogo").attr('src', img);
        }
        function canDeactivate() {
            var isCanDeactivate = true;
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, "", window.location.href);
            };

            if (isLoggedIn() && session.useOktaAuthentication() == false) {

                getPasswordChangeAlert().done(function (result) {
                    if (result == true) {
                        session.isLogoutVisible(false);
                        var title = 'Password Policy Alert';
                        var msg = 'Your password is more than 90 days old. For security reasons we strongly recommend to change the password immediately. Would you like to change the password now?';
                        return app.showMessage(msg, title, ['Yes', 'No'], true)
                            .then(function (option) {
                                if (option === 'Yes') {
                                    router.navigate('security', { replace: true, trigger: true });
                                }
                                if (option === 'No') {
                                    var url = config.updatePasswordHistoryUrl
                                    https.post(url)
                                        .then(function (data) {
                                            if (data.HasErrors == false) {

                                            }
                                            else {
                                                alert.error("Internal Error. Please contact Administrator.");
                                            }
                                        })
                                        .fail(function (data) {
                                            var error = JSON.parse(data.responseText)
                                            console.log(error);
                                            alert.error("Internal Error. Please contact Administrator.");
                                        })
                                }
                                session.isLogoutVisible(true);
                            });
                    }
                });



                if (session.userTypeIsEmployee()) {
                    session.pendingApprovals([]);
                    pendingApprovalService.checkPendingApproval().then(function () {
                        if (pendingApprovalService.arePendingApprovals()) {
                            session.pendingApprovals(pendingApprovalService.pendingApprovals());
                            return app.showDialog('viewmodels/administration/viewPendingApprovals', session);
                        }
                    }
                    );
                }

            }


            console.log("browser : " + session.browser());
            if (session.browser() !== 'IE' && session.isSalesRepCreatedFirstTimeLogin() === false) {
                if (session.isTCPAConsentTrackingEnabledForDefaultOperation() && session.isCustomer()) {
                    isTCPARequiredFromRemoteServer();
                }
            }



            return isCanDeactivate;
        }

        function canActivate(FirstParam, SecondParam) {
            var urlCurrent = window.location.href;
            var isOrderMode = false;

            if (urlCurrent.indexOf("heritage") > 0) {
                router.navigate('#acceptancella');
                return false;
            }

            session.getOktaAuthenticationEnabled();

            if (window.location.href == window.location.origin + "/#login") {
                window.location.href = window.location.origin
            }



            //session.mode('');
            var OrderId, Mode;
            if (FirstParam && SecondParam) {
                OrderId = FirstParam;
                Mode = SecondParam;
            }
            else {
                if (FirstParam != null && FirstParam != undefined) {
                    var mode = "";
                    mode = FirstParam;
                    if (mode.toLowerCase() === "orders") {
                        session.mode('orders');
                        if (session.isLoggedIn()) {
                            return { redirect: '#orders' };
                        }
                    }
                }
            }

            var counter = parseInt(sessionStorage["testCount"]);
            sessionStorage["testCount"] = counter.toString() == "NaN" ? 1 : counter + 1;
            if (counter > 2) {
                return true;
            }

            if (OrderId != undefined && Mode != '') {
                isOrderMode = true;
                session.orderId(OrderId);
                session.mode(Mode);
                var token = localStorage["accessToken"];
                //console.log('token :' + token);
                if (token != undefined && token != '' && token != null) {
                    var tokenExpiresOn = localStorage["accessTokenExpiresOn"];
                    //console.log('tokenExpiresOn :' + tokenExpiresOn);
                    if (tokenExpiresOn != undefined && tokenExpiresOn != '' && tokenExpiresOn != null) {
                        var now = (new Date()).getTime();
                        if (now < tokenExpiresOn) {
                            if (Mode == 'offline')
                                return { redirect: '#viewOfflineOrderDetail' + '/' + OrderId };
                            else if (Mode == 'online')
                                return { redirect: '#viewOrders' + '/' + OrderId };
                            else if (Mode === 'orders') {
                                session.profileId(OrderId);
                                sessionStorage['sessionFromProfile'] = 'true';
                                return { redirect: '#orders' };
                            }
                            else if (Mode == 'value' || Mode == 'valuec') {
                                session.payResponseId(OrderId);
                                session.payResponseAction(Mode);
                                router.navigate('#valuecheckcallback');
                            }
                            else if (Mode == 'core' || Mode == 'corec') {
                                session.payResponseId(OrderId);
                                session.payResponseAction(Mode);
                                return { redirect: '#corecallback' + '/' + OrderId };
                            }
                        }
                    }
                }
                if (session.isLoggedIn() == true) {
                    session.payResponseId('');
                    session.payResponseAction('');
                    if (Mode == 'offline')
                        return { redirect: '#viewOfflineOrderDetail' + '/' + OrderId };
                    else if (Mode == 'online')
                        return { redirect: '#viewOrders' + '/' + OrderId };
                    else if (Mode === 'orders') {
                        session.profileId(OrderId);
                        sessionStorage['sessionFromProfile'] = 'true';
                        return { redirect: '#orders' };
                    }
                    else if (Mode == 'value' || Mode == 'valuec') {
                        session.payResponseId(OrderId);
                        session.payResponseAction(Mode);
                        router.navigate('#valuecheckcallback');
                    }
                    else if (Mode == 'core' || Mode == 'corec') {
                        session.payResponseId(OrderId);
                        session.payResponseAction(Mode);
                        return { redirect: '#corecallback' + '/' + OrderId };
                    }
                }
            }
            else {
                var token = localStorage["accessToken"];
                var tokenExpiresOn = localStorage["accessTokenExpiresOn"];
                //console.log('Expires on: ' + tokenExpiresOn);
                if (token != undefined && token != '' && token != null) {
                    if (tokenExpiresOn != undefined && tokenExpiresOn != '' && tokenExpiresOn != null) {
                        var now = (new Date()).getTime();
                        if (now < tokenExpiresOn)
                            return { redirect: '#bingAddressSearch' };
                    }
                }
            }
            if (urlCurrent.indexOf("login") > 0) {
                router.navigate(' ');
                
                return false;
            }
            return true;
        }

        $(document).ready(function () {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, "", window.location.href);
            };
        });


        function activate(FirstParam, SecondParam) {
            localStorage["MessageDisplayed"] = 'false';
            sessionStorage["testCount"] = 0;
            isLoggedIn(false);

            if (FirstParam != undefined && SecondParam != undefined && SecondParam != '') {
                session.orderId(FirstParam);
                session.mode(SecondParam);
            }

            if (FirstParam != null) {
                if (FirstParam.toLowerCase() == "orders") {
                    session.mode('orders');
                    session.profileId("");
                }
                else {
                    orderId(FirstParam);
                }
            }

            getLoginSubLinks();
            //var dfd = $.Deferred();

            session.isBusy(true);

            reset();
            //  carouselView('GCTACarousel.html');
            console.log('Current Brand is ' + $('.currentBrand').val());
            SetCarousel(parseInt($('.currentBrand').val()));

            session.isBusy(false);
            displayAnnouncements();
        }

        function configOKTA() {
            
            if (useOktaAuthScheme == false) {
                //console.log("UseIdentityProvider: " + session.useOktaAuthentication());
                return;
            }
            else if (useOktaAuthScheme == true) {

                https.get(config.getOKTAConfigUrl)
                    .done(function (oktaConf) {
                        if (oktaConf) {
                            renderOKTAWidget(oktaConf);
                        }
                    });
            }
        }

        function renderOKTAWidget(oktaConf) {

            var authParams = {
                issuer: oktaConf.Issuer
            };

            var features = {
                rememberMe: false,
                showPasswordToggleOnSignInPage: true
            };

            var i18n = {
                'en': {
                    'oform.errorbanner.title': 'Please validate required fields.',
                    'primaryauth.title': brandServicesName() + ' is now part of the inHere Experience Platform',
                    'primaryauth.submit': 'Sign In',
                    'socialauth.divider.text': 'OR',
                    'primaryauth.username.placeholder': 'Email Address',
                    //'oform.errorbanner.title': 'We found some errors. Please review Email Address and password.',
                    'error.username.required': 'Please enter an Email Address',
                    'password.forgot.email.or.username.placeholder': 'Email Address',
                    'password.forgot.email.or.username.tooltip': 'Email Address',
                    'model.validation.field.blank': 'Required',
                    'password.reset': 'Forgot Password',
                    'errors.E0000004': 'Incorrect email/password combination or your account has not been upgraded to the inHere platform.',
                    'errors.E0000022': 'Incorrect email/password combination or your account has not been activated on the inHere platform.'                    
                }
            };

            var customButtons = [
                {
                    title: 'Upgrade Account Security',
                    className: 'button-primary',
                    click: function () {
                        router.navigate('#inHereUpgrade');
                    }
                },
                {
                    title: 'Not a Member?  Join Us Now!',
                    className: 'button-primary'
                    ,
                    click: function () {
                        registerModule.resetForm();
                        router.navigate('register', { replace: true, trigger: true });
                    }
                }
            ];

            if (!oktaConf.UseOktaMigrationWidget) {
                customButtons = customButtons.slice(1);
            }

            $("#mdLoading").modal({ backdrop: 'static', keyboard: false });

            var oktaConfig = {
                baseUrl: oktaConf.BaseUrl,
                redirectUri: oktaConf.RedirectUri,
                logo: oktaConf.Logo,
                clientId: oktaConf.ClientId,
                scopes: [oktaConf.Scope],
                features: features,
                issuer: oktaConf.Issuer,
                i18n: i18n,
                customButtons: customButtons
            }

            oktaSignIn = new OktaSignIn(oktaConfig);

            oktaSignIn.renderEl({
                el: '#okta-login-container'
            }).then(function (tokens) {                
                oktaSignIn.authClient.tokenManager.setTokens(tokens.tokens);
                oktaLogin(tokens.tokens.idToken.claims.preferred_username, tokens.tokens.idToken, tokens.tokens.accessToken, oktaSignIn);
            }).catch(function (err) {
                console.error(err);
            });
            
            console.log('okta widget display : ' + document.getElementById('okta-sign-in').style.display);
            setTimeout(function () {
                console.log('check okta widget after 2 seconds');
                var loginContainer = document.getElementById('okta-sign-in');
                if (document.getElementById('okta-sign-in').style.display == 'none') {
                    console.log('retry widget');

                    oktaSignIn = new OktaSignIn(oktaConfig);

                    oktaSignIn.renderEl ({
                        el: '#okta-login-container'
                    }).then(function (tokens) {
                        oktaSignIn.authClient.tokenManager.setTokens(tokens.tokens);
                        oktaLogin(tokens.tokens.idToken.claims.preferred_username, tokens.tokens.idToken, tokens.tokens.accessToken, oktaSignIn);
                    }).catch(function (err) {
                        console.error(err);
                    });
                    $("#mdLoading").modal('hide');
                }
                else {
                    console.log('okta widget display : ' + document.getElementById('okta-sign-in').style.display);
                }
            }, 2000);


            //oktaSignIn.showSignInAndRedirect({
            //    // Assumes there is an empty element on the page with an id of 'osw-container'
            //    el: '#okta-login-container'
            //}).catch(function (error) {
            //    // This function is invoked with errors the widget cannot recover from:
            //    // Known errors: CONFIG_ERROR, UNSUPPORTED_BROWSER_ERROR
            //});
        }

        function deactivate() {
            vm.setFocus(false);
        }

        function goBack() {
            router.navigateBack();
        }

        function login() {

            loginError('');

            if ($('#LoginUserName').val().length > 0) {
                vm.loginModel.userName($('#LoginUserName').val());
                vm.loginModel.validateEmail(true);
            }

            if ($('#LoginPassword').val().length > 0) {
                vm.loginModel.password($('#LoginPassword').val())
                vm.loginModel.validatePassword(true);
            }

            if (vm.validationErrors().length > 0) {

                vm.validationErrors.showAllMessages();
                return;
            }

            //if (localStorage["logout"] == undefined || localStorage["logout"] == null)
            //    localStorage["logout"] = true;
            //localStorage.removeItem("logout");

            session.isBusy(true);
            session.isAjaxPost(true);
            session.clearAccessToken();
            session.clearACSFToken();
            session.clearRecentSearchesFromLocalStorage();
            loginError('');
            security.login({
                grant_type: "password",
                username: vm.loginModel.userName(),
                password: vm.loginModel.password(),
                login_type: "web",
                remember: vm.loginModel.rememberMe()
            }).done(function (data) {
                if (data.userName && data.access_token) {

                    localStorage["logout"] = false;
                    session.setAccessToken(data.access_token, false);
                    isLoggedIn(true);
                    getlastLoginDetails();
                    userSecurityInfo = data;
                    checkforLLAAgreement(data.userName);
                    //updateUserLastActivity();
                    
                    
                } else {
                    logger.log({
                        message: "Error logging in.",
                        data: "",
                        showToast: false,
                        type: "error"
                    });
                }
            }).fail(function (data) {
                var error = JSON.parse(data.responseText)
                console.log(error);
                loginError(error.error_description);
                vm.loginModel.password("");
                vm.setFocus(true);
                vm.validationErrors.showAllMessages(false);


                //console.log(data);
            }).always(function () {
                vm.loginModel.validatePassword(false);
                vm.loginModel.validateEmail(false);
                session.isBusy(false);
            }).failJSON(function (data) {
                if (data && data.error_description) {
                    logger.log({
                        message: data.error_description,
                        data: data.error_description,
                        showToast: false,
                        type: "error"
                    });
                } else {
                    logger.log({
                        message: "Error logging in.",
                        data: "",
                        showToast: false,
                        type: "error"
                    });
                }

            })

        }

        function oktaLogin(userName, oktaIdToken, oktaAccessToken, oktaSignIn) {

            loginError('');

            (localStorage["logout"] == undefined || localStorage["logout"] == null)
            
            session.isBusy(true);
            session.isAjaxPost(true);
            session.clearAccessToken();
            session.clearACSFToken();
            session.clearRecentSearchesFromLocalStorage();
            loginError('');
            security.login({
                grant_type: "password",
                login_type: "web",
                username: userName,
                id_token: oktaIdToken.idToken,
                access_token: oktaAccessToken.accessToken,
                migrate: "false"
            }).done(function (data) {
                if (data.userName && data.access_token) {

                    localStorage["logout"] = false;
                    session.setAccessToken(data.access_token, false);
                    isLoggedIn(true);
                    //session.isLoggedIn(true);
                    getlastLoginDetails();
                    userSecurityInfo = data;
                    checkforLLAAgreementOkta(data.userName);


                    //router.navigate('#bingAddressSearch', { replace: true, trigger: true });


                    return null;

                } else {
                    logger.log({
                        message: "Error logging in.",
                        data: "",
                        showToast: false,
                        type: "error"
                    });
                }
            }).fail(function (data) {
                var error = JSON.parse(data.responseText)
                console.log(error);
                //oktaSignIn.remove();
                showError(error);
                //configOKTA();

            }).always(function () {
                session.isBusy(false);
            }).failJSON(function (data) {
                if (data && data.error_description) {
                    logger.log({
                        message: data.error_description,
                        data: data.error_description,
                        showToast: false,
                        type: "error"
                    });
                } else {
                    logger.log({
                        message: "Error logging in.",
                        data: "",
                        showToast: false,
                        type: "error"
                    });
                }

            })

        }


        function getlastLoginDetails() {

            var url = config.getUserLastLoginDateUrl;
            var lastLogDate = '';

            https.get(url).then(function (data) {
                lastLogDate = data;
                if (lastLogDate != '') {
                    var localDateTime = new Date(lastLogDate).toLocaleString('en-US');
                    if (localDateTime.indexOf('1900') == -1) {
                        session.lastLogonDateTime(localDateTime);
                    }
                    else {
                        session.lastLogonDateTime('');
                    }
                    //alert.success('Last Login Date: ' + localDateTime);
                }
            });

        }

        function showRedirectError(errorMessage) {

            setTimeout(
                function () {

                    alerter.error('Error: ' + errorMessage);
                    sessionStorage.removeItem('errorKey');
                },
                1000);
        }

        function showError(err) {



            if (err.error == 'error_inactive' || err.error == 'error_nobrandaccess') {

                sessionStorage.setItem('errorKey', err.error_description);
                window.location.href = window.location.origin;
                //setTimeout(
                //    function () {

                        

                //    },
                //    3000);

            }
            else if (err.error == 'error_nobrandaccess_signedin') {
                window.location.href = window.location.origin + '#nobrandaccess';
            }
            else {
                
                alerter.error('Error: ' + err.error_description);

            }
        }

        function register() {
            registerModule.resetForm();
            router.navigate('register', { replace: true, trigger: true });
        }

        function forgotPassword() {
            router.navigate('forgotPassword', { replace: true, trigger: true });
        }
        function setToken(callbackfunc) {
            return https.get(config.getTokenUrl)
                .done(function (result) {
                    if (result) {
                        session.storeACSFToken(result);
                        $.ajaxSetup({
                            headers:
                            {
                                "X-XSRF-Token": result.formToken + ":" + result.cookieToken
                            }
                        });
                        callbackfunc();
                    }
                }).fail(function () {
                    alerter.error('Error getting token information', 'User Information');
                });
        }
        function SetCarousel(currentBrandId) {
            switch (currentBrandId) {

                case 2:
                    carouselView('TicorCarousel.html');
                    app.title = 'Ticor Title';
                    break;
                case 3:
                    carouselView('FNTIC_Carousel.html');
                    app.title = 'Fidelity National Title';
                    break;
                case 7:
                    carouselView('FNTG_Carousel.html');
                    app.title = 'Fidelity National Title Group';
                    break;
                case 15:
                    carouselView('CLTIC_Carousel.html');
                    app.title = 'Commonwealth Land Title Insurance Company';
                    break;
                case 4:
                    carouselView('CTICCarousel.html');
                    app.title = 'Chicago Title Premier Services';
                    break;
                case 5:
                    carouselView('AlamoCarousel.html');
                    app.title = 'Alamo Title Company';
                    break;
                case 12:
                    carouselView('GCTACarousel.html');
                    app.title = 'Grand Canyon Title Agency';
                    break;

                case 16:
                    carouselView('LTICCarousel.html');
                    app.title = 'Lawyers Title';
                    break;
                case 19:
                    carouselView('STACarousel.html');
                    app.title = 'Security Title Agency';
                    break;

                case 21:
                    carouselView('AustinCarousel.html');
                    app.title = 'Austin Title';
                    break;

                case 23:
                    carouselView('WesternCarousel.html');
                    app.title = 'Western Title';
                    break;
                case 24:
                    carouselView('DCTC.html');
                    app.title = 'All Access Dane County Title';
                    break;
                case 26:
                    carouselView('CTFNT_Carousel.html');
                    app.title = 'Chicago Title & Fidelity National Title Group';
                    break;
                case 27:
                    carouselView('TGHI_Carousel.html');
                    app.title = 'Title Guaranty Hawaii';
                    break;
            }
        }

        function BindCarousel() {
            resetControls();
            //console.log('BindCarousel called.');
            $('.carousel').carousel(
                {
                    interval: 6000
                }
            );
            loginError(session.iframeLoginError());

            if (session.detectBrowser() === 'IE') {
                showIENotSupportedModal();
            }
            //if (session.isHighContrastEnabled()) {
            //    header.toggleCarouselImages();
            //}
        }


        function showIENotSupportedModal() {
            $('#mdIENotSupported').modal({
                backdrop: 'static',
                keyboard: false
            });
            $('#mdIENotSupported').modal('show');
        }

        function updateUserLastActivity() {
            var url = $.getBasePath() + "api/User/UpdateUserLastActivity";

            return https.get(url,
                {
                    userName: vm.loginModel.userName()

                });

        }

        function AfterLoad() {

            if ($('#hdnIsLogin').val() == "true") {
                $('#page-wrapper').attr('style', 'margin-left:0!important');
            }

            $(window).on("capsOn", function (e) {
                if ($("#LoginPassword:focus").length > 0) {
                    $("#capsPasswordWarning").show();
                }
            });
            $(window).on("capsOff capsUnknown", function (e) {
                $("#capsPasswordWarning").hide();
            });
            $("#LoginPassword").on("focusout", function (e) {
                $("#capsPasswordWarning").hide();

            });
            $("#LoginPassword").on("focusin", function (e) {
                if ($(window).capslockstate("state") === true) {
                    $("#capsPasswordWarning").show();


                }
            });
            $(window).capslockstate();
        }


        function checkforLLAAgreement(name) {
            //var url = $.getBasePath() + "api/Site/GetLiabilityAgreementByUserId";
            var url = config.getLLAByUserId;

            return https.get(url)
                .done(function (result) {
                    if (result.LiabilityAgreement) {
                        console.log("Checking for LLA agreement status");
                        userInfo = result.LiabilityAgreement;
                        isNewLLAEntry = false;

                        if (result.LiabilityAgreement.AgreeStatus === 1) {
                            session.llaAccepted(true);
                        }
                        else {
                            session.llaAccepted(false);
                        }

                        if (!session.llaAccepted()) {
                            showLLAAgreementModal();
                        }
                        else {
                            session.setUser(userSecurityInfo, vm.loginModel.rememberMe(), vm.brand(), vm.loginModel.password());
                            //setToken(function () {
                            //    session.setUser(userSecurityInfo, vm.loginModel.rememberMe(), vm.brand(), vm.loginModel.password());
                            //});
                        }
                        //})
                        //.fail(function () {
                        //    console.log("Couldn't retrieve LLA status");
                        //});
                    }
                    else {
                        isNewLLAEntry = true;
                        var url = $.getBasePath() + "api/User/GetUserInfoByEmail";
                        return https.get(url, {
                            email: vm.loginModel.userName()
                        })
                            .done(function (result) {
                                userInfo = result;
                                showLLAAgreementModal();
                            })
                            .fail(function () {
                                console.log("Couldn't retrieve User information");
                            });

                    }
                })
                .fail(function () {
                    console.log("Couldn't retrieve LLA for " + vm.loginModel.userName());
                });
        }

        function checkforLLAAgreementOkta(name) {
            //var url = $.getBasePath() + "api/Site/GetLiabilityAgreementByUserId";
            var url = config.getLLAByUserId;
            return https.get(url)
                .done(function (result) {
                    if (result.LiabilityAgreement) {
                        console.log("Checking for LLA agreement status");
                        userInfo = result.LiabilityAgreement;
                        isNewLLAEntry = false;

                        if (result.LiabilityAgreement.AgreeStatus === 1) {
                            session.llaAccepted(true);
                        }
                        else {
                            session.llaAccepted(false);
                        }

                        if (!session.llaAccepted()) {
                            showLLAAgreementModal();
                        }
                        else {
                            session.setUser(userSecurityInfo, vm.loginModel.rememberMe(), vm.brand(), vm.loginModel.password());

                            //updateUserLastActivity();
                            //session.routeToLandingPage();
                        }
                        //})
                        //.fail(function () {
                        //    console.log("Couldn't retrieve LLA status");
                        //});
                    }
                    else {
                        isNewLLAEntry = true;
                        var url = $.getBasePath() + "api/User/GetUserInfoByEmail";
                        return https.get(url, {
                            email: name //vm.loginModel.userName()
                        })
                            .done(function (result) {
                                userInfo = result;
                                showLLAAgreementModal();
                            })
                            .fail(function () {
                                console.log("Couldn't retrieve User information");
                            });

                    }
                })
                .fail(function () {
                    console.log("Couldn't retrieve LLA for " + name);  //vm.loginModel.userName());
                });
        }

        function showLLAAgreementModal() {
            $('#mdLLAAgreementWithAgreeDisagree').modal({
                backdrop: 'static',
                keyboard: false
            });
            $('#mdLLAAgreementWithAgreeDisagree').modal('show');
        }

        function LLAAccepted() {
            console.log("Saving LLA accept status");
            updateLLA("Agree");
        }

        function LLANotAccepted() {
            console.log("Saving LLA not accept status");
            updateLLA("NotAgree");
            if (oktaSignIn != null) {
                oktaSignIn.authClient.signOut();
            }

        }

        function updateLLA(LLAStatus) {
            var url = $.getBasePath() + "api/Site/UpdateLLA";

            return https.post(url, {
                AgreeStatus: LLAStatus
            })
                .done(function (result) {
                    if (result.HasErrors != false) {
                        console.log("Something went wrong saving the LLA agreement status.");
                    }
                    else {
                        console.log("LLA status saved succesfully");
                        if (result.LiabilityAgreement.AgreeStatus === 1) {
                            session.llaAccepted(true);
                            setToken(function () {
                                session.setUser(userSecurityInfo, vm.loginModel.rememberMe(), vm.brand(), vm.loginModel.password());
                            });
                        }
                        else {
                            session.llaAccepted(false);
                            session.clearAccessToken();
                            session.clearACSFToken();
                            router.navigate('#login');
                        }
                    }
                })
                .fail(function () {
                    console.log("Couldn't retrieve LLA status");
                });
        }

        function saveLLAStatus(agreementStatus) {
            var url = $.getBasePath() + "api/Site/SaveLiabilityAgreement";
            var agreement;

            if (isNewLLAEntry) {
                agreement = generateNewLLAJson(agreementStatus);
            }
            else {
                agreement = generateLLAJSon(agreementStatus);
            }

            return https.post(url, {
                agreement: agreement
            })
                .done(function (result) {
                    if (result.HasErrors != false) {
                        console.log("Something went wrong saving the LLA agreement status.");
                    }
                    else {
                        console.log("LLA status saved succesfully");
                        if (agreementStatus) {
                            setToken(function () {
                                session.setUser(userSecurityInfo, vm.loginModel.rememberMe(), vm.brand(), vm.loginModel.password());
                            });
                        }
                    }
                })
                .fail(function () {
                    console.log("Couldn't retrieve LLA status");
                });
        }

        function generateLLAJSon(status) {
            if (status == true) {
                agreement = {
                    "Id": userInfo.Id,
                    "UserGuid": userInfo.UserGuid,
                    "Username": userInfo.Username,
                    "FirstName": userInfo.FirstName,
                    "LastName": userInfo.LastName,
                    "Company": userInfo.Company,
                    "AgreeStatus": "Agree",
                    "Updated": userInfo.Updated,
                    "IPAddress": userInfo.IPAddress,
                    "Notes": userInfo.Notes,
                    "Address": userInfo.Address,
                    "City": userInfo.City,
                    "State": userInfo.State,
                    "Zip": userInfo.Zip,
                    "Phone": userInfo.Phone,
                    "Version": userInfo.Version
                }
            }
            else {
                agreement = {
                    "Id": userInfo.Id,
                    "UserGuid": userInfo.UserGuid,
                    "Username": userInfo.Username,
                    "FirstName": userInfo.FirstName,
                    "LastName": userInfo.LastName,
                    "Company": userInfo.Company,
                    "AgreeStatus": "NotAgree",
                    "Updated": userInfo.Updated,
                    "IPAddress": userInfo.IPAddress,
                    "Notes": userInfo.Notes,
                    "Address": userInfo.Address,
                    "City": userInfo.City,
                    "State": userInfo.State,
                    "Zip": userInfo.Zip,
                    "Phone": userInfo.Phone,
                    "Version": userInfo.Version
                }
            }
            return agreement;
        }

        function generateNewLLAJson(status) {
            if (status == true) {
                agreement = {
                    "Id": 0,
                    "UserGuid": userInfo.UserId,
                    "Username": userInfo.Email,
                    "FirstName": userInfo.FirstName,
                    "LastName": userInfo.LastName,
                    "Company": userInfo.Company,
                    "AgreeStatus": "Agree",
                    "Updated": "",
                    "IPAddress": "",
                    "Notes": "",
                    "Address": userInfo.StreetAddress1,
                    "City": userInfo.City,
                    "State": userInfo.State,
                    "Zip": userInfo.Zip,
                    "Phone": userInfo.PhoneWork,
                    "Version": ""
                }
            }
            else {
                agreement = {
                    "Id": 0,
                    "UserGuid": userInfo.UserId,
                    "Username": userInfo.Email,
                    "FirstName": userInfo.FirstName,
                    "LastName": userInfo.LastName,
                    "Company": userInfo.Company,
                    "AgreeStatus": "NotAgree",
                    "Updated": "",
                    "IPAddress": "",
                    "Notes": "",
                    "Address": userInfo.StreetAddress1,
                    "City": userInfo.City,
                    "State": userInfo.State,
                    "Zip": userInfo.Zip,
                    "Phone": userInfo.PhoneWork,
                    "Version": ""
                }
            }
            return agreement;
        }

        function getPasswordChangeAlert() {
            var url = config.getPasswordChangeAlertUrl
            return https.get(url, {
                format: 'json'
            });
        }


        function isTCPARequiredFromRemoteServer() {
            var url = config.isTCPARequiredFromRemoteServerUrl;
            return https.get(url).done(function (result) {
                if (result.HasErrors != false) {
                    console.log("Something went wrong getting TCPA status.");
                }
                else {
                    //console.log("TCPA Status from remote: ");
                    //console.log(result);
                    if (result.count > 0)
                        if (result.values[0].newConsentReq === true && result.values[0].consentDate == null) {
                            getTCPAConsentStatus();
                        }

                }
            }).fail(function () {
                console.log("Get TCPA Consent status failed!");
            });;
        }

        function getTCPAConsentStatus() {
            var url = config.getTCPAConsentStatusForUserUrl;
            return https.get(url).done(function (result) {
                if (result.HasErrors != false) {
                    console.log("Something went wrong getting TCPA status.");
                }
                else {
                    //console.log("TCPA Status : ");
                    //console.log(result);
                    if (result.IsTCPAConsentTrackingEnabled)
                        if (!result.HasAgreedTCPA && result.TimeToGetConsent) {
                            router.navigate('tcpaConsent', { replace: true, trigger: true });
                        }
                }
            }).fail(function () {
                console.log("Get TCPA Consent status failed!");
            });
        }


        function displayAnnouncements() {
            announcementsList([]);
            var url = config.getSiteAnnouncements;
            showSiteAnnouncements(false);
            return https.get(url).done(function (result) {
                if (result !== null) {
                    if (result.HasErrors != false) {
                        console.log("Something went wrong getting Site Announcements");
                    }
                    else {
                        //console.log("Site Announcements : ");
                        //console.log(result);
                        if (result.announcements.length > 0) {

                            buildAnnouncementsList(result.announcements);
                            //console.log("announcementsList : " + announcementsList()[0]);
                        }
                    }
                }
            }).fail(function () {
                console.log("Get Site Announcements failed!");
            });
        }

        function buildAnnouncementsList(announcements) {
            announcementsList([]);
            var buildannouncementsList = [];
            for (i = 0; i < announcements.length; i++) {
                var announcementModel = new siteAnnouncementModel.announcements();

                announcementModel.update(announcements[i]);
                if (announcements[i].DisplayInThisBrand)
                    showSiteAnnouncements(true);
                //var item = new userOperationModel.UserOperationModel();
                //item.OperationId(announcements[i].OperationId);
                //item.BrandId(list[i].BrandId);
                //item.OperationName(list[i].Name);
                //item.ValueCheckUserId(list[i].VCheckUid);
                //if (list[i].OperationId === defaultOpnId)
                //    item.IsDefaultOperation(true);
                //else
                //    item.IsDefaultOperation(false);
                buildannouncementsList.push(announcementModel);
            }
            announcementsList(buildannouncementsList);
        }

        function showAnnouncementsModal() {
            $('#mdSiteAnnouncements').modal({
                backdrop: 'static',
                keyboard: false
            });
            $('#mdSiteAnnouncements').modal('show');
        }
    });
