define('viewmodels/userModule/forgotPassword',['plugins/router', 'knockout', 'global/session', 'services/security', 'durandal/app', 'jquerycapslock', 'commonDialogModel', 'alertNotification', 'passwordMeter', 'plugins/http', 'config'],
    function (router, ko, session, security, app, jquerycapslock, commonDialog, alert, passwordMeter, https, config) {
        // Internal properties and functions

        // Reveal the bindable properties and functions

        var currentStep = ko.observable('userModule/ForgotPasswordStep1.html');

        var secretQuestion = ko.observable('');
        var isInValidUser = ko.observable(false);
        var isSecretAnswerInValid = ko.observable(false);
        var isAccountLocked = ko.observable(false);
        var commonDialogModel = new commonDialog("forgotPasswordDialog");
        var setFocus = ko.observable(true);
        var inValidAnswerTimes = ko.observable(0);


        var parentName = ko.observable('');
        var parentEmailId = ko.observable('');
        var parentWorkPhoneWithExtn = ko.observable('');
        var forgotPasswordToken = ko.observable('');
        var pluginFocus = ko.observable(false);
        var captchaResponse = '';



        pluginFocus.subscribe(function (newValue) {
            if (newValue == true) {
                setPlugins();
            }
        })

        var isNotRobotValidated = ko.observable(true);

        var userName = ko.observable("")
            .extend({ required: { message: 'Email is required.' } })
            .extend({ email: { message: 'Invalid Email Address.' } })
            //.extend({
            //    email: {
            //        message: 'Invalid Email.'
            //    }
            //})
            .extend({
                maxLength: {
                    params: 75, message: 'Invalid Email.'
                }
            });

        var secretAnswer = ko.observable("")
            .extend({ required: { params: true, message: 'Secret answer is required.' } })
            //    .extend({ minLength: { params: 6, message: 'Invalid Secret answer.' } })
            .extend({ maxLength: { params: 60, message: 'Invalid Secret answer.' } });

        var password = ko.observable("")
            .extend({ required: { message: 'New Password is required.' } })
            .extend({
                minLength: {
                    params: 8,
                    message:
                        'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                }
            })
            .extend({
                pattern: {
                    params: /\d{1}/,
                    message:
                        'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                }
            })
            .extend({
                pattern: {
                    params: /[a-zA-Z]/,
                    message:
                        'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                }
            })
            .extend({
                pattern: {
                    params: /(?=.*[a-z])(?=.*[A-Z])/,
                    message:
                        'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                }
            })



        password.subscribe(function (newValue) {
            if (newValue.length >= 128) {
                $("#maxPasswordLength").show();
            } else {
                $("#maxPasswordLength").hide();
            }
        })

        var confirmPassword = ko.observable("")
            .extend({ required: { message: 'Confirm New Password is required.' } })
            //.extend({ minLength: { params: 8, message: 'Confirm New Password should be of minimum 8 characters length.' } })
            //.extend({ maxLength: { params: 75, message: 'Confirm New Password should be of maximum 75 characters length.' } })
            .extend({ equal: { params: password, message: 'Should match New Password.' } })


        var vm = {
            activate: activate,
            goBack: goBack,
            title: 'forgotPassword',
            currentStep: currentStep,
            userName: userName,
            GetUserDetail: GetUserDetail,
            attached: attached,
            compositionComplete: compositionComplete,
            session: session,
            secretQuestion: secretQuestion,
            secretAnswer: secretAnswer,
            isInValidUser: isInValidUser,
            password: password,
            confirmPassword: confirmPassword,
            step2Previous: step2Previous,
            step2Next: step2Next,
            setFocus: setFocus,

            step3Previous: step3Previous,
            savePassword: savePassword,

            userName_mouseout: userName_mouseout,
            step1Enter: step1Enter,

            secretAnswer_mouseout: secretAnswer_mouseout,
            step2Enter: step2Enter,

            confirmPassword_mouseout: confirmPassword_mouseout,
            step3Enter: step3Enter,
            commonDialogModel: commonDialogModel,
            isSecretAnswerInValid: isSecretAnswerInValid,
            isAccountLocked: isAccountLocked,
            parentName: parentName,
            parentEmailId: parentEmailId,
            parentWorkPhoneWithExtn: parentWorkPhoneWithExtn,
            forgotPasswordToken: forgotPasswordToken,
            enableTooltip: setPlugins,
            pluginFocus: pluginFocus,
            isNotRobotValidated: isNotRobotValidated,


        };

        vm.validationUNameErrors = ko.validation.group([vm.userName]);

        vm.validationsecretAnswerErrors = ko.validation.group([vm.secretAnswer]);

        vm.validationPasswordErrors = ko.validation.group([vm.password, vm.confirmPassword]);

        return vm;

        function compositionComplete() {
            console.log('compositionComplete  called');
            session.validateInput();
            //setPlugins();
            $('#Password').password({
                shortPass: 'The password is too short',
                badPass: 'Weak; Try combining upper & lower case letters, numbers & special characters',
                goodPass: 'Medium; Try combining upper & lower case letters, numbers & special characters',
                strongPass: 'Strong password',
                containsUsername: 'The password contains the username',
                enterPass: 'Type your password',
                showPercent: false,
                showText: true, // shows the text tips
                animate: true, // whether or not to animate the progress bar on input blur/focus
                animateSpeed: 'fast', // the above animation speed
                username: false, // select the username field (selector or jQuery instance) for better password checks
                usernamePartialMatch: true, // whether to check for username partials
                minimumLength: 8 // minimum password length (below this threshold, the score is 0)

            });

            grecaptcha.render('g-recaptcha', {
                'sitekey': session.recaptchaKey(),
                'callback': reCaptchaCallback,
                'expired-callback': reCaptchaExpired,
                'error-callback': reCaptchaError
            });

        }

        function reCaptchaCallback(response) {
            captchaResponse = response;
            if (captchaResponse != undefined && captchaResponse != '')
                isNotRobotValidated(false);
        }

        function reCaptchaExpired() {
            isNotRobotValidated(true);
        }

        function reCaptchaError() {
            alert.error('Error connecting to server. Please try after some time.');
        }

        function bindingComplete() {
            console.log('bindingComplete called');
            setPlugins();
        }

        function setPlugins() {
            $('[data-toggle="tooltip"]').tooltip();
        }
        function attached() {
            userName('');
            //currentStep('userModule/ForgotPasswordStep1.html');

            $('#page-wrapper').attr('style', 'margin-left:0!important');

            $(window).on("capsOn", function (e) {
                if ($("#LoginPassword:focus").length > 0) {
                    $("#capsPasswordWarning").show();

                }
            });
            $(window).on("capsOff capsUnknown", function (e) {
                $("#capsPasswordWarning").hide();
            });
            $("#LoginPassword").on("focusout", function (e) {
                $("#capsPasswordWarning").hide();

            });
            $("#LoginPassword").on("focusin", function (e) {
                if ($(window).capslockstate("state") === true) {
                    $("#capsPasswordWarning").show();

                }
            });

            $(window).on("capsOn", function (e) {
                if ($("#Password:focus").length > 0) {
                    $("#capsNewPasswordWarning").show();

                }
            });
            $(window).on("capsOff capsUnknown", function (e) {
                $("#capsNewPasswordWarning").hide();
            });
            $("#Password").on("focusout", function (e) {
                $("#capsNewPasswordWarning").hide();

            });
            $("#Password").on("focusin", function (e) {
                if ($(window).capslockstate("state") === true) {
                    $("#capsNewPasswordWarning").show();

                }
            });

            $(window).on("capsOn", function (e) {
                if ($("#ConfirmPassword:focus").length > 0) {
                    $("#capsConfirmNewPasswordWarning").show();

                }
            });
            $(window).on("capsOff capsUnknown", function (e) {
                $("#capsConfirmNewPasswordWarning").hide();
            });
            $("#ConfirmPassword").on("focusout", function (e) {
                $("#capsConfirmNewPasswordWarning").hide();

            });
            $("#ConfirmPassword").on("focusin", function (e) {
                if ($(window).capslockstate("state") === true) {
                    $("#capsConfirmNewPasswordWarning").show();

                }
            });
            /* 
            * Initialize the capslockstate plugin.
            * Monitoring is happening at the window level.
            */
            $(window).capslockstate();


        }

        function userName_mouseout() {
            $('#userName').blur();
        }

        function step1Enter(d, e) {
            isInValidUser(false);
            if (e.keyCode == 13) {
                GetUserDetail();
            }
        }

        function secretAnswer_mouseout() {
            $('#LoginPassword').blur();
        }

        function step2Enter(d, e) {
            if (e.keyCode == 13) {
                step2Next();
            }
        }

        function confirmPassword_mouseout() {
            $('#ConfirmPassword').blur();
        }

        function step3Enter(d, e) {
            if (e.keyCode == 13) {
                savePassword();
            }
        }


        function GetUserDetail() {
            secretQuestion('');
            isAccountLocked(false);
            isInValidUser(false);
            if (vm.validationUNameErrors().length > 0) {
                vm.validationUNameErrors.showAllMessages();
                session.isBusy(false);
                return;
            }


            //console.log(userName());
            session.isBusy(true);
            //var url = config.getEmailForgotPasswordLinkUrl;
            //captchaResponse
            security.emailForgotPasswordLink(userName(), captchaResponse)
                .then(function (data) {
                    //console.log(data);
                    session.isBusy(false);
                    if ((data.HasErrors || data.HasValidationErrors) && data.Messages.length > 0) {
                        alert.error("There was an error sending email. Please contact administrator.");
                    }
                    else if (data.IsValidRequest == false) {
                        alert.error("Please select \"I'm not a robot\".");
                        try {
                            grecaptcha.reset();
                        }
                        catch (ex) {
                            //     setupCaptcha();
                        }

                        isNotRobotValidated(true);
                    }
                    else {
                        //if (data.EmailLinkSent == false) {
                        //    alert.error("There was an error sending email. Please contact administrator.");
                        //}
                        currentStep('userModule/_resetPasswordEmailSent.html');
                    }
                    //commonDialogModel.show('Forgot Password', 'An Email with the link to reset your password has been sent to your regisered email id.');
                }).fail(function (data) {
                    console.log(data);
                    commonDialogModel.show('Forgot Password', 'Internal Server Error');
                    session.isBusy(false);
                }).always(function () {
                    session.isBusy(false);
                });


            //security.getSecurityQuestion({ userName: userName() }).done(function (data) {

            //    secretQuestion(data);
            //    isInValidUser(false);
            //    currentStep('userModule/ForgotPasswordStep2.html');


            //}).fail(function (data) {
            //    console.log(data);
            //    if (data.status == 401) {
            //        //commonDialogModel.show('Forgot Password', 'Your Account has been locked out for 20 minutes.');
            //        isAccountLocked(true);

            //    }
            //    if (data.status == 400) {
            //        var s = data.responseJSON["ModelState"];
            //        alert.error(s[""]);

            //    }
            //}).always(function (data) {
            //    if (data.status != undefined && data.status == 404) {
            //        //commonDialogModel.show('Forgot Password', "Not a valid User's email.");
            //        userName('');
            //        setFocus(true);
            //        vm.validationUNameErrors.showAllMessages(false);
            //        isInValidUser(true);
            //    }
            //    session.isBusy(false);
            //});

        }



        function step2Previous() {
            currentStep('userModule/ForgotPasswordStep1.html');
        }

        function step2Next() {
            session.isBusy(true);
            isSecretAnswerInValid(false);
            if (vm.validationsecretAnswerErrors().length > 0) {
                vm.validationsecretAnswerErrors.showAllMessages();
                session.isBusy(false);
                return;
            }

            security.validateSecretAnswer({ userName: userName(), answer: secretAnswer() }).done(function (result) {
                console.log('security.validateSecretAnswer called');
                //console.log(result);
                if (result == true) {
                    currentStep('userModule/ForgotPasswordStep3.html');

                }
                else {
                    //commonDialogModel.show('Forgot Password', 'The answer provided does not match the one on record.');
                    isSecretAnswerInValid(true);

                    secretAnswer('');
                    vm.validationsecretAnswerErrors.showAllMessages(false);
                }

            }).fail(function (data) {
                if (data.status == 400) {
                    var s = data.responseJSON["ModelState"];
                    alert.error(s[""]);

                }
                isSecretAnswerInValid(true);
                secretAnswer('');
                vm.validationsecretAnswerErrors.showAllMessages(false);
            })
                .always(function () {

                    session.isBusy(false);
                });


        }

        function step3Previous() {
            currentStep('userModule/ForgotPasswordStep2.html');
        }

        function savePassword() {
            session.isBusy(true);

            if (vm.validationPasswordErrors().length > 0) {
                vm.validationPasswordErrors.showAllMessages();
                session.isBusy(false);
                return;
            }
            var url = config.updatePasswordUsingForgotPasswordUrl;

            var request = {
                "Password": password(),
                "Token": forgotPasswordToken().token
            };

            https.post(url, request)
                .done(function (result) {
                    //console.log(result);
                    if (result == true) {
                        //app.showMessage('Password changed successfully.');
                        currentStep('userModule/ForgotPasswordStep4.html');

                    } else {
                        commonDialogModel.show('Forgot Password', 'Failed to change the password.');
                    }

                }).fail(function (data) {
                    var message = toErrorString(data);
                    commonDialogModel.show('Forgot Password', message);
                }).always(function () {

                    session.isBusy(false);

                });


        }

        function activate(id, querystring) {
            //Initialize lookup data here.
            currentStep('userModule/ForgotPasswordStep1.html');
            isNotRobotValidated(true);
            if (querystring != undefined && querystring != null && querystring != "") {
                currentStep('userModule/ForgotPasswordStep1.html');
                forgotPasswordToken(querystring);
                security.forgotPasswordVerifyUser(querystring).done(function (data) {
                    //console.log(data);
                    if (data != null && data != undefined && data.IsValidUser != null && data.IsValidUser != undefined) {
                        if (data.IsValidUser) {
                            userName(data.UserEmail);
                            parentName(data.ParentName);
                            parentEmailId(data.ParentEmailId);
                            parentWorkPhoneWithExtn(data.ParentWorkPhoneWithExtn);
                            isInValidUser(false);
                            currentStep('userModule/ForgotPasswordStep3.html');
                            //security.getSecurityQuestion({ userName: data.UserEmail }).then(function (data) {
                            //    secretQuestion(data);
                            //    isInValidUser(false);
                            //    currentStep('userModule/ForgotPasswordStep2.html');
                            //}).fail(function (data) {
                            //    console.log(data);
                            //}).always(function (data) {
                            //    if (data != null && data != undefined && data.IsValidUser != null && data.IsValidUser != undefined) {
                            //        if (!data.IsValidUser) {
                            //            userName('');
                            //            setFocus(true);
                            //            vm.validationUNameErrors.showAllMessages(false);
                            //            isInValidUser(false);
                            //            currentStep('userModule/ForgotPasswordStep1.html');
                            //            commonDialogModel.show('Forgot Password', data.ValidationMessage);
                            //        }
                            //    }
                            //    session.isBusy(false);
                            //});
                        }
                        else {
                            currentStep('userModule/ForgotPasswordStep1.html');
                        }
                    }
                }).fail(function (data) {
                    console.log(data);
                }).always(function (data) {
                    if (data != null && data != undefined && data.IsValidUser != null && data.IsValidUser != undefined) {
                        if (!data.IsValidUser) {
                            userName('');
                            setFocus(true);
                            vm.validationUNameErrors.showAllMessages(false);
                            isInValidUser(false);
                            currentStep('userModule/ForgotPasswordStep1.html');
                            commonDialogModel.show('Forgot Password', data.ValidationMessage);
                        }
                    }
                    session.isBusy(false);
                });
            }
            else {
                // Get the data for this viewmodel, return a promise.
                Reset();
            }
        }

        function Reset() {
            userName("");
            secretAnswer("");
            password("");
            confirmPassword("");
            isInValidUser(false);
            isSecretAnswerInValid(false);
            isAccountLocked(false);
            forgotPasswordToken('');
            if (vm.validationUNameErrors().length > 0) {
                vm.validationUNameErrors.showAllMessages(false);
            }

            if (vm.validationsecretAnswerErrors().length > 0) {
                vm.validationsecretAnswerErrors.showAllMessages(false);
            }

            if (vm.validationPasswordErrors().length > 0) {
                vm.validationPasswordErrors.showAllMessages(false);
            }
        }


        function goBack() {
            //router.navigate('#login', { replace: true, trigger: true });
            window.location.href = "#login";
            location.reload();
        }

        function toErrorString(data) {
            var errors, items;
            if (data.responseJSON.ModelState) {

                for (var key in data.responseJSON.ModelState) {
                    items = data.responseJSON.ModelState[key];
                    if (items.length) {
                        errors = items.join(",");
                    }
                }
            }

            if (errors)
                return errors;

            if (data && data.responseJSON && data.responseJSON.Message) {
                errors = data.responseJSON.Message;
                return errors;
            }

            if (errors === undefined) {
                errors = "An error occurred when trying to process your request";
            }

            return errors;
        }
    });
