define('models/order/orderInformation',["knockout"],
    function (ko) {
        function orderInformation() {
            var self = this;
            self.viewName = "orderModule/_orderInformation.html";
            self.title = "Order Information";
            self.date = ko.observable("");
            self.index = ko.observable(-1);

            self.Service = ko.observable("");
            self.orderFormName = ko.observable("Testing binding")
                .extend({ required: { message: 'Order Form is required.' } });

            self.estimatedCloseDate = ko.observable("");

            self.transactionType = ko.observable("")
                .extend({ required: { message: 'TransactionType is required.' } });

            self.transactionAmount = ko.observable("")
            .extend({ number: true });

            self.scrowOffice = ko.observable("")
                .extend({ required: { message: 'Escrow Office is required.' } });

            self.scrowOfficer = ko.observable("")
                .extend({ required: { message: 'Escrow Officer is required.' } });

            self.lcp = ko.observable("")
                .extend({ required: { message: 'LCP is required.' } });

            self.TransactionTypeName = ko.observable('');
            self.TransactionTypeId = ko.observable('');

            self.iCloseSalesRepresentativeId = ko.observable("");
            self.iCloseSalesRepresentativeName = ko.observable("");
            self.isIcloseOrder = ko.observable(false);
            self.iCloseEscrowOfficerId = ko.observable("");
            self.iCloseEscrowOfficerName = ko.observable("");
            self.iCloseTitleOfficerId = ko.observable("");
            self.iCloseTitleOfficerName = ko.observable("");

            self.cb1 = ko.observable(false);
            self.cb2 = ko.observable(false);
            self.cb3 = ko.observable(false);
            self.cb5 = ko.observable(false);
            self.cb6 = ko.observable(false);
            self.cb7 = ko.observable(false);
            self.cb8 = ko.observable(false);
            self.cb9 = ko.observable(false);
            self.cb10 = ko.observable(false);
            self.cb11 = ko.observable(false);
            self.cb12 = ko.observable(false);
            self.cb13 = ko.observable(false);
            self.cb14 = ko.observable(false);
            self.cb16 = ko.observable(false);
            self.cb17 = ko.observable(false);
            self.cb18 = ko.observable(false);
            self.cb19 = ko.observable(false);
            self.cb20 = ko.observable(false);
            self.cb22 = ko.observable(false);
            self.cb23 = ko.observable(false);
            

            self.transactionTypeList = ko.observableArray([
           { key: "AUCT", value: "Auction" },
           { key: "CMLO", value: "Commercial Loan" },
           { key: "CMNC", value: "Commercial New Construction" },
           { key: "CMSA", value: "Commercial Sale / Re-Sale " },
           { key: "EQUI", value: "Equity (Loan)" },
           { key: "FORE", value: "Foreclosure" },
           { key: "MHRE", value: " Manufactured Home Refinance" },
           { key: "MHSA", value: "Manufactured Home Sale / Re-Sale" },
           { key: "NWCO", value: "New Construction" },
           { key: "NCLO", value: " New Construction Loan " },
           { key: "REFI", value: "Refinance" },
           { key: "RELO", value: "Relocation" },
           { key: "REO", value: "REO" },
           { key: "RVMG", value: "Reverse Mortgage  " },
           { key: "SALE", value: "Sale / Re-Sale  " },
           { key: "SHSA", value: "Short Sale" },
           { key: "SUBD", value: "Subdivisions" }

            ]);


            self.orderFormList = ko.observableArray([
          { key: "1", value: "Online Escrow Order" },
          { key: "2", value: "Online Title and Escrow Order" },
          { key: "3", value: "Online Title Order" },
          { key: "4", value: "Escrow Order(WESB/SP360) " },
          { key: "5", value: "Title and Escrow Order(WESB/SP360)" },
          { key: "6", value: "Title Order" },
          { key: "7", value: "WESB/SP360" }

            ]);


            self.scrowOfficeList = ko.observableArray([
         { key: "188", value: "zNGS - Escrow" },
         { key: "other", value: "Other Office" }

            ]);


            self.scrowOfficerList = ko.observableArray([
        { key: "b60bd147-d4ae-44fc-aabd-7f02012eedbe", value: "zNGS - Escrow" },
        { key: "other", value: "Other Officer" }

            ]);


            self.lcpList = ko.observableArray([
       { key: "Yes", value: "Yes" },
       { key: "No", value: "No" }

            ]);

            self.iCloseCustomerServiceList = ko.observableArray([]);

            self.reset = function()
            {
                self.transactionType("");
                self.estimatedCloseDate("");
                self.transactionAmount("");
                self.scrowOffice("");
                self.scrowOfficer("");
                self.lcp("");
                ko.validation.group(self).showAllMessages(false);

            }

            self.isValid = function () {
                return ko.validation.group(self).length === 0;
            }

        }

        var vm = {
            orderInformation: orderInformation
        }

        return vm;
    });
