define('operationInfoModel',["knockout", 'utlities'],
    function (ko, utlities) {


        function OfficeDetails() {
            var self = this;

            self.address1 = ko.observable("");
            self.address2 = ko.observable("");
            self.city = ko.observable("");
            self.stateName = ko.observable("");
            self.county = ko.observable("");
            self.tollFree = ko.observable("");
            self.fax = ko.observable("");
            self.phone = ko.observable("");
            self.officeName = ko.observable("");
            self.photoName = ko.observable("");
            self.photoUrl = ko.observable("Content/Images/Photos/no_image.jpg");

           

        }



        var vm = {
            OfficeDetails: OfficeDetails,
        }

        return vm;
    }
);
