define('models/userProfile/userInfoModel',["knockout", 'utlities', 'moment'],
    function (ko, utlities, moment) {

        function userInfo() {
            var self = this;

            self.title = "User Information"
            self.ModelId = "userInfo"
            self.index = ko.observable(-1)

            self.userId = ko.observable("")
            self.clientID = ko.observable("")


            self.fName = ko.observable("")
                .extend({ required: { message: 'First Name is required.' } });
            self.mName = ko.observable("");
            self.lName = ko.observable("")
                .extend({ required: { message: 'Last Name is required.' } });
            self.birthDate = ko.observable()
            self.email = ko.observable("")
                .extend({ required: { message: 'Email Address is required.' } })
                .extend({ email: { message: 'Invalid Email Address.' } });

            self.acceptsBulkEmail = ko.observable(false);
            self.enableTitlePro247CustActivityEmailNotifications = ko.observable(false);
            self.enableProfileLowLimitEmailNotifications = ko.observable(false);
            self.exemptFromWellsFargoResetProcess = ko.observable(false);
            self.lowLimitSetting = ko.observable("");

            self.address = ko.observable("")
                .extend({ required: { message: 'Address is required.' } })
                .extend({
                    pattern: {
                        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,250}$'
                    }
                });


            self.suiteNo = ko.observable("")
            self.zipCode = ko.observable("")
                .extend({ required: { message: 'Zip Code is required.' } })
                .extend({
                    pattern: {
                        message: 'Only 5 digits ZIP or ZIP + 4 is allowed.',
                        params: /^\d{5}\b([\-]?\d{4})?$/
                    }
                });

            self.city = ko.observable("")
                .extend({ required: { message: 'City is required.' } })
            self.state = ko.observable("")
                .extend({ required: { message: 'State is required.' } })
            self.stateList = ko.observableArray([])
            self.county = ko.observable("")
                .extend({ required: { message: 'County is required.' } })
            self.countyList = ko.observableArray([])

            self.fips = ko.observable("").extend({ required: { message: 'County is required.' } });
            self.company = ko.observable("")
                .extend({ required: { message: 'Company is required.' } })
            self.occupation = ko.observable("")
                .extend({ required: { message: 'Occupation is required.' } })

            self.phWorkExt = ko.observable("")
            self.phWork = ko.observable("")
                .extend({ phoneUS: true })
                .extend({
                    required: {
                        message: 'Work Phone is required.'
                    }
                });

            self.phHomeExt = ko.observable("")
            self.phHome = ko.observable("")
                .extend({
                    phoneUS: true
                })
                .extend({
                    required: {
                        message: "Home Phone is required",
                        onlyIf: function () {
                            return self.phHomeExt() != null && self.phHomeExt().length > 0;
                        }
                    }
                })

            self.allowSms = ko.observable(false)

            self.phCell = ko.observable("")
                .extend({
                    phoneUS: true
                })
                .extend({
                    required: {
                        message: "Phone Cell is required for Texting",
                        onlyIf: function () {
                            return self.allowSms();
                        }
                    }
                });

            self.phFax = ko.observable("")
                .extend({
                    phoneUS: true
                });

            self.phOtherType = ko.observable("")
            self.phOtherExt = ko.observable("")
            self.phOther = ko.observable("")
                .extend({
                    phoneUS: true
                })
                .extend({
                    required: {
                        message: "Other Phone is required",
                        onlyIf: function () {
                            return self.phOtherExt() != null && self.phOtherExt().length > 0;
                        }
                    }
                });

            self.websiteUrl = ko.observable().extend({ url: { params: false, message: 'Website URL must be a valid URL (Ex: http://www.fnf.com)' } })

            self.phCustServiceExt = ko.observable("")
            self.phCustService = ko.observable("")
                .extend({
                    phoneUS: true
                })
                .extend({
                    required: {
                        message: "Customer Service Phone is required",
                        onlyIf: function () {
                            return self.phCustServiceExt().length > 0;
                        }
                    }
                });

            self.manager = ko.observable("")
            self.sendBirthDayEmailsToCustomers = ko.observable(false);

            self.birthdayMonthList = ko.observableArray([
                { id: "1", name: "January" },
                { id: "2", name: "February" },
                { id: "3", name: "March" },
                { id: "4", name: "April" },
                { id: "5", name: "May" },
                { id: "6", name: "June" },
                { id: "7", name: "July" },
                { id: "8", name: "August" },
                { id: "9", name: "September" },
                { id: "10", name: "October" },
                { id: "11", name: "November" },
                { id: "12", name: "December" },
            ]);

            self.birthdayMonth = ko.observable("");

            self.birthdayDateList = ko.observableArray([{ Value: "1" }, { Value: "2" }, { Value: "3" }, { Value: "4" }, { Value: "5" }, { Value: "6" }, { Value: "7" }, { Value: "8" }, { Value: "9" }, { Value: "10" }, { Value: "11" }, { Value: "12" }, { Value: "13" }, { Value: "14" }, { Value: "15" },
            { Value: "16" }, { Value: "17" }, { Value: "18" }, { Value: "19" }, { Value: "20" }, { Value: "21" }, { Value: "22" }, { Value: "23" }, { Value: "24" }, { Value: "25" }, { Value: "26" }, { Value: "27" }, { Value: "28" }, { Value: "29" }, { Value: "30" }, { Value: "31" }]);

            self.birthdayDate = ko.observable("");


            utlities.getStatesList().done(function (result) {
                self.stateList([]);
                self.stateList(result);
            });

            self.birthdayMonth.extend({
                required: {
                    message: "Birthday Month is required",
                    onlyIf: function () {
                        if (self.birthdayMonth() == undefined && self.birthdayDate() == undefined)
                            return false;
                        else if (self.birthdayMonth() == undefined && self.birthdayDate() != undefined)
                            return true;
                        else
                            return false;
                    }
                }
            });
            self.birthdayDate.extend({
                required: {
                    message: "Birthday Date is required",
                    onlyIf: function () {
                        if (self.birthdayMonth() == undefined && self.birthdayDate() == undefined)
                            return false;
                        else if (self.birthdayMonth() != undefined && self.birthdayDate() == undefined)
                            return true;
                        else
                            return false;
                    }
                }
            });

            // Other Details
            self.userTypeLevel = ko.observable(100);
            self.userType = ko.observable("");

            self.fullName = ko.computed(function () {
                return self.lName() + ", " + self.fName();
            }, self);

            self.operationName = ko.observable("");
            self.userDetailsHtml = ko.observable("");
            self.lastUpdatedDate = ko.observable("");

            self.liabilityAgreementStatus = ko.observable("");

            self.showWellsFargo = ko.computed(function () {
                var userNameValid = self.email().toLowerCase().indexOf('wellsfargo');
                var comapanyNamevalid = self.company().toLowerCase().indexOf('wells fargo');

                if ((self.userTypeLevel() < 7) && (comapanyNamevalid > -1 || userNameValid > -1))
                    return true;
                else
                    return false;
            }, self);


            self.userliabilityDetailsHtml = ko.computed(function () {
                switch (self.liabilityAgreementStatus()) {

                    case 0: return "<span style='color:red'>Not Set</span>";
                        break;
                    case 1: return "<span style='color:Green'>Accepted (" + self.lastUpdatedDate() + ")</span> ";
                        break;
                    case 2: return "<span style='color:red'>Not Accepted (" + self.lastUpdatedDate() + ")</span>";
                        break;
                    default: return "";
                        break;
                }
            }, self);


            self.birthdayMonth.subscribe(function (month) {
                if (month != null && month != "" && month != undefined) {
                    //self.birthDate(moment(new Date(self.birthDate()).setMonth(month - 1)).format("YYYY-MM-DD"));
                    //self.birthdayDate("");

                    if (month == "1" || month == "3" || month == "5" || month == "7" || month == "8" || month == "10" || month == "12") {
                        self.birthdayDateList([]);
                        for (var i = 1; i <= 31; i++)
                            self.birthdayDateList.push({ Value: i });
                    }
                    else if (month == "4" || month == "6" || month == "9" || month == "11") {
                        self.birthdayDateList([]);

                        for (var i = 1; i <= 30; i++)
                            self.birthdayDateList.push({ Value: i });
                    }
                    else if (month == "2") {
                        self.birthdayDateList([]);
                        for (var i = 1; i <= 29; i++)
                            self.birthdayDateList.push({ Value: i });

                        //var year = new Date().getFullYear();
                        //if (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)) {
                        //    for (var i = 1; i <= 29; i++)
                        //        self.birthdayDateList.push({ Value: i });
                        //}
                        //else {
                        //    for (var i = 1; i <= 28; i++)
                        //        self.birthdayDateList.push({ Value: i });
                        //}
                    }
                    // return monthList;

                }
                else if (month == "") {
                    self.birthdayDate("");
                }
            });



            self.update = function (data) {
                if (data) {
                    var PhoneServiceWithExt = [];
                    if (data.PhoneService != null && data.PhoneService != "" && data.PhoneService.length > 0) {
                        if (data.PhoneService.indexOf('x') > -1) {
                            PhoneServiceWithExt = data.PhoneService.split('x');
                            if (PhoneServiceWithExt.length == 0) {
                                PhoneServiceWithExt.push("");
                                PhoneServiceWithExt.push("");
                            }
                            else if (PhoneServiceWithExt.length == 1) {
                                PhoneServiceWithExt.push("");
                            }
                        }
                        else {
                            PhoneServiceWithExt.push(data.PhoneService);
                            PhoneServiceWithExt.push("");
                        }
                    }
                    else {
                        PhoneServiceWithExt.push("");
                        PhoneServiceWithExt.push("");
                    }
                    self.clientID(data.OnlineOrderId);
                    self.fName(data.FirstName);
                    self.mName(data.MiddleName);
                    self.lName(data.LastName);

                    if (data.Birthday != null && data.Birthday != undefined) {
                        if (moment(data.Birthday).year() == 0001) {
                            self.birthdayMonth("");
                            self.birthdayDate("");
                            //self.birthDate(new Date(date).setFullYear(1900));
                        }
                        else {
                            self.birthdayMonth(new Date(data.Birthday).getMonth() + 1);
                            self.birthdayDate(new Date(data.Birthday).getDate());
                        }
                        self.birthDate(data.Birthday);
                    }
                    else {
                        self.birthdayDate("");
                        self.birthdayMonth("");
                    }
                    //self.birthDate(data.Birthday);
                    self.email(data.EmailAddress);
                    self.acceptsBulkEmail(data.AcceptsBulkEmail);
                    self.enableTitlePro247CustActivityEmailNotifications(data.AcceptsTitleProNotifications);
                    self.enableProfileLowLimitEmailNotifications(data.AcceptsLowLimitNotifications);
                    self.exemptFromWellsFargoResetProcess(data.WellsFargoResetExempt);
                    self.lowLimitSetting(data.LowLimitNotificationSetting);
                    self.address(data.StreetAddress1);
                    self.suiteNo(data.StreetAddress2);
                    self.zipCode(data.Zip);
                    self.city(data.City);
                    self.state(data.State);
                    self.county(data.County);
                    self.company(data.CompanyName);
                    self.occupation(data.OccupationId);
                    self.phWorkExt(data.PhoneWorkExt);
                    self.phWork(data.PhoneWork);
                    self.phHomeExt(data.PhoneHomeExt);
                    self.phHome(data.PhoneHome);
                    self.phCell(data.PhoneCell);
                    self.allowSms(data.AllowSMS);
                    self.phFax(data.PhoneFax);
                    self.phOtherType(data.PhoneOtherType);
                    self.phOtherExt(data.PhoneOtherExt);
                    self.phOther(data.PhoneOther);
                    self.websiteUrl(data.WebsiteUrl);
                    self.phCustServiceExt(PhoneServiceWithExt[1]);
                    self.phCustService(PhoneServiceWithExt[0]);
                    self.sendBirthDayEmailsToCustomers(data.BirthdayEmail);

                    //other Details
                    self.userTypeLevel(data.UserType.Level);
                    self.userType(data.UserType.Name);
                    if (data.SystemInfo.Operation !== null && data.SystemInfo.Operation.Name !== null)
                        self.operationName(data.SystemInfo.Operation.Name);
                    if (data.SystemInfo.User !== null && data.SystemInfo.User.LiabilityAgreementStatus !== null) {
                        self.liabilityAgreementStatus(data.SystemInfo.User.LiabilityAgreementStatus.AgreeStatus);
                        self.lastUpdatedDate(data.SystemInfo.User.LiabilityAgreementStatus.Updated);
                    }

                }
            };
            self.occupationList = ko.observableArray([
                { occupationId: "11", occupationName: "Appraiser" },
                { occupationId: "4", occupationName: "Attorney" },
                { occupationId: "8", occupationName: "Broker" },
                { occupationId: "10", occupationName: "Builder/Developer" },
                { occupationId: "9", occupationName: "Commercial Sales" },
                { occupationId: "13", occupationName: "Escrow" },
                { occupationId: "14", occupationName: "FSBO" },
                { occupationId: "12", occupationName: "Government" },
                { occupationId: "6", occupationName: "Insurance" },
                { occupationId: "2", occupationName: "Investor" },
                { occupationId: "5", occupationName: "Lender" },
                { occupationId: "7", occupationName: "Other" },
                { occupationId: "1", occupationName: "Real Estate" },
                { occupationId: "3", occupationName: "Title Agent" }])

            self.occupation = ko.observable(undefined)
                .extend({ required: { message: 'Occupation is required.' } })

            self.phOtherTypeList = ko.observableArray([
                { phTypeName: "Pager" },
                { phTypeName: "Work" },
                { phTypeName: "Home" },
                { phTypeName: "Cell" },
                { phTypeName: "Fax" },
                { phTypeName: "Other" }])

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }
            //self.birthDate.subscribe(function (date) {
            //    /*
            //    if (date.indexOf('T') > -1) {
            //        self.birthDate(date.split('T')[0]);
            //    }*/
            //    if (moment(date).year() < 1900) {
            //        self.birthdayMonth("");
            //        self.birthdayDate("");
            //        self.birthDate(new Date(date).setFullYear(1900));
            //    }
            //    else
            //        self.birthDate(moment(date).format("YYYY-MM-DD"));

            //});



            self.lastUpdatedDate.subscribe(function (date) {
                if (date != null && date != "undefined" && date != "") {
                    if (date.indexOf('T') > -1) {
                        if (date.lastIndexOf('.') > -1) {
                            self.lastUpdatedDate(date.replace('T', ' ').substring(0, date.lastIndexOf('.')));
                        }
                        else {
                            self.lastUpdatedDate(date.replace('T', ' '));
                        }
                    }
                }
            });

            //self.phCustService.subscribe(function (phone) {
            //    if (phone != null && phone != "" && phone.indexOf('x') > -1) {
            //        self.phCustService(phone.split('x')[0]);
            //        self.phCustServiceExt(phone.split('x')[1]);
            //    }
            //});


            self.reset = function () {
                self.clientID("");
                self.fName("");
                self.mName("");
                self.lName("");
                self.birthDate(undefined);
                self.birthdayDate("");
                self.birthdayMonth("");
                self.email("");
                self.acceptsBulkEmail(false);
                self.enableTitlePro247CustActivityEmailNotifications(false);
                self.enableProfileLowLimitEmailNotifications(false);
                self.exemptFromWellsFargoResetProcess(false);
                self.lowLimitSetting(false);
                self.address("");
                self.suiteNo("");
                self.zipCode("");
                self.city("");
                self.state("");
                self.county("");
                self.fips("");
                self.company("");
                self.occupation(undefined);
                self.phWorkExt("");
                self.phWork("");
                self.phHomeExt("");
                self.phHome("");
                self.phCell("");
                self.allowSms(false);
                self.phFax("");
                self.phOtherType("");
                self.phOtherExt("");
                self.phOther("");
                self.websiteUrl("");
                self.phCustServiceExt("");
                self.phCustService("");
                self.sendBirthDayEmailsToCustomers(false);
                self.userTypeLevel(100);
                self.manager("");

                self.userType("");
                self.operationName("");
                self.liabilityAgreementStatus("");
                self.lastUpdatedDate("");
                self.userliabilityDetailsHtml("")

                ko.validation.group(self).showAllMessages(false);
            }
        }

        function socialMedia() {
            var self = this;
            self.socialMediaId = ko.observable(0);
            self.name = ko.observable("");
            self.imageUrl = ko.observable("");
            self.active = ko.observable(false);
            self.siteUrl = ko.observable("").extend({
                pattern: {
                    message: "URL is Invalid for : Facebook -- Example: https://www.facebook.com/YourUniqueID",
                    params: '^[Hh][Tt][Tt][Pp]([Ss])?://(www\.)?(facebook|fb)\.com\/[A-z 0-9 _]+\/?',
                    onlyIf: function () {
                        return self.socialMediaId() === 1;
                    }
                }
            })
                .extend({
                    pattern: {
                        message: "URL is Invalid for : Twitter -- Example: https://twitter.com/YourUniqueID",
                        params: '^[Hh][Tt][Tt][Pp]([Ss])?:\/\/([\w]+\.)?twitter\.com\/[A-z 0-9 _]+\/?',
                        onlyIf: function () {
                            return self.socialMediaId() === 2;
                        }
                    }
                })
                .extend({
                    pattern: {
                        message: "URL is Invalid for : LinkedIn -- Example: http://www.linkedin.com/in/YourUniqueID",
                        params: '^[Hh][Tt][Tt][Pp]([Ss])?:\/\/(www\.)linkedin\.com\/(in|pub)\/[A-z 0-9 _]+\/?',
                        onlyIf: function () {
                            return self.socialMediaId() === 3;
                        }
                    }
                })
                .extend({
                    pattern: {
                        message: "URL is Invalid for : Google \+ -- Example: https://plus.google.com/u/0/YourUniqueID",
                        params: '^[Hh][Tt][Tt][Pp]([Ss])?:\/\/plus\.google\.com\/[A-z0-9_]+\/?',
                        onlyIf: function () {
                            return self.socialMediaId() === 4;
                        }
                    }
                })
                .extend({
                    pattern: {
                        message: 'Special characters < > " and Space not allowed',
                        params: /^[^<>\s"]*$/
                    }
                });

            self.reset = function () {
                self.socialMediaId(0);
                self.name("");
                self.imageUrl("");
                self.active(false);
                self.siteUrl("");

                ko.validation.group(self).showAllMessages(false);
            };

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            };
        }

        var vm = {
            userInfo: userInfo,
            socialMedia: socialMedia
        }
        return vm;
    });
