define('businessCardModel',["knockout"],
    function (ko) {
        function bCardUserInfo() {
            var self = this;
        }

        function bCardUserSocialMedia() {
            var self = this;

            self.socialMediaId = ko.observable(0);
            self.name = ko.observable("");
            self.imageUrl = ko.observable("");
            self.active = ko.observable(false);
            self.siteUrl = ko.observable("");

            self.reset = function () {
                self.socialMediaId(0);
                self.name("");
                self.imageUrl("");
                self.active(false);
                self.siteUrl("");
            };
        }

        function bCardUserMyOtherWebLink() {
            var self = this;
            self.webLinkName = ko.observable("");
            self.webLinkURL = ko.observable("");
            self.reset = function () {
                self.webLinkName("");
                self.webLinkURL("");
            };
        }

        var vm = {
            bCardUserInfo: bCardUserInfo,
            bCardUserSocialMedia: bCardUserSocialMedia,
            bCardUserMyOtherWebLink: bCardUserMyOtherWebLink,
        }

        return vm;
    });
