define('viewmodels/orderModule/residentialEscrow',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'commonDialogModel', 'residentialEscrow', 'customerInformationModel', 'utlities', 'commonOrderModel'],
 function (app, router, $, ko, validate, iCheck, config, validation, Q, https, session, alert, commonDialog, model, CustomerInformation, utlities, commonOrderModel) {

     var customerInformationModel = new CustomerInformation();
     var escrowInfoModel = new model.EscrowInformation();
     var sellerLenderInfoModel = new model.SellerLenderInformation();
     var buyerBorrowerInfoModel = new model.BuyerBorrowerInformation();
     var additionalInfoModel = new model.AdditionalInformation();
     var financingInfoModel = new model.FinancingInformation();
     var instructionsInfoModel = new model.InstructionsInformation();

     var submitOrderModel = new commonOrderModel.SubmitOrder();

     var commonDialogModel = new commonDialog("orderSubmitDialog");
     var orderSubmitted = ko.observable(false);
     var orderTitle = ko.observable('Residential Escrow');

     var currentView = ko.observable('');
     var currentOrderModels = ko.observableArray([]);
     var currentViewIndex = ko.observable(-1);
     var currentOrderModel = ko.observable();
     var selectedOrderType = ko.observable(0);
     var selectedOrderTypeName = ko.observable("");
     var stateList = ko.observableArray();

     var impoundAccount = ko.observable("");

     impoundAccount.subscribe(function (value) {
         console.log(value);
         if (value === "reimbursedtoSeller") {
             instructionsInfoModel.reimbursedtoSeller(true);
             instructionsInfoModel.transferredtoBuyer(false);
         }
         else if (value === "transferredtoBuyer") {
             instructionsInfoModel.reimbursedtoSeller(false);
             instructionsInfoModel.transferredtoBuyer(true);
         }
         else {
             instructionsInfoModel.reimbursedtoSeller(false);
             instructionsInfoModel.transferredtoBuyer(false);
         }
     });

     currentOrderModel.subscribe(function (model) {
         vm.validationErrors = ko.validation.group([model]).extend({
             notify: 'always'
         });
         currentView(model.viewName);
         selectedOrderTypeName(model.title);
         session.handleFocusOnTabChange(model.ModelId, model.viewName);
     });

     currentViewIndex.subscribe(function (index) {
         currentOrderModel(currentOrderModels()[index]);
     });

     sellerLenderInfoModel.sellerLenderZip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                 if (data.StateAbbr != null || data.City != null) {
                     sellerLenderInfoModel.sellerLenderState(data.StateAbbr)
                     sellerLenderInfoModel.sellerLenderCity(data.City)
                 }
                 else {
                     alert.error("Invalid Zip.")
                     sellerLenderInfoModel.sellerLenderZip('');
                     sellerLenderInfoModel.sellerLenderState('')
                     sellerLenderInfoModel.sellerLenderCity('')
                 }
             }
             else
    session.zipCodeServiceDown();
             })
         }
     })

     buyerBorrowerInfoModel.buyerBorrowerZip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     if (data.StateAbbr != null || data.City != null) {
                         buyerBorrowerInfoModel.buyerBorrowerCity(data.City)
                         buyerBorrowerInfoModel.buyerBorrowerState(data.StateAbbr)
                     }
                     else {
                         alert.error("Invalid Zip.")
                         buyerBorrowerInfoModel.buyerBorrowerZip('');
                         buyerBorrowerInfoModel.buyerBorrowerCity('')
                         buyerBorrowerInfoModel.buyerBorrowerState('')
                     }
                 }
                 else
                     session.zipCodeServiceDown();
             })
         }
     })

     var vm = {

         activate: activate,
         canDeactivate: canDeactivate,
         goBack: goBack,
         title: 'Residential Escrow',

         orderTitle: orderTitle,

         currentView: currentView,
         currentOrderModels: currentOrderModels,
         currentViewIndex: currentViewIndex,
         currentOrderModel: currentOrderModel,
         selectedOrderTypeName: selectedOrderTypeName,

         moveNext: moveNext,
         movePrevious: movePrevious,
         submitOrder: submitOrder,
         navigateButtonClick: navigateButtonClick,
         commonDialogModel: commonDialogModel,

         customerInformationModel: customerInformationModel,
         escrowInfoModel: escrowInfoModel,
         sellerLenderInfoModel: sellerLenderInfoModel,
         buyerBorrowerInfoModel: buyerBorrowerInfoModel,
         additionalInfoModel: additionalInfoModel,
         financingInfoModel: financingInfoModel,
         instructionsInfoModel: instructionsInfoModel,
         submitOrderModel: submitOrderModel,
         stateList: stateList,
         getUSPhoneFormat: utlities.getUSPhoneFormat,
         getUSPhoneFormatWithExtn: utlities.getUSPhoneFormatWithExtn,
         session: session,
         compositionComplete: function () {
             session.handleFocusOnTabChange(customerInformationModel.ModelId, customerInformationModel.viewName);
         },
         impoundAccount: impoundAccount,
     }
     return vm;


     function activate(orderType) {
         orderSubmitted(false);
         impoundAccount("");
         selectedOrderType(parseInt(orderType));
         setupOrder();
         currentViewIndex(0);
         if (session.userName() != undefined && session.userName() != '') {
             getCustomerInfo(session.userId, session.defaultOpertionId());
         }
         utlities.getStatesList().done(function (result) {
             stateList([]);
             stateList(result);

         });
         session.isBusy(false);
     }

     function canDeactivate() {
         if (!session.hasLoggedOut() && !orderSubmitted()) {
             var title = 'Cancel Order?';
             var msg = 'Navigate away and cancel the order? ';
             session.isLogoutVisible(false);
             return app.showMessage(msg, title, ['Yes', 'No '])
                 .then(function (selectedOption) {
                     if (selectedOption === 'Yes') {
                         // Call some cancel function
                     }
                     session.isLogoutVisible(true);
                     return selectedOption;
                 });
         }
         else {
             return true;
         }
     };

     function setupOrder() {
         currentOrderModels([]);
         currentOrderModels.push(customerInformationModel);
         currentOrderModels.push(escrowInfoModel);
         currentOrderModels.push(sellerLenderInfoModel);
         currentOrderModels.push(buyerBorrowerInfoModel);
         currentOrderModels.push(additionalInfoModel);
         currentOrderModels.push(financingInfoModel);
         currentOrderModels.push(instructionsInfoModel);
         currentOrderModels.push(submitOrderModel);
         resetModels();
     }

     function moveNext() {
         // commented for development, this need to be removed.
         vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
         if (vm.validationErrors().length > 0) {
             vm.validationErrors.showAllMessages();
             return;
         }
         currentViewIndex(currentViewIndex() + 1);

         if (currentViewIndex() == currentOrderModels().length - 1)
             buildPreviewModel();
     }

     function movePrevious() {
         currentViewIndex(currentViewIndex() - 1);
     }

     function navigateButtonClick(item, event) {
         if (item.index() > currentViewIndex()) {

             for (var i = 0; i < item.index() ; i++) {
                 // commented for development, this need to be removed.
                 vm.validationErrors = ko.validation.group(currentOrderModels()[i]);
                 if (vm.validationErrors().length > 0) {
                     vm.validationErrors.showAllMessages();
                     return;
                 }
             }
         }
         currentViewIndex(item.index());

         if (currentViewIndex() == currentOrderModels().length - 1)
             buildPreviewModel();
     }

     function goBack(complete) {
         router.navigateBack();
     }

     function resetModels() {
         for (var i = 0; i < currentOrderModels().length; i++) {
             currentOrderModels()[i].index(i);

             // skip order information view reset
             if (i > 0)
                 currentOrderModels()[i].reset();
         }
     }

     function getCustomerInfo(userId, opertionId) {
         return utlities.getOperationUserInfo(userId, opertionId).done(function (data) {
             var currdate = new Date();
             var currdate = (currdate.getMonth() + 1) + '/' + currdate.getDate() + '/' + currdate.getFullYear();
             customerInformationModel.orderDate(currdate);
             utlities.getManagerFullName(data.OperationId, data.UserId).done(function (result) {
                 customerInformationModel.salesRep(result);
             })
             customerInformationModel.fullName(data.FirstName + String.fromCharCode(160) + data.LastName);
             customerInformationModel.email(data.Email);
             customerInformationModel.company(data.Company);
             customerInformationModel.address(data.StreetAddress1 + String.fromCharCode(160) + data.StreetAddress2 + String.fromCharCode(160) + data.City + ',' + String.fromCharCode(160) + data.State + String.fromCharCode(160) + data.Zip);
             customerInformationModel.phon(data.PhoneWork);
             customerInformationModel.ext(data.PhoneWorkExt);
             customerInformationModel.fax(data.PhoneFax);

         })

     }

     function buildPreviewModel() {
         for (var i = 0; i < currentOrderModels().length; i++) {
             if (currentOrderModels()[i].GetModelData) {
                 currentOrderModels()[i].GetModelData();
             }
         }
     }

     function submitOrder() {
         session.isBusy(true);
         var url = config.submitResidentialEscrowUrl;
         var request = {
             //Order Model Information -- Required
             "BrandId":session.brandId,
             "OrderId": "-1",
             "UserId": session.userId,
             "ServiceId": "60",
             "OperationId": session.defaultOpertionId(),
             "CreateDate": new Date(),
             "PropertyAddress":"",
             "PropertyCity": "",
             "PropertyState": "",
             "PropertyZip": "",

             //Order Model Information -- Fill If Present
             "BackendSystem": "",
             "EscrowOfficerId": "",
             "TitleOfficerId": "",
             "IsBackendOrder": "",
             "PaymentId": "",

             //Customer Information
             "CustomerSalesRep": customerInformationModel.salesRep,
             "CustomerUserFullName": customerInformationModel.fullName,
             "CustomerUserEmail": customerInformationModel.email,
             "CustomerUserCompany": customerInformationModel.company,
             "CustomerUserAddress": customerInformationModel.address,
             "CustomerUserPhone":
                {
                    "PhoneNumber": customerInformationModel.phon(),
                    "Extension": customerInformationModel.ext(),
                    "PhoneType": "Business"
                },
             "CustomerUserFax":
             {
                 "PhoneNumber": customerInformationModel.fax(),
                 "Extension": "",
                 "PhoneType": "Fax"
             },
             //Escrow Information
             "EscrowOfficer": escrowInfoModel.escrowOfficer,
             "CloseDate": escrowInfoModel.escrowCloseDate,

             //Seller/Lender Information
             "SellerLenderName": sellerLenderInfoModel.sellerLender,
             "SellerLenderAddress": sellerLenderInfoModel.sellerLenderAddress,
             "SellerLenderCity": sellerLenderInfoModel.sellerLenderCity,
             "SellerLenderState": sellerLenderInfoModel.sellerLenderState,
             "SellerLenderZip": sellerLenderInfoModel.sellerLenderZip,
             "SellerLenderPhone":
               {
                   "PhoneNumber": sellerLenderInfoModel.sellerLenderPhone,
                   "Extension": sellerLenderInfoModel.ext,
                   "PhoneType": "Home"
               },
             "SellerLenderFax":
             {
                 "PhoneNumber": sellerLenderInfoModel.sellerLenderFax,
                 "Extension": "",
                 "PhoneType": "Fax"
             },
             "SellerLenderEmail": sellerLenderInfoModel.sellerLenderEmailAddress,

             //Buyer/Borrower Information
             "BuyerBorrowerName": buyerBorrowerInfoModel.buyerBorrower,
             "BuyerBorrowerAddress": buyerBorrowerInfoModel.buyerBorrowerAddress,
             "BuyerBorrowerCity": buyerBorrowerInfoModel.buyerBorrowerCity,
             "BuyerBorrowerState": buyerBorrowerInfoModel.buyerBorrowerState,
             "BuyerBorrowerZip": buyerBorrowerInfoModel.buyerBorrowerZip,
             "BuyerBorrowerPhone":
               {
                   "PhoneNumber": buyerBorrowerInfoModel.buyerBorrowerPhone,
                   "Extension": buyerBorrowerInfoModel.ext,
                   "PhoneType": "Home"
               },
             "BuyerBorrowerFax":
             {
                 "PhoneNumber": buyerBorrowerInfoModel.buyerBorrowerFax,
                 "Extension": "",
                 "PhoneType": "Fax"
             },
             "BuyrBorrowerEmail": buyerBorrowerInfoModel.buyerBorrowerEmailAddress,

             //Additional Information
             "HoldTitle": additionalInfoModel.buyerHoldtitle,
             "LegalDescription": additionalInfoModel.propertyAddress,

             //Financing Information
             "SalePrice": financingInfoModel.SalePrice,
             "InitialDeposit": financingInfoModel.InitialDeposit,
             "Financing": financingInfoModel.Financing,
             "FHA": financingInfoModel.fha,
             "VA": financingInfoModel.va,
             "Conv": financingInfoModel.conv,
             "CalVet": financingInfoModel.calVet,
             "SellerCarryBack": financingInfoModel.sellercarryback,
             "OtherFinancing": financingInfoModel.other,
             "IfNewFinancing": "",
             "IfOtherFinancing": financingInfoModel.describe,

             //Instructions Information
             "ProrateToClose": instructionsInfoModel.closeEscrow,
             "ProrateToTaxes": instructionsInfoModel.taxes,
             "ProrateToInterest": instructionsInfoModel.interest,
             "ProrateToRents": instructionsInfoModel.rents,
             "ProrateToOther": instructionsInfoModel.other,
             "ProrateToOtherDescription":instructionsInfoModel.describe,
             "ImpoundToSeller": instructionsInfoModel.reimbursedtoSeller,
             "ImpoundToBuyer": instructionsInfoModel.transferredtoBuyer,
             "ReCommission":instructionsInfoModel.realEstateBrokers,
             "ReCommissionToBePaidTo":instructionsInfoModel.tobePaidto,
             "OtherTerms":instructionsInfoModel.otherterms

         }

         https.post(url, request)
            .then(function (data) {
                if (data.HasErrors == false) {
                    orderSubmitted(true);
                    commonDialogModel.IsOrderDialog(true);
                    commonDialogModel.orderId(data.OrderId);
                    if (data.IntendedRecpients)
                        commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                    commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                }
                //else {
                //    for (var i = 0; i < data.Messages.length; i++) {
                //        if (data.Messages[i].ExceptionMessage != "") {
                //            console.log("Error:" + data.Messages[i].ExceptionMessage, orderTitle());
                //            alert.error('Internal Error. Please contact Administrator.');
                //            orderSubmitted(false);
                //            session.isBusy(false);
                //        }
                //    }
                //}
                else {
                    orderSubmitted(false);
                    session.isBusy(false);
                    var msgSuccess = "";
                    var msgError = "";

                    for (var i = 0; i < data.Messages.length; i++) {
                        if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                            //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                            //alert.error('Internal Error. Please contact Administrator.');
                            if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                msgError = msgError + data.Messages[i].Message + "\n";
                            }
                        }
                        else {
                            msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                        }
                    }
                    //alert.error(msgSuccess, orderTitle());
                    //alert.error(msgError, orderTitle());
                    if (msgSuccess.length > 0) {
                        orderSubmitted(true);
                        commonDialogModel.IsOrderDialog(true);
                        commonDialogModel.orderId(data.OrderId);
                        msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                        commonDialogModel.messagehtml(msgError);
                        if (data.IntendedRecpients)
                            commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                        commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                    }
                    else if (msgError.length > 0) {
                        alert.error(msgError, orderTitle());
                    }
                }
            })
             .fail(function (data) {
                 var error = JSON.parse(data.responseText)
                 console.log(error);
                 session.isBusy(false);
             })
     }

     function extractErrorMsg(data) {
         var exceptionMsg;
         for (var i = (data.Messages.length - 1) ; i >= 0; i--) {
             if (data.Messages[i].ExceptionMessage != "") {
                 exceptionMsg = data.Messages[i].ExceptionMessage;
             }
         }
         return exceptionMsg;
     }

  
 });
