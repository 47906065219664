define('featureUpdate',["knockout"],
    function (ko) {

        function featureUpdate() {
            var self = this;
            self.Id = ko.observable(0);
            self.BrandId = ko.observable(0);
            self.OperationId = ko.observable(0);
            self.featureUpdatesList = ko.observableArray([]);
            self.IsNetSellersUser = ko.observable(false);
            self.IsEmployee = ko.observable(false);
            self.Name = ko.observable("")
                .extend({ required: { message: 'Name is required.' } })
                //.extend({
                //    pattern: {
                //        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                //        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,50}$'

                //    }
                //});
            self.Description = ko.observable("")
                .extend({ required: { message: 'Description is required.' } })
                //.extend({
                //    pattern: {
                //        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                //        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,100}$'
                //    }
                //});

            self.VideoFileUrl = ko.observable("")
                .extend({
                    pattern: {
                        message: 'Only .wmv video files can be uploaded.',
                        params: '.*(\.[Ww][Mm][Vv])'
                    }
                });
            self.DocumentFileUrl = ko.observable("")
                .extend({
                    pattern: {
                        message: 'Only .docx Word documents can be uploaded.',
                        params: '.*(\.[Dd][Oo][Cc][Xx])'
                    }
                });
            self.DateAdded = ko.observable("");
            self.IsActive = ko.observable(false);


            self.reset = function() {
                self.Id("");
                self.BrandId("");
                self.OperationId("");
                self.Name("");
                self.Description("");
                self.VideoFileUrl("");
                self.DocumentFileUrl("");
                self.IsNetSellersUser(false);
                self.IsEmployee(false);
                self.DateAdded("");
                self.IsActive(false);
                ko.validation.group(self).showAllMessages(false);
            }


            self.isValid = function() {
                return ko.validation.group(self).length == 0;
            };

            self.update = function(data) {
                if (data) {
                    self.Id(data.Id);
                    self.BrandId(data.BrandId);
                    self.OperationId(data.OperationId);
                    self.IsNetSellersUser(data.IsNetSellersUser);
                    self.IsEmployee(data.IsEmployee);
                    self.Name(data.Name);
                    self.Description(data.Description);
                    self.VideoFileUrl(data.VideoFileUrl);
                    self.DocumentFileUrl(data.DocumentFileUrl);
                    self.IsActive(data.IsActive);

                }
            }

            self.update = function(data) {
                if (data) {
                    self.Id(data.Id);
                    self.BrandId(data.BrandId);
                    self.OperationId(data.OperationId);
                    self.IsNetSellersUser(data.IsNetSellersUser);
                    self.IsEmployee(data.IsEmployee);
                    self.Name(data.Name);
                    self.Description(data.Description);
                    self.VideoFileUrl(data.VideoFileUrl);
                    self.DocumentFileUrl(data.DocumentFileUrl);
                    self.IsActive(data.IsActive);

                }
            }
        }

        var vm = {
            featureUpdate: featureUpdate
        };

        return vm;

    });
