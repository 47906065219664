define('viewmodels/propertySearchModule/ownerSearch',['durandal/app', 'knockout', 'plugins/http', 'config', 'alertNotification', 'propertySearchCommon'], function (app, ko, https, config, alert, propertySearchCommon) {
    var searchResults = ko.observableArray();

    var vm = {
        
        addressSearch: addressSearch,
        searchResults: searchResults,
        compositionComplete: compositionComplete
    };

    vm.searchResults.subscribe(propertySearchCommon.alertMultiplePropertyWarning);

    function compositionComplete() {
        session.validateInput();
    }


    function addressSearch(parentResultHolder, ownerModel, isSearchable) {
        var url = config.propertySearchUrl;
        var request = {
            Zip: ownerModel.zip().trim(),
            State: ownerModel.state,
            County: ownerModel.county(),
            OwnerFirstName: ownerModel.firstName().trim(),
            OwnerLastName: ownerModel.lastName().trim(),
            Fips: ownerModel.county(),
            SearchType: 'Owner'
            
        };

        return https.post(url, request)
            .then(function (data) {
                searchResults(data);
                parentResultHolder(data);
            })
            .always(function () {
                isSearchable(true);
            })
            .fail(Failed);
        
    }

    function succeeded(data) {
        searchResults(data);
    }

    function Failed(error) {
        alert.error(error.statusText);
    }

    return vm;
});


