define('viewmodels/userProfileModule/manageContacts',['durandal/app', 'plugins/router', 'knockout', 'contactsModel', 'config', 'global/session', 'plugins/http', 'utlities', 'alertNotification'],
    function (app, router, ko, model, config, session, https, utlities, alerter) {

        console.log('Manage Contacts VM - called');



        var contactsModel = new model.ContactsModel();
        var stateList = ko.observableArray();
        var isLoading;

        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            contactsModel: contactsModel,
            saveContact: saveContact,
            cancel: cancel,
            goBack: goBack,
            stateList: stateList,
            editMode: false,
            serverState: null,
            compositionComplete: compositionComplete,
        };

        function compositionComplete() {
            session.validateInput();
        }

        function activate() {
            console.log('Frequent Contacts - activated');
            contactsModel.errors.showAllMessages(false);
            getContactTypes();
            utlities.getStatesList().done(function (result) {
                stateList([]);
                stateList(result);
            });


            var url = window.location.href;
            if (url.indexOf("contactId=") != -1) {
                vm.editMode = true;
                var array = url.split("contactId=");
                if (array[1]) {
                    getFrequentContact(array[1]);
                }
                //go to grid
            }
            else {
                vm.editMode = false;
                contactsModel.contactId(0);
            }

        }

        function canDeactivate() {
            contactsModel.Reset();
            return true;
        }

        function getContactTypes() {
            var url = config.getFrequentContactTypesUrl;

            https.get(url, { format: 'json' })
                .then(function (data) {
                    console.log(data);
                    contactsModel.contactTypes([]);
                    contactsModel.contactTypes(data);
                })
                .fail(function (data) {
                    console.log(data);

                });
        }

        function getFrequentContact(id) {
            contactsModel.contactId(id);
            isLoading = true;
            var url = config.getFrequentContactByIdUrl;
            https.get(url, {
                FrequentContactId: id,
                format: 'json'
            })
                .then(function (data) {
                    console.log(data);
                    if (data != null && data != undefined) {
                        vm.serverState = data;
                        contactsModel.Update(data);
                        contactsModel.contactType(data.FreqContactTypeId);
                    }
                    else {
                        alerter.error("An error occurred while retriving the contact");
                        router.navigate('#freqContacts');
                    }
                })
                .fail(function (data) {
                    console.log(data);
                    router.navigate('#freqContacts');
                })
                .always(function () {
                    isLoading = false;
                });
        }


        function cancel() {
            if (vm.editMode)
                contactsModel.Update(vm.serverState);
            else
                contactsModel.Reset();
        }

        function goBack() {
            router.navigate('#freqContacts');
        }

        //function updateContact() {

        //}

        function saveContact() {

            if (!contactsModel.isValid()) {
                contactsModel.errors.showAllMessages();
                return;
            }

            var url = config.saveFrequentContactUrl;

            var request = {
                "FreqContactId": contactsModel.contactId(),
                "UserId": session.userId,
                "FirstName": contactsModel.fName(),
                "LastName": contactsModel.lName(),
                "Email": contactsModel.email(),
                "Address": contactsModel.address(),
                "City": contactsModel.city(),
                "State": contactsModel.state(),
                "Zip": contactsModel.zip(),
                "Company": contactsModel.company(),
                "Phone": utlities.unmaskPhoneNumber(contactsModel.phone),
                "PhoneExt": contactsModel.extn(),
                "Fax": utlities.unmaskPhoneNumber(contactsModel.fax),
                "FreqContactTypeId": contactsModel.contactType(),
                "MortgageBroker": contactsModel.mortgageBroker()
            }

            return https.post(url, request)
                .then(function (data) {
                    console.log(data);
                    if (data.HasErrors == false) {
                        if (data.Messages["0"].Message) {
                            alerter.success(data.Messages["0"].Message);
                            if (vm.editMode)
                                vm.serverState = request;
                            else
                                contactsModel.Reset();
                        }
                    }
                    else alerter.error("An error occurred while saving contact");
                })
                .fail(function (data) {
                    console.log(data);
                    alerter.error("An error occurred while saving contact");
                });

        }





        contactsModel.zip.subscribe(function (zip) {

            if (vm.editMode && isLoading)
                return;

            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            contactsModel.city(data.City);
                            contactsModel.state(data.StateAbbr);
                        }
                        else {
                            alerter.error("Invalid Zip.")
                            contactsModel.zip('');
                            contactsModel.city(undefined);
                            contactsModel.state('');
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })
            }
        });

        console.log('Manage Contacts VM - returned');

        return vm;
    });
