define('propertyDetailService',['config', 'Q', 'plugins/http', 'global/session', 'purchaseableReportShopItem', 'propertyDetailResponse'],
    function (config, Q, https, session, purchaseableReportShopItem, propertyDetailResponse) {

        function getPropertyDetailsById(propertyId) {

            var url = config.getPropertyDetailUrl;

            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                propertyId: propertyId,
                format: 'json'
            }).done(function (data) {
                var converted = loadData(data);
                hasAnyProduct(converted);
                haslpsCredit(converted);
                var buttonVisibility = session.hasLPSCredit() || session.isEmployee();
                session.showProfileButtons(buttonVisibility)
                return converted;
            })
              .fail(function () {
                  if (session != undefined
                      && session.userId != undefined && session.userId.length > 0
                      && session.defaultOpertionId() != null && session.defaultOpertionId() > 0)
                    alert('Error getting property detail');
              });

        }

        function loadData(result) {
            var response = new propertyDetailResponse();

            response.property(result.Property);
            //   console.log(property());

            // load to local array with additional property of added to cart or not.
            var converted = loadPurchaseableReportsToKOArray(result.ProductServicesProducts);
            response.productServicesProducts(converted);
            //response.productServicesProducts(result.ProductServicesProducts);
            response.serviceProducts(result.ServiceProducts);
            response.walletBalance(result.WalletBalance);
            response.creditBalance(result.CreditBalance);
            response.walletEnabled(result.WalletEnabled);
            response.creditsEnabled(result.CreditsEnabled);
            response.tax(result.Tax);
            response.hasGetMethodError(result.HasErrors);
            return response;
        }

        function loadPurchaseableReportsToKOArray(purchaseableReports) {
            var purchaseableReportsWithCartStatus = [];

            for (var i = 0; i < purchaseableReports.length; i++) {
                var item = new purchaseableReportShopItem();
                item.Id(purchaseableReports[i].Id);
                item.Name(purchaseableReports[i].Name);
                item.Price(formatCurrency(purchaseableReports[i].Price));
                item.Credits(getCreditString(purchaseableReports[i].Credits));
                item.UseCredits(purchaseableReports[i].UseCredits);
                item.SampleURL(purchaseableReports[i].SampleUrl);
                item.DescriptionURL(purchaseableReports[i].DescriptionUrl);
                item.AddedToCart(false);
                purchaseableReportsWithCartStatus.push(item);
            }
            return purchaseableReportsWithCartStatus;
        }

        function formatCurrency(value) {
            value = parseFloat(value).toFixed(2);
            return "$" + value;
        }

        function getPriceString(value) {
            if (parseFloat(value) == 0) {
                return "Free";
            }
            else {
                return formatCurrency(value);
            }
        }

        function getCreditString(value) {
            if (parseInt(value) == 0) {
                return ""
            }
            else if (parseInt(value) < 0) {
                return 0;
            }
            else {
                return value;
            }
        }

        function getRemainingCredits(used, assigned) {

            var remaining = (parseInt(assigned) - parseInt(used))
            if (remaining <= 0) {
                remaining = 0;
            }
            return remaining + ' of ' + assigned + ' remaining';
        }

        function getRemainingCreditsValue(used, assigned) {

            var remaining = (parseInt(assigned) - parseInt(used))
            if (remaining <= 0) {
                remaining = 0;
            }
            return remaining;
        }


        function getAddressString(propertyData) {
            //console.log(propertyData);

            var addressString = '';



            if (propertyData) {

                //if (propertyData.Address1 && propertyData.Address1.length > 0)
                //    addressString = propertyData.Address1.trim() + ' ';

                //if (propertyData.Address2 && propertyData.Address2.length > 0)
                //    addressString = addressString + propertyData.Address2.trim() + ' ';


                if (propertyData.City && propertyData.City.length > 0)
                    addressString = addressString + propertyData.City.trim() + ', ';

                if (propertyData.State && propertyData.State.length > 0)
                    addressString = addressString + propertyData.State.trim() + ' ';

                if (propertyData.Zip && propertyData.Zip.length > 0)
                    addressString = addressString + propertyData.Zip.trim();

                if (propertyData.Zip4 && propertyData.Zip4.length > 0) {
                    addressString = addressString + '-' + propertyData.Zip4.trim()
                }
            }
            return addressString;

        }

        var propertyDetailService = {
            getPropertyDetailsById: getPropertyDetailsById,
            getAddressString: getAddressString,
            formatCurrency: formatCurrency,
            getPriceString: getPriceString,
            getCreditString: getCreditString,
            getRemainingCredits: getRemainingCredits,
            loadPurchaseableReportsToKOArray: loadPurchaseableReportsToKOArray,
            getRemainingCreditsValue: getRemainingCreditsValue,
            haslpsCredit: haslpsCredit,
            hasAnyProduct: hasAnyProduct
        };

        function hasAnyProduct(propDetails) {
            var hasAnyProductItem = false;

            if (session.isEmployee() == true) {
                session.hasAnyProduct(true);
                hasAnyProductItem = true;
            }
            else {
                hasAnyProductItem = propDetails != null && propDetails.serviceProducts() != null && propDetails.serviceProducts().length > 0;
                hasAnyProductItem = hasAnyProductItem || (propDetails.productServicesProducts() != null && propDetails.productServicesProducts().length > 0);

                if (session != undefined && session != null)
                    session.hasAnyProduct(hasAnyProductItem);
            }
            return hasAnyProductItem;
        };

        function haslpsCredit(propDetails) {
            var hasCreditAssigned = false;
            if (propDetails != undefined) {
                var serviceProducts = propDetails.serviceProducts();
                if (serviceProducts != undefined && serviceProducts.length > 0) {
                    for (var i = 0; i < serviceProducts.length; i++) {
                        if (serviceProducts[i].UsageLimitAssigned > 0) {
                            hasCreditAssigned = serviceProducts[i].UsageLimitAssigned > 0;
                            break;
                        }
                    }
                }
            }
            if (session != undefined && session != null)
                session.hasLPSCredit(hasCreditAssigned);
            return hasCreditAssigned;
        };

        return propertyDetailService;

    });
