define('viewmodels/payment/corecallback',['plugins/router', 'durandal/app', 'knockout', 'services/logger', 'global/session', 'header', 'config', 'plugins/http', 'alertNotification', 'commonDialogModel'],
    function (router, app, ko, logger, session, header, config, https, alert, commonDialog) {
        var payResponseId = ko.observable();
        var payResponseAction = ko.observable();


        var responseMessage = ko.observable("");

        var vm = {
            activate: activate,
            canActivate: canActivate,
            deactivate: deactivate,
            canDeactivate: canDeactivate,
            compositionComplete: compositionComplete,
            responseMessage: responseMessage
        };

        return vm;

        function activate() {
            var url = config.getFTNIOrderStatusResponseUrl;
            var request = {
                "OrderNumber": payResponseId(),
            };

            https.get(url, request)
                .then(function (data) {
                    if (data.HasErrors == false) {
                        console.log(data);
                        payResponseAction(data.ResponseMode);
                        responseMessage(data.Message);
                    }
                });
        }

        function canActivate(OrderId) {
            payResponseId(OrderId);

            if (payResponseId() != "") {
                return true;
            }
            else {
                return false;
            }
        }

        function compositionComplete() {
            console.log(payResponseId() + " - " + payResponseAction());
            if (payResponseAction() == "Cancelled") {

                handleNonSuccessFlow("Your Order got cancelled successfully", "Cancel Order");


            } else if (payResponseAction() == "Invalid") {
                handleNonSuccessFlow("Something went wrong", "Invalid Request");

            } else if (payResponseAction() == "Paid" || payResponseAction() == "ZeroCost") {
                var url = config.getStoredECResponseUrl;
                var request = {
                    "OrderNumber": payResponseId(),
                };
                https.get(url, request)
                    .then(function (data) {
                        if (data.HasErrors == false) {
                            switch (data.Status) {
                                case 10://Success
                                case 12:
                                    //resetSessionVariables();// Do not change the position of this call
                                    session.purchasableProdutsECommerceId(data.EcommerceOrderId);
                                    alert.success(data.StatusMessage, "Payment Process"); //PartialSuccess
                                    router.navigate('#purchasableProductsOrderConfirmation');
                                    break;
                                case 20: //UnableToProcessPayment
                                case 21: //UnableToProcessPaymentCreditsProductsAvailable
                                case 25: //InsufficientCredits
                                case 30: //NoAvailableProducts
                                    handleNonSuccessFlow(data.StatusMessage, "Error");
                                    break;
                                case 0://UnKnown
                                    handleNonSuccessFlow("Unknown Error", "Error");
                                    break;
                                case 100:
                                    handleNonSuccessFlow("Operation Failed", "Fatal");
                                    break;
                                default:
                                    break;
                            }
                        }
                        else {
                            var msgError = "";
                            for (var i = 0; i < data.Messages.length; i++) {
                                if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                                    if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                        msgError = msgError + data.Messages[i].Message + "\n";
                                    }
                                }
                            }
                            switch (data.Status) {
                                case 10://Success
                                case 12:
                                    //resetSessionVariables();// Do not change the position of this call
                                    session.purchasableProdutsECommerceId(data.EcommerceOrderId);
                                    alert.error(data.StatusMessage + "\n" + msgError, "Payment Process"); //PartialSuccess
                                    router.navigate('#purchasableProductsOrderConfirmation');
                                    break;
                                case 20: //UnableToProcessPayment
                                case 21: //UnableToProcessPaymentCreditsProductsAvailable
                                case 25: //InsufficientCredits
                                case 30: //NoAvailableProducts
                                    handleNonSuccessFlow(data.StatusMessage, "Error");
                                    break;
                                case 0://UnKnown
                                    handleNonSuccessFlow("Unknown Error", "Error");
                                    break;
                                case 100:
                                    handleNonSuccessFlow("Operation Failed", "Fatal");
                                    break;
                                default:
                                    alert.error(msgError, "Payment Process");
                                    break;
                            }
                        }
                    });
            } else if (payResponseAction() == "Error" || payResponseAction() == "PayFailed") {
                handleNonSuccessFlow(responseMessage(), 'Error');
            }
        }

        function handleNonSuccessFlow(Message, Title) {
            var url = config.getStoredCoreLogicOrderUrl;
            var request = {
                "OrderGuid": payResponseId(),
            };

            https.get(url, request)
                .then(function (data) {
                    data.Message = Message;
                    data.Title = Title;
                    session.savedCart(data);
                    router.navigate("#bingAddressSearch");
                });

        }

        function handleNonSuccessStatus(message, title) {
            app.showMessage(message, title).then(function (dialogResult) {
                router.navigate("#bingAddressSearch");
                return;
            });
        }

        function Failed() { }

        function deactivate() {

        }

        function canDeactivate() {
            payResponseId('');
            payResponseAction('');
            return true;
        }

    });
