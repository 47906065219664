define('purchaseableReportShopItem',["knockout"], function (ko) {
    function purchaseableReportShopItem() {
        var self = this;
        self.Id = ko.observable(0);
        self.Name = ko.observable("");
        self.Price = ko.observable("");
        self.Credits = ko.observable(0);
        self.UseCredits = ko.observable(false);
        self.SampleURL = ko.observable("");
        self.DescriptionURL = ko.observable("");
        self.AddedToCart = ko.observable(false);
        self.ButtonLabel = ko.computed(function () {
            if (self.AddedToCart())
                return "Remove from Cart";
            else
                return "Add to Cart";
        });
        self.index = ko.observable(-1);
        self.UseCreditEnable = ko.computed(function () {
            return parseInt(self.Credits()) > 0;
        }, self);
        self.AddBtnId = ko.computed(function () {
            return 'btnAddtoCart' + self.Id();
        });
        self.PriceVal = ko.computed(function () {
            var newprice = self.Price();
            if (newprice.indexOf('$') > -1)
                newprice = newprice.replace('$', '');
            return parseFloat(newprice);
        },self);
        self.IsCreditOnlyBrand = ko.computed(function () {
            var newprice = self.Price();
            if (newprice.indexOf('$') > -1)
                newprice = newprice.replace('$', '');
            if (parseInt(self.Credits()) > 0 && parseFloat(newprice) == 0) {
                self.UseCredits(true);
                return true;
            }
            else return false;
        }, self);
    }
    return purchaseableReportShopItem;
});
