define('models/order/principalBorrowers',["knockout"],
    function (ko) {
        function principalBorrowers() {
            var self = this;
            self.viewName = "orderModule/_principalBorrowers.html";
            self.title = "Principal Borrowers";
            self.index = ko.observable(-1);
            self.borrowerFirstName = ko.observable("")
            self.borrowerLastName = ko.observable("")
            self.borrowerWorkPhone = ko.observable("")
            self.workPhoneExt = ko.observable("")
            self.borrowerHomePhone = ko.observable("")
            self.homePhoneExt = ko.observable("")
            self.borrowerAddress = ko.observable("")
            self.borrowerZipCode = ko.observable("")
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.borrowerCity = ko.observable("")
            self.borrowerState = ko.observable("")
            self.borrowerEmail = ko.observable("")
            self.coBorrowerFirstName = ko.observable("")
            self.coBorrowerLastName = ko.observable("")

              self.reset = function () {
              }

              self.isValid = function () {
                  return ko.validation.group(self).length == 0;
              }
        }

        var vm = {
            principalBorrowers: principalBorrowers
        }

        return vm;
    });
