define('viewmodels/propertySearchModule/purchasableProductsOrderConfirmation',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'utlities', 'productOrderModel', 'orderCommon'],
    function (app, router, $, ko, validate, iCheck, config, validation, Q, https, session, alert, utlities, model, orderCommon) {

        var orderConfirmationView = ko.observable('productServices/productOrderDetails.html');
        var productOrdersDetailsModel = new model.ProductOrdersDetails();
        var orderDetail = ko.observableArray([]);

        function activate() {
            getOrderDetail(session.purchasableProdutsECommerceId());
            session.isBusy(false);
            return true;
        }

        function canDeactivate() {
            if (!session.hasLoggedOut()) {
                var urlInComing = window.location.href;
                var stopLength = urlInComing.length;
                if (urlInComing.indexOf("?") > 0) {
                    stopLength = urlInComing.indexOf("?");
                }
                var routeURL = urlInComing.substring(urlInComing.indexOf("#"), stopLength);
                window.history.pushState(null, "", window.location.href);
                if (routeURL.toLowerCase() == "#login" || routeURL.toLowerCase() == "#")
                    return false;
                window.history.pushState(null, "", window.location.href);
                window.onpopstate = function () {
                    window.history.pushState(null, "", window.location.href);
                };
            }
            resetSessionVariables();
            return true;
        }

        function resetSessionVariables() {
            session.purchasableProdutsECommerceId(0);
        }

        //function attached()
        //{
        //    return $('#orderSummaryHeader').css("display", "none");
        //}

        function Cancel() {
            router.navigateBack();
        }

        function loadDetailToKOArray(data) {

            productOrdersDetailsModel.orderItems([]);
            productOrdersDetailsModel.invalidOrderItems([]);

            productOrdersDetailsModel.totalValue(0);

            productOrdersDetailsModel.orderStatus(data.OrderStatus);
            productOrdersDetailsModel.orderDate(data.OrderDateString);
            productOrdersDetailsModel.confirmationNumber(data.ConfirmationNumber);
            productOrdersDetailsModel.propertyAddressHTML(data.PropertyAddressHTML);
            productOrdersDetailsModel.subTotal(data.SubTotalString);
            productOrdersDetailsModel.tax(data.TaxString);
            productOrdersDetailsModel.total(data.TotalString);
            productOrdersDetailsModel.usedCredits(data.UsedCredits);
            productOrdersDetailsModel.totalCreditString(data.TotalCreditString);
            productOrdersDetailsModel.stateTaxString(data.StateTaxString);
            productOrdersDetailsModel.systemProductTypeId(data.SystemProductTypeId);
            productOrdersDetailsModel.totalValue(data.Total);
            productOrdersDetailsModel.creditsOnly(data.CreditsOnly);

            var productOrderItemModel = null;

            for (var i = 0; i < data.OrderItems.length; i++) {
                productOrderItemModel = new model.ProductOrderItem();

                productOrderItemModel.productName(data.OrderItems[i].ProductName);
                productOrderItemModel.price(data.OrderItems[i].PriceString);
                productOrderItemModel.expiredDate(data.OrderItems[i].ExpiredDateString);
                productOrderItemModel.URL(data.OrderItems[i].URL);
                productOrderItemModel.propertyAddressHTML(data.OrderItems[i].PropertyAddressHTML);
                productOrderItemModel.expired(data.OrderItems[i].Expired);
                productOrdersDetailsModel.orderItems.push(productOrderItemModel);
            }
            for (var i = 0; i < data.InvalidOrderItems.length; i++) {
                productOrderItemModel = new model.ProductOrderItem();
                productOrderItemModel.productName(data.InvalidOrderItems[i].ProductName);
                productOrderItemModel.propertyAddressHTML(data.InvalidOrderItems[i].PropertyAddressHTML);
                productOrdersDetailsModel.invalidOrderItems.push(productOrderItemModel);
            }
            return productOrdersDetailsModel;
        }

        function getOrderDetail(ecommerceOrderId) {
            var url = config.getOrderUrl;
            return https.get(url, {
                EcommerceOrderId: ecommerceOrderId,
                UserId: session.userId,
                format: 'json'
            }).done(function (data) {
                var converted = loadDetailToKOArray(data);
                orderDetail(converted);
            });
        }

        function compositionComplete() {
            session.validateInput();
        }

        var vm = {

            session: session,
            activate: activate,
            canDeactivate: canDeactivate,
            compositionComplete: compositionComplete,
            title: 'Order Confirmation',
            orderConfirmationView: orderConfirmationView,
            Cancel: Cancel,
            productOrdersDetailsModel: productOrdersDetailsModel,
            getOrderDetail: getOrderDetail,
            orderDetail: orderDetail,
            Failed: orderCommon.Failed,
            getProductReport: orderCommon.getProductReport
        };
        vm.errors = ko.validation.group([vm]);
        return vm;
    });
