define('viewmodels/externalSiteRedirect/externalSiteSameWindow',["knockout", 'plugins/http', 'global/session', 'config', 'utlities', 'plugins/router', 'durandal/app'],
    function (ko, https, session, config, utlities, router, app) {
        var vm = {
            session: session,
            canActivate: function () {
                getWebinarURL().done(function (redirectUrl) {
                    if (redirectUrl && !redirectUrl.HasErrors && redirectUrl.length > 0)
                        session.CheckPopUpBlock(redirectUrl, '_parent', null);
                        //window.open(redirectUrl, '_parent');
                    else
                        app.showMessage('Required URL is not configured. Please contact Administrator.', 'URL not available', ['OK']);
                    //router.navigateBack();
                });
                return false;
            }
        }

        function getWebinarURL() {
            return https.get(config.getWebinarSiteURL);
        }

        return vm;
    });
