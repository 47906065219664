define('models/propertySearch/recentRequestsModel',["knockout"],
    function (ko) {

        console.log('Recent Requests Model - Called');

        function SearchCriteria() {
            var self = this;

            self.counts = ko.observableArray(['10', '20', '50', '100']),
            self.selectedCount = ko.observable(10);
            self.serviceTypes = ko.observableArray([]);
            self.selectedService = ko.observable(-1);

            self.reset = function () {
            }

        }

        var searchResults = ko.observableArray([]);
        searchResults.extend({
            paged: {
                pageSize: 25,
                pageGenerator: 'default'
            }
        });

        function SearchResult() {
            var self = this;
                self.Address = ko.observable("")
                self.City = ko.observable("")
                self.State = ko.observable("")
                self.Zip = ko.observable("")

                self.Owner = ko.observable("")
                self.APN = ko.observable("")
                self.DocNum = ko.observable("")
                self.Requested = ko.observable("")

                self.Fips = ko.observable("")
                self.ProfileId = ko.observable("")
                self.Custom = ko.observable("")
                self.ServiceId = ko.observable("")
                self.ServiceName = ko.observable("")
                self.DocLink = ko.observable("")
                self.Settings = ko.observable("")

        }

        function CustomSettings() {
            var self = this;

            self.AgeOfSaleInMonths = ko.observable("");
            self.AllReportSections = ko.observable("");
            self.AutoComparables = ko.observable("");
            self.BathroomsRange = ko.observable("");
            self.BedroomsRange = ko.observable("");
            self.DateBuiltRangeBegin = ko.observable("");
            self.DateBuiltRangeEnd = ko.observable("");
            self.NumberOfSales = ko.observable("");
            self.Radius = ko.observable("");
            self.SaleAmountRangeBegin = ko.observable("");
            self.SaleAmountRangeEnd = ko.observable("");
            self.SameLegalTract = ko.observable("");
            self.SelectedReportSections = ko.observable("");
            self.SquareFootageRange = ko.observable("");
            self.LotSize = ko.observable("");

            self.update = function (data) {
                self.AgeOfSaleInMonths(data.AgeOfSaleInMonths);
                self.AllReportSections(data.AllReportSections);
                self.AutoComparables(data.AutoComparables);
                self.BathroomsRange(data.BathroomsRange);
                self.BedroomsRange(data.BedroomsRange);
                self.DateBuiltRangeBegin(data.DateBuiltRangeBegin);
                self.DateBuiltRangeEnd(data.DateBuiltRangeEnd);
                self.NumberOfSales(data.NumberOfSales);
                self.Radius(data.Radius);
                self.SaleAmountRangeBegin('$' + data.SaleAmountRangeBegin);
                self.SaleAmountRangeEnd('$' + data.SaleAmountRangeEnd);
                self.SameLegalTract(data.SameLegalTract);
                self.SelectedReportSections(data.SelectedReportSections);
                self.SquareFootageRange(data.SquareFootageRange);
                self.LotSize(data.LotSize);
            }
        }


        var vm = {
            SearchCriteria: SearchCriteria,
            SearchResult:SearchResult,
            searchResults: searchResults,
            CustomSettings: CustomSettings
        }

        console.log('Recent Requests Model - Returned');
        return vm;
    });
