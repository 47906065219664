define('models/order/coOEOrderCart',["knockout", "paymentProcessModel"],
    function (ko, paymentProcessModel) {

        function Address() {
            var self = this;
            self.Address1 = ko.observable("")
            .extend({ required: { message: 'Address is required.' } });
            self.Address2 = ko.observable("");
            self.City = ko.observable(undefined).extend({ required: { message: 'City is required.' } });
            self.State = ko.observable(undefined).extend({ required: { message: 'State is required.' } });
            self.County = ko.observable(undefined).extend({ required: { message: 'County is required.' } });
            self.Zip = ko.observable("")
            .extend({ required: { message: 'Zip is required.' } })
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
            .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
            .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' });
            self.Zip4 = ko.observable("");

            self.FullAddress = ko.computed(function () {
                var strAddress = '';
                strAddress = self.Address1() + '<br />'
                strAddress = strAddress + self.City() + ', '+ '<br />'
                strAddress = strAddress + self.State() + ' ' + self.Zip() + '<br />'
                return strAddress;

                });


            self.update = function (data) {
                self.Address1(data.Address1());
                self.Address2(data.Address2());
                self.City(data.City());
                self.State(data.State());
                self.County(data.County());
                self.Zip(data.Zip());
                self.Zip4(data.Zip4());
            }
            
            self.updateJSObject = function (data) {
                self.Address1(data.Address1);
                self.Address2(data.Address2);
                self.City(data.City);
                self.State(data.State);
                self.County(data.County);
                self.Zip(data.Zip);
                self.Zip4(data.Zip4);
            }

            self.reset = function () {
                self.Address1("");
              //  self.Address1.isModified(false);
                self.Address2("");
                self.City(undefined);
                self.State(undefined);
                self.County(undefined);
                self.Zip("");
            //    self.Zip.isModified(false);
                self.Zip4("");
                ko.validation.group(self).showAllMessages(false);
            }

            self.resetPropertyAddress = function () {
                self.Address1("");
                self.City(undefined);
                self.State(undefined);
                self.County(undefined);
                self.Zip("");
                ko.validation.group(self).showAllMessages(false);
            }
        }

        function CardHolder() {
            var self = this;
            self.FullName = ko.observable("");
            self.PhoneNumber = ko.observable("");
            // type of Address
            self.Address = new Address();

            self.reset = function () {
                self.FullName("");
                self.PhoneNumber("");
                self.Address.reset();
                ko.validation.group(self).showAllMessages(false);

            }
        }

        function CardDetail() {
            var self = this;

            self.Number = ko.observable(0);
            self.CVC = ko.observable(0);
            self.NameOnCard = ko.observable("");
            self.SaveCardDetails = ko.observable(false);
            self.ExpiryMonth = ko.observable(0);
            self.ExpiryYear = ko.observable(0);

            self.reset = function () {

                ko.validation.group(self).showAllMessages(false);

            }


        }

        function coOEOrderCartItem() {
            var self = this;
            self.index = ko.observable(0);
            self.isLoading = false;
            self.OrderTypeId = ko.observable(undefined)
                .extend({ required: { message: 'Order Type is required.' } });

            self.OrderType = ko.observable("");
            self.Price = ko.observable(0);


            self.IsChargable = ko.observable(false);
            self.ChargedAmount = ko.observable(0);

            // type of Address
            self.PropertyAddress = new Address();

            self.PropertyPin = ko.observable("");
            self.PropertyCounty = ko.observable("");
            self.LegalDescription = ko.observable("");
            self.FirstName = ko.observable("");
            self.LastName = ko.observable("");
            self.SpecialInstructions = ko.observable("");
            self.Description = ko.observable("");

            self.Status = ko.observable(0);
            self.OrderId = ko.observable(0);

            self.FullName = ko.computed(function () {
                if (self.LastName().length > 0 && self.FirstName().length > 0)
                    return self.LastName() + ', ' + self.FirstName();
                else if (self.LastName().length > 0 && self.FirstName().length == 0) {
                    return self.LastName()
                }
                else if (self.LastName().length == 0 && self.FirstName().length > 0) {
                    return self.FirstName()
                }
                else
                    return '';
                });

            //self.FullAddress = ko.computed(function () {
            //    var strAddress = '';
            //    strAddress = self.PropertyAddress() + ',</br>';
            //    //strAddress = strAddress + self.PropertyAddress() + ',</br>';
            //    //strAddress = strAddress +self.ci + ',</br>';
            //    //strAddress = strAddress +self.PropertyAddress() + ',</br>';
            //    return strAddress;
            //    });

            self.PriceString = ko.computed(function () {
                return '$' + parseFloat(self.Price()).toFixed(2);
            });

            self.ChargedAmountString = ko.computed(function () {
                return '$' + parseFloat(self.ChargedAmount()).toFixed(2);
            });


            self.update = function (data) {
                self.index(data.index());
                self.OrderTypeId(data.OrderTypeId());
                self.OrderType(data.OrderType());
                self.PropertyAddress.update(data.PropertyAddress);
                self.PropertyPin(data.PropertyPin());
                self.PropertyCounty(data.PropertyCounty());
                self.LegalDescription(data.LegalDescription());
                self.FirstName(data.FirstName());
                self.LastName(data.LastName());
                self.SpecialInstructions(data.SpecialInstructions());
                self.Description(data.Description());
                self.Price(data.Price());
                self.ChargedAmount(data.ChargedAmount());
                self.IsChargable(data.IsChargable());

            }

            self.updateJSObject = function (data) {
                self.index(data.index);
                self.OrderTypeId(data.OrderTypeId);
                self.OrderType(data.OrderType);
                self.PropertyAddress.updateJSObject(data.PropertyAddress);
                self.PropertyPin(data.PropertyPin);
                self.PropertyCounty(data.PropertyCounty);
                self.LegalDescription(data.LegalDescription);
                self.FirstName(data.FirstName);
                self.LastName(data.LastName);
                self.SpecialInstructions(data.SpecialInstructions);
                self.Description(data.Description);
                self.Price(data.Price);
                self.ChargedAmount(data.ChargedAmount);
                self.IsChargable(data.IsChargable);

            }


            self.reset = function () {
                self.index(-1);
                self.OrderTypeId(undefined);
                self.OrderTypeId.isModified(false);
                self.PropertyAddress.reset();
                self.PropertyPin("");
                self.PropertyCounty("");
                self.LegalDescription("");
                self.FirstName("");
                self.LastName("");
                self.SpecialInstructions("");
                self.Description("");
                self.Price(0);
                self.ChargedAmount(0);
                self.IsChargable(false);
                ko.validation.group(self).showAllMessages(false);
            }

        }

        function coCheckout() {
            var self = this;

            self.viewName = "orderModule/coOEOrder/checkout.html";
            self.title = "Check out";
            self.ModelId = "COOEOrdercheckout"
            self.index = ko.observable(-1);

            self.cartValue = ko.observable(-1);
            // to be removed
            //self.fundToAdd = ko.observable(-1)
            //     .extend({ min: { params: 0, message: 'Invalid fund amount.'
            //     } });
            //self.walletBalance = ko.observable(-1);
            //self.grandTotal = ko.observable(-1);
            //self.finalWalletBalance = ko.observable(-1);
            //self.useCreditCard = ko.observable('new');
            //self.userTokens = ko.observableArray([]);
            //self.saveCard = ko.observable(false);


          //  self.creditCardModel = new paymentProcessModel.CreditCardInformation();


            self.reset = function () {

                self.cartValue(0);
                // to be removed
                //self.fundToAdd(0);
                //self.walletBalance(0);
                //self.grandTotal(0);
                //self.finalWalletBalance(0);
                //self.useCreditCard('new');
                //self.userTokens([]);
                //self.saveCard(false);
                //self.creditCardModel.reset();

                ko.validation.group(self).showAllMessages(false);
            }


            //self.resetNewCardDetail = function()
            //{
            //    //self.creditCardModel.reset();

            //    var validationErrors = ko.validation.group(self.creditCardModel);
            //    if (validationErrors().length > 0) {
            //        validationErrors.showAllMessages(false);
            //    }
            //}



            self.isCustomValid = function () {


                //if (self.fundToAdd() < 0)
                //    return;


                //if (self.grandTotal() == 0)
                //{
                //    return true;
                //}

                var isValid = true;

                //if (self.useCreditCard() == 'new') {
                //    var validationErrors = ko.validation.group(self.creditCardModel);
                //    if (validationErrors().length > 0) {
                //        validationErrors.showAllMessages();
                //        isValid= false;
                //    }
                //    else {
                //        isValid= true;
                //    }
                //}
                return isValid;

            }

        }

        function coOEOrderCart() {
            var self = this;

            self.viewName = "orderModule/coOEOrder/orderInformation.html";
            self.title = "Order Information";
            self.ModelId = "COOEOrder"
            self.index = ko.observable(-1);

            self.RequesterId = ko.observable("");
            self.UserId = ko.observable("");

            self.BrandId = ko.observable(0);
            self.OperationId = ko.observable(0);

            self.ServiceId = ko.observable(0);

            self.OrderDate = ko.observable("");
            self.SalesRep = ko.observable("");
            self.OrderedBy = ko.observable("");
            self.UseSavedCard = ko.observable(false);
            self.ClientFullName = ko.observable("");
            self.ClientEmail = ko.observable("");
            self.ClientCompanyName = ko.observable("");
            

            // type of Address
            self.ClientAddress = new Address();

            self.ClientPhone = ko.observable("");
            self.ClientFax = ko.observable("");


            // type of CardHolder
            self.CardHolder = new CardHolder();

            self.WalletFundToAdd = ko.observable(0);

            // type of CardDetail
            self.CardDetail = new CardDetail();

            // type of coOEOrderCartItem
            self.CartItems = ko.observableArray([]);

            self.Token = ko.observable("");
            self.customErrorMessage = ko.observable("");

            self.reset = function () {
                self.CartItems([]);
                self.UseSavedCard(false);
                self.WalletFundToAdd(0);
                self.CardHolder = new CardHolder();
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                var isValid = false;
                if (self.CartItems().length > 0) {
                    isValid = true;
                    return isValid;
                }
                else {
                    self.customErrorMessage("** At least one Order is required.");
                    return false;
                }

                return ko.validation.group(self).length == 0;
            }
        }

        var vm = {
            Address: Address,
            CardHolder: CardHolder,
            CardDetail: CardDetail,
            coOEOrderCartItem: coOEOrderCartItem,
            coOEOrderCart: coOEOrderCart,
            coCheckout: coCheckout
        };

        return vm;

    });
