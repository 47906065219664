//TODO: Inject dependencies
define('_BrandLogo',['plugins/router', 'global/session', 'knockout'],
    function (router,session,ko) {
        // Internal properties and functions
       
        // Reveal the bindable properties and functions

        
        var vm =  {
            
            activate : activate,
                goBack : goBack,
                handleLogoClick: handleLogoClick
            //self.title =ko.observable('CTIC Logo'),
            //imgPath= ko.observable('../Content/images/Brands/ChicagoTitle.png'),
            //self.reBindLogo = function ()
            //{
            //    if (session.userBrandId() == 1) {
            //        vm.imgPath('../Content/images/Brands/ChicagoTitle.png');
            //    }
            //    else {
            //        vm.imgPath('../Content/images/Brands/TicorTitle.png');
            //    }
            //}
        };

        return vm;
        
        function activate (id, querystring) {
            //TODO: Initialize lookup data here.

            //TODO: Get the data for this viewmodel, return a promise.
            //  alert(session.userBrandId);
        }

        function goBack(complete) {
            
            router.navigateBack();
        }

        function handleLogoClick() {
            session.orderId(null);
            session.routeToLandingPage();
        }

        //function reBindLogo1() {
           
        //    console.log('reBindLogo called.')
        //    console.log('user brand - ' + session.userBrandId());
        //   // vm.title('logo updated');

        //    if (session.userBrandId() == 1) {
        //       vm.imgPath('../Content/images/Brands/ChicagoTitle.png');
        //    }
        //    else {
        //       vm.imgPath('../Content/images/Brands/TicorTitle.png');
        //    }

        //}
    });
