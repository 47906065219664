define('viewmodels/hoaSearch/hoaSearch',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'knockout.validation', 'plugins/http', 'global/session', 'config', 'alertNotification', 'commonDialogModel', 'hoaModel', 'utlities', 'koPaging'],
 function (app, router, $, ko, validate, validation, https, session, config, alert, commonDialog, model, utlities, koPaging) {
     var hoaSearchModel = new model.hoaSearch();
     var hoaResultsModel = new model.hoaResults();
     var hoaDetailsModel = new model.hoaDetails();
     var searchResults = ko.observableArray([]);
     searchResults.extend({
         paged: {
             pageSize: 15,
             pageGenerator: 'default'
             //onlyIf: function () {
             //    return searchResults().length > 15;
             //}
         }
     });

     function activate() {
         clearSearch();
         return;
     }

     function clearSearch() {
         searchResults([]);
         hoaSearchModel.reset();
     }

     function searchHOA() {
         var url = config.hoaSearchUrl;
         https.get(url, {
             OperationId: session.defaultOpertionId(),
             stateId: hoaSearchModel.state(),
             countyId: hoaSearchModel.county(),
             name: hoaSearchModel.name().trim(),
             format: 'json'
         }).then(function (data) {
             searchResults([]);
             var converted = loadResultToKOArray(data);
             searchResults(converted);
             if (searchResults().length > 0)
                 searchResults.toFirstPage();
             hoaSearchModel.isPageReset(false);
             var exportSearchResultURL = config.hoaExportUrl;
             if (sessionStorage["accessToken"] != null) {
                 hoaSearchModel.exportSearchResult(exportSearchResultURL + '?OperationId=' + session.defaultOpertionId() + '&stateId=' + hoaSearchModel.state() + '&countyId=' + hoaSearchModel.county() + '&name=' + hoaSearchModel.name() + '&access_token=' + sessionStorage["accessToken"]);
             }
             else {
                 hoaSearchModel.exportSearchResult(exportSearchResultURL + '?OperationId=' + session.defaultOpertionId() + '&stateId=' + hoaSearchModel.state() + '&countyId=' + hoaSearchModel.county() + '&name=' + hoaSearchModel.name() + '&access_token=' + localStorage["accessToken"]);
             }
         });
     }


     function loadResultToKOArray(data) {
         var results = [];
         for (var i = 0; i < data.length; i++) {
             hoaResultsModel = new model.hoaResults();
             hoaResultsModel.hoaId(data[i].HOAId);
             hoaResultsModel.name(data[i].Name);
             hoaResultsModel.managementCompany(data[i].ManagementCompany);
             hoaResultsModel.city(data[i].City);
             results.push(hoaResultsModel);
         }
         return results;
     }

     function hoaDetailPopup() {
         $('#mdHoaDetail').modal('show');
     }

     function getSelectedValue(selectedItem, event) {
         return getHoaDetail(selectedItem.hoaId()).done(function (result) {
             hoaDetailsModel.name(result.Name);
             hoaDetailsModel.president(result.President);
             hoaDetailsModel.secretary(result.Secretary);
             hoaDetailsModel.managementCompany(result.ManagementCompany);
             hoaDetailsModel.streetAddress1(result.StreetAddress1);
             hoaDetailsModel.streetAddress2(result.StreetAddress2);
             hoaDetailsModel.city(result.City);
             hoaDetailsModel.zip(result.Zip);
             hoaDetailsModel.state(result.State);
             hoaDetailsModel.county(result.County);
             hoaDetailsModel.phone(result.Phone);
             hoaDetailsModel.fax(result.Fax);
             hoaDetailsModel.websiteURL(result.WebsiteUrl);
             hoaDetailsModel.period(result.Period);
             hoaDetailsModel.dues(result.Dues);
             hoaDetailsModel.notes(result.Notes);
             hoaDetailsModel.misc(result.Misc);
             hoaDetailPopup();
         })
     }

     function getHoaDetail(selectedItem) {
         var url = config.getHoaInformationByHoaIdUrl;
         return https.get(url, {
             hoaId: selectedItem,
             format: 'json'
         });
     }
     function compositionComplete() {
         session.validateInput();
     }
     var vm = {
         activate: activate,
         session: session,
         hoaSearchModel: hoaSearchModel,
         hoaResultsModel: hoaResultsModel,
         hoaDetailsModel: hoaDetailsModel,
         searchResults: searchResults,
         searchHOA: searchHOA,
         loadResultToKOArray: loadResultToKOArray,
         hoaDetailPopup: hoaDetailPopup,
         getHoaDetail: getHoaDetail,
         getSelectedValue: getSelectedValue,
         getUSPhoneFormat: utlities.getUSPhoneFormat,
         clearSearch: clearSearch,
         compositionComplete: compositionComplete
     };
     return vm;
 });
