define('viewmodels/customize/customize',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'knockout.validation', 'plugins/http', 'global/session', 'config', 'alertNotification', 'utlities'],
 function (app, router, $, ko, validate, validation, https, session, config, alert, utlities) {
     var quickMenuCustomList = ko.observableArray([]);

     function activate() {
         //var title = app.title;
         //queryString(title.substr(0, title.indexOf(' ')).toLowerCase());
         getQuickMenuItemsCustomize().done(function (result) {
             quickMenuCustomList(result.MenuItems);
         })

         session.getQuickMenuItems().done(function (result) {
             if (session.isLoggedIn() == true) {
                 session.quickMenuList(result.MenuItems);
             }
         })
     }
     function moveUp(item, event) {
         changeQuickMenuItemOrder(item.NavLinkId, true);
         
     }

     function moveDown(item, event) {
         changeQuickMenuItemOrder(item.NavLinkId, false);
         
     }
     function changeVisible(item, event) {
         var url = config.toggleVisible;

         https.get(url, {
             navLinkId: item.NavLinkId
         }).then(function (data) {
         });
         return true;
     }

     function showChanges() {
         //session.quickMenuList(quickMenuCustomList);
         getQuickMenuItemsCustomize().done(function (result) {
             if (session.isLoggedIn() == true) {
                 session.quickMenuList(result.MenuItems);
             }
         })

     }

     function getQuickMenuItemsCustomize() {
         var url = config.getQuickMenuCustomizeUrl
         return https.get(url, {
             userId: session.userId,
             operationId: session.defaultOpertionId(),
             brandId: session.brandId,
             format: 'json'
         });
     }

     function sortAlphabetically() {
         var url = config.sortAlphabetically;
         return https.get(url, {
         }).then(function (data) {
             session.getQuickMenuItems().done(function (result) {
                 if (session.isLoggedIn() == true) {
                     session.quickMenuList(result.MenuItems);
                     quickMenuCustomList(result.MenuItems);

                 }
             })
         });
         

     }
     function changeQuickMenuItemOrder(navLinkId,moveUp) {
         var url = config.changeQuickMenuItemOrder;

         return https.get(url, {
             navLinkId: navLinkId,
             moveUp: moveUp
         }).then(function (data) {

             session.getQuickMenuItems().done(function (result) {
                 quickMenuCustomList(result.MenuItems);

             })
         });
     }

     function compositionComplete() {
         session.validateInput();
     }

     var vm = {
         activate: activate,
         moveUp: moveUp,
         moveDown: moveDown,
         session: session,
         changeVisible: changeVisible,
         sortAlphabetically: sortAlphabetically,
         showChanges: showChanges,
         quickMenuCustomList: quickMenuCustomList,
         compositionComplete: compositionComplete
     }
     return vm;

 })



;
