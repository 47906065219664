define('viewmodels/vipServices/vipServices',["knockout", 'durandal/app', 'plugins/http', 'global/session', 'config', 'utlities', 'vipServicesModel', 'alertNotification', 'global/session'],
    function (ko, app, https, session, config, utlities, model, alert, session) {
        var vipServicesModel = new model.vipServices();
        var vipServiceProfileModel = new model.vipServiceProfile();
        var vipServiceContactsModel = new model.vipServicesContacts();
        var vipContactList = ko.observableArray([]);
        var vipDeliveryDetailModel = new model.vipDeliveryDetail();
        var vipDeliveryModel = new model.vipDelivery();
        var vipInterestCollectionModel = new model.vipInterestCollection();
        var categoryModel = new model.category();
        var vipInterestModel = new model.vipInterest();
        var userInterestModel = new model.userInterest();
        var profileUserName = ko.observable('');
        var isPhone = ko.observable(false);

        vipServicesModel.vipService.subscribe(function (id) {
            switch (id) {

                case "1":
                    vipServicesModel.vipServiceView("vipServices/_vipServices.html")
                    break;

                case "2":
                    vipServicesModel.vipServiceView("vipServices/_contactInformation.html")
                    break;
                case "3":
                    vipServicesModel.vipServiceView("vipServices/_vipDelivery.html")
                    break;
                case "4":
                    vipServicesModel.vipServiceView("vipServices/_vipInterest.html")
                    console.log('subscribe called' + $('.checkAll').length);
                    break;
            }
        });
        vipServiceContactsModel.Type.subscribe(function (type) {
            if (type.toLowerCase().indexOf("phone") >= 0 || type.toLowerCase().indexOf("fax") >= 0) {
                isPhone(true);
            }
            else {
                isPhone(false);
            }
            vipServiceContactsModel.PhoneData("");
            vipServiceContactsModel.MailData("");
        });
        vipDeliveryModel.zip.subscribe(function (zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.City != null) {
                            vipDeliveryModel.city(data.City);
                            //vipDeliveryModel.state(data.State);
                            var founditem = ko.utils.arrayFirst(vipDeliveryModel.stateList(), function (item) {
                                return item.Key === data.State;
                            });
                            vipDeliveryModel.state(founditem.Value);
                        }
                        else {
                            alert.error("Invalid Zip.");
                            vipDeliveryModel.zip('');
                            vipDeliveryModel.city('');
                            vipDeliveryModel.state('');
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })
            }
        })
        vipServiceProfileModel.Zip.subscribe(function (zip) {
            if (zip != undefined && zip != '') {
                utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.City != null) {
                            vipServiceProfileModel.City(data.City);
                            var selectedItem = null;
                            var founditem = ko.utils.arrayFirst(vipServiceProfileModel.allStateList(), function (item) {
                                selectedItem = item;
                                return item.Key === data.State;
                            });
                            if (founditem) {
                                vipServiceProfileModel.stateList([]);
                                vipServiceProfileModel.stateList({ Key: selectedItem.Key, Value: selectedItem.Value });
                            }

                            vipServiceProfileModel.StateId(founditem.Value);
                        }
                        else {
                            alert.error("Invalid Zip.");
                            vipServiceProfileModel.Zip('');
                            vipServiceProfileModel.City('');
                            vipServiceProfileModel.StateId('');
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                })
            }
            else {
                vipServiceProfileModel.stateList(vipServiceProfileModel.allStateList());
                vipServiceProfileModel.StateId(null);
            }
        })
        function activate() {
            vipServiceContactsModel.reset();
            //vm.validationErrors = ko.validation.group(vipServiceContactsModel);
            //vipServiceContactsModel.errors.showAllMessages(true);
            getProfile();
            getContactsVIPProfile();
            getVipDelivery();
            vipDeliveryModel.reset();
            getContactsVIPProfile();
            getVIPInterestByOperation();
            profileUserName(session.fullName());
            console.log('Profile name' + session.fullName());
            //return;
        }
        function deactivate() {
            //vipServiceContactsModel.Type.clearError();
            //vipServiceContactsModel.Time.clearError();
            vm.validationErrors = ko.validation.group(vipServiceContactsModel);
            vipServiceContactsModel.errors.showAllMessages(false);
        }
        function saveProfile() {
            var url = config.saveVIPProfileUrl;
            vm.validationErrors = ko.validation.group(vipServiceProfileModel);
            if (vm.validationErrors().length > 0) {
                vm.validationErrors.showAllMessages();
                session.isBusy(false);
                return;
            }
            var request = {

                "UserId:": vipServiceProfileModel.UserId,
                "Name": vipServiceProfileModel.Name,
                "Email": vipServiceProfileModel.Email,
                "Phone": utlities.unmaskPhoneNumber(vipServiceProfileModel.Phone),
                "Cell": utlities.unmaskPhoneNumber(vipServiceProfileModel.Cell),
                "Fax": utlities.unmaskPhoneNumber(vipServiceProfileModel.Fax),
                "Address": vipServiceProfileModel.Address,
                "City": vipServiceProfileModel.City,
                "StatusUpdates": vipServiceProfileModel.StatusUpdates,
                "Zip": vipServiceProfileModel.Zip,
                "StateId": vipServiceProfileModel.StateId,
                "ClientContact": vipServiceProfileModel.ClientContact,
                "CourtesyCall": vipServiceProfileModel.CourtesyCall,
                "MailHUD": vipServiceProfileModel.MailHUD,
                "Signings": vipServiceProfileModel.Signings

            };

            https.post(url, request)
               .then(function (data) {
                   alert.success("VIP Profile saved successfully");

               }).fail(function (data) {
                   var error = JSON.parse(data.responseText);
                   alert.error(error);
                   console.log(error);
                   session.isBusy(false);
               })
        }
        function deleteContact(item, event) {

            var title = 'Delete Contact?';
            var msg = 'Proceed to delete this contact?';
            return app.showMessage(msg, title, ['Yes', 'No '])
                .then(function (option) {
                    if (option === 'Yes') {

                        var url = config.deleteContactVIPUrl;
                        https.get(url, {
                            contactId: item.ContactId,
                            format: 'json'
                        }).then(function (data) {
                            alert.success("** Deleted Successfully");
                            getContactsVIPProfile();

                        }).fail(function (data) {
                            var error = JSON.parse(data.responseText)
                            console.log(error);
                            session.isBusy(false);
                        })
                    }
                });

        }
        function moveUp(item, event) {
            changeContactOrder(item.ContactId, true);

        }
        function moveDown(item, event) {
            changeContactOrder(item.ContactId, false);

        }
        function changeContactOrder(contactId, moveUp) {
            var url = config.changeVIPContactOrderUrl;

            return https.get(url, {
                contactId: contactId,
                moveUp: moveUp
            }).then(function (data) {

                getContactsVIPProfile();

            });
        }
        function getProfile() {
            var url = config.getVIPProfileUrl;
            https.get(url).then(function (data) {
                if (data != null) {
                    vipServiceProfileModel.Name(data.Name);
                    vipServiceProfileModel.Email(data.Email);
                    vipServiceProfileModel.Phone(data.Phone);
                    vipServiceProfileModel.Cell(data.Cell);
                    vipServiceProfileModel.Fax(data.Fax);
                    vipServiceProfileModel.Address(data.Address);
                    vipServiceProfileModel.City(data.City);
                    vipServiceProfileModel.StatusUpdates(data.StatusUpdates);
                    vipServiceProfileModel.Zip(data.Zip);
                    vipServiceProfileModel.StateId(data.StateId);
                    vipServiceProfileModel.ClientContact(data.ClientContact);
                    vipServiceProfileModel.CourtesyCall(data.CourtesyCall);
                    vipServiceProfileModel.MailHUD(data.MailHUD);
                    vipServiceProfileModel.Signings(data.Signings);
                    vipServiceProfileModel.UserId(data.UserId);
                }
            });
        }
        function getContactsVIPProfile() {
            var url = config.getContactsVIPUrl;
            https.get(url).then(function (data) {
                vipContactList(data);



            });
        }
        function saveContactsVIPProfile() {
            var url = config.saveVIPContactsUrl;
            vm.validationErrors = ko.validation.group(vipServiceContactsModel);
            if (vm.validationErrors().length > 0) {
                vm.validationErrors.showAllMessages();
                session.isBusy(false);
                return;
            }
            if (vipServiceContactsModel.PhoneData() == '' && vipServiceContactsModel.MailData() == '') {
                alert.error('Please enter data for contact method');
                return;
            }
            var week = 0;
            if (vipServiceContactsModel.Sun() == true)
                week += 1;
            if (vipServiceContactsModel.Mon() == true)
                week += 2;
            if (vipServiceContactsModel.Tue() == true)
                week += 4;
            if (vipServiceContactsModel.Wed() == true)
                week += 8;
            if (vipServiceContactsModel.Thu() == true)
                week += 16;
            if (vipServiceContactsModel.Fri() == true)
                week += 32;
            if (vipServiceContactsModel.Sat() == true)
                week += 64;
            var contactData;
            if (isPhone()) {
                contactData = utlities.unmaskPhoneNumber(vipServiceContactsModel.PhoneData);
            }
            else {
                contactData = vipServiceContactsModel.MailData;
            }
            var request = {

                //"ContactId:": vipServiceContactsModel.ContactId(),
                //"UserId": vipServiceContactsModel.UserId(),
                //"Order": vipServiceContactsModel.Order(),
                "Type": vipServiceContactsModel.Type,
                "Cell": vipServiceContactsModel.Cell,
                "Time": vipServiceContactsModel.Time,
                "Weekdays": week,
                "Data": contactData

            };

            https.post(url, request)
               .then(function (data) {
                   //vipContactList(data);
                   alert.success("** Saved Successfully ");
                   getContactsVIPProfile();
               }).fail(function (data) {
                   var error = JSON.parse(data.responseText);
                   alert.error(error);
                   console.log(error);
                   session.isBusy(false);
               })


        }
        function submitDelivery() {
            var url = config.saveVipDeliveryUrl;
            var request = {
                "Address": vipDeliveryModel.address,
                "City": vipDeliveryModel.city,
                "StateId": vipDeliveryModel.state,
                "Zip": vipDeliveryModel.zip,
                "Time": vipDeliveryModel.time()
            }
            https.post(url, request)
          .then(function (data) {
              alert.success("** Saved Successfully")
              vipDeliveryModel.reset();
              getVipDelivery();
          }).fail(function (data) {
              var error = JSON.parse(data.responseText);
              alert.error(error);
              console.log(error);
              session.isBusy(false);
          })
        }
        function getVipDelivery() {
            var url = config.getVipDeliveryUrl;
            https.get(url).then(function (data) {
                var converted = loadResultToKOArray(data);
                vipDeliveryDetailModel.deliveryList(converted);
            });
        }
        function loadResultToKOArray(data) {
            var results = [];
            for (var i = 0; i < data.length; i++) {
                deliveryDetailModel = new model.vipDeliveryDetail();
                deliveryDetailModel.deliveryId(data[i].DeliveryId);
                deliveryDetailModel.fullAddress(data[i].FullAddress);
                results.push(deliveryDetailModel);
            }
            return results;
        }
        function deleteDelivery(selectedItem, event) {

            var title = 'Delete Delivery?';
            var msg = 'Proceed to delete this delivery?';
            return app.showMessage(msg, title, ['Yes', 'No '])
                .then(function (option) {
                    if (option === 'Yes') {

                        var url = config.deleteVipDeliveryUrl;
                        var deliveryId = selectedItem.deliveryId();
                        https.get(url, {
                            deliveryId: selectedItem.deliveryId(),
                            format: 'json'
                        })
                            .then(function (data) {
                                alert.success("** Deleted Successfully")
                                getVipDelivery();

                            })
                            .fail(function (data) {
                                console.log(data)
                                alert.error("An error occurred while deleting delivery");
                            });
                    }
                });

        }

        function getVIPInterestByOperation() {
            var url = config.getVIPInterestByOperationUrl;
            https.get(url).then(function (webAPICollectionObject) {
                var converted = loadVipInterestResultToKOArray(webAPICollectionObject.VIPCategories);
                vipInterestCollectionModel.vipCategories(converted);
            });
        }
        function loadVipInterestResultToKOArray(webAPICollectionObject) {
            var mappedLocalKOArray = [];
            for (var j = 0; j < webAPICollectionObject.length; j++) {
                categoryModel = new model.category();
                var mappedInterestDataArray = [];
                for (var i = 0; i < webAPICollectionObject[j].Interests.length; i++) {
                    vipInterestModel = new model.vipInterest();
                    vipInterestModel.name(webAPICollectionObject[j].Interests[i].Name);
                    vipInterestModel.description(webAPICollectionObject[j].Interests[i].Description);
                    vipInterestModel.isSelected(webAPICollectionObject[j].Interests[i].IsSelected);
                    vipInterestModel.value(webAPICollectionObject[j].Interests[i].Value);
                    mappedInterestDataArray.push(vipInterestModel);
                }
                categoryModel.categoryId(webAPICollectionObject[j].CategoryId)
                categoryModel.name(webAPICollectionObject[j].Name)
                categoryModel.description(webAPICollectionObject[j].Description)
                categoryModel.interests(mappedInterestDataArray);
                mappedLocalKOArray.push(categoryModel);
            }
            return mappedLocalKOArray;
        }

        function saveIntrests() {
            var url = config.saveVipUserInterestUrl;

            https.post(url, vipInterestCollectionModel)
          .then(function (data) {
              if (data.HasErrors == false && data.HasValidationErrors == false) {
                  alert.success("** Saved Successfully")
              }
              else {
                  alert.success("Internal Server Error")
              }
          }).fail(function (data) {
              var error = JSON.parse(data.responseText);
              alert.error(error);
              console.log(error);
              session.isBusy(false);
          })

        }

        function compositionComplete() {
            session.validateInput();
        }

        
        function attachHandles() {
            console.log('attachHandles called' + $('.checkAll').length);
            $('.checkAll').change(function () {
                var selectedCat = $(this).attr('category');
                console.log(selectedCat);
                //$('.interest ')
            });
        }

        var vm = {
            activate: activate,
            attached: attachHandles,
            deactivate: deactivate,
            compositionComplete: compositionComplete,
            vipServiceProfileModel: vipServiceProfileModel,
            saveProfile: saveProfile,
            vipServicesModel: vipServicesModel,
            vipServiceContactsModel: vipServiceContactsModel,
            deleteContact: deleteContact,
            moveUp: moveUp,
            moveDown: moveDown,
            saveContactsVIPProfile: saveContactsVIPProfile,
            getContactsVIPProfile: getContactsVIPProfile,
            vipServicesModel: vipServicesModel,
            vipDeliveryModel: vipDeliveryModel,
            submitDelivery: submitDelivery,
            getVipDelivery: getVipDelivery,
            vipDeliveryDetailModel: vipDeliveryDetailModel,
            deleteDelivery: deleteDelivery,
            saveIntrests: saveIntrests,
            vipContactList: vipContactList,
            isPhone: isPhone,
            getVIPInterestByOperation: getVIPInterestByOperation,
            vipInterestCollectionModel: vipInterestCollectionModel,
            categoryModel: categoryModel,
            vipInterestModel: vipInterestModel,
            userInterestModel: userInterestModel,
            checkAllChange: checkAllChange,
            profileUserName: profileUserName
        }
        return vm;



    });

function checkAllChange(chkCategory) {
    $('.interest[interestcategory=' + chkCategory.getAttribute('category') + ']').prop('checked', chkCategory.checked);


    //var checkedIds = [];
    //$(".interest:checked").each(function () {
    //    $(this).triggerHandler('click');

    //});

    var interestsUnderCategory = $('.interest[interestcategory=' + chkCategory.getAttribute('category') + ']');
    $('.interest[interestcategory=' + chkCategory.getAttribute('category') + ']').each(function () {
        $(this).triggerHandler('click');
    })
}

function handleInterestChange(chkInterest) {
    var interestsUnderCategory = $('.interest[interestcategory=' + chkInterest.getAttribute('interestcategory') + ']').length;
    var interestsCheckedUnderCategory = $('.interest[interestcategory=' + chkInterest.getAttribute('interestcategory') + ']:checked').length;
    $('.checkAll[category=' + chkInterest.getAttribute('interestcategory') + ']').prop('checked', interestsUnderCategory == interestsCheckedUnderCategory);
};
