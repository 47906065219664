define('nameList',["knockout"],
    function (ko) {
        function NameList() {
            var self = this;

            self.Name = ko.observable("");
            self.List = ko.observableArray([]);
        }
        return NameList;
    });
