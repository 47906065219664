define('viewmodels/propertySearchModule/addressSearch_not_used',['durandal/app', 'knockout', 'advanceAddressSearch', 'services/logger', 'apnSearch', 'ownerSearch', 'streetSearch', 'propertySearchModel', 'Q', 'plugins/http', 'config', 'metisMenu', 'jquery.slimscroll', 'global/session', 'plugins/router', 'alertNotification', 'commonDialogModel', 'koPaging', 'utlities', 'propertyDetailService', 'propertyDetailResponse', 'propertyProfileModel', 'propertyProfileService', 'accounting', 'nameValue'],
	function (app, ko, addressSearch, logger, apnSearch, ownerSearch, streetSearch, model, Q, https, config, metisMenu, slimscroll, session, router, alert, commonDialog, koPaging, utlities, propertyDetailService, propertyDetailResponse, propertyProfile, propertyProfileService, accounting, nameValue) {

	    var isAdvanceSearchEnabled = ko.observable(false);
	    var showAdvanceSearchBadge = ko.observable(true);
	    var isBasicSearchNoResult = ko.observable(true);
	    var isFindYourPropertyEnabled = ko.observable(true);
	    var isAdvanceSearchFromAddressSearch = ko.observable(true);
	    var isMinimizeAdvanceSearch = ko.observable(false);
	    var searchResult = ko.observable(undefined);
	    var apnModel = new model.ApnSearch();
	    var ownerModel = new model.OwnerSerach();
	    var addressModel = new model.AddressSerach();
	    var streetLookupModel = new model.StreetLookUpSearch();
	    var customCompSettingsModel = new model.CustomCompSettings();
	    var stateList = ko.observableArray();
	    var ownerCountyList = ko.observableArray();
	    var apnCountyList = ko.observableArray();
	    var streetCountyList = ko.observableArray();
	    var ownerfips = ko.observableArray();
	    var streetfips = ko.observableArray();
	    var apnfips = ko.observableArray();
	    var latitude = ko.observable();
	    var longitude = ko.observable();
	    var ownerfipsNo = ko.observable('');
	    var streetfipsNo = ko.observable('');
	    var apnfipsNo = ko.observable('');
	    var isPredictiveSearch = ko.observable(false);
	    var isLatLongAvailable = ko.observable(false);
	    var propertyListing = ko.observableArray();
	    var isStreetView = ko.observable(false).extend({
	        notify: 'always'
	    });
	    var selectedPropertyId = ko.observable(0);
	    var dmKey = ko.observable('');
	    var DMAuthenticated = ko.observable(false);
	    var propertyDetailResponse = ko.observable(undefined);
	    var reportsContainer = ko.observable('');

	    var serviceProducts = ko.observable(undefined);
	    var productServicesProducts = ko.observable(undefined);
	    var purchaseableReportsShoppingCart = ko.observableArray([]);
	    var shoppingCartLabel = ko.observable("");
	    var shoppingCartEnable = ko.observable(false);
	    var walletBalance = ko.observable(0);
	    var creditBalance = ko.observable(0);
	    var walletEnabled = ko.observable(false);
	    var creditsEnabled = ko.observable(false);
	    var hasPrice = ko.observable(false);
	    var showRecentSearchBadge = ko.observable(true);


	    //BKFS Reports Start
	    var propertyProfileModel = new propertyProfile.PropertyProfile();
	    var reportPreparedForModel = new propertyProfile.ReportPreparedFor();
	    var emailPropertyProfileReportModel = new propertyProfile.EmailPropertyProfileReport();

	    var showMailDialog = false;
	    //BKFS Reports End

	    var propertyProfileResultStatus = ko.observable(0);
	    var propertyProfileProfileId = ko.observable("");
	    var propertyProfileProperty = ko.observable(undefined);
	    var propertyProfileAvailableSections = ko.observableArray([]);
	    var propertyProfileAvailableReportTypes = ko.observableArray

	    var showReports = ko.observable(false);


	    var selectedProperty = ko.observable(undefined);
	    var selectedAPN = ko.observable('');
	    var purchaseableReportsShoppingCartItems = ko.observableArray([]);

	    var predictiveTextBox = ko.observable('');
	    var isSearchable = ko.observable(true);
	    var hasZipFocused = ko.observable(false);
	    var commonDialogModel = new commonDialog("addressSearchDialog");
	    var isPredictiveInput = ko.observable(false);
	    var dtResults = null;

	    var currentAddress = '';
	    var currentOwner = '';
	    var currentAPN = '';

	    var map = null;
	    var panorama = null;

	    var isMarkable = false;
	    var markers = [];


	    var input = null;
	    var searchBox = null;

	    var directions = ["E", "N", "S", "W"];

	    var isQuickSearchResult = ko.observable(false).extend({
	        notify: 'always'
	    });
	    var searchBtnText = ko.observable("");
	    var defaultSettings = ko.observable(true);

	    var currentUserFullAddress = null;
	    var bgMarker = null;

	    isQuickSearchResult.subscribe(function (newValue) {
	        if (newValue) {
	            searchBtnText("Search Again");
	        }
	        else {
	            searchBtnText("Quick Search");
	        }
	    });
	    hasZipFocused.subscribe(function (value) {
	        //isSearchable(!value)
	    });

	    productServicesProducts.subscribe(function (newValue) {
	        if (newValue && newValue.length > 0) {
	            $('#dvAvailableReports').slimScroll({
	                height: '180px',
	            });
	        }
	    });

	    isStreetView.subscribe(function (newValue) {
	        if (map) {
	            setTimeout(function () {
	                google.maps.event.trigger(map, 'resize');
	            }, 100);
	        }

	        if (!newValue) {
	            //initMapAutocomplete();
	            //google.maps.event.trigger(map, "resize");
	            if (map) {
	                map.setOptions(
						   {
						       streetViewControl: false
						   });
	            }
	        }
	        else {
	            //reportsContainer('propertySearchModule/_showReportButtonContainer.html');
	        }


	    });

	    isLatLongAvailable.subscribe(function (newValue) {


	        if (isLatLongAvailable() == true) {

	            var mapDiv = document.getElementById('map');
	            var myLatLng = {
	                lat: latitude(), lng: longitude()
	            };


	            if (!isMarkable) {
	                //map = new google.maps.Map(mapDiv, {
	                //    center: myLatLng,
	                //    zoom: 8,
	                //    zoomControl: true,
	                //    zoomControlOptions: {
	                //        position: google.maps.ControlPosition.LEFT_TOP
	                //    },
	                //    streetViewControl: true,
	                //    streetViewControlOptions: {
	                //        position: google.maps.ControlPosition.TOP_LEFT
	                //    }




	                //});


	                initMapAutocomplete();


	            }

	            else {
	                markers = [];
	                for (var i = 0; i < markers.length; i++) {
	                    markers[i].setMap(null);
	                }


	                var infowindow = new google.maps.InfoWindow({
	                });

	                for (var i = 0; i < searchResult().Properties.length; i++) {
	                    //    currentAddress = searchResult().Properties[i].Address + ', ' + searchResult().Properties[i].City + ', ' + searchResult().Properties[i].State + ', ' + searchResult().Properties[i].ZIP + '-' + searchResult().Properties[i].ZIP4;
	                    currentAddress = getAddressString(searchResult().Properties[i]);

	                    currentOwner = searchResult().Properties[i].Owner;
	                    currentAPN = searchResult().Properties[i].APN;

	                    var content = '<div id="content">' +
			'<div id="siteNotice">' +
			'</div>' +
			'<h3 id="firstHeading" class="firstHeading">' + currentOwner + '</h3>' +
			'<div id="bodyContent">' +
			'<p><b>Address : </b>' + currentAddress + '</p>' +
			'<p><b>APN : </b>' + currentAPN + '</p>' +
			'<p><a href="#" class="mapLink"  data-APN="' + currentAPN + '">' +
			'Property Details</a> ' +
			'</p>' +
			'</div>' +
			'</div>';


	                    var markerTitle = '';

	                    if (currentOwner.length > 0 && currentAddress.length > 0 && currentAPN.length > 0) {
	                        markerTitle = 'Owner : ' + currentOwner + ', Address :  ' + currentAddress + ', APN :  ' + currentAPN;
	                    }


	                    var latLng = new google.maps.LatLng(searchResult().Properties[i].Latitude, searchResult().Properties[i].Longitude);

	                    map.setCenter(latLng);


	                    var marker = new google.maps.Marker({
	                        position: latLng,
	                        map: map,
	                        APN: currentAPN
	                    });


	                    marker.addListener('click', function (item, e) {
	                        getPropertyDetailsFromMapLink(this.APN);


	                    });


	                    google.maps.event.addListener(marker, 'mouseover', (function (marker, content, infowindow) {
	                        return function () {



	                            infowindow.setContent(content);
	                            infowindow.open(map, marker);

	                            $('.mapLink').click(function () {
	                                getPropertyDetailsFromMapLink($(this).attr("data-apn"));
	                                return false;
	                            });
	                        };
	                    })(marker, content, infowindow));

	                    markers.push(marker);

	                    //marker.addListener('click', function () {
	                    //    infowindow.open(map, marker);
	                    //});

	                }


	                if (propertyListing().length > 0 && propertyListing().length <= 5)
	                    map.setZoom(18);

	                if (propertyListing().length > 5 && propertyListing().length <= 10)
	                    map.setZoom(16);

	                if (propertyListing().length > 10 && propertyListing().length <= 15)
	                    map.setZoom(14);

	                if (propertyListing().length > 15 && propertyListing().length <= 20)
	                    map.setZoom(12);

	                if (propertyListing().length > 20 && propertyListing().length <= 25)
	                    map.setZoom(10);

	                if (propertyListing().length > 25)
	                    map.setZoom(10);


	                map.setTilt(0);


	                //setStreetView(searchResult().Properties[0].Latitude, searchResult().Properties[0].Longitude)
	                isStreetView(true);
	                selectedProperty(propertyListing()[0]);
	                loadPropertyDetail(selectedProperty());





	                //google.maps.event.trigger(map, "resize");
	                console.log(selectedProperty());


	                minimizeAdvanceSearch();
	                isSearchable(true);



	            }

	            isLatLongAvailable(false);

	        }
	    });

	    function setStreetView(lat, lng) {

	        var streetViewService = new google.maps.StreetViewService();
	        var STREETVIEW_MAX_DISTANCE = 50;
	        var latLng = new google.maps.LatLng(lat, lng);
	        streetViewService.getPanoramaByLocation(latLng, STREETVIEW_MAX_DISTANCE, function (streetViewPanoramaData, status) {
	            if (status === google.maps.StreetViewStatus.OK) {
	                var heading = google.maps.geometry.spherical.computeHeading(streetViewPanoramaData.location.latLng, latLng);
	                panorama = new google.maps.StreetViewPanorama(
								 document.getElementById('pano'), {
								     position: new google.maps.LatLng(lat, lng),
								     pov: {
								         heading: heading,
								         pitch: 0
								     },
								     addressControl: false,
								     linksControl: false,
								     panControl: false,
								     zoomControl: false,
								     fullscreenControl: true,
								     zoomControlOptions: {
								         style: google.maps.ZoomControlStyle.SMALL
								     },
								     enableCloseButton: false

								 });

	                map.setStreetView(panorama);
	                map.setOptions(
						{
						    streetViewControl: true
						});

	            }
	            else {



	                var mapDiv = document.getElementById('pano');
	                var streetMap = new google.maps.Map(mapDiv, {
	                    center: new google.maps.LatLng(lat, lng),
	                    zoom: 20,
	                    zoomControl: false,
	                    streetViewControl: false
	                });

	                streetMap.setMapTypeId('hybrid');

	                var marker = new google.maps.Marker({
	                    position: new google.maps.LatLng(lat, lng),
	                    map: streetMap
	                });

	                map.setOptions(
					   {
					       streetViewControl: false
					   });

	            }
	        });



	    }

	    function setDMKey() {
	        return utlities.getDMKey().done(function (dmKeyResponse) {
	            if (dmKeyResponse && dmKeyResponse.HasError == false) {
	                dmKey(dmKeyResponse.DMKey);
	                console.log('Parcel Key ' + dmKey());
	            }
	            else {
	                console.log('Parcel Key Error : ' + dmKeyResponse.ErrorMessage);
	            }

	        });
	    }


	    function afterAuthentication() {

	        //layer = new Dmp.Layer.TileLayer("SS", "SS.Base.Parcels/ParcelTiles", {
	        //	minZoom: 14, maxZoom: 19
	        //});
	        //map.overlayMapTypes.push(new google.maps.ImageMapType(layer));

	        REP.Layer.Google.Initialize(map);
	    }




	    function initMapAutocomplete() {
	        if (latitude() != null && longitude() != null) {
	            var latLng = new google.maps.LatLng(latitude(), longitude());

	            map = new google.maps.Map(document.getElementById('map'), {
	                center: latLng,
	                zoom: 14,
	                minZoom: 4,
	                zoomControl: true,
	                streetViewControl: false,
	                zoomControlOptions: {
	                    position: google.maps.ControlPosition.LEFT_TOP
	                }
	            });

	            map.setMapTypeId('hybrid');
	            //REP.Layer.Google.Initialize(map);
	            REP.Layer.Google.Initialize(map, { Parcels_OutlineColor: 'white' });

	            //google.maps.event.addListener(map, 'click', function (event) {
	            //    console.log(event.latLng);
	            //    if(isStreetView())
	            //	performReverseGeoCode(event.latLng);

	            //});

	            bgMarker = new google.maps.Marker({
	                position: latLng,
	                map: map,
	            });

	            //if (navigator.geolocation) {
	            //    navigator.geolocation.getCurrentPosition(function(position) {
	            //        var pos = {
	            //            lat: position.coords.latitude,
	            //            lng: position.coords.longitude
	            //        };

	            //        infoWindow.setPosition(pos);
	            //        infoWindow.setContent('Location found.');
	            //        map.setCenter(pos);
	            //    }, function() {
	            //       // handleLocationError(true, infoWindow, map.getCenter());
	            //    });
	            //} else {
	            //    // Browser doesn't support Geolocation
	            //   // handleLocationError(false, infoWindow, map.getCenter());
	            //}


	            // Create the search box and link it to the UI element.

	            //map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
	            //isFindYourPropertyEnabled(false);

	            // Bias the SearchBox results towards current map's viewport.
	            map.addListener('bounds_changed', function () {
	                searchBox.setBounds(map.getBounds());
	            });

	            var markers = [];
	            // Listen for the event fired when the user selects a prediction and retrieve
	            // more details for that place.
	            searchBox.addListener('places_changed', function () {
	                searchByGooglePrediction();
	            });

	            // DM key is set to local KO observable in the activate method by calling setDMKey. So no need to set again to the observable.
	            if (dmKey().length > 0) {
	                if (!DMAuthenticated()) {
	                    Dmp.Env.Connections["SS"].init(dmKey(), afterAuthentication());
	                    DMAuthenticated(true);
	                }
	                else {
	                    afterAuthentication();
	                }
	            }
	        }
	    }

	    $('#pac-input').keypress(function (e) {
	        if (e.which == 13) {
	            google.maps.event.trigger(autocomplete, 'place_changed');
	            return false;
	        }
	    });

	    function searchByGooglePrediction() {
	        if (!session.llaAccepted()) {
	            session.showLLAAgreement();
	            return;
	        }
			if ($('#pac-input').val().length == 0) {
				//app.showMessage('Please provide the address to search.', 'Property search', ['OK']);
				//app.showMessage("Please provide the address to search.", "Property search", ["OK"], true, { style: { width: "400px" } });
				commonDialogModel.show("Find Your Property", "Please provide the address to search.");
				return;
			}

	        var addressField = document.getElementById('pac-input');
	        var geocoder = new google.maps.Geocoder();

	        geocoder.geocode(
			{
			    'address': addressField.value
			},
					function (results, status) {
					    if (status == google.maps.GeocoderStatus.OK) {
					        var loc = results[0].geometry.location;
					        console.log(addressField.value + " found on Google");
					        console.log("loc : " + loc);
					        DoSearch();
					        return true;
					    }
					    else {
					        console.log(addressField.value + " not found on Google");
					        cleartMapResults();
					        showAdvanceSearch();
					        isBasicSearchNoResult(true);
					        return false;
					    }
					});
	        isQuickSearchResult(true);
	    }

	    function createOrder() {
	        router.navigate("/#orders");
	    }

		function DoSearch() {



	        //if (!isValidSearch()) {
	        //      cleartMapResults();
	        //    showAdvanceSearch();
	        //    isBasicSearchNoResult(true);
	        //    return;
	        //}


	        //var places = searchBox.getPlaces();
	        //isPredictiveInput(false);
	        //if (places == undefined || places.length == 0 || places[0].geometry == undefined || places[0].geometry.location == undefined) {
	        //    cleartMapResults();
	        //    showAdvanceSearch();
	        //    isBasicSearchNoResult(true);
	        //    return;
	        //}
	        isFindYourPropertyEnabled(false);
	        isPredictiveSearch(true);
	        //places[0].geometry.location.lng()

	        //https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452

	        //markers.forEach(function (marker) {
	        //    marker.setMap(null);
	        //});
	        //markers = [];

	        clearMarkers();


	        //var geocoder = new google.maps.Geocoder;


	        //var latlng = places[0].geometry.location;
	        //geocoder.geocode({
	        //    'location': latlng
	        //}, function (results, status) {


	        var addressField = document.getElementById('pac-input');

	        //doSearchByAddress(addressField.value);

	        var geocoder = new google.maps.Geocoder();

	        geocoder.geocode(
			 {
			     'address': addressField.value
			 },
					function (results, status) {
					    if (status === 'OK') {
					        if (results[0]) {
					            //console.log(JSON.parse(JSON.stringify(results[0])));
					            // doSearchByAddress(JSON.parse(JSON.stringify(results[0])).address_components);
					            doSearchByAddress(results[0].address_components);
					        }
					    }
					});

	    }

	    function performReverseGeoCode(LatLng) {

	        var geocoder = new google.maps.Geocoder();
	        geocoder.geocode({
	            'latLng': LatLng
	        }, function (results, status) {

	            if (status == google.maps.GeocoderStatus.OK) {
	                //setMarker(latlng, "FF0707");
	                console.log(results);
	                clearMarkers();
	                doSearchByAddress(results[0].address_components);
	                //console.log(address);

	                //var clickMarker = new google.maps.Marker({
	                //    position: LatLng,
	                //    map: map,
	                //});

	            }
	        });

	    }

	    function doSearchByAddress(addressVal) {
	        var address = addressVal;
	        var type = '';
	        var address1 = '';
	        var address2 = '';

	        var city = '';
	        var state = '';
	        var zip = '';
	        var street = '';


	        console.log(address);


	        //                        addressModel.reset();

	        for (var i = 0; i < address.length; i++) {
	            type = address[i].types[0];
	            switch (type) {
	                //City
	                case "locality":
	                    city = address[i].short_name;
	                    break;
	                    // State
	                case "administrative_area_level_1":
	                    state = address[i].short_name;
	                    break;
	                case "postal_code":
	                    zip = address[i].short_name;
	                    break;
	                case "street_number":
	                    address1 = address[i].short_name;
	                    break;
	                case "route":
	                    address2 = address[i].short_name;
	                    break;
	            }
	        }

	        street = address1 + ' ' + address2;



	        isMarkable = true;
	        searchResult(undefined);
	        var result = addressSearch.addressSearch(searchResult, {
	            city: city, state: state, zip: zip, streetName: street
	        }, stateList);
	    }

	    searchResult.subscribe(function (newValue) {


	        if (searchResult()) {
	            if (searchResult().Properties.length > 0) {

	                //$('#tblPropertyResults').destroy();

	                //if ($.fn.dataTable.isDataTable('#tblPropertyResults')) {
	                //    table = $('#tblPropertyResults').DataTable();
	                //    table.clear();
	                //    table.destroy();
	                //    //$("#tblPropertyResults").empty();
	                //    //table.draw();
	                //}

	                searchResult().Properties.sort(function (a, b) {
	                    var nameA = a.Owner.toUpperCase(); // ignore upper and lowercase
	                    var nameB = b.Owner.toUpperCase(); // ignore upper and lowercase
	                    if (nameA < nameB) {
	                        return -1;
	                    }
	                    if (nameA > nameB) {
	                        return 1;
	                    }

	                    // names must be equal
	                    return 0;
	                });



	                propertyListing([]);
	                propertyListing(searchResult().Properties);

	                //$('#tblPropertyResults').DataTable();
	                //table.draw();

	                //if (propertyListing().length > 10) {
	                //    $('#tblPropertyResults').DataTable();
	                //}
	                //else {
	                //    $('#tblPropertyResults').DataTable(
	                //    {
	                //        "paging": false,
	                //        "ordering": false,
	                //        "info": false,
	                //        "searching": false,
	                //    });
	                //}




	                //dtResults.data = propertyListing;
	                //dtResults.search('').draw();
	                isLatLongAvailable(true);
	                isLatLongAvailable(false);
	            }
	            else {
	                clearMarkers();
	                propertyListing([]);
	                if (isPredictiveSearch()) {
	                    showAdvanceSearch();
	                    isBasicSearchNoResult(true);
	                }
	                else {
	                    //app.showMessage('No property found.', 'Property search', ['OK']);
	                    commonDialogModel.show("Property search", "No property found.");
	                }
	                isSearchable(true);

	            }

	        }
	    });


	    isAdvanceSearchEnabled.subscribe(function (isEnabled) {
	        if (isEnabled) {
	            //addressModel.reset();
	            showAdvanceSearchBadge(false);
	            isBasicSearchNoResult(false);
	            isFindYourPropertyEnabled(false);
	            showRecentSearchBadge(false);
	            //    $('#page-wrapper').css("min-height",  "1200px");
	        }
	        else {
	            //console.log('isAdvanceSearchFromAddressSearch ' + isAdvanceSearchFromAddressSearch());
	            showAdvanceSearchBadge(true);
	            if (isAdvanceSearchFromAddressSearch() && !isMinimizeAdvanceSearch()) {
	                isFindYourPropertyEnabled(true);
	            }
	            showRecentSearchBadge(true);
	        }
	    });

	    //-------------------------------------------------------------------------------------------------------------------------------------
	    //BKFS Reports Start

	    reportPreparedForModel.pname.subscribe(function (value) {
	        if (propertyProfileModel.enableReportPreparedFor()) {
	            console.log("In subscribe : pname");
	            RebuildReportURLs("pname", value, true, true);
	        }
	    });

	    reportPreparedForModel.pcompany.subscribe(function (value) {
	        if (propertyProfileModel.enableReportPreparedFor()) {
	            console.log("In subscribe : pcompany");
	            RebuildReportURLs("pcompany", value, true, true);
	        }
	    });

	    reportPreparedForModel.paddress1.subscribe(function (value) {
	        if (propertyProfileModel.enableReportPreparedFor()) {
	            console.log("In subscribe : paddress1");
	            RebuildReportURLs("paddress1", value, true, true);
	        }
	    });

	    reportPreparedForModel.paddress2.subscribe(function (value) {
	        if (propertyProfileModel.enableReportPreparedFor()) {
	            console.log("In subscribe : paddress2");
	            RebuildReportURLs("paddress2", value, true, true);
	        }
	    });

	    reportPreparedForModel.pcsz.subscribe(function (value) {
	        if (propertyProfileModel.enableReportPreparedFor()) {
	            console.log("In subscribe : pcsz");
	            RebuildReportURLs("pcsz", value, true, true);
	        }
	    });

	    function ChangeProfileURL() {
	        var int = 0;
	        $.each(propertyProfileModel.availableSections(), function (index, data) {
	            if (data.CheckBoxValue() === true) {
	                int = int + data.Value();
	            }
	        });
	        RebuildReportURLs("mask", int, false, true);
	        return true;
	    }

	    function RebuildReportURLs(queryStringParameter, value, includeIndividualPages, includeReports) {
	        if (value != null && value != "") {
	            if (includeIndividualPages) {
	                if (propertyProfileModel.availableSections().length > 0) {
	                    for (var i = 0; i < propertyProfileModel.availableSections().length; i++) {
	                        if (propertyProfileModel.availableSections()[i].ProfileURL() != null && propertyProfileModel.availableSections()[i].ProfileURL() != "") {
	                            propertyProfileModel.availableSections()[i].ProfileURL(replaceURLParameters(propertyProfileModel.availableSections()[i].ProfileURL(), queryStringParameter, value));
	                            //propertyProfileModel.availableSections()[i].ProfileURL(propertyProfileModel.availableSections()[i].ProfileURL() + "&" + queryStringParameter + "=" + value);
	                        }
	                    }
	                }
	            }
	            if (includeReports) {
	                if (propertyProfileModel.availableReportTypes().length > 0) {
	                    for (var i = 0; i < propertyProfileModel.availableReportTypes().length; i++) {
	                        if (propertyProfileModel.availableReportTypes()[i].ProfileURL() != null && propertyProfileModel.availableReportTypes()[i].ProfileURL() != "") {
	                            //propertyProfileModel.availableReportTypes()[i].ProfileURL(propertyProfileModel.availableReportTypes()[i].ProfileURL() + "&" + queryStringParameter + "=" + value);
	                            propertyProfileModel.availableReportTypes()[i].ProfileURL(replaceURLParameters(propertyProfileModel.availableReportTypes()[i].ProfileURL(), queryStringParameter, value));
	                        }
	                    }
	                }
	            }
	        }
	    }

	    function replaceURLParameters(url, queryStringParameter, value) {
	        var vars = [], hash;
	        var urlWithoutParams = url.slice(0, url.indexOf('?'));
	        var finalURLParams = "";
	        var replaced = false;
	        var hashes = url.slice(url.indexOf('?') + 1).split('&');
	        for (var i = 0; i < hashes.length; i++) {
	            hash = hashes[i].split('=');
	            if (hash[0] === queryStringParameter) {
	                hash[1] = value;
	                replaced = true;
	            }
	            if (i > 0)
	                finalURLParams = finalURLParams + "&" + hash[0] + "=" + hash[1];
	            else
	                finalURLParams = hash[0] + "=" + hash[1];
	        }
	        if (!replaced) {
	            finalURLParams = finalURLParams + "&" + queryStringParameter + "=" + value;
	        }
	        return urlWithoutParams + "?" + finalURLParams;
	    }

	    function getReport(selectedReport, event) {
	        if (session.userId != null) {
	            $('#mdEmailPropertyProfileReport').modal('hide');
	            $('#mdPropertyProfile').modal('hide');
	            if (selectedReport.Id == 87 || selectedReport.Id == 85 || selectedReport.Id == 91) {
	                var url = config.getBKFSTPReportsUrl;
	                var request = "";
	                if (selectedReport.Id == 87 || selectedReport.Id == 85) {
	                    request = {
	                        OperationId: session.defaultOpertionId(),
	                        ServiceId: selectedReport.Id,
	                        BrandId: session.brandId,
	                        Fips: selectedProperty().FIPS,
	                        APN: selectedProperty().APN
	                    };
	                }
	                if (selectedReport.Id == 91) {
	                    request = {
	                        OperationId: session.defaultOpertionId(),
	                        ServiceId: selectedReport.Id,
	                        BrandId: session.brandId,
	                        Fips: selectedProperty().FIPS,
	                        APN: selectedProperty().APN,
	                        Owner: selectedProperty().Owner,
	                        Address: selectedProperty().Address,
	                        City: selectedProperty().City,
	                        County: selectedProperty().County,
	                        State: selectedProperty().State,
	                        Zip: selectedProperty().ZIP,
	                        Latitude: selectedProperty().Latitude,
	                        Longitude: selectedProperty().Longitude
	                    };
	                }
	                https.get(url, request)
					.then(function (data) {
					    console.log(data);
					    window.open(data);
					})
						.fail(function (data) {
						    console.log(data);
						});
	            }
	            else if (selectedReport.Id == 84) {
	                //getProfileCustomSettingsForUser(session.userId).done(function (data) {
	                //	if (data != null) {
	                console.log('open profile report modal');
	                reportPreparedForModel.reset();
	                propertyProfileService.getPropertyProfileInfo(selectedProperty().FIPS, selectedProperty().APN, null).done(function (result) {
	                    propertyProfileModel.resultStatus(result.ResultStatus);
	                    propertyProfileModel.profileId(result.ProfileId);
	                    propertyProfileModel.property(result.Property);
	                    propertyProfileModel.availableSections(propertyProfileService.loadToKOArray(result.AvailableSections).slice(0));
	                    propertyProfileModel.availableReportTypes(propertyProfileService.loadToKOArray(result.AvailableReportTypes).slice(0));
	                    propertyProfileModel.assignedCredits(selectedReport.UsageLimitAssigned);
	                    propertyProfileModel.usedCredits(selectedReport.UsageCountUsed);
	                    propertyProfileModel.enableReportPreparedFor(result.EnableReportPreparedForSection);
	                }).then(showPropertyProfilePopUp).fail(function () {
	                    alert.error('Error getting Property Profile Info');
	                });
	                //	}
	                //	else {
	                //		console.log('Custom Comparable Settings has not been enabled');
	                //		alert.error('Custom Comparable Settings has not been enabled');
	                //		return;
	                //	}
	                //});
	            }
	        }
	        else {
	            console.log('User Id is null');
	            alert.error('User not logged in');
	            return;
	        }
	    }

	    function getProfileReport() {
	        reportPreparedForModel.reset();
	        propertyProfileService.getPropertyProfileInfo(selectedProperty().FIPS, selectedProperty().APN, null).done(function (result) {
	            propertyProfileModel.resultStatus(result.ResultStatus);
	            propertyProfileModel.profileId(result.ProfileId);
	            propertyProfileModel.property(result.Property);
	            propertyProfileModel.availableSections(propertyProfileService.loadToKOArray(result.AvailableSections).slice(0));
	            propertyProfileModel.availableReportTypes(propertyProfileService.loadToKOArray(result.AvailableReportTypes).slice(0));
	            // propertyProfileModel.assignedCredits(selectedReport.UsageLimitAssigned);
	            // propertyProfileModel.usedCredits(selectedReport.UsageCountUsed);
	            propertyProfileModel.enableReportPreparedFor(result.EnableReportPreparedForSection);
	        }).then(showPropertyProfilePopUp).fail(function () {
	            alert.error('Error getting Property Profile Info');
	        });
	        // $('#mdButtonProfile').modal('show');
	    }

	    function showPropertyProfilePopUp() {
	        console.log(propertyProfileModel.profileId());
	        console.log(propertyProfileModel.availableReportTypes());
	        console.log(propertyProfileModel.availableSections());

	        showMailDialog = true;
	        $('#mdEmailPropertyProfileReport').modal('hide');
	        $('#mdPropertyProfile').modal('show');
	    }

	    function propertyProfilePopup() {
	        $('#mdCustomComparables').modal('show');

	    }

	    function saveDefaultSetting() {
	        var selected = new Array();
	        $.each(propertyProfileModel.availableSections(), function (index, data) {
	            if (data.CheckBoxValue() === true) {
	                selected.push(data.Value);
	            }
	        });
	        propertyProfileService.setReportCustomizationForUser(selected);
	    }

	    function EmailPropertyProfileReportPopUp(ReportName, ReportURL) {
	        if (showMailDialog) {
	            if (ReportName() != null && ReportURL() != null && ReportName() != '' && ReportURL() != '') {
	                emailPropertyProfileReportModel.reset();
	                emailPropertyProfileReportModel.selectedReport(ReportName());
	                emailPropertyProfileReportModel.selectedReportURL(ReportURL());
	                showEmailPropertyProfileReports();
	            }
	        }
	    }

	    //function CloseSendEmailPropertyProfileReportPopUp()
	    //{
	    //    showMailDialog = false;
	    //    $('#mdEmailPropertyProfileReport').modal('hide');
	    //}

	    function showEmailPropertyProfileReports() {
	        if (showMailDialog)
	            $('#mdEmailPropertyProfileReport').modal('show');
	    }

	    function SendEmailPropertyProfileReport() {
	        if (vm.emailPropertyProfileReportModelError != null && vm.emailPropertyProfileReportModelError != 'undefined' && vm.emailPropertyProfileReportModelError().length > 0) {
	            vm.emailPropertyProfileReportModelError.showAllMessages();
	            return;
	        }

	        var url = config.emailPropertyProfileReportUrl;
	        var request = {
	            "UserId": session.userId,
	            "OperationId": session.defaultOpertionId(),
	            "EmailAddress": emailPropertyProfileReportModel.emailAddresses(),
	            "EmailSubject": emailPropertyProfileReportModel.emailSubject(),
	            "EmailMessage": emailPropertyProfileReportModel.emailMessage(),
	            "ReadReceipt": emailPropertyProfileReportModel.readReceipt(),
	            "ReportURL": emailPropertyProfileReportModel.selectedReportURL(),
	            "ReportName": emailPropertyProfileReportModel.selectedReport(),
	            "SelectedProperty": {
	                "UserId": session.userId,
	                "Apn": selectedProperty().APN,
	                "Fips": selectedProperty().FIPS,
	                "Address1": selectedProperty().Address,
	                "Address2": "",
	                "Zip": selectedProperty().ZIP,
	                "City": selectedProperty().City,
	                "State": selectedProperty().State,
	                "Zip4": selectedProperty().ZIP4,
	                "Owner": selectedProperty().Owner,
	            },
	        };

	        return https.post(url, request)
			.done(function (data) {
			    $('#mdEmailPropertyProfileReport').modal('hide');
			    if (data != null) {
			        if (data.HasErrors == false) {
			            alert.success("Email Sent", "Email Property Profile Report");
			        }
			        else {
			            alert.error('Error while sending email', "Email Property Profile Report");
			        }
			    }
			    else {
			        alert.error('Internal Server Error', "Email Property Profile Report");
			    }
			});
	    }
	    //BKFS End
	    //-------------------------------------------------------------------------------------------------------------------------------------

		var vm = {
			attached: initMap,
			bindingComplete: setupMetisMenu,
			isAdvanceSearchEnabled: isAdvanceSearchEnabled,
			showAdvanceSearch: showAdvanceSearch,
			showAdvanceSearchBadge: showAdvanceSearchBadge,
			isBasicSearchNoResult: isBasicSearchNoResult,
			closeAdvanceSearch: closeAdvanceSearch,
			isFindYourPropertyEnabled: isFindYourPropertyEnabled,
			searchProperty: searchProperty,
			activate: activate,
			isAdvanceSearchFromAddressSearch: isAdvanceSearchFromAddressSearch,
			minimizeAdvanceSearch: minimizeAdvanceSearch,
			searchResult: searchResult,
			showBasicSearch: showBasicSearch,
			addressSearch: addressSearch,
			ownerSearch: ownerSearch,
			apnSearch: apnSearch,
			streetSearch: streetSearch,
			apnModel: apnModel,
			ownerModel: ownerModel,
			addressModel: addressModel,
			streetLookupModel: streetLookupModel,
			searchPropertyByAdress: searchPropertyByAdress,
			searchPropertyByOwner: searchPropertyByOwner,
			searchPropertyByAPN: searchPropertyByAPN,
			searchPropertyByStreet: searchPropertyByStreet,
			directions: directions,
			searchPropertyByStreet: searchPropertyByStreet,
			GetStatesList: GetStatesList,
			stateList: stateList,
			ownerCountyList: ownerCountyList,
			apnCountyList: apnCountyList,
			streetCountyList: streetCountyList,
			propertyListing: propertyListing,
			ownerfips: ownerfips,
			streetfips: streetfips,
			apnfips: apnfips,
			ownerfipsNo: ownerfipsNo,
			streetfipsNo: streetfipsNo,
			apnfipsNo: apnfipsNo,
			cleartMapResults: cleartMapResults,
			isPredictiveSearch: isPredictiveSearch,
			searchByGooglePrediction: searchByGooglePrediction,
			predictiveTextBox: predictiveTextBox,
			getPropertyDetail: getPropertyDetail,
			getAddressString: getAddressString,
			isSearchable: isSearchable,
			hasZipFocused: hasZipFocused,
			commonDialogModel: commonDialogModel,
			isPredictiveInput: isPredictiveInput,
			isStreetView: isStreetView,
			propertyDetailResponse: propertyDetailResponse,
			//getAddressString: propertyDetailService.getAddressString,
			selectedProperty: selectedProperty,
			customCompSettingsModel: customCompSettingsModel,
			getProfileCustomSettingsForUser: getProfileCustomSettingsForUser,
			saveCompSettings: saveCompSettings,
			customComparablesPopup: customComparablesPopup,
			resultOwner_click: resultOwner_click,
			reportsContainer: reportsContainer,
			togglePropertyReportView: togglePropertyReportView,
			serviceProducts: serviceProducts,
			productServicesProducts: productServicesProducts,
			purchaseableReportsShoppingCart: purchaseableReportsShoppingCart,
			shoppingCartLabel: shoppingCartLabel,
			shoppingCartEnable: shoppingCartEnable,
			walletBalance: walletBalance,
			creditBalance: creditBalance,
			walletEnabled: walletEnabled,
			creditsEnabled: creditsEnabled,
			hasPrice: hasPrice,
			formatCurrency: formatCurrency,
			getPriceString: propertyDetailService.getPriceString,
			getRemainingCreditsValue: propertyDetailService.getRemainingCreditsValue,
			getCreditString: propertyDetailService.getCreditString,
			getRemainingCredits: propertyDetailService.getRemainingCredits,
			propertyProfileModel: propertyProfileModel,
			propertyProfileResultStatus: propertyProfileResultStatus,
			propertyProfileProfileId: propertyProfileProfileId,
			propertyProfileProperty: propertyProfileProperty,
			propertyProfileAvailableSections: propertyProfileAvailableSections,
			propertyProfileAvailableReportTypes: propertyProfileAvailableReportTypes,
			getReport: getReport,
			showReports: showReports,
			selectedAPN: selectedAPN,
			validateDateRange: validateDateRange,
			toggleAddToCart: toggleAddToCart,
			showShoppingCart: showShoppingCart,
			purchaseableReportsShoppingCartItems: purchaseableReportsShoppingCartItems,
			createOrder: createOrder,
			reportLoaded: reportLoaded,
			searchBtnText: searchBtnText,
			saveDefaultSetting: saveDefaultSetting,
			defaultSettings: defaultSettings,
			reportPreparedForModel: reportPreparedForModel,
			ChangeProfileURL: ChangeProfileURL,
			emailPropertyProfileReportModel: emailPropertyProfileReportModel,
			EmailPropertyProfileReportPopUp: EmailPropertyProfileReportPopUp,
			showEmailPropertyProfileReports: showEmailPropertyProfileReports,
			SendEmailPropertyProfileReport: SendEmailPropertyProfileReport,
			recentSearches: session.recentSearches,
			onRecentSearchClick: onRecentSearchClick,
			showRecentSearchBadge: showRecentSearchBadge,
			onRecentSearchHeaderClick: onRecentSearchHeaderClick,
			getProfileReport: getProfileReport,
			checkLLAAgreement: session.checkLLAAgreement
		};


	    vm.APNErrors = ko.validation.group([vm.apnModel]);

	    vm.emailPropertyProfileReportModelError = ko.validation.group([vm.emailPropertyProfileReportModel]);

	    vm.ownerErrors = ko.validation.group([vm.ownerModel]);

	    vm.addressErrors = ko.validation.group([vm.addressModel]);
	    vm.streetErrors = ko.validation.group([vm.streetLookupModel]);
	    vm.customSettingsErrors = ko.validation.group([vm.customCompSettingsModel]);
	    vm.predictiveTextBox.subscribe(function (newVal) {
	        if (newVal.length > 0) {
	            //console.log('predictiveTextBox updated');
	            //searchBox = new google.maps.places.SearchBox(input);        
	        }
	    });

	    vm.ownerModel.state.subscribe(function (state) {
	        if (state != undefined && state != '') {
	            getOwnerCounties(state);
	        }
	    });

	    vm.apnModel.state.subscribe(function (state) {
	        if (state != undefined && state != '') {
	            getApnModelCounties(state);
	        }
	    });

	    vm.streetLookupModel.state.subscribe(function (state) {
	        if (state != undefined && state != '') {
	            getStreetLookupCounties(state);
	        }
	    });

	    vm.addressModel.zip.subscribe(function (zip) {
	        if (zip != undefined && zip != '') {
	            var url = config.getZipInfoUrl;
	            isSearchable(false);
	            Q.when(https.get(url, {
	                zipCode: zip,
	                format: 'json'
	            })
					   .then(function (data) {
					       if (data.CountyName != null && data.Fips != null) {

					           addressModel.city(data.City);
					           addressModel.state(data.StateAbbr);

					       }
					       else {
					           alert.error("Invalid Zip.");
					           addressModel.zip('');
					           addressModel.city('');
					           addressModel.state('');
					       }



					   }).always(function () {
					       isSearchable(true);
					   })

				   .fail(Failed));

	        }
	        else {

	        }
	    });

	    vm.ownerModel.zip.subscribe(function (zip) {
	        if (zip != undefined && zip != '') {
	            ownerfips([]);
	            var url = config.getZipInfoUrl;
	            isSearchable(false);
	            Q.when(https.get(url, {
	                zipCode: zip,
	                format: 'json'
	            })
							   .then(function (data) {
							       if (data.CountyName != null && data.Fips != null) {
							           ownerfips(data);

							           if (ownerfips().Fips != null) {
							               ownerfipsNo = ownerfips().Fips;
							           }
							           ownerModel.state(data.StateAbbr);
							           getOwnerCounties(data.StateAbbr);

							       }
							       else {
							           alert.error("Invalid Zip.");
							           ownerModel.zip('');
							           ownerModel.state('');
							           ownerModel.county('');


							       }

							   }).always(function () {
							       isSearchable(true);
							   })

				   .fail(Failed));
	        }
	    });

	    vm.apnModel.zip.subscribe(function (zip) {
	        if (zip != undefined && zip != '') {
	            apnfips([]);
	            var url = config.getZipInfoUrl;
	            isSearchable(false);
	            Q.when(https.get(url, {
	                zipCode: zip,
	                format: 'json'
	            })
					.then(function (data) {
					    if (data.CountyName != null && data.Fips != null) {
					        apnfips(data);

					        if (apnfips().Fips != null) {
					            apnfipsNo = apnfips().Fips;
					        }

					        apnModel.state(data.StateAbbr);
					        getApnModelCounties(data.StateAbbr);

					    }
					    else {
					        alert.error("Invalid Zip.");
					        apnModel.zip('');
					        apnModel.state('');
					        apnModel.county('');
					    }

					}).always(function () {
					    isSearchable(true);
					})

				   .fail(Failed));
	        }
	    });

	    vm.streetLookupModel.zip.subscribe(function (zip) {
	        if (zip != undefined && zip != '') {
	            streetfips([]);

	            var url = config.getZipInfoUrl;
	            isSearchable(true);
	            Q.when(https.get(url, {
	                zipCode: zip,
	                format: 'json'
	            })
				   .then(function (data) {
				       if (data.CountyName != null && data.Fips != null) {
				           streetfips(data);

				           if (streetfips().Fips != null) {
				               streetfipsNo = streetfips().Fips;
				           }
				           streetLookupModel.state(data.StateAbbr);
				           streetLookupModel.city(data.City);
				           getStreetLookupCounties(data.StateAbbr);

				       }
				       else {
				           alert.error("Invalid Zip.");
				           streetLookupModel.zip('');
				           streetLookupModel.state('');
				           streetLookupModel.city('');
				           streetLookupModel.county('');
				       }

				   }).always(function () {
				       isSearchable(true);
				   })

		.fail(Failed));
	        }
	    });

	    return vm;

	    function reportLoaded() {
	        console.log('reportLoaded');
	        $('#dvAvailableReports').slimScroll({
	            height: '180px',
	        });
	    }

	    function showBasicSearch() {
	        showMailDialog = true;
	        cleartMapResults();
	        //searchBox = new google.maps.places.SearchBox(input);
	        //initMapAutocomplete();
	        isFindYourPropertyEnabled(true);
	        isPredictiveInput(true);
	        isStreetView(false);
	        showReports(false);
	    }

	    function getPropertyDetailsFromMapLink(selectedAPN) {
	        ko.utils.arrayFilter(propertyListing(), function (item) {
	            if (item.APN == selectedAPN) {
	                loadPropertyDetail(item);
	            }
	        });
	        return false;
	    }

	    function formatCurrency(value) {
	        return propertyDetailService.formatCurrency(value());
	    }



	    function resultOwner_click(selectedItem, event) {

	        loadPropertyDetail(selectedItem);
	        showReports(false);
	    }


	    function loadPropertyDetail(selectedItem) {
	        pushItemToRecentSearches(selectedItem);
	        selectedAPN(selectedItem.APN);
	        propertyDetailResponse(undefined);
	        reportsContainer('propertySearchModule/_showReportButtonContainer.html');
	        selectedProperty(selectedItem);
	        setStreetView(selectedItem.Latitude, selectedItem.Longitude);
	        //google.maps.event.trigger(map, "resize");

	        setTimeout(function () {
	            google.maps.event.trigger(map, 'resize');
	            map.setCenter(new google.maps.LatLng(selectedItem.Latitude, selectedItem.Longitude));
	        }, 100);


	        //google.maps.event.trigger(markers[0], "mouseover");
	        purchaseableReportsShoppingCartItems([]);
	        purchaseableReportsShoppingCart([]);
	        shoppingCartEnable(false);
	        shoppingCartLabel("Shopping Cart");

	    }

	    function pushItemToRecentSearches(item) {
	        var removedItem = session.recentSearches.remove(function (data) {
	            return data.APN == item.APN;
	        });
	        if (removedItem.length <= 0 && session.recentSearches().length > 4) {
	            session.recentSearches.pop();
	        }
	        session.recentSearches.unshift(item);
	    }

	    function handlePropertyDetailView() {
	        purchaseableReportsShoppingCart([]);
	        shoppingCartLabel("Shopping Cart");
	        shoppingCartEnable(false);
	    }


	    function togglePropertyReportView() {
	        showReports(true);
	        showMailDialog = false;
	        return getPropertyReports();
	    }



	    function getPropertyReports() {
	        return getPropertyDetailByObject(selectedProperty).done(function (id) {
	            propertyDetailService.getPropertyDetailsById(id).done(function (result) {
	                //propertyDetailResponse(result);
	                return loadReportResults(result);
	                //response.hasGetMethodError(result.HasErrors);
	            });
	        });
	    }

	    function loadReportResults(result) {
	        //console.log('loadReportResults');

	        var converted = propertyDetailService.loadPurchaseableReportsToKOArray(result.ProductServicesProducts);
	        productServicesProducts(converted);
	        for (var i = 0; i < converted.length; i++) {
	            var priceValue = converted[i].Price();
	            if (priceValue.indexOf('$') > -1)
	                priceValue = priceValue.replace('$', '');
	            //if(converted[i].Credits > 0 && )
	            //{

	            //}
	            if (priceValue > 0) {
	                hasPrice(true);
	                break;
	            }
	        }
	        //console.log(productServicesProducts());
	        serviceProducts(result.ServiceProducts);
	        console.log(serviceProducts());
	        walletBalance(result.WalletBalance);
	        creditBalance(result.CreditBalance);
	        walletEnabled(result.WalletEnabled);
	        creditsEnabled(result.CreditsEnabled);

	        //Required for Payment
	        session.purchasableProdutsWalletBalance(result.WalletBalance);
	        session.purchasableProdutsCreditBalance(result.CreditBalance);
	        session.purchasableProdutsWalletEnabled(result.WalletEnabled);
	        session.purchasableProdutsCreditsEnabled(result.CreditsEnabled);
	        session.purchasableProdutsTax(result.Tax);
	        session.selectedPropertyId(result.Property.Id);

	        reportsContainer('propertySearchModule/_availableReports.html');
	        reportLoaded();
	        showReports(false);
	        return true;
	    }


	    function getPropertyDetail(selectedItem, event) {
	        getPropertyDetailByObject(selectedItem);
	    }

	    function getPropertyDetailByObject(selectedItem) {
	        var url = config.addToMyPropertiesUrl;
	        var property = {
	            "APN": selectedItem().APN,
	            "Address": selectedItem().Address,
	            "AssessmentYear": selectedItem().AssessmentYear,
	            "BorrowAmount": selectedItem().BorrowAmount,
	            "City": selectedItem().City,
	            "FIPS": selectedItem().FIPS,
	            "LatestSalePrice": selectedItem().LatestSalePrice,
	            "LatestSaleRecordingDate": selectedItem().LatestSaleRecordingDate,
	            "Latitude": selectedItem().Latitude,
	            "LoanAmount": selectedItem().LoanAmount,
	            "Longitude": selectedItem().Longitude,
	            "Owner": selectedItem().Owner,
	            "OwnerMailAddress": selectedItem().OwnerMailAddress,
	            "OwnerMailCity": selectedItem().OwnerMailCity,
	            "OwnerMailState": selectedItem().OwnerMailState,
	            "OwnerMailUnit": selectedItem().OwnerMailUnit,
	            "OwnerMailUnitType": selectedItem().OwnerMailUnitType,
	            "OwnerMailZip": selectedItem().OwnerMailZip,
	            "OwnerMailZip4": selectedItem().OwnerMailZip4,
	            "State": selectedItem().State,
	            "TaxDelinquency": selectedItem().TaxDelinquency,
	            "TaxRateCodeArea": selectedItem().TaxRateCodeArea,
	            "TotalAssessedValue": selectedItem().TotalAssessedValue,
	            "TotalMarketValue": selectedItem().TotalMarketValue,
	            "UnitNumber": selectedItem().UnitNumber,
	            "UnitType": selectedItem().UnitType,
	            "UseCode": selectedItem().UseCode,
	            "UseCodeDescription": selectedItem().UseCodeDescription,
	            "ZIP": selectedItem().ZIP,
	            "ZIP4": selectedItem().ZIP4
	        };
	        url = $.getBasePath() + "api/Property/AddToMyProperties?userId=" + session.userId;
	        return https.post(url, property)
				   .then(function (data) {
				       if (data) {
				           //console.log(data);

				           //getPropertyDetail(session.userId, session.defaultOpertionId(), data.Id).done(function (result) {
				           //router.navigate("#/propertyDetail/" + data.Id);
				           return data.Id;
				           //});
				       }

				   })
		.fail(Failed);
	    }

	    function getAddressString(propertyData) {
	        //console.log(propertyData);

	        var addressString = '';

	        if (propertyData.Address.length > 0)
	            addressString = propertyData.Address + ' ';

	        if (propertyData.UnitType.length > 0)
	            addressString = addressString + propertyData.UnitType + ' ';

	        if (propertyData.UnitNumber.length > 0)
	            addressString = addressString + propertyData.UnitNumber + ' ';


	        if (propertyData.City.length > 0)
	            addressString = addressString + propertyData.City + ', ';

	        if (propertyData.State.length > 0)
	            addressString = addressString + propertyData.State + ' ';

	        if (propertyData.ZIP.length > 0)
	            addressString = addressString + propertyData.ZIP;

	        if (propertyData.ZIP4.length > 0) {
	            addressString = addressString + '-' + propertyData.ZIP4
	        }

	        return addressString;

	    }

	    //function getPropertyDetail(userId,operationId,propertyId) {
	    //    var url = config.getPropertyDetailUrl;
	    //   return https.get(url, {
	    //        userId: userId,
	    //        operationId:operationId,
	    //        propertyId:propertyId,
	    //        format: 'json'
	    //    })

	    //}

	    function GetStatesList() {
	        var url = config.stateUrl;
	        stateList([]);
	        Q.when(https.get(url, {
	            stateCode: true, format: 'json'
	        })
			   .then(function (data) {
			       stateList(data);
			   })
			   .fail(Failed));
	    }

	    function Failed(error) {
	        alert.error(error.statusText);

	    }


	    function getOwnerCounties(state) {
	        var url = config.countyUrl;
	        isSearchable(false);
	        Q.when(https.get(url, {
	            stateAbbr: state,
	            format: 'json'
	        })
				   .then(function (data) {
				       ownerCountyList(data);
				       if (ownerfips().Fips != undefined && ownerfips().Fips != '') {
				           ownerModel.county(ownerfips().Fips);
				       }
				   }).always(function () {
				       isSearchable(true);
				   })
		   .fail(Failed)
		);
	    }

	    function getApnModelCounties(state) {
	        var url = config.countyUrl;
	        isSearchable(false);
	        Q.when(https.get(url, {
	            stateAbbr: state,
	            format: 'json'
	        })
					   .then(function (data) {
					       apnCountyList(data);
					       if (apnfips().Fips != undefined && apnfips().Fips != '') {
					           apnModel.county(apnfips().Fips);
					       }
					   }).always(function () {
					       isSearchable(true);
					   })
				   .fail(Failed)
			);
	    }

	    function getStreetLookupCounties(state) {
	        var url = config.countyUrl;
	        isSearchable(false);
	        session.isAjaxPost(true);
	        Q.when(https.get(url, {
	            stateAbbr: state,
	            format: 'json'
	        })
					   .then(function (data) {
					       streetCountyList(data);
					       if (streetfips().Fips != undefined && streetfips().Fips != '') {
					           streetLookupModel.county(streetfips().Fips);
					       }
					   }).always(function () {
					       isSearchable(true);
					       session.isAjaxPost(false);
					   })
			.fail(Failed)
		);
	    }


		function searchPropertyByAdress() {
            if (!session.llaAccepted()) {
		        session.showLLAAgreement();
		        return;
		    }
			isMarkable = true;
			cleartMapResults();
			if (vm.addressErrors().length > 0) {
				vm.addressErrors.showAllMessages();
				return;
			}
			isQuickSearchResult(false);
			isSearchable(false);
			isMarkable = true;
			searchResult(undefined);
			var result = addressSearch.addressSearch(searchResult, addressModel);
		}

		function searchPropertyByAPN() {
		    if (!session.llaAccepted()) {
		        session.showLLAAgreement();
		        return;
		    }
			cleartMapResults();
			isMarkable = true;
			if (vm.APNErrors().length > 0) {
				vm.APNErrors.showAllMessages();
				return;
			}
			isQuickSearchResult(false);
			isSearchable(false);
			console.log('SearchPropertyByAPN called');
			console.log(apnModel);
			apnSearch.addressSearch(searchResult, apnModel);
		}

		function searchPropertyByOwner() {
		    if (!session.llaAccepted()) {
		        session.showLLAAgreement();
		        return;
		    }
			cleartMapResults();
			isMarkable = true;
			if (vm.ownerErrors().length > 0) {
				vm.ownerErrors.showAllMessages();
				return;
			}
			isQuickSearchResult(false);
			isSearchable(false);
			ownerSearch.addressSearch(searchResult, ownerModel);
		}

		function searchPropertyByStreet() {
		    if (!session.llaAccepted()) {
		        session.showLLAAgreement();
		        return;
		    }
			cleartMapResults();
			isMarkable = true;
			if (vm.streetErrors().length > 0) {
				vm.streetErrors.showAllMessages();
				return;
			}
			isQuickSearchResult(false);
			isSearchable(false);
			// searchResult([]);
			console.log('searchPropertyByStreet called');
			streetSearch.addressSearch(searchResult, streetLookupModel);
		}


	    function activate(isAdvanceSearch) {

	        ///////////////BKFS
	        showMailDialog = false;

	        isStreetView(false);

	        shoppingCartLabel("Shopping Cart");
	        isFindYourPropertyEnabled(true);

	        //dtResults = $('#tblPropertyResults').DataTable();

	        GetStatesList();


	        setDMKey();



	        if (isAdvanceSearch == 'true') {
	            isAdvanceSearchFromAddressSearch(false);
	            isAdvanceSearchEnabled(true);

	        }
	        else {
	            isAdvanceSearchFromAddressSearch(true);
	            isAdvanceSearchEnabled(false);
	        }
	        resetAdvanceSearch();
	        isPredictiveInput(true);
	        defaultSettings(true);
	        //searchResult([]);
	        //console.log('activate called');
	        //console.log('isAdvanceSearchFromAddressSearch ' + isAdvanceSearchFromAddressSearch());
	    }

	    function initMap() {
	        $('#pac-input').val('');
	        isSearchable(true);
	        isMarkable = false;
	        isLatLongAvailable(false);
	        input = document.getElementById('pac-input');
	        searchBox = new google.maps.places.SearchBox(input);
	        console.log('initMap called');

	        $('.collapse').on('shown.bs.collapse', function () {
	            $('.panel-collapse').not(this).removeClass("in");
	            $(".indicator").removeClass("fa-chevron-up").addClass("fa-chevron-down");
	            $(this).parent().find(".fa-chevron-down").removeClass("fa-chevron-down").addClass("fa-chevron-up");
	        }).on('hidden.bs.collapse', function () {

	            $(this).parent().find(".fa-chevron-up").removeClass("fa-chevron-up").addClass("fa-chevron-down");
	        });

	        AfterLoad();
	        Q.when(https.get(config.getCurrentUserFullAddress))
			.then(function (data) {
			    currentUserFullAddress = data;
			    setTimeout(drawMap, 100);
			})
	    }

	    function showAdvanceSearch() {
	        isAdvanceSearchEnabled(true);
	        isMinimizeAdvanceSearch(false);
	        isPredictiveSearch(false);
	        $('#pac-input').val('');
	    }

	    function closeAdvanceSearch() {
	        isAdvanceSearchEnabled(false);

	        resetAdvanceSearch();
	        isStreetView(false);
	    }

	    function resetAdvanceSearch() {
	        addressModel.reset();
	        apnModel.reset();
	        ownerModel.reset();
	        streetLookupModel.reset();

	        clearMarkers();
	        isStreetView(false);

	        propertyListing([]);
	    }

	    function cleartMapResults() {
	        clearMarkers();

	        propertyListing([]);
	        map.setZoom(14);

	        map.setOptions(
			{
			    streetViewControl: false
			});

	    }



	    function clearMarkers() {
	        for (var i = 0; i < markers.length; i++) {
	            markers[i].setMap(null);
	        }
	    }

	    function minimizeAdvanceSearch() {
	        isMinimizeAdvanceSearch(true);
	        isAdvanceSearchEnabled(false);
	    }



	    function searchProperty() {
	        showAdvanceSearch();
	        isBasicSearchNoResult(true);
	    }



	    function drawMap() {

	        if (document.getElementById('map') != null) {
	            var geocoder = new google.maps.Geocoder(document.getElementById('map'));
	            geocoder.geocode(
				{
				    'address': currentUserFullAddress
				},
						function (results, status) {
						    if (status == google.maps.GeocoderStatus.OK) {
						        var location = results[0].geometry.location;
						        latitude(location.lat());
						        longitude(location.lng());
						    }
						    else {
						        latitude(33.6694649);
						        longitude(-117.8231107);
						    }
						    isLatLongAvailable(true);
						    isLatLongAvailable(false);
						    return;
						});
	        }

	    }

	    function AfterLoad() {
	        if ($('#hdnIsLogin').val() == "true") {
	            $('#page-wrapper').attr('style', 'margin-left:220!important');
	        }
	    }
	    function setupMetisMenu() {
	        console.log('setupMetisMenu() called');
	        console.log($('.sidebar-collapse').length);

	        $('.sidebar-collapse').slimScroll({
	            height: '580px',
	            railOpacity: 0.9
	        });


	        //$('.advanceSearchSidePanel').slimScroll({
	        //    width: '365px',
	        //    height: '500px',
	        //});
	        //$('.slimScrollDiv').attr('style', 'position:absolute!important');
	        $('.slimScrollDiv').addClass('repositionSearch');

	        $('#side-menu').metisMenu();

	     







	    };


		function customComparablesPopup() {
		    if (session.userId != null) {
		        if (!session.llaAccepted()) {
		            session.showLLAAgreement();
		            return;
		        }
				return getProfileCustomSettingsForUser(session.userId).done(function (data) {
					if (data != null) {
						if (data.AutoComparables == true) {
							customCompSettingsModel.autoComparables(true)
						}
						else {
							customCompSettingsModel.autoComparables(false)
						}
						// customCompSettingsModel.customComparables(data.AutoComparables)
						customCompSettingsModel.numberofSales(data.NumberOfSales)
						customCompSettingsModel.bedrooms(data.BedroomsRange)
						customCompSettingsModel.bathrooms(data.BathroomsRange)
						customCompSettingsModel.squareFootage(data.SquareFootageRange)
						customCompSettingsModel.yearBuiltRangeFrom(moment(data.DateBuiltRangeBegin).format("YYYY-MM-DD"))
						customCompSettingsModel.yearBuiltRangeTo(moment(data.DateBuiltRangeEnd).format("YYYY-MM-DD"))
						customCompSettingsModel.ageofSales(data.AgeOfSaleInMonths)
						customCompSettingsModel.saleAmountFrom('$' + data.SaleAmountRangeBegin.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"))
						customCompSettingsModel.saleAmountTo('$' + data.SaleAmountRangeEnd.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"))
						customCompSettingsModel.radius(data.Radius)
						customCompSettingsModel.sameLegalTract(data.SameLegalTract)
					}

	                $('#mdCustomComparables').modal('show');
	            })
	        }


	    }
	    function getProfileCustomSettingsForUser(userId) {
	        var url = config.getProfileCustomSettingsForUserUrl;
	        return https.get(url, {
	            userId: userId,
	            format: 'json'
	        });
	    }

	    function toggleAddToCart(item, event) {
	        if (item.AddedToCart() == false) {
	            item.AddedToCart(true);
	            item.index(purchaseableReportsShoppingCart().length)
	            if (!hasPrice() && item.Credits() > 0)
	                item.UseCredits(true);
	            purchaseableReportsShoppingCart().push(item);

	        }
	        else {
	            item.AddedToCart(false);
	            purchaseableReportsShoppingCart().splice(item.index(), 1);
	            for (var i = 0; i < purchaseableReportsShoppingCart().length; i++) {
	                purchaseableReportsShoppingCart()[i].index(i);
	            }
	        }

	        if (purchaseableReportsShoppingCart().length > 0) {
	            shoppingCartLabel("Shopping Cart (" + purchaseableReportsShoppingCart().length + ")");
	            shoppingCartEnable(true);
	        }
	        else {
	            shoppingCartLabel("Shopping Cart");
	            shoppingCartEnable(false);
	        }
	    }

	    function saveCompSettings() {

	        if (vm.customSettingsErrors().length > 0) {
	            vm.customSettingsErrors.showAllMessages();
	            return;
	        }
	        var fromDate = customCompSettingsModel.yearBuiltRangeFrom();
	        var toDate = customCompSettingsModel.yearBuiltRangeTo();
	        var datechk = validateDateRange(fromDate, toDate);
	        if (datechk == true) {
	            alert.error("Year Built Range From date  cannot be greater than Year Built Range To. ");
	            customCompSettingsModel.yearBuiltRangeTo('');
	            return false;
	        }
	        var fromAmt = customCompSettingsModel.saleAmountFrom();
	        var toAmt = customCompSettingsModel.saleAmountTo();
	        var amtchk = validateAmount(fromAmt, toAmt);
	        if (amtchk == true) {
	            alert.error("Sale Amount From cannot be greater than Sale Amount To. ");
	            customCompSettingsModel.saleAmountTo('');
	            return false;
	        }

	        var settings = {
	            "AutoComparables": customCompSettingsModel.autoComparables() == true ? true : false,
	            "NumberOfSales": customCompSettingsModel.numberofSales,
	            "BedroomsRange": customCompSettingsModel.bedrooms,
	            "BathroomsRange": customCompSettingsModel.bathrooms,
	            "SquareFootageRange": customCompSettingsModel.squareFootage,
	            "DateBuiltRangeBegin": customCompSettingsModel.yearBuiltRangeFrom,
	            "DateBuiltRangeEnd": customCompSettingsModel.yearBuiltRangeTo,
	            "AgeOfSaleInMonths": customCompSettingsModel.ageofSales,
	            "SaleAmountRangeBegin": Number(customCompSettingsModel.saleAmountFrom().replace(/[^0-9\.]+/g, "")),
	            "SaleAmountRangeEnd": Number(customCompSettingsModel.saleAmountTo().replace(/[^0-9\.]+/g, "")),
	            "Radius": customCompSettingsModel.radius,
	            "SameLegalTract": customCompSettingsModel.sameLegalTract,
	        };
	        url = $.getBasePath() + "api/Property/SaveProfileCustomSettingsForUser?userId=" + session.userId;
	        return https.post(url, settings)
					 .then(function (data) {
					     if (data) {
					         if (data == true) {
					             alert.success("Custom Comparables Settings saved sucessfully.");
					         }
					         else {
					             alert.error("unable to save Custom Comparables Settings")
					         }

					     }

					 })
					  .fail(Failed);
	    }
	    function showShoppingCart() {
	        //purchaseableReportsShoppingCartItems([]);
	        //purchaseableReportsShoppingCartItems(purchaseableReportsShoppingCart());
	        //$('#mdShoppingCart').modal('show');

	        session.purchasableProdutsShoppingCartList([]);
	        session.purchasableProdutsShoppingCartList(purchaseableReportsShoppingCart());
	        session.purchasableProdutsSelectedProperty(selectedProperty());
	        router.navigate("#purchasableProducts");
	    }
	    function validateDateRange(fromDate, toDate) {
	        var fromDate = moment(fromDate);
	        var toDate = moment(toDate);
	        var date = (fromDate > toDate);
	        return date;

	    };
	    function validateAmount(saleAmountFrom, saleAmountTo) {
	        var saleAmtFrom = saleAmountFrom.replace(/[^0-9\.]+/g, "");
	        var saleAmtTo = saleAmountTo.replace(/[^0-9\.]+/g, "");
	        var amount = ((saleAmtFrom - saleAmtTo) > 0);
	        return amount;

	    };

	    function onRecentSearchClick(data) {
	        selectedProperty(data);
	        //searchResult(data);
	        propertyListing([]);
	        propertyListing.push(data);
	        isStreetView(true);
	        isFindYourPropertyEnabled(false);

	        isLatLongAvailable(true);
	        isLatLongAvailable(false);
	        loadPropertyDetail(data);
	    }

	    function onRecentSearchHeaderClick() {
	        $this = $('#recentSearchListPanel');
	        if (!$this.hasClass('panel-collapsed')) {
	            $this.parents('.recentSearchBadge').find('.recent-search-body').slideUp();
	            $this.addClass('panel-collapsed');
	            $this.parents('.recentSearchBadge').find('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
	        } else {
	            $this.parents('.recentSearchBadge').find('.recent-search-body').slideDown();
	            $this.removeClass('panel-collapsed');
	            $this.parents('.recentSearchBadge').find('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
	        }
	    }

	});
