define('viewmodels/userProfileModule/security',['durandal/app', 'knockout', 'securityModel', 'config', 'plugins/http', 'global/session', 'alertNotification', 'passwordMeter', 'shell'],
    function (app, ko, model, config, https, session, alerter, passwordMeter, shell) {
        console.log('VM loading...');
        var passwordModel = new model.PasswordModel();
        //var questionModel = new model.QuestionModel();

        var fullName = ko.observable("");
        var operationName = ko.observable("");
        var userType = ko.observable("");
        var QAChange = ko.observable(false);
        var passwordChange = ko.observable(false);
        var savedQuestion = ko.observable("");
        var pluginFocus = ko.observable(false);
        var profileURL = ko.observable("");
        var useOktaAuthentication = ko.observable(false);
        

        //Do not remove this as this is used for shared View _changePassword.html for 1st Change Password
        //Fix For Bug : 79144
        var isFirstTimeLogin = ko.observable(false);

        pluginFocus.subscribe(function (newValue) {
            if (newValue == true) {
                setPlugins();
            }
        })
        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            passwordModel: passwordModel,
            //questionModel: questionModel,
            updatePassword: updatePassword,
            //updateQnA: updateQnA,
            cancelPwdChange: cancelPwdChange,
            //cancelQnA: cancelQnA,
            fullName: fullName,
            operationName: operationName,
            userType: userType,
            QAChange: QAChange,
            passwordChange: passwordChange,
            enableTooltip: setPlugins,
            compositionComplete: compositionComplete,
            bindingComplete: bindingComplete,
            pluginFocus: pluginFocus,
            isFirstTimeLogin: isFirstTimeLogin,
            cancelPwdChangeGoToLogin: cancelPwdChangeGoToLogin,
            profileURL: profileURL,
            useOktaAuthentication: useOktaAuthentication
        };


        function activate() {
            console.log('VM - activated');
            useOktaAuthentication(session.useOktaAuthentication());
            fullName("");
            operationName("");
            userType("");
            if (useOktaAuthentication() === false) {
                getUserProfileInformation();
            } else {
                getOKTAProfileURL();
            }
            //getQuestion();
        }

        function canDeactivate() {
            vm.passwordModel.reset();
            //vm.questionModel.reset();
            return true;
        }

        function cancelPwdChange() {
            vm.passwordModel.reset();
            resetPasswordMeter();
        }

        function resetPasswordMeter() {
            $('#newPassword').keyup();
            $('#newPassword').trigger('blur');
        }

        function getOKTAProfileURL() {
            var url = config.getOKTAUserProfileURL;
            return https.get(url)
                .done(function (result) {
                    if (result) {
                        profileURL(result);
                    }
                });
        }

        function cancelPwdChangeGoToLogin() {
            vm.passwordModel.reset();
        }

        function compositionComplete() {
            console.log('compositionComplete  called');
            session.validateInput();
            setPlugins();
            $('#newPassword').password({
                shortPass: 'The password is too short',
                badPass: 'Weak; Try combining upper & lower case letters, numbers & special characters',
                goodPass: 'Medium; Try combining upper & lower case letters, numbers & special characters',
                strongPass: 'Strong password',
                containsUsername: 'The password contains the username',
                enterPass: 'Type your password',
                showPercent: false,
                showText: true, // shows the text tips
                animate: true, // whether or not to animate the progress bar on input blur/focus
                animateSpeed: 'fast', // the above animation speed
                username: false, // select the username field (selector or jQuery instance) for better password checks
                usernamePartialMatch: true, // whether to check for username partials
                minimumLength: 8 // minimum password length (below this threshold, the score is 0)
            });
        }

        function bindingComplete() {
            console.log('bindingComplete called');
            setPlugins();
        }
        function setPlugins() {
            $('[data-toggle="tooltip"]').tooltip();
        }
        function getUserProfileInformation() {
            var url = config.getUserProfileInfoUrl;
            return https.get(url, { UserId: session.userId, OperationId: session.defaultOpertionId() })
                .done(function (result) {
                    if (result) {
                        vm.operationName(result.UserInformation.SystemInfo.Operation.Name);
                        vm.fullName(result.UserInformation.LastName + ", " + result.UserInformation.FirstName);
                        vm.userType(result.UserInformation.UserType.Name);
                    }
                }).always(function () {
                    session.isAjaxPost(true);
                })
                .fail(function () {
                    //alerter.error('Error fetching User Information', 'User Information');
                });
        }
        //function getQuestion() {
        //    var url = config.questionUrl;
        //    return https.get(url, { userId: session.userId })
        //        .done(function (result) {
        //            if (result) {
        //                var selectedOption = ko.utils.arrayFirst(questionModel.questionList(), function (item) {
        //                    return item.questionName == result;
        //                });
        //                if (selectedOption !== null) {
        //                    savedQuestion = selectedOption.questionName;
        //                    vm.questionModel.secretQuestion(savedQuestion);
        //                }
        //            }
        //        })
        //        .fail(function () {
        //            alerter.error('Error fetching Question', 'User Information');
        //        });
        //}
        function updatePassword() {

            if (!vm.passwordModel.isValid()) {
                vm.passwordModel.errors.showAllMessages();
                return;
            }
            ChangePassword();
        }

        //function cancelQnA() {
        //    questionModel.reset(false);
        //}

        //function updateQnA() {
        //    if (!vm.questionModel.isValid()) {
        //        vm.questionModel.errors.showAllMessages();
        //        return;
        //    }
        //    ChangeSecretQnA();
        //}

        //function ChangeSecretQnA() {
        //    var url = config.changeSecretAnswerUrl;
        //    vm.QAChange(true);
        //    var request = {
        //        "currentPassword": questionModel.password,
        //        "secretQuestion": questionModel.secretQuestion,
        //        "secretAnswer": questionModel.secretAnswer,
        //        "UserId": session.userId
        //    };

        //    https.post(url, request)
        //        .then(function (data) {
        //            alerter.success("Saved Successfully");
        //            questionModel.reset(false);
        //            vm.QAChange(false);
        //        })
        //        .fail(function (data) {
        //            var message = toErrorString(data);
        //            alerter.error(message);
        //            questionModel.reset();
        //            vm.QAChange(false);
        //        });
        //}



        function ChangePassword() {
            vm.passwordChange(true);
            var url = config.changePasswordUrl;
            var request = {
                "OldPassword": passwordModel.currentPassword,
                "NewPassword": passwordModel.newPassword,
                "ConfirmPassword": passwordModel.confirmPassword,
                "UserId": session.userId
            };

            https.post(url, request)
                .then(function (data,textStatus, xhr)
                {
                    if (data != undefined && data != null && data.HasErrors == false)
                    {
                        alerter.success('Password updated Successfully. The application will log you out shortly. Please login with your new Password.');
                    }
                    else
                    {
                        var msgSuccess = "";
                        var msgError = "";

                        for (var i = 0; i < data.Messages.length; i++)
                        {
                            if (data.Messages[i].Message != "" && data.Messages[i].Level == 500)
                            {
                                if (msgError.trim().indexOf(data.Messages[i].Message) == -1)
                                {
                                    msgError = msgError + data.Messages[i].Message + "\n";
                                }
                            }
                            else
                            {
                                msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                            }
                        }
                        alerter.warning('Password updated Successfully. The application will log you out shortly. Please login with your new Password.' + ' But, ' + msgError);
                    }
                    passwordModel.reset();
                    vm.passwordChange(false);
                    setTimeout(function () { shell.logout(); }, 5000);
                })
                .fail(function (data) {
                    var message = toErrorString(data);
                    alerter.error(message);
                    passwordModel.reset();
                    vm.passwordChange(false);
                });
        }

        function toErrorString(data) {
            var errors, items;
            if (data.responseJSON.ModelState) {

                for (var key in data.responseJSON.ModelState) {
                    items = data.responseJSON.ModelState[key];
                    if (items.length) {
                        errors = items.join(",");
                    }
                }

            }

            if (errors)
                return errors;

            if (data && data.responseJSON && data.responseJSON.Message) {
                errors = data.responseJSON.Message;
                return errors;
            }

            if (errors === undefined) {
                errors = "An error occurred when trying to process your request";
            }

            return errors;
        }

        return vm;
    });
