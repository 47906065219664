define('viewmodels/favorites/favorites',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'knockout.validation', 'plugins/http', 'global/session', 'config', 'alertNotification', 'favoritesModels', 'utlities'],
 function (app, router, $, ko, validate, validation, https, session, config, alert, fModel, utlities) {
     var favoritesModel = new fModel.favorites();
     var favoriteModel = new fModel.favorite();
     var name = ko.observable('');
     var url = ko.observable('');
     var isEdit = ko.observable(false);
     var favoritesList = ko.observableArray([]);
     var _deletingObjectIndex = ko.observable(-1);
     var _deletingObjectTitle = ko.observable("");
     var showButton = ko.observable(true);


     function editable(val) {
         isEdit(true);
         name(val);
         //url(url);
     }
     function activate() {
         isEdit(false);
         favoritesModel.reset();
         var add = session.showAddFavButton();
         showButton(session.showAddFavButton());
         //favoritesModel.List(session.favoritesList());
         //for (var i = 0; i < favoritesModel.List().length; i++)
         //    favoritesModel.List()[i].index = i;

         populateModel();

         _deletingObjectIndex(-1);
         _deletingObjectTitle("");
     }

     function populateModel() {
         favoritesModel.reset();
         for (var i = 0; i < session.favoritesList().length; i++) {
             var favModel = new fModel.favorite();
             favModel.FavoriteId(session.favoritesList()[i].FavoriteId);
             favModel.Name(session.favoritesList()[i].Name);
             favModel.Url(session.favoritesList()[i].Url);
             favModel.index(i);
             favoritesModel.List.push(favModel);
         }
     }
     function savetoDB(fmodel) {
         var url = config.saveFavoriteUrl;

         return https.post(url, {
             FavoriteId: fmodel.FavoriteId(),
             Name: fmodel.Name(),
             Url: fmodel.Url()
         }).then(function (data) {
             if (data)
                 alert.success("Your request was successfully processed.");
             else
                 alert.error("Error processing your request!");

             session.getFavorites().done(function (result) {
             if (session.isLoggedIn() == true) {
                 session.favoritesList(result.FavoritesList);
                 showButton(result.ShowAddButton);
                 populateModel();
                 favoriteModel.reset();
             }
         })
         });
     }


     function updateObject()
     {
         //var id = favoriteModel.Id();
         //var index = favoritesModel.List.indexOf(favoriteModel);
         //favoritesModel.List.splice(index, 1, favoriteModel);

         //favoriteModel.reset();

         _objectUsed = new fModel.favorite();
         _objectUsed.update(favoriteModel);

         favoritesModel.List.splice(favoriteModel.index(), 1, _objectUsed);

         vm.objectErrors = ko.validation.group([favoriteModel]);
         if (vm.objectErrors().length > 0) {
             vm.objectErrors.showAllMessages();
             return;
         }

         savetoDB(_objectUsed);

         //favoriteModel.reset();
         $("#mdCreateFavorite").modal('hide');
     }

     function addNewObject() {
         favoriteModel.reset();
         $("#mdCreateFavorite").modal('show');
         isEdit(false);
         vm.objectErrors = ko.validation.group([favoriteModel]);
         vm.objectErrors.showAllMessages(false);
     }

     function editObject(item, event) {
         //favoriteModel.update(item);
         favoriteModel.update(favoritesModel.List()[item.index()]);
         $("#mdCreateFavorite").modal('show');
         isEdit(true);
     }


     function deleteObject(item, event) {
         $("#mdDeleteItem").modal('show');
         _deletingObjectIndex(item.index())
         _deletingObjectTitle(item.Name());
     }

     function deletObjectConfirmed() {
         favoriteModel.update(favoritesModel.List()[_deletingObjectIndex()])
         favoritesModel.List.splice(_deletingObjectIndex(), 1);
         for (var i = 0; i < favoritesModel.List().length; i++) {
             favoritesModel.List()[i].index(i);
         }
         favoriteModel.Name('');
         savetoDB(favoriteModel);
         favoriteModel.reset();
         $("#mdDeleteItem").modal('hide');
     }

     function deactivate() {
         session.showAddFavButton(showButton());
     }

     function saveObject() {



         if (vm.objectErrors().length > 0) {
             vm.objectErrors.showAllMessages();
             setTimeout(function () {
                 $('.modal-body', '#mdCreateFavorite').scrollTop(500);
             }, 300);
             return;
         }


         _objectUsed = new fModel.favorite();
         favoriteModel.index(favoritesModel.List().length);
         _objectUsed.update(favoriteModel);
         favoritesModel.List.push(_objectUsed);
         _objectUsed.FavoriteId(0);
         savetoDB(_objectUsed);
         
         $("#mdCreateFavorite").modal('hide');
     }

     function closeDialog() {
         //_objectModel.reset();
         $("#mdCreateFavorite").modal('hide');
     }
     function moveUp(item, event) {
         changeFavoritesOrder(item.FavoriteId, true);

     }

     function moveDown(item, event) {
         changeFavoritesOrder(item.FavoriteId, false);

     }
     function changeFavoritesOrder(favId, moveUp) {
         var url = config.changeFavoritesOrderUrl;

         return https.get(url, {
             favId: favId,
             moveUp: moveUp
         }).then(function (data) {

             session.getFavorites().done(function (result) {
                 if (session.isLoggedIn() == true) {
                     session.favoritesList(result.FavoritesList);
                     showButton(result.ShowAddButton);
                     populateModel();
                     favoriteModel.reset();
                 }
         });
         });
     }

     function compositionComplete() {
         session.validateInput();
     }

     var vm = {
         favoritesModel: favoritesModel,
         session: session,
         editable: editable,
         isEdit: isEdit,
         activate: activate,
         editObject: editObject,
         deleteObject: deleteObject,
         closeDialog: closeDialog,
         saveObject: saveObject,
         updateObject: updateObject,
         favoritesList: favoritesList,
         closeDialog: closeDialog,
         addNewObject: addNewObject,
         favoriteModel: favoriteModel,
         deletObjectConfirmed: deletObjectConfirmed,
         _deletingObjectTitle: _deletingObjectTitle,
         deactivate: deactivate,
         moveUp: moveUp,
         moveDown: moveDown,
         showButton: showButton,
         compositionComplete: compositionComplete
         //queryString: queryString
     }
     return vm;

 })



;
