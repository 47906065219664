define('viewmodels/blank',['plugins/router', 'durandal/app', 'knockout', 'services/logger', 'global/session'],
 function (router, app, ko, logger, session) {

     function activate() {
         //TODO: Initialize lookup data here.

         //TODO: Get the data for this viewmodel, return a promise.
         console.log("Home page activated");

         showHomePage();
     }

     function showHomePage() {
         //Needed during page refresh (F5) after login 
         if (session.isLoggedIn()) {
             if (!session.isSalesRepCreatedFirstTimeLogin()) {
                 if (session.brandId === 5 || session.brandId === 21) {
                     if (session.premiumLeadsHomePageEnable) {
                         router.navigate('premiumLeads', { replace: true, trigger: true });
                         return;
                     }
                 }
                 router.navigate('#', { replace: true, trigger: true });
             }
         }
     }

     
     function goBack() {
         router.navigateBack();
     }

     var vm = {
         activate: activate,
         goBack: goBack,
         title: 'Home'
     };

     return vm;
 });
