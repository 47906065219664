define('models/customPage/getDocuments',["knockout", 'plugins/http', 'global/session', 'config', 'utlities'],
    function (ko, https, session, config, utlities) {

        function getDocuments() {
            var self = this;
            self.folderID = ko.observable("");
            self.folderGUID = ko.observable("");
            self.parentFolderId = ko.observable("");
            self.parentFolderName = ko.observable("");
            self.parentFolderGUID = ko.observable("");
            self.parentFolderCreateDate = ko.observable("");
            self.parentFolderName = ko.observable("");
            self.folderName = ko.observable("");
            self.folderCreateDate = ko.observable("");
            self.documentID = ko.observable("");
            self.documentName = ko.observable("");
            self.documentNameStored = ko.observable("");
            self.documentFullPath = ko.observable("");
            self.docSizeInKb = ko.observable("");
            self.documentUploadDate = ko.observable("");
            self.documentTitle = ko.observable("");
            self.documentDescription = ko.observable("");

            self.resultData = function (data) {
                if (data) {
                    self.folderID(data.folderID);
                    self.folderGUID(data.folderGUID);
                    self.parentFolderId(data.parentFolderId);
                    self.parentFolderName(data.parentFolderName);
                    self.parentFolderGUID(data.parentFolderGUID);
                    self.parentFolderCreateDate(data.parentFolderCreateDate);
                    self.parentFolderName(data.parentFolderName);
                    self.folderName(data.folderName);
                    self.folderCreateDate(data.folderCreateDate);
                    self.documentID(data.documentID);
                    self.documentName(data.documentName);
                    self.documentNameStored(data.documentNameStored);
                    self.documentFullPath(data.documentFullPath);
                    self.docSizeInKb(data.docSizeInKb);
                    self.documentUploadDate(data.documentUploadDate);
                    self.documentTitle(data.documentTitle);
                    self.documentDescription(data.documentDescription);

                }
            }
        }

        var vm = {
            getDocuments: getDocuments
        }

        return vm;
    });
