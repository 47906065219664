define('viewmodels/_genLogo',['plugins/router', 'global/session', 'knockout', 'config', 'plugins/http'],
    function (router, session, ko, config,https) {
        var genericLogoURL = ko.observable('');
        var vm = {
            compositionComplete: compositionComplete,
            activate: activate,
            genericLogoURL: genericLogoURL
        };

        return vm;

        function activate() {
            console.log('activated');

            

      
        }

        function compositionComplete() {
            console.log('compositionCompleted');
            var url = config.getCompanyLogoUrl;
            https.get(url, {
                name: session.genericLLABrand(),
                format: 'json'
            }).then(function (data) {
                console.log(data);
                genericLogoURL(data);
                //genericLogoURL('url("https://dev.passport.fntic.com/Content/images/Brands/FNTitle.png")');
            });
        }
    });
