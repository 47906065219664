define('productOrderModel',["knockout", 'moment'],
    function (ko, moment) {
        function ProductOrdersSearch() {
            var self = this;
            var date = new Date();
            self.confirmation = ko.observable("")

            

            self.fromDate = ko.observable()
           


            self.toDate = ko.observable()
             //.extend({
             //    min: {
             //        params:Date.parse(self.fromDate),
             //        message: 'From Date can not be later than To Date.'
             //    }
             //})
          

            self.reset = function () {

                self.confirmation("");
                self.fromDate("")
                self.toDate("")
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }
          
        }
        function ProductOrdersResults() {
            var self = this;
            self.propertyInfo = ko.observable("")
            self.products = ko.observable("")
            self.productsHtmlText = ko.observable("")
            self.orderDate = ko.observable("")
            self.ecommerceId = ko.observable("")
            self.totalRecordsFound = ko.observable(0).extend({ notify: 'always' });
            self.total = ko.observable("")
            
        }

        function ProductOrdersDetails() {
            var self = this;
            self.orderStatus = ko.observable("")
            self.orderDate = ko.observable("")
            self.confirmationNumber = ko.observable("")
            self.propertyAddressHTML = ko.observable("")
            self.subTotal = ko.observable("")
            self.tax = ko.observable("")
            self.total = ko.observable("")
            self.totalValue = ko.observable(0);
            self.orderItems = ko.observableArray([])
            self.invalidOrderItems = ko.observableArray([])
            self.usedCredits = ko.observable(false)
            self.totalCreditString = ko.observable("")
            self.stateTaxString = ko.observable("")
            self.systemProductTypeId = ko.observable(0)
            self.creditsOnly = ko.observable(false)
            
        }

        function ProductOrderItem()
        {
            var self = this;
            self.productName = ko.observable("")
            self.price = ko.observable("")
            self.expiredDate = ko.observable("")
            self.propertyAddressHTML = ko.observable("")
            self.URL = ko.observable("")
            self.expired = ko.observable(false)
        }


        var vm = {
            ProductOrdersSearch: ProductOrdersSearch,
            ProductOrdersResults: ProductOrdersResults,
            ProductOrdersDetails: ProductOrdersDetails,
            ProductOrderItem: ProductOrderItem
        }

        return vm;
    });
