define('models/userProfile/securityModel',["knockout"],
    function (ko) {

        console.log('Model loading...');


        function PasswordModel() {
            var self = this;

            self.title = ko.observable("Security")

            self.currentPassword = ko.observable("")
                .extend({ required: { message: 'Current Password is required.' } })

            self.newPassword = ko.observable("")
                .extend({ required: { params: true, message: 'New Password is required.' } })
                .extend({
                    minLength: {
                        params: 8,
                        message:
                            'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                    }
                })
                .extend({ maxLength: { params: 128, message: 'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.' } })
                .extend({
                    pattern: {
                        params: /\d{1}/,
                        message:
                            'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                    }
                })
                .extend({
                    pattern: {
                        params: /[a-zA-Z]/,
                        message:
                            'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                    }
                })
                .extend({
                    pattern: {
                        params: /(?=.*[a-z])(?=.*[A-Z])/,
                        message:
                            'New Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                    }
                })


            self.confirmPassword = ko.observable("")
                .extend({ required: { params: true, message: 'Confirm Password is required.' } })
                //.extend({ minLength: { params: 8, message: 'Password should be of minimum 8 characters length.' } })
                .extend({ equal: { params: self.newPassword, message: 'New Password and New Password Confirmation must match.' } })

            self.newPassword.subscribe(function (newValue) {
                if (newValue.length >= 128) {
                    $("#maxPasswordLength").show();
                } else {
                    $("#maxPasswordLength").hide();
                }
            })

            self.updatePassword = function (data) {
                if (data) {
                    self.currentPassword(data.currentPassword);
                    self.newPassword(data.newPassword);
                    self.confirmPassword(data.confirmPassword);
                }
            };

            self.reset = function () {
                self.currentPassword("");
                self.newPassword("");
                self.confirmPassword("");
                ko.validation.group(self).showAllMessages(false);
            }

            self.errors = ko.validation.group(self);
            self.isValid = ko.computed(function () {
                return self.errors().length === 0;
            });
        }

        //function QuestionModel() {
        //    var self = this;

        //    self.password = ko.observable("")
        //       .extend({ required: { params: true, message: 'Current Password is required.' } })

        //    self.secretQuestion = ko.observable(undefined)
        //        .extend({ required: { params: true, message: 'Secret Question is required.' } })

        //    self.secretAnswer = ko.observable("")
        //    .extend({ required: { params: true, message: 'Secret Answer is required.' } })
        //    .extend({ minLength: { params: 6, message: 'Secret Answer should be of minimum 6 characters length.' } })
        //    .extend({
        //        pattern: {
        //            params: /^(?=.*[a-zA-Z])([a-zA-Z0-9/]+)$/,
        //            message: 'Secret Answer must have at least 6 characters. Only Alphanumeric and Forward slash allowed.'
        //        }
        //    })

        //    self.questionList = ko.observableArray([
        //    { questionId: "1", questionName: "Favorite pet's name?" },
        //    { questionId: "2", questionName: "Favorite movie?" },
        //    { questionId: "3", questionName: "Anniversary [mm/dd/yy]?" },
        //    { questionId: "4", questionName: "Father's middle name?" },
        //    { questionId: "5", questionName: "Spouse's middle name?" },
        //    { questionId: "6", questionName: "First child's middle name?" },
        //    { questionId: "7", questionName: "High school name?" },
        //    { questionId: "8", questionName: "Favorite teacher's name?" },
        //    { questionId: "9", questionName: "Favorite sports team?" }])

        //    self.updatePassword = function (data) {
        //        if (data) {
        //            self.password(data.password);
        //            self.secretQuestion(data.secretQuestion);
        //            self.secretAnswer(data.secretAnswer);
        //        }
        //    };

        //    self.reset = function (resetquestion) {
        //        self.password("");
        //        if (resetquestion!==false)
        //            self.secretQuestion(undefined);
        //        self.secretAnswer("");
        //        ko.validation.group(self).showAllMessages(false);
        //    }

        //    self.errors = ko.validation.group(self);
        //    self.isValid = ko.computed(function () {
        //        return self.errors().length === 0;
        //    });
        //}

        var vm = {
            PasswordModel: PasswordModel,
            //QuestionModel: QuestionModel
        }
        return vm;
    });
