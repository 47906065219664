//TODO: Inject dependencies
define('viewmodels/GPSDialog',['plugins/router', 'knockout'],
    function (router,ko) {
        // Internal properties and functions
       
        // Reveal the bindable properties and functions
        var acknowledgeDialogTitle = ko.observable('');
        var acknowledgeDialogMessage = ko.observable('');
       


        var vm = {

            activate: activate,
            acknowledgeDialogTitle: acknowledgeDialogTitle,
            acknowledgeDialogMessage: acknowledgeDialogMessage,
            showDialog: function (Title, Message) {
                console.log('showDialog called.');

                console.log(Title);
                console.log(Message);

                acknowledgeDialogTitle(Title);
                acknowledgeDialogMessage(Message);

                $('#mdAcknowledgeDialog').modal('show');
            }
            

        }
        
        return vm;



        //function showDialog(Title, Message) {
        //    console.log('showDialog called.');

        //    console.log(Title);
        //    console.log(Message);
            
        //    acknowledgeDialogTitle(Title);
        //    acknowledgeDialogMessage(Message);

        //    $('#mdAcknowledgeDialog').modal('show');
        //}
        
        function activate (id, querystring) {
            //TODO: Initialize lookup data here.

            //TODO: Get the data for this viewmodel, return a promise.
            //acknowledgeDialogTitle('some Title');
            //acknowledgeDialogMessage('some Message');
        }

       

        
    });
