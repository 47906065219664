define('models/order/lender1',["knockout"],
    function (ko) {
        function lender1() {
            var self = this;
            self.firstName = ko.observable("")
            self.lastName = ko.observable("")
            self.company = ko.observable("")
            self.address = ko.observable("")
            self.zip = ko.observable("")
            self.city = ko.observable("")
            self.state = ko.observable("")
            self.phone = ko.observable("")
            self.phoneExt = ko.observable("")
            self.fax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }

            });
            self.faxExt = ko.observable("")
            self.mortgageBroker = ko.observable("")
            self.new = ko.observable("")
            self.Existing = ko.observable("")
            self.accountNumber = ko.observable("")
            self.loanAmount = ko.observable("")
            self.fullName = ko.computed(function () {
                var firstLast = self.firstName() + " " + self.lastName();
                return firstLast;
            });

            
        }

        function getAddressString(Address, Zip, City, State) {
            var strAddress = '';
            if (Address && Address.length > 0)
                strAddress = Address + ' ';

            if (City && City.length > 0)
                strAddress = strAddress + City + ', ';

            if (State && State.length > 0)
                strAddress = strAddress + State + ' ';

            if (Zip && Zip.length > 0)
                strAddress = strAddress + Zip + ' ';

            return strAddress;
        }

        var vm = {
            lender1: lender1
        }

        return vm;
    });
