define('viewmodels/orderModule/refiLenderEscrow',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'commonOrderModel', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'commonDialogModel', 'refiLenderEscrowModel', 'customerInformationModel', 'utlities'],
 function (app, router, $, ko, validate, iCheck, commonOrderModel, config, validation, Q, https, session, alert, commonDialog, model, CustomerInformation, utlities) {

     var customerInformationModel = new CustomerInformation();


     var subjectPropertyModel = new model.subjectProperty();
     var borrowerInformationModel = new model.borrowerInformation();
     var loanInformationModel = new model.loanInformation();
     var trustDeedOfRecordModel = new model.trustDeedOfRecord();
     var additionalInformationModel = new model.additionalInformation();




     var submitOrderModel = new commonOrderModel.SubmitOrder();

     var commonDialogModel = new commonDialog("orderSubmitDialog");
     var orderSubmitted = ko.observable(false);
     var orderTitle = ko.observable('Refi/Lender Escrow');

     var currentView = ko.observable('');
     var currentOrderModels = ko.observableArray([]);
     var currentViewIndex = ko.observable(-1);
     var currentOrderModel = ko.observable();
     var selectedOrderType = ko.observable(0);
     var selectedOrderTypeName = ko.observable("");

     var stateList = ko.observableArray();
     var propertyInfoPopulateFromSession = ko.observable(false);
     var recentSearches = ko.observable(undefined);
     var recentSearchSelectedAPN = ko.observable("");
     var propertyInfoPopulateFromRecentSearch = ko.observable(false);

     currentOrderModel.subscribe(function (model) {
         vm.validationErrors = ko.validation.group([model]).extend({
             notify: 'always'
         });
         currentView(model.viewName);
         selectedOrderTypeName(model.title);
         session.handleFocusOnTabChange(model.ModelId, model.viewName);
     });

     currentViewIndex.subscribe(function (index) {
         currentOrderModel(currentOrderModels()[index]);
     });
     subjectPropertyModel.zip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     if (data.StateAbbr != null || data.City != null) {
                         subjectPropertyModel.city(data.City);
                         subjectPropertyModel.state(data.StateAbbr);
                         if (propertyInfoPopulateFromSession() === true && session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null && session.orderForPropertyAddress.city() != undefined && session.orderForPropertyAddress.city() != null && session.orderForPropertyAddress.city() != "") {
                             subjectPropertyModel.city(session.orderForPropertyAddress.city());
                             propertyInfoPopulateFromSession(false);
                         }
                         else if (propertyInfoPopulateFromRecentSearch() === true) {
                             propertyInfoPopulateFromRecentSearch(false);
                         }
                         else {

                         }
                     }
                     else {
                         alert.error("Invalid Zip.");
                         subjectPropertyModel.zip('');
                         subjectPropertyModel.city('');
                         subjectPropertyModel.state('');
                     }
                 }
                 else
                     session.zipCodeServiceDown();
             })
         }
     })
     borrowerInformationModel.zip.subscribe(function (zip) {
         if (zip != undefined && zip != '') {
             utlities.getZipInfo(zip).done(function (data) {
                 if (data != undefined && data != null && data.HasErrors == false) {
                     if (data.StateAbbr != null || data.City != null) {
                         borrowerInformationModel.city(data.City)
                         borrowerInformationModel.state(data.StateAbbr)
                     }
                     else {
                         alert.error("Invalid Zip.")
                         borrowerInformationModel.zip('')
                         borrowerInformationModel.city('')
                         borrowerInformationModel.state('')
                     }
                 }
                 else
                     session.zipCodeServiceDown();
             })
         }
     })

     var vm = {
         activate: activate,
         canDeactivate: canDeactivate,
         compositionComplete: compositionComplete,
         goBack: goBack,
         title: 'Refi/Lender Escrow',

         orderTitle: orderTitle,

         currentView: currentView,
         currentOrderModels: currentOrderModels,
         currentViewIndex: currentViewIndex,
         currentOrderModel: currentOrderModel,
         selectedOrderTypeName: selectedOrderTypeName,

         moveNext: moveNext,
         movePrevious: movePrevious,
         submitOrder: submitOrder,
         navigateButtonClick: navigateButtonClick,
         commonDialogModel: commonDialogModel,

         customerInformationModel: customerInformationModel,
         subjectPropertyModel: subjectPropertyModel,
         borrowerInformationModel: borrowerInformationModel,
         loanInformationModel: loanInformationModel,
         trustDeedOfRecordModel: trustDeedOfRecordModel,
         additionalInformationModel: additionalInformationModel,
         submitOrderModel: submitOrderModel,
         getUSPhoneFormatWithExtn: utlities.getUSPhoneFormatWithExtn,
         getUSPhoneFormat: utlities.getUSPhoneFormat,
         stateList: stateList,
         session: session,
         recentSearches: recentSearches,
         recentResult_click: recentResult_click,
         recentSearchSelectedAPN: recentSearchSelectedAPN,
         clearSelectedProperty: clearSelectedProperty,
     }
     return vm;

     function activate(orderType) {
         orderSubmitted(false);
         recentSearches(session.getRecentSearchesFromLocalStorage());
         selectedOrderType(parseInt(orderType));
         setupOrder();
         currentViewIndex(0);
         if (session.userName() != undefined && session.userName() != '') {
             getCustomerInfo(session.userId, session.defaultOpertionId());
         }
         utlities.getStatesList().done(function (result) {
             stateList([]);
             stateList(result);

         });
         session.isBusy(false);
     }

     function canDeactivate() {
         if (!session.hasLoggedOut() && !orderSubmitted()) {
             var title = 'Cancel Order?';
             var msg = 'Navigate away and cancel the order? ';
             session.isLogoutVisible(false);
             return app.showMessage(msg, title, ['Yes', 'No '])
                 .then(function (selectedOption) {
                     if (selectedOption === 'Yes') {
                         // Call some cancel function
                         recentSearchSelectedAPN('');
                         session.clearOrderForPropertyAddress();
                         subjectPropertyModel.reset();
                     }
                     session.isLogoutVisible(true);
                     return selectedOption;
                 });
         }
         else {
             recentSearchSelectedAPN('');
             session.clearOrderForPropertyAddress();
             subjectPropertyModel.reset();
             return true;
         }
     };

     function recentResult_click(result) {
         recentSearchSelectedAPN(result.APN);
         propertyInfoPopulateFromSession(false);
         subjectPropertyModel.address(result.Address);
         subjectPropertyModel.city(result.City);

         if (subjectPropertyModel.zip() != result.ZIP.slice(0, 5)) {
             propertyInfoPopulateFromRecentSearch(true);
             subjectPropertyModel.zip(result.ZIP.slice(0, 5));
         }
         else
             propertyInfoPopulateFromRecentSearch(false);
     }
     function clearSelectedProperty() {
         recentSearchSelectedAPN('');
         subjectPropertyModel.reset();
     }

     function setupOrder() {
         currentOrderModels([]);
         currentOrderModels.push(customerInformationModel);
         currentOrderModels.push(subjectPropertyModel);
         currentOrderModels.push(borrowerInformationModel);
         currentOrderModels.push(loanInformationModel);
         currentOrderModels.push(trustDeedOfRecordModel);
         currentOrderModels.push(additionalInformationModel);
         currentOrderModels.push(submitOrderModel);
         resetModels();
     }

     function moveNext() {
         // commented for development, this need to be removed.
         vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
         if (vm.validationErrors().length > 0) {
             vm.validationErrors.showAllMessages();
             return;
         }
         currentViewIndex(currentViewIndex() + 1);

         if (currentViewIndex() == currentOrderModels().length - 1)
             buildPreviewModel();
     }

     function movePrevious() {
         currentViewIndex(currentViewIndex() - 1);
     }

     function navigateButtonClick(item, event) {
         if (item.index() > currentViewIndex()) {

             for (var i = 0; i < item.index() ; i++) {
                 // commented for development, this need to be removed.
                 vm.validationErrors = ko.validation.group(currentOrderModels()[i]);
                 if (vm.validationErrors().length > 0) {
                     vm.validationErrors.showAllMessages();
                     return;
                 }
             }
         }
         currentViewIndex(item.index());

         if (currentViewIndex() == currentOrderModels().length - 1)
             buildPreviewModel();
     }

     function goBack(complete) {
         router.navigateBack();
     }

     function compositionComplete() {
         
             session.handleFocusOnTabChange(customerInformationModel.ModelId, customerInformationModel.viewName);
         
         if (session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null) {
             propertyInfoPopulateFromSession(true);
             subjectPropertyModel.address(session.orderForPropertyAddress.address());
             subjectPropertyModel.zip(session.orderForPropertyAddress.zip())
         }
     }

     function resetModels() {
         for (var i = 0; i < currentOrderModels().length; i++) {
             currentOrderModels()[i].index(i);

             // skip order information view reset
             if (i > 0)
                 currentOrderModels()[i].reset();
         }
     }
     function getCustomerInfo(userId, opertionId) {
         utlities.getOperationUserInfo(userId, opertionId).done(function (data) {
             var currdate = new Date();
             var currdate = (currdate.getMonth() + 1) + '/' + currdate.getDate() + '/' + currdate.getFullYear();
             customerInformationModel.orderDate(currdate);
             utlities.getManagerFullName(data.OperationId, data.UserId).done(function (result) {
                 customerInformationModel.salesRep(result);
             })
             customerInformationModel.fullName(data.FirstName + String.fromCharCode(160) + data.LastName);
             customerInformationModel.email(data.Email);
             customerInformationModel.company(data.Company);
             customerInformationModel.address(data.StreetAddress1 + String.fromCharCode(160) + data.StreetAddress2 + String.fromCharCode(160) + data.City + ',' + String.fromCharCode(160) + data.State + String.fromCharCode(160) + data.Zip);
             customerInformationModel.phon(data.PhoneWork);
             customerInformationModel.ext(data.PhoneWorkExt);
             customerInformationModel.fax(data.PhoneFax);

         })

     }

     function buildPreviewModel() {
         for (var i = 0; i < currentOrderModels().length; i++) {
             if (currentOrderModels()[i].GetModelData) {
                 currentOrderModels()[i].GetModelData();
             }
         }
     }

     function submitOrder() {
         session.isBusy(true);
         var url = config.submitRefiLenderEscrowUrl;
         var request = {
             "BrandId": session.brandId,
             "OrderId": "-1",
             "UserId": session.userId,
             "ServiceId": "64",
             "OperationId": session.defaultOpertionId(),
             "CreateDate": new Date(),
             "PropertyAddress": subjectPropertyModel.address,
             "PropertyCity": subjectPropertyModel.city,
             "PropertyState": subjectPropertyModel.state,
             "PropertyZip": subjectPropertyModel.zip,

             //Order Model Information -- Fill If Present
             "BackendSystem": "",
             "EscrowOfficerId": "",
             "TitleOfficerId": "",
             "IsBackendOrder": "",
             "PaymentId": "",

             //Customer Information

             "CustomerSalesRep": customerInformationModel.salesRep,
             "CustomerUserFullName": customerInformationModel.fullName,
             "CustomerUserEmail": customerInformationModel.email,
             "CustomerUserCompany": customerInformationModel.company,
             "CustomerUserAddress": customerInformationModel.address,
             "CustomerUserPhone":
               {
                   "PhoneNumber": customerInformationModel.phon(),
                   "Extension": customerInformationModel.ext(),
                   "PhoneType": "Business"
               },
             "CustomerUserFax":
             {
                 "PhoneNumber": customerInformationModel.fax,
                 "Extension": "",
                 "PhoneType": "Fax"
             },

             //Loan Information
             "LenderName": loanInformationModel.lenderName,
             "EscrowOfficer": loanInformationModel.escrowOfficer,
             "ClosingDate": loanInformationModel.closingDate,
             "LoanType": loanInformationModel.loanType,
             "LoanAmount": loanInformationModel.loanAmount,
             "NewLoanNumber": loanInformationModel.newLoanNumber,

             "Borrower": borrowerInformationModel.borrower,
             "BorrowerSSN": borrowerInformationModel.last4SSN,
             "CoBorrower": borrowerInformationModel.coBorrower,
             "BorrowerHomePhone":
               {
                   "PhoneNumber": borrowerInformationModel.homePhone,
                   "Extension": "",
                   "PhoneType": "Home"
               },
             "BorrowerWorkPhone":
             {
                 "PhoneNumber": borrowerInformationModel.workPhone,
                 "Extension": borrowerInformationModel.extn,
                 "PhoneType": "Business"
             },
             "BorrowerMailingAddress": borrowerInformationModel.address,
             "BorrowerMailingCity": borrowerInformationModel.city,
             "BorrowerMailingState": borrowerInformationModel.state,
             "BorrowerMailingZip": borrowerInformationModel.zip,

             "TrustDeed1": trustDeedOfRecordModel.firstTrustDeedOfRecord,
             "Td1LoanNumber": trustDeedOfRecordModel.firstTrustLoanNumber,
             "Td1LoanAmount": trustDeedOfRecordModel.firstTrustloanBalance,
             "Td1Phone":
              {
                  "PhoneNumber": trustDeedOfRecordModel.firstTrustPhone,
                  "Extension": trustDeedOfRecordModel.firstTrustPhoneExtn,
                  "PhoneType": "Business"
              },

             "TrustDeed2": trustDeedOfRecordModel.secondTrustDeedofRecord,
             "Td2LoanAmount": trustDeedOfRecordModel.secondTrustLoanBalance,
             "Td2LoanNumber": trustDeedOfRecordModel.secondTrustLoanNumber,
             "Td2Phone": {
                 "PhoneNumber": trustDeedOfRecordModel.secondTrustPhone,
                 "Extension": trustDeedOfRecordModel.secondTrustPhoneExtn,
                 "PhoneType": "Business"
             },

             "PayOffOtherLiens": additionalInformationModel.payoffOtherLiens,
             "Subordination": additionalInformationModel.subordination,
             "Comments": additionalInformationModel.comments
         }

         https.post(url, request)
            .then(function (data) {
                if (data.HasErrors == false) {
                    orderSubmitted(true);
                    commonDialogModel.IsOrderDialog(true);
                    commonDialogModel.orderId(data.OrderId);
                    if (data.IntendedRecpients)
                        commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                    commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                }
                //else {
                //    for (var i = 0; i < data.Messages.length; i++) {
                //        if (data.Messages[i].ExceptionMessage != "") {
                //            console.log("Error:" + data.Messages[i].ExceptionMessage, orderTitle());
                //            alert.error('Internal Error. Please contact Administrator.');
                //            orderSubmitted(false);
                //            session.isBusy(false);
                //        }
                //    }
                //}
                else {
                    orderSubmitted(false);
                    session.isBusy(false);
                    var msgSuccess = "";
                    var msgError = "";

                    for (var i = 0; i < data.Messages.length; i++) {
                        if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                            //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                            //alert.error('Internal Error. Please contact Administrator.');
                            if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                msgError = msgError + data.Messages[i].Message + "\n";
                            }
                        }
                        else {
                            msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                        }
                    }
                    //alert.error(msgSuccess, orderTitle());
                    //alert.error(msgError, orderTitle());
                    if (msgSuccess.length > 0) {
                        orderSubmitted(true);
                        commonDialogModel.IsOrderDialog(true);
                        commonDialogModel.orderId(data.OrderId);
                        msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                        commonDialogModel.messagehtml(msgError);
                        if (data.IntendedRecpients)
                            commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                        commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                    }
                    else if (msgError.length > 0) {
                        alert.error(msgError, orderTitle());
                    }
                }
            })
         .fail(function (data) {
             var error = JSON.parse(data.responseText)
             console.log(error);
             session.isBusy(false);
         })
     }

 });
