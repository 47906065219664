define('models/order/orderReuester',["knockout"],
    function (ko) {
        function orderReuester() {
            var self = this;
            self.viewName = "orderModule/_orderRequester.html";
            self.title = "Order Requester";
            self.index = ko.observable(-1);

            self.firstName = ko.observable("")
            self.lastName = ko.observable("")
            self.company = ko.observable("")
            self.address = ko.observable("")
            self.zip = ko.observable("")
                .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.city = ko.observable("")
            self.state = ko.observable("")
            self.phone = ko.observable("")
            self.phoneExt = ko.observable("")
            self.fax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });
            self.faxExt = ko.observable("")
            self.email = ko.observable("")
            .extend({ email: { message: 'Invalid Confirm Email Address.' } })
                .extend({ maxLength: { params: 75, message: 'Invalid Confirm Email Address.' } })

              self.reset = function () {
              }

              self.isValid = function () {
                  return ko.validation.group(self).length == 0;
              }
        }

        var vm = {
            orderReuester: orderReuester
        }

        return vm;
    });
