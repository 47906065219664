define('models/order/viewOrder',["knockout"],
    function (ko) {

        //function Principal()
        //{
        //    self.Address = ko.observable('');
        //    self.NameEmail = ko.observable('');
        //    self.PartyType = ko.observable(0);
        //    self.Phone = ko.observable('');
        //    self.Type = ko.observable('');
        //}

        //function Office() {
        //    self.CostCenter = ko.observable('');
        //    self.OfficerFullName = ko.observable('');
        //    self.ProfitCenter = ko.observable('');
        //    self.RoutingNumber = ko.observable('');
        //    self.TypeName = ko.observable('');
        //    self.Type = ko.observable('');
        //}
        function TransactionParty()
        {
            var self = this;

            self.AccountNumber = ko.observable('');
            self.Address = ko.observable('');
            self.FinanceType = ko.observable('');
            self.LoanAmount = ko.observable('');
            self.LoanType = ko.observable('');
            self.NameEmail = ko.observable('');
            self.PartyType = ko.observable(0);
            self.Phone = ko.observable('');
            self.Type = ko.observable('');
            self.LenderInfo = ko.computed(function () {
                return self.Type() + ' Info';
            });
        }

        //function Broker() {
        //    var self = this;
        //    self.Address = ko.observable('');
        //    self.NameEmail = ko.observable('');
        //    self.PartyType = ko.observable(0);
        //    self.Phone = ko.observable('');
        //    self.Type = ko.observable('');
        //}



        function ViewOrder() {
            var self = this;

            self.Id = ko.observable(0);
            self.OrderNumber = ko.observable('');
            self.OrderStatusName = ko.observable('');
            self.OrderStatus = ko.observable(0);
            self.OrderTypeName = ko.observable('');
            self.CreateDate = ko.observable('');
            self.EstimatedCOEDate = ko.observable('');
            self.TransactionTypeCode = ko.observable('');
            self.TransactionAmount = ko.observable('');

            self.LimitedCoveragePolicy = ko.observable('');

            self.PropertyAddressHTML = ko.observable('');
            self.PropertyTypeName = ko.observable('');

            self.SubmittedByEmail = ko.observable('');
            self.SubmittedByFullName = ko.observable('');
            self.SubmittedBySalesRep = ko.observable('');
            self.SubmittedBySalesRepEmail = ko.observable('');
            self.SpecialInstructions = ko.observable('');
            
            self.Principals = ko.observableArray([]);
            self.Logs = ko.observableArray([]);
            self.Offices = ko.observableArray([]);
            self.Statuses = ko.observableArray([]);
            self.TransactionParties = ko.observableArray([]);
            //self.IsICloseOrder = ko.observable(false);
            self.IsSP360ManualOrder = ko.observable(false);
            //self.IsSP360Order = ko.observable(false);
            self.ProviderType = ko.observable('');

            self.HasErrors = ko.observable(false);
            self.HasValidationErrors = ko.observable(false);
            self.ErrorMessages = ko.observableArray([]);

            self.reset = function () {
                self.Id(0);
                self.OrderNumber('');
                self.OrderStatusName('');
                self.OrderStatus(0);
                self.OrderTypeName('');
                self.CreateDate('');
                self.EstimatedCOEDate('');
                self.TransactionTypeCode('');
                self.TransactionAmount('');

                self.LimitedCoveragePolicy('');

                self.PropertyAddressHTML('');
                self.PropertyTypeName('');

                self.SubmittedByEmail('');
                self.SubmittedByFullName('');
                self.SubmittedBySalesRep('');
                self.SubmittedBySalesRepEmail('');
                self.SpecialInstructions('');

                self.Principals([]);
                self.Logs([]);
                self.Offices([]);
                self.Statuses([]);
                self.TransactionParties([]);
                //self.IsICloseOrder(false);
                self.IsSP360ManualOrder(false);
                //self.IsSP360Order(false);
                self.ProviderType('');
            };

        }

        var vm = {
            ViewOrder: ViewOrder,
            TransactionParty: TransactionParty
        }

        return vm;
    }
    );
