define('models/common/modelMetaData',["knockout",'utlities'],
    function (ko, utils) {

        function propertyData() {
            var self = this;
            self.sectionType = "Field";
            self.label = ko.observable("");
            self.value = ko.observable(undefined);
            self.type ="text";
            self.id = "";
            self.isNewRow = false;

            // set type to collection
            self.list = ko.observableArray([]);
            self.bindingView = "";

            self.create = function (label, value, type,bindingView, id, isNewRow) {

                if(type && type =='collection')
                {
                    self.list(value);
                    self.isNewRow = true;
                    self.bindingView= bindingView;
                }
                else
                {
                    self.label(label);
                    self.value(value);
                }
                
                if(type)
                    self.type = type;

                if (id)
                self.id = id;

                if (isNewRow)
                self.isNewRow = isNewRow;

                return self;
            };
        }

        function instructionData()
        {
            var self = this;
            self.sectionType = "Instruction";
            self.head = ko.observable("");
            self.text = ko.observable("");

            self.create = function(head,text)
            {
                self.head(head);
                self.text(text);
                return self;
            }

        }

         function modelData() {
            var self = this;
            self.title = ko.observable("");
            self.properties = ko.observableArray([]);
        }


        var vm = {
            propertyData: propertyData,
            instructionData: instructionData,
            modelData: modelData,
            getUSPhoneFormatWithExtn: utils.getUSPhoneFormatWithExtn,
            reportBooleanValue: utils.reportBooleanValue,
            appendPhoneWithExtn: utils.appendPhoneWithExtn
        };
        return vm;
    });
