define('propertyProfileModel',["knockout"],
    function (ko) {
        function PropertyProfile() {
            var self = this;

            self.resultStatus = ko.observable(0);
            self.profileId = ko.observable("");
            self.property = ko.observable(undefined);
            self.availableSections = ko.observableArray([]);
            self.availableReportTypes = ko.observableArray([]);
            self.assignedCredits = ko.observable(0);
            self.usedCredits = ko.observable(0);
            self.enableReportPreparedFor = ko.observable(true);
        }

        function EmailPropertyProfileReport() {
            var self = this;

            self.selectedReport = ko.observable("");
            self.emailAddresses = ko.observable("").extend({ required: { message: 'Email Address is required.' } })
                .extend({
                    pattern: {
                        message: 'Invalid Email Address(es)',
                        params : /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/
                        //params: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*([;]\s*\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)*/
                    }
                });
            self.emailSubject = ko.observable("").extend({ required: { message: 'Email Subject is required.' } });
            self.emailMessage = ko.observable("").extend({
                pattern: {
                    message: "Email Message must be 300 characters or less. Only Alphanumeric and some special characters (.-'|*?¿+$#+!&@,:;/()[]) allowed.",
                    params: "^[\\w\\s\\.\\-\\'\\|\\*\\^\\%\\#\\?\\¿\\+\\$!\\&@,\\:\\;/\\(\\)\\[\\]]{0,300}$"
                }             
            });
            self.readReceipt = ko.observable(false);

            self.selectedReport = ko.observable("");
            self.selectedReportURL = ko.observable("");

            self.update = function (data) {
                if (data) {
                    self.propertyAselectedReportddress(data.selectedReport())
                    self.emailAddresses(data.emailAddresses())
                    self.emailSubject(data.emailSubject())
                    self.emailMessage(data.emailMessage())
                    self.readReceipt(data.readReceipt())
                }
            };

            self.reset = function () {
                self.selectedReport('')
                self.emailAddresses('')
                self.emailSubject('')
                self.emailMessage('')
                self.readReceipt(false)
                self.selectedReport("");
                self.selectedReportURL("");
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }
        }

        function ReportPreparedFor()
        {
            var self = this;

            self.pname = ko.observable("");
            self.pcompany = ko.observable("");
            self.paddress1 = ko.observable("");
            self.paddress2 = ko.observable("");
            self.pcsz = ko.observable("");

            self.reset = function () {
                self.pname('');
                self.pcompany('');
                self.paddress1('');
                self.paddress2('');
                self.pcsz('');
                ko.validation.group(self).showAllMessages(false);
            };
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            };
        }

        var vm = {
            PropertyProfile: PropertyProfile,
            ReportPreparedFor: ReportPreparedFor,
            EmailPropertyProfileReport: EmailPropertyProfileReport,
        };
        return vm;
    });
