define('viewmodels/userProfileModule/teamSettings',['durandal/app', 'knockout', 'teamModel', 'config', 'plugins/http', 'global/session', 'utlities', 'alertNotification'], function (app, ko, model, config, https, session, utils, alerter) {

    console.log('Team Settings - VM called...');

    var teamSettings = new model.TeamSettingsModel();
    var serverState;

    var isTeamAccount = ko.observable();
    var fullName = ko.observable("");
    var operationName = ko.observable("");
    var userType = ko.observable("");
    var photoSelected = null;
    var vm = {

        activate: activate,
        canDeactivate: canDeactivate,
        isTeamAccount: isTeamAccount,
        teamSettings: teamSettings,
        updateProfile: updateProfile,
        cancel: cancel,
        uploadPhoto: uploadPhoto,
        removePhoto: removePhoto,
        fullName: fullName,
        operationName: operationName,
        compositionComplete: compositionComplete,
        userType: userType

    };

    vm.validationErrors = ko.validation.group(vm.teamSettings);

    function compositionComplete() {
        session.validateInput();
    }


    function activate() {
        fullName("");
        operationName("");
        userType("");
        serverState = {};
        getTeamSettingsInfo();
        getUserProfileInformation();
        console.log('Team Settings - activated');
    }

    function getUserProfileInformation() {
        var url = config.getUserProfileInfoUrl;
        return https.get(url, { UserId: session.userId, OperationId: session.defaultOpertionId() })
            .done(function (result) {
                if (result) {
                    if (result.UserInformation !== null && result.UserInformation.SystemInfo !== null && result.UserInformation.SystemInfo.Operation !== null) {
                        vm.operationName(result.UserInformation.SystemInfo.Operation.Name);
                    }
                    if (result.UserInformation !== null) {
                        vm.fullName(result.UserInformation.LastName + ", " + result.UserInformation.FirstName);
                    }
                    if (result.UserInformation !== null && result.UserInformation.UserType !== null) {
                        vm.userType(result.UserInformation.UserType.Name);
                    }

                }
            }).always(function () {
                session.isAjaxPost(true);
            })
            .fail(function () {
                //alerter.error('Error fetching User Information', 'User Information');
            });
    }

    function canDeactivate() {
        vm.teamSettings.ResetModel();
        photoSelected = null;
        return true;
    }

    function uploadPhoto(obj, evnt) {
        if (evnt.currentTarget.files[0])
            photoSelected = evnt.currentTarget.files[0];
        console.log(photoSelected);
    }

    function removePhoto() {
        var url = config.removeTeamProfilePictureUrl;

        https.post(url, session.userId)
            .then(function (data) {
                vm.teamSettings.photo("");
                vm.teamSettings.photoUrl(data);
            })
            .fail(function (data) { alerter.error('Updating Photo Information Failed'); })
    }

    function updateProfile() {

        var request;
        var url = config.updateTeamSettingsInfoUrl;
        var formData = new FormData();

        if (!vm.isTeamAccount()) {
            formData.append("IsTeamAccount", false);
            formData.append("UserId", session.userId);
            vm.teamSettings.ResetModel();
            photoSelected = null;
            serverState.IsTeamAccount = false;
        }
        else {

            if (vm.validationErrors().length > 0) {
                vm.validationErrors.showAllMessages();
                return;
            }
            if (photoSelected) {
                if (!isValidImage()) {
                    document.getElementById("file").value = "";
                    photoSelected = null;
                    return;
                }
                formData.append("pic", photoSelected);
            }
            formData.append("IsTeamAccount", true);
            formData.append("UserId", session.userId);
            formData.append("FirstName", teamSettings.fName());
            formData.append("MiddleName", teamSettings.mName());
            formData.append("LastName", teamSettings.lName());
            formData.append("Email", teamSettings.email());
            formData.append("Department", teamSettings.department());
            formData.append("PhoneWork", utils.unmaskPhoneNumber(teamSettings.workPhone));
            formData.append("PhoneWorkExt", teamSettings.workPhoneExt());
            formData.append("PhoneFax", utils.unmaskPhoneNumber(teamSettings.faxPhone));
            formData.append("Birthday", teamSettings.birthday() ? teamSettings.birthday() : "");
            formData.append("ReceivesEmails", teamSettings.acceptEmail());
            formData.append("Photo", teamSettings.photo());
        }



        $.ajax({
            url: url,
            type: 'POST',
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function (req) {
                if (sessionStorage["accessToken"] || localStorage["accessToken"] != null)
                    req.setRequestHeader('Authorization', 'Bearer ' + (sessionStorage["accessToken"] || localStorage["accessToken"]));
            }
        }).then(function (result) {
            applyServerState(result);
            alerter.success("Team Profile Saved");
            photoSelected = null;
            document.getElementById("file").value = "";
        })
            .fail(function (data) {
                alerter.error('An error occurred while saving Team Profile');
                console.log(data);
                photoSelected = null;
                document.getElementById("file").value = "";
            });
    }

    function cancel() {
        if (serverState.IsTeamAccount)
            teamSettings.UpdateModel(serverState);
        else teamSettings.ResetModel();
        photoSelected = null;
        document.getElementById("file").value = "";
    }

    function isValidImage() {

        var imageType = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];

        if (-1 == $.inArray(photoSelected.type.split('/')[1], imageType)) {
            alerter.warning('Please enter valid photo file. Only JPG, GIF, PNG or BMP allowed.');
            return false;
        }
        if (photoSelected.size / 1024 > 4096) {
            alerter.warning('Selected Photo exceeds 4096 KB !');
            return false;
        }
        return true;
    }

    function getTeamSettingsInfo() {
        var url = config.getTeamSettingsInfoUrl;
        https.get(url)

            .done(function (result) {
                applyServerState(result);
            })
            .fail(function (data) {
                vm.isTeamAccount(false);
                alerter.error('Error occurred while fetching Team Profile');
                console.log(data);
            });
    }

    function applyServerState(result) {
        if (result) {
            if (result.IsTeamAccount) {
                vm.isTeamAccount(true);
                serverState = result;
                teamSettings.UpdateModel(serverState);
            }
            else {
                vm.isTeamAccount(false);
                teamSettings.photoUrl(result.PhotoUrl);
            }
        }
    }


    console.log('Team Settings - VM returned...');

    return vm;
});
