define('customPageModel',["knockout", 'plugins/http', 'global/session', 'config', 'utlities'],
    function (ko, https, session, config, utlities) {

        function CustomPageCulture()
        {
            var self = this;
            self.cultureId = ko.observable("");
            self.cultureName = ko.observable("");
            self.cultureShortName = ko.observable("");
            self.customPageId = ko.observable("");
            self.navLinkId = ko.observable("");
            self.pageAlias = ko.observable("");
            self.categoryDisplayType = ko.observable("");
            self.welcomeContent = ko.observable("");
            self.pageTitle = ko.observable("");
            self.name = ko.observable("");
            self.active = ko.observable("");
            self.isCustomPage = ko.observable("");
            self.categoryCount = ko.observable("");
            self.isAdmin = ko.observable("");
            self.customPageCategoryId= ko.observable("");
            self.description= ko.observable("");
            self.customPageLinkId= ko.observable("");
            self.embedded= ko.observable("");
            self.isDocument= ko.observable("");
            self.url = ko.observable("");
           
            self.reset = function () {
                self.cultureId("");
                self.cultureName("");
                self.cultureShortName("");
                self.customPageId("");
                self.navLinkId("");
                self.pageAlias("");
                self.categoryDisplayType("");
                self.welcomeContent("");
                self.pageTitle("");
                self.name("");
                self.active("");
                self.isCustomPage("");
                self.categoryCount("");
                self.isAdmin("");
                self.customPageCategoryId("");
                self.description("");
                self.customPageLinkId("");
                self.embedded("");
                self.isDocument("");
                self.url("");
            }
            self.clientObject = function (data) {
                if (data) {
                    self.cultureId(data.CultureId);
                    self.cultureName(data.CultureName);
                    self.cultureShortName(data.CultureShortName);
                    self.customPageId(data.CustomPageId);
                    self.navLinkId(data.NavLinkId);
                    self.pageAlias(data.PageAlias);
                    self.categoryDisplayType(data.CategoryDisplayType);
                    self.welcomeContent(data.WelcomeContent);
                    self.pageTitle(data.PageTitle);
                    self.name(data.Name);
                    self.active(data.Active);
                    self.isCustomPage(data.IsCustomPage);
                    self.categoryCount(data.CategoryCount);
                    self.isAdmin(data.IsAdmin);
                    self.customPageCategoryId(data.CustomPageCategoryId);
                    self.description(data.Description);
                    self.customPageLinkId(data.CustomPageLinkId);
                    self.embedded(data.Embedded);
                    self.isDocument(data.IsDocument);
                    self.url(data.Url);

                }
            }
        }
        function DetailList() {
            var self = this;
            self.category = ko.observableArray([]);
        }
        var vm = {
            CustomPageCulture: CustomPageCulture,
            DetailList: DetailList
        }

        return vm;
    });
