define('viewmodels/orderModule/coOEOrder',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'commonDialogModel', 'customerInformationModel', 'coOEOrderModel', 'utlities', 'commonOrderModel', 'koPaging', 'coloradoOECartModel', 'paymentProcessModel', 'ftniForm'],
    function (app, router, $, ko, validate, iCheck, config, validation, Q, https, session, alert, commonDialog, CustomerInformation, model, utlities,  commonOrderModel, koPaging, coloradoOECart, paymentProcessModel, ftniForm) {
        var customerInformationModel = new model.CustomerInformation();
        var customerSearchModel = new model.CustomerSearch();
        var orderTypeModel = new model.OrderType();
        var propertyInformationModel = new model.propertyInformation();

        var submitOrderModel = new model.SubmitOrder();

        var ftniFormModel = new ftniForm('ftniOAndEForm');

        var commonDialogModel = new commonDialog("orderSubmitDialog");
        var orderSubmitted = ko.observable(false);
        var isLoopbackEnabled = ko.observable(false);
        var orderTitle = ko.observable('');
        var serviceId = ko.observable(0);
        var currentView = ko.observable('');
        var currentOrderModels = ko.observableArray([]);
        var currentViewIndex = ko.observable(-1);
        var currentOrderModel = ko.observable();
        var selectedOrderType = ko.observable(0);
        var selectedOrderTypeName = ko.observable("");
        var stateList = ko.observableArray();
        var countyList = ko.observableArray();
        var coOEOrderCartItemModel = new coloradoOECart.coOEOrderCartItem();
        var coOEOrderCartModel = new coloradoOECart.coOEOrderCart();
        var coCheckoutModel = new coloradoOECart.coCheckout();

        var isOrderBehalf = ko.observable(false);
        var creditCardInformationModel = coCheckoutModel.creditCardModel;
        var requesterId = ko.observable("");
        var requesterName = ko.observable("");
        var selectedRequesterName = ko.observable("");
        var selectedRequesterId = ko.observable("");
        var selectedRequesterEmail = ko.observable("");
        var changingToSelf = ko.observable(false);

        var cartValue = ko.observable(-1);
        //var fundToAdd = ko.observable(0);
        //var walletBalance = ko.observable(0);
        //var grandTotal = ko.observable(0);
        //var finalWalletBalance = ko.observable(0);
        //var useCreditCard = ko.observable('new');
        //var userTokens = ko.observableArray([]);
        //var saveCard = ko.observable(false);


        var priceList = ko.observableArray();
        var cartValueString = ko.observable("");

        //var walletBalanceString = ko.observable(0);

        var fundToAddValue = ko.observable(0);

        //var grandTotalString = ko.observable("");

        var enableSaveCard = ko.observable(true);

        var savedCardExist = ko.observable(false);
        var selectedCardName = ko.observable('');
        var selectedCardId = ko.observable('');
        var paidThroughWallet = ko.observable(false);
        // var finalWalletBalanceString = ko.observable("");
        var propertyInfoPopulateFromSession = ko.observable(false);
        var recentSearches = ko.observable(undefined);
        var recentSearchSelectedAPN = ko.observable("");
        var propertyInfoPopulateFromRecentSearch = ko.observable(false);


        var finalWalletBalanceString = ko.computed(function () {
            //return '$' + parseFloat(coCheckoutModel.finalWalletBalance()).toFixed(2);
            return "";
        });


        var grandTotalString = ko.computed(function () {
            //  return '$' + parseFloat(coCheckoutModel.grandTotal()).toFixed(2);
            return "";
        });


        var walletBalanceString = ko.computed(function () {
            // return '$' + parseFloat(coCheckoutModel.walletBalance()).toFixed(2);
            return "";
        });

        var fundToAddValueString = ko.computed(function () {
            //return '$' + parseFloat(fundToAddValue()).toFixed(2);
            return "";
        });

        var userChangeMessage = '';

        var changeCustomerMessage = ko.computed(function () {
            userChangeMessage = '';

            userChangeMessage = 'Already ' + coOEOrderCartModel.CartItems().length + ' item(s) are in Cart for "' + requesterName() + '". Changing of customer will clear cart items. <br> Please Confirm.';

            return userChangeMessage;
        });

        var orderBy = ko.computed(function () {
            var orderedBy = '';

            if (isOrderBehalf())
                orderedBy = session.fullName();
            else
                orderedBy = 'Self';

            return orderedBy;
        });

        var selectedSavedCard = ko.computed(function () {
            //var strCardName = ''

            //var selectedCard = ko.utils.arrayFilter(coCheckoutModel.userTokens(), function (item) {
            //    if (item.Token == userCardToken()) {
            //        return item;
            //    }
            //});

            //if (selectedCard && selectedCard.length > 0)
            //    strCardName = selectedCard[0].Identifier;

            ////coCheckoutModel.userTokens
            ////userCardToken
            ////Token
            ////Identifier
            //return strCardName;
            return "";
        });

        var newCardNumber = ko.computed(function () {
            //var strCNumber = creditCardInformationModel.cardNumber();

            //strCNumber = strCNumber.substr(strCNumber.length - 4);
            //strCNumber = 'card ending with ' + strCNumber;
            //return strCNumber;
            return "";
        });

        var isOAndECartEnabled = ko.observable(false);

        var customerSearchListing = ko.observableArray([]).extend({
            paged: {
                pageSize: 15,
                pageGenerator: 'sliding'
            }
        });
        var customerInfoPanel = ko.observable(false);
        var searchPanel = ko.observable(false);
        var showMessage = ko.observable(false);
        var orderTypeList = ko.observableArray([]);
        var orderTypePriceList = ko.observableArray([]);
        var _deletingItemIndex = ko.observable(0);

        var assignedToList = ko.observableArray([]);

        coOEOrderCartModel.CartItems.subscribe(function (cartItem) {
            updateCartValue();
            updateGrandTotal();

        });

        requesterId.subscribe(function (newUserId) {
            if (newUserId.length > 0)
                GetOAndEOrderTypesByUser();
        });

        changingToSelf.subscribe(function (newValue) {
            showMessage(!newValue);
            isOrderBehalf(!newValue);
        });

        selectedRequesterId.subscribe(function (newUserId) {
            if (newUserId.length == 0 || requesterId() == newUserId)
                return;

            if (coOEOrderCartModel.CartItems().length > 0) {
                $('#mdChangeCustomer').modal('show');
            }
            else {
                requesterId(newUserId);
                changeRequesterConfirmed();
            }
        });

        coCheckoutModel.cartValue.subscribe(function (newVal) {
            cartValueString('$' + parseFloat(newVal).toFixed(2));
            updateGrandTotal();
        });

        currentOrderModel.subscribe(function (model) {
            vm.validationErrors = ko.validation.group([model]).extend({
                notify: 'always'
            });
            currentView(model.viewName);
            selectedOrderTypeName(model.title);
            session.handleFocusOnTabChange(model.ModelId, model.viewName);
        });

        currentViewIndex.subscribe(function (index) {
            currentOrderModel(currentOrderModels()[index]);
        });

        propertyInformationModel.state.subscribe(function (state) {
            if (state != undefined && state != '') {
                countyList([]);
                return utlities.getCountyList(state).done(function (result) {
                    countyList(result);
                });
            }
            else {
                countyList([]);
            }
        });

        coOEOrderCartItemModel.PropertyAddress.Zip.subscribe(function (zip) {
            if (coOEOrderCartItemModel.index() > -1 && coOEOrderCartItemModel.isLoading) {
                coOEOrderCartItemModel.isLoading = false;
                return;
            }
            if (zip != undefined && zip != '' && (zip.length == 5)) {
                countyList([]);
                coOEOrderCartItemModel.PropertyAddress.State('');
                coOEOrderCartItemModel.PropertyAddress.City('');

                return utlities.getZipInfo(zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if ((data.CountyName == "Adams") || (data.CountyName == "Arapahoe") || (data.CountyName == "Boulder") || (data.CountyName == "Broomfield")
                            || (data.CountyName == "Clear Creek") || (data.CountyName == "Denver") || (data.CountyName == "Douglas") || (data.CountyName == "Eagle")
                            || (data.CountyName == "El Paso") || (data.CountyName == "Elbert") || (data.CountyName == "Jefferson") || (data.CountyName == "Larimer")
                            || (data.CountyName == "Park") || (data.CountyName == "Pueblo") || (data.CountyName == "Summit") || (data.CountyName == "Teller")
                            || (data.CountyName == "Weld")) {
                        }
                        else {
                            alert.error("Chicago Title of Colorado O&E’s are only available through Adams, Arapahoe, Boulder, Broomfield, Clear Creek, Denver, Douglas, Eagle, El Paso, Elbert, Jefferson, Larimer, Park, Pueblo, Summit, Teller and Weld County.")
                            coOEOrderCartItemModel.PropertyAddress.Zip('');
                            //propertyInformationModel.city('');
                            //propertyInformationModel.state('');
                            coOEOrderCartItemModel.PropertyAddress.County('');
                            $("#zipCode").focus();
                            return false;
                        }
                        var selectedCounty = data.CountyName;
                        coOEOrderCartItemModel.PropertyAddress.City(data.City);
                        coOEOrderCartItemModel.PropertyAddress.State(data.StateAbbr);

                        if (propertyInfoPopulateFromSession() === true && session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null && session.orderForPropertyAddress.city() != undefined && session.orderForPropertyAddress.city() != null && session.orderForPropertyAddress.city() != "") {
                            coOEOrderCartItemModel.PropertyAddress.City(session.orderForPropertyAddress.city());
                            propertyInfoPopulateFromSession(false);
                        }
                        else if (propertyInfoPopulateFromRecentSearch() === true) {
                            propertyInfoPopulateFromRecentSearch(false);
                        }

                        if (selectedCounty != null) {
                            return utlities.getCountyList(data.StateAbbr).done(function (result) {
                                countyList(result);
                                coOEOrderCartItemModel.PropertyAddress.County(selectedCounty);

                            });
                        }
                        else {
                            alert.error("Invalid Zip.");
                            coOEOrderCartItemModel.PropertyAddress.Zip('');
                            coOEOrderCartItemModel.PropertyAddress.City('');
                            coOEOrderCartItemModel.PropertyAddress.State('');
                            coOEOrderCartItemModel.PropertyAddress.County('');
                        }

                    }
                    else
                        session.zipCodeServiceDown();
                });
            }

        });

        coOEOrderCartItemModel.OrderTypeId.subscribe(function (orderType) {
            selectedOrderType(orderType);
            console.log(selectedOrderType());
        });





        function changeRequesterConfirmed() {
            if (selectedRequesterId().length == 0)
                return;
            requesterName(selectedRequesterName());

            changingToSelf(session.userId == selectedRequesterId())
            requesterId(selectedRequesterId());

            getCustomerInfo(selectedRequesterId(), session.defaultOpertionId());
            customerInfoPanel(true);
            showMessage(true);
            isOrderBehalf(true);
            searchPanel(false);
            currentViewIndex(1);

            coOEOrderCartModel.CartItems([]);
            $('#mdChangeCustomer').modal('hide');

        }

        function NoChangeofRequester() {
            selectedRequesterId("");
            $('#mdChangeCustomer').modal('hide');
        }

        function updateCartValue() {
            var total = 0;

            for (var i = 0; i < coOEOrderCartModel.CartItems().length; i++) {
                total += coOEOrderCartModel.CartItems()[i].ChargedAmount();
            }
            coCheckoutModel.cartValue(total);
        }

        function updateGrandTotal() {
            //var gTotal = 0;
            //var remainingWallet = 0;

            //if (parseFloat(coCheckoutModel.cartValue()) > parseFloat(coCheckoutModel.walletBalance())) {
            //    gTotal = (parseFloat(coCheckoutModel.cartValue()) - parseFloat(coCheckoutModel.walletBalance()));
            //    remainingWallet = 0;
            //}
            //else {
            //    remainingWallet = parseFloat(coCheckoutModel.walletBalance()) - parseFloat(coCheckoutModel.cartValue());
            //    gTotal = 0;
            //}

            //coCheckoutModel.finalWalletBalance(remainingWallet + parseFloat(fundToAddValue()));

            //gTotal = gTotal + parseFloat(fundToAddValue());

            //if (gTotal > 0)
            //    coCheckoutModel.grandTotal(gTotal);
            //else
            //    coCheckoutModel.grandTotal(0.00);
        }

        function canActivate() {

            if (session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null && session.orderForPropertyAddress.state().length > 0) {

                if (session.orderForPropertyAddress.state().toLowerCase() == 'co') {
                    propertyInfoPopulateFromSession(true);
                    return true;
                }
                else {
                    propertyInfoPopulateFromSession(false);
                }
                var title = 'Create O&E Order?';
                var msg = 'Selected Property is outside Colorado State. Would you like to create an O&E order for a property in Colorado state?';
                session.isLogoutVisible(false);
                return app.showMessage(msg, title, ['Yes', 'No '])
                    .then(function (selectedOption) {
                        if (selectedOption === 'Yes') {
                            // Call some cancel function
                        }
                        return selectedOption;
                    });
            }
            else {
                return true;
            }
        }


        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            canActivate: canActivate,
            goBack: goBack,
            title: 'O&E Order',
            requesterId: requesterId,
            orderTitle: orderTitle,

            currentView: currentView,
            currentOrderModels: currentOrderModels,
            currentViewIndex: currentViewIndex,
            currentOrderModel: currentOrderModel,
            selectedOrderTypeName: selectedOrderTypeName,

            moveNext: moveNext,
            movePrevious: movePrevious,
            submitOrder: submitOrder,
            navigateButtonClick: navigateButtonClick,
            commonDialogModel: commonDialogModel,

            customerInformationModel: customerInformationModel,
            customerSearchModel: customerSearchModel,
            orderTypeModel: orderTypeModel,
            propertyInformationModel: propertyInformationModel,
            submitOrderModel: submitOrderModel,
            stateList: stateList,
            countyList: countyList,
            getUSPhoneFormat: utlities.getUSPhoneFormat,
            getUSPhoneFormat: utlities.getUSPhoneFormat,
            getUSPhoneFormatWithExtn: utlities.getUSPhoneFormatWithExtn,
            customerSearch: customerSearch,
            clearSearch: clearSearch,
            customerSearchListing: customerSearchListing,
            loadResultToKOArray: loadResultToKOArray,
            getSelectedValue: getSelectedValue,
            customerInfoPanel: customerInfoPanel,
            showMessage: showMessage,
            myself: myself,
            orderTypeList: orderTypeList,
            orderTypePriceList: orderTypePriceList,
            assignedToList: assignedToList,
            orderTypeChanged: orderTypeChanged,
            getCustomerTableStatusMessage: getCustomerTableStatusMessage,

            isOAndECartEnabled: isOAndECartEnabled,
            coOEOrderCartItemModel: coOEOrderCartItemModel,
            coOEOrderCartModel: coOEOrderCartModel,
            coCheckoutModel: coCheckoutModel,
            addCartItem: addCartItem,
            checkout: checkout,
            cartValue: coCheckoutModel.cartValue,
            deleteCartItem: deleteCartItem,
            cartValueString: cartValueString,
            editCartItem: editCartItem,
            directSubmit: directSubmit,
            addOrder: addOrder,
            updateCartItem: updateCartItem,
            deleteCartItemConfirmed: deleteCartItemConfirmed,
            creditCardInformationModel: creditCardInformationModel,
            //walletBalance: coCheckoutModel.walletBalance,
            //fundToAdd: coCheckoutModel.fundToAdd,
            walletBalanceString: walletBalanceString,
            // grandTotal: coCheckoutModel.grandTotal,
            grandTotalString: grandTotalString,
            // userTokens: coCheckoutModel.userTokens,
            removeCard: removeCard,
            // finalWalletBalance: coCheckoutModel.finalWalletBalance,
            finalWalletBalanceString: finalWalletBalanceString,
            // useCreditCard: coCheckoutModel.useCreditCard,
            enableSaveCard: enableSaveCard,
            //   saveCard: coCheckoutModel.saveCard,
            savedCardExist: savedCardExist,
            selectedCardName: selectedCardName,
            deleteCardConfirmed: deleteCardConfirmed,
            fundToAddValueString: fundToAddValueString,
            fundToAddValue: fundToAddValue,
            selectedSavedCard: selectedSavedCard,
            newCardNumber: newCardNumber,
            isOrderBehalf: isOrderBehalf,
            orderBy: orderBy,
            session: session,
            orderMyselfAndMoveNext: orderMyselfAndMoveNext,
            changeCustomerMessage: changeCustomerMessage,
            changeRequesterConfirmed: changeRequesterConfirmed,
            NoChangeofRequester: NoChangeofRequester,
            serviceId: serviceId,
            recentSearches: recentSearches,
            recentResult_click: recentResult_click,
            recentSearchSelectedAPN: recentSearchSelectedAPN,
            clearSelectedProperty: clearSelectedProperty,
            isLoopbackEnabled: isLoopbackEnabled,
            ftniFormModel: ftniFormModel,
            compositionComplete: function () {
                $('#mdAddOrder').on('hidden.bs.modal', function (e) {
                    session.handleFocusOnTabChange(coOEOrderCartModel.ModelId, coOEOrderCartModel.viewName);
                })

                $('#mdDeleteItem').on('hidden.bs.modal', function (e) {
                    session.handleFocusOnTabChange("", "");
                })



                $('#mdAddOrder').on('shown.bs.modal', function (e) {
                    setTimeout(function () {
                        $('#contactType').focus();
                    }, 200);
                })


                if (!session.userIsInRole('Customer')) {
                    session.handleFocusOnTabChange(customerSearchModel.ModelId, customerSearchModel.viewName);
                }
                else {
                    session.handleFocusOnTabChange(customerInformationModel.ModelId, customerInformationModel.viewName);
                }

                if (session.savedCart() != undefined) {
                    var savedCart = session.savedCart();
                    var newItem = null;
                    for (i = 0; i < savedCart.CartItems.length; i++) {
                         var cartItem = savedCart.CartItems[i];
                        cartItem.index = i;
                        newItem = new coloradoOECart.coOEOrderCartItem();
                        newItem.updateJSObject(cartItem);
                        coOEOrderCartModel.CartItems.push(newItem);
                    };
                    currentViewIndex(currentOrderModels().length - 1);
                    session.savedCart(undefined);
                }
            }
        }
        return vm;

        function removeCard(item, event) {
            //getDeleteCCTokenUrl
            selectedCardId('');
            $('#mdDeleteCard').modal('show');
            selectedCardName(item.Identifier);
            selectedCardId(item.CardId);
            // console.log(item);
        }

        function deleteCardConfirmed() {
            //if (selectedCardId().length > 0) {
            //    //console.log('deleting ' +  selectedCardId());
            //    https.get(config.getDeleteCCTokenUrl, { "CardId": selectedCardId(), "CustomerID": requesterId() }).done(function (result) {

            //        if (result.length > 0) {
            //            coCheckoutModel.userTokens([]);
            //            coCheckoutModel.userTokens(result);

            //            savedCardExist(true);
            //            coCheckoutModel.useCreditCard('existing');

            //            if (coCheckoutModel.userTokens().length > 0)
            //                userCardToken(result[0].Token);
            //        }
            //        else {
            //            savedCardExist(false);
            //            coCheckoutModel.useCreditCard('new');
            //        }
            //    });

            //}

            //$('#mdDeleteCard').modal('hide');
        }

        function editCartItem(item, event) {
            coOEOrderCartItemModel.isLoading = true;
            coOEOrderCartItemModel.update(coOEOrderCartModel.CartItems()[item.index()]);

            $('#mdAddOrder').modal('show');
        }

        function updateCartItem() {
            if (vm.objectErrors != undefined && vm.objectErrors().length > 0) {
                vm.objectErrors.showAllMessages();
                return;
            }

            var editObject = new coloradoOECart.coOEOrderCartItem();

            editObject.update(coOEOrderCartItemModel);

            var selectedOrder = ko.utils.arrayFilter(orderTypePriceList(), function (item) {
                if (item.Id == coOEOrderCartItemModel.OrderTypeId()) {
                    return item;
                }
            });

            editObject.Price(selectedOrder[0].OperationPrice);
            editObject.OrderType(selectedOrder[0].Name);

            getIsOAndECartEnabled(selectedOrderType(), session.defaultOpertionId(), requesterId()).done(function (result) {

                editObject.IsChargable(result);

                if (editObject.IsChargable()) {
                    editObject.ChargedAmount(editObject.Price());
                }
                else {
                    editObject.ChargedAmount(0);
                }
                coOEOrderCartModel.CartItems.splice(coOEOrderCartItemModel.index(), 1, editObject);
                coOEOrderCartItemModel.reset();
                updateCartValue();
            });

            $('#mdAddOrder').modal('hide');
        }

        function checkout() {

        }

        function addOrder() {
            coOEOrderCartItemModel.reset();
            $('#mdAddOrder').modal('show');
            vm.objectErrors = ko.validation.group([coOEOrderCartItemModel]);
            vm.objectErrors.showAllMessages(false);

            if (propertyInfoPopulateFromSession()) {
                coOEOrderCartItemModel.PropertyAddress.Address1(session.orderForPropertyAddress.address());
                coOEOrderCartItemModel.PropertyAddress.Zip(session.orderForPropertyAddress.zip());
            }

        }

        function closeAddEditDialog() {

        }

        // handle submit without credit card.
        function directSubmit() {

        }

        function activate(orderType) {

            isOrderBehalf(false);
            requesterId("");
            requesterName("");
            selectedRequesterName("");
            selectedRequesterId("");
            selectedRequesterEmail("");
            changingToSelf(false);
            orderSubmitted(false);
            selectedOrderType(parseInt(orderType));
            serviceId(parseInt(orderType));
            setupOrder();
            currentViewIndex(0);

            var recentSearchList = $(session.getRecentSearchesFromLocalStorage()).filter(function (i, n) { return n.State.toLowerCase() == 'co' });
            console.log(recentSearchList);
            recentSearches(recentSearchList);

            //coCheckoutModel.grandTotal(0.00);
            //coCheckoutModel.cartValue(0.00);
            //coCheckoutModel.fundToAdd(0.00);
            //coCheckoutModel.useCreditCard('new');
            //coCheckoutModel.saveCard(false);

            coCheckoutModel.reset();
            customerSearchModel.reset();

            requesterId(session.userId);
            requesterName(session.fullName());
            fundToAddValue(0.00);
            //  getWalletBalance();

            enableSaveCard(true);
            //getUserTokens();
            currentView(currentOrderModels()[0].viewName);

            savedCardExist(false);
            coOEOrderCartModel.CartItems([]);

            if (session.userName() != undefined && session.userName() != '') {
                getCustomerInfo(session.userId, session.defaultOpertionId());
            }

            utlities.getStatesList().done(function (result) {
                stateList([]);
                countyList([]);
                stateList(result[5]);

            });

            //not required here, its called when requesterId is set above.
            //GetOAndEOrderTypesByUser();

            if (session.defaultOpertionId() != undefined && session.defaultOpertionId() != '') {
                assignedToList([]);
                getOAndEOrderManagersUrl(session.brandId, session.defaultOpertionId(), session.userId).done(function (result) {
                    assignedToList(result);
                    assignedToList.splice(0, 0, { UserId: "NA", Fullname: "All Employees" });
                    console.log(result.length);
                    console.log(session.userId);

                    setTimeout(function () {
                        customerSearchModel.assignedTo(session.userId.toString());
                    }, 10);
                })

                isOAndECartEnabled(false);

                getIsOAndECartEnabled(selectedOrderType(), session.defaultOpertionId(), requesterId).done(function (result) {
                    isOAndECartEnabled(result);
                    console.log('is cart enabled ' + isOAndECartEnabled());
                });

            }
            orderTypeModel.orderType(selectedOrderType());
            customerInfoPanel(false);
            searchPanel(false);
            showMessage(false);
            isOrderBehalf(false);
            session.isBusy(false);


        }

        function GetOAndEOrderTypesByUser() {
            if (session.defaultOpertionId() != undefined && session.defaultOpertionId() != '') {
                orderTypeList([]);
                getOAndEOrderTypes(session.brandId, session.defaultOpertionId(), requesterId, true, 1).done(function (result) {
                    orderTypeList(result);
                });
                orderTypePriceList([]);
                getOAndEOrderTypesPrice(requesterId, true, session.defaultOpertionId(), 1).done(function (result) {
                    orderTypePriceList(result);
                    console.log(orderTypePriceList());
                })
            }
        }


        function canDeactivate() {
            if (!session.hasLoggedOut() && !orderSubmitted()) {
                window.history.pushState(null, "", window.location.href);
                window.onpopstate = function () {
                    window.history.pushState(null, "", window.location.href);
                    return false;
                };
                var title = 'Cancel Order?';
                var msg = 'Navigate away and cancel the order? ';
                session.isLogoutVisible(false);
                return app.showMessage(msg, title, ['Yes', 'No '])
                    .then(function (selectedOption) {
                        if (selectedOption === 'Yes') {
                            // Call some cancel function
                            recentSearchSelectedAPN('');
                            session.clearOrderForPropertyAddress();
                            coOEOrderCartItemModel.PropertyAddress.reset();
                        }
                        session.isLogoutVisible(true);
                        return selectedOption === 'Yes';
                    });
            }
            else {
                recentSearchSelectedAPN('');
                session.clearOrderForPropertyAddress();
                coOEOrderCartItemModel.PropertyAddress.reset();
                return true;
            }
        };

        function recentResult_click(result) {
            recentSearchSelectedAPN(result.APN);
            propertyInfoPopulateFromSession(false);
            coOEOrderCartItemModel.PropertyAddress.Address1(result.Address);
            coOEOrderCartItemModel.PropertyAddress.City(result.City);

            if (coOEOrderCartItemModel.PropertyAddress.Zip() != result.ZIP.slice(0, 5)) {
                propertyInfoPopulateFromRecentSearch(true);
                coOEOrderCartItemModel.PropertyAddress.Zip(result.ZIP.slice(0, 5));
            }
            else
                propertyInfoPopulateFromRecentSearch(false);
        }
        function clearSelectedProperty() {
            recentSearchSelectedAPN('');
            coOEOrderCartItemModel.PropertyAddress.resetPropertyAddress();
        }

        function setupOrder() {
            currentOrderModels([]);
            customerSearchListing([]);

            if (!session.userIsInRole('Customer')) {
                currentOrderModels.push(customerSearchModel);
            }
            currentOrderModels.push(customerInformationModel);
            currentOrderModels.push(coOEOrderCartModel);
            currentOrderModels.push(coCheckoutModel);
            //currentOrderModels.push(orderTypeModel);
            //currentOrderModels.push(propertyInformationModel);
            currentOrderModels.push(submitOrderModel);



            switch (selectedOrderType()) {
                case 73:
                    orderTitle('Vesting Deed (No Charge)');
                    resetModels();
                    break;
                case 74:
                    orderTitle("O&E With Plat and CC&R's Per Parcel");
                    resetModels();
                    break;
                case 78:
                    orderTitle("O&E Without Plat and CC&R's Per Parcel");
                    resetModels();
                    break;
                case 79:
                    orderTitle("O&E With CC&R's Per Parcel");
                    resetModels();
                    break;
                case 80:
                    orderTitle(' O&E With Plat');
                    resetModels();
                    break;
                default:

            }

        }

        function moveNext() {

            if (currentOrderModel().isCustomValid()) {
                currentViewIndex(currentViewIndex() + 1);
            }

        }

        function movePrevious() {
            currentViewIndex(currentViewIndex() - 1);
        }

        function navigateButtonClick(item, event) {
            if (item.index() > currentViewIndex()) {

                for (var i = 0; i < item.index(); i++) {

                    if (!currentOrderModels()[i].isCustomValid()) {
                        return;
                    }

                    //  commented for development, this need to be removed.
                    //vm.validationErrors = ko.validation.group(currentOrderModels()[i]);
                    //if (vm.validationErrors().length > 0) {
                    //    vm.validationErrors.showAllMessages();
                    //    return;
                    //}
                    //else {
                    //    if (!currentOrderModels()[i].isCustomValid()) {
                    //        return;
                    //    }
                    //}
                }
            }
            currentViewIndex(item.index());
        }

        function goBack(complete) {
            router.navigateBack();
        }

        function resetModels() {
            for (var i = 0; i < currentOrderModels().length; i++) {
                currentOrderModels()[i].index(i);

                // skip order information view reset
                if (i > 0)
                    currentOrderModels()[i].reset();
            }
        }

        function getCustomerInfo(userId, opertionId) {
            return utlities.getOperationUserInfo(userId, opertionId).done(function (data) {
                var currdate = new Date();
                var currdate = (currdate.getMonth() + 1) + '/' + currdate.getDate() + '/' + currdate.getFullYear();
                customerInformationModel.orderDate(currdate);
                utlities.getManagerFullName(data.OperationId, data.UserId).done(function (result) {
                    customerInformationModel.salesRep(result);
                })
                customerInformationModel.fullName(data.FirstName + String.fromCharCode(160) + data.LastName);
                customerInformationModel.email(data.Email);
                customerInformationModel.company(data.Company);
                customerInformationModel.address(data.StreetAddress1 + String.fromCharCode(160) + data.StreetAddress2 + String.fromCharCode(160) + data.City + ',' + String.fromCharCode(160) + data.State + String.fromCharCode(160) + data.Zip);
                customerInformationModel.phon(data.PhoneWork);
                customerInformationModel.ext(data.PhoneWorkExt);
                customerInformationModel.fax(data.PhoneFax);

            })

        }
        function getSelectedValue(selectedItem, event) {

            selectedRequesterName(selectedItem.name());
            selectedRequesterEmail(selectedItem.email());
            selectedRequesterId(selectedItem.userId());
            //alert.info(selectedItem.email());
            //alert.info(selectedItem.operationId());
        }
        function getWalletBalance() {
            // coCheckoutModel.walletBalance(0);
            //https.get(config.getWalletBalanceUrl).done(function (result) {
            //    coCheckoutModel.walletBalance(result);
            //});
        }

        function getUserTokens() {
            //coCheckoutModel.userTokens([]);
            //https.get(config.getUserTokensUrl).done(function (result) {
            //    coCheckoutModel.userTokens(result);
            //    savedCardExist(coCheckoutModel.userTokens().length > 0);


            //    if (coCheckoutModel.userTokens().length > 0) {
            //        userCardToken(result[0].Token);
            //        coCheckoutModel.useCreditCard('existing');
            //    }
            //    else {
            //        coCheckoutModel.useCreditCard('new');
            //    }


            //});
        }
        function customerSearch() {
            customerInfoPanel(false);
            searchPanel(true);
            var url = config.getOECustomersUrl;
            var isSearchAll = false;
            if (customerSearchModel.assignedTo() == "NA") {
                isSearchAll = true;
            }
            var searchParams = {

                "FirstName": customerSearchModel.firstName,
                "LastName": customerSearchModel.lastName,
                "Email": customerSearchModel.email,
                "ManagerUid": "",
                "SearchByAllManager": isSearchAll,
                "OperationId": session.defaultOpertionId(),
                "ParentUserId": customerSearchModel.assignedTo(),
                "BrandId": session.brandId

            }

            https.post(url, searchParams)
                .then(function (data) {
                    if (data.length == 0) {
                        customerSearchListing([]);
                        alert.info("No users found based on the selected criteria. Please refine your search and try again.");
                    }
                    else {
                        customerSearchListing([]);
                        var converted = loadResultToKOArray(data);
                        customerSearchListing(converted);
                        customerSearchListing.toFirstPage();
                        //customerSearchListing(data);
                        //alert.info(customerSearchListing()[0].Fullname);
                        //customerSearchListing().forEach(function (v, i) {
                        //    alert.info(v.Fullname);
                        //});
                    }
                })
                .fail(Failed);

        }
        function clearSearch() {
            customerSearchModel.reset();
            customerSearchListing([]);
        }
        function loadResultToKOArray(data) {
            var results = [];

            for (var i = 0; i < data.length; i++) {
                var item = new model.SearchResult();

                item.email(data[i].Email)
                item.operationId(data[i].OperationId)
                item.name(data[i].Fullname);
                item.company(data[i].Company);
                item.assignedTo(data[i].ParentFullName);
                item.userId(data[i].UserId);
                results.push(item);
            }
            return results;
        }
        function submitOrder() {
            session.isBusy(true);
            url = config.saveColoradoOAndEOrderUrl;
            var request = {
                "RequesterId": requesterId(),
                "BrandId": session.brandId,
                "OperationId": session.defaultOpertionId(),
                "SalesRep": customerInformationModel.salesRep,
                "OrderedBy": customerInformationModel.fullName,
                "CartItems": coOEOrderCartModel.CartItems,
                "ServiceId": serviceId(),
                "TotalCartValue": parseFloat(coCheckoutModel.cartValue())
            };

            console.log(request);

            https.post(url, request)
                .then(function (data) {
                    if (data.HasErrors == false) {
                        console.log(data);
                        orderSubmitted(true);
                        var amountToCharge = parseFloat(coCheckoutModel.cartValue());
                        handleAfterSaveRequest(data, amountToCharge);
                    }
                    else {
                        var exception = ko.utils.arrayFilter(data.Messages, function (item) {
                            if (item.Level == 500) {
                                return item;
                            }
                        });
                        console.log(exception[0].Message);
                        alert.error(exception[0].Message);
                        session.isBusy(false);
                    }
                })
                .fail(Failed);
        }

        function handleAfterSaveRequest(response, amountToCharge) {
            if (amountToCharge > 0 && response.SessionId != "" && response.IsAuto == false) {
                $('#paymentForm').prop("action", response.PaymentPostUrl);
                $('#pytPageTitle').val("O&E Order Payment");
                $('#pytPageHeader').val("O&E Order Payment");
                $('#pytOrderNumber').val(response.OrderGuid);
                $('#pytPassthroughData').val(response.OrderGuid);
                $('#pytPaymentFormHeaderTextColor').val($('.btn-primary').css('color'));
                $('#pytPaymentFormHeaderBackgroundColor').val($('.btn-primary').css('background-color'));
                $('#pytLogoURL').val($('#dvBaseColor').css('background-image').split('"')[1]);
                $('#pytSessionID').val(response.SessionId);
                $('#pytCancelURL').val(response.CancelURL);
                $('#pytSettlement').val(response.Settlement);
                $('#pytAmount').val(amountToCharge.toFixed(2));

                $('#pytCallBack').val(response.CallbackURL);
                $('#paymentForm').submit();

                return;
            } else {
                window.location = response.CallbackURL;
            }
        }


        function Failed(data) {

            var error = JSON.parse(data.responseText)
            console.log(error);
            session.isBusy(false);

        }
        function myself() {
            selectedRequesterEmail(session.userName());
            selectedRequesterName(session.fullName());
            selectedRequesterId(session.userId)
        }

        function orderMyselfAndMoveNext() {

            if (customerInformationModel.email() != session.userName()) {
                myself();
            }
            moveNext();
        }

        function getOAndEOrderTypes(brandId, operationId, userId, isAdmin, cultureId) {

            var url = config.getOAndEOrderTypesUrl;
            return https.get(url, {
                BrandId: brandId,
                OperationId: operationId,
                UserId: userId,
                IsAdmin: isAdmin,
                CultureId: cultureId,
                format: 'json'
            })
        }

        function getOAndEOrderTypesPrice(userId, isAdmin, operationId, cultureId) {

            var url = config.getOAndEOrderTypesPriceUrl;
            return https.get(url, {
                UserId: userId,
                IsAdmin: isAdmin,
                OperationId: operationId,
                CurrentCultureId: cultureId,
                format: 'json'
            })
        }

        function addCartItem() {

            vm.validationErrors = ko.validation.group(coOEOrderCartItemModel);
                if (vm.validationErrors().length > 0) {
                    vm.validationErrors.showAllMessages();
                    return;
            }

            var newItem = new coloradoOECart.coOEOrderCartItem();



            var selectedOrder = ko.utils.arrayFilter(orderTypePriceList(), function (item) {
                if (item.Id == coOEOrderCartItemModel.OrderTypeId()) {
                    return item;
                }
            });

            coOEOrderCartItemModel.Price(selectedOrder[0].OperationPrice);
            coOEOrderCartItemModel.OrderType(selectedOrder[0].Name);

            coOEOrderCartItemModel.index(coOEOrderCartModel.CartItems().length);

            getIsOAndECartEnabled(selectedOrderType(), session.defaultOpertionId(), requesterId()).done(function (result) {

                coOEOrderCartItemModel.IsChargable(result);

                if (coOEOrderCartItemModel.IsChargable()) {
                    coOEOrderCartItemModel.ChargedAmount(coOEOrderCartItemModel.Price());
                }
                else {
                    coOEOrderCartItemModel.ChargedAmount(0);
                }

                newItem.update(coOEOrderCartItemModel);

                coOEOrderCartModel.CartItems.push(newItem);

                ko.validation.group(coOEOrderCartItemModel).showAllMessages(false);
                coOEOrderCartItemModel.reset();
                //vm.validationErrors([]);
                //vm.validationErrors.showAllMessages(false);            
            });
            $('#mdAddOrder').modal('hide');

        }



        function getOAndEOrderManagersUrl(brandId, operationId, userId) {

            var url = config.getOAndEOrderManagersUrl;
            return https.get(url, {
                BrandId: brandId,
                OperationId: operationId,
                UserId: userId,
                format: 'json'
            })
        }

        function deleteCartItem(item, event) {
            _deletingItemIndex(item.index())
            $('#mdDeleteItem').modal('show');
        }


        function deleteCartItemConfirmed() {
            coOEOrderCartModel.CartItems.splice(_deletingItemIndex(), 1);
            for (var i = 0; i < coOEOrderCartModel.CartItems().length; i++) {
                coOEOrderCartModel.CartItems()[i].index(i);
            }

            if (coOEOrderCartModel.CartItems().length == 0 && currentOrderModel().ModelId == "COOEOrdercheckout") {
                currentViewIndex(currentViewIndex() - 1);
            }

            $("#mdDeleteItem").modal('hide');
        }


        function getIsOAndECartEnabled(serviceId, operationId, userId) {
            var url = config.getIsOAndECartEnabledUrl;
            return https.get(url, {
                ServiceId: serviceId,
                OperationId: operationId,
                UserId: userId,
                format: 'json'
            })
        }

        function orderTypeChanged() {
            customerSearchListing([]);
            switch (orderTypeModel.orderType()) {
                //ONLINE TITLE AND ESCROW ORDER
                case "73":
                    orderTitle('Vesting Deed (No Charge)');
                    customerInformationModel.reset();
                    customerSearchModel.reset();
                    propertyInformationModel.reset();
                    break;
                //Online Escrow Order
                case "74":
                    customerInformationModel.reset();
                    customerSearchModel.reset();
                    propertyInformationModel.reset();
                    orderTitle('O&E With Plat and CC&Rs Per Parcel');

                    break;
                //ONLINE TITLE ORDER
                case "78":
                    customerInformationModel.reset();
                    customerSearchModel.reset();
                    propertyInformationModel.reset();
                    orderTitle('O&E Without Plat and CC&Rs Per Parcel');
                    break;
                case "79":
                    customerInformationModel.reset();
                    customerSearchModel.reset();
                    propertyInformationModel.reset();
                    orderTitle(' O&E With CC&Rs Per Parcel');
                    break;
                case "80":
                    customerInformationModel.reset();
                    customerSearchModel.reset();
                    propertyInformationModel.reset();
                    orderTitle(' O&E With Plat');
                    break;
                default:

            }
            if (session.userName() != undefined && session.userName() != '') {
                getCustomerInfo(session.userId, session.defaultOpertionId());
            }
            utlities.getStatesList().done(function (result) {
                stateList([]);
                countyList([]);
                stateList(result[5]);

            });

            if (session.defaultOpertionId() != undefined && session.defaultOpertionId() != '') {
                assignedToList([]);
                getOAndEOrderManagersUrl(session.brandId, session.defaultOpertionId(), session.userId).done(function (result) {
                    assignedToList(result);
                    assignedToList.splice(0, 0, { UserId: "NA", Fullname: "All Employees" });
                })

            }
            customerInfoPanel(false);
            searchPanel(false);
            showMessage(false);
            isOrderBehalf(false);
        }

        function getCustomerTableStatusMessage() {
            return "Users: " + customerSearchListing.firstItemOnPage() + " - " + customerSearchListing.lastItemOnPage() + " of " + customerSearchListing().length + " total users";
        }

    });
