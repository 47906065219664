define('viewmodels/userProfileModule/freqContacts',['durandal/app', 'plugins/router', 'knockout', 'contactsModel', 'config', 'global/session', 'plugins/http', 'alertNotification', 'utlities', 'jquery'],
    function (app, router, ko, model, config, session, https, alerter, utlities, jquery) {

        console.log('Frequent Contacts VM - called');

        var contactsModel = new model.ContactsModel();
        var fullName = ko.observable("");
        var operationName = ko.observable("");
        var userType = ko.observable("");
        var stateList = ko.observableArray();
        var editMode = ko.observable(false);

        var vm = {
            activate: activate,
            contactsModel: contactsModel,
            deleteContact: deleteContact,
            fullName: fullName,
            operationName: operationName,
            userType: userType,
            getUSPhoneFormatWithExtn: utlities.getUSPhoneFormatWithExtn,
            stateList: stateList,
            AddNewContact: AddNewContact,
            EditFreqContact: EditFreqContact,
            saveContact: saveContact,
            cancel: cancel,
            editMode: editMode,
            serverState: null,
            compositionComplete: compositionComplete,
        };

        function compositionComplete() {
            session.validateInput();
        }

        function activate() {
            vm.editMode(false);
            contactsModel.Reset();
            fullName("");
            operationName("");
            userType("");
            console.log('Frequent Contacts - activated');
            utlities.getStatesList().done(function (result) {
                stateList([]);
                stateList(result);
            });
            getUserProfileInformation();
            getFrequentContacts();

        }

        function getUserProfileInformation() {
            var url = config.getUserProfileInfoUrl;
            return https.get(url, { UserId: session.userId, OperationId: session.defaultOpertionId() })
                .done(function (result) {
                    if (result) {
                        vm.operationName(result.UserInformation.SystemInfo.Operation.Name);
                        vm.fullName(result.UserInformation.LastName + ", " + result.UserInformation.FirstName);
                        vm.userType(result.UserInformation.UserType.Name);
                    }
                }).always(function () {
                    session.isAjaxPost(true);
                })
                .fail(function () {
                    //   alerter.error('Error fetching User Information', 'User Information');
                });
        }

        function getFrequentContacts() {
            var url = config.frequentContactsUrl;
            https.get(url, {
                userId: session.userId
            })
                .then(function (data) {
                    console.log(data);
                    vm.contactsModel.ContactsList([]);
                    vm.contactsModel.ContactsList(data);
                });
        }

        function AddNewContact() {
            vm.editMode(false);
            contactsModel.Reset();
            $("#ManageFrequentContact").modal('hide');
            contactsModel.errors.showAllMessages(false);
            contactsModel.contactId(0);
            getContactTypes().then(function () {
                $("#ManageFrequentContact").modal('show');
                contactsModel.errors.showAllMessages(false);
            });

        }

        function getContactTypes() {
            var dfd = $.Deferred();
            var url = config.getFrequentContactTypesUrl;
            https.get(url, { format: 'json' })
                .then(function (data) {
                    contactsModel.contactTypes([]);
                    contactsModel.contactTypes(data);
                    dfd.resolve();
                })
                .fail(function (data) {
                    dfd.resolve();
                });
            return dfd.promise();
        }


        function EditFreqContact(id) {
            contactsModel.Reset();
            vm.editMode(true);
            $("#ManageFrequentContact").modal('hide');
            contactsModel.contactId(id);
            getContactTypes().then(function () {
                var url = config.getFrequentContactByIdUrl;
                https.get(url, {
                    FrequentContactId: id,
                    format: 'json'
                }).then(function (data) {
                    console.log(data);
                    if (data !== null && data !== undefined) {
                        vm.serverState = data;
                        contactsModel.Update(data);
                        $("#ManageFrequentContact").modal('show');
                    }
                    else {
                        alerter.error("An error occurred while retriving the contact");
                        $("#ManageFrequentContact").modal('hide');
                    }
                }).fail(function (data) {
                    $("#ManageFrequentContact").modal('hide');
                }).always(function () {
                    contactsModel.Update(vm.serverState);
                });
            });

        }

        //function navigate() {
        //    router.navigate("/manageContacts");
        //}

        function saveContact() {
            if (!contactsModel.isValid()) {
                contactsModel.errors.showAllMessages();
                return;
            }
            var url = config.saveFrequentContactUrl;

            var request = {
                "FreqContactId": contactsModel.contactId(),
                "UserId": session.userId,
                "FirstName": contactsModel.fName(),
                "LastName": contactsModel.lName(),
                "Email": contactsModel.email(),
                "Address": contactsModel.address(),
                "City": contactsModel.city(),
                "State": contactsModel.state(),
                "Zip": contactsModel.zip(),
                "Company": contactsModel.company(),
                "Phone": utlities.unmaskPhoneNumber(contactsModel.phone),
                "PhoneExt": contactsModel.extn(),
                "Fax": utlities.unmaskPhoneNumber(contactsModel.fax),
                "FreqContactTypeId": contactsModel.contactType(),
                "MortgageBroker": contactsModel.mortgageBroker()
            };

            return https.post(url, request)
                .then(function (data) {
                    if (data.HasErrors === false) {
                        if (data.Messages["0"].Message) {
                            alerter.success(data.Messages["0"].Message);
                            contactsModel.Reset();
                            $("#ManageFrequentContact").modal('hide');
                            $("#ManageFrequentContact").modal('hide');
                            getFrequentContacts();
                        }
                    }
                    else if (data.HasErrors === true) {
                        if (data.Messages["0"].Message) {
                            alerter.error(data.Messages["0"].Message);
                        }
                    }
                    else alerter.error("An error occurred while saving contact");
                })
                .fail(function (data) {
                    alerter.error("An error occurred while saving contact");
                });
        }


        function deleteContact(contact) {

            var title = 'Delete Contact?';
            var msg = 'Proceed to delete this contact?';
            return app.showMessage(msg, title, ['Yes', 'No '])
                .then(function (option) {
                    if (option === 'Yes') {

                        var url = config.deleteFrequentContactUrl;

                        https.post(url, contact)
                            .then(function (data) {
                                if (data.Messages["0"].Message) {
                                    if (data.HasErrors === false)
                                        alerter.success(data.Messages["0"].Message);
                                    else
                                        alerter.error(data.Messages["0"].Message);
                                }
                                else
                                    alerter.error("An error occurred while deleting contact");

                                getFrequentContacts();
                            })
                            .fail(function (data) {
                                alerter.error("An error occurred while deleting contact");
                            });
                    }
                });
        }

        function cancel() {
            if (vm.editMode())
                contactsModel.Update(vm.serverState);
            else
                contactsModel.Reset();
        }


        contactsModel.zip.subscribe(function (zip) {
            if (vm.editMode() && (vm.serverState.Zip === zip))
                return;

            if (zip !== undefined && zip !== '') {
                utlities.getZipInfo(zip).done(function (data) {
                    if (data !== undefined && data !== null && data.HasErrors === false) {
                        if (data.StateAbbr !== null || data.City !== null) {
                            contactsModel.city(data.City);
                            contactsModel.state(data.StateAbbr);
                        }
                        else {
                            alerter.error("Invalid Zip.");
                            contactsModel.zip('');
                            contactsModel.city(undefined);
                            contactsModel.state('');
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                });
            }
        });
        console.log('Frequent Contacts VM - returned');

        return vm;
    });
