define('models/successStories/successStories',["knockout", 'utlities'],
    function (ko, utlities) {


        function successStory() {
            var self = this;

            self.successStoryId = ko.observable("");
            self.datePublished = ko.observable("");
            self.title = ko.observable("");
            self.content = ko.observable("");

        }



        var vm = {
            successStory: successStory,
        }

        return vm;
    }
);
