define('userOperationModel',["knockout"],
    function (ko) {
        console.log('User Operations Model called...');

        function UserOperationModel() {

            var self = this;
            self.OperationId = ko.observable(0);
            self.BrandId = ko.observable(0);
            self.OperationName = ko.observable("");
            self.ValueCheckUserId = ko.observable("");
            self.IsDefaultOperation = ko.observable(false);

            self.Reset = function () {
                self.OperationId(0);
                self.BrandId(0);
                self.OperationName("");
                self.ValueCheckUserId("");
                self.IsDefaultOperation(false);
                ko.validation.group(self).showAllMessages(false);
            }
            self.errors = ko.validation.group(self);
        }
        var vm = {
            UserOperationModel: UserOperationModel,
        }
        return vm;
    });
