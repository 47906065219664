define('apnSearch',['durandal/app', 'knockout', 'plugins/http', 'config', 'alertNotification', 'propertySearchCommon', 'radiusSearch'],
    function (app, ko, https, config, alert, propertySearchCommon, radiusSearch) {
        var searchResults = ko.observableArray();

        var vm = {
            addressSearch: addressSearch,
            searchResults: searchResults,
            compositionComplete: compositionComplete
        };

        vm.searchResults.subscribe(propertySearchCommon.alertMultiplePropertyWarning);

        function compositionComplete() {
            session.validateInput();
        }
        function addressSearch(parentResultHolder, apnModel, isSearchable) {
            var url = config.propertySearchUrl;
            var request = {
                Zip: apnModel.zip() == undefined ? "" : apnModel.zip().trim(),
                State: apnModel.state,
                County: apnModel.county(),
                Apn: apnModel.apnNumber() == undefined ? "-----" : apnModel.apnNumber().trim(),
                SearchType: 'APN',
                // Inorder to get the service response need to pass fips.
                // However fips field is not available in UI. Hence passing hard coded value to get respons from service.
                Fips: apnModel.county(),
                //geo codes required to search if the property not found with apn and search was done on map click
                latitude: apnModel.latitude(),
                longitude: apnModel.longitude(),
                landusecode: apnModel.landusecode(),
                radius: apnModel.radius()
            };
            console.log(url);
            radiusSearch.setRequestParameters(request);
            return https.post(url, request)
                .then(function (data) {
                    searchResults(data);
                    parentResultHolder(data);
                })
                .always(function () {
                    isSearchable(true);
                })
                .fail(Failed);
        }



        function Failed(error) {
            alert.error(error.statusText);

        }

        return vm;
    });


