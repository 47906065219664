define('viewmodels/netSeller/netSeller',["knockout", 'plugins/http', 'global/session', 'config', 'utlities', 'alertNotification', 'plugins/router'],
    function (ko, https, session, config, utlities, alert, router) {
        var vm = {
            canActivate: canActivate,
            session: session,
        }
        return vm;

        function canActivate() {

            getNetSellerURL().done(function (redirectUrl) {
                if (redirectUrl && redirectUrl.length > 0)
                    session.CheckPopUpBlock(redirectUrl, '_self', null);
                    //window.open(redirectUrl, '_self');
                else
                    app.showMessage('Error accessing Netseller.', 'Netseller Error', ['OK']);
                //router.navigateBack();
            });
            return false;

            
            //https.get(url, {
            //    encPw: session.pass,
            //    ssoTarget: 'Netsellers',
            //    format: 'json'
            //}).then(function (data) {
            //    var redirectUrl = data;
            //    console.log('netseller URL ' + redirectUrl);
            //    window.open(redirectUrl, '_top', false);
            //    //router.navigate('bingAddressSearch', { replace: true, trigger: true });
            //});

        }

        function getNetSellerURL() {
            var url = config.getSsoUrl;
            return https.get(url, {
                ssoTarget: 'Netsellers',
                format: 'json'
            });
        }


    });
