define('models/order/pirtOrder',["knockout", "modelMetaData", 'utlities'],
    function (ko, modelMetaData, utils) {
        function OrderInformation() {
            var self = this;

            self.viewName = "orderModule/pirt/_orderInformation.html"
            self.title = "Order Information"
            self.ModelId = "orderInformation"
            self.index = ko.observable(-1)

            self.pirt = ko.observable("")
            //Field Asset Verification
            self.fav = ko.observable("")
            //FHLMC 704 Appraisal/photos w/comparables photos
            self.fhlmc = ko.observable("")
            self.pirtUpdate = ko.observable("")
            self.fullAppraisal = ko.observable("")
            self.pirtSubEscrow = ko.observable("")
            self.fullEscrow = ko.observable("")
            self.alta = ko.observable("")
            self.floodCertificate = ko.observable("")
            self.floodCertificateStandard = ko.observable("")
            self.floodCertificateLifeLoan = ko.observable("")
            self.ownerName = ko.observable("")
            .extend({ required: { message: 'Owner\'s Name  is required.' } })
            .extend({
                pattern: {
                    message: 'Invalid character(s).',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            })
            self.address = ko.observable("")
            .extend({ required: { message: 'Address is required.' } })
            .extend({
                pattern: {
                    message: 'Invalid character(s).',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            })
            self.city = ko.observable(undefined)
             .extend({ required: { message: 'City is required.' } })
             .extend({
                 pattern: {
                     message: 'Invalid character(s).',
                     params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                 }
             })
            self.state = ko.observable(undefined)
             .extend({ required: { message: 'State is required.' } })
            self.zipCode = ko.observable("")
             .extend({ required: { message: 'Zip Code is required.' } })
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.county = ko.observable(undefined)
            self.apn = ko.observable("")
            .extend({
                pattern: {
                    message: 'Invalid character(s).',
                    params: '^[\\w \\s\\.\\|\\*\\?\\-\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            })
            self.homePhone = ko.observable("")
            .extend({ phoneUS: true })
            .extend({
                required: {
                    message: "Home Phone number is required for Full Appraisal.",
                    onlyIf: function () {
                        return self.fullAppraisal();
                    }
                }
            })
            self.homeExt = ko.observable("")
            .extend({ digit: true });
            self.ownerWorkPhone = ko.observable("")
            .extend({ phoneUS: true })
            .extend({
                required: {
                    message: "Owner\'s Work Phone is required",
                    onlyIf: function () {
                        return self.ownerExt().length > 0;
                    }
                }
            })
            .extend({
                required: {
                    message: "Owner\'s Work Phone number is required for Full Appraisal.",
                    onlyIf: function () {
                        return self.fullAppraisal();
                    }
                }
            })
            self.ownerExt = ko.observable("")
            .extend({ digit: true });
            self.spouseWorkPhone = ko.observable("")
            .extend({ phoneUS: true })
            .extend({
                required: {
                    message: "* Spouse's Work Phone is required",
                    onlyIf: function () {
                        return self.spouseExt().length > 0;
                    }
                }
            })
            self.spouseExt = ko.observable("")
            .extend({ digit: true });

            self.pirtList = ko.observableArray([
                { Key: " PIRT ($200,000 liability limit)", Value: " PIRT ($200,000 liability limit)" },
                { Key: " PIRT Plus ($250,000 liability limit)", Value: " PIRT Plus ($250,000 liability limit)" },
                { Key: "  PIRT Extra ($360,000 liability limit)", Value: "  PIRT Extra ($360,000 liability limit)" }
            ]);

            self.update = function (data) {
                if (data) {
                    self.pirt(data.pirt)
                    //Field Asset Verification
                    self.fav(data.fav)
                    //FHLMC 704 Appraisal/photos w/comparables photos
                    self.fhlmc(data.fhlmc)
                    self.pirtUpdate(data.pirtUpdate)
                    self.fullAppraisal(data.fullAppraisal)
                    self.pirtSubEscrow(data.pirtSubEscrow)
                    self.fullEscrow(data.fullEscrow)
                    self.alta(data.alta)
                    self.floodCertificate(data.floodCertificate)
                    self.floodCertificateStandard(data.floodCertificateStandard)
                    self.floodCertificateLifeLoan(data.floodCertificateLifeLoan)
                    self.ownerName(data.ownerName)
                    self.address(data.address)
                    self.city(data.city)
                    self.state(data.state)
                    self.zipCode(data.zipCode)
                    self.county(data.county)
                    self.apn(data.apn)
                    self.homePhone(data.homePhone)
                    self.homeExt(data.homeExt)
                    self.ownerWorkPhone(data.ownerWorkPhone)
                    self.ownerExt(data.ownerExt)
                    self.spouseWorkPhone(data.spouseWorkPhone)
                    self.spouseExt(data.spouseExt)

                }
            }
            self.reset = function () {
                self.pirt("")
                //Field Asset Verification
                self.fav("")
                //FHLMC 704 Appraisal/photos w/comparables photos
                self.fhlmc("")
                self.pirtUpdate("")
                self.fullAppraisal("")
                self.pirtSubEscrow("")
                self.fullEscrow("")
                self.alta("")
                self.floodCertificate("")
                self.floodCertificateStandard("")
                self.floodCertificateLifeLoan("")
                self.ownerName("")
                self.address("")
                self.city(undefined)
                self.state(undefined)
                self.zipCode("")
                self.county(undefined)
                self.apn("")
                self.homePhone("")
                self.homeExt("")
                self.ownerWorkPhone("")
                self.ownerExt("")
                self.spouseWorkPhone("")
                self.spouseExt("")

                ko.validation.group(self).showAllMessages(false);
            }

            self.resetPropertyAddress = function () {
                self.address("");
                self.city(undefined);
                self.state(undefined);
                self.zipCode("");
                self.county(undefined);
                self.apn("");
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("PIRT", self.pirt()));

                self.modelData.properties.push(new modelMetaData.propertyData().create("FAV (Field Asset Verification)", utils.reportBooleanValue(self.fav())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("FHLMC 704 Appraisal/photos w/comparables photos", utils.reportBooleanValue(self.fhlmc())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("PIRT Update", utils.reportBooleanValue(self.pirtUpdate())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Full Appraisal - Phone #'s required", utils.reportBooleanValue(self.fullAppraisal())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("PIRT Sub-Escrow", utils.reportBooleanValue(self.pirtSubEscrow())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Full Escrow", utils.reportBooleanValue(self.fullEscrow())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("ALTA", utils.reportBooleanValue(self.alta())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Flood Certificate", utils.reportBooleanValue(self.floodCertificate())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Flood Certificate Standard", utils.reportBooleanValue(self.floodCertificateStandard())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Flood Certificate Life of Loan", utils.reportBooleanValue(self.floodCertificateLifeLoan())));

                self.modelData.properties.push(new modelMetaData.propertyData().create("Owner's Name", self.ownerName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Address", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip Code", self.zipCode()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("County", self.county()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("APN #", self.apn()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Home Phone", self.homePhone()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Owner's Work Phone", modelMetaData.appendPhoneWithExtn(self.ownerWorkPhone(), self.ownerExt(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Spouse's Work Phone", modelMetaData.appendPhoneWithExtn(self.spouseWorkPhone(), self.spouseExt(), 'x')));

            }



        }
        function AdditionalAppraisalInfo() {
            var self = this;

            self.viewName = "orderModule/pirt/_additionalAppraisalInformation.html"
            self.title = "Additional Appraisal Info"
            self.ModelId = "additionalAppraisalInformation"
            self.index = ko.observable(-1)

            self.typeofStructure = ko.observable("")
            self.typeofStructureList = ko.observableArray([
                                       { text: "Single Family", value: "Single Family" },
                                       { text: "Duplex", value: "Duplex" },
                                       { text: "Triplex", value: "Triplex" },
                                       { text: "Condo", value: "Condo" }
            ]);
            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.typeofStructure(data.typeofStructure)
                }

            };

            self.reset = function () {
                self.typeofStructure("")
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Type of Structure", self.typeofStructure()));
            }

        }
        function Interior() {
            var self = this;

            self.viewName = "orderModule/pirt/_interior.html"
            self.title = "Interior"
            self.ModelId = "interior"
            self.index = ko.observable(-1)

            self.livingRoom = ko.observable("")
            self.familyRoom = ko.observable("")
            self.kitchen = ko.observable("")
            self.den = ko.observable("")
            self.guestHome = ko.observable("")
            self.bedrooms = ko.observable("")
            .extend({ digit: true });
            self.baths = ko.observable("")
            .extend({ digit: true });
            self.standardBuiltins = ko.observable("")
            self.other = ko.observable("")

            self.update = function (data) {
                if (data) {

                    self.livingRoom(data.livingRoom)
                    self.familyRoom(data.familyRoom)
                    self.kitchen(data.kitchen)
                    self.den(data.den)
                    self.guestHome(data.guestHome)
                    self.bedrooms(data.bedrooms)
                    self.baths(data.baths)
                    self.standardBuiltins(data.standardBuiltins)
                    self.other(data.other)
                }

            };

            self.reset = function () {

                self.livingRoom("")
                self.familyRoom("")
                self.kitchen("")
                self.den("")
                self.guestHome("")
                self.bedrooms("")
                self.baths("")
                self.standardBuiltins("")
                self.other("")
                ko.validation.group(self).showAllMessages(false);

            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Living Room", utils.reportBooleanValue(self.livingRoom())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Family Room", utils.reportBooleanValue(self.familyRoom())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Kitchen", utils.reportBooleanValue(self.kitchen())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Den", utils.reportBooleanValue(self.den())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Guest Home", utils.reportBooleanValue(self.guestHome())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("# Bedrooms", self.bedrooms()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("# Baths", self.baths()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Standard Built-ins", utils.reportBooleanValue(self.standardBuiltins())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other", self.other()));

            }

        }
        function Fixtures() {
            var self = this;

            self.viewName = "orderModule/pirt/_fixtures.html"
            self.title = "Fixtures"
            self.ModelId = "fixtures"
            self.index = ko.observable(-1)

            self.centralAC = ko.observable("")
            self.poolInGround = ko.observable("")
            self.poolAboveGround = ko.observable("")
            self.spaInGround = ko.observable("")
            self.spaAboveGround = ko.observable("")
            self.doubleGarage = ko.observable("")
            self.fireplace = ko.observable("")
            self.centralHeating = ko.observable("")
            self.singleGarage = ko.observable("")
            self.woodBurningStove = ko.observable("")
            self.evaporativeCooler = ko.observable("")
            self.carport = ko.observable("")
            self.deck = ko.observable("")
            self.coveredPatio = ko.observable("")
            self.windowOrWallHeater = ko.observable("")
            self.other = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Other entry should be limited to 200 characters' } })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self.additionalInformation = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Additional Information should be limited to 200 characters' } })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self.approxLivingArea = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Approx. Living Area should be limited to 200 characters' } })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self.approxLotSize = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Approx. Lot Size should be limited to 200 characters' } })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self
            self.purchasePrice = ko.observable("")
            .extend({ number: true });
            self.comments = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Comments entry should be limited to  200 characters' } })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.centralAC(data.centralAC)
                    self.poolInGround(data.poolInGround)
                    self.poolAboveGround(data.poolAboveGround)
                    self.spaInGround(data.spaInGround)
                    self.spaAboveGround(data.spaAboveGround)
                    self.doubleGarage(data.doubleGarage)
                    self.fireplace(data.fireplace)
                    self.centralHeating(data.centralHeating)
                    self.singleGarage(data.singleGarage)
                    self.woodBurningStove(data.woodBurningStove)
                    self.evaporativeCooler(data.evaporativeCooler)
                    self.carport(data.carport)
                    self.deck(data.deck)
                    self.coveredPatio(data.coveredPatio)
                    self.windowOrWallHeater(data.windowOrWallHeater)
                    self.other(data.other)
                    self.additionalInformation(data.additionalInformation)
                    self.approxLivingArea(data.approxLivingArea)
                    self.approxLotSize(data.approxLotSize)
                    self.purchasePrice(data.purchasePrice)
                    self.comments(data.comments)

                }

            };

            self.reset = function () {
                self.centralAC("")
                self.poolInGround("")
                self.poolAboveGround("")
                self.spaInGround("")
                self.spaAboveGround("")
                self.doubleGarage("")
                self.fireplace("")
                self.centralHeating("")
                self.singleGarage("")
                self.woodBurningStove("")
                self.evaporativeCooler("")
                self.carport("")
                self.deck("")
                self.coveredPatio("")
                self.windowOrWallHeater("")
                self.other("")
                self.additionalInformation("")
                self.approxLivingArea("")
                self.approxLotSize("")
                self.purchasePrice("")
                self.comments("")
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Central A/C", utils.reportBooleanValue(self.centralAC())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Pool, In Ground", utils.reportBooleanValue(self.poolInGround())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Pool, Above Ground", utils.reportBooleanValue(self.poolAboveGround())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Spa, In Ground", utils.reportBooleanValue(self.spaInGround())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Spa, Above Ground", utils.reportBooleanValue(self.spaAboveGround())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Double Garage", utils.reportBooleanValue(self.doubleGarage())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Fireplace", utils.reportBooleanValue(self.fireplace())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Central Heating", utils.reportBooleanValue(self.centralHeating())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Single Garage", utils.reportBooleanValue(self.singleGarage())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Wood Burning Stove", utils.reportBooleanValue(self.woodBurningStove())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Evaporative Cooler", utils.reportBooleanValue(self.evaporativeCooler())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Carport", utils.reportBooleanValue(self.carport())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Deck", utils.reportBooleanValue(self.deck())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Covered Patio", utils.reportBooleanValue(self.coveredPatio())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Window or Wall Heater", utils.reportBooleanValue(self.windowOrWallHeater())));

                self.modelData.properties.push(new modelMetaData.propertyData().create("Other", self.other()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Additional Information", self.additionalInformation()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Approx. Living Area", self.approxLivingArea()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Approx. Lot Size", self.approxLotSize()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Purchase Price", self.purchasePrice()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Comments", self.comments()));

            }

        }
        var vm = {
            OrderInformation: OrderInformation,
            AdditionalAppraisalInfo: AdditionalAppraisalInfo,
            Interior: Interior,
            Fixtures: Fixtures
        }

        return vm;
    });
