define('viewmodels/propertySearchModule/propertySearchCommon',['durandal/app', 'knockout', 'alertNotification', 'global/session', 'nameValue', 'radiusSearch', 'propertyProfileService'],
    function (app, ko, alert, session, nameValue, radiusSearch, propertyProfileService) {
        var messages = {
            multipleLocationsMsg: "Your entry returned the following best matched addresses.\nIf you still encounter problems, please contact your local Sales Executive for information.",
            radiusSearchAlert: 'The exact property match was not found. Please use the <b>Radius Search</b> option under <b>Advanced Search</b>. <a id="lnk_openRadiusSearch" style = "color:#DEED2B" data-bind="click:openRadiusSearch" class="hand-after">Click here</a> to go to Radius Search.',
            propertyListMaxlimitWarning: 'The maximum number of properties displayed is 100'
        };

        var getPropProfileButtonText = ko.computed(function () {
            var text = '';
            if (session.selectedProfLanguage != null && session.selectedProfLanguage.languageText() != null
                && session.selectedProfLanguage.langageCode != '-') {

                text = session.selectedProfLanguage.languageText();
            }
            return 'Get ' + text + ' Property Profile';
        });
        var sortedColumn = ko.observable('');

        function compositionComplete() {
            session.validateInput();
        }

        var vm = {
            radiusSearch: ko.observable(false),
            searchContextChanged: ko.observable(),
            compositionComplete: compositionComplete,
            openRadiusSearch: function () {
                //$('#Address').attr("aria-expanded", "false");
                $('#showAdvanceSearch').trigger("click");
                //$('#advanceSlimScroll [aria-expanded=true]').trigger("click");
                //$('a[href="#Address"]').attr("aria-expanded", "false");
                setTimeout(function () {
                    //$('[href="#radius"]').trigger("click");
                    if ($('#collapseRadius').css('display') == 'none') {
                        $('[href="#collapseRadius"]').trigger("click");
                    }
                    session.isRadiusSearchActive(true);
                    radiusSearch.setSliderActive(true);
                    $(window).scrollTop(0);
                }, 350);
            }
        };

        resetRadiusSearch = function (newValue) {
            if (vm != undefined) {
                vm.radiusSearch(true);
                vm.radiusSearch(false);
                session.isRadiusSearchActive(false);
                radiusSearch.setSliderActive(false);
                //console.log('radiusSearch(false): ' + vm.radiusSearch(false));
            }
        }

        vm.searchContextChanged.subscribe(function () {
            session.isRadiusSearchActive(false);
            resetRadiusSearch();
        });

        var alertMultiplePropertyWarning = function (newValue) {

            if (newValue != null && newValue.Properties != null) {
                if (newValue.Messages.length > 0) {
                    var tmpMessages = '';
                    var isRadiusSearch = false;
                    for (var i = 0; i < newValue.Messages.length; i++) {
                        var msg = newValue.Messages[0].Message;
                        if (msg.indexOf('found properties by radius search') >= 0) {
                            isRadiusSearch = true;
                            break;
                        }
                        else if (msg.indexOf('Invalid value') == 0) {
                            continue;
                        }
                        else if (tmpMessages.indexOf(msg) >= 0) {
                            continue;
                        }
                        else {
                            tmpMessages += msg;
                        }

                    }

                    if (isRadiusSearch == true) {
                        msg = messages.radiusSearchAlert;

                        if (newValue.Properties.length == 100)
                            msg += '<br />' + messages.propertyListMaxlimitWarning;

                        session.isRadiusSearchActive(true);
                    }
                    else if (tmpMessages.length > 0) {
                        msg = tmpMessages;
                    }
                    else {
                        msg = '';
                    }

                    if ((msg != null && msg.length > 0) && (msg.indexOf('Zipcode Service Error') == -1))
                        alert.warning(msg, {
                            timeOut: 60000,
                            extendedTimeOut: 60000,
                            tapToDismiss: false
                        });

                    //set link button event to open the radius search
                    var link = $("#lnk_openRadiusSearch");
                    if (link != null && link.length > 0)
                        ko.applyBindings(vm, $("#lnk_openRadiusSearch")[0]);

                }
                else if (newValue.Properties.length > 1) {
                    alert.warning(messages.multipleLocationsMsg, {
                        //progressBar: true,
                        timeOut: 60000,
                        extendedTimeOut: 60000,
                        tapToDismiss: false
                    });
                }
            }
            var config = { attributes: true, childList: true, characterData: true };
            //var target = document.querySelector('#searchResult');
            //observer.observe(target, config);
        };

        var closeMultiplePropertyWarning = function () {
            var btn = $('.toast-close-button');
            if (btn) btn.trigger("click");
            //observer.disconnect();
            return true;
        };

        var hasAnyProduct = function (propDetails) {
            var hasAnyProductItem = false;

            if (session.isEmployee() == true) {
                session.hasAnyProduct(true);
                hasAnyProductItem = true;
            }
            else {
                hasAnyProductItem = propDetails != null && propDetails.ServiceProducts != null && propDetails.ServiceProducts.length > 0;
                hasAnyProductItem = hasAnyProductItem || (propDetails.ProductServicesProducts != null && propDetails.ProductServicesProducts.length > 0);

                if (session != undefined && session != null)
                    session.hasAnyProduct(hasAnyProductItem);
            }
            return hasAnyProductItem;
        };

        var haslpsCredit = function (propDetails) {
            var hasCreditAssigned = false;
            if (propDetails != undefined) {
                var serviceProducts = propDetails.ServiceProducts;
                if (serviceProducts != undefined && serviceProducts.length > 0) {
                    for (var i = 0; i < serviceProducts.length; i++) {
                        if (serviceProducts[i].UsageLimitAssigned > 0) {
                            hasCreditAssigned = serviceProducts[i].UsageLimitAssigned > 0;
                            break;
                        }
                    }
                }
            }
            if (session != undefined && session != null)
                session.hasLPSCredit(hasCreditAssigned);
            return hasCreditAssigned;
        };

        var openReport = function (reportData, event, skipSave) {
            var item = new nameValue();
            item.ReportType(reportData.ReportType);
            item.LanguageCode(reportData.LanguageCode);
            item.Language(reportData.Language);
            item.ProfileURL(reportData.ProfileURL);
            setLanguagePreferance(item, null, skipSave);
            return false;
        };

        var setLanguagePreferance = function (reportData, event, skipSave) {
            var lancCde;
            if (reportData && reportData.ReportType && (reportData.ReportType() == "EnhancedProfile" || reportData.ReportType() == "EnhancedProfile2.0"  || reportData.ReportType() == "Profile")) {
                if (reportData.LanguageCode() == null || reportData.LanguageCode() == '')
                    lancCde = '-'
                else
                    lancCde = reportData.LanguageCode();
                var ctr = $('.prof');
                ctr.removeClass('prof btn btn-primary');
                $('[data-lac=' + lancCde + ']').addClass('prof btn btn-primary')
                setProfileSelectedLanguage({ Key: lancCde, Value: reportData.Language() });
                propertyProfileService.savePropertyProfileLanguage(lancCde);
            }


            //if (skipSave != true && reportData != null && reportData.ProfileURL()) {
            //    propertyProfileService.savePropertyProfileLanguage(lancCde);
            //    session.CheckPopUpBlock(reportData.ProfileURL(), null, "** Reports open in a new window. Please disable your popup blocker and try again.");
            //}
        };

        var setLanguagePreferanceHosted = function (reportData, event, skipSave) {
            var lancCde;
            if (reportData && reportData.ReportType && (reportData.ReportType() == "EnhancedProfile" || reportData.ReportType() == "EnhancedProfile2.0" || reportData.ReportType() == "Profile")) {
                if (reportData.LanguageCode() == null || reportData.LanguageCode() == '')
                    lancCde = '-'
                else
                    lancCde = reportData.LanguageCode();
                var ctr = $('.prof');
                ctr.removeClass('prof btn btn-primary');
                $('[data-lac=' + lancCde + ']').addClass('prof btn btn-primary')
                setProfileSelectedLanguage({ Key: lancCde, Value: reportData.Language() });
            }


            if (skipSave != true && reportData != null && reportData.ProfileURL()) {
                propertyProfileService.savePropertyProfileLanguage(lancCde);
                session.CheckPopUpBlock(reportData.ProfileURL(), null, "** Reports open in a new window. Please disable your popup blocker and try again.");
            }
        };

        var setProfileSelectedLanguage = function (data) {
            if (data == null || data.Key == null || data.Key.length == 0) return;

            session.selectedProfLanguage.langageCode(data.Key);
            session.selectedProfLanguage.languageText(data.Value);

            //if (data.Value.indexOf('Spanish') > -1)
            //    session.selectedProfLanguage.languageText('Spanish');
            //else if (data.Value.indexOf('Korean') > -1)
            //    session.selectedProfLanguage.languageText('Korean');
            //else if (data.Value.indexOf('Chinese') > -1)
            //    session.selectedProfLanguage.languageText('Chinese');
            //else if (data.Value.indexOf('Bengali') > -1)
            //    session.selectedProfLanguage.languageText('Bengali');
            //else if (data.Value.indexOf('Chaldean') > -1)
            //    session.selectedProfLanguage.languageText('Chaldean');
            //else if (data.Value.indexOf('Hindi') > -1)
            //    session.selectedProfLanguage.languageText('Hindi');
            //else if (data.Value.indexOf('Japanese') > -1)
            //    session.selectedProfLanguage.languageText('Japanese');
            //else if (data.Value.indexOf('Marathi') > -1)
            //    session.selectedProfLanguage.languageText('Marathi');
            //else if (data.Value.indexOf('Punjabi') > -1)
            //    session.selectedProfLanguage.languageText('Punjabi');
            //else if (data.Value.indexOf('Russian') > -1)
            //    session.selectedProfLanguage.languageText('Russian');
            //else if (data.Value.indexOf('Vietnamese') > -1)
            //    session.selectedProfLanguage.languageText('Vietnamese');
            //else
            //    session.selectedProfLanguage.languageText('');

            if ($('a.getProfileLinkInfoBox') != null) {
                $('a.getProfileLinkInfoBox').text(getPropProfileButtonText());
            }
        };


        return {
            getPropProfileButtonText: getPropProfileButtonText,
            openReport: openReport,
            setProfileSelectedLanguage: setProfileSelectedLanguage,
            setLanguagePreferance: setLanguagePreferance,
            messages: messages,
            alertMultiplePropertyWarning: alertMultiplePropertyWarning,
            closeMultiplePropertyWarning: closeMultiplePropertyWarning,
            haslpsCredit: haslpsCredit,
            hasAnyProduct: hasAnyProduct,
            resetRadiusSearch: resetRadiusSearch,
            searchDetails: vm,
            sortedColumn: sortedColumn
        }
    });


