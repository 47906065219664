define('propertyDetailResponse',["knockout"], function (ko) {
    function propertyDetailResponse() {
        var self = this;

        self.property = ko.observable(undefined);
        self.productServicesProducts = ko.observable(undefined);
        self.serviceProducts = ko.observable(undefined);
        self.hasGetMethodError = ko.observable(false);
        self.purchaseableReportsShoppingCart = ko.observableArray([]);
        self.walletBalance = ko.observable(0);
        self.creditBalance = ko.observable(0);
        self.tax = ko.observable(0);
        self.walletEnabled = ko.observable(false);
        self.creditsEnabled = ko.observable(false);
    }
    return propertyDetailResponse;
});
