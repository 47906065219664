define('models/order/subjectProperty',["knockout"],
    function (ko) {
        function subjectProperty() {
            var self = this;
            self.viewName = "orderModule/_subjectProperty.html";
            self.title = "Subject Property";
            self.index = ko.observable(-1);

            self.addressNumber = ko.observable("")
            self.addressStreetName = ko.observable("")

            self.zip = ko.observable("")
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
            .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
            .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.city = ko.observable("")
             .extend({ required: { params: true, message: 'City is required.' } });

            self.state = ko.observable("")
             .extend({ required: { params: true, message: 'State is required.' } });

            self.county = ko.observable("")
            self.apn = ko.observable("");
            self.propertyType = ko.observable("")

            self.reset = function () {
            }

            self.isValid = function()
            {
                return ko.validation.group(self).length==0;
            }

        }

        var vm = {
            subjectProperty: subjectProperty
        }

        return vm;
    });
