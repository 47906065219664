define('viewmodels/llaModule/llaPublicPage',['plugins/router','durandal/app', 'knockout', 'services/logger', 'Q', 'plugins/http', 'config', 'global/session', 'alertNotification', 'utlities', 'commonDialogModel'],
 function (router, app, ko, logger, Q, https, config, session, alert, utlities, commonDialog) {

     var vm = {
         activate: activate,
         goBack: goBack,
         compositionComplete: compositionComplete, 
         title: 'LLA Public Page'
     };

     return vm;

     function compositionComplete() {
         session.validateInput();
     }

     function activate() {
         //TODO: Initialize lookup data here.

         //TODO: Get the data for this viewmodel, return a promise.
         console.log("Showing Public LLA page");
     }

     function goBack() {
         router.navigateBack();
     }
 });
