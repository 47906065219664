define('viewmodels/learning/learnDurandal',['plugins/router', 'knockout', 'durandal/app', 'plugins/http', 'global/session', 'config'],
    function (router, ko, app, https, session, config) {

        var orderTypes = ko.observableArray();
        var type = ko.observable('Test');

        var vm = {
            activate: activateM,
            canActivate: canActivateM,
            canDeactivate: canDeactivateM,
            compositionComplete: compositionCompleteM,
            orderTypes: orderTypes,
            type: type,
            addType: addType
        };

        function canActivateM() {
            console.log('canActivate');
            return true;
        }
        function activateM() {
            orderTypes.push(type());
            console.log('Activate');
        }

        function compositionCompleteM() {
            console.log('compositionComplete');
        }
        function canDeactivateM() {
            console.log('canDeactivate');
            return true;
        }

        function addType() {
            var newType = type();
            orderTypes.push(newType);
            type('');
        }

        return vm;
    });
