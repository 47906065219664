define('viewmodels/successStories/successStories',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'knockout.validation', 'plugins/http', 'global/session', 'config', 'alertNotification', 'successStoryModel', 'utlities'],
 function (app, router, $, ko, validate, validation, https, session, config, alert, successStoryModel, utlities) {
     var successStoryModel = new successStoryModel.successStory();
     var successStoriesList = ko.observableArray();

     function activate() {
         getSuccessStories();

     }

     function getSuccessStories() {
         var url = config.getSuccessStoriesUrl;
         https.get(url, {
             currentCultureId: 1,
             brandId: session.brandId,
             format: 'json'
         }).done(function (data) {
             successStoriesList(data);

         }).fail(function () {
         });
     }

    

     function Failed(error) {
         alert.error(error.statusText);
     }

     function compositionComplete() {
         session.validateInput();
     }

     var vm = {
         activate:activate,
         successStoryModel: successStoryModel,
         successStoriesList: successStoriesList,
         compositionComplete: compositionComplete
     }
     return vm;

 })



;
