define('registerPersonalInfoModel',["knockout", 'global/session'],
    function (ko, session) {
        function registerPersonalInfo() {
            var self = this;

            //    ko.validation.rules['requiredAsUndefined'] = {
            //        validator: function(val) {
            //            return val !== undefined;
            //        },
            //    message: 'The field is required'
            //};
            ko.validation.registerExtenders();

            self.firstName = ko.observable("")
                .extend({ required: { message: 'First name is required.' } });

            self.isPasswordRequired = ko.observable(true);


            self.lastName = ko.observable("")
                .extend({ required: { message: 'Last name is required.' } });


            if (session.useOktaAuthentication() == false) {

                self.password = ko.observable("")
                    .extend({
                        required: {
                            message: 'Password is required.',
                            onlyIf: function () {
                                return self.isPasswordRequired();
                            }

                        }


                    })
                    .extend({ minLength: { params: 8, message: 'Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.' } })
                    //.extend({ maxLength: { params: 128, message: 'New Password has a maximum of 128 characters' } })
                    .extend({
                        pattern: {
                            params: /\d{1}/,
                            message: 'Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                        }
                    })
                    .extend({
                        pattern: {
                            params: /[a-zA-Z]/,
                            message: 'Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                        }
                    })
                    .extend({
                        pattern: {
                            params: /(?=.*[a-z])(?=.*[A-Z])/,
                            message: 'Password should be a minimum of 8 characters, maximum of 128 characters, at least one number, and a mixture of upper and lower case letters.'
                        }
                    });
         


                self.confirmPassword = ko.observable("")
                    .extend({
                        required: {
                            message: 'Confirm Password is required.',
                            onlyIf: function () {
                                return self.isPasswordRequired();
                            }

                        }
                    })
                    //.extend({ minLength: { params: 8, message: 'Confirm Password should be of minimum 8 characters length.' } })
                    //.extend({ maxLength: { params: 75, message: 'Confirm Password should be of maximum 75 characters length.' } })
                    .extend({ equal: { params: self.password, message: 'Should match Password.' } })

                self.password.subscribe(function (newValue) {
                    if (newValue.length >= 128) {
                        $("#maxPasswordLength").show();
                    } else {
                        $("#maxPasswordLength").hide();
                    }
                })
            }


            self.companyName = ko.observable("")
                .extend({ required: { message: 'Company name is required.' } });


             self.occupationList = ko.observableArray([
             { occupationId: "11", occupationName: "Appraiser" },
             { occupationId: "4", occupationName: "Attorney" },
             { occupationId: "8", occupationName: "Broker" },
             { occupationId: "10", occupationName: "Builder/Developer" },
             { occupationId: "9", occupationName: "Commercial Sales" },
             { occupationId: "13", occupationName: "Escrow" },
             { occupationId: "14", occupationName: "FSBO" },
             { occupationId: "12", occupationName: "Government" },
             { occupationId: "6", occupationName: "Insurance" },
             { occupationId: "2", occupationName: "Investor" },
             { occupationId: "5", occupationName: "Lender" },
             { occupationId: "7", occupationName: "Other" },
             { occupationId: "1", occupationName: "Real Estate" },
             { occupationId: "3", occupationName: "Title Agent" }])

            self.occupation = ko.observable(undefined)
                .extend({ required: { message: 'Occupation is required.' } });

            self.workPhone = ko.observable("")
                .extend({ required: { message: 'Work phone is required.' } })
                .extend({ phoneUS: true });


            self.cellPhone = ko.observable("")
                .extend({ phoneUS: true });

            self.streetAddress = ko.observable("")
                .extend({ required: { message: 'Street address is required.' } });

            //This input is not required.
            self.suitNumber = ko.observable("");
            //    .extend({ required: { message: 'Suit number is required.' } })

            self.zipCode = ko.observable("")
                .extend({ required: { message: 'Zip code is required.' } })
                .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.city = ko.observable("")
                .extend({ required: { message: 'City is required.' } })

            self.state = ko.observable(undefined)
                .extend({ required: { message: 'State is required.' } })

            self.county = ko.observable(undefined)
                .extend({ required: { message: 'County is required.' } })

            self.countyName = ko.observable("");

            self.salesExecutive = ko.observable(undefined)
                .extend({ required: { message: 'Sales Executive is required.' } });

            self.salesExecutiveContact = ko.observable('');

            self.birthdayMonthList = ko.observableArray([
           { id: "1", name: "January" },
           { id: "2", name: "February" },
           { id: "3", name: "March" },
           { id: "4", name: "April" },
           { id: "5", name: "May" },
           { id: "6", name: "June" },
           { id: "7", name: "July" },
           { id: "8", name: "August" },
           { id: "9", name: "September" },
           { id: "10", name: "October" },
           { id: "11", name: "November" },
           { id: "12", name: "December" },
           ]);
            self.birthdayMonth = ko.observable(undefined)
         //       .extend({ required: { message: 'Birthday month is required.' } })

            self.birthdayDayList = ko.observable(["Please select", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15",
                "16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"]);
           
            self.birthdayDay = ko.observable(undefined)
            //    .extend({ required: { message: 'Birthday day is required.' } });


            self.birthdayMonth.extend({
                required: {
                    message: "Birthday Month is required",
                    onlyIf: function () {
                        if (self.birthdayMonth() == undefined && self.birthdayDay() == undefined)
                            return false;
                        else if (self.birthdayMonth() == undefined && self.birthdayDay() != undefined)
                            return true;
                        else
                            return false;
                    }
                }
            });
            self.birthdayDay.extend({
                required: {
                    message: "Birthday Date is required",
                    onlyIf: function () {
                        if (self.birthdayMonth() == undefined && self.birthdayDay() == undefined)
                            return false;
                        else if (self.birthdayMonth() != undefined && self.birthdayDay() == undefined)
                            return true;
                        else
                            return false;
                    }
                }
            });
            self.agreeTerms = ko.observable(false)
            .extend({ equal: {  params: true,message: 'User needs to agree to the terms, to continue.' } })

            //self.agreeLLA = ko.observable(false)

            //ko.observable(false).extend({
            //    equal: { params: true, message: 'check must be ticked' }
            //});

            self.update = function (data) {
                if (data) {
                    self.firstName(data.firstName());
                    self.lastName(data.lastName());
                    if (session.useOktaAuthentication == false) {
                        self.password(data.password());
                        self.confirmPassword(data.confirmPassword());
                    }
                    

                    self.companyName(data.companyName());
                    self.occupation(data.occupation());
                    self.workPhone(data.workPhone());
                    self.cellPhone(data.cellPhone());

                    self.streetAddress(data.streetAddress());
                    self.suitNumber(data.suitNumber());
                    self.zipCode(data.zipCode());
                    self.city(data.city());
                    self.state(data.state());
                    self.county(data.county());

                    self.salesExecutive(data.salesExecutive());
                    self.birthdayMonth(data.birthdayMonth());
                    self.birthdayDay(data.birthdayDay());
                    self.agreeTerms(data.agreeTerms());
                    //self.agreeLLA(data.agreeLLA());
                }
            };
        }

        var vm = {
            registerPersonalInfo: registerPersonalInfo
        }

        return vm;
    });
