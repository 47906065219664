define('viewmodels/externalSiteRedirect/TitleProRedirect',["knockout", 'plugins/http', 'global/session', 'config', 'utlities', 'plugins/router', 'durandal/app'],
    function (ko, https, session, config, utlities, router, app) {
        var vm = {
            session: session,
            canActivate: function () {
                if (session.ngPopUpEnabled()) {
                    app.showMessage(session.ngPopUpText(), 'Important Announcement', ['Continue'], true, { "secondaryButtonClass": "btn-primary", style: { 'max-width': "600px", 'max-height': "50vh!important" } })
                    //app.showMessage(session.promptClassicEfarmMessage(), 'Important Announcement', [classicEFarmName, nextGenEFarmName, 'Cancel '], true, { "secondaryButtonClass": "btn-primary", style: { 'max-width': "600px", 'max-height': "50vh!important" } })
                        .then(function (selectedOption) {
                            if (selectedOption == 'Continue') {
                                getTitleProURL().done(function (data) {

                                    if (data &&
                                        !data.HasErrors &&
                                        (data.UrlAndKey !== undefined || data.UrlAndKey !== null) &&
                                        data.UrlAndKey.length > 0) {
                                        session.CheckPopUpBlock(data.UrlAndKey);
                                    } else {
                                        app.showMessage('Required URL is not configured. Please contact Administrator.',
                                            'URL not available',
                                            ['OK']);
                                    }
                                    return false;
                                });
                            }
                        });
                } else {
                    getTitleProURL().done(function (data) {

                        if (data &&
                            !data.HasErrors &&
                            (data.UrlAndKey !== undefined || data.UrlAndKey !== null) &&
                            data.UrlAndKey.length > 0) {
                            session.CheckPopUpBlock(data.UrlAndKey);
                        } else {
                            app.showMessage('Required URL is not configured. Please contact Administrator.',
                                'URL not available',
                                ['OK']);
                        };
                        return false;
                    });
                }
                return false;
            }
        };

        function getTitleProURL() {
            console.log('getTitleProURL');
            
                return https.get(config.getAccessTitleProAccount, {
                    operationId: session.defaultOpertionId(),
                    brandId: session.brandId,
                    format: 'json'
                });
          
            
        }

        return vm;
    });
