define('models/payment/ftniForm',["knockout", 'jquery', 'plugins/router'],
    function (ko, $, router) {

        function ftniForm(FormId) {
            var self = this;

            self.viewName = "payment/_ftniForm.html";
            
            self.formId = ko.observable(FormId);
            self.postURL = ko.observable("");

            self.pageTitle = ko.observable("");
            self.pageHeader = ko.observable("");
            self.orderNumber = ko.observable("");
            self.passthroughData = ko.observable("");
            self.color = ko.observable("");
            self.bgColor = ko.observable("");
            self.logoURL = ko.observable("");
            self.sessionId = ko.observable("");
            self.callbackUrl = ko.observable("");
            self.cancelUrl = ko.observable("");
            self.settlement = ko.observable("");
            self.cartAmount = ko.observable("");

            self.reset = function () {
               
            }
        }
        return ftniForm;

    });
