define('repository/propertSerchRepository',['ajaxhelper/ajaxHelper'], function (ajaxHelper) {

    function propertySearchUrl() { return '/api/PropertySearch/PropertySearch' }
   
    
    var getPropertySearch = function () {
        return ajaxHelper.ajaxRequest("get", propertySearchUrl())
        .done(getSucceeded)
                   .fail(getFailed);
        function getSucceeded(data) {
            return Q.when(data);
        }
        function getFailed() {
            return "Error Property  lists.";
        }
    };
   
    return {
        getPropertySearch: getPropertySearch,
       
    };
});

