//TODO: Inject dependencies

define('viewmodels/propertyDetailsModule/propertyDetail',['plugins/router', 'durandal/app', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'purchaseableReportShopItem'],
    function (router, app, $, ko, validate, iCheck, config, validation, Q, https, session, purchaseableReportShopItem) {

        // Internal properties and functions
        // Reveal the bindable properties and functions

        var property = ko.observable(undefined);
        var productServicesProducts = ko.observable(undefined);
        var serviceProducts = ko.observable(undefined);
        var hasGetMethodError = ko.observable(false);
        var purchaseableReportsShoppingCart = ko.observableArray([]);
        var shoppingCartLabel = ko.observable("");
        var shoppingCartEnable = ko.observable(false);
        var walletBalance = ko.observable(0);
        var creditBalance = ko.observable(0);
        var walletEnabled = ko.observable(false);
        var creditsEnabled = ko.observable(false);
        var hasPrice = ko.observable(false);
        var showPurchasableProducts = ko.observable(true);

        var vm = {
            activate: activate,
            goBack: goBack,
            title: 'Property Detail',
            property: property,
            productServicesProducts: productServicesProducts,
            serviceProducts: serviceProducts,
            hasGetMethodError: hasGetMethodError,
            getRemainingCredits: getRemainingCredits,
            downloadReport: downloadReport,
            toggleAddToCart: toggleAddToCart,
            purchaseableReportsShoppingCart: purchaseableReportsShoppingCart,
            shoppingCartLabel: shoppingCartLabel,
            shoppingCartEnable: shoppingCartEnable,
            showShoppingCart: showShoppingCart,
            formatCurrency: formatCurrency,
            createOrder: createOrder,
            walletBalance: walletBalance,
            creditBalance: creditBalance,
            walletEnabled: walletEnabled,
            creditsEnabled: creditsEnabled,
            getPriceString: getPriceString,
            getCreditString: getCreditString,
            getAddressString: getAddressString,
            hasPrice: hasPrice,
            showPurchasableProducts: showPurchasableProducts,
            compositionComplete: compositionComplete
        };

        return vm;

        function compositionComplete() {
            session.validateInput();
        }

        function activate(propertyId) {
            //TODO: Initialize lookup data here.

            //TODO: Get the data for this viewmodel, return a promise.

            var url = config.getPropertyDetailUrl;
            purchaseableReportsShoppingCart([]);

            shoppingCartLabel("Shopping Cart");
            shoppingCartEnable(false);
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                propertyId: propertyId,
                format: 'json'
            }).done(loadData)
              .fail(function () {
                  alert('Error getting property detail');
              });

            var showReportsUrl = config.showPurchasableProductsUrl;
            return https.get(showReportsUrl).done(function (data) {
                showPurchasableProducts(data);
            })
              .fail(function () {
                  //alert('Error getting property detail');
              });
        }

        function getAddressString(propertyData) {
            //console.log(propertyData);

            var addressString = '';



            if (propertyData) {

                //if (propertyData.Address1 && propertyData.Address1.length > 0)
                //    addressString = propertyData.Address1.trim() + ' ';

                //if (propertyData.Address2 && propertyData.Address2.length > 0)
                //    addressString = addressString + propertyData.Address2.trim() + ' ';


                if (propertyData.City && propertyData.City.length > 0)
                    addressString = addressString + propertyData.City.trim() + ', ';

                if (propertyData.State && propertyData.State.length > 0)
                    addressString = addressString + propertyData.State.trim() + ' ';

                if (propertyData.Zip && propertyData.Zip.length > 0)
                    addressString = addressString + propertyData.Zip.trim();

                if (propertyData.Zip4 && propertyData.Zip4.length > 0) {
                    addressString = addressString + '-' + propertyData.Zip4.trim()
                }
            }
            return addressString;

        }

        function loadData(result) {
            debugger;
            console.log(result);
            property(result.Property);
            console.log(property());

            // load to local array with additional property of added to cart or not.
            loadPurchaseableReportsToKOArray(result.ProductServicesProducts);

            walletBalance(result.WalletBalance);
            creditBalance(result.CreditBalance);
            walletEnabled(result.WalletEnabled);
            creditsEnabled(result.CreditsEnabled);
            serviceProducts(result.ServiceProducts);
            hasGetMethodError(result.HasErrors);
        }

        function loadPurchaseableReportsToKOArray(purchaseableReports) {
            var purchaseableReportsWithCartStatus = [];

            for (var i = 0; i < purchaseableReports.length; i++) {
                var item = new purchaseableReportShopItem();

                item.Name(purchaseableReports[i].Name);
                item.Price(purchaseableReports[i].Price);
                item.Credits(purchaseableReports[i].Credits);

                item.SampleURL(purchaseableReports[i].SampleUrl);
                item.DescriptionURL(purchaseableReports[i].DescriptionUrl);

                item.AddedToCart(false);

                purchaseableReportsWithCartStatus.push(item);

            }
            productServicesProducts(purchaseableReportsWithCartStatus);
        }

        function formatCurrency(value) {
            value = parseFloat(value()).toFixed(2);
            return "$" + value;
        }

        function getPriceString(value) {
            if (parseFloat(value()) == 0) {
                return "Free";
            }
            else {
                return formatCurrency(value);
            }
        }

        function getCreditString(value) {
            if (parseInt(value()) == 0) {
                return ""
            }
            else {
                return value;
            }
        }

        function toggleAddToCart(item, event) {
            if (item.AddedToCart() == false) {
                item.AddedToCart(true);
                item.index(purchaseableReportsShoppingCart().length)
                purchaseableReportsShoppingCart().push(item);

            }
            else {
                item.AddedToCart(false);
                purchaseableReportsShoppingCart().splice(item.index(), 1);
                for (var i = 0; i < purchaseableReportsShoppingCart().length; i++) {
                    purchaseableReportsShoppingCart()[i].index(i);
                }
            }

            if (purchaseableReportsShoppingCart().length > 0) {
                shoppingCartLabel("Shopping Cart (" + purchaseableReportsShoppingCart().length + ")");
                shoppingCartEnable(true);
            }
            else {
                shoppingCartLabel("Shopping Cart");
                shoppingCartEnable(false);
            }
        }

        function getRemainingCredits(used, assigned) {
            return (parseInt(assigned) - parseInt(used)) + ' of ' + assigned + ' remaining';
        }

        function downloadReport(reportItem, event) {
            console.log('selected report');
            console.log(reportItem);
            return false;
        }

        function showShoppingCart() {
            $('#mdShoppingCart').modal('show');
        }

        function createOrder() {
            router.navigate("/#orders");
        }

        function goBack(complete) {
            router.navigateBack();
        }
    });
