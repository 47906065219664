define('viewmodels/propertySearchModule/radiusSearch',['durandal/app', 'knockout', 'bootstrap', 'bootstrapSlider', 'global/session', 'propertySearchCommon'],
    function (app, ko, bootstrap, bootstrapSlider, session, propertySearchCommon) {
        "use strict";
        ko.bindingHandlers.sliderValue = {
            init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var params = valueAccessor();

                var radiusSlider = $(element).bootstrapSlider();
                // radiusSlider.bootstrapSlider('setValue', 5);

                // Check whether the value observable is either placed directly or in the paramaters object.
                if (!(ko.isObservable(params) || params['value']))
                    throw "You need to define an observable value for the sliderValue. Either pass the observable directly or as the 'value' field in the parameters.";

                // Identify the value and initialize the slider
                var valueObservable;
                if (ko.isObservable(params)) {
                    valueObservable = params;
                    //$(element).slider({ value: ko.unwrap(params) });
                    radiusSlider.bootstrapSlider({ value: ko.unwrap(params) });
                }
                else {
                    valueObservable = params['value'];
                    // Replace the 'value' field in the options object with the actual value
                    params['value'] = ko.unwrap(valueObservable);
                    //$(element).slider(params);
                    radiusSlider.bootstrapSlider(params);
                }

                // Make sure we update the observable when changing the slider value
                $(element).on('slide', function (ev) {
                    valueObservable(ev.value);
                });

            },
            update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var modelValue = valueAccessor();
                var valueObservable;
                if (ko.isObservable(modelValue))
                    valueObservable = modelValue;
                else
                    valueObservable = modelValue['value'];
                var radiusSlider = $(element).bootstrapSlider();
                radiusSlider.bootstrapSlider('setValue', valueObservable());
                //$(element).slider('setValue', valueObservable());
            }
        };

        var defaultRadius = 0.5;
        var radius = ko.observable(defaultRadius);
        var searchRequest;
        var sliderActivable = ko.observable(false);
        var sliderCtrlName;

        function initSearchParams() {
            if (searchRequest == undefined) {
                searchRequest = {
                    Fips: ko.observable(),
                    latitude: ko.observable(),
                    longitude: ko.observable(),
                    radius: ko.observable(),
                    Zip: ko.observable(),
                    State: ko.observable(),
                    County: ko.observable(),
                    Apn: ko.observable(),
                    currentRadius: ko.observable(defaultRadius)
                };
            }

            return searchRequest;
        }

        initSearchParams();
        var initCompleted = false;

        //// This event fires when the slider is dragged
        //.on(slide, callback);
        //// This event fires when dragging starts
        //.on(slideStart, callback);
        //// This event fires when the dragging stops or has been clicked on
        //.on(slideStop, callback);
        //// This event fires when the slider value has changed
        //.on(change, callback);
        //// This event fires when the slider is enabled
        //.on(slideEnabled, callback);
        //// This event fires when the slider is disabled
        //.on(slideDisabled, callback);
        var sliderCtrl;
        var radiusChangeCallbackFn;
        var eventsInitialized = false;

        //this is not the actual init but extension
        function init(sliderCtrlId, radiusChangeCallback) {
            //console.log('radiusSearch Init:');
            var dvSldr = $('#' + sliderCtrlId + 'Slider');
            sliderCtrlName = sliderCtrlId;
            initCompleted = dvSldr && dvSldr.length > 0;

            if (initCompleted == true) {
                sliderCtrl = $("#" + sliderCtrlId);
                if (sliderCtrl != undefined) {
                    var rngSlider = sliderCtrl.slider({
                        enabled: false,
                        formatter: function (value) {
                            return value + ' mi.';
                        }
                    });
                    rngSlider.unbind('slideStop');
                    rngSlider.on('slideStop', onRadiusChange);

                    initSearchParams();
                    initCompleted = true;
                    radiusChangeCallbackFn = radiusChangeCallback;
                }
            }
            if (initCompleted & session.isRadiusSearchActive() == false) {
                sliderCtrl = $("#" + sliderCtrlId);
                sliderCtrl.bootstrapSlider('disable');
            }
        };

        function onRadiusChange(sliderValue) {

            if (sliderValue != defaultRadius) {
                //console.log('onRadiusChange - ' + sliderValue.value)
                radius(sliderValue.value);
                searchRequest.currentRadius(sliderValue.value);
                if (radiusChangeCallbackFn)
                    radiusChangeCallbackFn();
            }
        };

        session.isRadiusSearchActive.subscribe(function (newVal) {
            setSliderActive(newVal);
            if (newVal == false) {
                var request = {
                    Zip: "",
                    State: "",
                    County: "",
                    Apn: "",
                    SearchType: 'APN',
                    Fips: "",
                    latitude: "",
                    longitude: "",
                    landusecode: "",
                    radius: defaultRadius
                };
                setRequestParameters(request);
            }
        });

        function isSliderObjectInitialized() {
            var dvSldr = $('#' + sliderCtrlName + 'Slider');
            var initCompleted = (dvSldr && dvSldr.length > 0);
            return initCompleted;
        }

        function setSliderActive(newValue) {
            //console.log('setSliderActive: ' + newValue);
            //var sldr = $('#radiusRangeSlider');

            var dvSldr = $('#' + sliderCtrlName + 'Slider');

            if (initCompleted === true || (dvSldr !== null && dvSldr.length > 0))
                sliderCtrl = $("#" + sliderCtrlName);

            if (sliderCtrl !== undefined && sliderCtrl.length > 0) {
                var radiusSlider = $(sliderCtrl).bootstrapSlider();
                var initComplete = isSliderObjectInitialized();
                if (initComplete === true) {
                    if (newValue === true)
                        radiusSlider.bootstrapSlider("enable");
                    else if (isRadiusSearchActive()) {
                        radiusSlider.bootstrapSlider('disable');
                    }
                }

                if (isRadiusSearchActive() === false && newValue === false) {
                    init('radiusRange', radiusChangeCallbackFn);
                    initComplete = isSliderObjectInitialized();
                    if (initComplete === true) radiusSlider.bootstrapSlider('setValue', defaultRadius);
                }
            }

            sliderActivable(newValue);

            //console.log('setSliderActive: ' + sldr != null ? sldr.hasClass('slider-disabled'):'not found');
        }

        function isRadiusSearchActive() {
            return sliderCtrl != null && sliderCtrl.is('disabled');
        }

        function setRequestParameters(request) {
            searchRequest.Fips(request.Fips);
            searchRequest.latitude(request.latitude);
            searchRequest.longitude(request.longitude);
            searchRequest.radius(request.radius);
            searchRequest.Zip(request.Zip);
            searchRequest.State(request.State);
            searchRequest.County(request.County);
            searchRequest.Apn(request.Apn);
            if (searchRequest.radius() == null || searchRequest.radius() == undefined)
                searchRequest.radius(request.radius);
            searchRequest.currentRadius(searchRequest.radius());
        };

        function canActivate() {
            ///console.log('VM - canActivate');
            return true;
        };

        function activate() {
            //console.log('VM - Activated');
            return true;
        };

        function canDeactivate(isClose) {
            //console.log('VM - canActivate');
            return true;
        };

        function deactivate(isClose) {
            //console.log('VM - deactivate');
            return true;
        };

        function refreshRangeSlider() {
            console.log('RadiusSearch - refreshRangeSlider');
            if (sliderCtrl != undefined && sliderCtrl.length > 0) {
                var radiusSlider = $(sliderCtrl).bootstrapSlider();
                init(sliderCtrlName, radiusChangeCallbackFn);
                var initComplete = isSliderObjectInitialized();
                if (initCompleted == true) radiusSlider.bootstrapSlider('refresh');
                searchRequest.currentRadius(sliderCtrl.val());
                setSliderActive(session.isRadiusSearchActive());
            }
        };
        var divVisible = ko.observable(true);
        divVisible.subscribe(function (value) {
            console.log('div visibe: ' + value);
        });

        function onRadiusLinkClick(e) {
            setSliderActive(sliderActivable());
        }

        function compositionComplete() {
            session.validateInput();
        }

        return {
            sliderCtrlName: sliderCtrlName,
            canActivate: canActivate,
            activate: activate,
            canDeactivate: canDeactivate,
            currentRadius: radius,  // used to set the control initial value only
            divVisible: divVisible,
            searchRequest: searchRequest,
            setRequestParameters: setRequestParameters,
            init: init,
            initSearchParams: initSearchParams,
            sliderCtrl: sliderCtrl,
            refreshRangeSlider: refreshRangeSlider,
            setSliderActive: setSliderActive,
            isSliderObjectInitialized: isSliderObjectInitialized,
            isRadiusSearchActive: isRadiusSearchActive,
            defaultRadius: function () { return defaultRadius; },
            sliderActivable: sliderActivable,
            onRadiusLinkClick: onRadiusLinkClick,
            compositionComplete: compositionComplete,
        };
    });
