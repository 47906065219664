define('viewmodels/externalSiteRedirect/ReiSourceRedirect',["knockout", 'plugins/http', 'global/session', 'config', 'utlities', 'plugins/router', 'durandal/app', 'alertNotification'],
    function (ko, https, session, config, utlities, router, app, alert) {
        var vm = {
            session: session,
            canActivate: function () {
                getReiSourceURL().done(function (data) {
                    if (data && !data.HasErrors && (data.ProcessedUrl !== undefined || data.ProcessedUrl !== null) &&  data.ProcessedUrl.length > 0)
                        session.CheckPopUpBlock(data.ProcessedUrl);
                    else
                        app.showMessage('Required URL is not configured. Please contact Administrator.', 'URL not available', ['OK']);

                        //alert.error("Access REiSource Leads -- No Accounts Available. Please contact Your Sales Executive");

                });
                return false;
            }
        }

        function getReiSourceURL() {
            console.log('getReiSourceURL');
            return https.get(config.getAccessReiSourceAccount, {
                operationId: session.defaultOpertionId(),
                brandId: session.brandId,
                format: 'json'
            });
        }

        return vm;
    });
