define('config',['jquery', 'jquery.utilities', 'toastr'], function ($, toastr) {
    var baseUrl = $.getBasePath();
    var propertySearchUrl = baseUrl + "api/PropertySearch/PropertySearch";
    var getPropertyLatLongUrl = baseUrl + "api/PropertySearch/GetPropertyLatLong";
    var commonOrderUrl = baseUrl + "api/Orders/SubmitCommonOrder";
    var stateUrl = baseUrl + "api/Site/GetStatesList";
    var countyUrl = baseUrl + "api/Site/GetCountiesListByState";
    var getAPNFormatUrl = baseUrl + "api/Site/GetAPNFormat";
    var getOpeartionStatesByOpeartionId = baseUrl + "api/Site/GetOperationStatesByOperationId";
    var getStatesListWithStateIdUrl = baseUrl + "api/Site/GetStatesListWithStateId";
    var getCountiesListWithCountyIdByStateIdUrl = baseUrl + "api/Site/GetCountiesListWithCountyIdByStateId";
    var getAllCountiesListWithFips = baseUrl + "api/Site/GetAllCountiesListWithFips";
    var getBkfsUserCreditInfoUrl = baseUrl + "api/Secondary/GetBkfsUserCreditInfo";
    var getIsGlobalLoginMessageEnabledUrl = baseUrl + "api/Site/GetIsGlobalLoginMessageEnabled";
    var getGlobalLoginMessageTextUrl = baseUrl + "api/Site/GetGlobalLoginMessageText";
    var getAccessTitleProAccountSlsUrl = baseUrl + "api/Secondary/AccessTitleProAccountSls";
    var getAccessPremLeadSlsInfo = baseUrl + "api/Secondary/AccessPremLeadSls";
    var isMobileLoginDisabledUrl = baseUrl + "api/Site/IsMobileLoginDisabled";
    var getOKTAUserProfileURL = baseUrl + "api/User/GetOKTAUserProfileURL";
    var sendOktaOneTimeCodeEmailUrl = baseUrl + "api/User/SendOktaOneTimeCodeEmail";
    var verifyOneTimeCodeUrl = baseUrl + "api/User/VerifyOneTimeCode";
    var registerOktaUserUrl = baseUrl + "api/User/OktaRegisterUser";
    var sendAlreadyUpgradedEmailUrl = baseUrl + "api/User/SendAlreadyUpgradedEmail";
    var getContactsVIPUrl = baseUrl + "api/Site/GetContactsVIPProfile";
    var getICloseOfficeInfoUrl = baseUrl + "api/Orders/getICloseOfficeInfo";
    var getSessionTimeoutValueUrl = baseUrl + "api/Site/getSessionTimeoutValue";
    var getFeatureVideosFolderUrl = baseUrl + "api/Site/GetFeatureVideosFolder";
    var getFeatureUpdatesUrl = baseUrl + "api/Site/GetFeatureUpdates";
    var getAllFeatureUpdatesUrl = baseUrl + "api/Site/GetAllFeatureUpdates";
    var saveFeatureUpdateUrl = baseUrl + "api/Site/SaveFeatureUpdate";
    var saveFeatureUpdateFilesUrl = baseUrl + "api/Site/SaveFeatureUpdateFiles";
    var getZipInfoUrl = baseUrl + "api/Site/GetZipInfo";
    var getParentUserListUrl = baseUrl + "api/User/GetSalesRepUserList";
    var getIsWalletAvailableForUserUrl = baseUrl + "api/Property/IsWalletAvailableForUser";
    var savePropertyProfileLanguageUrl = baseUrl + "api/Property/SavePropertyProfileLanguage";
    var getOrderFormOffices = baseUrl + "api/Orders/GetOrderFormOffices";
    var getOrderTransactionTypes = baseUrl + "api/Orders/GetOperationTransactionTypes";
    var getOrderFormOfficers = baseUrl + "api/Orders/getOrderFormOfficers";
    var getOperationsForUserUrl = baseUrl + "api/user/GetOperationsForUser";
    var addToMyPropertiesUrl = baseUrl + "api/Property/AddToMyProperties";
    var getServiceTypesUrl = baseUrl + "api/Property/GetServiceTypes";
    var getServiceNamesUrl = baseUrl + "api/Property/GetOperationServiceNames";
    var getServicesUrl = baseUrl + "api/User/GetOperationServices";
    var getPropertyDetailUrl = baseUrl + "api/Property/GetPropertyDetail";
    var getOperationUserInfoUrl = baseUrl + "api/user/GetOperationUserInfoById";
    var getWesbOperationTransactionTypes = baseUrl + "api/Orders/GetWesbOperationTransactionTypes";
    var submitMobileHomeEscrowUrl = baseUrl + "api/Orders/SubmitMobileHomeEscrow";
    var submitBulkSaleLiquorLicenseUrl = baseUrl + "api/Orders/SubmitBulkSaleLiquorLicense";
    var submitRefiLenderEscrowUrl = baseUrl + "api/Orders/SubmitRefiLenderEscrow";
    var submitResidentialEscrowUrl = baseUrl + "api/Orders/SubmitResidentialEscrow";
    var submitOfflineTitleUrl = baseUrl + "api/Orders/SubmitOfflineTitle";
    var submitOfflineFarmUrl = baseUrl + "api/Orders/SubmitOfflineFarm";
    var submitOfflinePropertyProfileUrl = baseUrl + "api/Orders/SubmitOfflinePropertyProfile";
    var submitPirtOrderUrl = baseUrl + "api/Orders/SubmitPirtOrder";
    var submitChicagoTitleOrderUrl = baseUrl + "api/Orders/SubmitChicagoTitleOrder";
    var SubmitColoradoTitleOrderUrl = baseUrl + "api/Orders/SubmitColoradoTitleOrder";
    var submitColoradoRefiLenderUrl = baseUrl + "api/Orders/SubmitColoradoRefiLender";
    var getProfileCustomSettingsForUserUrl = baseUrl + "api/Property/GetProfileCustomSettingsForUser";
    var getFinanceTypesUrl = baseUrl + "api/orders/GetFinanceTypes";
    var getPropertyProfileInfoUrl = baseUrl + "api/Property/GetProfileInfo";
    var getOperationProfileInfoUrl = baseUrl + "api/Property/GetProfileByOperation";
    var buildProfileUrl = baseUrl + "api/Property/GetEnhancedReportUrl";
    var buildProfileUrls = baseUrl + "api/Property/GetEnhancedReportUrls";

    var emailPropertyProfileReportUrl = baseUrl + "api/Property/EmailPropertyProfileReport";
    var getAvailableSectionsForReportCustomizationUrl = baseUrl + "api/Property/GetAvailableSectionsForReportCustomization";

    var showPurchasableProductsUrl = baseUrl + "api/User/ShowPurchasableProducts";

    var emailAcknowledgmentForOrderDetailsUrl = baseUrl + "api/Orders/EmailAcknowledgmentForOrderDetails";

    var getOECustomersUrl = baseUrl + "api/Orders/GetCustomers";
    var getOAndEOrderTypesUrl = baseUrl + "api/Orders/GetOAndEOrderTypes";
    var getOAndEOrderTypesPriceUrl = baseUrl + "api/Orders/GetOAndEOrderTypesWithNamePrice";
    var getOAndEOrderManagersUrl = baseUrl + "api/Orders/GetOAndEOrderManagers";
    var getColoradoOAndECartUrl = baseUrl + "api/Orders/GetColoradoOAndECart";
    var getStoredCoreLogicOrderUrl = baseUrl + "api/Orders/GetStoredCoreLogicOrder";
    
    
    var submitColoradoOandEOrderUrl = baseUrl + "api/Payment/SubmitOAndEOrder";
    var getStoredVCTransactionResponseUrl = baseUrl + "api/Payment/GetStoredVCTransactionResponse";
    var getFTNIOrderStatusResponseUrl = baseUrl + "api/Payment/GetFTNIOrderStatusResponse";
       
    
    var saveColoradoOAndEOrderUrl = baseUrl + "api/Orders/SaveColoradoOAndEOrder";
    
    var changePasswordUrl = baseUrl + "api/Account/ChangePassword";
    var changeSecretAnswerUrl = baseUrl + "api/Account/ChangeSecretQuestionAnswer";
    var questionUrl = baseUrl + "api/Account/GetQuestion";
    var getReportCustomizationForUserUrl = baseUrl + "api/Property/GetReportCustomizationOptionsForUser";
    var setReportCustomizationForUserUrl = baseUrl + "api/Property/SetReportCustomizationOptionsForUser";
    var documentAPNSearchUrl = baseUrl + "api/Property/SearchByAPN";
    var documentAddressSearchUrl = baseUrl + "api/Property/SearchByAddress";
    var documentNumberSearchUrl = baseUrl + "api/Property/NewDocumentRequest";
    var getNewDocumentUrl = baseUrl + "api/Property/GetNewDocumentUrl";
    //-------------------- User API ---------------------------------------
    var registerUserUrl = baseUrl + "api/User/RegisterUser";
    var postRequestBrandAccessUrl = baseUrl + 'api/user/ProcessBrandAccessRegistration';
    var changePasswordOnBrandRegistrationUrl = baseUrl + 'api/account/ChangePasswordOnBrandRegistration';
    var userExistUrl = baseUrl + "api/user/UserExists";
    var getUserInfoByEmailUrl = baseUrl + "api/User/GetUserInfoByEmail";
    var getUserInfoByIdUrl = baseUrl + "api/User/GetUserInfoById";
    var getRepInfobyIdUrl = baseUrl + "api/User/GetRepInfobyId";
    var getFrequentContacts = baseUrl + "api/User/GetFrequentContacts";
    var getUserProfileInfoUrl = baseUrl + "api/User/GetUserInformation";
    var updateUserProfileUrl = baseUrl + "api/User/SaveUserInformation"; //SaveUserTeamProfile
    var frequentContactsUrl = baseUrl + "api/Orders/GetFrequentContactsWithName";
    var getTeamSettingsInfoUrl = baseUrl + "api/User/GetTeamProfileByUserId";
    var updateTeamSettingsInfoUrl = baseUrl + "api/User/SaveUserTeamProfile";
    var removeTeamProfilePictureUrl = baseUrl + "api/User/RemoveTeamProfilePicture";
    var getUserManagerFullNameUrl = baseUrl + "api/User/GetUserManagerFullName";
    var saveUserImagesUrl = baseUrl + "api/User/SaveUserImages";
    var getUserImagesUrl = baseUrl + "api/User/GetUserImages";
    var getAllUserLogosUrl = baseUrl + "api/User/GetAllUserLogos";
    var updateUserImagesUrl = baseUrl + "api/User/UpdateUserImages";
    var saveFrequentContactUrl = baseUrl + "api/User/SaveFrequentContact";
    var deleteFrequentContactUrl = baseUrl + "api/User/RemoveContact";
    var getFrequentContactByIdUrl = baseUrl + "api/User/GetByFrequentContactId";
    var getFrequentContactTypesUrl = baseUrl + "api/Site/GetAllFrequentContacts";
    var getManagerProfileUrl = baseUrl + "api/User/GetUserManagerProfile";
    var saveFavoriteUrl = baseUrl + "api/User/SaveFavorite";
    var changeFavoritesOrderUrl = baseUrl + "api/User/ChangeFavoriteOrder";
    var updateUserLastActivityUrl = baseUrl + "api/User/UpdateUserLastActivity";
    var updateUserDefaultOperationURL = baseUrl + "api/User/UpdateUserDefaultOperation";
    var isUserAEmployeeURL = baseUrl + "api/User/IsUserAEmployee";
    var getUserBusCardLinksUrl = baseUrl + "api/User/GetUserBusCardLinks";
    var saveUserBusCardLinkUrl = baseUrl + "api/User/SaveUserBusCardLink";
    var changeBusCardLinksOrderUrl = baseUrl + "api/user/ChangeBusCardLinkOrder";
    var registrationsEnabledUrl = baseUrl + "api/Site/GetRegistrationEnabled";
    var getUseOktaAuthenticationUrl = baseUrl + "api/site/UseOktaAuthentication";
    var getOKTAConfigUrl = baseUrl + "api/Site/GetOKTAConfig";
    var getDocByIdUrl = baseUrl + "api/Document/GetDocById";

    //------------------------------------------------------------------------
    var getCustomersUrl = baseUrl + "api/Orders/GetCustomers";
    var searchOrdersUrl = baseUrl + "api/Orders/SearchOfflineOrders";
    var searchOnlineOrdersUrl = baseUrl + "api/Orders/GetSoftProOrders";
    var ecommerceOrderSearchUrl = baseUrl + "api/Property/EcommerceOrderSearch";
    var getOrderUrl = baseUrl + "api/Property/GetEcommerceOrderSummary";
    var getReportProductsUrl = baseUrl + "api/Property/GetReportProducts";
    var getOfflineOrderDetailsUrl = baseUrl + "api/Orders/GetOrderDetails";
    var searchAllOrdersUrl = baseUrl + "api/Orders/SearchAllOrders";

    var getGetOrderByIdURL = baseUrl + "api/Orders/GetOrderById";


    var purchasableProductProcessOrder = baseUrl + "api/Property/ProcessOrder";
    var addFundsToWalletUrl = baseUrl + "api/Property/AddFundsToWallet";

    var getOfficeLocatorStates = baseUrl + "api/OfficeLocator/GetOfficeLocatorStates";
    var getOfficeLocatorCounties = baseUrl + "api/OfficeLocator/GetOfficeLocatorCounties";
    var getOfficeLocatorOffices = baseUrl + "api/OfficeLocator/GetOfficeLocatorOffices";
    var getOfficeLocatorOfficeInformation = baseUrl + "api/OfficeLocator/GetOfficeLocatorOfficeInformation";
    var getOfficeLocatorImagesUrl = baseUrl + "api/OfficeLocator/GetOfficeImage";
    var getOfficeLocatorEmpImageUrl = baseUrl + "api/OfficeLocator/GetEmployeeImage";
    var getChatClientScriptUrl = baseUrl + "api/Secondary/GetChatClientScript";
    var getIsOAndECartEnabledUrl = baseUrl + "api/Orders/GetIsOAndECartEnabled";
    var getBKFSTPReportsUrl = baseUrl + "api/Property/GetBKFSTPReportsUrl";
    var getQuickMenuItemsUrl = baseUrl + "api/User/GetQuickMenuItems";
    var getQuickMenuCustomizeUrl = baseUrl + "api/User/GetQuickMenuItemsCustomize";
    var changeQuickMenuItemOrder = baseUrl + "api/User/ChangeQuickMenuItemOrder";
    var getAdminMenuItemsUrl = baseUrl + "api/User/GetAdminMenuItems";
    var getWebinarSiteURL = baseUrl + "api/User/GetWebinarSiteURL";
    var toggleVisible = baseUrl + "api/User/ToggleVisibilityQuickMenu";
    var sortAlphabetically = baseUrl + "api/User/SortAlphabetically";
    var getProductMenuItemsUrl = baseUrl + "api/User/GetProductMenuItems";
    var getTokenUrl = baseUrl + "api/Account/GetToken";
    var hoaSearchUrl = baseUrl + "api/Secondary/GetHoaInformationByStateIdCountyIdName";
    var getHoaInformationByHoaIdUrl = baseUrl + "api/Secondary/GetHoaInformationByHoaId";
    var hoaStateUrl = baseUrl + "api/Site/GetStatesList";
    var getUsageUrl = baseUrl + "api/Property/GetUsageDetails";
    var getCombinedServiceCreditLimtsUrl = baseUrl + "api/Property/GetCombinedServiceCreditLimts";
    var hoaExportUrl = baseUrl + "api/Secondary/HoaExport";
    var waterSewerSearchUrl = baseUrl + "api/Secondary/GetWaterSewerInformationByStateIdCountyIdDistrict";
    var getWaterSewerByIdUrl = baseUrl + "api/Secondary/GetWaterSewerInformationByWaterSewerId";
    var waterSewerExportUrl = baseUrl + "api/Secondary/WaterSewerExport";
    var getLLAByEmailUrl = baseUrl + "api/Site/GetLiabilityAgreementByEmail";
    var getLLAByUserId = baseUrl + "api/Site/GetLiabilityAgreementByUserId";
    var postLLAUrl = baseUrl + "api/Site/SaveLiabilityAgreement";
    var postUserLLAUrl = baseUrl + "api/Site/SaveUserLiabilityAgreement";
    var getCurrentUserFullAddress = baseUrl + "api/User/GetCurrentUserFullAddress";
    var getWalletBalanceUrl = baseUrl + "api/Orders/GetWalletBalance";
    var getUserTokensUrl = baseUrl + "api/Orders/GetUserTokens";
    var getDeleteCCTokenUrl = baseUrl + "api/Orders/RemoveCCToken";
    var getlegalGlossaryUrl = baseUrl + "api/Site/GetLegalGlossary";
    var getSalesExecutiveDetails = baseUrl + "api/User/GetSalesExecInfo";
    var getOfficesUrl = baseUrl + "api/Orders/GetOffices";
    var getSuccessStoriesUrl = baseUrl + "api/Site/GetSuccessStories";
    var GetUrlAndKeyForFnds = baseUrl + "api/Secondary/GetUrlAndKeyForFnds";
    var getBingMapAPIKeyUrl = baseUrl + "api/Site/GetMapConfigs";
    var getBingMapAPIKeyAnonymUrl = baseUrl + "api/Site/GetBingMapAPIKeyAnonym";
    var getSalesRepCreatedFirstTimeLoginStatus = baseUrl + "api/User/GetSalesRepCreatedFirstTimeLoginStatus";
    var getDocumentsUrl = baseUrl + "api/Secondary/GetDocuments";
    var getFoldersUrl = baseUrl + "api/Secondary/GetFolders";
    var getFolderInfoUrl = baseUrl + "api/Secondary/GetFolderInfo";
    var getPremiumLeadsOperationStatus = baseUrl + "api/Secondary/GetPremiumLeadsOperationStatus";
    var getAccessReiSourceAccount = baseUrl + "api/Secondary/AccessReiSourceAccount";
    var getAccessTitleProAccount = baseUrl + "api/Secondary/AccessTitleProAccount";
    var getGetTitleProAccountInfo = baseUrl + "api/Secondary/GetTitleProAccountInfo";
    var getGetReiSourceAccountInfo = baseUrl + "api/Secondary/GetReiSourceAccountInfo";
    var getCustomPageCultureUrl = baseUrl + "api/Secondary/GetCustomPageCulture";
    var getCustomPageCategoryUrl = baseUrl + "api/Secondary/GetCustomPageCategory";
    var getCustomPageLinkCultureUrl = baseUrl + "api/Secondary/GetCustomPageLinkCulture";
    var getTableDataUrl = baseUrl + "api/Secondary/GetTableData";
    var sendFeedbackEmail = baseUrl + "api/Site/EmailFeedbackForm";
    var getBrandOperationAdminDetails = baseUrl + "api/Site/GetBrandOperationAdminDetails";
    var getIsEz2ReadEnabledUrl = baseUrl + "api/User/IsEz2ReadEnabled";
    var getFavoritesUrl = baseUrl + "api/User/GetFavorites";
    var getBrandNameUrl = baseUrl + "api/User/GetBrandName";
    var getBrandServicesNameUrl = baseUrl + "api/Site/GetBrandServicesName";
    var getAllBrandsUrl = baseUrl + "api/Site/GetAllBrands";
    var getBrandOperationsUrl = baseUrl + "api/Site/GetBrandOperations";
    var getAllOperationsUrl = baseUrl + "api/Site/GetAllOperations";
    var getSsoUrl = baseUrl + "api/User/GetSsoUrl";
    var getManagerContactFileUrl = baseUrl + "api/User/GetManagerContactFile";
    var getVipDeliveryUrl = baseUrl + "api/Site/GetVIPDelivery";
    var saveVipDeliveryUrl = baseUrl + "api/Site/SaveVIPDelivery";
    var getWesternOfficeURL = baseUrl + "api/Site/GetWesternTitleOfficeLocatorLink";
    var deleteVipDeliveryUrl = baseUrl + "api/Site/DeleteDelivery";
    var saveVipUserInterestUrl = baseUrl + "api/Site/SaveVIPUserInterest";
    var getVIPCategoryCultureUrl = baseUrl + "api/Site/GetVIPCategoryCulture";
    var getVIPUserInterestUrl = baseUrl + "api/Site/GetVIPUserInterest";
    var getVIPProfileUrl = baseUrl + "api/Site/GetVIPProfile";
    var saveVIPProfileUrl = baseUrl + "api/Site/SaveVIPServiceProfile";
    var saveVIPContactsUrl = baseUrl + "api/Site/SaveContactVIPProfile";
    var deleteContactVIPUrl = baseUrl + "api/Site/DeleteContactsVIPProfile";
    var changeVIPContactOrderUrl = baseUrl + "api/Site/ChangeContactsOrderVIPProfile";
    var getVIPInterestByOperationUrl = baseUrl + "api/Site/GetVIPInterestByOperation";
    var getPasswordChangeAlertUrl = baseUrl + "api/User/GetPasswordChangeAlert";
    var updatePasswordHistoryUrl = baseUrl + "api/User/UpdatePasswordHistory";
    var getIsParcelOverlayEnabledUrl = baseUrl + "api/Site/GetIsParcelOverlayEnabled";
    var getPropertyInformationFromCurrentProfileUrl = baseUrl + "api/Property/GetPropertyInfoFromCurrentProfile";
    var getUserOperationRoleNamesUrl = baseUrl + "api/User/GetUserOperationRoleNames";
    var getUserBusinessCardInfo = "api/Secondary/GetUserBusinessCardDetails";
    
    var updateLocalSlsUsageUrl = "api/Secondary/UpdateLocalSlsUsage";
    var setUsageUpdatedFlagBkSlsUrl = baseUrl + "api/Secondary/SetUsageUpdatedFlagBkSls";
    var updatePasswordUsingForgotPasswordUrl = baseUrl + "api/Account/UpdatePasswordUsingForgotPassword";
    var saveAndProcessPurchasableProductUrl = baseUrl + "api/Property/SaveAndProcessPurchasableProduct";
    var getStoredECResponseUrl = baseUrl + "api/Property/GetStoredECResponse";
    var getProductPurchasesUrl = baseUrl + "api/Payment/GetProductPurchases";
    var getFTNITransactionExtractUrl = baseUrl + "api/Payment/GetFTNITransactionExtract";

    var getTCPAConsentForUserUrl = baseUrl + "api/User/GetTCPAConsentDetailsForUser";
    var userRejectedTCPAConsentUrl = baseUrl + "api/User/UserRejectedTCPAConsent";
    var getTCPAConsentStatusForUserUrl = baseUrl + "api/User/GetTCPAConsentStatusForUser";
    var userAcceptedTCPAConsentUrl = baseUrl + "api/User/UserAcceptedTCPAConsent";
    var isTCPARequiredFromRemoteServerUrl = baseUrl + "api/User/IsTCPAConsentRequiredFromRemoteServer";

    var getSiteAnnouncements = baseUrl + "api/Site/GetSiteAnnouncements";
    var getCompanyLogoUrl = baseUrl + "api/Site/GetCompanyLogo";
    var getPendingApprovalUsersURL = baseUrl + "api/User/GetPendingApprovalUsers";

    var getLastUserActivityUrl = baseUrl + "api/User/GetLastUserActivity";
    var getUserLastLoginDateUrl = baseUrl + "api/User/GetUserLastLoginDate";

    var getSalesRepByUniqueIdUrl = baseUrl + "api/User/GetSalesRepByUniqueId";
    var getBrandMobileAppLinksUrl = baseUrl + "api/Site/GetMobileAppLinks";

    var getAppSettingsUrl = baseUrl + "api/Site/GetAppSettings";
    var getFarmingAppSsoUrl = baseUrl + "api/User/GetFarmingAppSsoUrl";
    var getDocsByAddressUrl = baseUrl + "api/Reporting/GetDocsByAddress";

    var getDocsByAPNUrl = baseUrl + "api/Reporting/GetDocsByAPN";
    //GetDocsByAPN

    var getDocumentUrl = baseUrl + "api/Reporting/GetDocument";
    var getPreviousSearchDocumentsUrl = baseUrl + "api/Reporting/GetPreviousSearchDocuments";
    var getDocumentByIdUrl = baseUrl + "api/Reporting/GetRecentDocumentById";
    var getDocsBySearchIdUrl = baseUrl + "api/Reporting/GetDocsBySearchId";
    var getDocumentDetailUrl = baseUrl + "api/Reporting/GetDocumentDetail";
    var getOnlineDocsPreviousSearchUrl = baseUrl + "api/Reporting/GetOnlineDocsPreviousSearches";
    

    return {
        baseUrl: baseUrl,
        setUsageUpdatedFlagBkSlsUrl: setUsageUpdatedFlagBkSlsUrl,
        updateLocalSlsUsageUrl: updateLocalSlsUsageUrl,
        getAccessTitleProAccountSlsUrl: getAccessTitleProAccountSlsUrl,
        propertySearchUrl: propertySearchUrl,
        getPropertyLatLongUrl: getPropertyLatLongUrl,
        commonOrderUrl: commonOrderUrl,
        registerUserUrl: registerUserUrl,
        postRequestBrandAccessUrl: postRequestBrandAccessUrl,
        changePasswordOnBrandRegistrationUrl: changePasswordOnBrandRegistrationUrl,
        stateUrl: stateUrl,
        countyUrl: countyUrl,
        getAllBrandsUrl: getAllBrandsUrl,
        getBrandOperationsUrl: getBrandOperationsUrl,
        getBrandServicesNameUrl: getBrandServicesNameUrl,
        getAllOperationsUrl: getAllOperationsUrl,
        getAPNFormatUrl: getAPNFormatUrl,
        getOpeartionStatesByOpeartionId: getOpeartionStatesByOpeartionId,
        getStatesListWithStateIdUrl: getStatesListWithStateIdUrl,
        getCountiesListWithCountyIdByStateIdUrl: getCountiesListWithCountyIdByStateIdUrl,
        isMobileLoginDisabledUrl: isMobileLoginDisabledUrl,
        getAllCountiesListWithFips: getAllCountiesListWithFips,
        getSessionTimeoutValueUrl: getSessionTimeoutValueUrl,
        getZipInfoUrl: getZipInfoUrl,
        getIsWalletAvailableForUserUrl: getIsWalletAvailableForUserUrl,
        savePropertyProfileLanguageUrl: savePropertyProfileLanguageUrl,
        getServicesUrl: getServicesUrl,
        getParentUserListUrl: getParentUserListUrl,
        userExistUrl: userExistUrl,
        getICloseOfficeInfoUrl: getICloseOfficeInfoUrl,
        getFeatureVideosFolderUrl: getFeatureVideosFolderUrl,
        getIsGlobalLoginMessageEnabledUrl: getIsGlobalLoginMessageEnabledUrl,
        getGlobalLoginMessageTextUrl: getGlobalLoginMessageTextUrl,
        getFeatureUpdatesUrl: getFeatureUpdatesUrl,
        getAllFeatureUpdatesUrl: getAllFeatureUpdatesUrl,
        saveFeatureUpdateUrl: saveFeatureUpdateUrl,
        saveFeatureUpdateFilesUrl: saveFeatureUpdateFilesUrl,
        getUserInfoByEmailUrl: getUserInfoByEmailUrl,
        getUserInfoByIdUrl: getUserInfoByIdUrl,
        getRepInfobyIdUrl: getRepInfobyIdUrl,
        getFrequentContacts: getFrequentContacts,
        getOrderFormOffices: getOrderFormOffices,
        getOrderTransactionTypes: getOrderTransactionTypes,
        getWesbOperationTransactionTypes: getWesbOperationTransactionTypes,
        getOrderFormOfficers: getOrderFormOfficers,
        getOperationsForUserUrl: getOperationsForUserUrl,
        addToMyPropertiesUrl: addToMyPropertiesUrl,
        getPropertyDetailUrl: getPropertyDetailUrl,
        getServiceTypesUrl: getServiceTypesUrl,
        getServiceNamesUrl: getServiceNamesUrl,
        getOperationUserInfoUrl: getOperationUserInfoUrl,
        getUserOperationRoleNamesUrl: getUserOperationRoleNamesUrl,
        submitMobileHomeEscrowUrl: submitMobileHomeEscrowUrl,
        submitBulkSaleLiquorLicenseUrl: submitBulkSaleLiquorLicenseUrl,
        submitRefiLenderEscrowUrl: submitRefiLenderEscrowUrl,
        submitResidentialEscrowUrl: submitResidentialEscrowUrl,
        submitOfflineTitleUrl: submitOfflineTitleUrl,
        submitOfflineFarmUrl: submitOfflineFarmUrl,
        submitOfflinePropertyProfileUrl: submitOfflinePropertyProfileUrl,
        submitPirtOrderUrl: submitPirtOrderUrl,
        submitChicagoTitleOrderUrl: submitChicagoTitleOrderUrl,
        submitColoradoRefiLenderUrl: submitColoradoRefiLenderUrl,
        SubmitColoradoTitleOrderUrl: SubmitColoradoTitleOrderUrl,
        getProfileCustomSettingsForUserUrl: getProfileCustomSettingsForUserUrl,
        getFinanceTypesUrl: getFinanceTypesUrl,
        getPropertyProfileInfoUrl: getPropertyProfileInfoUrl,
        getOperationProfileInfoUrl: getOperationProfileInfoUrl,
        buildProfileUrl: buildProfileUrl,
        buildProfileUrls: buildProfileUrls,
        getAvailableSectionsForReportCustomizationUrl: getAvailableSectionsForReportCustomizationUrl,
        emailPropertyProfileReportUrl: emailPropertyProfileReportUrl,
        getUserProfileInfoUrl: getUserProfileInfoUrl,
        updateUserProfileUrl: updateUserProfileUrl,
        getOECustomersUrl: getOECustomersUrl,
        getOAndEOrderTypesUrl: getOAndEOrderTypesUrl,
        getOAndEOrderManagersUrl: getOAndEOrderManagersUrl,
        submitColoradoOandEOrderUrl: submitColoradoOandEOrderUrl,
        saveColoradoOAndEOrderUrl: saveColoradoOAndEOrderUrl,
        documentAPNSearchUrl: documentAPNSearchUrl,
        documentAddressSearchUrl: documentAddressSearchUrl,
        documentNumberSearchUrl: documentNumberSearchUrl,
        getNewDocumentUrl: getNewDocumentUrl,
        changePasswordUrl: changePasswordUrl,
        changeSecretAnswerUrl: changeSecretAnswerUrl,
        getReportCustomizationForUserUrl: getReportCustomizationForUserUrl,
        setReportCustomizationForUserUrl: setReportCustomizationForUserUrl,
        frequentContactsUrl: frequentContactsUrl,
        saveFrequentContactUrl: saveFrequentContactUrl,
        getFrequentContactByIdUrl: getFrequentContactByIdUrl,
        deleteFrequentContactUrl: deleteFrequentContactUrl,
        getFrequentContactTypesUrl: getFrequentContactTypesUrl,
        getTeamSettingsInfoUrl: getTeamSettingsInfoUrl,
        updateTeamSettingsInfoUrl: updateTeamSettingsInfoUrl,
        removeTeamProfilePictureUrl: removeTeamProfilePictureUrl,
        saveUserImagesUrl: saveUserImagesUrl,
        getUserImagesUrl: getUserImagesUrl,
        getAllUserLogosUrl: getAllUserLogosUrl,
        updateUserImagesUrl: updateUserImagesUrl,
        getUserBusCardLinksUrl: getUserBusCardLinksUrl,
        saveUserBusCardLinkUrl: saveUserBusCardLinkUrl,
        changeBusCardLinksOrderUrl: changeBusCardLinksOrderUrl,
        searchOrdersUrl: searchOrdersUrl,
        searchOnlineOrdersUrl: searchOnlineOrdersUrl,
        searchAllOrdersUrl: searchAllOrdersUrl,
        getCustomersUrl: getCustomersUrl,
        ecommerceOrderSearchUrl: ecommerceOrderSearchUrl,
        getUserManagerFullNameUrl: getUserManagerFullNameUrl,
        getOrderUrl: getOrderUrl,
        getReportProductsUrl: getReportProductsUrl,
        getOfflineOrderDetailsUrl: getOfflineOrderDetailsUrl,
        getOfficeLocatorStates: getOfficeLocatorStates,
        getOfficeLocatorCounties: getOfficeLocatorCounties,
        getOfficeLocatorOffices: getOfficeLocatorOffices,
        getOfficeLocatorOfficeInformation: getOfficeLocatorOfficeInformation,
        getOfficeLocatorImagesUrl: getOfficeLocatorImagesUrl,
        getOfficeLocatorEmpImageUrl: getOfficeLocatorEmpImageUrl,
        purchasableProductProcessOrder: purchasableProductProcessOrder,
        addFundsToWalletUrl: addFundsToWalletUrl,
        getChatClientScriptUrl: getChatClientScriptUrl,
        getIsOAndECartEnabledUrl: getIsOAndECartEnabledUrl,
        getOAndEOrderTypesPriceUrl: getOAndEOrderTypesPriceUrl,
        getQuickMenuItemsUrl: getQuickMenuItemsUrl,
        getAdminMenuItemsUrl: getAdminMenuItemsUrl,
        questionUrl: questionUrl,
        getTokenUrl: getTokenUrl,
        hoaSearchUrl: hoaSearchUrl,
        getHoaInformationByHoaIdUrl: getHoaInformationByHoaIdUrl,
        hoaStateUrl: hoaStateUrl,
        getUsageUrl: getUsageUrl,
        getCombinedServiceCreditLimtsUrl: getCombinedServiceCreditLimtsUrl,
        hoaExportUrl: hoaExportUrl,
        waterSewerSearchUrl: waterSewerSearchUrl,
        getWaterSewerByIdUrl: getWaterSewerByIdUrl,
        waterSewerExportUrl: waterSewerExportUrl,
        getLLAByEmailUrl: getLLAByEmailUrl,
        getLLAByUserId: getLLAByUserId,
        postLLAUrl: postLLAUrl,
        postUserLLAUrl: postUserLLAUrl,
        getCurrentUserFullAddress: getCurrentUserFullAddress,
        getWalletBalanceUrl: getWalletBalanceUrl,
        getUserTokensUrl: getUserTokensUrl,
        getDeleteCCTokenUrl: getDeleteCCTokenUrl,
        getBKFSTPReportsUrl: getBKFSTPReportsUrl,
        getlegalGlossaryUrl: getlegalGlossaryUrl,
        getSalesExecutiveDetails: getSalesExecutiveDetails,
        getOfficesUrl: getOfficesUrl,
        getSuccessStoriesUrl: getSuccessStoriesUrl,
        GetUrlAndKeyForFnds: GetUrlAndKeyForFnds,
        getManagerProfileUrl: getManagerProfileUrl,
        getBingMapAPIKeyUrl: getBingMapAPIKeyUrl,
        getBingMapAPIKeyAnonymUrl: getBingMapAPIKeyAnonymUrl,
        getSalesRepCreatedFirstTimeLoginStatus: getSalesRepCreatedFirstTimeLoginStatus,
        getDocumentsUrl: getDocumentsUrl,
        getFoldersUrl: getFoldersUrl,
        getFolderInfoUrl: getFolderInfoUrl,
        getCustomPageCultureUrl: getCustomPageCultureUrl,
        getCustomPageCategoryUrl: getCustomPageCategoryUrl,
        getCustomPageLinkCultureUrl: getCustomPageLinkCultureUrl,
        getPremiumLeadsOperationStatus: getPremiumLeadsOperationStatus,
        getAccessReiSourceAccount: getAccessReiSourceAccount,
        getAccessTitleProAccount: getAccessTitleProAccount,
        getAccessPremLeadSlsInfo: getAccessPremLeadSlsInfo,
        getGetTitleProAccountInfo: getGetTitleProAccountInfo,
        getGetReiSourceAccountInfo: getGetReiSourceAccountInfo,
        getTableDataUrl: getTableDataUrl,
        sendFeedbackEmail: sendFeedbackEmail,
        getBrandOperationAdminDetails: getBrandOperationAdminDetails,
        getIsEz2ReadEnabledUrl: getIsEz2ReadEnabledUrl,
        getFavoritesUrl: getFavoritesUrl,
        saveFavoriteUrl: saveFavoriteUrl,
        getBrandNameUrl: getBrandNameUrl,
        getProductMenuItemsUrl: getProductMenuItemsUrl,
        changeQuickMenuItemOrder: changeQuickMenuItemOrder,
        toggleVisible: toggleVisible,
        sortAlphabetically: sortAlphabetically,
        getSsoUrl: getSsoUrl,

        getQuickMenuCustomizeUrl: getQuickMenuCustomizeUrl,
        getManagerContactFileUrl: getManagerContactFileUrl,
        getVipDeliveryUrl: getVipDeliveryUrl,
        saveVipDeliveryUrl: saveVipDeliveryUrl,
        deleteVipDeliveryUrl: deleteVipDeliveryUrl,
        saveVipUserInterestUrl: saveVipUserInterestUrl,
        getVIPProfileUrl: getVIPProfileUrl,
        saveVIPProfileUrl: saveVIPProfileUrl,
        getContactsVIPUrl: getContactsVIPUrl,
        saveVIPContactsUrl: saveVIPContactsUrl,
        deleteContactVIPUrl: deleteContactVIPUrl,
        changeVIPContactOrderUrl: changeVIPContactOrderUrl,
        getVIPCategoryCultureUrl: getVIPCategoryCultureUrl,
        getVIPUserInterestUrl: getVIPUserInterestUrl,
        getVIPInterestByOperationUrl: getVIPInterestByOperationUrl,
        changeFavoritesOrderUrl: changeFavoritesOrderUrl,
        getPasswordChangeAlertUrl: getPasswordChangeAlertUrl,
        showPurchasableProductsUrl: showPurchasableProductsUrl,
        updatePasswordHistoryUrl: updatePasswordHistoryUrl,
        getIsParcelOverlayEnabledUrl: getIsParcelOverlayEnabledUrl,
        getWesternOfficeURL: getWesternOfficeURL,
        getWebinarSiteURL: getWebinarSiteURL,
        updateUserDefaultOperationURL: updateUserDefaultOperationURL,
        isUserAEmployeeURL: isUserAEmployeeURL,
        registrationsEnabledUrl: registrationsEnabledUrl,
        getGetOrderByIdURL: getGetOrderByIdURL,
        getPropertyInformationFromCurrentProfileUrl: getPropertyInformationFromCurrentProfileUrl,
        getUserBusinessCardInfo: getUserBusinessCardInfo,
        emailAcknowledgmentForOrderDetailsUrl: emailAcknowledgmentForOrderDetailsUrl,
        updatePasswordUsingForgotPasswordUrl: updatePasswordUsingForgotPasswordUrl,
        getStoredVCTransactionResponseUrl: getStoredVCTransactionResponseUrl,
        getFTNIOrderStatusResponseUrl: getFTNIOrderStatusResponseUrl,
        saveAndProcessPurchasableProductUrl: saveAndProcessPurchasableProductUrl,
        getStoredECResponseUrl: getStoredECResponseUrl,
        getColoradoOAndECartUrl: getColoradoOAndECartUrl,
        getStoredCoreLogicOrderUrl: getStoredCoreLogicOrderUrl,
        getProductPurchasesUrl: getProductPurchasesUrl,
        getFTNITransactionExtractUrl: getFTNITransactionExtractUrl,
        getTCPAConsentForUserUrl: getTCPAConsentForUserUrl,
        userRejectedTCPAConsentUrl: userRejectedTCPAConsentUrl,
        getTCPAConsentStatusForUserUrl: getTCPAConsentStatusForUserUrl,
        userAcceptedTCPAConsentUrl: userAcceptedTCPAConsentUrl,
        isTCPARequiredFromRemoteServerUrl: isTCPARequiredFromRemoteServerUrl,
        getSiteAnnouncements: getSiteAnnouncements,
        getCompanyLogoUrl: getCompanyLogoUrl,
        getPendingApprovalUsersURL: getPendingApprovalUsersURL,
        getUseOktaAuthenticationUrl: getUseOktaAuthenticationUrl,
        getOKTAConfigUrl: getOKTAConfigUrl,
        getDocByIdUrl: getDocByIdUrl,
        getOKTAUserProfileURL: getOKTAUserProfileURL,
        sendOktaOneTimeCodeEmailUrl: sendOktaOneTimeCodeEmailUrl,
        verifyOneTimeCodeUrl: verifyOneTimeCodeUrl,
        registerOktaUserUrl: registerOktaUserUrl,

        getLastUserActivityUrl: getLastUserActivityUrl,
        getUserLastLoginDateUrl: getUserLastLoginDateUrl,
        getSalesRepByUniqueIdUrl: getSalesRepByUniqueIdUrl,
        getBrandMobileAppLinksUrl: getBrandMobileAppLinksUrl,
        getAppSettingsUrl: getAppSettingsUrl,
        getFarmingAppSsoUrl: getFarmingAppSsoUrl,
        getDocsByAddressUrl: getDocsByAddressUrl, 
        getDocsByAPNUrl: getDocsByAPNUrl,
        getDocumentUrl: getDocumentUrl,
        getPreviousSearchDocumentsUrl: getPreviousSearchDocumentsUrl,
        getDocumentByIdUrl: getDocumentByIdUrl,
        getDocsBySearchIdUrl: getDocsBySearchIdUrl,
        getDocumentDetailUrl: getDocumentDetailUrl,
        getOnlineDocsPreviousSearchUrl: getOnlineDocsPreviousSearchUrl,
        // toastr property setting
        toastr: {
            positionClass: 'toast-top-center',
            extendedTimeOut: 1000,
            fadeIn: 'slow',
            fadeOut: 'slow',
            timeOut: 5000,
            showEasing: 'swing',
            hideEasing: 'swing',
            closeButton: true
        }
    };

});
