define('viewmodels/waterSewer/waterSewer',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'knockout.validation', 'plugins/http', 'global/session', 'config', 'alertNotification', 'commonDialogModel', 'waterSewerModel', 'utlities', 'koPaging'],
    function (app, router, $, ko, validate, validation, https, session, config, alert, commonDialog, model, utlities, koPaging) {
        var waterSewerSearchModel = new model.waterSewerSearch();
        var waterSewerResultsModel = new model.waterSewerResults();
        var waterSewerDetailsModel = new model.waterSewerDetails();
        var searchResults = ko.observableArray([]);
        searchResults.extend({
            paged: {
                pageSize: 15,
                pageGenerator: 'default'
                //onlyIf: function () {
                //    return searchResults().length > 15;
                //}
            }
        });
        function activate() {
            clearSearch();
            return;
        }

        function clearSearch() {
            searchResults([]);
            waterSewerSearchModel.reset();
        }

        function searchWaterSewerInfo() {
            var url = config.waterSewerSearchUrl;
            https.get(url, {
                OperationId: session.defaultOpertionId(),
                stateId: waterSewerSearchModel.state(),
                countyId: waterSewerSearchModel.county(),
                district: waterSewerSearchModel.district().trim(),
                format: 'json'
            }).then(function (data) {
                searchResults([]);
                var converted = loadResultToKOArray(data);
                searchResults(converted);
                if (searchResults().length > 0)
                    searchResults.toFirstPage();
                waterSewerSearchModel.isPageReset(false);
                var exportSearchResultURL = config.waterSewerExportUrl;
                waterSewerSearchModel.exportSearchResult(exportSearchResultURL + '?OperationId=' + session.defaultOpertionId() + '&stateId=' + waterSewerSearchModel.state() + '&countyId=' + waterSewerSearchModel.county() + '&district=' + waterSewerSearchModel.district() + '&access_token=' + sessionStorage["accessToken"]);
            });
        }

        function loadResultToKOArray(data) {
            var results = [];
            for (var i = 0; i < data.length; i++) {
                waterSewerResultsModel = new model.waterSewerResults();
                waterSewerResultsModel.waterSewerId(data[i].WaterSewerId)
                waterSewerResultsModel.district(data[i].District)
                waterSewerResultsModel.managementCompany(data[i].ManagementCompany)
                waterSewerResultsModel.city(data[i].City);
                results.push(waterSewerResultsModel);
            }
            return results;
        }

        function waterSewerDetailPopup() {
            $('#mdWaterSewerDetail').modal('show');
        }

        function getSelectedItemDetails(selectedItem, event) {
            return getWaterSewerDetails(selectedItem.waterSewerId()).done(function (result) {
                waterSewerDetailsModel.district(result.WaterSewer.District);
                waterSewerDetailsModel.managementCompany(result.WaterSewer.ManagementCompany)
                waterSewerDetailsModel.contactPerson(result.WaterSewer.ContactPerson)
                waterSewerDetailsModel.streetAddress1(result.WaterSewer.StreetAddress1)
                waterSewerDetailsModel.streetAddress2(result.WaterSewer.StreetAddress2)
                waterSewerDetailsModel.city(result.WaterSewer.City)
                waterSewerDetailsModel.zip(result.WaterSewer.Zip)
                waterSewerDetailsModel.state(result.State)
                waterSewerDetailsModel.county(result.County)
                waterSewerDetailsModel.phone(result.WaterSewer.Phone)
                waterSewerDetailsModel.fax(result.WaterSewer.Fax)
                waterSewerDetailsModel.websiteURL(result.WaterSewer.WebSiteUrl)
                waterSewerDetailsModel.notes(result.WaterSewer.Notes)
                waterSewerDetailPopup();
            });

        }
        function getWaterSewerDetails(selectedItem) {
            var url = config.getWaterSewerByIdUrl;
            return https.get(url, {
                wsId: selectedItem,
                format: 'json'
            });
        }

        function compositionComplete() {
            session.validateInput();
        }

        var vm = {
            activate: activate,
            compositionComplete: compositionComplete,
            session: session,
            waterSewerSearchModel: waterSewerSearchModel,
            waterSewerResultsModel: waterSewerResultsModel,
            waterSewerDetailsModel: waterSewerDetailsModel,
            searchWaterSewerInfo: searchWaterSewerInfo,
            getSelectedItemDetails: getSelectedItemDetails,
            searchResults: searchResults,
            getUSPhoneFormat: utlities.getUSPhoneFormat,
            clearSearch: clearSearch,
        }
        return vm;
    });
