define('viewmodels/premiumLeads/premiumLeads',['plugins/router', 'durandal/app', 'knockout', 'services/logger', 'global/session', 'header'],
 function (router, app, ko, logger, session, header) {

     var premiumLeadsTitleBox = 'premiumLeads/_premiumLeadsTitle.html';
     var premiumLeadsItemsSection = 'premiumLeads/_premiumLeadsItemsSection.html';

     var currentStep = ko.observable(premiumLeadsTitleBox);


     var vm = {
         activate: activate,
         goBack: goBack,
         bindingComplete: setupMetisMenu,
         currentStep: currentStep,
         getStartedNow: getStartedNow,
         session: session,
         checkLLAgreement: checkLLAgreement,
         checkLLAgreementWithREiSourceAccount:checkLLAgreementWithREiSourceAccount,
         title: 'Premium Leads',
         canDeactivate: cleanUP,
         compositionComplete: initTopSearch
     };

     return vm;

     function getStartedNow() {
         currentStep(premiumLeadsItemsSection);
     }


     function activate() {
         //TODO: Initialize lookup data here.

         //TODO: Get the data for this viewmodel, return a promise.
         console.log("Showing Premium Leads page");
         AfterLoad();
         currentStep(premiumLeadsTitleBox);
     }

     function cleanUP() {
         return true;
     }

    

     function AfterLoad() {
         if ($('#hdnIsLogin').val() == "true") {
             $('#page-wrapper').attr('style', 'margin-left:220!important');
         }
     }

     function goBack() {
         router.navigateBack();
     }

     function checkLLAgreement() {
         if (session.llaAccepted()) {
             return true;
         }
         else {
             session.showLLAAgreement();
             return false;
         }
     }

     function checkLLAgreementWithREiSourceAccount() {
         if (!session.hasAccessReiSourceAccount()) {
             session.noREiSourceAccount();
         }
         if (session.llaAccepted()) {
             return true;
         }
         else {
             session.showLLAAgreement();
             return false;
         }
     }

     function setupMetisMenu() {
         console.log('setupMetisMenu() called');
         console.log($('.sidebar-collapse').length);

         //$('.sidebar-collapse').slimScroll({
         //    height: '550px',
         //    railOpacity: 0.9
         //});
         
         //$('.slimScrollDiv').attr('style', 'position:absolute!important');
         $('.slimScrollDiv').attr('style', 'width:100%;');

         $('.slimScrollDiv').addClass('repositionSearch');

         $('#side-menu').metisMenu();
         

         //$('.navbar-minimalize').click(function () {
         //    $("body").toggleClass("mini-navbar");
         //    SmoothlyMenu();
         //    return false;
         //});
     }

     function initTopSearch() {
         session.validateInput();
         session.showTopSearchControl(true);
         session.headerSearchText('');
         setTimeout(header.initAutoSuggest(), 200);
     }
 });
