define('propertyProfileService',['knockout', 'config', 'Q', 'plugins/http', 'global/session', 'nameValue', 'propertyProfileModel'],
function (ko, config, Q, https, session, nameValue, propertyProfileModel) {

    function getPropertyProfileInfo(fips, apn, profileId) {
        var url = config.getPropertyProfileInfoUrl;
        return https.get(url, {
            userId: session.userId,
            operationId: session.defaultOpertionId(),
            fips: fips,
            apn: apn,
            profileId: profileId,
            format: 'json'
        });
    }

    function getProfileByOperation(fips, apn, profileId) {
        var url = config.getOperationProfileInfoUrl;
        return https.get(url, {
            userId: session.userId,
            operationId: session.defaultOpertionId(),
            fips: fips,
            apn: apn,
            profileId: profileId,
            format: 'json'
        });
    }

    function loadDataToModel(result, fips, apn, profileId, customSettings) {
        var response = new propertyProfileModel.PropertyProfile();

        response.resultStatus(result.ResultStatus);
        response.profileId(result.ProfileId);
        response.property(result.Property);
        //load to local array.
        response.availableSections(loadToKOArray(result.AvailableSections).slice(0));
        response.availableReportTypes(loadToKOArray(result.AvailableReportTypes).slice(0));
        return response;
    }

    //load to local array.
    function loadToKOArray(webAPICollectionObject) {
        var mappedLocalKOArray = ko.observableArray([]);

        for (var i = 0; i < webAPICollectionObject.length; i++) {
            var item = new nameValue(); //nameValue = a common model
            item.Name(webAPICollectionObject[i].Name);
            item.Value(webAPICollectionObject[i].Value);
            item.Index(webAPICollectionObject[i].Index);
            item.ProfileURL(webAPICollectionObject[i].ProfileURL);
            item.CheckBoxValue(webAPICollectionObject[i].CheckBoxValue);
                item.Language(webAPICollectionObject[i].Language);
            item.LanguageCode((webAPICollectionObject[i].ReportType != null && webAPICollectionObject[i].ReportType.indexOf('Profile') > -1 && (webAPICollectionObject[i].LanguageCode == null || webAPICollectionObject[i].LanguageCode == 0)) ? '-' : webAPICollectionObject[i].LanguageCode);
            item.ReportType(webAPICollectionObject[i].ReportType);
            mappedLocalKOArray.push(item);
        }
        return mappedLocalKOArray;
    }

    function buildProfileURL(apn, fips, profileId, viewId) {
        var url = config.buildProfileUrl;
        return https.get(url, {
            apn: apn,
            fips: fips,
            view: viewId,
            format: 'json'
        });
    }

    function buildProfileURLs(apn, fips) {
        var url = config.buildProfileUrls;
        return https.get(url, {
            apn: apn,
            fips: fips,
            format: 'json'
        });
    }

    function getAvailableSectionsForReportCustomization(fips, apn) {
        var url = config.getAvailableSectionsForReportCustomizationUrl;
        return https.get(url, {
            fips: fips,
            apn: apn,
            format: 'json'
        });
    }

    function getReportCustomizationForUser(userId) {
        var url = config.getReportCustomizationForUserUrl;
        return https.get(url, {
            userId: session.userId,
            format: 'json'
        })
    }
    function setReportCustomizationForUser(masklist) {
        var url = config.setReportCustomizationForUserUrl;

        return https.post(url, {
            UserId: session.userId,
            MaskList: masklist
        }).then(function (data) {
        });
    }

    function savePropertyProfileLanguage(lang) {
        var url = config.savePropertyProfileLanguageUrl;
        return https.post(url +'?langCode='+ lang);
    }
    var propertyProfileService = {
        getPropertyProfileInfo: getPropertyProfileInfo,
        getProfileByOperation: getProfileByOperation,
        buildProfileURL: buildProfileURL,
        buildProfileURLs: buildProfileURLs,
        getReportCustomizationForUser: getReportCustomizationForUser,
        setReportCustomizationForUser: setReportCustomizationForUser,
        loadDataToModel: loadDataToModel,
        loadToKOArray: loadToKOArray,
        getAvailableSectionsForReportCustomization: getAvailableSectionsForReportCustomization,
        savePropertyProfileLanguage: savePropertyProfileLanguage
    };

    return propertyProfileService;
});
