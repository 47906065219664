define('viewmodels/orderModule/mobileHomeEscrow',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'commonOrderModel', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'commonDialogModel', 'mobileHomeEscrowModel', 'customerInformationModel', 'utlities',  'commonOrderModel'],
 function (app, router, $, ko, validate, iCheck, commonOrderModel, config, validation, Q, https, session, alert, commonDialog, mobileHomeEscrowModel, CustomerInformation, utlities, commonOrderModel) {
     var customerInformationModel = new CustomerInformation();
     var escrowInformationModel = new mobileHomeEscrowModel.EscrowInformation();
     var mobileHomeSellerInfoModel = new mobileHomeEscrowModel.MobileHomeSellerInfo();
     var mobileHomeBuyerInfoModel = new mobileHomeEscrowModel.MobileHomeBuyerInfo();
     var mobileHomeListingAgentInfoModel = new mobileHomeEscrowModel.MobileHomeListingAgentInfo();
     var mobileHomeSellingAgentInfoModel = new mobileHomeEscrowModel.MobileHomeSellingAgentInfo();
     var mobileHomeLegalOwnerInfoModel = new mobileHomeEscrowModel.MobileHomeLegalOwnerInfo();
     var mobileHomeNewLenderInfoModel = new mobileHomeEscrowModel.MobileHomeNewLenderInfo();
     var loanInformationModel = new mobileHomeEscrowModel.LoanInformation();
     var closingCostsModel = new mobileHomeEscrowModel.ClosingCosts();
     var prorationsModel = new mobileHomeEscrowModel.Prorations();
     var submitOrderModel = new commonOrderModel.SubmitOrder();
     var propertyInfoPopulateFromSession = ko.observable(false);

     var commonDialogModel = new commonDialog("orderSubmitDialog");
     var orderSubmitted = ko.observable(false);
     var orderTitle = ko.observable('Mobile Home Escrow');
     var escrowPropertyAddress = ko.observable('');

     var currentView = ko.observable('');
     var currentOrderModels = ko.observableArray([]);
     var currentViewIndex = ko.observable(-1);
     var currentOrderModel = ko.observable();
     var selectedOrderType = ko.observable(0);
     var selectedOrderTypeName = ko.observable('');
     var userProfile = ko.observableArray();
     var stateList = ko.observableArray();
     var countyList = ko.observableArray();

     var recentSearches = ko.observable(undefined);
     var recentSearchSelectedAPN = ko.observable("");
     var propertyInfoPopulateFromRecentSearch = ko.observable(false);

     currentOrderModel.subscribe(function (model) {
         vm.validationErrors = ko.validation.group([model]).extend({
             notify: 'always'
         });
         currentView(model.viewName);
         selectedOrderTypeName(model.title);
         session.handleFocusOnTabChange(model.ModelId, model.viewName);
     });

     currentViewIndex.subscribe(function (index) {
         currentOrderModel(currentOrderModels()[index]);
     });

     escrowInformationModel.year.subscribe(function (val) {
         if (val == "0000") {
             alert.error("Year 0000 should not be allowed");
             escrowInformationModel.year('');
         }
     });
     var vm = {
         activate: activate,
         canDeactivate: canDeactivate,
         goBack: goBack,
         title: 'Mobile Home Escrow',
         orderTitle: orderTitle,
         currentView: currentView,
         currentOrderModels: currentOrderModels,
         currentViewIndex: currentViewIndex,
         currentOrderModel: currentOrderModel,
         selectedOrderTypeName: selectedOrderTypeName,
         moveNext: moveNext,
         movePrevious: movePrevious,
         submitOrder: submitOrder,
         navigateButtonClick: navigateButtonClick,
         userProfile: userProfile,
         commonDialogModel: commonDialogModel,
         customerInformationModel: customerInformationModel,
         escrowInformationModel: escrowInformationModel,
         mobileHomeSellerInfoModel: mobileHomeSellerInfoModel,
         mobileHomeBuyerInfoModel: mobileHomeBuyerInfoModel,
         mobileHomeListingAgentInfoModel: mobileHomeListingAgentInfoModel,
         mobileHomeSellingAgentInfoModel: mobileHomeSellingAgentInfoModel,
         mobileHomeLegalOwnerInfoModel: mobileHomeLegalOwnerInfoModel,
         mobileHomeNewLenderInfoModel: mobileHomeNewLenderInfoModel,
         loanInformationModel: loanInformationModel,
         closingCostsModel: closingCostsModel,
         prorationsModel: prorationsModel,
         submitOrderModel: submitOrderModel,
         getUSPhoneFormat: utlities.getUSPhoneFormat,
         getUSPhoneFormatWithExtn: utlities.getUSPhoneFormatWithExtn,
         session: session,
         compositionComplete: compositionComplete,
         escrowPropertyAddress: escrowPropertyAddress,
         recentSearches: recentSearches,
         recentResult_click: recentResult_click,
         recentSearchSelectedAPN: recentSearchSelectedAPN,
         clearSelectedProperty: clearSelectedProperty,

     }
     return vm;

     function compositionComplete() {
         
             session.handleFocusOnTabChange(customerInformationModel.ModelId, customerInformationModel.viewName);
         
         if (session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null && session.orderForPropertyAddress.city() != undefined && session.orderForPropertyAddress.city() != null && session.orderForPropertyAddress.city() != "") {
             propertyInfoPopulateFromSession(true);

             var propertyAddress = "";
             if (session.orderForPropertyAddress.address() != undefined && session.orderForPropertyAddress.address() != null && session.orderForPropertyAddress.address() != "null" && session.orderForPropertyAddress.address() != "")
                 propertyAddress = session.orderForPropertyAddress.address();
             if (session.orderForPropertyAddress.city() != undefined && session.orderForPropertyAddress.city() != null && session.orderForPropertyAddress.city() != "null" && session.orderForPropertyAddress.city() != "")
                 propertyAddress = propertyAddress + ',\n' + session.orderForPropertyAddress.city();
             if (session.orderForPropertyAddress.county() != undefined && session.orderForPropertyAddress.county() != null && session.orderForPropertyAddress.county() != "null" && session.orderForPropertyAddress.county() != "")
                 propertyAddress = propertyAddress + ',\n' + session.orderForPropertyAddress.county();
             if (session.orderForPropertyAddress.state() != undefined && session.orderForPropertyAddress.state() != null && session.orderForPropertyAddress.state() != "null" && session.orderForPropertyAddress.state() != "")
                 propertyAddress = propertyAddress + ',\n' + session.orderForPropertyAddress.state();
             if (session.orderForPropertyAddress.zip() != undefined && session.orderForPropertyAddress.zip() != null && session.orderForPropertyAddress.zip() != "null" && session.orderForPropertyAddress.zip() != "")
                 propertyAddress = propertyAddress + ' ' + session.orderForPropertyAddress.zip();

             //var propertyAddress = session.orderForPropertyAddress.address() + ',\n' + session.orderForPropertyAddress.city() + ',\n' + session.orderForPropertyAddress.county() + ',\n' + session.orderForPropertyAddress.state() + ' ' + session.orderForPropertyAddress.zip();
             // escrowPropertyAddress(propertyAddress);
             escrowInformationModel.propertyAddress(propertyAddress);
         }
         else {
         }
     }


     function activate(orderType) {
         orderSubmitted(false);
         recentSearches(session.getRecentSearchesFromLocalStorage());
         selectedOrderType(parseInt(orderType));
         setupOrder();
         if (session.userName() != undefined && session.userName() != '') {
             getCustomerInfo(session.userId, session.defaultOpertionId());
         }
         //utlities.getStatesList().done(function (result) {
         //    stateList([]);
         //    stateList(result);

         //});
         currentViewIndex(0);
         session.isBusy(false);
     }

     function recentResult_click(result) {
         recentSearchSelectedAPN(result.APN);
         var propertyAddress = "";
         if (result.Address != undefined && result.Address != null && result.Address != "null" && result.Address != "")
             propertyAddress = result.Address;
         if (result.City != undefined && result.City != null && result.City != "null" && result.City != "")
             propertyAddress = propertyAddress + ',\n' + result.City;
         if (result.County != undefined && result.County != null && result.County != "null" && result.County != "")
             propertyAddress = propertyAddress + ',\n' + result.County;
         if (result.State != undefined && result.State != null && result.State != "null" && result.State != "")
             propertyAddress = propertyAddress + ',\n' + result.State;
         if (result.ZIP != undefined && result.ZIP != null && result.ZIP != "null" && result.ZIP != "")
             propertyAddress = propertyAddress + ' ' + result.ZIP.slice(0, 5);

         escrowInformationModel.propertyAddress(propertyAddress);
     }
     function clearSelectedProperty() {
         recentSearchSelectedAPN('');
         escrowInformationModel.propertyAddress("");
     }

     function canDeactivate() {
         if (!session.hasLoggedOut() && !orderSubmitted()) {
             var title = 'Cancel Order?';
             var msg = 'Navigate away and cancel the order? ';
             session.isLogoutVisible(false);
             return app.showMessage(msg, title, ['Yes', 'No '])
                 .then(function (selectedOption) {
                     if (selectedOption === 'Yes') {
                         // Call some cancel function
                         recentSearchSelectedAPN('');
                         session.clearOrderForPropertyAddress();
                     }
                     session.isLogoutVisible(true);
                     return selectedOption;
                 });
         }
         else {
             recentSearchSelectedAPN('');
             session.clearOrderForPropertyAddress();
             return true;
         }
     };

     function setupOrder() {
         currentOrderModels([]);

         currentOrderModels.push(customerInformationModel);
         currentOrderModels.push(escrowInformationModel);
         currentOrderModels.push(mobileHomeSellerInfoModel);
         currentOrderModels.push(mobileHomeBuyerInfoModel);
         currentOrderModels.push(mobileHomeListingAgentInfoModel);
         currentOrderModels.push(mobileHomeSellingAgentInfoModel);
         currentOrderModels.push(mobileHomeLegalOwnerInfoModel);
         currentOrderModels.push(mobileHomeNewLenderInfoModel);
         currentOrderModels.push(loanInformationModel);
         currentOrderModels.push(closingCostsModel);
         currentOrderModels.push(prorationsModel);
         currentOrderModels.push(submitOrderModel);
         resetModels();
     }

     function moveNext() {
         // commented for development, this need to be removed.
         vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
         if (vm.validationErrors().length > 0) {
             vm.validationErrors.showAllMessages();
             return;
         }
         console.log(escrowInformationModel.escrowNumber());
         currentViewIndex(currentViewIndex() + 1);

         if (currentViewIndex() == currentOrderModels().length - 1)
             buildPreviewModel();
     }

     function movePrevious() {
         currentViewIndex(currentViewIndex() - 1);
     }

     function navigateButtonClick(item, event) {
         if (item.index() > currentViewIndex()) {

             for (var i = 0; i < item.index() ; i++) {
                 // commented for development, this need to be removed.
                 vm.validationErrors = ko.validation.group(currentOrderModels()[i]);
                 if (vm.validationErrors().length > 0) {
                     vm.validationErrors.showAllMessages();
                     return;
                 }
             }
         }
         currentViewIndex(item.index());

         if (currentViewIndex() == currentOrderModels().length - 1)
             buildPreviewModel();
     }

     function goBack(complete) {
         router.navigateBack();
     }

     function resetModels() {
         for (var i = 0; i < currentOrderModels().length; i++) {
             currentOrderModels()[i].index(i);

             // skip order information view reset
             if (i > 0)
                 currentOrderModels()[i].reset();
         }
     }

     function getCustomerInfo(userId, opertionId) {
         return utlities.getOperationUserInfo(userId, opertionId).done(function (data) {
             var currdate = new Date();
             var currdate = (currdate.getMonth() + 1) + '/' + currdate.getDate() + '/' + currdate.getFullYear();
             customerInformationModel.orderDate(currdate);
             utlities.getManagerFullName(data.OperationId, data.UserId).done(function (result) {
                 customerInformationModel.salesRep(result);
             })
             customerInformationModel.fullName(data.FirstName + String.fromCharCode(160) + data.LastName);
             customerInformationModel.email(data.Email);
             customerInformationModel.company(data.Company);
             customerInformationModel.address(data.StreetAddress1 + String.fromCharCode(160) + data.StreetAddress2 + String.fromCharCode(160) + data.City + ',' + String.fromCharCode(160) + data.State + String.fromCharCode(160) + data.Zip);
             customerInformationModel.phon(data.PhoneWork);
             customerInformationModel.ext(data.PhoneWorkExt);
             customerInformationModel.fax(data.PhoneFax);

         })

     }

     function buildPreviewModel() {
         for (var i = 0; i < currentOrderModels().length; i++) {
             if (currentOrderModels()[i].GetModelData) {
                 currentOrderModels()[i].GetModelData();
             }
         }
     }

     function submitOrder() {
         session.isBusy(true);
         var url = config.submitMobileHomeEscrowUrl;

         var request = {
             //Order Model Information -- Required
             "BrandId": session.brandId,
             "OrderId": "-1",
             "UserId": session.userId,
             "ServiceId": "61",
             "OperationId": session.defaultOpertionId(),
             "CreateDate": new Date(),
             "PropertyAddress": escrowInformationModel.propertyAddress,
             "PropertyCity": "",
             "PropertyState": "",
             "PropertyZip": "",
             //Order Model Information -- Fill If Present
             "BackendSystem": "",
             "EscrowOfficerId": "",
             "TitleOfficerId": "",
             "IsBackendOrder": "",
             "PaymentId": "",
             //Customer Information
             "CustomerSalesRep": customerInformationModel.salesRep,
             "CustomerUserFullName": customerInformationModel.fullName,
             "CustomerUserEmail": customerInformationModel.email,
             "CustomerUserCompany": customerInformationModel.company,
             "CustomerUserAddress": customerInformationModel.address,
             "CustomerUserPhone":
              {
                  "PhoneNumber": customerInformationModel.phon(),
                  "Extension": customerInformationModel.ext(),
                  "PhoneType": "Home"
              },
             "CustomerUserFax":
              {
                  "PhoneNumber": customerInformationModel.fax(),
                  "Extension": "",
                  "PhoneType": "Fax"
              },

             //Escrow Information
             "EscrowNumber": escrowInformationModel.escrowNumber,
             "DateClose": escrowInformationModel.escrowCloseDate,
             "EscrowOfficer": escrowInformationModel.escrowOfficer,
             "Company": escrowInformationModel.companyName,
             "Make": escrowInformationModel.make,
             "Model": escrowInformationModel.model,
             "Year": escrowInformationModel.year,
             "Size": escrowInformationModel.size,
             "License": escrowInformationModel.licenseorDecal,
             "SerialNumber": escrowInformationModel.serial,
             "DohNumber": escrowInformationModel.doh,


             //Seller Information
             "SellerName": mobileHomeSellerInfoModel.sellerName,
             "SellerAddress": mobileHomeSellerInfoModel.sellerAddress,
             "SellerHomePhone":
             {
                 "PhoneNumber": mobileHomeSellerInfoModel.sellerHomePhone,
                 "Extension": "",
                 "PhoneType": "Home"
             },
             "SellerBusinessPhone":
             {
                 "PhoneNumber": mobileHomeSellerInfoModel.sellerBusinessPhone,
                 "Extension": mobileHomeSellerInfoModel.ext,
                 "PhoneType": "Business"
             },

             //Buyer Information
             "BuyerName": mobileHomeBuyerInfoModel.buyerName,
             "BuyerAddress": mobileHomeBuyerInfoModel.buyerAddress,
             "BuyerHomePhone":
             {
                 "PhoneNumber": mobileHomeBuyerInfoModel.buyerHomePhone,
                 "Extension": "",
                 "PhoneType": "Home"
             },
             "BuyerBusinessPhone":
             {
                 "PhoneNumber": mobileHomeBuyerInfoModel.buyerBusinessPhone,
                 "Extension": mobileHomeBuyerInfoModel.ext,
                 "PhoneType": "Business"
             },

             //Listing Agent Information
             "ListingName": mobileHomeListingAgentInfoModel.listingAgentName,
             "ListingAddress": mobileHomeListingAgentInfoModel.listingAgentAddress,
             "ListingPhone":
             {
                 "PhoneNumber": mobileHomeListingAgentInfoModel.listingAgentBusinessPhone,
                 "Extension": mobileHomeListingAgentInfoModel.ext,
                 "PhoneType": "Business"
             },

             //Selling Agent Information
             "SellingName": mobileHomeSellingAgentInfoModel.sellingAgentName,
             "SellingAddress": mobileHomeSellingAgentInfoModel.sellingAgentAddress,
             "SellingPhone":
             {
                 "PhoneNumber": mobileHomeSellingAgentInfoModel.sellingAgentBusinessPhone,
                 "Extension": mobileHomeSellingAgentInfoModel.ext,
                 "PhoneType": "Business"
             },

             //Owner Information
             "OwnerName": mobileHomeLegalOwnerInfoModel.legalOwnerName,
             "OwnerAddress": mobileHomeLegalOwnerInfoModel.legalOwnerAddress,
             "OwnerPhone":
             {
                 "PhoneNumber": mobileHomeLegalOwnerInfoModel.legalOwnerHomePhone,
                 "Extension": "",
                 "PhoneType": "Home"
             },
             "OwnerFax":
             {
                 "PhoneNumber": mobileHomeLegalOwnerInfoModel.legalOwnerFax,
                 "Extension": "",
                 "PhoneType": "Fax"
             },

             //New Lender Information
             "LenderName": mobileHomeNewLenderInfoModel.newLenderName,
             "LenderAddress": mobileHomeNewLenderInfoModel.newLenderAddress,
             "LenderPhone":
             {
                 "PhoneNumber": mobileHomeNewLenderInfoModel.newLenderBusinessPhone,
                 "Extension": mobileHomeNewLenderInfoModel.ext,
                 "PhoneType": "Business"
             },
             "LenderFax":
             {
                 "PhoneNumber": mobileHomeNewLenderInfoModel.newLenderFax,
                 "Extension": "",
                 "PhoneType": "Fax"
             },
             "LoanNumber": mobileHomeNewLenderInfoModel.loanNumber,
             "ParkName": mobileHomeNewLenderInfoModel.mobileHomeParkName,
             "ParkAddress": mobileHomeNewLenderInfoModel.parkAddress,
             "ParkPhone":
             {
                 "PhoneNumber": mobileHomeNewLenderInfoModel.parkPhoneNumber,
                 "Extension": mobileHomeNewLenderInfoModel.parkPhoneExt,
                 "PhoneType": "Business"
             },
             "SpaceNumber": mobileHomeNewLenderInfoModel.space,

             //Loan Information
             "Deposit": loanInformationModel.deposit,
             "NewLoan": loanInformationModel.neworExistLoan,
             "SellerFinancing": loanInformationModel.sellerFinancing,
             "OtherLoan": loanInformationModel.other,
             "Balance": loanInformationModel.balance,
             "TotalSalesPrice": loanInformationModel.totalSalesPrice,
             "ClosingDate": loanInformationModel.closingDate,
             "ProrateAsOf": loanInformationModel.prorateasof,
             "COE": loanInformationModel.coe,
             //Closing Costs
             "EscrowFeeBuyer": closingCostsModel.escrowFeeB,
             "EscrowFeeSeller": closingCostsModel.escrowFeeS,
             "EscrowFee": closingCostsModel.escrowFeeea,
             "RegistrationFeeBuyer": closingCostsModel.registrationFeeB,
             "RegistrationFeeSeller": closingCostsModel.registrationFeeS,
             "RegistrationFeeEa": closingCostsModel.registrationFeeea,
             "HcdFeeBuyer": closingCostsModel.hdcTransferFeeB,
             "HcdFeeSeller": closingCostsModel.hdcTransferFeeS,
             "HcdFee": closingCostsModel.hdcTransferFeeea,
             "TaxFeeBuyer": closingCostsModel.salesTaxFeeB,
             "TaxFeeSeller": closingCostsModel.salesTaxFeeS,
             "TaxFee": closingCostsModel.salesTaxFeeea,
             "OtherFeeBuyer": closingCostsModel.otherFeeB,
             "OtherFeeSeller": closingCostsModel.otherFeeS,
             "OtherFee": closingCostsModel.otherFeeea,
             //Prorations Information
             "SpaceRent": prorationsModel.spaceRent,
             "PerMonth": prorationsModel.perMonth,
             "PaidTo": prorationsModel.paidTo,
             "InterestAmt": prorationsModel.interestonLoan,
             "ReimburseSecurityDepositAmount": prorationsModel.amount,
             "RegistrationFee": prorationsModel.registrationFee,
             "RegistrationFeeAmount": prorationsModel.registrationFeeAmountPerYear,
             "RegistrationFeeExpiration": prorationsModel.registrationFeeExpiration,
             "ReimburseSecurityDeposit": prorationsModel.reimburseSecurityDeposit() == "Yes" ? true : false,
             "InterestOnExisitingLoan": prorationsModel.interestonExistingLoan,
             "OtherProvisions": prorationsModel.otherProvisions,
         }

         https.post(url, request)
            .then(function (data) {
                if (data.HasErrors == false) {
                    orderSubmitted(true);
                    commonDialogModel.IsOrderDialog(true);
                    commonDialogModel.orderId(data.OrderId);
                    if (data.IntendedRecpients)
                        commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                    commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                }
                //else {
                //    for (var i = 0; i < data.Messages.length; i++) {
                //        if (data.Messages[i].ExceptionMessage != "") {
                //            console.log("Error:" + data.Messages[i].ExceptionMessage, orderTitle());
                //            alert.error('Internal Error. Please contact Administrator.');
                //            orderSubmitted(false);
                //            session.isBusy(false);
                //        }
                //    }
                //}
                else {
                    orderSubmitted(false);
                    session.isBusy(false);
                    var msgSuccess = "";
                    var msgError = "";

                    for (var i = 0; i < data.Messages.length; i++) {
                        if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                            //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                            //alert.error('Internal Error. Please contact Administrator.');
                            if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                msgError = msgError + data.Messages[i].Message + "\n";
                            }
                        }
                        else {
                            msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                        }
                    }
                    //alert.error(msgSuccess, orderTitle());
                    //alert.error(msgError, orderTitle());
                    if (msgSuccess.length > 0) {
                        orderSubmitted(true);
                        commonDialogModel.IsOrderDialog(true);
                        commonDialogModel.orderId(data.OrderId);
                        msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                        commonDialogModel.messagehtml(msgError);
                        if (data.IntendedRecpients)
                            commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                        commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                    }
                    else if (msgError.length > 0) {
                        alert.error(msgError, orderTitle());
                    }

                }
            })
             .fail(function (data) {
                 var error = JSON.parse(data.responseText)
                 console.log(error);
                 session.isBusy(false);
             })

     }

 });
