define('services/logger',['durandal/system','alertNotification'],
    function (system,alert) {
        var defaults = {
            source: "app",
            title: "",
            message: "no message provided",
            data: "",
            showToast: false,
            type: "info"
        };
        
        var logger = {
            log: log
        };

        return logger;

        function log(options) {
            var opns = $.extend({}, defaults, options);

            system.log(opns.source + ", " + opns.type + ", " + opns.message + ", " + opns.data + " ");

            if (opns.showToast) {
                alert[opns.type](opns.message, opns.title);
            }
        }
    });
