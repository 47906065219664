define('viewmodels/eFarm/eFarm',["knockout", 'plugins/http', 'global/session', 'config', 'utlities'],
    function (ko, https, session, config, utlities) {
        var vm = {
            activate: activate,
            session: session,
        }
        return vm;

        function activate() {
            var redirectUrl = session.eFarmUrl();
            session.CheckPopUpBlock(redirectUrl);
            //window.open(redirectUrl, '_blank');
            router.navigateBack();
        }

    });
