define('services/AddressSearchService',['config', 'Q', 'plugins/http', 'global/session'],
    function (config, Q, https, session, purchaseableReportShopItem, propertyDetailResponse) {

        function getStateAndCityByZip(zip, successCallback, failCallback, completeCallback) {

            if (session.userAddressZip() == zip && session.userZipInfo() != undefined) {
                if (successCallback != null)
                    successCallback(session.userZipInfo());
            }

            Q.when(https.get(config.getZipInfoUrl, {
                zipCode: zip,
                format: 'json'
            })
            .then(function (data) {
                if (data != undefined && data != null && data.HasErrors == false) {
                    if (successCallback != null)
                        successCallback(data);
                }
                else {
                    session.zipCodeServiceDown();
                }
            }).fail(function (data) {
                if (failCallback != null)
                    failCallback(data);
            }).always(function (data) {
                if (completeCallback != null)
                    completeCallback(data);
            }));
        }

        var searchService = {
            getStateAndCityByZip: getStateAndCityByZip
        }

        return searchService;

    });

