define('viewmodels/userProfileModule/userImages',['durandal/app', 'knockout', 'config', 'global/session', 'alertNotification', 'plugins/http', 'jquery'],
    function (app, ko, config, session, alerter, https, $) {

        var fullName = ko.observable("");
        var operationName = ko.observable("");
        var userType = ko.observable("");

        var vm = {
            activate: activate,
            currentPhoto: ko.observable(""),
            currentLogo: ko.observable(""),
            photoUrl: ko.observable(""),
            logoUrl: ko.observable(""),
            resetPage: resetPage,
            submitPage: submitPage,
            getImagesFromServer: getImagesFromServer,
            removeLogo: removeLogo,
            removePhoto: removePhoto,
            serverFileCollection: ko.observableArray(undefined),
            selectedLogoUrl: ko.observable(),
            selectedLogoChanged: selectedLogoChanged,
            photoForUpload: null,
            photoForUploadChanged: photoForUploadChanged,
            logoForUpload: null,
            logoForUploadChanged: logoForUploadChanged,
            preferredLogo: null,
            compositionComplete: compositionComplete,
            fileMode: ko.observable(""),
            fullName: fullName,
            operationName: operationName,
            userType: userType

        };

        function compositionComplete() {
            session.validateInput();
        }

        function activate() {
            fullName("");
            operationName("");
            userType("");
            console.log('User Information - activated');
            vm.fileMode('upload');
            getUserProfileInformation();
            getImagesFromServer();
        }

        function photoForUploadChanged(obj, evnt) {
            if (evnt.currentTarget.files[0])
                vm.photoForUpload = evnt.currentTarget.files[0];
            console.log(vm.photoForUpload);
        }

        function logoForUploadChanged(obj, evnt) {
            if (evnt.currentTarget.files[0])
                vm.logoForUpload = evnt.currentTarget.files[0];
            console.log(vm.logoForUpload);
        }

        vm.fileMode.subscribe(function (newValue) {
            if (newValue == 'select') {
                var url = config.getAllUserLogosUrl;
                https.get(url, { x: session.userId, format: 'json' })
                    .then(function (data) {
                        if (data) {
                            vm.serverFileCollection(data);
                            if (vm.serverFileCollection()[0])
                                vm.selectedLogoUrl(vm.serverFileCollection()[0].Value);
                        }
                    })
                    .fail(function (data) { alerter.error("An error occurred while fetching images"); })
            }
        });



        function resetPage() {
            vm.fileMode('upload');
            vm.selectedLogoUrl("");
            vm.preferredLogo = null;
            vm.photoForUpload = null;
            vm.logoForUpload = null;
            $('#photoFile').val("");
            $('#logoFile').val("");
            document.getElementById("rdoUpload").checked = true;
        }
        function selectedLogoChanged(file, url) {
            vm.selectedLogoUrl(url);
            vm.preferredLogo = file;
        }
        function getImagesFromServer() {
            var url = config.getUserImagesUrl;
            https.get(url, {
                UserId: session.userId,
                format: 'json'
            })
                .then(function (data) {
                    setImages(data);
                })
                .fail(function (data) { })
        }

        function setImages(data) {
            if (data) {
                vm.photoUrl(data.PhotoUrl);
                vm.logoUrl(data.LogoUrl);
                vm.currentPhoto(data.PhotoFileName);
                vm.currentLogo(data.AgentLogoFileName);
            }
        }



        function submitPage() {
            var formData = new FormData();
            var imageType = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];
            var errors = [];
            if (vm.photoForUpload) {
                if (-1 == $.inArray(vm.photoForUpload.type.split('/')[1], imageType)) {
                    $('#photoFile').val("");
                    errors.push('Please enter valid photo file. Only JPG, GIF, PNG or BMP allowed.');
                }
                else if (vm.photoForUpload.size / 1024 > 4096) {
                    $('#photoFile').val("");
                    errors.push('Selected Photo exceeds 4096 KB !');
                }
                else
                    formData.append("photo", vm.photoForUpload);
            }

            if (vm.fileMode() == 'upload' && vm.logoForUpload) {
                if (-1 == $.inArray(vm.logoForUpload.type.split('/')[1], imageType)) {
                    $('#logoFile').val("");
                    errors.push('Please enter valid logo file. Only JPG, GIF, PNG or BMP allowed.');
                }
                else if (vm.logoForUpload.size / 1024 > 4096) {
                    $('#logoFile').val("");
                    errors.push('Selected Logo exceeds 4096 KB !');
                }
                else
                    formData.append("logo", vm.logoForUpload);
            }
            else if (vm.selectedLogoUrl()) {
                vm.selectedLogoUrl().length > 0
                formData.append("preferred_logo", vm.preferredLogo);
            }

            if (!vm.photoForUpload && !vm.logoForUpload && !vm.selectedLogoUrl()) {
                errors.push('Please provide a Photo / Logo file input.');
            }

            var totalFileSize = 0;
            if (vm.photoForUpload != null) totalFileSize = vm.photoForUpload.size / 1024;
            if (vm.logoForUpload != null) totalFileSize = totalFileSize + vm.logoForUpload.size / 1024;
            if (totalFileSize > 4096) {
                errors.push('Total file size of single or combined files cannot be more than 4 mb.');
            }

            if (errors.length > 0) {
                alerter.warning(errors.join("<br/>"));
                resetPage();
                return;
            }

            formData.append("current_photo", vm.currentPhoto());
            formData.append("current_logo", vm.currentLogo());
            formData.append("userId", session.userId);

            $.ajax({
                url: config.saveUserImagesUrl,
                type: 'POST',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                beforeSend: function (req) {
                    if (sessionStorage["accessToken"] || localStorage["accessToken"] != null)
                        req.setRequestHeader('Authorization', 'Bearer ' + sessionStorage["accessToken"] || localStorage["accessToken"]);
                }
            }).then(function (data) {
                setImages(data);
                alerter.success('Photo Information saved successfully');
                resetPage();
                $('[name="switch"][value="upload"]').prop('checked', true);
            })
                .fail(function (data) {
                    alerter.error('Saving Photo Information failed');
                    resetPage();
                });

        }


        function removeLogo() {
            var url = config.updateUserImagesUrl;
            var input = {
                "UserId": session.userId,
                "PhotoFileName": vm.currentPhoto(),
                "AgentLogoFileName": vm.currentLogo(),
                "FileForDelete": "logo"
            }
            https.post(url, input)
                .then(function (data) { setImages(data); resetPage(); })
                .fail(function (data) { alerter.error('Updating Photo Information Failed'); resetPage(); });
        }

        function removePhoto() {
            var url = config.updateUserImagesUrl;
            var input = {
                "UserId": session.userId,
                "PhotoFileName": vm.currentPhoto(),
                "AgentLogoFileName": vm.currentLogo(),
                "FileForDelete": "photo"
            }
            https.post(url, input)
                .then(function (data) { setImages(data); resetPage(); })
                .fail(function (data) { alerter.error('Updating Photo Information Failed'); resetPage(); })
        }

        function getUserProfileInformation() {
            var url = config.getUserProfileInfoUrl;
            return https.get(url, { UserId: session.userId, OperationId: session.defaultOpertionId() })
                .done(function (result) {
                    if (result) {
                        vm.operationName(result.UserInformation.SystemInfo.Operation.Name);
                        vm.fullName(result.UserInformation.LastName + ", " + result.UserInformation.FirstName);
                        vm.userType(result.UserInformation.UserType.Name);
                    }
                }).always(function () {
                    session.isAjaxPost(true);
                })
                .fail(function () {
                    //alerter.error('Error fetching User Information', 'User Information');
                });
        }

        return vm;
    });
