define('viewmodels/sso/sso',["knockout", 'plugins/http', 'global/session', 'config', 'utlities', 'alertNotification', 'plugins/router'],
    function (ko, https, session, config, utlities, alert, router) {
        var vm = {
            activate: activate,
            session : session,
        }
        return vm;

        function activate() {

            var url = config.getSsoUrl;
            https.get(url, {
               ssoTarget: 'Admin',
                format: 'json'
            }).then(function (data) {
                var redirectUrl = data;
                session.CheckPopUpBlock(redirectUrl);
                //window.open(redirectUrl, '_blank');
                router.navigate('bingAddressSearch', { replace: true, trigger: true });
            });

        }


    });
