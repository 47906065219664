define('models/llaModule/llaModel',["knockout"],
    function (ko) {
        function AcceptanceLLAInfo() {
            var self = this;
            self.FirstName = ko.observable("")
                .extend({ required: { message: 'First Name is required.' } })

            self.LastName = ko.observable("")
                .extend({ required: { message: 'Last Name is required.' } })

            self.Company = ko.observable("")
                .extend({ required: { message: 'Company name is required.' } })

            self.Address = ko.observable("")
                .extend({ required: { message: 'Address is required.' } })

            self.UserName = ko.observable("")
                .extend({ required: { params: true, message: 'Email Address is required.' } })
                .extend({
                    pattern: {
                        message: 'Invalid Email Address.',
                        params: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
            //params: "^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$"
        }
               })
               .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })

            self.confirmEmail = ko.observable("")
                .extend({ required: { message: 'Confirm Email Address is required.' } })
                .extend({
                    pattern: {
                        message: 'Invalid Confirm Email Address.',
                        params: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                    }
                })
                .extend({ maxLength: { params: 75, message: 'Invalid Confirm Email Address.' } })
                .extend({ equal: { params: self.UserName, message: 'Email Address and Confirm Email Address must match.' } })

            self.Phone = ko.observable("")
                .extend({ required: { message: 'Phone is required.' } })
                .extend({ phoneUS: true })

            self.Zip = ko.observable("")
                .extend({ required: { message: 'Zip Code is required.' } })
                .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.City = ko.observable("")
                .extend({ required: { message: 'City is required.' } })

            self.State = ko.observable(undefined)
                .extend({ required: { message: 'State is required.' } })

            self.County = ko.observable(undefined);

            self.AgreeStatus = ko.observable("NotSet");

            self.reset = function () {
                self.FirstName("");
                self.LastName("");
                self.Company("");
                self.Address("");
                self.UserName("");
                self.confirmEmail("");
                self.Phone("");
                self.Zip("");
                self.City("");
                self.State(undefined);
                self.County(undefined);
                self.AgreeStatus("NotSet");
                ko.validation.group(self).showAllMessages(false);
            }

        }

        var vm = {
            acceptanceLLAInfo: AcceptanceLLAInfo
        }

        return vm;
    });
