define('viewmodels/orderModule/viewOfflineOrders',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'config', 'alertNotification', 'commonDialogModel', 'viewOfflineModel', 'utlities',  'koPaging', 'nameValue', 'nameList', 'moment'],
 function (app, router, $, ko, validate, iCheck, validation, Q, https, session, config, alert, commonDialog, model, utlities, koPaging, nameValue, nameList, moment) {
     var offlineOrderSearchModel = new model.OfflineOrderSearch();
     var orderSearchResultsModel = new model.OrderSearchResults();
     var searchResults = ko.observableArray([]);
     var isCustomer = ko.observable(true);
     searchResults.extend({
         paged: {
             pageSize: 10,
             pageGenerator: 'default'
         }
     });

     function canActivate() {
         if (!session.llaAccepted()) {
             session.showLLAAgreement();
             return false;
         }
         if (!session.isLoggedIn()) {
             router.navigate('#login', { replace: false, trigger: true });
             return false;
         }
         return true;
     }

     function activate() {
         searchResults([]);
         if (session.offlineSearchResults().length > 1) {

             offlineOrderSearchModel.orderRefNumber(session.offlineOrderRefNumber());
             offlineOrderSearchModel.customerName(session.offlineCustomerName());
             offlineOrderSearchModel.fromDate(session.offlineFromDate());
             offlineOrderSearchModel.toDate(session.offlineToDate());
             searchResults(session.offlineSearchResults());
             if (searchResults().length > 0)
                 searchResults.toFirstPage();
         }
         else {
             clearSessionData();
             GetCustomers();
             offlineOrderSearchModel.reset();
             var date = new Date();
             //dateError(false);
             offlineOrderSearchModel.fromDate(moment.utc(date.setDate(date.getDate() - 14)).format('MM/DD/YYYY'));
             offlineOrderSearchModel.toDate(moment.utc(new Date()).format('MM/DD/YYYY'));
             return searchOrders();
         }

     }

     function canDeactivate() {
         session.clearOrderForPropertyAddress();
         return true;
     };

     function GetCustomers() {

         var url = config.getCustomersUrl;

         https.get(url, {
             OperationId: session.defaultOpertionId(), TopUserId: session.userId
         })
            .then(function (data) {
                vm.offlineOrderSearchModel.customerList([]);
                vm.offlineOrderSearchModel.customerList(data);
                vm.offlineOrderSearchModel.customerList.unshift({
                    Key: "00000000-0000-0000-0000-000000000000", Value: "Any Customer"
                });
                vm.offlineOrderSearchModel.customerName(vm.offlineOrderSearchModel.customerList()[0].Key);
                if (session.userName() != undefined && session.userName() != '') {
                    return utlities.getOperationUserInfo(session.userId, session.defaultOpertionId()).done(function (result) {
                        if (result.UserType == "Customer") {
                            vm.offlineOrderSearchModel.customerName(result.UserId);
                            isCustomer(false);
                        }
                        else {
                            isCustomer(true);
                        }
                    })
                }
            })
            .fail(function (data) {
                console.log(data);
            });
     }

     function searchOrders() {

         var offset = new Date().getTimezoneOffset();

         var url = config.searchOrdersUrl;
         var param = {

             "OrderId": offlineOrderSearchModel.orderRefNumber,
             "UserId": offlineOrderSearchModel.customerName,
             "OrderDateFrom": offlineOrderSearchModel.fromDate,
             "OrderDateTo": offlineOrderSearchModel.toDate,
             "OperationID": session.defaultOpertionId(),
             "UTCOffset": offset
         }

         https.post(url, param)
                .then(function (data) {
                    searchResults([]);
                    var converted = loadResultToKOArray(data);
                    searchResults(converted);
                    session.setOfflineSearchData(param, searchResults());
                    if (searchResults().length > 0)
                        searchResults.toFirstPage();
                    //searchResults().forEach(function (v, i) {
                    //    alert.info(v.OrderId);
                    //});

                })
                .fail(Failed);

     }

     function getSelectedValue(selectedItem, event) {
         //return getOrderDetail(selectedItem.orderNumber()).done(function (result) {
         //    OfflineOrderDetailsModel.name(result.Name)
         //    OfflineOrderDetailsModel.orderNumber(selectedItem.orderNumber());
         //    OfflineOrderDetailsModel.sections(loadToKOArray(result.Sections))
         //    console.log(OfflineOrderDetailsModel);
         //    offlineOrderDetailPopup();
         //})

         var OrderId = selectedItem.orderNumber();
         router.navigate("/viewOfflineOrderDetail" + '/' + OrderId);

     }

     function printOrderDetail() {
         var divToPrint = document.getElementById("divOrderDetail");
         newWin = window.open("");
         if (newWin == null || typeof (newWin) == 'undefined') {
             alert.warning('Please disable your popup blocker and try again.');
         }
         else {
             newWin.document.write(divToPrint.outerHTML);
             newWin.print();
             newWin.close();
             window.print();
         }
     }

     function loadResultToKOArray(data) {
         var results = [];

         for (var i = 0; i < data.length; i++) {

             orderSearchResultsModel = new model.OrderSearchResults();
             orderSearchResultsModel.orderType(data[i].XML)
             orderSearchResultsModel.orderNumber(data[i].OrderId)
             orderSearchResultsModel.customerName(data[i].UserId);
             orderSearchResultsModel.orderPlaced(moment(data[i].OrderDate).format('MM/DD/YYYY hh:mm A'));
             orderSearchResultsModel.fullName(data[i].FullName);
             orderSearchResultsModel.serviceName(data[i].ServiceName);
             results.push(orderSearchResultsModel);
         }
         return results;
     }

     function clearSearch() {
         searchResults([]);
         offlineOrderSearchModel.reset();
     }

     function Failed(error) {

         //alert.error(error.statusText);

     }

     function itemClicked(index) {

         var id = "div" + index;
         var el = document.getElementById(id);
         if (el != null) {
             if (el.style.display != 'none') {
                 el.style.display = 'none';
             }
             else {
                 el.style.display = '';
             }
         }

         changeimage(id.replace('div', 'img'), "arrow_up.gif", "arrow_down.gif");
     }

     function changeimage(id, sMinus, sPlus) {
         var img = document.getElementById(id);
         if (img != null) {
             var bExpand = img.src.indexOf(sPlus) >= 0;
             if (!bExpand)
                 img.src = '/Content/images/' + sPlus;
             else
                 img.src = '/Content/images/' + sMinus;
         }
     }

     ko.bindingHandlers.uniqueId = {
         init: function (element, valueAccessor) {
             var value = valueAccessor();
             value.id = value.id || ko.bindingHandlers.uniqueId.prefix + (ko.bindingHandlers.uniqueId.counter++);

             element.id = value.id;
             value.id = 0;
             counter = 0;
         },
         counter: 0,
         prefix: "div"
     };

     function clearSessionData() {
         session.offlineOrderRefNumber('')
         session.offlineCustomerName('')
         session.offlineFromDate('')
         session.offlineToDate('')
         session.offlineSearchResults([])
     }
     function compositionComplete() {
         session.validateInput();
     }
     var vm = {
         canActivate: canActivate,
         activate: activate,
         offlineOrderSearchModel: offlineOrderSearchModel,
         searchResults: searchResults,
         searchOrders: searchOrders,
         clearSearch: clearSearch,
         orderSearchResultsModel: orderSearchResultsModel,
         getSelectedValue: getSelectedValue,
         printOrderDetail: printOrderDetail,
         changeimage: changeimage,
         itemClicked: itemClicked,
         clearSessionData: clearSessionData,
         isCustomer: isCustomer,
         compositionComplete: compositionComplete
     }

     return vm;

 })



;
