define('services/security',['global/session', 'jquery', 'jquery.utilities', 'plugins/http', 'knockout' ],
    function (session, $, jqutilities, https, ko) {
        // Routes
        var baseUrl = $.getBasePath(),
            addExternalLoginUrl = baseUrl + "api/Account/AddExternalLogin",
            changePasswordUrl = baseUrl + "api/Account/changePassword",
            loginUrl = baseUrl + "Token",
            logoutUrl = baseUrl + "api/Account/Logout",
            getOktaLogoutUrl = baseUrl + "api/Account/GetOktaLogoutUrl",
            registerUrl = baseUrl + "api/Account/Register",
            registerExternalUrl = baseUrl + "api/Account/RegisterExternal",
            removeLoginUrl = baseUrl + "api/Account/RemoveLogin",
            setPasswordUrl = baseUrl + "api/Account/setPassword",
            siteUrl = baseUrl,
            secretQuestionUrl = baseUrl + "api/Account/GetSecretQuestion",
            validateSecretAnswerUrl = baseUrl + "api/Account/VerifyPasswordAnswer",
            updatePasswordUsingForgotPasswordUrl = baseUrl + "api/Account/UpdatePasswordUsingForgotPassword",
            isAccountLockedUrl = baseUrl + "api/Account/getIsLockedOut",
            userInfoUrl = "api/Account/UserInfo";
            getLegacyTokenAndClaimsUrl = "api/account/GetLegacyTokenAndClaims",
            getEmailForgotPasswordLinkUrl = baseUrl + "api/User/emailPasswordLink";
            getForgotPasswordVerifyUserUrl = baseUrl + "api/Account/forgotPasswordVerifyUser";
            verifyRequestBrandAccessUrl = baseUrl + "api/user/ValidateBrandAccessRequestToken";
            verifyNewUserRegistrationTokenAccessUrl = baseUrl + "api/user/ValidateNewUserRegistrationRequestToken";
            getEmailOktaUpgradeLinkUrl = baseUrl + "api/User/emailOktaUpgradeLink";
        // Route operations
        function externalLoginsUrl(returnUrl, generateState) {
            return baseUrl + "api/Account/ExternalLogins?returnUrl=" + (encodeURIComponent(returnUrl)) +
                "&generateState=" + (generateState ? "true" : "false");
        }

        function manageInfoUrl(returnUrl, generateState) {
            return baseUrl + "api/Account/ManageInfo?returnUrl=" + (encodeURIComponent(returnUrl)) +
                "&generateState=" + (generateState ? "true" : "false");
        }

        // Other private operations
        function getSecurityHeaders() {
            var accessToken = sessionStorage["accessToken"] || localStorage["accessToken"];

            if (accessToken) {
                return { "Authorization": "Bearer " + accessToken };
            }

            return {};
        }

        function toErrorString(data) {
            var errors, items;

            if (!data || !data.message) {
                return null;
            }

            if (data.modelState) {
                for (var key in data.modelState) {
                    items = data.modelState[key];

                    if (items.length) {
                        errors = items.join(",");
                    }
                }
            }

            if (errors === undefined) {
                errors = data.message;
            }

            return errors;
        }
        function productMenuItem() {
            Credits = ko.observable("");
            Name = ko.observable("");
            CpUrl = ko.observable("");
            Limits = ko.observable("");
        }
        var securityService = {
            addExternalLogin: addExternalLogin,
            changePassword: changePassword,
            getExternalLogins: getExternalLogins,
            getManageInfo: getManageInfo,
            getUserInfo: getUserInfo,
            login: login,
            logout: logout,
            logoutOkta: logoutOkta,
            session: session,
            register: register,
            registerExternal: registerExternal,
            removeLogin: removeLogin,
            setPassword: setPassword,
            returnUrl: siteUrl,
            toErrorString: toErrorString,
            getSecurityQuestion: getSecurityQuestion,
            validateSecretAnswer: validateSecretAnswer,
            updatePasswordUsingForgotPassword: updatePasswordUsingForgotPassword,
            isAccountLocked: isAccountLocked,
            emailForgotPasswordLink: emailForgotPasswordLink,
            forgotPasswordVerifyUser: forgotPasswordVerifyUser,
            verifyRequestBrandAccess: verifyRequestBrandAccess,
            verifyNewUserRegistrationTokenAccess: verifyNewUserRegistrationTokenAccess,
            getOktaVerificationEmail: getOktaVerificationEmail
        };

        $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
            jqXHR.failJSON = function (callback) {
                jqXHR.fail(function (jqXHR, textStatus, error) {
                    var data;

                    try {
                        // data = $.parseJSON(jqXHR.responseText); 
                        data = JSON.parse(jqXHR.responseText);
                    }
                    catch (e) {
                        data = null;
                    }

                    callback(data, textStatus, jqXHR);
                });
            };
        });

        return securityService;

        // Data access operations
        function addExternalLogin(data) {
            return https.rawPost(addExternalLoginUrl, data)
        }

        function changePassword(data) {
            return https.rawPost(changePasswordUrl, data)
        }

        function emailForgotPasswordLink(userName, key) {
            return https.get(getEmailForgotPasswordLinkUrl, { userName: userName, key: key, format: 'json' });
        }

        function forgotPasswordVerifyUser(token) {
            return https.get(getForgotPasswordVerifyUserUrl, token, null, null, null, null, true);
        }

        function verifyRequestBrandAccess(token) {
            return https.get(verifyRequestBrandAccessUrl, { token: token, format: 'json' }, null, null, null, null, true);
        }

        function verifyNewUserRegistrationTokenAccess(token) {
            return https.get(verifyNewUserRegistrationTokenAccessUrl, { token: token, format: 'json' }, null, null, null, null, true);
        }

        function getOktaVerificationEmail(userEmail) {
            return https.get(getEmailOktaUpgradeLinkUrl, { userName: userName, upgradeToken: upgradeToken})
        }

        function getExternalLogins(returnUrl, generateState) {
            return https.get(externalLoginsUrl(returnUrl, generateState));
            //(url, query, headers, successCallback, errorCallback, completeCallback)
        }

        function getSecurityQuestion(data) {
            return https.get(secretQuestionUrl, data, null, null, null, null, true);
        }

        function validateSecretAnswer(data) {
            return https.get(validateSecretAnswerUrl, data, null, null, null, null, true);
        }

        function getManageInfo(returnUrl, generateState) {
            return https.get(manageInfoUrl(returnUrl, generateState));
        }

        function getUserInfo(accessToken) {
            return https.get(userInfoUrl);
        }

        function login(data) {
            return https.rawPost(loginUrl, data);
        }

        function updatePasswordUsingForgotPassword(request) {
            return https.post(updatePasswordUsingForgotPasswordUrl, request);
        }


        function isAccountLocked(data) {
            return https.get(isAccountLockedUrl, data, null, null, null, null, true);
        }

        function logout() {
            return https.rawPost(logoutUrl);
        }

        function logoutOkta() {
            return https.get(getOktaLogoutUrl).done(function (data) { });
        }

        function register(data) {
            return https.rawPost(registerUrl, data);
        }

        function registerExternal(accessToken, data) {
            return https.rawPost(registerExternalUrl, data);
        }

        function removeLogin(data) {
            return https.rawPost(removeLoginUrl, data);
        }

        function setPassword(data) {
            return https.rawPost(setPasswordUrl, data);
        }
    });
