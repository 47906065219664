define('viewmodels/userProfileModule/tcpaConsent',['durandal/app', 'plugins/router', 'knockout', 'config', 'global/session', 'plugins/http', 'alertNotification', 'utlities', 'tcpaModel'],
    function (app, router, ko, config, session, https, alert, utlities, tcpaModel) {
        var tcpaModel = new tcpaModel.tcpaUserDataModel();
        var myConsentElement = null;

        function canActivate() {
            if (session.browser() === 'IE') {
                return false;
            }
            return (session.isTCPAConsentTrackingEnabledForDefaultOperation() && session.isCustomer());
        }

        function activate() {
            //console.log('TCPA Consent VM - canActivate');
            tcpaModel.reset();

            getTCPAConsentDetailsForUser().done(function (details) {
                //console.log("getTCPAConsentDetailsForUser");
                //console.log(details);
                tcpaModel.reset();
                if (details == null || details.HasErrors != false) {
                    //console.log("Something went wrong while getting TCPA Aggrement Form.");
                    if (details != null && details.Messages.length > 0) {
                        for (var i = 0; i < details.Messages.length; i++) {
                            alert.error('Error while getting TCPA Consent Form : ' + details.Messages[i].Message, "TCPA Consent Form");
                        }
                    }
                    router.navigate('bingAddressSearch', { replace: true, trigger: true });
                }
                else if (details != null && details.HasErrors == false) {
                    tcpaModel.update(details);
                    //console.log("TCPA tcpaModel : ");
                    //console.log(tcpaModel);
                }
            }).fail(function () {
                console.log("TCPA Component get failed!");
                router.navigate('bingAddressSearch', { replace: true, trigger: true });
            });
        }

        function compositionComplete() {

            //////////////////////////////////////////////////////////////////////////////
            // wiring up the event example to an JavaScript alert box, wire after the
            // component has been defined - but add the event listener just after the
            // component declaration
            //
            // first validate that there is no error: "e.detail.error". if there is
            // no error message (i.e., e.detail.error === '') then check the message
            // "accepted"     -- the user has accepted and submitted the consent; 
            // "declined"     -- the user has declined to consent; 
            // "notrequired"  -- the user has no Consents to either accept or decline;
            if ($("#communication-consent") != null) {
                myConsentElement = document.querySelector('communication-consent');
                window.addEventListener('communication-consent', tcpaEventListener);
            }
        }


        function tcpaEventListener(e) {

            var message = '';

            if (!e.detail.error) {
                if (e.detail.message === 'accepted') {
                    message = "You have accepted the consents";
                    alert.success(message);
                    //console.log("In TCPA accepted Status : " + e.detail.message);
                    TCPAConsentAccepted();
                }
                else if (e.detail.message === 'declined') {
                    //message = "You have declined to consent";
                    //console.log("In TCPA declined Status : " + e.detail.message);
                    TCPAConsentReject();
                }
                else if (e.detail.message === 'notrequired') {
                    message = "You have already consented to everything";
                    console.log("In TCPA notrequired Status : " + e.detail.message);
                    alert.info(message);
                    $(this).unbind(e);
                    $('consent').innerHTML = '';
                    //router.navigate('bingAddressSearch', { replace: true, trigger: true });
                }
                else {
                    message = 'unknown';
                    alert.info(message);
                }

                $(this).unbind(e);
                $('consent').innerHTML = '';

                //window.location.href = '/CTSW/CTSW/ConsentResults?message=' + message;
            }
            else {
                // the error will have a "status and a message" and a error content message, for example:
                //  Error   = "401"  (unauthuorized - might be that the token is not be valid)
                //  message = "There is a Consent service issue, the service returned [401 ())], please try again later! You can email us at: Privacy@fnf.com"
                message = "----------[Webcomponent Error Message returned from communication-consent]----------\r\nError: " + e.detail.error + "\r\nMessage: " + e.detail.message;
                //window.location.href = '/CTSW/CTSW/ConsentResults?message=' + message;
                console.log(message);
                alert.error(message);
                router.navigate('bingAddressSearch', { replace: true, trigger: true });
            }

        }

        function canDeactivate() {
            tcpaModel.reset();
            return true;
        }

        function getTCPAConsentDetailsForUser() {
            var url = config.getTCPAConsentForUserUrl;
            return https.get(url, {
                format: 'json'
            });
        }
        function getTCPAConsentStatus() {
            var url = config.getTCPAConsentStatusForUserUrl;
            return https.get(url);
        }

        function TCPAConsentReject() {
            var url = config.userRejectedTCPAConsentUrl;
            var message = "";
            https.post(url)
                .done(function (result) {
                    if (result.HasErrors != false) {
                        //console.log("Something went wrong updating TCPA Rejection status.");
                        message = "Something went wrong updating TCPA Rejection status.";
                        //console.log(message);
                        alert.error(message);
                    }
                    else {
                        message = "You have declined TCPA Consent. Status saved succesfully!";
                        //console.log(message);
                        alert.success(message);
                        setTimeout(function () {
                            router.navigate('bingAddressSearch', { replace: true, trigger: true });
                        }, 3000);
                    }
                })
                .fail(function () {
                    message = "TCPA Rejection status save failed!";
                    console.log(message);
                    alert.error(message);
                    router.navigate('bingAddressSearch', { replace: true, trigger: true });
                });
        }

        function TCPAConsentAccepted() {
            //router.navigate('bingAddressSearch', { replace: true, trigger: true });
        }

        var vm = {
            canActivate: canActivate,
            activate: activate,
            canDeactivate: canDeactivate,
            compositionComplete: compositionComplete,
            getTCPAConsentDetailsForUser: getTCPAConsentDetailsForUser,
            tcpaModel: tcpaModel,
            getTCPAConsentStatus: getTCPAConsentStatus,
            TCPAConsentAccepted: TCPAConsentAccepted,
            TCPAConsentReject: TCPAConsentReject,
        };
        return vm;
    });
