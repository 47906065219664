define('viewmodels/propertySearchModule/mapNotAvailable',['durandal/app', 'knockout', 'plugins/http', 'config', 'alertNotification'], function (app, ko, https, config, alert) {
    

    var vm = {
       
    };

    return vm;
});


