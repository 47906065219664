define('viewOrdersModel',["knockout", 'moment'],
    function (ko, moment) {

        function OrderSearch() {
            var self = this;
            self.orderRefNumber = ko.observable("");
            self.customerName = ko.observable("");
            self.fromDate = ko.observable();
            self.toDate = ko.observable();
            self.customerList = ko.observableArray([]);

            self.reset = function () {
                self.orderRefNumber("");
                self.customerName("");
                var date = new Date();
                self.fromDate(moment.utc(date.setDate(date.getDate() - 14)).format('MM/DD/YYYY'));
                self.toDate(moment.utc(new Date()).format('MM/DD/YYYY'));
            };
        }

        function OrderSearchResults() {
            var self = this;
            self.type = ko.observable("");
            self.orderDate = ko.observable("");
            self.orderType = ko.observable("");
            self.orderNumber = ko.observable("");
            self.customerName = ko.observable("");
            self.status = ko.observable("");

            self.reset = function () {
                self.orderDate("");
                self.orderType("");
                self.orderNumber("");
                self.customerName("");
                self.type("");
                self.status("");
            };
        }

        var model = {
            OrderSearch: OrderSearch,
            OrderSearchResults: OrderSearchResults,
        }

        return model;
    });
