define('services/BingAPIService',['config', 'Q', 'plugins/http', 'global/session'],
    function (config, Q, https, session) {

        var map = null;

        function setMap(targetMap) {
            map = targetMap;
        }

        function getCoOrdinates(address)
        {
            Microsoft.Maps.loadModule('Microsoft.Maps.Search', function () {
                var searchManager = new Microsoft.Maps.Search.SearchManager(map);
                var requestOptions = {
                    where: address,
                    callback: function (answer, userData) {
                        map.setView({
                            bounds: answer.results[0].bestView
                        });
                        map.entities.push(new Microsoft.Maps.Pushpin(answer.results[0].location));
                    }
                };
                searchManager.geocode(requestOptions);
            });
        }

        var bingAPIService =
            {
                getCoOrdinates: getCoOrdinates
            };

        return bingAPIService;
    });
