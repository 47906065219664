define('legalGlossaryModel',["knockout"],
    function (ko) {
        function legalGlossary() {
            var self = this;
            self.legalGlossaryList = ko.observableArray([]);
        }



        var vm = {
            legalGlossary: legalGlossary
        }

        return vm;
    });
