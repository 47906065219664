//TODO: Inject dependencies
define('viewmodels/_Footer',['plugins/router', 'knockout', 'plugins/http', 'global/session', 'managerInfoModel', 'feedbackModel', 'config', 'alertNotification'],
    function (router, ko, https, session, model, modelFeedback, config, alert) {
        // Internal properties and functions

        // Reveal the bindable properties and functions
        var feedbackMod = new modelFeedback.feedback();
        var footerData = ko.observable();
        var CopyRightText = ko.observable('');

        var TermsText = ko.observable('');
        var TermsURL = ko.observable('');
        var PrivacyPolicyURL = ko.observable('');
        var FeedbackURL = ko.observable('');
        var HelpPhone = ko.observable('');
        var AccessibilityStmt = ko.observable('');

        function showLLAAgreement() {
            $('#mdLLAAgreement').modal('show');
        }

        function closeLLADialog() {
            $('#mdLLAAgreement').modal('hide');
        }

        var LLAText = ko.observable('');

        function compositionComplete() {
            session.validateInput();
        }

        var vm = {
            activate: activate,
            compositionComplete: compositionComplete,
            goBack: goBack,
            title: '_Footer',
            CopyRightText: CopyRightText,
            TermsText: TermsText,
            TermsURL: TermsURL,
            PrivacyPolicyURL: PrivacyPolicyURL,
            FeedbackURL: FeedbackURL,
            HelpPhone: HelpPhone,
            AccessibilityStmt: AccessibilityStmt,
            ShowLLAAgreement: showLLAAgreement,
            CloseLLADialog: closeLLADialog,
            LLAText: LLAText,
            session: session,
            feedbackMod: feedbackMod,
            feedbackEmailForm: feedbackEmailForm,
            sendFeedbackEmail: sendFeedbackEmail,
        };
        vm.feedbackEmailErrors = ko.validation.group([vm.feedbackMod]);
        return vm;

        function activate(id, querystring) {
            //TODO: Initialize lookup data here.

            //TODO: Get the data for this viewmodel, return a promise.
            getFooterData();
            $('#mdFeedbackEmailForm').modal('hide');
            $('#mdFeedbackEmailSentDetails').modal('hide');
        }

        function getFooterData() {
            https.get('durandal/GetFooter').done(function (data) {


                CopyRightText(data.CopyRightText);
                TermsText(data.TermsText);
                TermsURL(data.TermsURL);
                PrivacyPolicyURL(data.PrivacyPolicyURL);
                FeedbackURL(data.FeedbackURL);
                HelpPhone(data.HelpPhone);
                LLAText(data.LLAText);
                AccessibilityStmt(data.AccessibilityStmt);

                //console.log(footerData().CopyRightText);
            });
        }
        function feedbackEmailForm() {
            $('#mdFeedbackEmailSentDetails').modal('hide');
            feedbackMod.reset();
            var url = config.getBrandOperationAdminDetails;
            https.get(url, {
                format: 'json'
            }).then(function (data) {
                if (session.isLoggedIn()) {
                    feedbackMod.BrandId(session.brandId);
                    feedbackMod.OperationId(session.defaultOpertionId());
                    feedbackMod.Name(session.fullName());
                    feedbackMod.Email(session.email());
                    feedbackMod.ConfirmEmail(session.email());
                }
                else {
                    feedbackMod.BrandId(data.BrandId);
                    feedbackMod.OperationId(data.BrandAdminDefaultOperationId);
                }
                feedbackMod.BrandName(data.BrandMnemonics);
                $('#mdFeedbackEmailForm').modal('show');
            });
        }


        function sendFeedbackEmail() {
            if (vm.feedbackEmailErrors != null && vm.feedbackEmailErrors != 'undefined' && vm.feedbackEmailErrors().length > 0) {
                vm.feedbackEmailErrors.showAllMessages();
                return;
            }
            var url = config.sendFeedbackEmail;
            var request = {
                "OperationId": feedbackMod.OperationId(),
                "BrandId": feedbackMod.BrandId(),
                "Name": session.fullName(),
                "Email": session.email(),
                "ConfirmEmail": session.email(),
                "Message": feedbackMod.Message(),
                "Subject": feedbackMod.Subject(),
            };
            return https.post(url, request)
                .then(function (data) {
                    $('#mdFeedbackEmailForm').modal('hide');
                    if (data.HasErrors == false) {
                        alert.success("Feedback email successfully sent", "Feedback");
                        $('#mdFeedbackEmailSentDetails').modal('show');
                    }
                    else {
                        alert.error("Error while sending Feedback email", "Feedback");
                    }
                })
                .fail(function () {
                    alert.error("Error while sending Feedback email", "Feedback");
                });
        }

        function goBack(complete) {
            router.navigateBack();
        }
    });
