//define(['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'alertNotification', 'utlities', 'paymentProcessModel', 'creditCardValidator', 'commonDialogModel'],
// function (app, router, $, ko, validate, iCheck, config, validation, Q, https, session, alert, utlities, paymentProcessModel, creditCardValidator, commonDialog) {

//     var paymentModeView = ko.observable('paymentProcess/_cardInfo.html');
//     var creditCardInformationModel = new paymentProcessModel.CreditCardInformation();
//     var orderSummaryModel = new paymentProcessModel.OrderSummary();
//     var walletModel = new paymentProcessModel.Wallet();

//     var paymentMode = ko.observable("1");

//     var paymentModeList = ko.observableArray([
//                        { key: "Wallet", value: "0" },
//                        { key: "Credit Card", value: "1" }
//     ]);

//     var amountToAdd = ko.observable(0.0)
//                        .extend({ required: { message: 'Please enter amount.' } })
//                         .extend({
//                             pattern: {
//                                 params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
//                                 message: 'Invalid Amount. (format: ###.##)'
//                             }
//                         })
//                        .extend({ max: { params: 200.00, message: "Max amount : $200.00" } })
//                        .extend({ min: { params: 1.00, message: "Min amount : $1.00" } });


//     var amountToAddString = ko.computed(function () {
//         return '$' + parseFloat(amountToAdd()).toFixed(2);
//     });

//     var tax = ko.observable(0);
//     var subTotal = ko.observable(0);
//     var totalCredits = ko.observable(0);
//     var Total = ko.observable(0);
//     var subTotalDisplay = ko.observable('');
//     var totalDisplay = ko.observable('');
//     var taxDisplay = ko.observable('');
//     var taxRateDisplay = ko.observable('');
//     var canPurchaseWithWallet = ko.observable(true);
//     var enablePayment = ko.observable(true);
//     var hasPrice = ko.observable(false);
//     var commonDialogModel2 = new commonDialog("AddFundsToWallet");


//     function reset() {
//         paymentMode("1");
//         paymentModeView('paymentProcess/_cardInfo.html');
//         tax(0);
//         subTotal(0);
//         totalCredits(0);
//         Total(0);
//         subTotalDisplay('');
//         totalDisplay('');
//         taxDisplay('');
//         taxRateDisplay('');
//         canPurchaseWithWallet(true);
//         enablePayment(true);
//     }

//     paymentMode.subscribe(function (newValue) {
//         // credit card
//         if (enablePayment()) {
//             if (newValue == 0) {
//                 if (session.purchasableProdutsWalletBalance() < Total()) {
//                     canPurchaseWithWallet(false);
//                 }
//                 else {
//                     canPurchaseWithWallet(true);
//                 }
//                 paymentModeView('paymentProcess/_wallet.html');
//             }
//             else {
//                 paymentModeView('paymentProcess/_cardInfo.html');
//                 creditCardInformationModel.reset();
//             }
//         }
//         else {
//             paymentModeView('paymentProcess/_noPayment.html');
//         }
//     });

//     function activate() {
//         calculateTotal();
//         creditCardInformationModel.reset();
//         if (Total() <= 0) {
//             enablePayment(false);
//             paymentMode("-1");
//         }
//         return true;
//     };

//     function showAddFundsWalletModal() {
//         $('#mdAddFundsWalletModal').modal({
//             backdrop: 'static',
//             keyboard: false
//         });
//         creditCardInformationModel.reset();
//         amountToAdd(0.0);
//         ko.validation.group(vm).showAllMessages(false);

//         //$('#mdAddFundsWalletModal').modal('show');

//         $("#mdAddFundsWalletModal").on("shown.bs.modal", function () {
//             $('#mdAddFundsWalletModal .modal-body').scrollTop(0);
//         }).modal('show');

//     }

//     function calculateTotal() {
//         reset();
//         session.purchasableProdutsWalletBalance(parseFloat(session.purchasableProdutsWalletBalance()).toFixed(2));
//         if (session.purchasableProdutsShoppingCartList() != null && session.purchasableProdutsShoppingCartList().length > 0) {
//             tax(parseFloat(session.purchasableProdutsTax()));
//             for (var i = 0; i < session.purchasableProdutsShoppingCartList().length; i++) {
//                 if (session.purchasableProdutsShoppingCartList()[i].UseCredits()) {
//                     var existingCredits = totalCredits();
//                     totalCredits(existingCredits + session.purchasableProdutsShoppingCartList()[i].Credits());
//                 }
//                 else {
//                     hasPrice(true);
//                     var existingSubTotal = subTotal();
//                     var price = session.purchasableProdutsShoppingCartList()[i].Price();
//                     if (price.indexOf('$') > -1)
//                         price = price.replace('$', '');
//                     subTotal(parseFloat((existingSubTotal + parseFloat(price))));
//                 }
//             }
//             var creditsDisplay = '';
//             if (subTotal() > 0) {
//                 if (totalCredits() > 0) {
//                     creditsDisplay = ' & ' + totalCredits() + ' Credits';
//                 }
//                 subTotalDisplay('$' + subTotal().toFixed(2) + creditsDisplay);
//                 Total(subTotal() + (parseFloat((subTotal() * tax()))));
//                 totalDisplay('$' + Total().toFixed(2) + creditsDisplay);
//                 taxDisplay('$' + parseFloat((subTotal() * tax())).toFixed(2));
//             }
//             else if (subTotal() == 0 && hasPrice()) {
//                 if (totalCredits() > 0) {
//                     creditsDisplay = ' & ' + totalCredits() + ' Credits';
//                 }
//                 subTotalDisplay('$' + subTotal().toFixed(2) + creditsDisplay);
//                 Total(subTotal() + (parseFloat((subTotal() * tax()))));
//                 totalDisplay('$' + Total().toFixed(2) + creditsDisplay);
//                 taxDisplay('$' + parseFloat((subTotal() * tax())).toFixed(2));
//             }
//             else {
//                 if (totalCredits() > 0) {
//                     creditsDisplay = totalCredits() + ' Credits';
//                 }
//                 totalDisplay(creditsDisplay);
//             }
//         }
//         return true;
//     }
//     function canDeactivate() {
//         window.history.pushState(null, "", window.location.href);
//         window.onpopstate = function () {
//             window.history.pushState(null, "", window.location.href);
//             return false;
//         };
//         return true;
//     };

//     function goBack(complete) {
//         router.navigateBack();
//     }

//     function placeOrderProducts() {

//         if (vm.creditCardValidationErrors != null && vm.creditCardValidationErrors != 'undefined' && vm.creditCardValidationErrors().length > 0) {
//             vm.creditCardValidationErrors.showAllMessages();
//             if (vm.errors != null && vm.errors != 'undefined' && vm.errors().length > 0) {
//                 vm.errors.showAllMessages();
//             }
//             $('#addFundsToWallet').modal('hide');
//             return;
//         }
//         if (vm.errors != null && vm.errors != 'undefined' && vm.errors().length > 0) {
//             vm.errors.showAllMessages();
//             $('#addFundsToWallet').modal('hide');
//             return;
//         }
//         $('#addFundsToWallet').modal('show');
//     }

//     function AddFundsToWallet() {
//         session.isBusy(true);
//         if (vm.errors != null && vm.errors != 'undefined' && vm.errors().length > 0) {
//             vm.errors.showAllMessages();
//             session.isBusy(false);
//             return;
//         }
//         $('#addFundsToWallet').modal('hide');
//         $('#mdAddFundsWalletModal').modal('hide');
//         var request = {
//             "Products": null,
//             "PropertyId": null,
//             "UserId": session.userId,
//             "OperationId": session.defaultOpertionId(),
//             "BrandId": session.brandId,
//             "CardHolderAddress":
//             {
//                 "Address1": creditCardInformationModel.billingAddress(),
//                 "Address2": null,
//                 "City": creditCardInformationModel.city(),
//                 "State": creditCardInformationModel.state(),
//                 "County": '',
//                 "CountyIsOther": false,
//                 "Zip": creditCardInformationModel.zip(),
//                 "Zip4": null,
//                 "Apn": null,
//                 "Fips": null,
//                 "Latitude": null,
//                 "Longitude": null,
//             },
//             "PaymentTransaction":
//             {
//                 "PaymentType": 0,
//                 "CardNumber": creditCardInformationModel.cardNumber,
//                 "CardExpirationDate": creditCardInformationModel.expirationYear() + '-' + creditCardInformationModel.expirationMonth() + '-' + '01',
//                 "CardCVS": creditCardInformationModel.verificationCodeCVC,
//                 "NameOnCard": creditCardInformationModel.nameOnCard,
//                 "ChargeAmount": amountToAdd,
//             },

//             "AmountToAdd": amountToAdd
//         };

//         var url = config.addFundsToWalletUrl;
//         return https.post(url, request)
//         .then(function (data) {
//             if (data.HasErrors == false) {

//                 switch (data.Status) {
//                     case 10://Success
//                         if (data.Messages.length > 0) {

//                             var messageObj = data.Messages.filter(function (i) {
//                                 return i.Level === 0;
//                             });
//                             var currWalletBalance = session.purchasableProdutsWalletBalance();
//                             var addedAmount = parseFloat(amountToAdd());
//                             var newWalletBalance = parseFloat(currWalletBalance) + addedAmount;
//                             session.purchasableProdutsWalletBalance(newWalletBalance.toFixed(2));
//                             if (session.purchasableProdutsWalletBalance() > Total()) {
//                                 canPurchaseWithWallet(true);
//                             }
//                             else {
//                                 canPurchaseWithWallet(false);
//                             }
//                             $('#mdAddFundsWalletModal').modal('hide');
//                             creditCardInformationModel.reset();
//                             amountToAdd(0);
//                             commonDialogModel2.IsOrderDialog(false);
//                             commonDialogModel2.show("Add Funds To Wallet", "Funds were added to your wallet successfully. " + messageObj[0].Message);
//                         }
//                         else {
//                             var currWalletBalance = session.purchasableProdutsWalletBalance();
//                             var newWalletBalance = parseFloat(currWalletBalance) + parseFloat(amountToAdd());
//                             session.purchasableProdutsWalletBalance(newWalletBalance.toFixed(2));
//                             if (session.purchasableProdutsWalletBalance() > Total()) {
//                                 canPurchaseWithWallet(true);
//                             }
//                             else {
//                                 canPurchaseWithWallet(false);
//                             }
//                             $('#mdAddFundsWalletModal').modal('hide');
//                             creditCardInformationModel.reset();
//                             amountToAdd(0);
//                             commonDialogModel2.IsOrderDialog(false);
//                             commonDialogModel2.show("Add Funds To Wallet", "Funds were added to your wallet successfully");
//                         }
//                         break;

//                     case 20: alert.error(data.StatusMessage, "Add Funds To Wallet");//UnableToProcessPayment
//                         alert.error("Unable To ProcessPayment : Error while adding funds to your wallet.", "Add Funds To Wallet");
//                         break;
//                     case 30: alert.error("No Available Products? Error while adding funds to your wallet.", "Add Funds To Wallet");//NoAvailableProducts
//                         break;
//                     case 0://UnKnown
//                     case 100://Failed
//                     default: alert.error("Error while adding funds to your wallet.", "Add Funds To Wallet");
//                         break;
//                 }
//             }
//             else {
//                 if (data.Messages.length > 0) {
//                     for (var i = 0; i < data.Messages.length; i++) {
//                         alert.error('Error while adding funds to your wallet : ' + data.Messages[i].Message, "Add Funds To Wallet");
//                     }
//                 }
//                 else {
//                     alert.error("Error while adding funds to your wallet.", "Add Funds To Wallet");
//                 }
//             }
//             creditCardInformationModel.reset();
//             amountToAdd(0);
//             session.isBusy(false);
//         })
//         .fail(function () {
//             alert.error('Error while adding funds to your wallet', "Add Funds To Wallet");
//             creditCardInformationModel.reset();
//             amountToAdd(0);
//             session.isBusy(false);
//         });
//     }

//     function onCancelOrCloseAddFundsToWallet() {
//         creditCardInformationModel.reset();
//     }

//     function PlaceOrder() {
//         if (paymentMode() == 1) {
//             if (vm.creditCardValidationErrors != null && vm.creditCardValidationErrors != 'undefined' && vm.creditCardValidationErrors().length > 0) {
//                 vm.creditCardValidationErrors.showAllMessages();
//                 session.isBusy(false);
//                 return;
//             }
//         }

//         if (totalCredits() > session.purchasableProdutsCreditBalance()) {
//             alert.error("You do not have enough credits for selected items.", "Payment Process");
//             session.isBusy(false);
//             return;
//         }
//         session.isBusy(true);
//         var purchasableProdutsListJSONObject = ko.toJS(session.purchasableProdutsShoppingCartList());

//         var request = null;
//         if (!enablePayment()) {
//             request = {
//                 "Products": purchasableProdutsListJSONObject,
//                 "PropertyId": session.selectedPropertyId(),
//                 "UserId": session.userId,
//                 "OperationId": session.defaultOpertionId(),
//                 "BrandId": session.brandId,
//                 "PaymentTransaction":
//                    {
//                        "PaymentType": 2,
//                        "CardNumber": null,
//                        "CardExpirationDate": null,
//                        "CardCVS": null,
//                        "NameOnCard": null,
//                        "ChargeAmount": null,
//                    },
//                 "CardHolderAddress": null,
//                 "AmountToAdd": 0
//             };
//         }
//         else {
//             if (paymentMode() == 1) { //CreditCard
//                 request = {
//                     "Products": purchasableProdutsListJSONObject,
//                     "PropertyId": session.selectedPropertyId(),
//                     "UserId": session.userId,
//                     "OperationId": session.defaultOpertionId(),
//                     "BrandId": session.brandId,
//                     "PaymentTransaction":
//                         {
//                             "PaymentType": 0,
//                             "CardNumber": creditCardInformationModel.cardNumber(),
//                             "CardExpirationDate": creditCardInformationModel.expirationYear() + '-' + creditCardInformationModel.expirationMonth() + '-' + '01',
//                             "CardCVS": creditCardInformationModel.verificationCodeCVC(),
//                             "NameOnCard": creditCardInformationModel.nameOnCard(),
//                             "ChargeAmount": Total,
//                         },
//                     "CardHolderAddress":
//                         {
//                             "Address1": creditCardInformationModel.billingAddress(),
//                             "Address2": null,
//                             "City": creditCardInformationModel.city(),
//                             "State": creditCardInformationModel.state(),
//                             "County": '',
//                             "CountyIsOther": false,
//                             "Zip": creditCardInformationModel.zip(),
//                             "Zip4": null,
//                             "Apn": null,
//                             "Fips": null,
//                             "Latitude": null,
//                             "Longitude": null,
//                         },
//                     "AmountToAdd": 0
//                 };
//             }
//             else // Wallet
//             {
//                 var walletBal = session.purchasableProdutsWalletBalance();
//                 if (walletBal.toString().indexOf('$') > -1)
//                     walletBal = walletBal.toString().replace('$', '');
//                 if (parseFloat(walletBal) < Total()) {
//                     alert.error("Insufficient Funds in wallet. Please select other Payment Method or Add Funds to wallet and then proceed with payment", "Payment Process");
//                     session.isBusy(false);
//                     return;
//                 }
//                 request = {
//                     "Products": purchasableProdutsListJSONObject,
//                     "PropertyId": session.selectedPropertyId(),
//                     "UserId": session.userId,
//                     "OperationId": session.defaultOpertionId(),
//                     "BrandId": session.brandId,
//                     "PaymentTransaction":
//                         {
//                             "PaymentType": 1,
//                             "CardNumber": null,
//                             "CardExpirationDate": null,
//                             "CardCVS": null,
//                             "NameOnCard": null,
//                             "ChargeAmount": null,
//                         },
//                     "CardHolderAddress": null,
//                     "AmountToAdd": 0
//                 };
//             }
//         }

//         var url = config.purchasableProductProcessOrder;
//         return https.post(url, request)
//         .then(function (data) {

//             if (data.HasErrors == false) {
//                 switch (data.Status) {
//                     case 10://Success
//                     case 12:
//                         resetSessionVariables();// Do not change the position of this call
//                         session.purchasableProdutsECommerceId(data.EcommerceOrderId);
//                         alert.success(data.StatusMessage, "Payment Process"); //PartialSuccess
//                         router.navigate('#purchasableProductsOrderConfirmation');
//                         break;
//                     case 20: alert.error(data.StatusMessage, "Payment Process");//UnableToProcessPayment
//                         break;
//                     case 21: alert.error(data.StatusMessage, "Payment Process");//UnableToProcessPaymentCreditsProductsAvailable
//                         break;
//                     case 25: alert.error(data.StatusMessage, "Payment Process");//InsufficientCredits
//                         break;
//                     case 30: alert.error(data.StatusMessage, "Payment Process");//NoAvailableProducts
//                         break;
//                     case 0://UnKnown
//                     case 100://Failed
//                     default: alert.error(data.StatusMessage, "Payment Process");
//                         break;
//                 }
//             }
//             else {
//                 alert.error('Error while processing payment', "Payment Process");
//             }
//             creditCardInformationModel.reset();
//             session.isBusy(false);
//         })
//         .fail(function () {
//             session.isBusy(false);
//             alert.error('Error while processing payment', "Payment Process");

//         });

//     }

//     function CancelAddFunds() {
//         $('#mdAddFundsWalletModal').modal('hide');
//         onCancelOrCloseAddFundsToWallet();
//         session.isBusy(false);
//     }

//     function Cancel() {
//         reset();
//         router.navigateBack();
//     }

//     function resetSessionVariables() {
//         session.purchasableProdutsECommerceId(0);
//         session.purchasableProdutsShoppingCartList([]);
//         session.purchasableProdutsWalletBalance(0);
//         session.purchasableProdutsCreditBalance(0);
//         session.purchasableProdutsWalletEnabled(false);
//         session.purchasableProdutsCreditsEnabled(false);
//         session.purchasableProdutsSelectedProperty(undefined);
//         session.purchasableProdutsTax(0.0);
//     }
//     var vm = {
//         session: session,
//         calculateTotal: calculateTotal,
//         activate: activate,
//         canDeactivate: canDeactivate,
//         goBack: goBack,
//         title: 'Purchase Summary',
//         paymentModeView: paymentModeView,
//         paymentMode: paymentMode,
//         paymentModeList: paymentModeList,
//         creditCardInformationModel: creditCardInformationModel,
//         orderSummaryModel: orderSummaryModel,
//         walletModel: walletModel,
//         tax: tax,
//         subTotal: subTotal,
//         totalCredits: totalCredits,
//         Total: Total,
//         subTotalDisplay: subTotalDisplay,
//         totalDisplay: totalDisplay,
//         taxDisplay: taxDisplay,
//         canPurchaseWithWallet: canPurchaseWithWallet,
//         enablePayment: enablePayment,
//         Total: Total,
//         reset: reset,
//         PlaceOrder: PlaceOrder,
//         Cancel: Cancel,
//         resetSessionVariables: resetSessionVariables,
//         hasPrice: hasPrice,
//         showAddFundsWalletModal: showAddFundsWalletModal,
//         CancelAddFunds: CancelAddFunds,
//         AddFundsToWallet: AddFundsToWallet,
//         placeOrderProducts: placeOrderProducts,
//         amountToAdd: amountToAdd,
//         amountToAddString: amountToAddString,
//         commonDialogModel2: commonDialogModel2,
//         onCancelOrCloseAddFundsToWallet: onCancelOrCloseAddFundsToWallet
//     }

//     vm.creditCardValidationErrors = ko.validation.group([vm.creditCardInformationModel]);
//     vm.errors = ko.validation.group([vm]);

//     return vm;


//     function Failed(error) {
//         alert.error(error.statusText);
//     }
// });
define("viewmodels/propertySearchModule/purchasableProductsPaymentProcess", function(){});

