define('viewmodels/orderModule/viewOrders',['plugins/router', 'knockout', 'durandal/app', 'jquery', 'plugins/http', 'global/session', 'viewOrdersModel', 'config', 'nameValue', 'nameList', 'moment', 'alertNotification', 'koPaging', 'commonOrderModel', 'viewOrder', 'viewOrderService', 'printThis', 'utlities'],
    function (router, ko, app, $, https, session, model, config, nameValue, nameList, moment, alert, koPaging, commonOrderModel, viewOnlyOrder, viewOrderService, printThis, utlities)
    {
        var orderSearchModel = new model.OrderSearch();
        var orderSearchResultsModel = new model.OrderSearchResults();
        var viewOrder = new viewOnlyOrder.ViewOrder();

        var searchResults = ko.observableArray([]).extend({ notify: 'always' });
        var isCustomer = ko.observable(true);
        var emailMessage = ko.observable("").extend({ required: { message: 'Email Message is required.' } });
        var emailSubject = ko.observable("");//.extend({ required: { message: 'Email Subject is required.' } });
        var orderSubmittedByEmail = ko.observable("");

        searchResults.extend({
            paged: {
                pageSize: 10,
                pageGenerator: 'default'
            }
        });

        //function sortItems(itemType, event)
        //{
        //    searchResults(searchResults().sort(function (left, right)
        //    {
        //        return left.orderType().toLowerCase() == right.orderType().toLowerCase() ? 0 : (left.orderType().toLowerCase() < right.orderType().toLowerCase() ? left : right)
        //    }));
        //}
        //sortItems = function()
        //{
        //    searchResults(searchResults().sort(function (left, right)
        //    {
        //        return left.orderType().toLowerCase() == right.orderType().toLowerCase() ? 0 : (left.orderType().toLowerCase() < right.orderType().toLowerCase() ? left : right)
        //    }));
        //}

        function canActivate()
        {
            if (!session.llaAccepted())
            {
                session.showLLAAgreement();
                return false;
            }
            if (!session.isLoggedIn())
            {
                router.navigate('#login', { replace: false, trigger: true });
                return false;
            }
            return true;
        }


        function activate(OrderId)
        {
            $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('hide');
            viewOrder.reset();
            //AfterLoad();
            searchResults.extend({
                paged: {
                    pageNumber: 1,
                    pageSize: 10,
                    pageGenerator: 'default'
                }
            });
            searchResults([]);
            if (session.viewOrders_SearchResults().length > 1)
            {
                orderSearchModel.orderRefNumber(session.viewOrders_OrderRefNumber());
                orderSearchModel.customerName(session.viewOrders_CustomerName());
                orderSearchModel.fromDate(session.viewOrders_FromDate());
                orderSearchModel.toDate(session.viewOrders_ToDate());
                searchResults(session.viewOrders_SearchResults());
                if (searchResults().length > 0)
                    searchResults.toFirstPage();
            }
            else
            {
                clearSessionData();
                GetCustomers();
                orderSearchModel.reset();
                var date = new Date();
                orderSearchModel.fromDate(moment.utc(date.setDate(date.getDate() - 14)).format('MM/DD/YYYY'));
                orderSearchModel.toDate(moment.utc(new Date()).format('MM/DD/YYYY'));
            }

            searchOrders().done(function ()
            {
                if (OrderId != null && OrderId != '')
                {
                    loadOrderDetail(OrderId);
                }
            });
        }


        function clearSessionData()
        {
            session.viewOrders_OrderRefNumber('');
            session.viewOrders_CustomerName('');
            session.viewOrders_FromDate('');
            session.viewOrders_ToDate('');
            session.viewOrders_SearchResults([]);
        }

        function GetCustomers()
        {
            var url = config.getCustomersUrl;

            https.get(url, {
                OperationId: session.defaultOpertionId(), TopUserId: session.userId
            })
                .then(function (data)
                {
                    vm.orderSearchModel.customerList([]);
                    vm.orderSearchModel.customerList(data);
                    if (data.length > 1)
                    {
                        vm.orderSearchModel.customerList.unshift({
                            Key: "00000000-0000-0000-0000-000000000000", Value: "Any Customer"
                        });
                    }
                    vm.orderSearchModel.customerName(vm.orderSearchModel.customerList()[0].Key);
                    if (session.userName() != undefined && session.userName() != '')
                    {
                        return utlities.getOperationUserInfo(session.userId, session.defaultOpertionId()).done(function (result)
                        {
                            if (result.UserType == "Customer")
                            {
                                vm.orderSearchModel.customerName(result.UserId);
                                isCustomer(false);
                            }
                            else
                            {
                                isCustomer(true);
                            }
                        })
                    }
                })
                .fail(function (data)
                {
                    console.log(data);
                });
        }

        function searchOrders()
        {
            var offset = new Date().getTimezoneOffset();

            var url = config.searchAllOrdersUrl;
            var param = {
                "OrderId": orderSearchModel.orderRefNumber,
                "UserId": orderSearchModel.customerName,
                "OrderDateFrom": orderSearchModel.fromDate,
                "OrderDateTo": orderSearchModel.toDate,
                "OperationID": session.defaultOpertionId(),
                "UTCOffset": offset
            }

            return https.post(url, param)
                .then(function (data)
                {
                    console.log(data);
                    searchResults([]);
                    var converted = loadResultToKOArray(data);
                    searchResults(converted);
                    session.viewOrders_setSearchData(param, searchResults());
                    if (searchResults().length > 0)
                        searchResults.toFirstPage();
                    //searchResults().forEach(function (v, i) {
                    //    alert.info(v.OrderId);
                    //});

                })
                .fail(Failed);
        }


        function loadResultToKOArray(data)
        {
            var results = [];
            for (var i = 0; i < data.length; i++)
            {
                orderSearchResultsModel = new model.OrderSearchResults();
                orderSearchResultsModel.type(data[i].Type);
                orderSearchResultsModel.orderType(data[i].OrderType);
                orderSearchResultsModel.orderDate(moment(data[i].OrderDate).format('MM/DD/YYYY hh:mm A'));
                orderSearchResultsModel.orderNumber(data[i].OrderId)
                orderSearchResultsModel.customerName(data[i].FullName);
                orderSearchResultsModel.status(data[i].Status);
                results.push(orderSearchResultsModel);
            }
            return results;
        }

        function clearSearch()
        {
            searchResults([]);
            orderSearchResultsModel.reset();
            vm.orderSearchModel.reset();
            var date = new Date();
            vm.orderSearchModel.fromDate(moment.utc(date.setDate(date.getDate() - 14)).format('MM/DD/YYYY'));
            vm.orderSearchModel.toDate(moment.utc(new Date()).format('MM/DD/YYYY'));
            vm.orderSearchModel.customerName(vm.orderSearchModel.customerList()[0].Key);
        }

        function Failed(error)
        {
            //alert.error(error.statusText);
        }

        function clearSessionData()
        {
            session.viewOrders_OrderRefNumber('')
            session.viewOrders_CustomerName('')
            session.viewOrders_FromDate('')
            session.viewOrders_ToDate('')
            session.viewOrders_SearchResults([])
        }
        function recentResult_click(result, event)
        {
            console.log(result);
            var OrderIdForDetails = result.orderNumber();
            if (result.type() === 'offline')
                router.navigate("/viewOfflineOrderDetail" + '/' + OrderIdForDetails);
            else if (result.type() === 'online')
                loadOrderDetail(OrderIdForDetails);
            else
                alert.error("There was an error viewing details for Order ID : " + OrderIdForDetails);
        }
        function loadOrderDetail(orderId)
        {
            viewOrderService.getViewOrder(orderId).done(function (orderDetail)
            {
                if (orderDetail.HasErrors())
                {
                    for (var i = 0; i < orderDetail.ErrorMessages().length; i++)
                    {
                        alert.error(orderDetail.ErrorMessages()[i]);
                    }
                }
                else
                {
                    viewOrder.Id(orderDetail.Id());
                    viewOrder.OrderNumber(orderDetail.OrderNumber());
                    viewOrder.OrderStatus(orderDetail.OrderStatus());
                    viewOrder.OrderStatusName(orderDetail.OrderStatusName());
                    viewOrder.OrderTypeName(orderDetail.OrderTypeName());
                    viewOrder.CreateDate(orderDetail.CreateDate());
                    viewOrder.EstimatedCOEDate(orderDetail.EstimatedCOEDate());

                    if (orderDetail.TransactionTypeCode == 'UNKN')
                    {
                        viewOrder.TransactionTypeCode(orderDetail.TransactionTypeName());
                    } else
                    {
                        viewOrder.TransactionTypeCode(orderDetail.TransactionTypeCode());
                    }
                    viewOrder.IsSP360ManualOrder(orderDetail.IsSP360ManualOrder);

                    viewOrder.ProviderType(orderDetail.ProviderType());
                    viewOrder.TransactionTypeCode(orderDetail.TransactionTypeCode());
                    viewOrder.TransactionAmount(orderDetail.TransactionAmount());
                    viewOrder.LimitedCoveragePolicy(orderDetail.LimitedCoveragePolicy());
                    viewOrder.PropertyAddressHTML(orderDetail.PropertyAddressHTML());
                    viewOrder.PropertyTypeName(orderDetail.PropertyTypeName());
                    viewOrder.SubmittedByEmail(orderDetail.SubmittedByEmail());
                    viewOrder.SubmittedByFullName(orderDetail.SubmittedByFullName());
                    viewOrder.SubmittedBySalesRep(orderDetail.SubmittedBySalesRep());
                    viewOrder.SubmittedBySalesRepEmail(orderDetail.SubmittedBySalesRepEmail());
                    viewOrder.SpecialInstructions(orderDetail.SpecialInstructions());
                    viewOrder.Principals(orderDetail.Principals());
                    viewOrder.Logs(orderDetail.Logs());
                    viewOrder.Offices(orderDetail.Offices());
                    viewOrder.Statuses(orderDetail.Statuses());
                    viewOrder.TransactionParties(orderDetail.TransactionParties());

                    $('#mdonlineOrderDetails').modal('show');
                    //$('.modal-body', '#mdonlineOrderDetails').scrollTop(0);
                }

            }).fail(function ()
            {
                alert.error("Error in getting Order details");
            });
        }

        function PrintOnlineOrderDetails()
        {
            //get the modal box content and load it into the printable div
            //var elementId = "onlineOrderDetails";
            //var divToPrint = document.getElementById(elementId);
            //var printableDiv = document.getElementById("printable");
            //printableDiv.style = "margin-top:20px";
            //printableDiv.innerHTML = divToPrint.innerHTML;
            //printableDiv.insertAdjacentHTML('afterbegin', '<h3 class="text-center mt-3">Online Order Details</h3>');

            //window.print();

            //printableDiv.innerHTML = "";

            $('#onlineOrderDetails').printThis(
                {
                    header: '<h3 class="text-center mt-1">Online Order Details</h1>'
                });
        }

        function EmailOnlineOrderDetails()
        {
            console.log("EmailOnlineOrderDetails : Pop-up open");
            orderSubmittedByEmail(vm.viewOrder.SubmittedByEmail());
            emailMessage("");
            var sub = "Online Order Id : " + vm.viewOrder.Id();
            emailSubject(sub);
            $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('show');
            $('.modal-body', '#mdEmailAcknowledgmentOnlineOrderDetails').scrollTop(0);
        }


        function SendEmailAcknowledgment()
        {
            if (vm.emailMessageError != null && vm.emailMessageError != 'undefined' && vm.emailMessageError().length > 0)
            {
                vm.emailMessageError.showAllMessages();
                return;
            }


            //var dictEmailTo = {
            //    "Key": vm.viewOrder.SubmittedByEmail(), "Value": vm.viewOrder.SubmittedByEmail()
            //};
            //var to = [];
            //to.push(dictEmailTo);
            var url = config.emailAcknowledgmentForOrderDetailsUrl;

            var emailRequest = {
                "SenderName": session.fullName(),
                "SenderEmail": session.email(),
                "Subject": emailSubject(),
                "EmailHTML": emailMessage(),
                "To": vm.viewOrder.SubmittedByEmail()
            }

            return https.post(url, emailRequest)
                .done(function (data)
                {
                    $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('hide');
                    if (data != null)
                    {
                        if (data.HasErrors == false)
                        {
                            alert.success("Email Sent", "Email Acknowledgment For Online Order Details");
                        }
                        else
                        {
                            alert.error(data.Messages[0].Message, "Email Acknowledgment For Online Order Details");
                        }
                    }
                    else
                    {
                        alert.error('Internal Server Error', "Email Acknowledgment For Online Order Details");
                    }
                }).fail(function ()
                {
                    $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('hide');
                    alert.error('Error while sending email', "Email Acknowledgment For Online Order Details");
                });
        }

        var vm = {
            canActivate: canActivate,
            activate: activate,
            searchResults: searchResults,
            searchOrders: searchOrders,
            clearSearch: clearSearch,
            orderSearchModel: orderSearchModel,
            orderSearchResultsModel: orderSearchResultsModel,
            //getSelectedValue: getSelectedValue,
            //printOrderDetail: printOrderDetail,
            //changeimage: changeimage,
            //itemClicked: itemClicked,
            clearSessionData: clearSessionData,
            //isCustomer: isCustomer,
            orderSubmittedByEmail: orderSubmittedByEmail,
            viewOrder: viewOrder,
            SendEmailAcknowledgment: SendEmailAcknowledgment,
            PrintOnlineOrderDetails: PrintOnlineOrderDetails,
            EmailOnlineOrderDetails: EmailOnlineOrderDetails,
            recentResult_click: recentResult_click,
            emailMessage: emailMessage,
            emailSubject: emailSubject,
            orderSubmittedByEmail: orderSubmittedByEmail,
            compositionComplete: function ()
            {
                session.validateInput();
                $('#mdEmailAcknowledgmentOnlineOrderDetails').modal('hide');
                $("#panel1Content").on('shown.bs.collapse', function ()
                {
                    $(".panel1Icon").removeClass("fa-arrow-down").addClass("fa-arrow-up");
                });
                $("#panel1Content").on('hidden.bs.collapse', function ()
                {
                    $(".panel1Icon").removeClass("fa-arrow-up").addClass("fa-arrow-down");
                });
            }
        }

        return vm;

    });

