define('models/userProfile/busCardLinksModel',["knockout"],
    function (ko) {

        console.log('Business Card Links Model called...');


        function busCardLink() {

            var self = this;
            self.title = ko.observable("Business Card Links");
            self.busCardLinksList = ko.observableArray([]);
            self.id = ko.observable("");
            self.userId = ko.observable("");
            self.operationId = ko.observable("");
            self.linkName = ko.observable("")
                .extend({ required: { message: 'Link Name is required.' } })
                .extend({
                pattern: {
                    message: 'Special characters not allowed',
                    params: /^(a-z|A-Z|0-9)*[^<>#$%^&*()']*$/
                }
            });



            //    '^[a-zA-Z0-9].$', message: 'Special characters not allowed.' });
            //.extend({ pattern: '^[a-zA-Z0-9].$', message: 'Special characters not allowed.' });


            self.order = ko.observable('');
            self.linkUrl = ko.observable("")
                .extend({ required: { message: 'Link URL is required.' } })
                .extend({
                    pattern: {
                        message: 'URL must be a valid URL (Ex: http://www.fnf.com)',
                        params: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
                    }
                });


            self.reset = function () {
                self.id("");
                self.userId("");
                self.operationId("");
                self.linkName("");
                self.linkUrl("");
                self.order(-1);

                ko.validation.group(self).showAllMessages(false);

            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            };


            self.update = function (data) {
                if (data) {
                    self.id(data.id);
                    self.userId(data.userId);
                    self.operationId(data.operationId);
                    self.linkName(data.linkName);
                    self.linkUrl(data.linkUrl);
                    self.order(data.index);

                }
            }
        }

        



            self.update = function (data) {
                if (data) {
                    self.id(data.id);
                    self.userId(data.userId);
                    self.operationId(data.operationId);
                    self.linkName(data.linkName);
                    self.linkUrl(data.linkUrl);
                    self.order(data.index);
                }
            }
        

        var vm = {
            busCardLink: busCardLink,
            //busCardLinks: busCardLinks

        };


        return vm;
    });
