define('viewmodels/buyersellers/buyersellers',["knockout", 'plugins/http', 'global/session', 'config', 'utlities'],
    function (ko, https, session, config, utlities) {
        var vm = {
            activate: activate,
            session: session,
            compositionComplete: compositionComplete
        }
        return vm;

        function compositionComplete() {
            session.validateInput();
        }

        function activate() {
            var redirectUrl = 'http://ctic.com/Consumers.aspx';
            session.CheckPopUpBlock(redirectUrl);
            //window.open(redirectUrl, '_blank');
            router.navigateBack();
        }
    });
