define('viewmodels/administration/viewPendingApprovals',['plugins/router', 'knockout', 'durandal/app', 'plugins/http', 'global/session', 'config', 'moment', 'alertNotification', 'koPaging', 'pendingApprovalService', 'plugins/dialog'],
    function (router, ko, app, https, session, config, moment, alerter, koPaging, pendingApprovalService, dialog) {

        //var pendingApproval = new models.PendingApprovalModel();
        var pendingApprovals = ko.observableArray([]);
        var isDialog = ko.observable(false);
        var self = this;

        var vm = {
            canActivate: canActivate,
            activate: activate,
            canDeactivate: canDeactivate,
            pendingApprovals: pendingApprovals,
            openAdmin: openAdmin,
            session: session,
            closeDialog: closeDialog,
            isDialog: isDialog,
            bindingComplete: bindingComplete
        };

        return vm;

        function bindingComplete() {
            //console.log('is dialog ' + dialog.getDialog(this));
            var pnldialog = dialog.getDialog(this);
            isDialog(pnldialog != undefined);
            //console.log('is dialog ' + isDialog());
          
        }

        function canActivate() {

            //if (!session.userIsInRole("TransactionViewer"))
            //    return false;

            

            if (!session.isLoggedIn()) {
                router.navigate('#login', { replace: false, trigger: true });
                return false;
            }
            if (session.userTypeIsEmployee()) {

                return pendingApprovalService.checkPendingApproval().then(function () {
                    if (pendingApprovalService.arePendingApprovals()) {
                        return true;
                    }
                    else {
                        app.showMessage('No customers registrations to approve.','Customer approval');
                        return false;
                    }
                });

                return true;
            }
            else {
                return false;
            }
        }

        

        function activate() {

            session.pendingApprovals([]);
            pendingApprovalService.arePendingApprovals(false);
            //clearSearch();
            //searchResults.extend({
            //    paged: {
            //        pageNumber: 1,
            //        pageSize: 10,
            //        pageGenerator: 'default'
            //    }
            //});
            isDialog(dialog.getDialog(this));
            //console.log('is dialog ' + dialog.getDialog(this));

            //pendingApprovalService.checkPendingApproval().then(function () {
            //    if (pendingApprovalService.arePendingApprovals()) {
            //        if (isDialog()) {
            //            document.getElementById('dvPendingApprovals').style.marginTop = '-290px';
            //        }
            //        session.pendingApprovals(pendingApprovalService.pendingApprovals());
            //    }
            //});

            
            session.pendingApprovals(pendingApprovalService.pendingApprovals());
            if (isDialog()) {
                document.getElementById('dvPendingApprovals').style.marginTop = '-290px';
            }
        }

        function openAdmin() {
            pendingApprovalService.openAdmin();
            if (dialog.getDialog(this))
            dialog.close(this, null);
        }

        function closeDialog() {
            if (dialog.getDialog(this))
                dialog.close(this, null);
        }

        //self.close = function (result) {
        //    if (dialog.getDialog(self))
        //        dialog.close(self, result);
        //}

        function canDeactivate() {
            //session.pendingApprovals([]);
            //clearSearch();
            //session.clearOrderForPropertyAddress();
            return true;
        }

    });
