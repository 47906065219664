define('models/propertySearch/propertySearch',["knockout", "suspendNotification", "config"],
    function (ko, suspendNotification, config) {
        var addressSearchInitialVal;
        function ApnSearch() {

            var self = this;
            self.latitude = ko.observable();
            self.longitude = ko.observable();
            self.landusecode = ko.observable();
            self.radius = ko.observable();

            self.state = ko.observable(undefined)
                .extend({ required: { params: true, message: 'State is required.' } });

            self.county = ko.observable(undefined)
                .extend({ required: { params: true, message: 'County is required.' } })

            self.zip = ko.observable("")
                //    .extend({
                //    required: {
                //        message: "Zip is required.",
                //        onlyIf: function () { return (self.state() == "" || self.county() == ""); }
                //    }
                //})
                .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })




            self.apnNumber = ko.observable("")
                .extend({ required: { params: true, message: 'APN is required.' } })

            self.update = function (data) {
                if (data) {
                    self.zip(data.zip);
                    self.state(data.state);
                    self.county(data.county);
                    self.apnNumber(data.apnNumber);

                }

            };

            self.hasCountyFocus = ko.observable();
            self.onCountyChange = function (options) {
                if (self.hasCountyFocus() == true)
                    self.zip('');
            }

            self.reset = function () {
                self.zip('');
                self.state(undefined);
                self.county(undefined);
                self.apnNumber('');
                ko.validation.group(self).showAllMessages(false);
            }
        }

        function OwnerSerach() {

            var self = this;

            self.state = ko.observable(undefined)
                .extend({ required: { params: true, message: 'State is required.' } });


            self.county = ko.observable(undefined)
                .extend({ required: { params: true, message: 'County is required.' } });

            self.zip = ko.observable("")
                //    .extend({
                //    required: {
                //        message: "Zip is required.",
                //        onlyIf: function () { return (self.state() == "" || self.county() == ""); }
                //    }
                //})
                .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' });



            self.firstName = ko.observable("");

            self.lastName = ko.observable("")
                .extend({
                    required: {
                        message: "At least Last Name is required" //,
                        //onlyIf: function () { return self.firstName().length == 0; }
                    }
                });


            //    .extend({
            //    required: {
            //        message: "At least First Name is required",
            //        onlyIf: function () { return self.lastName().length==0; }
            //    }
            //});

            self.hasCountyFocus = ko.observable();
            self.onCountyChange = function (options) {
                if (self.hasCountyFocus() == true)
                    self.zip('');
            }

            self.hasStateFocus = ko.observable();
            self.onStateChange = function (options) {
                if (self.hasStateFocus() == true)
                    self.zip('');
            }

            self.update = function (data) {
                if (data) {
                    self.zip(data.zip);
                    self.state(data.state);
                    self.county(data.county);
                    self.firstName(data.firstName);
                    self.lastName(data.lastName);

                }

            };

            self.reset = function () {
                self.zip('');
                self.state(undefined);
                self.county(undefined);
                self.firstName('');
                self.lastName('');

                ko.validation.group(self).showAllMessages(false);
            }
        }

        function AddressSerach(state, city, zip, streetNumber, streetName, unitNumber) {
            addressSearchInitialVal = {
                state: state,
                city: city,
                zip: zip,
                streetNumber: streetNumber,
                streetName: streetName,
                unitNumber: unitNumber,
                lastUpdated: Date()
            };
            var self = this;
            self.isResetting = false;
            self.state = ko.observable(state).extend({ required: { params: true, message: 'State is required.' } })

            self.city = ko.observable(city).extend({ required: { params: true, message: 'City is required.' } })

            self.zip = ko.observable(zip)
                //  .extend({
                //    required: {
                //        message: "Zip is required.",
                //        onlyIf: function () { return (self.state()== "" || self.city()== ""); }
                //    }
                //})
                .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.streetNumber = ko.observable((streetNumber == undefined ? "" : streetNumber));
            self.streetName = ko.observable((streetName == undefined ? "" : streetName))
                .extend({
                    required: {
                        message: "At least Street Name is required",
                        onlyIf: function () { return self.streetNumber().length == 0; }
                    }
                }).extend({ maxLength: { params: 250, message: '250 or less characters are allowed.' } });

            self.unitNumber = ko.observable((unitNumber == undefined ? "" : unitNumber));

            self.zipInFocus = ko.observable(false);

            self.stateInFocus = ko.observable(false);

            self.update = function (data) {
                if (data) {
                    self.zip(data.zip);
                    self.state(data.state);
                    self.city(data.city);
                    self.streetNumber(data.streetNumber);
                    self.streetName(data.streetName);
                    self.unitNumber(data.unitNumber);
                }
            };

            self.state.subscribe(function (newValue) {
                //console.log('self.state.subscribe ', newValue, self.isResetting);
                if (self.isResetting == false) {
                    self.zip('');
                    self.city('');
                    //console.log('self.state.subscribe done ', newValue);
                }
            });

            self.city.subscribe(function (newValue) {
                //console.log('self.city.subscribe ', newValue, self.isResetting);
                if (self.isResetting == false) {
                    self.zip('');
                    //console.log('self.city.subscribe done', newValue);
                }
            });

            self.reset = function (state, city, zip, streetNumber, streetName, unitNumber) {
                //console.log('self.reset ', state, city, zip, streetNumber, streetName, unitNumber, self.isResetting);
                if (self.isResetting == false) {
                    self.isResetting = true;
                    if (self.zip() != zip) self.zip(zip);
                    if (self.state() != state) self.state(state);
                    if (self.city() != city) self.city(city);
                    self.streetNumber((streetNumber == undefined ? '' : streetNumber));
                    self.streetName((streetName == undefined ? '' : streetName));
                    self.unitNumber((unitNumber == undefined ? '' : unitNumber));

                    ko.validation.group(self).showAllMessages(false);
                    setTimeout(function () {
                        self.isResetting = false;
                        //console.log('self.reset completed', state, city, zip, streetNumber, streetName, unitNumber);
                    }, 250);
                }
            }
        }

        function StreetLookUpSearch() {
            var self = this;
            self.state = ko.observable(undefined).extend({ required: { params: true, message: 'State is required.' } });
            self.county = ko.observable(undefined).extend({ required: { params: true, message: 'County is required.' } });
            self.houseNumber = ko.observable("");
            self.direction = ko.observable("");
            //.extend({ required: { params: true, message: 'Direction is required.' } });
            self.streetName = ko.observable("").extend({ required: { params: true, message: 'Street Name is required.' } });
            self.postDirection = ko.observable("");
            self.suffix = ko.observable("");
            self.city = ko.observable("");//.extend({ required: { params: true, message: 'City is required.' } });

            self.zip = ko.observable("")
                //    .extend({
                //    required: {
                //        message: "Zip is required.",
                //        onlyIf: function () { return (self.state() == "" || self.county() == ""); }
                //    }
                //})
                .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.update = function (data) {
                if (data) {
                    self.state(data.state);
                    self.county(data.county);
                    self.houseNumber(data.houseNumber);
                    self.direction(data.direction);
                    self.streetName(data.streetName);
                    self.streetName(data.streetName);
                    self.postDirection(data.postDirection);
                    self.suffix(data.suffix);
                    self.city(data.city);
                    self.zip(data.zip);
                }

            };

            self.reset = function () {
                self.state(undefined);
                self.county(undefined);
                self.houseNumber('');
                self.direction('');
                self.streetName('');
                self.streetName('');
                self.postDirection('');
                self.suffix('');
                self.city('');
                self.zip('');
                ko.validation.group(self).showAllMessages(false);
            }

            self.hasCountyFocus = ko.observable();
            self.onCountyChange = function (options) {
                if (self.hasCountyFocus() == true) {
                    self.city('');
                    self.zip('');
                }
            }

            self.hasStateFocus = ko.observable();
            self.onStateChange = function (options) {
                if (self.hasStateFocus() == true) {
                    self.zip('');
                    self.city('');
                }
            }
        }


        function CustomCompSettings() {

            var self = this;

            self.autoComparables = ko.observable(true)
            self.customComparables = ko.observable("")
            self.numberofSales = ko.observable(15)
            self.bedrooms = ko.observable(2)
            self.bathrooms = ko.observable(2)
            self.squareFootage = ko.observable(0.25)

            self.yearBuiltRangeFrom = ko.observable(0)
                .extend({ maxLength: { params: 4, message: 'Only 4 digit year is allowed.' } })
                .extend({ minLength: { params: 4, message: '4 digit year is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 4 digit year is allowed.' })

            self.yearBuiltRangeTo = ko.observable(0)
                .extend({ maxLength: { params: 4, message: 'Only 4 digit year is allowed.' } })
                .extend({ minLength: { params: 4, message: '4 digit year is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 4 digit year is allowed.' })

            self.ageofSales = ko.observable(6)
            self.saleAmountFrom = ko.observable("")
            self.saleAmountTo = ko.observable("")
            self.radius = ko.observable(0.25)
            self.sameLegalTract = ko.observable("")
            self.lotSize = ko.observable(0.25)
            
            self.blurAmtFrm = function () {
                var amtFrom = Number(self.saleAmountFrom().replace(/[^0-9\.]+/g, ""));
                if (isNaN(amtFrom))
                    amtFrom = 0;
                self.saleAmountFrom('$' + amtFrom.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));

            }

            self.blurAmtTo = function () {
                var amtTo = Number(self.saleAmountTo().replace(/[^0-9\.]+/g, ""));
                if (isNaN(amtTo))
                    amtTo = 0;
                self.saleAmountTo('$' + amtTo.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));

            }

            self.salesList = ko.observableArray([
                { key: "5", value: "5" },
                { key: "10", value: "10" },
                { key: "15", value: "15" },
                { key: "20", value: "20" },
                { key: "25", value: "25" },
                { key: "30", value: "30" }
            ]);

            self.bedroomsList = ko.observableArray([
                { key: "0", value: "0" },
                { key: "1", value: "1" },
                { key: "2", value: "2" },
                { key: "3", value: "3" },
                { key: "4", value: "4" },
                { key: "5", value: "5" },
                { key: "6", value: "6" },
                { key: "7", value: "7" },
                { key: "8", value: "8" },
                { key: "9", value: "9" },
                { key: "10", value: "10" },
                { key: "11", value: "11" },
                { key: "12", value: "12" },
                { key: "13", value: "13" }
            ]);

            self.bathroomsList = ko.observableArray([
                { key: "0", value: "0" },
                { key: "1", value: "1" },
                { key: "2", value: "2" },
                { key: "3", value: "3" },
                { key: "4", value: "4" },
                { key: "5", value: "5" },
                { key: "6", value: "6" },
                { key: "7", value: "7" },
                { key: "8", value: "8" },
                { key: "9", value: "9" },
                { key: "10", value: "10" }

            ]);

            self.squareFootageList = ko.observableArray([
                { key: "0.05", value: "5%" },
                { key: "0.10", value: "10%" },
                { key: "0.15", value: "15%" },
                { key: "0.20", value: "20%" },
                { key: "0.25", value: "25%" },
                { key: "0.30", value: "30%" },
                { key: "0.35", value: "35%" },
                { key: "0.40", value: "40%" },
                { key: "0.45", value: "45%" },
                { key: "0.50", value: "50%" },
                { key: "0.55", value: "55%" },
                { key: "0.60", value: "60%" },
                { key: "0.65", value: "65%" },
                { key: "0.70", value: "70%" },
                { key: "0.75", value: "75%" },
                { key: "0.80", value: "80%" },
                { key: "0.85", value: "85%" },
                { key: "0.90", value: "90%" },
                { key: "0.95", value: "95%" }
            ]);

            self.ageofSalesList = ko.observableArray([
                { key: "3", value: "3 Mo." },
                { key: "6", value: "6 Mo." },
                { key: "9", value: "9 Mo." },
                { key: "12", value: "12 Mo." },
                { key: "15", value: "15 Mo." },
                { key: "18", value: "18 Mo." },
                { key: "21", value: "21 Mo." },
                { key: "24", value: "24 Mo." }
            ]);

            self.radiusList = ko.observableArray([
                { key: "0.25", value: "0.25 Mi." },
                { key: "0.50", value: "0.5 Mi." },
                { key: "1.00", value: "1.0 Mi." },
                { key: "1.50", value: "1.5 Mi." },
                { key: "2.00", value: "2.0 Mi." },
                { key: "2.50", value: "2.5 Mi." },
                { key: "3.00", value: "3.0 Mi." },
                { key: "3.50", value: "2.5 Mi." },
                { key: "4.00", value: "4.0 Mi." },
                { key: "4.50", value: "4.5 Mi." },
                { key: "5.00", value: "5.0 Mi." }
            ]);

            self.lotSizeList = ko.observableArray([
                { key: "0.05", value: "5%" },
                { key: "0.10", value: "10%" },
                { key: "0.15", value: "15%" },
                { key: "0.20", value: "20%" },
                { key: "0.25", value: "25%" },
                { key: "0.30", value: "30%" },
                { key: "0.35", value: "35%" },
                { key: "0.40", value: "40%" },
                { key: "0.45", value: "45%" },
                { key: "0.50", value: "50%" },
                { key: "0.55", value: "55%" },
                { key: "0.60", value: "60%" },
                { key: "0.65", value: "65%" },
                { key: "0.70", value: "70%" },
                { key: "0.75", value: "75%" },
                { key: "0.80", value: "80%" },
                { key: "0.85", value: "85%" },
                { key: "0.90", value: "90%" },
                { key: "0.95", value: "95%" }
            ]);

            self.update = function (data) {
                if (data) {
                    self.autoComparables(data.autoComparables)
                    self.customComparables(data.customComparables)
                    self.numberofSales(data.numberofSales)
                    self.bedrooms(data.bedrooms)
                    self.bathrooms(data.bathrooms)
                    self.squareFootage(data.squareFootage)
                    self.yearBuiltRangeFrom(data.yearBuiltRangeFrom)
                    self.yearBuiltRangeTo(data.yearBuiltRangeTo)
                    self.ageofSales(data.ageofSales)
                    self.saleAmountFrom(data.saleAmountFrom)
                    self.saleAmountTo(data.saleAmountTo)
                    self.radius(data.radius)
                    self.sameLegalTract(data.sameLegalTract)
                    self.lotSize(data.lotSize)
                }

            };

            self.reset = function () {

                self.autoComparables("")
                self.customComparables("")
                self.numberofSales("")
                self.bedrooms("")
                self.bathrooms("")
                self.squareFootage("")
                self.yearBuiltRangeFrom("")
                self.yearBuiltRangeTo("")
                self.ageofSales("")
                self.saleAmountFrom("")
                self.saleAmountTo("")
                self.radius("")
                self.sameLegalTract("")
                self.lotSize("")
                
                ko.validation.group(self).showAllMessages(false);
            }
        }



        var vm = {
            ApnSearch: ApnSearch,
            OwnerSerach: OwnerSerach,
            AddressSerach: AddressSerach,
            StreetLookUpSearch: StreetLookUpSearch,
            CustomCompSettings: CustomCompSettings
        };

        return vm;
    }
);
