//TODO: Inject dependencies
define('viewmodels/dashboardModule/dashboard',['plugins/router', '_BrandLogo', 'global/session'],
    function (router,logo,session) {
        // Internal properties and functions
       
        // Reveal the bindable properties and functions
        var vm = {
            activate: activate,
            goBack: goBack,
            
            title: 'dashboard'
        };

        return vm;
        
        function activate (id, querystring) {
            //TODO: Initialize lookup data here.

            //TODO: Get the data for this viewmodel, return a promise.
            //logo.reBindLogo();
        }

        function goBack(complete) {
            router.navigateBack();
        }

       
    });


