define('viewmodels/propertySearchModule/customCompSettings',['durandal/app', 'knockout', 'plugins/http', 'config', 'alertNotification'],
    function (app, ko, https, config, alert) {
  
    //var customCompSettingsModel = new model.CustomCompSettings();
    var vm = {
        //customCompSettingsModel: customCompSettingsModel
    };

    //function saveCompSettings() {
    //    var settings = {
    //        "AutoComparables": customCompSettingsModel.autoComparables,
    //        "NumberOfSales": customCompSettingsModel.numberofSales,
    //        "BedroomsRange": customCompSettingsModel.bedrooms,
    //        "BathroomsRange": customCompSettingsModel.bathrooms,
    //        "SquareFootageRange": customCompSettingsModel.squareFootage,
    //        "DateBuiltRangeBegin": customCompSettingsModel.yearBuiltRangeFrom,
    //        "DateBuiltRangeEnd": customCompSettingsModel.yearBuiltRangeTo,
    //        "AgeOfSaleInMonths": customCompSettingsModel.ageofSales,
    //        "SaleAmountRangeBegin": customCompSettingsModel.saleAmountFrom,
    //        "SaleAmountRangeEnd": customCompSettingsModel.saleAmountTo,
    //        "Radius": customCompSettingsModel.radius,
    //        "SameLegalTract": customCompSettingsModel.sameLegalTract,
    //    };
    //    url = $.getBasePath() + "api/Property/SaveProfileCustomSettingsForUser?userId=" + session.userId;
    //  return  https.post(url, settings)
    //           .then(function (data) {
    //               if (data) {
    //                   alert.success("custom settings saved successfully.");
    //                   console.log(data);
    //               }

    //           })
    //            .fail(Failed);
    //}
  
    
    function Failed(error) {
        alert.error(error.statusText);

    }

    return vm;
});


