define('viewmodels/operationInfo/operationInfo',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'knockout.validation', 'plugins/http', 'global/session', 'config', 'alertNotification', 'utlities','operationInfoModel'],
 function (app, router, $, ko, validate, validation, https, session, config, alert, utlities,model) {
     var officesList = ko.observableArray();
     var officeLocatorModel = new model.OfficeDetails();

     function activate() {
         getOfficesByOperationId(session.defaultOpertionId());
     }   

     function getOfficesByOperationId(operationId) {
         getOfficesList(operationId).done(function (result) {
             officesList(result);

         });
     }

     function getOfficesList(operationId) {
         var url = config.getOfficesUrl;
         return https.get(url, {operationId: operationId})

     }

     function getUSPhoneFormat(data) {
         var phoneNumber = data;
         phoneNumber = phoneNumber.replace(/\D/g, '');
         phoneNumber = phoneNumber.replace(/\s/g, '');
         phoneNumber = phoneNumber.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
         if (phoneNumber.length == 10) {
             return utlities.getUSPhoneFormat(phoneNumber);
         }
         else {
             return "Invalid Number";
         }
     }

     function compositionComplete() {
         session.validateInput();
     }

     var vm = {
         activate: activate,
         officesList: officesList,
         session: session,
         getUSPhoneFormat: getUSPhoneFormat,
         officeLocatorModel: officeLocatorModel,
         compositionComplete: compositionComplete
     }
     return vm;

 });
