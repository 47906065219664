define('models/userProfile/teamSettingsModel',["knockout"],
    function (ko) {

        console.log('Team Settings Model called...');


        function TeamSettingsModel() {

            var self = this;
            self.title = ko.observable("Team Profile")
            self.fName = ko.observable("")
            self.mName = ko.observable("")
            self.lName = ko.observable("")
            self.email = ko.observable("")
            self.department = ko.observable("")
            self.workPhoneExt = ko.observable("")
            .extend({ digit: true });
            self.workPhone = ko.observable("")
            .extend({
                phoneUS: true
            })
            .extend({
                required: {
                    message: "Phone (Work) is required",
                    onlyIf: function () {
                        return (self.workPhoneExt() && self.workPhoneExt().length > 0);
                    }
                }
            });
           
            self.faxPhone = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });
            self.photo = ko.observable("")
            self.photoUrl = ko.observable("")
            self.birthday = ko.observable("")
            self.acceptEmail = ko.observable("")
            //self.setAccount = ko.observable()
            // Extensions
            self.email.extend({ required: { message: 'Email is required.' } })
                .extend({ email: { message: 'Invalid Email Address.' } })
                .extend({ maxLength: { params: 200, message: 'Invalid Email Address.' } })
            self.fName.extend({ required: { message: 'First Name is required.' } })
            self.lName.extend({ required: { message: 'Last Name is required.' } })

            self.birthday.subscribe(function (date) {
                if (date) {
                    if (date.indexOf('T') > -1) {
                        self.birthday(date.split('T')[0]);
                    }
                }
            });

            self.ResetModel = function () {
                self.fName("");
                self.mName("");
                self.lName("");
                self.email("");
                self.department("");
                self.workPhone(""); 
                self.workPhoneExt("");
                self.faxPhone("");
                self.photo(""); 
                self.photoUrl("");
                self.birthday("");
                self.acceptEmail("");
                ko.validation.group(self).showAllMessages(false);
                
            }


            self.UpdateModel = function (data) {
                if (data) {
                    self.fName(data.FirstName);
                    self.mName(data.MiddleName);
                    self.lName(data.LastName);
                    self.email(data.Email);
                    self.department(data.Department);
                    self.workPhone(data.PhoneWork);
                    self.workPhoneExt(data.PhoneWorkExt);
                    self.faxPhone(data.PhoneFax);
                    self.photo(data.Photo);
                    self.photoUrl(data.PhotoUrl);
                    self.birthday(data.Birthday);
                    self.acceptEmail(data.ReceivesEmails);
                }
            }

        }




        var vm = {

            TeamSettingsModel: TeamSettingsModel

        }



        console.log('Team Settings Model returned...');

        return vm;
    });
