define('viewmodels/propertySearchModule/streetIndexLookUp',['durandal/app', 'knockout', 'plugins/http', 'config', 'alertNotification', 'propertySearchCommon'], function (app, ko, https, config, alert, propertySearchCommon) {
    var searchResults = ko.observableArray();
   
    var vm = {
        addressSearch: addressSearch,
        searchResults: searchResults
    };

    vm.searchResults.subscribe(propertySearchCommon.alertMultiplePropertyWarning);

    function compositionComplete() {
        session.validateInput();
    }

    function addressSearch(parentResultHolder, streetLookupModel, isSearchable) {
        var url = config.propertySearchUrl;
        var request = {
            State: streetLookupModel.state,
            //Phil needs to expose County Property.
            County: streetLookupModel.county(),
            HouseNumberMin: streetLookupModel.houseNumber().trim(),
            HouseNumberMax: streetLookupModel.houseNumber().trim(),
            Direction: streetLookupModel.direction,
            StreetName: streetLookupModel.streetName().trim(),
            PostDirection: streetLookupModel.postDirection,
            Suffix: streetLookupModel.suffix().trim(),
            City: streetLookupModel.city().trim(),
            Zip: streetLookupModel.zip().trim(),
            Fips: streetLookupModel.county(),
            SearchType: 'Advanced',
            compositionComplete: compositionComplete

        };
        console.log(url);

       return https.post(url, request)
          .then(function (data) {
              searchResults(data);
              parentResultHolder(data);
          })
           .always(function () {
              isSearchable(true);
          })
            .fail(Failed);
    }



    function Failed(error) {
        alert.error(error.statusText);

    }

    return vm;
});


