define('mobileHomeEscrowModel',["knockout", "modelMetaData"],
    function (ko, modelMetaData) {
        function EscrowInformation() {
            var self = this;
            self.viewName = "orderModule/_escrowInformation.html"
            self.title = "Escrow Information"
            self.ModelId = "EscrowInformation"
            self.index = ko.observable(-1)

            self.escrowNumber = ko.observable("")
            self.escrowCloseDate = ko.observable("")
            self.escrowOfficer = ko.observable("")
            self.companyName = ko.observable("")
            self.propertyAddress = ko.observable("")
            .extend({ required: { message: 'Property Address is required.' } })
            .extend({
                maxLength: {
                    params: 200, message: 'Property Address must be 200 characters or less'
                }
            })
            //.extend({
            //    pattern: {
            //        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
            //        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
            //    }
            //});

            self.make = ko.observable("")
            self.model = ko.observable("")
            self.year = ko.observable("")
            .extend({ digit: true });
            self.size = ko.observable("")
            self.licenseorDecal = ko.observable("")
            self.serial = ko.observable("")
            self.doh = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.escrowNumber(data.escrowNumber)
                    self.escrowCloseDate(data.escrowCloseDate)
                    self.escrowOfficer(data.escrowOfficer)
                    self.companyName(data.companyName)
                    self.propertyAddress(data.propertyAddress)
                    self.make(data.make)
                    self.model(data.model)
                    self.year(data.year)
                    self.size(data.size)
                    self.licenseorDecal(data.licenseorDecal)
                    self.serial(data.serial)
                    self.doh(data.doh)
                }

            };

            self.reset = function () {
                self.escrowNumber('')
                self.escrowCloseDate('')
                self.escrowOfficer('')
                self.companyName('')
                self.propertyAddress('')
                self.make('')
                self.model('')
                self.year('')
                self.size('')
                self.licenseorDecal('')
                self.serial('')
                self.doh('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Escrow Number", self.escrowNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Date for Close of Escrow", self.escrowCloseDate()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Name of Escrow Officer", self.escrowOfficer()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Company Name", self.companyName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Address", self.propertyAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Make", self.make()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Model", self.model()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Year", self.year()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Size", self.size()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("License/Decal #", self.licenseorDecal()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Serial #", self.serial()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("DOH #", self.doh()));

            }
        }

        function MobileHomeSellerInfo() {
            var self = this;
            self.viewName = "orderModule/mobileHome/_mobileHomeSellerInfo.html"
            self.title = "Seller Information"
            self.ModelId = "SellerInformation"
            self.index = ko.observable(-1)
            self.sellerName = ko.observable("")
             .extend({ required: { message: 'Seller Name is required.' } })
            self.sellerAddress = ko.observable("")
             .extend({ required: { message: 'Seller Address is required.' } })
             .extend({
                 maxLength: {
                     params: 200, message: 'Seller Address must be 200 characters or less'
                 }
             })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self.sellerHomePhone = ko.observable("")
            .extend({ phoneUS: true })
            self.sellerBusinessPhone = ko.observable("")
            .extend({ phoneUS: true })
            .extend({
                required: {
                    message: "Seller Business Phone is required",
                    onlyIf: function () {
                        return self.ext().length > 0;
                    }
                }
            })
            self.ext = ko.observable("")
            .extend({ digit: true });

            self.update = function (data) {
                if (data) {
                    self.sellerName(data.sellerName)
                    self.sellerAddress(data.sellerAddress)
                    self.sellerHomePhone(data.sellerHomePhone)
                    self.sellerBusinessPhone(data.sellerBusinessPhone)
                    self.ext(data.ext)
                }

            };

            self.reset = function () {
                self.sellerName('')
                self.sellerAddress('')
                self.sellerHomePhone('')
                self.sellerBusinessPhone('')
                self.ext('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller's Name", self.sellerName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller's Address", self.sellerAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller's Home Phone", self.sellerHomePhone()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller's Business Phone", modelMetaData.appendPhoneWithExtn(self.sellerBusinessPhone(), self.ext(), 'x')));
            }
        }

        function MobileHomeBuyerInfo() {
            var self = this;
            self.viewName = "orderModule/mobileHome/_mobileHomeBuyerInfo.html"
            self.title = "Buyer Information"
            self.ModelId = "BuyerInformation"
            self.index = ko.observable(-1)
            self.buyerName = ko.observable("")
            self.buyerAddress = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Buyer Address must be 200 characters or less' } })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self.buyerHomePhone = ko.observable("")
            .extend({ phoneUS: true })
            self.buyerBusinessPhone = ko.observable("")
            .extend({ phoneUS: true })
            .extend({
                required: {
                    message: "Buyer Business Phone is required",
                    onlyIf: function () {
                        return self.ext().length > 0;
                    }
                }
            })
            self.ext = ko.observable("")
            .extend({ digit: true });

            self.update = function (data) {
                if (data) {
                    self.buyerName(data.buyerName)
                    self.buyerAddress(data.buyerAddress)
                    self.buyerHomePhone(data.buyerHomePhone)
                    self.buyerBusinessPhone(data.buyerBusinessPhone)
                    self.ext(data.ext)
                }

            };

            self.reset = function () {
                self.buyerName('')
                self.buyerAddress('')
                self.buyerHomePhone('')
                self.buyerBusinessPhone('')
                self.ext('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer's Name", self.buyerName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer's Address", self.buyerAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer's Home Phone", self.buyerHomePhone()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Buyer's Business Phone", modelMetaData.appendPhoneWithExtn(self.buyerBusinessPhone(), self.ext(), 'x')));
            }
        }

        function MobileHomeListingAgentInfo() {
            var self = this;
            self.viewName = "orderModule/mobileHome/_mobileHomeListingAgentInfo.html"
            self.title = "Listing Agent Information"
            self.ModelId = "ListingAgentInformation"
            self.index = ko.observable(-1)
            self.listingAgentName = ko.observable("")
            self.listingAgentAddress = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Listing Agent Address must be 200 characters or less' } })
               .extend({
                   pattern: {
                       message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                       params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                   }
               });
            self.listingAgentBusinessPhone = ko.observable("")
            .extend({ phoneUS: true })
            .extend({
                required: {
                    message: "Listing Agent Business Phone is required",
                    onlyIf: function () {
                        return self.ext().length > 0;
                    }
                }
            })

            self.ext = ko.observable("")
            .extend({ digit: true });

            self.update = function (data) {
                if (data) {
                    self.listingAgentName(data.listingAgentName)
                    self.listingAgentAddress(data.listingAgentAddress)
                    self.listingAgentBusinessPhone(data.listingAgentBusinessPhone)
                    self.ext(data.ext)
                }

            };

            self.reset = function () {
                self.listingAgentName('')
                self.listingAgentAddress('')
                self.listingAgentBusinessPhone('')
                self.ext('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Agent's Name", self.listingAgentName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Agent's Address", self.listingAgentAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Listing Agent's Business Phone", modelMetaData.appendPhoneWithExtn(self.listingAgentBusinessPhone(), self.ext(), 'x')));
            }
        }

        function MobileHomeSellingAgentInfo() {
            var self = this;
            self.viewName = "orderModule/mobileHome/_mobileHomeSellingAgentInfo.html"
            self.title = "Selling Agent Information"
            self.ModelId = "SellingAgentInformation"
            self.index = ko.observable(-1)
            self.sellingAgentName = ko.observable("")
            self.sellingAgentAddress = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'Selling Agent Address must be 200 characters or less' } })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self.sellingAgentBusinessPhone = ko.observable("")
            .extend({ phoneUS: true })
             .extend({
                 required: {
                     message: "Selling Agent Business Phone is required",
                     onlyIf: function () {
                         return self.ext().length > 0;
                     }
                 }
             })
            self.ext = ko.observable("")
            .extend({ digit: true });
            self.update = function (data) {
                if (data) {
                    self.sellingAgentName(data.sellingAgentName)
                    self.sellingAgentAddress(data.sellingAgentAddress)
                    self.sellingAgentBusinessPhone(data.sellingAgentBusinessPhone)
                    self.ext(data.ext)
                }

            };

            self.reset = function () {
                self.sellingAgentName('')
                self.sellingAgentAddress('')
                self.sellingAgentBusinessPhone('')
                self.ext('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Agent's Name", self.sellingAgentName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Agent's Address", self.sellingAgentAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Selling Agent's Business Phone", modelMetaData.appendPhoneWithExtn(self.sellingAgentBusinessPhone(), self.ext(), 'x')));
            }
        }

        function MobileHomeLegalOwnerInfo() {
            var self = this;
            self.viewName = "orderModule/mobileHome/_mobileHomeLegalOwnerInfo.html"
            self.title = "Legal Owner Information"
            self.ModelId = "LegalOwnerInformation"
            self.index = ko.observable(-1)
            self.legalOwnerName = ko.observable("")
            self.legalOwnerAddress = ko.observable("")
            .extend({
                maxLength: {
                    params: 200, message: 'Legal Owner Address must be 200 characters or less'
                }
            })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self.legalOwnerHomePhone = ko.observable("")
            .extend({ phoneUS: true })
            self.legalOwnerFax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });

            self.update = function (data) {
                if (data) {
                    self.legalOwnerName(data.legalOwnerName)
                    self.legalOwnerAddress(data.legalOwnerAddress)
                    self.legalOwnerHomePhone(data.legalOwnerHomePhone)
                    self.legalOwnerFax(data.legalOwnerFax)
                }

            };

            self.reset = function () {
                self.legalOwnerName('')
                self.legalOwnerAddress('')
                self.legalOwnerHomePhone('')
                self.legalOwnerFax('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Legal Owner's Name", self.legalOwnerName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Legal Owner's Address", self.legalOwnerAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Legal Owner's Home Phone", self.legalOwnerHomePhone()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Legal Owner's Fax", self.legalOwnerFax()));
            }
        }

        function MobileHomeNewLenderInfo() {
            var self = this;
            self.viewName = "orderModule/mobileHome/_mobileHomeNewLenderInfo.html"
            self.title = "New Lender Information"
            self.ModelId = "NewLenderInformation"
            self.index = ko.observable(-1)
            self.newLenderName = ko.observable("")
            self.newLenderAddress = ko.observable("")
            .extend({ maxLength: { params: 200, message: 'New Lender Address must be 200 characters or less' } })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self.newLenderBusinessPhone = ko.observable("")
            .extend({ phoneUS: true })
            .extend({
                required: {
                    message: "New Lender Business Phone is required",
                    onlyIf: function () {
                        return self.ext().length > 0;
                    }
                }
            })
            self.ext = ko.observable("")
            .extend({ digit: true });
            self.newLenderFax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });

            self.loanNumber = ko.observable("")
            self.mobileHomeParkName = ko.observable("")
           
            self.parkAddress = ko.observable("")
            .extend({
                maxLength: { params: 200, message: 'Park Address must be 200 characters or less' }
            })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self.parkPhoneNumber = ko.observable("")
            .extend({ phoneUS: true })
             .extend({
                 required: {
                     message: "Park Phone Number is required",
                     onlyIf: function () {
                         return self.parkPhoneExt().length > 0;
                     }
                 }
             })
            self.parkPhoneExt = ko.observable("")
            .extend({ digit: true });
            self.space = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.newLenderName(data.newLenderName)
                    self.newLenderAddress(data.newLenderName)
                    self.newLenderBusinessPhone(data.newLenderName)
                    self.ext(data.newLenderName)
                    self.newLenderFax(data.newLenderFax)
                    self.loanNumber(data.loanNumber)
                    self.mobileHomeParkName(data.mobileHomeParkName)
                    self.parkAddress(data.parkAddress)
                    self.parkPhoneNumber(data.parkPhoneNumber)
                    self.parkPhoneExt(data.parkPhoneExt)
                    self.space(data.space)
                }

            };

            self.reset = function () {
                self.newLenderName('')
                self.newLenderAddress('')
                self.newLenderBusinessPhone('')
                self.ext('')
                self.newLenderFax('')
                self.loanNumber('')
                self.mobileHomeParkName('')
                self.parkAddress('')
                self.parkPhoneNumber('')
                self.parkPhoneExt('')
                self.space('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("New Lender's Name", self.newLenderName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("New Lender's Address", self.newLenderAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("New Lender's Business Phone", modelMetaData.appendPhoneWithExtn(self.newLenderBusinessPhone(), self.ext(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("New Lender's Fax", self.newLenderFax()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Loan Number", self.loanNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Mobile Home Park Name", self.mobileHomeParkName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Park Address", self.parkAddress()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Park Phone Number", modelMetaData.appendPhoneWithExtn(self.parkPhoneNumber(), self.parkPhoneExt(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Space #", self.space()));

            }
        }

        function LoanInformation() {
            var self = this;
            self.viewName = "orderModule/_loanInformation.html"
            self.title = "Loan Information"
            self.ModelId = "LoanInformation"
            self.index = ko.observable(-1)
            self.deposit = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Deposit Amount. (format: ####.##)'
                }
            });
            self.neworExistLoan = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid New or Exist. Amount. (format: ####.##)'
                }
            });
            self.sellerFinancing = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Seller Financing Amount. (format: ####.##)'
                }
            });
            self.other = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Other Amount. (format: ####.##)'
                }
            });
            self.balance = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Balance Amount. (format: ####.##)'
                }
            });
            self.totalSalesPrice = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Total Sales Price. (format: ####.##)'
                }
            });
            self.closingDate = ko.observable("")
            self.prorateasof = ko.observable("")
            self.coe = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.deposit(data.deposit)
                    self.neworExistLoan(data.neworExistLoan)
                    self.sellerFinancing(data.sellerFinancing)
                    self.other(data.other)
                    self.balance(data.balance)
                    self.totalSalesPrice(data.totalSalesPrice)
                    self.closingDate(data.closingDate)
                    self.prorateasof(data.prorateasof)
                    self.coe(data.coe)
                }

            };

            self.reset = function () {
                self.deposit('')
                self.neworExistLoan('')
                self.sellerFinancing('')
                self.other('')
                self.balance('')
                self.totalSalesPrice('')
                self.closingDate('')
                self.prorateasof('')
                self.coe('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Deposit. ($)", self.deposit()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("New/Exist. Loan. ($)", self.neworExistLoan()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Seller Financing. ($)", self.sellerFinancing()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other. ($)", self.other()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Balance. ($)", self.balance()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Your Total Sales Price. ($)", self.totalSalesPrice()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Closing Date", self.closingDate()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Prorate as of", self.prorateasof()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("COE or", self.coe()));

            }
        }

        function ClosingCosts() {
            var self = this;
            self.viewName = "orderModule/_closingCosts.html"
            self.title = "Closing Costs"
            self.ModelId = "ClosingCosts"
            self.index = ko.observable(-1)
            self.escrowFeeB = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Escrow Fee B. ($). (format: ####.##)'
                }
            });
            self.escrowFeeS = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Escrow Fee S. ($). (format: ####.##)'
                }
            });
            self.escrowFeeea = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Escrow Fee 1/2 ea. ($). (format: ####.##)'
                }
            });
            self.registrationFeeB = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Registration Fee B. ($). (format: ####.##)'
                }
            });
            self.registrationFeeS = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Registration Fee S. ($). (format: ####.##)'
                }
            });
            self.registrationFeeea = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Registration Fee 1/2 ea. ($). (format: ####.##)'
                }
            });
            self.hdcTransferFeeB = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid HCD Transfer Fee B. ($). (format: ####.##)'
                }
            });
            self.hdcTransferFeeS = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid HCD Transfer Fee S. ($). (format: ####.##)'
                }
            });
            self.hdcTransferFeeea = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid HCD Transfer Fee 1/2 ea. ($). (format: ####.##)'
                }
            });
            self.salesTaxFeeB = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Sales Tax Fee B. ($). (format: ####.##)'
                }
            });
            self.salesTaxFeeS = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Sales Tax Fee S. ($). (format: ####.##)'
                }
            });
            self.salesTaxFeeea = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Sales Tax Fee 1/2 ea. ($). (format: ####.##)'
                }
            });
            self.otherFeeB = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Other Fee B. ($). (format: ####.##)'
                }
            });
            self.otherFeeS = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Other Fee S. ($). (format: ####.##)'
                }
            });
            self.otherFeeea = ko.observable("").extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Other Fee 1/2 ea. ($). (format: ####.##)'
                }
            });
            self.update = function (data) {
                if (data) {
                    self.escrowFeeB(data.escrowFeeB)
                    self.escrowFeeS(data.escrowFeeS)
                    self.escrowFeeea(data.escrowFeeea)
                    self.registrationFeeB(data.registrationFeeB)
                    self.registrationFeeS(data.registrationFeeS)
                    self.registrationFeeea(data.registrationFeeea)
                    self.hdcTransferFeeB(data.hdcTransferFeeB)
                    self.hdcTransferFeeS(data.hdcTransferFeeS)
                    self.hdcTransferFeeea(data.hdcTransferFeeea)
                    self.salesTaxFeeB(data.salesTaxFeeB)
                    self.salesTaxFeeS(data.salesTaxFeeS)
                    self.salesTaxFeeea(data.salesTaxFeeea)
                    self.otherFeeB(data.otherFeeB)
                    self.otherFeeS(data.otherFeeS)
                    self.otherFeeea(data.otherFeeea)
                }

            };

            self.reset = function () {
                self.escrowFeeB('')
                self.escrowFeeS('')
                self.escrowFeeea('')
                self.registrationFeeB('')
                self.registrationFeeS('')
                self.registrationFeeea('')
                self.hdcTransferFeeB('')
                self.hdcTransferFeeS('')
                self.hdcTransferFeeea('')
                self.salesTaxFeeB('')
                self.salesTaxFeeS('')
                self.salesTaxFeeea('')
                self.otherFeeB('')
                self.otherFeeS('')
                self.otherFeeea('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Escrow Fee B. ($)", self.escrowFeeB()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Escrow Fee S. ($)", self.escrowFeeS()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Escrow Fee 1/2 ea. ($)", self.escrowFeeea()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Registration Fee B. ($)", self.registrationFeeB()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Registration Fee S. ($)", self.registrationFeeS()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Registration Fee 1/2 ea. ($)", self.registrationFeeea()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("HCD Transfer Fee B. ($)", self.hdcTransferFeeB()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("HCD Transfer Fee S. ($)", self.hdcTransferFeeS()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("HCD Transfer Fee 1/2 ea. ($)", self.hdcTransferFeeea()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Sales Tax Fee B. ($)", self.salesTaxFeeB()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Sales Tax Fee S. ($)", self.salesTaxFeeS()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Sales Tax Fee 1/2 ea. ($)", self.salesTaxFeeea()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other Fee B. ($)", self.otherFeeB()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other Fee S. ($)", self.otherFeeS()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other Fee 1/2 ea. ($)", self.otherFeeea()));
            }
        }

        function Prorations() {
            var self = this;
            self.viewName = "orderModule/_prorations.html"
            self.title = "Prorations"
            self.ModelId = "Prorations"
            self.index = ko.observable(-1)
            
            self.spaceRent = ko.observable("")
            self.perMonth = ko.observable("")
           .extend({
               pattern: {
                   params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                   message: 'Invalid Per Month. ($). (format: ####.##)'
               }
           });
            self.paidTo = ko.observable("")
            self.interestonExistingLoan = ko.observable("")
            self.interestonLoan = ko.observable("")
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Interest on Loan. ($). (format: ####.##)'
                }
            });
            self.registrationFee = ko.observable("")
            self.registrationFeeAmountPerYear = ko.observable("")
           .extend({
               pattern: {
                   params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                   message: 'Invalid Registration Fee Amount per year. ($). (format: ####.##)'
               }
           });
            self.registrationFeeExpiration = ko.observable("")
            self.reimburseSecurityDeposit = ko.observable("")
            self.amount = ko.observable("")
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Amount. ($). (format: ####.##)'
                }
            });
            self.otherProvisions = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.spaceRent(data.spaceRent)
                    self.perMonth(data.perMonth)
                    self.paidTo(data.paidTo)
                    self.interestonExistingLoan(data.interestonExistingLoan)
                    self.interestonLoan(data.interestonLoan)
                    self.registrationFee(data.registrationFee)
                    self.registrationFeeAmountPerYear(data.registrationFeeAmountPerYear)
                    //self.hdcTransferFeeS(data.spaceRent)
                    //self.hdcTransferFeeea(data.spaceRent)
                    self.registrationFeeExpiration(data.registrationFeeExpiration)
                    reimburseSecurityDeposit(data.reimburseSecurityDeposit)
                    //self.yes(data.yes)
                    //  self.no(data.no)
                    self.amount(data.amount)
                    self.otherProvisions(data.otherProvisions)
                }

            };

            self.reset = function () {
                self.spaceRent('')
                self.perMonth('')
                self.paidTo('')
                self.interestonExistingLoan('')
                self.interestonLoan('')
                self.registrationFee('')
                self.registrationFeeAmountPerYear('')
                //self.hdcTransferFeeS('')
                //self.hdcTransferFeeea('')
                self.registrationFeeExpiration('')
                // reimburseSecurityDeposit?	
                self.reimburseSecurityDeposit('')
                //self.yes('')
                //  self.no('')
                self.amount('')
                self.otherProvisions('')
                ko.validation.group(self).showAllMessages(false);
            }
            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Space Rent @", modelMetaData.reportBooleanValue(self.spaceRent())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Per Month. ($)", self.perMonth()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Paid To", self.paidTo()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Interest on Existing Loan", modelMetaData.reportBooleanValue(self.interestonExistingLoan())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Interest on Loan. ($)", self.interestonLoan()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Registration Fee", modelMetaData.reportBooleanValue(self.registrationFee())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Registration Fee Amount per year. ($)", self.registrationFeeAmountPerYear()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Registration Fee Expiration", self.registrationFeeExpiration()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Reimburse Security Deposit?", self.reimburseSecurityDeposit()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Amount. ($)", self.amount()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Other Provisions", self.otherProvisions()));
            }
        }

        var vm = {
            EscrowInformation: EscrowInformation,
            MobileHomeSellerInfo: MobileHomeSellerInfo,
            MobileHomeBuyerInfo: MobileHomeBuyerInfo,
            MobileHomeListingAgentInfo: MobileHomeListingAgentInfo,
            MobileHomeSellingAgentInfo: MobileHomeSellingAgentInfo,
            MobileHomeLegalOwnerInfo: MobileHomeLegalOwnerInfo,
            MobileHomeNewLenderInfo: MobileHomeNewLenderInfo,
            LoanInformation: LoanInformation,
            ClosingCosts: ClosingCosts,
            Prorations: Prorations
        }

        return vm;
    });
