define('viewOrderService',['config', 'Q', 'plugins/http', 'global/session', 'viewOrder'],
    function (config, Q, https, session, viewOnlyOrder) {

        function getViewOrder(orderId) {
            var viewOrder = new viewOnlyOrder.ViewOrder();

            var url = config.getGetOrderByIdURL;
            var tpArr = [];


            return $.ajax({
                url: url,
                type: 'GET',
                data: $.param({ OrderId: orderId }),
                cache: false,
                contentType: false,
                processData: false,
                beforeSend: function (req) {
                    if (sessionStorage["accessToken"] || localStorage["accessToken"] != null)
                        req.setRequestHeader('Authorization', 'Bearer ' + (sessionStorage["accessToken"] || localStorage["accessToken"]));
                }
            }).then(function (order) {
                console.log(order);
                if (order == null) {
                    viewOrder.HasErrors(true);
                    viewOrder.ErrorMessages.push("There was an error retriving your Order Details.");
                }
                else {
                    if (order.HasErrors == true) {
                        viewOrder.HasErrors(true);
                        for (var i = 0; i < order.Messages.length; i++) {
                            viewOrder.ErrorMessages.push(order.Messages[i].Message);
                        }
                    }
                    else {
                        viewOrder.Id(order.Id);
                        viewOrder.OrderNumber(order.OrderNumber);
                        viewOrder.OrderStatus(order.OrderStatus);
                        viewOrder.OrderStatusName(order.OrderStatusName);
                        viewOrder.OrderTypeName(order.OrderTypeName);
                        viewOrder.CreateDate(order.CreateDateFormatted);
                        viewOrder.EstimatedCOEDate(order.EstimatedCOEDate);
                        if (order.TransactionTypeCode == 'UNKN') {
                            viewOrder.TransactionTypeCode(order.TransactionTypeName);
                        } else {
                            viewOrder.TransactionTypeCode(order.TransactionTypeCode);
                        }
                        viewOrder.TransactionAmount(order.TransactionAmount);
                        viewOrder.LimitedCoveragePolicy(order.LimitedCoveragePolicy);
                        viewOrder.PropertyAddressHTML(order.PropertyAddressHTML);
                        viewOrder.PropertyTypeName(order.PropertyTypeName);
                        viewOrder.SubmittedByEmail(order.SubmittedByEmail);
                        viewOrder.SubmittedByFullName(order.SubmittedByFullName);
                        viewOrder.SubmittedBySalesRep(order.SubmittedBySalesRep);
                        viewOrder.SubmittedBySalesRepEmail(order.SubmittedBySalesRepEmail);
                        viewOrder.SpecialInstructions(order.SpecialInstructions);
                        //viewOrder.IsICloseOrder = order.ProviderType == 40? true : false;
                        //viewOrder.IsManualOrder = order.ProviderType == 0 ? true : false;
                        if (order.Logs.length > 0) {
                            viewOrder.IsSP360ManualOrder = order.ProviderType == 30 && order.Logs[0].Message.indexOf('MANUAL') != -1 ? true : false;
                        }

                        viewOrder.ProviderType(order.ProviderType);



                        viewOrder.Logs(order.Logs);
                        viewOrder.Principals(order.Principals);
                        viewOrder.Offices(order.Offices);
                        viewOrder.Statuses(order.Statuses);

                        var transactionParties = order.TransactionParties;
                        var transactionParty = null;

                        viewOrder.TransactionParties([]);
                        tpArr = [];
                        for (i = 0; i < transactionParties.length; i++) {

                            // Lender
                            if (transactionParties[i].PartyType == 2 || transactionParties[i].PartyType == 7) {
                                transactionParty = new viewOnlyOrder.TransactionParty();

                                transactionParty.AccountNumber(transactionParties[i].AccountNumber);
                                transactionParty.Address(transactionParties[i].Address);
                                transactionParty.FinanceType(transactionParties[i].FinanceType);
                                transactionParty.LoanAmount(transactionParties[i].LoanAmount);
                                transactionParty.LoanType(transactionParties[i].LoanType);
                                transactionParty.NameEmail(transactionParties[i].NameEmail);
                                transactionParty.PartyType(transactionParties[i].PartyType);
                                transactionParty.Phone(transactionParties[i].Phone);
                                transactionParty.Type(transactionParties[i].Type);


                            }
                            else // SellingBroker or ListingBroker
                            {
                                transactionParty = new viewOnlyOrder.TransactionParty();


                                transactionParty.Address(transactionParties[i].Address);
                                transactionParty.NameEmail(transactionParties[i].NameEmail);
                                transactionParty.PartyType(transactionParties[i].PartyType);
                                transactionParty.Phone(transactionParties[i].Phone);
                                transactionParty.Type(transactionParties[i].Type);
                            }

                            tpArr.push(transactionParty);
                        }

                        if (tpArr.length > 0) {
                            viewOrder.TransactionParties(tpArr);
                        }
                    }
                }
                return viewOrder;

            })
                .fail(function () {
                    //alert.error("Error in getting Order details");
                });


        }

        var viewOrderService = {
            getViewOrder: getViewOrder
        };

        return viewOrderService;
    });
