define('models/user/registerAccountInfoModel',["knockout"],
    function (ko) {
        function registerAccountInfo() {
            var self = this;
            self.UserRegisteredInAnotherBrand = ko.observable(false);
            self.ValidToken = ko.observable(false);
            self.LLAAccepted = ko.observable(false);
            self.email = ko.observable("")
                .extend({ required: { params: true, message: 'Email Address is required.' } })
                .extend({
                    pattern: {
                        message: 'Invalid Email Address.',
                        params: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                    }
                })
                .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } });
               

            self.confirmEmail = ko.observable("")
                .extend({ required: { message: 'Confirm Email Address is required.' } })
                .extend({
                    pattern: {
                        message: 'Invalid Email Address.',
                        params: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                    }
                })
                .extend({ maxLength: { params: 75, message: 'Invalid Confirm Email Address.' } })
                .extend({ equal: { params: self.email, message: 'Should match Email Address.' } });




            

          //  self.secretQuestionArray = ko.observable(
          // [{ key: "Favorite pet's name?", value: "Favorite pet's name?" },
          //{ key: "Favorite movie?", value: "Favorite movie?" },
          //{ key: "Anniversary [mm/dd/yy]?", value: "Anniversary [mm/dd/yy]?" },
          //{ key: "Father's middle name?", value: "Father's middle name?" },
          //{ key: "Spouse's middle name?", value: "Spouse's middle name?" },
          //{ key: "First child's middle name?", value: "First child's middle name?" },
          //{ key: "High school name?", value: "High school name?" },
          //{ key: "Favorite teacher's name?", value: "Favorite teacher's name?" },
          //{ key: "Favorite sports team?", value: "Favorite sports team?" }]);
          //  self.secretQuestion = ko.observable(undefined)
          //  .extend({ required: { message: 'Secret question is required.' } })

          //  self.secretAnswer = ko.observable("")
          //   .extend({ required: { message: 'Secret answer is required.' } })
          //   .extend({ minLength: { params: 6, message: 'Secret answer must have at least 6 characters.' } })
          //  .extend({pattern: {
          //      message: 'Only Alphanumeric and Forward slash allowed.',
          //      params: /^[a-zA-Z0-9/]*$/
          //  }})
            self.update = function (data) {
                if (data) {
                    self.email(data.email());
                    self.confirmEmail(data.confirmEmail());

                    //self.secretQuestion(data.secretQuestion());
                    //self.secretAnswer(data.secretAnswer());
                }
            };

        }

        var vm = {
            registerAccountInfo: registerAccountInfo
        }

        return vm;
    });
