define('models/user/siteAnnouncementsModel',["knockout"],
    function (ko) {
        function announcements() {
            var self = this;
            self.Headline = ko.observable("");
            self.Message = ko.observable("");
            self.Brand = ko.observable("");
            self.EffectiveDate = ko.observable("");
            self.ExpireDate = ko.observable("");
            self.DisplayInThisBrand = ko.observable(false);

            self.reset = function () {
                self.Headline("");
                self.Message("");
                self.Brand("");
                self.EffectiveDate("");
                self.ExpireDate("");
                self.DisplayInThisBrand(false);
            };

            self.update = function (data) {
                if (data) {
                    self.Headline(data.headline);
                    self.Message(data.message);
                    self.Brand(data.brand);
                    self.EffectiveDate(data.effectiveDate);
                    self.ExpireDate(data.expireDate);
                    self.DisplayInThisBrand(data.DisplayInThisBrand);
                }
            };
        }

        var vm = {
            announcements: announcements,
        }

        return vm;

    });
