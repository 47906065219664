define('models/calculators/calculators',["knockout"],
    function (ko) {
        function calculators() {
            var self = this;
            self.zip = ko.observable("")
               .extend({ required: { message: 'Zip is required.' } })
               .extend({ maxLength: { params: 5, message: 'Only 5 digit Zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit Zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit Zip code is allowed.' });

           
        }

        

        var vm = {
            calculators: calculators
        }

        return vm;
    });
