define('models/favorites/favoritesModels',["knockout"],
    function (ko) {

        function favorite() {
            var self = this;
            self.FavoriteId = ko.observable("");
            self.Name = ko.observable("").extend({ required: { message: 'Name is required.' } })
            self.Url = ko.observable("")
                .extend({ required: { message: 'Url is required.' } })
                .extend({ url: { params: false, message: 'Favorite URL must be a valid URL (Ex: http://www.fnf.com)' } })
            self.index = ko.observable(-1)

            self.update = function (data) {
                if (data) {
                    self.FavoriteId(data.FavoriteId())
                    self.Name(data.Name())
                    self.Url(data.Url())
                    self.index(data.index())
                }

            }

            self.reset = function () {
                self.index(-1);
                self.FavoriteId('')
                self.Name('')
                self.Url('')
                

            }
        }

        function favorites() {
            var self = this;
            self.index = ko.observable(-1)
            self.List = ko.observableArray([]);

            self.reset = function () {
                self.List([]);
            }

            self.update = function (data) {
                if (data) {
                    self.Name(data.Name)
                    self.Url(data.Url)
                    self.FavoriteId(data.FavoriteId)
                    self.index(data.index)
                }
            }
        }
        var vm = {
            favorite: favorite,
            favorites: favorites
        };

        return vm;
    }
    );
