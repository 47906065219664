define('viewmodels/officeLocatorModule/officeLocator',['durandal/app', 'plugins/router', 'knockout', 'services/logger', 'Q', 'plugins/http', 'config', 'global/session', 'alertNotification', 'utlities', 'commonDialogModel', 'officeLocatorModel'],
    function (app, router, ko, logger, Q, https, config, session, alert, utlities, commonDialog, model) {

        var officeLocatorModel = new model.OfficeLocator();
        var officeLocatorDetailModel = new model.OfficeLocatorDetails();
        var officeLocatorEmployeeInfo = new model.OfficeEmployeeInfo();
        var isMobile = ko.observable(false);
        var officeId;

        var officeSearchHtml = 'officeLocatorModule/officeLocatorSearch.html';
        var officeDetailsHtml = 'officeLocatorModule/officeLocatorDetails.html';
        var searchManager = null;
        var brandId;
        var map = null;

        var isOfficeSearchSuccess = ko.observable(true);

        var latLng;
        var isOperationInfoAvailable = ko.observable(false);

        self.currentStep = ko.observable(officeSearchHtml);

        officeLocatorModel.state.subscribe(function (newState) {
            if (newState != undefined && newState != '') {
                officeLocatorModel.noOfficeMessage("");
                getCountyList(newState);
                officeLocatorModel.county('All Counties');
                officeLocatorModel.showAllValdiationMessages(false);
            }
            else {
                resetAllFields();
            }
        });

        officeLocatorModel.county.subscribe(function (newCounty) {
            if (newCounty != undefined && newCounty != '') {
                if (officeLocatorModel.state() != undefined && officeLocatorModel.state() != '') {
                    getOfficesList(newCounty);
                    officeLocatorModel.office(undefined);
                }
                else {
                    resetOfficeField();
                }
            }
        });

        officeLocatorModel.office.subscribe(function (newOffice) {
            if (newOffice != undefined && newOffice != '') {
                currentStep(officeDetailsHtml);
                setTimeout(function () {
                    fetchOfficeInformation(newOffice);
                }, 500);
            }
        });

        

        function onStateAreaClick(stateId) {
            if (stateId != officeLocatorModel.state()) {
                for (var i = 0; i < officeLocatorModel.stateList().length; i++) {
                    var stateKeyValue = officeLocatorModel.stateList()[i];
                    if (stateKeyValue.Key === stateId) {
                        officeLocatorModel.state(stateId);
                        return;
                    }
                }
                officeLocatorModel.state(undefined);
                officeLocatorModel.noOfficeMessage("There are no offices within this searching criteria");
            }
        }

        function canActivate() {
            if (session.brandId == 23) {
                getOfficeLink().done(function (redirectUrl) {
                    if (redirectUrl && redirectUrl.length > 0)
                        session.CheckPopUpBlock(redirectUrl, '_blank', null);
                    else
                        app.showMessage('Office Locator link error.', 'Office Locator error', ['OK']);
                });
                return false;
            }
            return true;
        }

        function getOfficeLink() {
            var url = config.getWesternOfficeURL;
            return https.get(url);
        }

        function resetOfficeField() {
            officeLocatorModel.office(undefined);
            resetOfficeList();
        }

        function resetCountyField() {
            officeLocatorModel.county('All Counties');
            resetCountyList();
        }

        function resetAllFields() {
            officeLocatorModel.state(undefined);
            resetCountyField();
            resetOfficeField();
        }

        function resetCountyList() {
            officeLocatorModel.countyList({ Key: 0, Value: 'All Counties' });
        }

        function resetOfficeList() {
            officeLocatorModel.officeList([]);
        }

        function getStatesList(brandId) {
            var url = config.getOfficeLocatorStates;
            officeLocatorModel.stateList([]);
            Q.when(https.get(url, { brandId: brandId, format: 'json' }, null, null, null, null, true)
                .then(function (data) {
                    officeLocatorModel.stateList(data);
                })
                .fail(Failed));
        }

        function getCountyList(stateId) {
            var url = config.getOfficeLocatorCounties;
            officeLocatorModel.countyList({ Key: 0, Value: 'All Counties' });
            Q.when(https.get(url, { brandId: brandId, stateId: stateId, format: 'json' }, null, null, null, null, true)
                .then(function (data) {
                    data.unshift({ Key: 0, Value: 'All Counties' });
                    officeLocatorModel.countyList(data);
                })
                .fail(Failed));
        }

        function getOfficesList(county) {
            var url = config.getOfficeLocatorOffices;
            officeLocatorModel.officeList([]);
            if (county === 'All Counties') {
                county = "";
            }
            Q.when(https.get(url, { brandId: brandId, stateId: officeLocatorModel.state, countyName: county, format: 'json' }, null, null, null, null, true)
                .then(function (data) {
                    officeLocatorDetailModel.reset();
                    officeLocatorModel.officeList(data);
                })
                .fail(Failed));
        }

        function fetchOfficeInformation(officeId) {
            officeLocatorDetailModel.reset();
            officeLocatorDetailModel.officeName(officeLocatorModel.officeName(officeId));
            var url = config.getOfficeLocatorOfficeInformation;
            Q.when(https.get(url, { officeId: officeId, brandId: brandId, format: 'json' }, null, null, null, null, true)
                .then(function (data) {
                    ko.utils.arrayFilter(session.quickMenuList(), function (item) {
                        if (item && item.CpUrl && item.CpUrl.toLowerCase() == "#officeoperation")
                            isOperationInfoAvailable(true);
                    });
                    console.log("bool val : " + isOperationInfoAvailable());
                    officeLocatorDetailModel.officeName(data.OfficeName);
                    officeLocatorDetailModel.address1(data.Address1);
                    officeLocatorDetailModel.address2(data.Address2);
                    officeLocatorDetailModel.city(data.City);
                    officeLocatorDetailModel.stateName(data.StateName);
                    officeLocatorDetailModel.stateAbbr(data.State);
                    officeLocatorDetailModel.county(data.CountyName);
                    officeLocatorDetailModel.phone(utlities.getUSPhoneFormat(data.Phone.split('x')[0]));

                    if (data.Tollfree.length > 0)
                        officeLocatorDetailModel.tollFree(utlities.getUSPhoneFormat(data.Tollfree));
                    else
                        officeLocatorDetailModel.tollFree('');

                    officeLocatorDetailModel.fax(utlities.getUSPhoneFormat(data.Fax));
                    if (data.Link && data.Link.length > 0)
                        officeLocatorDetailModel.officeLink(data.Link);
                    else
                        officeLocatorDetailModel.officeLink('Not Available');
                    officeLocatorDetailModel.zip(data.Zip);
                    officeLocatorDetailModel.departments(data.OfficeDepartments);
                    officeLocatorDetailModel.photoName(data.Photo);
                }).done(officeInfoFetchSuccessful)
                .fail(Failed));
        }

        function Failed(error) {
            alert.error(error.statusText);
        }

        function officeInfoFetchSuccessful() {
            getOfficeImage(officeLocatorDetailModel.photoName());
            loadOfficeMap();
            window.location.href = "#siteTop";
        }

        function activate(OfficeId) {
            if (session.bingMapAPIKeyAnonym().length == 0) {
                session.getBingMapAPIKeyAnonym().done(function (result) {
                    session.bingMapAPIKeyAnonym(result);
                });
            }
            session.showTopSearchControl(true);
            console.log("Opening Office locator");
            if (OfficeId != null && OfficeId != "" && OfficeId != undefined) {
                currentStep(officeDetailsHtml);
            }
            else {
                currentStep(officeSearchHtml);
            }
            officeId = OfficeId;
            officeLocatorModel.reset();
            var checkMobile = window.matchMedia("only screen and (max-width: 760px)");
            isMobile(checkMobile.matches);

            // Using brandId from the site as we need this page without login
            brandId = parseInt($('.currentBrand').val());

            getStatesList(brandId);

        }

        function onComposed() {
            session.validateInput();
            console.log("composed");
            $('#stateList').focus();
            if (officeId != null) {
                currentStep(officeDetailsHtml);
                fetchOfficeInformation(officeId);
            }
        }

        function goBack(complete) {
            router.navigateBack();
        }

        function onClickBackToSearchScreen() {
            currentStep(officeSearchHtml);
            //officeLocatorModel.reset();
            window.location.href = "#siteTop";
        }

        function onClickBackToOperationInfo() {
            //router.navigate("#officeOperation");
            router.navigate('officeOperation', {
                replace: true, trigger: true
            });
        }

        function loadOfficeMap() {

            $('#aBackToOffLoc, #aBackToOpnInfo').on('focus', function () {
                $('html,body').animate({ scrollTop: 0 }, 'fast');
            });
            //map.dispose();
            if (map != null) {
                map.dispose();
                map = null;
            }

            if (searchManager != null) {
                searchManager = null;
            }

            initMap().done(function () {
                if (document.getElementById('map') != null) {
                    var requestOptions = {
                        bounds: map.getBounds(),
                        where: officeLocatorDetailModel.fullAddress(),
                        callback: function (answer, userData) {
                            map.setView({
                                center: answer.results[0].location,
                                zoom: 15
                            });
                            map.entities.push(new Microsoft.Maps.Pushpin(answer.results[0].location));
                        }
                    };

                    if (searchManager == undefined || searchManager == null) {
                        //Create an instance of the search manager and perform the search.
                        Microsoft.Maps.loadModule('Microsoft.Maps.Search', function () {
                            searchManager = new Microsoft.Maps.Search.SearchManager(map);
                            searchManager.geocode(requestOptions);
                            document.getElementById('map').style.visibility = 'visible';
                        });
                    }

                }
            });

        }

        function initMap() {
            console.log('initialize map');
            var dfrd1 = $.Deferred();
            if (session.bingMapAPIKeyAnonym().length == 0) {
                session.getBingMapAPIKeyAnonym().done(function (result) {
                    session.bingMapAPIKeyAnonym(result);
                });
            }

            if (document.getElementById('map') != null) {
                document.getElementById('map').style.visibility = 'hidden';
                var navigationBarMode = Microsoft.Maps.NavigationBarMode;
                map = new Microsoft.Maps.Map(document.getElementById('map'), {
                    credentials: session.bingMapAPIKeyAnonym(),
                    mapTypeId: Microsoft.Maps.MapTypeId.road,
                    navigationBarMode: navigationBarMode.compact,
                    zoom: 18
                });

                //if (searchManager == undefined || searchManager == null) {
                //    //Create an instance of the search manager and perform the search.
                //    Microsoft.Maps.loadModule('Microsoft.Maps.Search', function () {
                //        searchManager = new Microsoft.Maps.Search.SearchManager(map);
                //    });
                //}

            }
            dfrd1.resolve();

            return $.when(dfrd1).done(function () {
                console.log('Init Map done');
            }).promise();
        }

        function getUSPhoneFormat(data) {
            return utlities.getUSPhoneFormat(data);
        }

        function onClickOfficeEmployee(data) {
            setEmployeeInfo(data);
            employeeInformationPopup();
        }

        function setEmployeeInfo(data) {
            officeLocatorEmployeeInfo.fullName(data.FullName);
            officeLocatorEmployeeInfo.title(data.Title);
            officeLocatorEmployeeInfo.department(data.DepartmentName);
            officeLocatorEmployeeInfo.office(data.OfficeName);
            officeLocatorEmployeeInfo.phone(data.PhoneWork);
            officeLocatorEmployeeInfo.fax(data.PhoneFax);
            officeLocatorEmployeeInfo.email(data.Email);
            officeLocatorEmployeeInfo.photoName(data.Photo);
            officeLocatorEmployeeInfo.employeeType(data.EmployeeType);
            getEmployeeImage(data.Photo);
        }

        function employeeInformationPopup() {
            $('#mdEmployeeInformation').modal('show');
        }

        function closeDialog() {
            $('#mdEmployeeInformation').modal('hide');
            officeLocatorEmployeeInfo.reset();
        }

        function getOfficeImage(photoName) {
            var url = config.getOfficeLocatorImagesUrl;
            https.get(url, {
                PhotoFileName: photoName,
                format: 'json'
            }, null, null, null, null, true)
                .then(function (data) {
                    officeLocatorDetailModel.photoUrl(data.PhotoUrl);
                })
                .fail(function (data) { console.log(data); })
        }

        function getEmployeeImage(photoName) {
            var url = config.getOfficeLocatorEmpImageUrl;
            https.get(url, {
                PhotoFileName: photoName,
                format: 'json'
            }, null, null, null, null, true)
                .then(function (data) {
                    officeLocatorEmployeeInfo.photoUrl(data.PhotoUrl);
                })
                .fail(function (data) {
                    console.log(data);
                });
        }

        function checkForNotAvailable(data) {
            if (data) {
                return utlities.getUSPhoneFormat(data);
            }
            else
                return "Not Available";
        }

        function retrieveTitle(data) {
            if (data.Title) {
                return data.Title;
            }
            else {
                return data.EmployeeType;
            }
        }


        var vm = {
            activate: activate,
            canActivate: canActivate,
            compositionComplete: onComposed,
            isOperationInfoAvailable: isOperationInfoAvailable,
            currentStep: currentStep,
            officeLocatorModel: officeLocatorModel,
            officeLocatorDetailModel: officeLocatorDetailModel,
            officeLocatorEmployeeInfo: officeLocatorEmployeeInfo,
            onStateAreaClick: onStateAreaClick,
            onClickBackToSearchScreen: onClickBackToSearchScreen,
            onClickBackToOperationInfo: onClickBackToOperationInfo,
            getUSPhoneFormat: getUSPhoneFormat,
            onClickOfficeEmployee: onClickOfficeEmployee,
            isOfficeSearchSuccess: isOfficeSearchSuccess,
            closeDialog: closeDialog,
            isMobile: isMobile,
            checkForNotAvailable: checkForNotAvailable,
            retrieveTitle: retrieveTitle,
            deactivate: function () {
                if (map != null) {
                    map.dispose();
                }
                if (searchManager != null) {
                    searchManager = null;
                }
                isMobile(false);
            },
            afterCompose: function () {
                console.log('afterCompose : called');
            }
        };

        return vm;

    });
