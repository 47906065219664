define('models/order/coloradoRefiLenderEscrow',["knockout", "modelMetaData"],
    function (ko, modelMetaData) {

        function subjectProperty() {
            var self = this;
            self.viewName = "orderModule/coloradoRefiLender/_subjectProperty.html"
            self.title = "Subject Property"
            self.ModelId = "subjectProperty"
            self.index = ko.observable(-1)

            self.address = ko.observable("")
            .extend({ required: { message: 'Property Address is required.' } })
            .extend({
                maxLength: {
                    params: 200, message: 'Property Address must be 200 characters or less'
                }
            })
            //.extend({
            //    pattern: {
            //        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
            //        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
            //    }
            //});

            self.city = ko.observable(undefined)
            .extend({ required: { message: 'Property City is required.' } })

            self.state = ko.observable(undefined)
            .extend({ required: { message: 'Property State is required.' } })

            self.zip = ko.observable("")
            .extend({ required: { message: 'Property Zip is required.' } })
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
            .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
            .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.update = function (data) {
                if (data) {
                    self.address(data.Address())
                    self.city(data.City())
                    self.state(data.State())
                    self.zip(data.Zip())
                }

            }

            self.reset = function () {

                self.address("")
                self.city(undefined)
                self.state(undefined)
                self.zip("")
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Address", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property Zip", self.zip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Property State", self.state()));
            }
        }


        function borrowerInformation() {
            var self = this;
            self.viewName = "orderModule/coloradoRefiLender/_borrowerInformation.html"
            self.title = "Borrower Information"
            self.ModelId = "borrowerInformation"
            self.index = ko.observable(-1)

            self.borrower = ko.observable("")
            self.last4SSN = ko.observable("")
            .extend({ digit: true });

            self.coBorrower = ko.observable("")

            self.homePhone = ko.observable("")
            .extend({ phoneUS: true })

            self.extn = ko.observable("")
            .extend({ digit: true });


            self.workPhone = ko.observable("")
            .extend({ phoneUS: true })
            .extend({
                required: {
                    message: "Work Phone is required",
                    onlyIf: function () {
                        return self.extn().length > 0;
                    }
                }
            })

            self.address = ko.observable("")
            .extend({
                maxLength: {
                    params: 200, message: 'Mailing Address must be 200 characters or less'
                }
            })
            //.extend({
            //    pattern: {
            //        message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
            //        params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
            //    }
            //});

            self.city = ko.observable(undefined)
            self.state = ko.observable(undefined)
            self.zip = ko.observable("")



            self.update = function (data) {
                if (data) {
                    self.borrower(data.borrower)
                    self.last4SSN(data.last4SSN)
                    self.coBorrower(data.coBorrower)
                    self.homePhone(data.homePhone)
                    self.workPhone(data.workPhone)
                    self.extn(data.extn)
                    self.address(data.address)
                    self.city(data.city)
                    self.state(data.state)
                    self.zip(data.zip)
                }

            }
            self.reset = function () {

                self.borrower("")
                self.last4SSN("")
                self.coBorrower("")
                self.homePhone("")
                self.workPhone("")
                self.extn("")
                self.address("")
                self.city(undefined)
                self.state(undefined)
                self.zip("")
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Borrower", self.borrower()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Last 4 Social Security #", self.last4SSN()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Co-Borrower", self.coBorrower()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Home Phone", self.homePhone()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Work Phone", modelMetaData.appendPhoneWithExtn(self.workPhone(), self.extn(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Mailing Address (if different from property address)", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Mailing Zip", self.zip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Mailing City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Mailing State", self.state()));

            }
        }


        function loanInformation() {
            var self = this;
            self.viewName = "orderModule/coloradoRefiLender/_loanInformation.html"
            self.title = "Loan Information"
            self.ModelId = "loanInformation"
            self.index = ko.observable(-1)

            self.lenderName = ko.observable("")
            self.escrowOfficer = ko.observable("")
            self.closingDate = ko.observable("")

            self.loanAmount = ko.observable("").extend({ number: true }).extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Loan Amount. (format: ####.##)'
                }
            });

            self.loanType = ko.observable("")
            //pattern: {
            //    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
            //    message: 'Invalid Loan Amount. (format: ####.##)'
            //}
        //}

        self.newLoanNumber = ko.observable("")


            self.update = function (data) {
                if (data) {
                    self.lenderName(data.lenderName)
                    self.escrowOfficer(data.escrowOfficer)
                    self.closingDate(data.closingDate)
                    self.loanType(data.loanType)
                    self.loanAmount(data.loanAmount)
                    self.newLoanNumber(data.newLoanNumber)
                }

            }

            self.reset = function () {

                self.lenderName("")
                self.escrowOfficer("")
                self.closingDate("")
                self.loanType("")
                self.loanAmount("")
                self.newLoanNumber("")
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Lender's Name", self.lenderName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Escrow Officer", self.escrowOfficer()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Closing Date", self.closingDate()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Type of Loan", self.loanType()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Loan Amount ($)", self.loanAmount()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("New Loan Number", self.newLoanNumber()));
            }

        }


        function trustDeedOfRecord() {
            var self = this;
            self.viewName = "orderModule/coloradoRefiLender/_trustDeedOfRecord.html"
            self.title = "Trust Deed of Record"
            self.ModelId = "trustDeedOfRecord"
            self.index = ko.observable(-1)


            self.firstTrustDeedOfRecord = ko.observable("")
            self.firstTrustLoanNumber = ko.observable("")
             //.extend({ digit: true });
            self.firstTrustloanBalance = ko.observable("")
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid First Trust Deed Of Record Loan Balance. (format: ####.##)'
                }
            });

            self.firstTrustPhoneExtn = ko.observable("")
           .extend({ digit: true });

            self.firstTrustPhone = ko.observable("").extend({ phoneUS: true })
            .extend({
                required: {
                    message: "First Trust Deed Phone is required",
                    onlyIf: function () {
                        return self.firstTrustPhoneExtn().length > 0;
                    }
                }
            })
           

            self.secondTrustDeedofRecord = ko.observable("")
            self.secondTrustLoanNumber = ko.observable("")
             //.extend({ digit: true });
            self.secondTrustLoanBalance = ko.observable("")
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Second Trust Deed Of Record Loan Balance. (format: ####.##)'
                }
            });

            self.secondTrustPhoneExtn = ko.observable("")
             .extend({ digit: true });

            self.secondTrustPhone = ko.observable("").extend({ phoneUS: true })
            .extend({
                required: {
                    message: "Second Trust Deed Phone is required",
                    onlyIf: function () {
                        return self.secondTrustPhoneExtn().length > 0;
                    }
                }
            })
          

            self.update = function (data) {
                if (data) {
                    self.firstTrustDeedOfRecord(data.firstTrustDeedOfRecord)
                    self.firstTrustLoanNumber(data.firstTrustLoanNumber)
                    self.firstTrustloanBalance(data.firstTrustloanBalance)
                    self.firstTrustPhone(data.firstTrustPhone)
                    self.firstTrustPhoneExtn(data.firstTrustPhoneExtn)

                    self.secondTrustDeedofRecord(data.secondTrustDeedofRecord)
                    self.secondTrustLoanNumber(data.secondTrustLoanNumber)
                    self.secondTrustLoanBalance(data.secondTrustLoanBalance)
                    .extend({
                        pattern: {
                            params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                            message: 'Invalid Second Trust Deed Of Record Loan Balance. (format: ####.##)'
                        }
                    });
                    self.secondTrustPhone(data.secondTrustPhone)
                    self.secondTrustPhoneExtn(data.secondTrustPhoneExtn)
                }

            }

            self.reset = function () {

                self.firstTrustDeedOfRecord("")
                self.firstTrustLoanNumber("")
                self.firstTrustloanBalance("")
                self.firstTrustPhone("")
                self.firstTrustPhoneExtn("")

                self.secondTrustDeedofRecord("")
                self.secondTrustLoanNumber("")
                self.secondTrustLoanBalance("")
                self.secondTrustPhone("")
                self.secondTrustPhoneExtn("")

                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("First Trust Deed of Record", self.firstTrustDeedOfRecord()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Loan Number", self.firstTrustLoanNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Loan Balance ($)", self.firstTrustloanBalance()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Phone", modelMetaData.appendPhoneWithExtn(self.firstTrustPhone(), self.firstTrustPhoneExtn(), 'x')));

                self.modelData.properties.push(new modelMetaData.propertyData().create("Second Trust Deed of Record", self.secondTrustDeedofRecord()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Loan Number", self.secondTrustLoanNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Loan Balance ($)", self.secondTrustLoanBalance()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Phone", modelMetaData.appendPhoneWithExtn(self.secondTrustPhone(), self.secondTrustPhoneExtn(), 'x')));
            }
        }

        function additionalInformation() {
            var self = this;
            self.viewName = "orderModule/coloradoRefiLender/_additionalInformation.html"
            self.title = "Additional Information"
            self.ModelId = "additionalInformation"
            self.index = ko.observable(-1)

            self.payoffOtherLiens = ko.observable("")
            .extend({
                maxLength: {
                    params: 200, message: 'Payoff Other Liens must be 200 characters or less'
                }
            })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });
            self.subordination = ko.observable("")

            self.comments = ko.observable("")
            .extend({
                maxLength: {
                    params: 200, message: 'comments must be 200 characters or less'
                }
            })
            .extend({
                pattern: {
                    message: 'Only Alphanumeric and some special characters (.|*?+$!&@,/()[]) allowed.',
                    params: '^[\\w \\s\\.\\|\\*\\?\\+\\$!\\&@,/\\(\\)\\[\\]]{0,200}$'
                }
            });

            self.update = function (data) {
                if (data) {
                    self.payoffOtherLiens(data.payoffOtherLiens())
                    self.subordination(data.subordination())
                    self.comments(data.comments())
                }

            }

            self.reset = function () {

                self.payoffOtherLiens("")
                self.subordination("")
                self.comments("")
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }


            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Payoff other Liens", self.payoffOtherLiens()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Subordination", self.subordination()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Comments", self.comments()));
            }
        }

        var vm = {
            subjectProperty: subjectProperty,
            borrowerInformation: borrowerInformation,
            loanInformation: loanInformation,
            trustDeedOfRecord: trustDeedOfRecord,
            additionalInformation: additionalInformation
        }

        return vm;
    });
