define('alertNotificationAtBottomRight',['toastr', 'config'], function (toastr, config) {
    var
        success = function (message, title) {
            init();
            toastr.success(message, title);
        },
        error = function (message, title) {
            init();
            toastr.error(message, title);
        },
        info = function (message, title) {
            init();
            toastr.info(message, title);
        },
        warning = function (message, title, timeout) {
            init();
            toastr.warning(message, title, timeout);
        },
        init = function () {
            toastr.options.positionClass = 'toast-bottom-right';
            toastr.options.timeOut = 0;
            toastr.options.extendedTimeOut = 0;
            toastr.options.closeButton = true;
        };
    init();
    return {
        success: success,
        error: error,
        info: info,
        warning: warning
    }

});
