define('userRoleModel',["knockout"],
    function (ko) {

        console.log('User Role Model called...');


        function userRole() {
            var self = this;
            self.userId = ko.observable("");
            self.operationId = ko.observable("");
            self.roleId = ko.observable("");
            self.roleName = ko.observable("");


            self.reset = function() {
                self.userId("");
                self.operationId("");
                self.roleId("");
                self.roleName("");
            }
        }

        var vm = {
            userRole: userRole
        }

        return vm;
    });
