define('models/userProfile/tcpaConsentModel',["knockout"],
    function (ko) {

        function tcpaUserDataModel() {
            var self = this;

            self.userIpAddress = ko.observable("");
            self.operations = ko.observable("");
            self.agreements = ko.observable("");
            self.name = ko.observable("");
            self.email = ko.observable("");
            self.phone = ko.observable("");
            self.environment = ko.observable("");
            self.token = ko.observable("");


            self.update = function (data) {
                if (data) {
                    self.userIpAddress(data.UserIpAddress);
                    self.operations(data.OperationFlashCode);
                    self.agreements("");
                    self.name(data.UserName);
                    self.email(data.UserEmail);
                    self.phone(data.UserPhone);
                    self.environment(data.Environment);
                    self.token(data.OKTAToken.ResponseItem.access_token);
                }
            };

            self.reset = function () {
                self.userIpAddress("");
                self.operations("");
                self.agreements("");
                self.name("");
                self.email("");
                self.phone("");
                self.environment("");
                self.token("");
                ko.validation.group(self).showAllMessages(false);
            }

            self.errors = ko.validation.group(self);
            self.isValid = ko.computed(function () {
                return self.errors().length === 0;
            });
        }
        var vm = {
            tcpaUserDataModel: tcpaUserDataModel
        }
        return vm;
    });
