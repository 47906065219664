define('suspendNotification',["knockout"],
    function (ko) {
        ko.observable.fn.silentUpdate = function (value) {
            this.notifySubscribers = function () { };
            this(value);
            this.notifySubscribers = function () {
                ko.subscribable.fn.notifySubscribers.apply(this, arguments);
            };
        };

        ko.conditionedObservable = function (initialValue, condition) {
            var obi = ko.observable(initialValue);
            var computer = ko.computed({
                read: function () { return obi(); },
                write: function (newValue) {
                    //unwrap value - just to be sure
                    var v = ko.unwrap(newValue);
                    //check condition
                    if (condition(v)) {
                        //set it to the observable
                        obi(v);
                    }
                    else {
                        //reset the value
                        computer.notifySubscribers();
                    }
                }
            });
            return computer;
        };

        ko.bindingHandlers.hasFocus = {
            init: function (element, valueAccessor) {
                $(element).focus(function () {
                    var value = valueAccessor();
                    if (ko.unwrap(value))
                        element.focus();
                });
                $(element).blur(function () {
                    var value = valueAccessor();
                    if (ko.unwrap(value))
                        element.blur();
                });
            },
            update: function (element, valueAccessor) {
                var value = valueAccessor();
                if (ko.unwrap(value))
                    element.focus();
                else
                    element.blur();
            }
        };
    });

