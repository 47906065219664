define('models/user/registerModel',["knockout"],
    function (ko) {
        function register() {
            //self.update = function (data) {
            //    if (data) {
            //        self.userName(data.userName);
            //        self.password(data.password);
            //        self.rememberMe(data.rememberMe);
            //    }
            //};
        }

        var vm = {
            register: register
        }

        return vm;
    });
