define('models/propertyDetails/purchaseableReportShopCartItem',["knockout"], function (ko) {
    function purchaseableReportShopCartItem() {
        var self = this;

        self.Name = ko.observable("");
        self.Price = ko.observable(0);
        self.Credits = ko.observable(0);
        self.AddedToCart = ko.observable(false);
        
    }

    return purchaseableReportShopCartItem;
});
