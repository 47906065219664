define('viewmodels/calculators/calculators',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'knockout.validation', 'plugins/http', 'global/session', 'config', 'alertNotification', 'calculatorsModel', 'utlities'],
 function (app, router, $, ko, validate, validation, https, session, config, alert, calculatorsModel, utlities) {
     var calculatorsModel = new calculatorsModel.calculators();
     var queryString = ko.observable('');

     function activate(brand) {
         var title = app.title;
         if (title === '' || title === 'Application')
             queryString(brand);
         else
             queryString(title.substr(0, title.indexOf(' ')).toLowerCase());
     }
     
     //function setQueryString(currentBrandId) {
     //    switch (currentBrandId) {

     //        case 2:
     //            queryString('Ticor')
     //            break;
     //        case 3:
     //            queryString('FNTIC')
     //            break;
     //        case 7:
     //            queryString('FNTG')
     //            break;
     //        case 15:
     //            queryString('FNTG')
     //            carouselView('CLTIC_Carousel.html');
     //            app.title = 'Commonwealth Land Title Insurance Company';
     //            break;
     //        case 4:
     //            queryString('FNTG')
     //            carouselView('CTICCarousel.html');
     //            app.title = 'Chicago Premier Title Services';
     //            break;
     //        case 5:
     //            queryString('FNTG')
     //            carouselView('AlamoCarousel.html');
     //            app.title = 'Alamo Title Company';
     //            break;
     //        case 12:
     //            queryString('FNTG')
     //            carouselView('GCTACarousel.html');
     //            app.title = 'Grand Canyon Title Agency';
     //            break;

     //        case 16:
     //            queryString('FNTG')
     //            carouselView('LTICCarousel.html');
     //            app.title = 'Lawyers Title';
     //            break;
     //        case 19:
     //            queryString('FNTG')
     //            carouselView('STACarousel.html');
     //            app.title = 'Security Title Agency';
     //            break;

     //        case 21:
     //            queryString('FNTG')
     //            carouselView('AustinCarousel.html');
     //            app.title = 'Austin Title';
     //            break;

     //    }
     //}

     function compositionComplete() {
         session.validateInput();
     }

     function isZipValid() {
         if (calculatorsModel.zip() == '' || calculatorsModel.zip() == undefined)
             alert.error('Zip is required');
         if (calculatorsModel.zip().length == 5)
             return true;
     }
     var vm = {
         calculatorsModel: calculatorsModel,
         isZipValid: isZipValid,
         activate: activate,
         queryString: queryString,
         compositionComplete: compositionComplete
     }
     return vm;

 })



;
