define('models/documentSearch/documentSearch',["knockout"],
    function (ko) {

        function ApnSearch() {

            var self = this;

            self.state = ko.observable(undefined)
                    .extend({ required: { params: true, message: 'State is required.' } });

            self.county = ko.observable(undefined)
            .extend({ required: { params: true, message: 'County is required.' } });

            self.zip = ko.observable("")
            //    .extend({
            //    required: {
            //        message: "Zip is required.",
            //        onlyIf: function () { return (self.state() == "" || self.county() == ""); }
            //    }
            //})
                       .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                       .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                       .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' });




            self.apnNumber = ko.observable("")
            .extend({ required: { params: true, message: 'APN is required.' } });

            self.update = function (data) {
                if (data) {
                    self.zip(data.zip);
                    self.state(data.state);
                    self.county(data.county);
                    self.apnNumber(data.apnNumber);

                }

            };

            self.reset = function () {
                self.zip('');
                self.state(undefined);
                self.county(undefined);
                self.apnNumber('');
                ko.validation.group(self).showAllMessages(false);
            }
        }

        function AddressSerach() {

            var self = this;

            self.state = ko.observable(undefined).extend({ required: { params: true, message: 'State is required.' } });

            self.city = ko.observable(undefined).extend({ required: { params: true, message: 'City is required.' } });

            self.zip = ko.observable("")
              //  .extend({
              //    required: {
              //        message: "Zip is required.",
              //        onlyIf: function () { return (self.state()== "" || self.city()== ""); }
              //    }
              //})
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
            .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
            .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' });

            self.streetNumber = ko.observable("");
            self.streetName = ko.observable("")
             .extend({
                 required: {
                     message: "At least Street Name is required",
                     onlyIf: function () { return self.streetNumber().length == 0; }
                 }
             });

            self.unitNumber = ko.observable("");

            self.update = function (data) {
                if (data) {
                    self.zip(data.zip);
                    self.state(data.state);
                    self.city(data.city);
                    self.streetNumber(data.streetNumber);
                    self.streetName(data.streetName);
                    self.unitNumber(data.unitNumber);

                }

            };

            self.reset = function () {

                self.zip('');
                self.state(undefined);
                self.city(undefined);
                self.streetNumber('');
                self.streetName('');
                self.unitNumber('');
                ko.validation.group(self).showAllMessages(false);


            }
        }

        function PropertyAddress() {

            var self = this;
            self.Apn = ko.observable('');
            self.Address = ko.observable('');
            self.State = ko.observable('');
            self.City = ko.observable('');
            self.OwnerFullName = ko.observable('');
            self.Zip = ko.observable('');
            self.Zip4 = ko.observable('');
            self.UnitType = ko.observable("");
            self.UnitNumber = ko.observable('');
            self.CompleteAddress = ko.observable('');

            self.reset = function () {
                self.Apn('');
                self.Zip('');
                self.State('');
                self.City('');
                self.Zip4('');
                self.UnitType('');
                self.UnitNumber('');
                self.OwnerFullName('');
                self.Address('');
                self.CompleteAddress('');
                ko.validation.group(self).showAllMessages(false);
            }

            self.update = function (data) {
                if (data) {
                    self.Apn(data.Apn);
                    self.State(data.State);
                    self.City(data.City);
                    self.Address(data.Address);
                    self.OwnerFullName(data.OwnerFullName);
                    self.Zip(data.Zip);
                    self.Zip4(data.Zip4);
                    self.UnitType(data.UnitType);
                    self.UnitNumber(data.UnitNumber);

                    var addressString = '';
                    if (data.Address != null && data.Address.length > 0)
                        addressString = data.Address + ' ';

                    if (data.UnitType != null && data.UnitType.length > 0)
                        addressString = addressString + data.UnitType + ' ';

                    if (data.UnitNumber != null && data.UnitNumber.length > 0)
                        addressString = addressString + data.UnitNumber + ' ';

                    if (data.City != null && data.City.length > 0)
                        addressString = addressString + data.City + ', ';

                    if (data.State != null && data.State.length > 0)
                        addressString = addressString + data.State + ' ';

                    if (data.Zip != null && data.Zip.length > 0)
                        addressString = addressString + data.Zip;

                    if (data.Zip4 != null && data.Zip4.length > 0) {
                        addressString = addressString + '-' + data.Zip4;
                    }
                    self.CompleteAddress(addressString);
                }
            };

        }

        function DocumentInfo() {
            var self = this;

            self.Book = ko.observable('');
            self.DocumentNumber = ko.observable('');
            self.DocumentType = ko.observable('');
            self.Fips = ko.observable('');
            self.Id = ko.observable('');
            self.Instrument = ko.observable('');
            self.Page = ko.observable('');
            self.RecordingDate = ko.observable("");
            self.Url = ko.observable('');


            self.reset = function () {
                self.Book('');
                self.DocumentNumber('');
                self.DocumentType('');
                self.Fips('');
                self.Id('');
                self.Instrument('');
                self.Page('');
                self.RecordingDate('');
                self.Url('');
                ko.validation.group(self).showAllMessages(false);
            }

            self.update = function (data) {
                if (data) {
                    self.Book(data.Book);
                    self.DocumentNumber(data.DocumentNumber);
                    self.DocumentType(data.DocumentType);
                    self.Fips(data.Fips);
                    self.Id(data.Id);
                    self.Instrument(data.Instrument);
                    self.Page(data.Page);
                    self.RecordingDate(data.RecordingDate);
                    self.Url(data.Url);
                }
            };

        }

        function DocumentNumberSearch() {
            var self = this;

            self.state = ko.observable(undefined)
                    .extend({ required: { params: true, message: 'State is required.' } });

            self.county = ko.observable(undefined)
            .extend({ required: { params: true, message: 'County is required.' } });

            self.book = ko.observable('');
            self.documentnumber = ko.observable('');
            self.page = ko.observable('');
            //.extend({
            //    required: {
            //        message: "Either Document number or Book and Page are required.",
            //        onlyIf: function () {
            //            return (self.documentnumber() == '') == true;
            //        }
            //    }
            //});

            self.documentnumber.extend({
                required: {
                    message: "Either Document number or Book and Page are required.",
                    onlyIf: function () {
                        return (self.page() == '' && self.book() == '') == true;
                    }
                }
            });

            self.recordingdate = ko.observable('')
              .extend({ required: { params: true, message: 'Recording Date is required.' } });

            self.update = function (data) {
                if (data) {
                    self.documentnumber(data.documentnumber);
                    self.state(data.state);
                    self.county(data.county);
                    self.book(data.book);
                    self.page(data.page);
                    self.recordingdate(data.recordingdate);
                }
            };

            self.reset = function () {
                self.documentnumber('');
                self.state(undefined);
                self.county(undefined);
                self.book('');
                self.page('');
                self.recordingdate('');
                ko.validation.group(self).showAllMessages(false);
            }
        }

        var vm = {
            ApnSearch: ApnSearch,
            AddressSerach: AddressSerach,
            DocumentNumberSearch: DocumentNumberSearch,
            PropertyAddress: PropertyAddress,
            DocumentInfo: DocumentInfo
        };

        return vm;
    }
);





