//TODO: Inject dependencies
define('viewmodels/orderModule/orders',['plugins/router', 'knockout', 'durandal/app', 'plugins/http', 'global/session', 'config'],
    function (router, ko, app, https, session, config) {
        // Internal properties and functions
        ko.options.deferUpdates = true;

        var orderTypes = ko.observableArray();

        function checkForLLA(data) {
            if (data.LLACheckReq) {
                if (!session.llaAccepted()) {
                    session.showLLAAgreement();
                    return false;
                }
            }
            return true;
        }

        // Reveal the bindable properties and functions
        var vm = {
            activate: activate,
            goBack: goBack,
            title: 'Orders',
            orderTypes: orderTypes,
            checkForLLA: checkForLLA,
            canDeactivate: canDeactivate,
            compositionComplete: compositionComplete,
            router: router
            
           
        };
        return vm;


        function activate() {
            //TODO: Initialize lookup data here.
            session.showTopSearchControl(true);
            //TODO: Get the data for this viewmodel, return a promise.
            return getOrderTypes();
        }

        
        function compositionComplete() {
            session.validateInput();
            if (session.profileId() != undefined && session.profileId() != null && session.profileId() != "") {
                getPropertyInformationFromCurrentProfile(session.profileId()).done(function (data) {
                    session.orderForPropertyAddress.reset();//Do not reset if session.profileId() is null, Address may be coming from Profile Page
                    session.orderForPropertyAddress.updateFromURL(data);
                    console.log(session.orderForPropertyAddress);
                }).then(function () {
                    session.profileId("");
                });
            }
            else {
                session.orderForPropertyAddress.addressInURL(false);
            }
            $('#tblOrders tr:first a').focus();
        }

        function getOrderTypes() {
            orderTypes([]);
            console.log('getOrderTypes called');
            return https.get('api/orders/GetOrderFormsByOperation?operationId=' + session.defaultOpertionId()).done(function (result) {

                var data = result.Forms;
                var routes = result.Routes;

                var count = 0;
                ko.utils.arrayForEach(router.routes, function (item) {
                    if (item.type && item.type == 'Orders')
                        count++;
                });

                if (count == 0) {
                    var orderRoutes = [];
                    ko.utils.arrayForEach(routes, function (item) {
                        orderRoutes.push(
                        { route: item.Name, title: item.Title, moduleId: item.Module, requiredRoles: item.Roles, type: item.Type, nav: item.IsNav });
                    });
                    router.map(orderRoutes).buildNavigationModel();
                }


                var orderType = null;
                for (var i = 0; i < data.length; i++) {
                    orderType = null;
                    orderType = {
                        ServiceId: data[i].ServiceId,
                        OperationId: data[i].OperationId,
                        Name: data[i].Name,
                        Description: data[i].Description,
                        targetVM: "",
                        LLACheckReq: false
                    };

                    switch (orderType.ServiceId) {
                        case 17:
                        case 47:
                        case 48:
                        case 99:
                        case 97:
                        case 98:
                            orderType.targetVM = "order";
                            break;
                        case 49:
                        case 50:
                        case 51:
                            orderType.targetVM = "offlineOrder";
                            orderType.LLACheckReq = true;
                            break;
                        case 58:
                            orderType.targetVM = "pirtOrder";
                            break;
                        case 59:
                            orderType.targetVM = "bulkSaleOrder";
                            break;
                        case 60:
                            orderType.targetVM = "residentialEscrow";
                            break;
                        case 61:
                            orderType.targetVM = "mobileHomeEscrow";
                            break;
                        case 64:
                            orderType.targetVM = "refiLenderEscrow";
                            break;
                        case 95:
                            orderType.targetVM = "coloradoRefiLenderEscrow";
                            break;
                        case 65:
                            orderType.targetVM = "chicagoTitleOrder";
                            break;
                        case 66:
                            orderType.targetVM = "coloradoTitleOrder";
                            break;
                        case 73:
                            orderType.targetVM = "paidorder";
                            orderType.LLACheckReq = true;
                            break;
                        case 74:
                            orderType.targetVM = "paidorder";
                            orderType.LLACheckReq = true;
                            break;
                        case 78:
                            orderType.targetVM = "paidorder";
                            orderType.LLACheckReq = true;
                            break;
                        case 79:
                            orderType.targetVM = "paidorder";
                            orderType.LLACheckReq = true;
                            break;
                        case 80:
                            orderType.targetVM = "paidorder";
                            orderType.LLACheckReq = true;
                            break;

                    }

                    if (orderType.targetVM.length > 0)
                        orderTypes.push(orderType);

                   
                }

            }).fail(function () {
                //app.showMessage('Failed to get Order Types.','Order Types', ['OK']);
            });
        }

        function canDeactivate() {
            //session.clearOrderForPropertyAddress();
            if (!(router.activeInstruction().config.moduleId.indexOf("viewmodels/orderModule") >= 0))
                session.clearOrderForPropertyAddress();
            return true;
        }


        function getPropertyInformationFromCurrentProfile(profileId) {
            var url = config.getPropertyInformationFromCurrentProfileUrl;
            return https.get(url,
                {
                    profileId: profileId,
                    format: 'json'
                });
        }

        function goBack(complete) {
            router.navigateBack();
        }
    });
