define('commonOrderModel',["knockout", "modelMetaData", 'utlities', 'moment', 'global/session'],
    function (ko, modelMetaData, utils, moment, session) {

        function orderInformation() {
            var self = this;
            self.viewName = "orderModule/_orderInformation.html"
            self.title = "Order Information"
            self.ModelId = "orderInformation"
            self.index = ko.observable(-1)
            self.date = ko.observable("")
            
            self.service = ko.observable("")
            self.orderType = ko.observable("")
            self.orderFormName = ko.observable("")
            //.extend({ required: { message: 'Order Form is required.' } })

            self.isEscrowOrder = ko.observable(false);
            self.isTitleOrder = ko.observable(false);
            self.isIcloseOrder = ko.observable(undefined);
            self.estimatedCloseDate = ko.observable("");
            self.cb1 = ko.observable(false);
            self.cb2 = ko.observable(false);
            self.cb3 = ko.observable(false);
            self.cb5 = ko.observable(false);
            self.cb6 = ko.observable(false);
            self.cb7 = ko.observable(false);
            self.cb8 = ko.observable(false);
            self.cb9 = ko.observable(false);
            self.cb10 = ko.observable(false);
            self.cb11 = ko.observable(false);
            self.cb12 = ko.observable(false);
            self.cb13 = ko.observable(false);
            self.cb14 = ko.observable(false);
            self.cb16 = ko.observable(false);
            self.cb17 = ko.observable(false);
            self.cb18 = ko.observable(false);
            self.cb19 = ko.observable(false);
            self.cb20 = ko.observable(false);
            self.cb22 = ko.observable(false);
            self.cb23 = ko.observable(false);

            self.servicesList = ko.observableArray([]);
                          
            self.transactionType = ko.observable(undefined)
                .extend({ required: { message: 'Transaction Type is required.' } });

            
            self.salesRepresentative = ko.observable(undefined)
                .extend({ required: {
                    message: 'Sales Representative is Required.',
                    onlyIf: function () {
                        return self.isIcloseOrder();
                    }
                } });

            
            self.TransactionTypeName = ko.observable('');
            self.TransactionTypeId = ko.observable('');

            //self.transactionAmount = ko.observable(0).extend({
            //    number: {
            //        params: true,
            //        message: 'Transaction amount should be a valid number.'
            //    }
            //});


            self.transactionAmount = ko.observable(0).extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Transaction Amount.'
                    }
                
            });

            self.scrowOffice = ko.observable(undefined)
                .extend({
                    required: {
                        message: "Escrow Office is required",
                        onlyIf: function () {
                            return self.isEscrowOrder();
                        }
                    }
                });


            self.scrowOfficer = ko.observable(undefined)
                .extend({
                    required: {
                        message: "Escrow Officer is required",
                        onlyIf: function () {
                            return self.isEscrowOrder();
                        }
                    }
                });


            self.titleOffice = ko.observable(undefined)
            .extend({
                required: {
                    message: "Title Office is required",
                    onlyIf: function () {
                        return self.isTitleOrder();
                    }
                }
            });



            self.titleOfficer = ko.observable(undefined)
                .extend({
                    required: {
                        message: "Title Officer is required",
                        onlyIf: function () {
                            return self.isTitleOrder();
                        }
                    }
                });

            self.titleCostProfitCenterId = ko.observable(undefined);
            self.escrowCostProfitCenterId = ko.observable(undefined);

            self.lcp = ko.observable(undefined);
             
            


            self.lcpList = ko.observableArray([
                { key: "No", value: "No" },
                { key: "Yes", value: "Yes" }
            ]);

            //self.modelData = ko.observable(undefined);

            self.iCloseEscrowOfficerId = ko.observable("");
            self.iCloseEscrowOfficerName = ko.observable("");
            self.iCloseTitleOfficerId = ko.observable("");
            self.iCloseTitleOfficerName = ko.observable("");
            self.iCloseSalesRepresentativeId = ko.observable("");
            self.iCloseSalesRepresentativeName = ko.observable("");

            self.selectedTransactionTypeName = ko.observable("");
            self.scrowOfficeName = ko.observable("");
            self.scrowOfficerName = ko.observable("");
            self.titleOfficeName = ko.observable("");
            self.titleOfficerName = ko.observable("");
            

            

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Transaction Type", self.selectedTransactionTypeName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Date", moment.utc(self.date()).format('MM/DD/YYYY')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Order Type", self.service()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Estimated Close Date", self.estimatedCloseDate()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Transaction Amount", "$ " + self.transactionAmount()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("LCP", self.lcp()));
                if (!self.isIcloseOrder()) {
                    if (self.isEscrowOrder()) {
                        self.modelData.properties.push(
                            new modelMetaData.propertyData().create("Escrow Office", self.scrowOfficeName()));
                        self.modelData.properties.push(
                            new modelMetaData.propertyData().create("Escrow Officer", self.scrowOfficerName()));
                    }
                    if (self.isTitleOrder()) {
                        self.modelData.properties.push(
                            new modelMetaData.propertyData().create("Title Office", self.titleOfficeName()));
                        self.modelData.properties.push(
                            new modelMetaData.propertyData().create("Title Officer", self.titleOfficerName()));
                    }
                    self.modelData.properties.push(
                        new modelMetaData.propertyData().create("Sales Representative", session.managerFullName()));
                } else {
                    

                    if (self.isEscrowOrder()) {
                        self.modelData.properties.push(
                            new modelMetaData.propertyData().create("Escrow Office", self.scrowOfficeName()));
                        self.modelData.properties.push(
                            new modelMetaData.propertyData().create("Escrow Officer", self.iCloseEscrowOfficerName()));
                    }
                    if (self.isTitleOrder()) {
                        self.modelData.properties.push(
                            new modelMetaData.propertyData().create("Title Office", self.titleOfficeName()));
                        self.modelData.properties.push(
                            new modelMetaData.propertyData().create("Title Officer", self.iCloseTitleOfficerName()));
                    }
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Sales Representative",
                        self.iCloseSalesRepresentativeName()));

                    self.servicesList = ko.observableArray([]);
                    if (self.cb1()) self.servicesList.push({ "serviceName": "Order All Docs" });
                    if (self.cb2()) self.servicesList.push({ "serviceName": "Number of Pres to Agent" });
                    if (self.cb3()) self.servicesList.push({ "serviceName": "Hold" });
                    if (self.cb5()) self.servicesList.push({ "serviceName": "Number of Pres" });
                    if (self.cb6()) self.servicesList.push({ "serviceName": "Number of CC&Rs" });
                    if (self.cb7()) self.servicesList.push({ "serviceName": "Legal & Vesting" });
                    if (self.cb8()) self.servicesList.push({ "serviceName": "Plot Easements" });
                    if (self.cb9()) self.servicesList.push({ "serviceName": "Recording Number" });
                    if (self.cb10()) self.servicesList.push({ "serviceName": "Email Pre (1) to" });
                    if (self.cb11()) self.servicesList.push({ "serviceName": "Email Pre to Escrow" });
                    if (self.cb12()) self.servicesList.push({ "serviceName": "Email Pre to Lender" });
                    if (self.cb13()) self.servicesList.push({ "serviceName": "Email Pre to Listing Agent" });
                    if (self.cb14()) self.servicesList.push({ "serviceName": "Email Pre to Selling Agent" });
                    if (self.cb16()) self.servicesList.push({ "serviceName": "Email Pre to Mortgage Broker" });
                    if (self.cb17()) self.servicesList.push({ "serviceName": "Email Pre (2) to" });
                    if (self.cb18()) self.servicesList.push({ "serviceName": "Email Pre (3) to" });
                    if (self.cb19()) self.servicesList.push({ "serviceName": "Email Pre (4) to" });
                    if (self.cb20()) self.servicesList.push({ "serviceName": "Email Pre (5) to" });
                    if (self.cb22()) self.servicesList.push({ "serviceName": "Mortgage Recording Date (1st)" });
                    if (self.cb23()) self.servicesList.push({ "serviceName": "Mortgage Recording Date (2nd)" });
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Customer Services", self.servicesList(), 'collection', 'views/orderModule/orderPreview/_customerServices.html'));
                }   
            }

            self.update = function (data) {
                if (data) {
                    self.date(data.date)
                    self.Service(data.service)
                    self.orderFormName(data.orderFormName)
                    self.estimatedCloseDate(data.estimatedCloseDate)
                    self.transactionType(data.transactionType)
                    self.transactionAmount(data.transactionAmount)
                    self.scrowOffice(data.scrowOffice)
                    self.scrowOfficer(data.scrowOfficer)
                    self.titleOffice(data.titleOffice)
                    self.titleOfficer(data.titleOfficer)
                    self.lcp(data.lcp)
                    self.salesRepresentative(data.salesRepresentative)
                    self.titleCostProfitCenterId(data.titleCostProfitCenterId)
                    self.escrowCostProfitCenterId(data.escrowCostProfitCenterId)
                    self.iCloseEscrowOfficerId(data.iCloseEscrowOfficerId);
                    self.iCloseEscrowOfficerName(data.iCloseEscrowOfficerName);
                    self.iCloseTitleOfficerId(data.iCloseTitleOfficerId);
                    self.iCloseTitleOfficerName(data.iCloseTitleOfficerName);
                    self.iCloseSalesRepresentativeId(data.iCloseSalesRepresentativeId);
                    self.iCloseSalesRepresentativeName(data.iCloseSalesRepresentativeName);
                    self.cb1(data.cb1);
                    self.cb2(data.cb2);
                    self.cb3(data.cb3);
                    self.cb5(data.cb5);
                    self.cb6(data.cb6);
                    self.cb7(data.cb7);
                    self.cb8(data.cb8);
                    self.cb9(data.cb9);
                    self.cb10(data.cb10);
                    self.cb11(data.cb11);
                    self.cb12(data.cb12);
                    self.cb13(data.cb13);
                    self.cb14(data.cb14);
                    self.cb16(data.cb16);
                    self.cb17(data.cb17);
                    self.cb18(data.cb18);
                    self.cb19(data.cb19);
                    self.cb20(data.cb20);
                    self.cb22(data.cb22);
                    self.cb23(data.cb23);

                }
            }

            self.reset = function () {
                self.date("")
                self.service("")
                self.orderType("")
                self.orderFormName("")
                self.estimatedCloseDate("")
                self.transactionType(undefined)
                self.transactionAmount("")
                self.isEscrowOrder(false)
                self.isTitleOrder(false)
                self.scrowOffice(undefined)
                self.scrowOfficer(undefined)
                self.titleOffice(undefined)
                self.titleOfficer(undefined)
                self.lcp(undefined)
                self.salesRepresentative(undefined)
                self.titleCostProfitCenterId(undefined)
                self.escrowCostProfitCenterId(undefined)
                ko.validation.group(self).showAllMessages(false);
                self.iCloseEscrowOfficerId("");
                self.iCloseEscrowOfficerName("");
                self.iCloseTitleOfficerId("");
                self.iCloseTitleOfficerName("");
                self.iCloseSalesRepresentativeId("");
                self.iCloseSalesRepresentativeName("");
                self.cb1(false);
                self.cb2(false);
                self.cb3(false);
                self.cb5(false);
                self.cb6(false);
                self.cb7(false);
                self.cb8(false);
                self.cb9(false);
                self.cb10(false);
                self.cb11(false);
                self.cb12(false);
                self.cb13(false);
                self.cb14(false);
                self.cb16(false);
                self.cb17(false);
                self.cb18(false);
                self.cb19(false);
                self.cb20(false);
                self.cb22(false);
                self.cb23(false);
                


            }


            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }


        }

        function SubjectProperty() {
            var self = this;
            self.viewName = "orderModule/_subjectProperty.html"
            self.title = "Subject Property"
            self.ModelId = "SubjectProperty"
            self.index = ko.observable(-1)
            //self.isSkippable = false;

            self.addressNumber = ko.observable("")
             .extend({
                 required: {
                     params: true,
                     message: 'Address Number is required.',
                     onlyIf: function () {
                         return self.isOnlinePropertyType(); 
                     }
                 }
             })
             .extend({
                 digit: {
                     params: true,
                     message: 'Please enter a digit',
                     
                 }
             })



            self.addressStreetName = ko.observable("")
             .extend({
                 required: {
                     params: true,
                     message: 'Address Street Name is required.',
                     
                 }
             })
             .extend({
                 required: {
                     params: true,
                     message: 'Address is required.',
                     
                 }
             })




            self.zip = ko.observable("")
          .extend({ required: { message: 'Zip is required.' } })
         .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
            .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
            .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.city = ko.observable(undefined)
             .extend({ required: { params: true, message: 'City is required.' } });

            self.state = ko.observable(undefined)
             .extend({ required: { params: true, message: 'State is required.' } });


            self.county = ko.observable(undefined)
                          .extend({ required: { message: 'County is required.' } });


            self.apn = ko.observable("");

            self.isOfflinePropertyType = ko.observable(false);
            self.selectedOfflinePropertyType = ko.observable("");
            self.isOnlinePropertyType = ko.observable(false);
            self.selectedOnlinePropertyType = ko.observable("");
            self.isIcPropertyType = ko.observable(false);
            self.selectedIcPropertyType = ko.observable("");

          

            self.propertyType = ko.observable(undefined)        //8888888888888888
                         .extend({
                             required: {
                                 message: "Property Type is required.",
                                 onlyIf: function () {
                                     return self.isOnlinePropertyType(); //|| self.isIcPropertyType());
                                 }
                             }
                });

            self.icPropertyType = ko.observable(undefined)
                .extend({
                    required: {
                        message: "Property Type is required.",
                        onlyIf: function () {
                            return (self.isIcPropertyType());
                        }
                    }
                });

            self.offlinePropertyType = ko.observable(undefined)
            .extend({
                required: {
                    message: "Property Type is required.",
                    onlyIf: function () {
                        return self.isOfflinePropertyType(); //|| self.isIcPropertyType());
                    }
                }
            });

           self.propertyTypeList = ko.observableArray([
                    { key: "AgriculturalLand", value: "Agricultural Land" },
                    { key: "Apartment", value: "Apartment" },
                    { key: "ApartmentCommercial", value: "Apartment + Commercial" },
                    { key: "Commercial", value: "Commercial" },
                    { key: "Condominium", value: "Condominium" },
                    { key: "CoOp", value: "Co-Op" },
                    { key: "Industrial", value: "Industrial" },
                    { key: "ManufacturedHome", value: "Manufactured Home" },
                    { key: "MultiFamily24Commercial", value: "Multi Family 2 - 4 + Commercial" },
                    { key: "MultiFamily24", value: "Multi Family 2 - 4" },
                    { key: "PUD", value: "PUD" },
                    { key: "SingleFamily", value: "Single Family" },
                    { key: "SingleFamilyCommercial", value: "Single Family + Commercial" },
                    { key: "TimeShare", value: "Time Share" },
                    { key: "Townhouse", value: "Townhouse" },
                    { key: "TownhouseCommercial", value: "Townhouse + Commercial" },
                    { key: "Unknown", value: "Unknown" },
                    { key: "Vacant", value: "Vacant" },
                    { key: "Other", value: "Other" }
            
                ]);

            self.icPropertyTypeList = ko.observableArray([]);

            self.offlinePropertyTypeList = ko.observableArray([

             { key: "FamilyResidential14", value: "1-4 Family Residential" },
             { key: "AgriculturalLand", value: "Agriculture" },
             { key: "Commercial", value: "Commercial" },
             { key: "Condominium", value: "Condominium" },
             { key: "CoOp", value: "Cooperative" },
             { key: "Industrial", value: "Industrial" },
             { key: "ManufacturedHome", value: "Manufactured Housing" },
             { key: "MultiFamily24", value: "Multi Family" },
             { key: "Other", value: "Other" },
             { key: "RetailStructureStripCenter", value: "Retail Structure/Strip Center" },
             { key: "ShoppingCenterMall", value: "Shopping Center/Mall" },
             { key: "SingleFamily", value: "Single Family" },
             { key: "TimeShare", value: "Time Share (including recreational land)" },
             { key: "UnimprovedLots", value: "Unimproved Lots" },
             { key: "Vacant", value: "Vacant Land" },
            ]);

            //self.modelData = ko.observable();

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("APN", self.apn()));
                if (self.isOnlinePropertyType()) {
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Property Type", self.selectedOnlinePropertyType()));
                }
                if (self.isOfflinePropertyType()) {
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Property Type", self.selectedOfflinePropertyType()));
                }
                if (self.isIcPropertyType()) {
                    self.modelData.properties.push(new modelMetaData.propertyData().create("Property Type", self.selectedIcPropertyType()));
                }    
                
                    
                self.modelData.properties.push(new modelMetaData.propertyData().create("Address", self.addressStreetName()));
//                self.modelData.properties.push(new modelMetaData.propertyData().create("Apt/Unit #", self.addressNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip", self.zip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("County", self.county()));
            }

            self.update = function (data) {
                if (data) {

                    self.addressNumber(data.addressNumber)
                    self.addressStreetName(data.addressStreetName)
                    self.zip(data.zip)
                    self.city(data.city)
                    self.state(data.state)
                    self.county(data.county)
                    self.apn(data.apn)
                    self.propertyType(data.propertyType)
                }

            }

            self.reset = function () {
                self.addressNumber('')
                self.addressStreetName('')
                self.zip('')
                self.city(undefined)
                self.state(undefined)
                self.county(undefined)
                self.apn('')
                self.propertyType(undefined)
                self.offlinePropertyType(undefined)
                //self.onlinePropertyType(undefined)
                self.icPropertyType(undefined)
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

        }

        function OrderRequester() {
            // * These fields are automatically pulled from your profile and cannot be modified.
            var self = this;
            self.viewName = "orderModule/_orderRequester.html"
            self.title = "Order Requester"
            self.ModelId = "OrderRequester"
            self.index = ko.observable(-1)
            self.firstName = ko.observable("")
            self.lastName = ko.observable("")
            self.company = ko.observable("")
            self.address = ko.observable("")
            self.zip = ko.observable("")
                .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.city = ko.observable(undefined)
            self.state = ko.observable(undefined)
            self.phone = ko.observable("")
            self.phoneExt = ko.observable("")
            .extend({ digit: true });
            self.fax = ko.observable("")
            self.faxExt = ko.observable("")
            self.email = ko.observable("")
             .extend({ email: { message: 'Invalid Email Address.' } })
                .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })


            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("First Name", self.firstName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Last Name", self.lastName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Company", self.company()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Address", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip", self.zip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Phone", modelMetaData.getUSPhoneFormatWithExtn(self.phone(), self.phoneExt(), 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Fax", modelMetaData.getUSPhoneFormatWithExtn(self.fax(), '', 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Email", self.email()));

            }

            self.update = function (data) {
                if (data) {

                    self.firstName(data.firstName)
                    self.lastName(data.lastName)
                    self.company(data.company)
                    self.address(data.address)
                    self.zip(data.zip)
                    self.city(data.city)
                    self.state(data.state)
                    self.phone(data.phone)
                    self.phoneExt(data.phoneExt)
                    self.fax(data.fax)
                    self.faxExt(data.faxExt)
                    self.email(data.email)
                }

            }

            self.reset = function () {

                self.firstName('')
                self.lastName('')
                self.company('')
                self.address('')
                self.zip('')
                self.city(undefined)
                self.state(undefined)
                self.phone('')
                self.phoneExt('')
                self.fax('')
                self.faxExt('')
                self.email('')
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

        }

        function PrincipalSeller() {
            var self = this;
            self.viewName = "orderModule/_principalSeller.html"
            self.title = "Principals-Seller"
            self.index = ko.observable(-1)
            self.ModelId = "PrincipalSeller"
            self.IsWesbOrder = ko.observable(false);


            self.sellerFirstName = ko.observable("")
             .extend({ required: { message: 'Seller First Name is required.' } })
            self.sellerLastName = ko.observable("")
             .extend({ required: { message: 'Seller Last Name is required.' } })

            self.sellerFullName = ko.computed(function () {
                return self.sellerFirstName() + " " + self.sellerLastName();
            });

            self.workPhonExt = ko.observable("")
           .extend({ digit: true });

            self.sellerWorkPhone = ko.observable("").extend({
                required: {
                    message: "Work Phone is required",
                    onlyIf: function () {
                        return self.workPhonExt().length > 0;
                    }
                }
            })
            .extend({ phoneUS: true })

            self.sellerHomePhone = ko.observable("")
            .extend({ phoneUS: true })

            self.homePhonExt = ko.observable("")
            self.coSellerFirstName = ko.observable("")
            self.coSellerLastName = ko.observable("")

            self.coSellerFullName = ko.computed(function () {
                return self.coSellerFirstName() + " " + self.coSellerLastName();
            });


            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.sellerFirstName(data.sellerFirstName())
                    self.sellerLastName(data.sellerLastName())
                    self.sellerWorkPhone(data.sellerWorkPhone())
                    self.workPhonExt(data.workPhonExt())
                    self.sellerHomePhone(data.sellerHomePhone())
                    self.homePhonExt(data.homePhonExt())
                    self.coSellerFirstName(data.coSellerFirstName())
                    self.coSellerLastName(data.coSellerLastName())
                }

            }

            self.reset = function () {
                self.index(-1)
                self.sellerFirstName("")
                self.sellerLastName("")
                self.sellerWorkPhone("")
                self.workPhonExt("")
                self.sellerHomePhone("")
                self.homePhonExt("")
                self.coSellerFirstName("")
                self.coSellerLastName("")
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

        }

        function principalSellers() {
            var self = this;
            self.viewName = "orderModule/_principalSellers.html";
            self.title = "Principals-Sellers";
            self.ModelId = "principalSellers"
            self.index = ko.observable(-1);
            self.List = ko.observableArray([]);


            self.orderTypeId = ko.observable(0);
            self.transactionTypeId = ko.observable("");
            self.customErrorMessage = ko.observable("");

            self.reset = function () {
                self.orderTypeId(0);
                self.transactionTypeId("");
                self.customErrorMessage("");
                self.List([]);
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                var isValid = false;
                // Online Title Order  : 47
                //Online Escrow Order :48
                //Online Title and Escrow Order :17
                if ((self.orderTypeId() == 47 || self.orderTypeId() == 48 || self.orderTypeId() == 17) && (self.transactionTypeId() == "CMSA" || self.transactionTypeId() == "FORE" || self.transactionTypeId() == "MHSA" || self.transactionTypeId() == "RELO" || self.transactionTypeId() == "REO" || self.transactionTypeId() == "SALE" || self.transactionTypeId() == "SHSA" || self.transactionTypeId() == "SUBD")) {
                    if (self.List().length > 0) {
                        isValid = true;
                        return isValid;
                    }
                    else {
                        self.customErrorMessage("** At least one seller is required for this transaction type");
                        return false;
                    }
                }
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create(self.title, self.List(), 'collection', 'views/orderModule/orderPreview/_principalSellers.html'));
            }


        }

       

        function PrincipalBorrower() {
            var self = this;
            self.viewName = "orderModule/_principalBorrower.html"
            self.title = "Principal Borrower"
            self.ModelId = "PrincipalBorrower"
            self.index = ko.observable(-1)
            self.borrowerFirstName = ko.observable("")
             .extend({ required: { message: 'Borrower First Name is required.' } })
            self.borrowerLastName = ko.observable("")
             .extend({ required: { message: 'Borrower Last Name is required.' } })

            self.borrowerFullName = ko.computed(function () {
                return self.borrowerFirstName() + " " + self.borrowerLastName();
            });

            self.workPhoneExt = ko.observable("")
              .extend({ digit: true });

            self.borrowerWorkPhone = ko.observable("")
.extend({
    required: {
        message: "Work Phone is required",
        onlyIf: function () {
            return self.workPhoneExt().length > 0;
        }
    }
})
.extend({ phoneUS: true })


            self.borrowerHomePhone = ko.observable("")
            .extend({ phoneUS: true })

            self.homePhoneExt = ko.observable("")
            self.borrowerAddress = ko.observable("")
            self.borrowerZipCode = ko.observable("")
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
                .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
                .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.borrowerCity = ko.observable(undefined)
            self.borrowerState = ko.observable("")
            self.borrowerEmail = ko.observable("")
             .extend({ email: { message: 'Invalid Email Address.' } })
                .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })
            self.coBorrowerFirstName = ko.observable("")
            self.coBorrowerLastName = ko.observable("")

            self.coBorrowerFullName = ko.computed(function () {
                return self.coBorrowerFirstName() + " " + self.coBorrowerLastName();
            });

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.borrowerFirstName(data.borrowerFirstName())
                    self.borrowerLastName(data.borrowerLastName())
                    self.borrowerWorkPhone(data.borrowerWorkPhone())
                    self.workPhoneExt(data.workPhoneExt())
                    self.borrowerHomePhone(data.borrowerHomePhone())
                    self.homePhoneExt(data.homePhoneExt())
                    self.borrowerAddress(data.borrowerAddress())
                    self.borrowerZipCode(data.borrowerZipCode())
                    self.borrowerCity(data.borrowerCity())
                    self.borrowerState(data.borrowerState())
                    self.borrowerEmail(data.borrowerEmail())
                    self.coBorrowerFirstName(data.coBorrowerFirstName())
                    self.coBorrowerLastName(data.coBorrowerLastName())
                }

            }

            self.reset = function () {
                self.index(-1);
                self.borrowerFirstName('')
                self.borrowerLastName('')
                self.borrowerWorkPhone('')
                self.workPhoneExt('')
                self.borrowerHomePhone('')
                self.homePhoneExt('')
                self.borrowerAddress('')
                self.borrowerZipCode('')
                self.borrowerCity(undefined)
                self.borrowerState('')
                self.borrowerEmail('')
                self.coBorrowerFirstName('')
                self.coBorrowerLastName('')
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }
        }


        function PrincipalBorrowers() {
            //* No more than 9 borrowers can be added to this order.

            var self = this;
            self.viewName = "orderModule/_principalBorrowers.html"
            self.title = "Principal Borrowers"
            self.ModelId = "PrincipalBorrowers"
            self.index = ko.observable(-1)
            self.List = ko.observableArray([]);
            self.orderTypeId = ko.observable(0);
            self.transactionTypeId = ko.observable("");
            self.customErrorMessage = ko.observable("");

            self.reset = function () {
                self.orderTypeId(0);
                self.transactionTypeId("");
                self.customErrorMessage("");
                self.List([]);
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                var isValid = false;
                // Online Title Order  : 47
                if ((self.orderTypeId() == 47 || self.orderTypeId() == 48 || self.orderTypeId() == 17) && (self.transactionTypeId() == "CMLO" || self.transactionTypeId() == "EQUI" || self.transactionTypeId() == "MHRE" || self.transactionTypeId() == "NCLO" || self.transactionTypeId() == "REFI" || self.transactionTypeId() == "RVMG")) {
                    if (self.List().length > 0) {
                        isValid = true;
                        return isValid;
                    }
                    else {
                        self.customErrorMessage("At least one borrower is required for this transaction type");
                        return false;
                    }
                }
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create(self.title, self.List(), 'collection', 'views/orderModule/orderPreview/_principalBorrowers.html'));
            }

        }

        function PrincipalBuyer() {
            var self = this;
            self.viewName = "_principalBuyer.html"
            self.title = "Principals-Buyer"
            self.ModelId = "PrincipalsBuyer"

            self.index = ko.observable(-1)
            self.buyerFirstName = ko.observable("")
             .extend({ required: { message: 'Buyer First Name is required.' } })
            self.buyerLastName = ko.observable("")
             .extend({ required: { message: 'Buyer Last Name is required.' } })

            self.buyerFullName = ko.computed(function () {
                return self.buyerFirstName() + " " + self.buyerLastName();
            });

            self.workPhoneExt = ko.observable("")
            .extend({ digit: true });

            self.buyerWorkPhone = ko.observable("")
            .extend({
                required: {
                    message: "Work Phone is required",
                    onlyIf: function () {
                        return self.workPhoneExt().length > 0;
                    }
                }
            })
            .extend({ phoneUS: true })
            self.buyerHomePhone = ko.observable("")
            .extend({ phoneUS: true })
            self.homePhoneExt = ko.observable("")
            self.buyerAddress = ko.observable("")
            self.buyerZipCode = ko.observable("")
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.buyerCity = ko.observable(undefined)
            self.buyerState = ko.observable("")
            self.buyerEmail = ko.observable("")
                .extend({ email: { message: 'Invalid Email Address.' } })
                .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })

            self.coBuyerFirstName = ko.observable("")
            self.cobuyerLastName = ko.observable("")

            self.coBuyerFullName = ko.computed(function () {
                return self.coBuyerFirstName() + " " + self.cobuyerLastName();
            });

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.buyerFirstName(data.buyerFirstName())
                    self.buyerLastName(data.buyerLastName())
                    self.buyerWorkPhone(data.buyerWorkPhone())
                    self.workPhoneExt(data.workPhoneExt())
                    self.buyerHomePhone(data.buyerHomePhone())
                    self.homePhoneExt(data.homePhoneExt())
                    self.buyerAddress(data.buyerAddress())
                    self.buyerZipCode(data.buyerZipCode())
                    self.buyerCity(data.buyerCity())
                    self.buyerState(data.buyerState())
                    self.buyerEmail(data.buyerEmail())
                    self.coBuyerFirstName(data.coBuyerFirstName())
                    self.cobuyerLastName(data.cobuyerLastName())
                }

            }

            self.reset = function () {
                self.index(-1)
                self.buyerFirstName('')
                self.buyerLastName('')
                self.buyerWorkPhone('')
                self.workPhoneExt('')
                self.buyerHomePhone('')
                self.homePhoneExt('')
                self.buyerAddress('')
                self.buyerZipCode('')
                self.buyerCity(undefined)
                self.buyerState('')
                self.buyerEmail('')
                self.coBuyerFirstName('')
                self.cobuyerLastName('')
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

        }

        function PrincipalBuyers() {
            var self = this;
            self.viewName = "orderModule/_principalBuyers.html"
            self.title = "Principals-Buyers"
            self.ModelId = "PrincipalsBuyers"
            self.index = ko.observable(-1)
            self.List = ko.observableArray([]);


            self.orderTypeId = ko.observable(0);
            self.transactionTypeId = ko.observable("");
            self.customErrorMessage = ko.observable("");

            self.reset = function () {
                self.orderTypeId(0);
                self.transactionTypeId("");
                self.customErrorMessage("");
                self.List([]);
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                var isValid = false;
                // Online Title Order  : 47
                if ((self.orderTypeId() == 47 || self.orderTypeId() == 48 || self.orderTypeId() == 17) && (self.transactionTypeId() == "CMSA" || self.transactionTypeId() == "FORE" || self.transactionTypeId() == "MHSA" || self.transactionTypeId() == "RELO" || self.transactionTypeId() == "REO" || self.transactionTypeId() == "SALE" || self.transactionTypeId() == "SHSA" || self.transactionTypeId() == "SUBD")) {
                    if (self.List().length > 0) {
                        isValid = true;
                        return isValid;
                    }
                    else {
                        self.customErrorMessage("** At least one buyer is required for this transaction type");
                        return false;
                    }
                }
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create(self.title, self.List(), 'collection', 'views/orderModule/orderPreview/_principalBuyers.html'));
            }
        }

        function Lender() {
            var self = this;
            self.viewName = "orderModule/_lender.html"
            self.title = "Lender"
            self.ModelId = "Lender"
            self.index = ko.observable(-1)
            self.isLoading = false
            self.contact = ko.observable("")
            self.isReset = ko.observable(true)
            self.isWesbOrder = ko.observable(false);
            self.isSkippable = false;

            self.firstName = ko.observable("")
             .extend({ required: { message: 'First Name is required.' } })

            self.lastName = ko.observable("")
             .extend({ required: { message: 'Last Name is required.' } })

            self.fullName = ko.computed(function () {
                var firstLast = self.firstName() + " " + self.lastName();
                return firstLast;
            });

            
            self.company = ko.observable("")
             .extend({ required: { message: 'Company is required.' } })

            self.address = ko.observable("")
             .extend({ required: { message: 'Address is required.' } })

            self.zip = ko.observable("")
             .extend({ required: { message: 'Zip is required.' } })
              .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.city = ko.observable(undefined)
             .extend({ required: { message: 'City is required.' } })

            self.state = ko.observable(undefined)
             .extend({
                 required: { message: 'State is required.' }
             })
            self.newFrequentContact = ko.observable(false);
            self.phone = ko.observable("")
             .extend({
                 required: {
                     message: 'Phone is required.'
                 }
                })

            
            self.fullAddress = ko.computed(function () {
                return utils.getAddressString(self.address(), self.zip(), self.city(), self.state());
            });


            //.extend({
            //    phoneUS: {
            //        params: true,
            //        onlyIf: function () {
            //            return self.newFrequentContact() == true;
            //        },
            //        message: 'Please specify a valid phone number'
            //    }
            //});

            self.phoneExt = ko.observable("")
            .extend({ digit: true });

            self.fax = ko.observable("")
            //.extend({
            //    phoneUS: {
            //        params: true,
            //        onlyIf: function () {
            //            return self.newFrequentContact() === true;
            //        },
            //        message: 'Please specify a valid fax number',
            //    }
            //});

            self.faxExt = ko.observable("")
            self.email = ko.observable("")
              .extend({ email: { message: 'Invalid Email Address.' } })
                .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })

            self.addfreqContacts = ko.observable("")
            self.mortgageBroker = ko.observable(undefined)
            self.accountNumber = ko.observable("").extend({
                pattern: {
                    params: '^[^<>{}\/\\~`\[^%?|":=+\\]]+$',
                    message: 'Invalid Account Number.'
                }
            });
            self.loanAmount = ko.observable("")
            .extend({ required: { message: 'Loan Amount is required.' } })
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Loan Amount.'
                }
            });

            
            self.loanType = ko.observable(undefined)
            .extend({ required: { message: 'Loan Type is required.' } })

            self.financeType = ko.observable(undefined)
             .extend({
                 required: {
                     params: true,
                     message: 'Financing Type is required.',
                     onlyIf: function () {
                         return self.isWesbOrder();
                     }
                 }
             });

            
           

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.contact(data.contact())
                    self.firstName(data.firstName())
                    self.lastName(data.lastName())
                    self.company(data.company())
                    self.address(data.address())
                    self.zip(data.zip())
                    self.city(data.city())
                    self.state(data.state())
                    self.phone(data.phone())
                    self.phoneExt(data.phoneExt())
                    self.fax(data.fax())
                    self.faxExt(data.faxExt())
                    self.email(data.email())
                    self.accountNumber(data.accountNumber());
                    self.loanAmount(data.loanAmount());
                    self.loanType(data.loanType());
                    self.addfreqContacts(data.addfreqContacts())
                    self.mortgageBroker(data.mortgageBroker())
                    if (self.isWesbOrder())
                        self.financeType(data.financeType());

                }

            }

            self.reset = function () {
                self.index(-1);

                self.contact("0")

                self.firstName("")
                self.lastName("")
                self.company("")
                self.address("")
                self.zip("")
                self.city(undefined)
                self.state(undefined)
                self.phone("")
                self.phoneExt("")
                self.fax("")
                self.faxExt("")
                self.email("")
                self.addfreqContacts("")
                self.accountNumber("")
                self.loanAmount("")
                self.loanType(undefined)
                self.mortgageBroker(undefined)
                if (self.isWesbOrder())
                    self.financeType(undefined);
                //self.newFrequentContact(false)
                ko.validation.group(self).showAllMessages(false);


            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                console.log("GetModelData Called!.");
            }

        }

        //ko.extenders.required = function (target, overrideMessage) {
        //    //add some sub-observables to our observable
        //    target.hasError = ko.observable();
        //    target.validationMessage = ko.observable();

        //    //define a function to do validation

        //    function validate(newValue) {
        //        if (newValue) {
        //            target.hasError(newValue.length > 0 ? false : true);
        //            target.validationMessage(newValue ? "" : overrideMessage || "This field is required");
        //        }
        //        }




        //    //initial validation
        //    validate(target());

        //    //validate whenever the value changes
        //    target.subscribe(validate);

        //    //return the original observable
        //    return target;
        //};

        
        function Lenders() {
            var self = this;
            self.viewName = "orderModule/_lenders.html";
            self.title = "Lenders";
            self.ModelId = "Lenders"
            self.index = ko.observable(-1);
            self.List = ko.observableArray([]);
            self.orderTypeId = ko.observable(0);
            self.transactionTypeId = ko.observable("");
            self.customErrorMessage = ko.observable("");
            self.MyselfSet = ko.observable(false);
            self.isSkippable = false;


            self.reset = function () {
                self.orderTypeId(0);
                self.transactionTypeId("");
                self.customErrorMessage("");
                self.List([]);
                self.MyselfSet(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.isCustomValid = function () {
                var isValid = false;
                // Online Title Order  : 47
                if ((self.orderTypeId() == 47 || self.orderTypeId() == 48 || self.orderTypeId() == 17) && (self.transactionTypeId() == "AUCT" || self.transactionTypeId() == "CMNC")) {
                    if (self.List().length > 0) {
                        ko.utils.arrayFilter(self.List(), function (item) {
                            if (item.contact() == "1") {
                                isValid = true;
                            }
                        });
                        if (!isValid) {
                            self.customErrorMessage("** Myself must be selected for one Lender");
                        }
                        return isValid;
                    }
                    else {
                        self.customErrorMessage("** Myself must be selected for one Lender");
                        return false;
                    }
                }
                // Escrow/ Title / Escrow Title  
                //if ((self.orderTypeId() == 97 || self.orderTypeId() == 98 || self.orderTypeId() == 99) && (self.transactionTypeId() == "NCLOAN" || self.transactionTypeId() == "CMLOAN" || self.transactionTypeId() == "EQUITY" || self.transactionTypeId() == "MHREFI" || self.transactionTypeId() == "REFI" || self.transactionTypeId() == "RVMTG")) {
                //    if (self.List().length > 0) {
                //        ko.utils.arrayFilter(self.List(), function (item) {
                //            if (item.contact() == "1") {
                //                isValid = true;
                //            }
                //        });
                //        if (!isValid) {
                //            self.customErrorMessage("** Myself must be selected for one Lender");
                //        }
                //        return isValid;
                //    }
                //    else {
                //        self.customErrorMessage("** Myself must be selected for one Lender");
                //        return false;
                //    }
                //}

                // Online Title Order  : 47
                if ((self.orderTypeId() == 47 || self.orderTypeId() == 48 || self.orderTypeId() == 17) && (self.transactionTypeId() == "CMLO" || self.transactionTypeId() == "EQUI" || self.transactionTypeId() == "MHRE" || self.transactionTypeId() == "NCLO" || self.transactionTypeId() == "REFI")) {
                    if (self.List().length > 0) {
                        ko.utils.arrayFilter(self.List(), function (item) {
                            if (item.contact() == "1") {
                                isValid = true;
                            }
                        });
                        if (!isValid) {
                            self.customErrorMessage("** At least one lender is required for this transaction type (** Myself must be selected for one Lender)");
                        }
                        return isValid;
                    }
                    else {
                        self.customErrorMessage("** At least one lender is required for this transaction type (** Myself must be selected for one Lender)");
                        return false;
                    }
                }
                // Online Title Order  : 47
                if ((self.orderTypeId() == 47 || self.orderTypeId() == 48 || self.orderTypeId() == 17) && (self.transactionTypeId() == "FORE" || self.transactionTypeId() == "CMSA" || self.transactionTypeId() == "MHSA" || self.transactionTypeId() == "RELO" || self.transactionTypeId() == "REO" || self.transactionTypeId() == "SALE" || self.transactionTypeId() == "SHSA" || self.transactionTypeId() == "SUBD") && !self.MyselfSet()) {
                    if (self.List().length > 0) {
                        ko.utils.arrayFilter(self.List(), function (item) {
                            if (item.contact() == "1") {
                                isValid = true;
                            }
                        });
                        if (!isValid) {
                            self.customErrorMessage("** One of the following must be entered for this transaction type: Listing Agent, Selling Agent or 1 Lender (** Myself must be selected for one of the following: Listing Agent, Selling Agent or Lender)");
                        }
                        return isValid;
                    }
                    else {
                        self.customErrorMessage("** One of the following must be entered for this transaction type: Listing Agent, Selling Agent or 1 Lender (** Myself must be selected for one of the following: Listing Agent, Selling Agent or Lender)");
                        return false;
                    }
                }

                // OffLine Title / Escrow/ Title & Escrow
                //if ((self.orderTypeId() == 97 || self.orderTypeId() == 98 || self.orderTypeId() == 99) && (self.transactionTypeId() == "AUCT" || self.transactionTypeId() == "BULK" || self.transactionTypeId() == "CMNC" || self.transactionTypeId() == "CMSALE" || self.transactionTypeId() == "CMSALE" || self.transactionTypeId() == "FORE" || self.transactionTypeId() == "MHSALE" || self.transactionTypeId() == "NCLOAN" || self.transactionTypeId() == "NCSALE" || self.transactionTypeId() == "RELO" || self.transactionTypeId() == "REO" || self.transactionTypeId() == "SALE" || self.transactionTypeId() == "SHSALE" || self.transactionTypeId() == "SUBDIV") && !self.MyselfSet()) {
                //    if (self.List().length > 0) {
                //        ko.utils.arrayFilter(self.List(), function (item) {
                //            if (item.contact() == "1") {
                //                isValid = true;
                //            }
                //        });
                //        if (!isValid) {
                //            self.customErrorMessage("** One of the following must be entered for this transaction type: Listing Agent, Selling Agent or 1 Lender (** Myself must be selected for one of the following: Listing Agent, Selling Agent or Lender)");
                //        }
                //        return isValid;
                //    }
                //    else {
                //        self.customErrorMessage("** One of the following must be entered for this transaction type: Listing Agent, Selling Agent or 1 Lender (** Myself must be selected for one of the following: Listing Agent, Selling Agent or Lender)");
                //        return false;
                //    }
                //}

                // Online Title Order  : 47
                if ((self.orderTypeId() == 47 || self.orderTypeId() == 48 || self.orderTypeId() == 17) && (self.transactionTypeId() == "NWCO") && !self.MyselfSet()) {
                    if (self.List().length > 0) {
                        ko.utils.arrayFilter(self.List(), function (item) {
                            if (item.contact() == "1") {
                                isValid = true;
                            }
                        });
                        if (!isValid) {
                            self.customErrorMessage("** Myself must be selected for one of the following: Listing Agent, Selling Agent or Lender");
                        }
                        return isValid;
                    }
                    else {
                        self.customErrorMessage("** Myself must be selected for one of the following: Listing Agent, Selling Agent or Lender");
                        return false;
                    }
                }

                //


                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create(self.title, self.List(), 'collection', 'views/orderModule/orderPreview/_lenders.html'));
            }

        }

        function SingleLender() {
            var self = this;
            self.viewName = "orderModule/_singlelender.html"
            self.title = "Lender"
            self.ModelId = "Lender"
            self.index = ko.observable(-1)
            self.contact = ko.observable("").extend({
                notify: 'always'
            });
            self.isSkippable = false;
            self.isReset = ko.observable(true)

            self.orderTypeId = ko.observable(0);
            self.transactionTypeId = ko.observable("");
            self.customErrorMessage = ko.observable("");
            self.MyselfSet = ko.observable(false);



            self.firstName = ko.observable("")
             .extend({ required: { message: 'First Name is required.' } })

            self.lastName = ko.observable("")
             .extend({ required: { message: 'Last Name is required.' } })

            self.fullName = ko.computed(function () {
                return self.firstName() + " " + self.lastName();
            });

            self.company = ko.observable("")
             .extend({ required: { message: 'Company is required.' } })

            self.address = ko.observable("")
             .extend({ required: { message: 'Address is required.' } })

            self.zip = ko.observable("")
             .extend({ required: { message: 'Zip is required.' } })
              .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.city = ko.observable(undefined)
             .extend({ required: { message: 'City is required.' } })

            self.state = ko.observable(undefined)
             .extend({
                 required: { message: 'State is required.' }
             })

            self.phone = ko.observable("")
             .extend({
                 required: {
                     message: 'Phone is required.'
                 }
             })
             //.extend({
             //    phoneUS: {
             //        params: true,
             //        onlyIf: function () {
             //            return self.newFrequentContact();
             //        }
             //    }
             //});

            self.phoneExt = ko.observable("")
            .extend({ digit: true });

            self.fax = ko.observable("")
           //.extend({
           //    phoneUS: {
           //        params: true,
           //        onlyIf: function () {
           //            return self.newFrequentContact();
           //        },
           //        message: 'Please specify a valid fax number'
           //    }
           //});

            self.faxExt = ko.observable("")
            self.email = ko.observable("")
              .extend({ email: { message: 'Invalid Email Address.' } })
                .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })

            self.addfreqContacts = ko.observable("")
            self.mortgageBroker = ko.observable(false)
            self.accountNumber = ko.observable("").extend({
                pattern: {
                    params: '^[^<>{}\/\\~`\[^%?|":=+\\]]+$',
                    message: 'Invalid Account Number.'
                }
            });
            self.loanAmount = ko.observable("")
            .extend({ required: { message: 'Loan Amount is required.' } })
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Loan Amount.'
                }
            });

            self.loanType = ko.observable(undefined)
                .extend({ required: { message: 'Loan Type is required.' } })

            

            self.newFrequentContact = ko.observable(true)

            self.update = function (data) {
                if (data) {
                    //self.index(data.index())
                    //self.contact(data.contact())
                    self.firstName(data.firstName())
                    self.lastName(data.lastName())
                    self.company(data.company())
                    self.address(data.address())
                    self.zip(data.zip())
                    self.city(data.city())
                    self.state(data.state())
                    self.phone(data.phone())
                    self.phoneExt(data.phoneExt())
                    self.fax(data.fax())
                    self.faxExt(data.faxExt())
                    self.email(data.email())
                    //self.accountNumber(data.accountNumber());
                    //self.loanAmount(data.loanAmount());
                    //self.loanType(data.loanType());
                    //self.addfreqContacts(data.addfreqContacts)

                }

            }

            self.reset = function () {
                //   self.index(-1);

                // self.contact("0")

                self.firstName("")
                self.lastName("")
                self.company("")
                self.address("")
                self.zip("")
                self.city(undefined)
                self.state(undefined)
                self.phone("")
                self.phoneExt("")
                self.fax("")
                self.faxExt("")
                self.email("")
                self.addfreqContacts("")
                self.accountNumber("")
                self.loanAmount("")
                self.loanType(undefined)
                self.mortgageBroker(false)
                self.newFrequentContact(false)
                ko.validation.group(self).showAllMessages(false);


            }

            self.isCustomValid = function () {
                //var isValid = false;
                //if ((self.transactionTypeId() == "RVMG" || self.transactionTypeId() == "RVMTG")) {
                //    if (self.contact() == "1") {
                //        isValid = true;
                //    }

                //    if (!isValid) {
                //        self.customErrorMessage("*Myself must be selected for Lender");
                //    }
                //    return isValid;

                //}
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("First Name", self.firstName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Last Name", self.lastName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Company", self.company()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Address", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip", self.zip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Phone", modelMetaData.getUSPhoneFormatWithExtn(self.phone(), self.phoneExt(), '')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Fax", modelMetaData.getUSPhoneFormatWithExtn(self.fax(), '', 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Email", self.email()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Mortgage Broker", utils.reportBooleanValue(self.mortgageBroker())));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Loan Type", self.loanType()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Account Number", self.accountNumber()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Loan Amount", self.loanAmount()));
            }

        }

        function OutsideTitle() {
            var self = this;
            self.viewName = "orderModule/_outsideTitle.html"
            self.title = "Out Side Title"
            self.ModelId = "OutsideTitle"
            self.index = ko.observable(-1)
            self.contact = ko.observable("")
            self.firstName = ko.observable("")
             .extend({ required: { message: 'First Name is required.' } })
            self.lastName = ko.observable("")
             .extend({ required: { message: 'Last Name is required.' } })
            self.company = ko.observable("")
             .extend({ required: { message: 'Company is required.' } })
            self.address = ko.observable("")
             .extend({ required: { message: 'Address is required.' } })
            self.zip = ko.observable("")
             .extend({ required: { message: 'Zip is required.' } })
              .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.city = ko.observable(undefined)
             .extend({ required: { message: 'City is required.' } })
            self.state = ko.observable(undefined)
             .extend({ required: { message: 'State is required.' } })
            self.phone = ko.observable("")
             .extend({ required: { message: 'Phone is required.' } })
             .extend({
                 phoneUS: {
                     params: true,
                     onlyIf: function () {
                         return self.newFrequentContact();
                     },
                     message: 'Please specify a valid phone number'
                 }
             });
            self.phoneExt = ko.observable("")
            .extend({ digit: true });

            self.fax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    onlyIf: function () {
                        return self.newFrequentContact();
                    },
                    message: 'Please specify a valid fax number'
                }
            });
            self.faxExt = ko.observable("")
            self.email = ko.observable("")
              .extend({ email: { message: 'Invalid Email Address.' } })
                .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })
            self.addfreqContacts = ko.observable("")
            self.newFrequentContact = ko.observable(true)


            self.update = function (data) {
                if (data) {
                    self.contact(data.contact)
                    self.firstName(data.firstName)
                    self.lastName(data.lastName)
                    self.company(data.company)
                    self.address(data.address)
                    self.zip(data.zip)
                    self.city(data.city)
                    self.state(data.state)
                    self.phone(data.phone)
                    self.phoneExt(data.phoneExt)
                    self.fax(data.fax)
                    self.faxExt(data.faxExt)
                    self.email(data.email)
                    self.addfreqContacts(data.addfreqContacts)
                }

            }

            self.reset = function () {

                self.contact("0")
                self.firstName('')
                self.lastName('')
                self.company('')
                self.address('')
                self.zip('')
                self.city(undefined)
                self.state(undefined)
                self.phone('')
                self.phoneExt('')
                self.fax('')
                self.faxExt('')
                self.email('')
                self.addfreqContacts('')
                self.newFrequentContact(false)
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("First Name", self.firstName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Last Name", self.lastName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Company", self.company()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Address", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip", self.zip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Phone", modelMetaData.getUSPhoneFormatWithExtn(self.phone(), self.phoneExt(), '')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Fax", modelMetaData.getUSPhoneFormatWithExtn(self.fax(), '', '')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Email", self.email()));
            }

        }


        function IndependentEscrow() {
            var self = this;
            self.viewName = "orderModule/_independentEscrow.html"
            self.title = "Independent Escrow"
            self.ModelId = "IndependentEscrow"
            self.index = ko.observable(-1)
            self.contact = ko.observable("")
            self.firstName = ko.observable("")
             .extend({
                 required: {
                     message: 'First Name is required.'
                 }
             })
            self.lastName = ko.observable("")
                 .extend({
                     required: {
                         message: 'Last Name is required.'
                     }
                 })
            self.company = ko.observable("")
             .extend({
                 required: {
                     message: 'Company is required.'
                 }
             })
            self.address = ko.observable("")
             .extend({
                 required: { message: 'Address is required.' }
             })
            self.zip = ko.observable("")
             .extend({ required: { message: 'Zip is required.' } })
              .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.city = ko.observable(undefined)
             .extend({
                 required: {
                     message: 'City is required.'
                 }
             })
            self.state = ko.observable(undefined)
          .extend({
              required: {
                  message: 'State is required.'
              }
          })
            self.phone = ko.observable("")
         .extend({
             required: {
                 message: 'Phone is required.'
             }
         })
       .extend({
           phoneUS: {
               params: true,
               onlyIf: function () {
                   return self.newFrequentContact();
               },
               message: 'Please specify a valid phone number'
           }
       });

            self.phoneExt = ko.observable("")
            .extend({ digit: true });

            self.fax = ko.observable("")
            .extend({
                phoneUS: {
                    params: true,
                    onlyIf: function () {
                        return self.newFrequentContact();
                    },
                    message: 'Please specify a valid fax number'
                }
            });

            self.faxExt = ko.observable("")
            self.email = ko.observable("")
              .extend({
                  email: {
                      message: 'Invalid Email Address.'
                  }
              })
                .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })
            self.addfreqContacts = ko.observable("")
            self.newFrequentContact = ko.observable(true)

            self.update = function (data) {
                if (data) {
                    self.contact(data.contact)
                    self.firstName(data.firstName)
                    self.lastName(data.lastName)
                    self.company(data.company)
                    self.address(data.address)
                    self.zip(data.zip)
                    self.city(data.city)
                    self.state(data.state)
                    self.phone(data.phone)
                    self.phoneExt(data.phoneExt)
                    self.fax(data.fax)
                    self.faxExt(data.faxExt)
                    self.email(data.email)
                    self.addfreqContacts(data.addfreqContacts)
                }

            }

            self.reset = function () {

                self.contact("0")
                self.firstName('')
                self.lastName('')
                self.company('')
                self.address('')
                self.zip('')
                self.city(undefined)
                self.state(undefined)
                self.phone('')
                self.phoneExt('')
                self.fax('')
                self.faxExt('')
                self.email('')
                self.addfreqContacts('')
                self.newFrequentContact(false)
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

        }

       

       

        function SpecialInstructions() {
            var self = this;
            self.viewName = "orderModule/_specialInstructions.html"
            self.title = "Special Instructions"
            self.ModelId = "SpecialInstructions"
            self.index = ko.observable(-1)
            self.instructions = ko.observable("")



            self.update = function (data) {
                if (data) {
                    self.instructions(data.instructions())

                }

            }

            self.reset = function () {

                self.instructions('')
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }


            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("For This Order", self.instructions()));
            }

        }

        function SubmitOrder() {
            var self = this;
            self.viewName = "orderModule/_submitOrder.html"
            self.title = "Submit Order"
            self.index = ko.observable(-1)
            self.ModelId = "SubmitOrder"

            self.update = function (data) {
                if (data) {
                }
            }

            self.reset = function () {
                //self.index(-1)
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

        }

        function listingAgent() {
            var self = this;
            self.viewName = "orderModule/_listingAgent.html";
            self.title = "Listing Agent";
            self.ModelId = "listingAgent";
            self.MyselfSet = ko.observable(false);
            self.IsWesbOrder = ko.observable(false);
            self.isSkippable = false;

            self.index = ko.observable(-1);
            self.contact = ko.observable("")
            self.firstName = ko.observable("")
             .extend({ required: { message: 'First Name is required.' } })
            self.lastName = ko.observable("")
             .extend({ required: { message: 'Last Name is required.' } })
            self.company = ko.observable("")
             .extend({ required: { message: 'Company is required.' } })
            self.address = ko.observable("")
             .extend({ required: { message: 'Address is required.' } })
            self.zip = ko.observable("")
             .extend({ required: { message: 'Zip is required.' } })
              .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.city = ko.observable(undefined)
             .extend({ required: { message: 'City is required.' } })
            self.state = ko.observable(undefined)
             .extend({ required: { message: 'State is required.' } })
            self.newFrequentContact = ko.observable(true)
            self.phone = ko.observable("")
             .extend({ required: { message: 'Phone is required.' } })
            //.extend({
            //    phoneUS: {
            //        params: true,
            //        onlyIf: function () {
            //            return self.newFrequentContact();
            //        }
            //    }
            //});


            self.phoneExt = ko.observable("")
            .extend({ digit: true });

            self.fax = ko.observable("")
            //.extend({
            //    phoneUS: {
            //        params: true,
            //        onlyIf: function () {
            //            return self.newFrequentContact();
            //        },
            //        message: 'Please specify a valid fax number'
            //    }
            //});


            self.faxExt = ko.observable("")

            self.email = ko.observable("")
            .extend({
                required: {
                    message: "Email Address is required",
                    onlyIf: function () {
                        return self.IsWesbOrder();
                    }
                }
            })
             .extend({ email: { message: 'Invalid Email Address.' } })
             .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })

            self.addfreqContacts = ko.observable("")
            
            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.contact(data.contact())
                    self.firstName(data.firstName())
                    self.lastName(data.lastName())
                    self.company(data.company())
                    self.address(data.address())
                    self.zip(data.zip())
                    self.city(data.city())
                    self.state(data.state())
                    self.phone(data.phone())
                    self.phoneExt(data.phoneExt())
                    self.fax(data.fax())
                    self.faxExt(data.faxExt())
                    self.email(data.email())
                    self.addfreqContacts(data.addfreqContacts())
                }

            }

            self.reset = function () {
                //    self.index(-1)
                self.contact("0")
                self.firstName('')
                self.lastName('')
                self.company('')
                self.address('')
                self.zip('')
                self.city(undefined)
                self.state(undefined)
                self.phone('')
                self.phoneExt('')
                self.fax('')
                self.faxExt('')
                self.email('')
                self.addfreqContacts('')
                self.newFrequentContact(false)
                ko.validation.group(self).showAllMessages(false);
                self.MyselfSet(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("First Name", self.firstName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Last Name", self.lastName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Company", self.company()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Address", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip", self.zip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                if (self.phone() && self.phone().length > 10)
                    self.phone(utils.unmaskPhoneNumber(self.phone));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Phone", modelMetaData.getUSPhoneFormatWithExtn(self.phone(), self.phoneExt(), 'x')));
                if (self.fax() && self.fax().length > 10)
                    self.fax(utils.unmaskPhoneNumber(self.fax));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Fax", modelMetaData.getUSPhoneFormatWithExtn(self.fax(), '', 'x')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Email", self.email()));
            }

        }
        function sellingAgent() {
            var self = this;
            self.viewName = "orderModule/_sellingAgent.html"
            self.title = "Selling Agent"
            self.ModelId = "sellingAgent"
            self.MyselfSet = ko.observable(false);
            self.IsWesbOrder = ko.observable(false);
            self.isSkippable = false;
            self.index = ko.observable(-1)
            self.contact = ko.observable("")
            self.firstName = ko.observable("")
             .extend({ required: { message: 'First Name is required.' } })
            self.lastName = ko.observable("")
             .extend({ required: { message: 'Last Name is required.' } })
            self.company = ko.observable("")
             .extend({ required: { message: 'Company is required.' } })
            self.address = ko.observable("")
             .extend({ required: { message: 'Address is required.' } })
            self.zip = ko.observable("")
             .extend({ required: { message: 'Zip is required.' } })
              .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
               .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
               .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })
            self.city = ko.observable(undefined)
             .extend({ required: { message: 'City is required.' } })
            self.state = ko.observable(undefined)
             .extend({ required: { message: 'State is required.' } })
            self.phone = ko.observable("")
             .extend({ required: { message: 'Phone is required.' } })
             //.extend({
             //    phoneUS: {
             //        params: true,
             //        onlyIf: function () {
             //            return self.newFrequentContact();
             //        }
             //    }
             //});
            self.phoneExt = ko.observable("")
            .extend({ digit: true });

            self.fax = ko.observable("")
           //.extend({
           //    phoneUS: {
           //        params: true,
           //        onlyIf: function () {
           //            return self.newFrequentContact();
           //        },
           //        message: 'Please specify a valid fax number'
           //    }
           //});
            self.faxExt = ko.observable("")

            self.email = ko.observable("")
                .extend({
                    required: {
                        message: "Email Address is required",
                        onlyIf: function () {
                            return self.IsWesbOrder();
                        }
                    }
                })
             .extend({ email: { message: 'Invalid Email Address.' } })
             .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })

            self.addfreqContacts = ko.observable("")
            self.newFrequentContact = ko.observable(true)

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.contact(data.contact)
                    self.firstName(data.firstName)
                    self.lastName(data.lastName)
                    self.company(data.company)
                    self.address(data.address)
                    self.zip(data.zip)
                    self.city(data.city)
                    self.state(data.state)
                    self.phone(data.phone)
                    self.phoneExt(data.phoneExt)
                    self.fax(data.fax)
                    self.faxExt(data.faxExt)
                    self.email(data.email)
                    self.addfreqContacts(data.addfreqContacts)
                }

            }

            self.reset = function () {
                //        self.index(-1)
                self.contact("0")
                self.firstName('')
                self.lastName('')
                self.company('')
                self.address('')
                self.zip('')
                self.city(undefined)
                self.state(undefined)
                self.phone('')
                self.phoneExt('')
                self.fax('')
                self.faxExt('')
                self.email('')
                self.addfreqContacts('')
                self.newFrequentContact(false)
                ko.validation.group(self).showAllMessages(false);
                self.MyselfSet(false);
            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("First Name", self.firstName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Last Name", self.lastName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Company", self.company()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Address", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Zip", self.zip()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("City", self.city()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("State", self.state()));
                if (self.phone() && self.phone().length > 10)
                    self.phone(utils.unmaskPhoneNumber(self.phone));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Phone", modelMetaData.getUSPhoneFormatWithExtn(self.phone(), self.phoneExt(), '')));
                if (self.fax() && self.fax().length > 10)
                    self.fax(utils.unmaskPhoneNumber(self.fax));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Fax", modelMetaData.getUSPhoneFormatWithExtn(self.fax(), '', '')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Email", self.email()));
            }

        }

        function WesbPrincipalSeller() {
            var self = this;
            self.viewName = "orderModule/_WesbPrincipalSeller.html"
            self.title = "Principals-Seller"
            self.isLoading = false
            self.index = ko.observable(-1)
            self.ModelId = "wesbprincipalseller"
            self.IsWesbOrder = ko.observable(false);
            self.isSkippable = false;

            self.sellerFirstName = ko.observable("")
             .extend({ required: { message: 'Seller First Name is required.' } })
            self.sellerLastName = ko.observable("")
             .extend({ required: { message: 'Seller Last Name is required.' } })

            self.sellerFullName = ko.computed(function () {
                return self.sellerFirstName() + " " + self.sellerLastName();
            });


            self.sellerHomePhone = ko.observable("")
            .extend({ phoneUS: true })

            self.cellPhone = ko.observable("")
            .extend({ phoneUS: true })

            self.email = ko.observable("")
            .extend({ email: { message: 'Invalid Email Address.' } })
            .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })


            self.address = ko.observable("")

            self.zipIsEmpty = ko.observable("");
            self.zip = ko.observable("")
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
            .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
            .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.city = ko.observable(undefined)

            self.state = ko.observable(undefined)

            self.fullAddress = ko.computed(function () {
                return utils.getAddressString(self.address(), self.zip(), self.city(), self.state());
            });
           
            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.sellerFirstName(data.sellerFirstName())
                    self.sellerLastName(data.sellerLastName())
                    self.cellPhone(data.cellPhone())
                    self.email(data.email())
                    self.sellerHomePhone(data.sellerHomePhone())
                    self.address(data.address())
                    self.zip(data.zip())
                    self.city(data.city())
                    self.state(data.state())
                    if (data.zip() == false)
                        self.zipIsEmpty(new Date().getTime());
                }

            }

            self.reset = function () {
                self.index(-1)
                self.sellerFirstName("")
                self.sellerLastName("")
                self.cellPhone("")
                self.email("")
                self.sellerHomePhone("")
                self.address("")
                self.zip("")
                self.zipIsEmpty("")
                self.city(undefined)
                self.state(undefined)
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }



        }

        function WesbprincipalSellers() {
            var self = this;
            self.viewName = "orderModule/_WesbPrincipalSellers.html";
            self.title = "Principals-Sellers";
            self.ModelId = "wesbprincipalSellers"
            self.index = ko.observable(-1);
            self.List = ko.observableArray([]);
            self.isSkippable = false;

            self.orderTypeId = ko.observable(0);
            self.transactionTypeId = ko.observable("");
            self.customErrorMessage = ko.observable("");

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create(self.title, self.List(), 'collection', 'views/orderModule/orderPreview/_wesbPrincipalSellers.html'));
            }

            self.reset = function () {
                self.orderTypeId(0);
                self.transactionTypeId("");
                self.customErrorMessage("");
                self.List([]);
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                var isValid = false;
                // Online Title Order  : 47
                //Online Escrow Order :48
                //Online Title and Escrow Order :17
                //if ((self.orderTypeId() == 99 || self.orderTypeId() == 98 || self.orderTypeId() == 97)) {
                //    if (self.List().length > 0) {
                //        isValid = true;
                //        return isValid;
                //    }
                //    else {
                //        self.customErrorMessage("* You have no Sellers associated with this order.");
                //        return false;
                //    }
                //}
                return ko.validation.group(self).length == 0;
            }
            

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create(self.title, self.List(), 'collection', 'views/orderModule/orderPreview/_wesbPrincipalSellers.html'));
            }



        }

        function WesbPrincipalBuyer() {
            var self = this;
            self.viewName = "orderModule/_WesbPrincipalBuyer.html"
            self.title = "Principals-Buyer"
            self.index = ko.observable(-1)
            self.isLoading = false
            self.ModelId = "wesbprincipalbuyer"
            self.IsWesbOrder = ko.observable(false);
            self.isSkippable = false;

            self.buyerFirstName = ko.observable("")
             .extend({ required: { message: 'Buyer First Name is required.' } })
            self.buyerLastName = ko.observable("")
             .extend({ required: { message: 'Buyer Last Name is required.' } })

            self.buyerFullName = ko.computed(function () {
                return self.buyerFirstName() + " " + self.buyerLastName();
            });


            self.buyerHomePhone = ko.observable("")
            .extend({ phoneUS: true })

            self.cellPhone = ko.observable("")
            .extend({ phoneUS: true })

            self.email = ko.observable("")
            .extend({ email: { message: 'Invalid Email Address.' } })
            .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })


            self.address = ko.observable("")

            self.zipIsEmpty = ko.observable("");
            self.zip = ko.observable("")
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
            .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
            .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.city = ko.observable(undefined)

            self.state = ko.observable(undefined)

            self.fullAddress = ko.computed(function () {
                return utils.getAddressString(self.address(), self.zip(), self.city(), self.state());
            });

            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.buyerFirstName(data.buyerFirstName())
                    self.buyerLastName(data.buyerLastName())
                    self.cellPhone(data.cellPhone())
                    self.email(data.email())
                    self.buyerHomePhone(data.buyerHomePhone())
                    self.address(data.address())
                    self.zip(data.zip())
                    self.city(data.city())
                    self.state(data.state())
                    if (data.zip() == false)
                        self.zipIsEmpty(new Date().getTime());
                }

            }

            self.reset = function () {
                self.index(-1)
                self.buyerFirstName("")
                self.buyerLastName("")
                self.cellPhone("")
                self.email("")
                self.buyerHomePhone("")
                self.address("")
                self.zip("")
                self.zipIsEmpty("")
                self.city(undefined)
                self.state(undefined)
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

        }

        function WesbprincipalBuyers() {
            var self = this;
            self.viewName = "orderModule/_WesbPrincipalBuyers.html";
            self.title = "Principals-Buyers";
            self.ModelId = "wesbprincipalbuyers"
            self.index = ko.observable(-1);
            self.List = ko.observableArray([]);
            self.isSkippable = false;

            self.orderTypeId = ko.observable(0);
            self.transactionTypeId = ko.observable("");
            self.customErrorMessage = ko.observable("");
            
            

            self.reset = function () {
                self.orderTypeId(0);
                self.transactionTypeId("");
                self.customErrorMessage("");
                self.List([]);
                ko.validation.group(self).showAllMessages(false);
            }

            self.isCustomValid = function () {
                var isValid = false;
                // Online Title Order  : 47
                //Online Escrow Order :48
                //Online Title and Escrow Order :17
                //if ((self.orderTypeId() == 99 || self.orderTypeId() == 98 || self.orderTypeId() == 97)) {
                //    if (self.List().length > 0) {
                //        isValid = true;
                //        return isValid;
                //    }
                //    else {
                //        self.customErrorMessage("* You have no Buyers associated with this order.");
                //        return false;
                //    }
                //}
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create(self.title, self.List(), 'collection', 'views/orderModule/orderPreview/_wesbPrincipalBuyers.html'));
            }

        }


        function WesbBorrower() {
            var self = this;
            self.viewName = "orderModule/_WesbBorrower.html"
            self.title = "Borrower"
            self.index = ko.observable(-1)
            self.isLoading = false
            self.ModelId = "wesbborrower"
            self.IsWesbOrder = ko.observable(false);
            self.isSkippable = false;

            self.borrowerFirstName = ko.observable("")
             .extend({ required: { message: 'Borrower First Name is required.' } })
            self.borrowerLastName = ko.observable("")
             .extend({ required: { message: 'Borrower Last Name is required.' } })

            self.borrowerFullName = ko.computed(function () {
                return self.borrowerFirstName() + " " + self.borrowerLastName();
            });


            self.borrowerHomePhone = ko.observable("")
            .extend({ phoneUS: true })

            self.cellPhone = ko.observable("")
            .extend({ phoneUS: true })

            self.email = ko.observable("")
            .extend({ email: { message: 'Invalid Email Address.' } })
            .extend({ maxLength: { params: 75, message: 'Invalid Email Address.' } })


            self.address = ko.observable("")

            self.zipIsEmpty = ko.observable("");
            self.zip = ko.observable("")
            .extend({ maxLength: { params: 5, message: 'Only 5 digit zip code is allowed.' } })
            .extend({ minLength: { params: 5, message: '5 digit zip code is allowed.' } })
            .extend({ pattern: '^[0-9]', message: 'Only 5 digit zip code is allowed.' })

            self.city = ko.observable(undefined)

            self.state = ko.observable(undefined)

            self.fullAddress = ko.computed(function () {
                return utils.getAddressString(self.address(), self.zip(), self.city(), self.state());
            });




            self.update = function (data) {
                if (data) {
                    self.index(data.index())
                    self.borrowerFirstName(data.borrowerFirstName())
                    self.borrowerLastName(data.borrowerLastName())
                    self.cellPhone(data.cellPhone())
                    self.email(data.email())
                    self.borrowerHomePhone(data.borrowerHomePhone())
                    self.address(data.address())
                    self.zip(data.zip())
                    self.city(data.city())
                    self.state(data.state())
                    if (data.zip() == false)
                        self.zipIsEmpty(new Date().getTime());
                }

            }

            self.reset = function () {
                self.index(-1)
                self.borrowerFirstName("")
                self.borrowerLastName("")
                self.cellPhone("")
                self.email("")
                self.borrowerHomePhone("")
                self.address("")
                self.zip("")
                self.zipIsEmpty("")
                self.city(undefined)
                self.state(undefined)
                ko.validation.group(self).showAllMessages(false);

            }

            self.isCustomValid = function () {
                return ko.validation.group(self).length == 0;
            }

        }

        function WesbBorrowers() {
            var self = this;
            self.viewName = "orderModule/_WesbBorrowers.html";
            self.title = "Borrowers";
            self.ModelId = "wesbborrowers"
            self.index = ko.observable(-1);
            self.List = ko.observableArray([]);
            self.isSkippable = false;

            self.orderTypeId = ko.observable(0);
            self.transactionTypeId = ko.observable("");
            self.customErrorMessage = ko.observable("");

            self.reset = function () {
                self.orderTypeId(0);
                self.transactionTypeId("");
                self.customErrorMessage("");
                self.List([]);
                ko.validation.group(self).showAllMessages(false);
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create(self.title, self.List(), 'collection', 'views/orderModule/orderPreview/_wesbBorrowers.html'));
            }

            self.isCustomValid = function () {
                var isValid = false;
                // Online Title Order  : 47
                //Online Escrow Order :48
                //Online Title and Escrow Order :17
                //if ((self.orderTypeId() == 99 || self.orderTypeId() == 98 || self.orderTypeId() == 97)) {
                //    if (self.List().length > 0) {
                //        isValid = true;
                //        return isValid;
                //    }
                //    else {
                //        self.customErrorMessage("* You have no Borrowers associated with this order.");
                //        return false;
                //    }
                //}
                return ko.validation.group(self).length == 0;
            }


        }

        function getAddressString(Address, Zip, City, State) {
            var strAddress = '';
            if (Address && Address.length > 0)
                strAddress = Address + ' ';

            if (City && City.length > 0)
                strAddress = strAddress + City + ', ';

            if (State && State.length > 0)
                strAddress = strAddress + State + ' ';

            if (Zip && Zip.length > 0)
                strAddress = strAddress + Zip + ' ';

            return strAddress;
        }


        var vm = {

            orderInformation: orderInformation,
            SubjectProperty: SubjectProperty,
            OrderRequester: OrderRequester,
            PrincipalSeller: PrincipalSeller,
            principalSellers: principalSellers,
            PrincipalBorrower: PrincipalBorrower,
            PrincipalBorrowers: PrincipalBorrowers,
            PrincipalBuyer: PrincipalBuyer,
            PrincipalBuyers: PrincipalBuyers,
            listingAgent: listingAgent,
            sellingAgent: sellingAgent,
            Lender: Lender,
            SingleLender: SingleLender,
            Lenders: Lenders,
            OutsideTitle: OutsideTitle,
            SpecialInstructions: SpecialInstructions,
            SubmitOrder: SubmitOrder,
            IndependentEscrow: IndependentEscrow,
            WesbPrincipalSeller: WesbPrincipalSeller,
            WesbprincipalSellers: WesbprincipalSellers,
            WesbPrincipalBuyer: WesbPrincipalBuyer,
            WesbprincipalBuyers: WesbprincipalBuyers,
            WesbBorrower: WesbBorrower,
            WesbBorrowers: WesbBorrowers,
            getAddressString: getAddressString
            
            //CustomerServices: CustomerServices
        };

        return vm;
    }
);

