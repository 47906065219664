define('nameValue',["knockout"],
    function (ko) {
        function NameValue() {
            var self = this;

            self.Name = ko.observable("");
            self.Value = ko.observable(undefined);
            self.Index = ko.observable(0);
            self.ProfileURL = ko.observable("");
            self.CheckBoxValue = ko.observable(false);
            self.Language = ko.observable("")
            self.LanguageCode = ko.observable("")
            self.ReportType = ko.observable("")
        }
        return NameValue;
    });
