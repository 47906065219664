define('viewmodels/userModule/oktaLogin',['plugins/router', 'durandal/app', 'knockout', 'services/logger', 'global/session', 'header'],
    function (router, app, ko, logger, session, header) {


        var vm = {
            activate: activate,
            //bindingComplete: configOKTA,
            compositionComplete: configOKTA

        };

        return vm;



        function activate() {
            
            console.log("okta login");
        }

        //"BaseUrl": "https://inhere.oktapreview.com/",
        //    "Logo": "Content/images/inHereLogo.png",
        //        "RedirectUri": "https://local.premier.ctic.com/",
        //            "ClientId": "0oa1f55pq7aDRsJGm0h8",
        //                "Issuer": "https://inhere.oktapreview.com/oauth2/aus1wpcyw6uUCSPMn0h8",
        //                    "Scope": "openid profile groupsGPS",
        //                        "UseOktaMigrationWidget": true


        function configOKTA() {
            var oktaConfig = {
                //issuer: "https://trial-3820612.okta.com/oauth2/default",
                baseUrl: 'https://inhere.oktapreview.com',
                redirectUri: 'https://local.premier.ctic.com',
                clientId: "0oa1f55pq7aDRsJGm0h8",
                Scope: "openid profile groupsGPS",
                UseOktaMigrationWidget: true,
            //    authParams: {
            //        issuer: 'https://inhere.oktapreview.com/oauth2/aus1wpcyw6uUCSPMn0h8'
            //    }
            }

            const oktaSignIn = new OktaSignIn(oktaConfig);
            //showSignInToGetTokens
            oktaSignIn.renderEl({
                el: '#okta-login-container'
            }).then(function (tokens) {
                oktaSignIn.authClient.tokenManager.setTokens(tokens);
                oktaSignIn.remove();

                const idToken = tokens.idToken;

            }).catch(function (err) {
                console.error(err);
            });

            setTimeout(function () {
                var loginContainer = document.getElementById('okta-sign-in');
                if (document.getElementById('okta-sign-in').style.display == 'none') {
                    console.log('retry widget');
                    oktaSignIn.renderEl({
                        el: '#okta-login-container'
                    }).then(function (tokens) {
                        oktaSignIn.authClient.tokenManager.setTokens(tokens);
                        oktaSignIn.remove();

                        const idToken = tokens.idToken;

                    }).catch(function (err) {
                        console.error(err);
                    });
                }
            },2000);



          //  var oktaSignIn = new OktaSignIn({
          //      baseUrl: 'https://inhere.oktapreview.com',
          //      clientId: '0oa1f55pq7aDRsJGm0h8',
          //      redirectUri: 'https://local.premier.ctic.com',
          //      UseOktaMigrationWidget: true,
          //      authParams: {
          //          issuer: 'https://inhere.oktapreview.com/oauth2/aus1wpcyw6uUCSPMn0h8',
          //          responseType: ['token', 'id_token'],
          //          display: 'page'
          //      }
          //  });

          //  oktaSignIn.renderEl(
          //      { el: '#okta-login-container' },
          //      function success(res) {
          //          if (res.status === 'SUCCESS') {
          //              console.log('User successfully authenticated');
          //              // Handle successful authentication here
          //              res.session.setCookieAndRedirect('http://localhost:8080/home');
          //}
          //      },
          //      function error(err) {
          //          console.error(err);
          //      }
          //  );



        }

        function cleanUP() {
            return true;
        }



        function AfterLoad() {

        }

        function goBack() {
            router.navigateBack();
        }

        function checkLLAgreement() {

        }

        function checkLLAgreementWithREiSourceAccount() {

        }



    });
