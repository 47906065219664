define('viewmodels/administration/featureUpdatesAdmin',['plugins/router', 'knockout', 'durandal/app', 'plugins/http', 'global/session', 'config', 'featureUpdate',
        'userRoleModel', 'alertNotification', 'Q', 'koPaging'],
    function (router, ko, app, https, session, config, model, roleModel, alert, Q, koPaging) {

        var featureUpdateModel = new model.featureUpdate();
        var featureUpdatesList = ko.observableArray([]);
        featureUpdatesList.extend({
            paged: {
                pageSize: 6,
                pageGenerator: 'default'
            }
        });
        var userRoleModel = new roleModel.userRole();
        var userRolesList = ko.observableArray([]);
        var brandsList = ko.observable(undefined);
        var operationsList = ko.observableArray([]);
        var isEdit = ko.observable(false);
        var isDelete = ko.observable(false);
        var isDataLoad = ko.observable(false);
        var videoForUpload = ko.observable("");
        var docForUpload = ko.observable("");
        var featureUpdatesPath = "\\Documents\\FeatureUpdates\\";


        var _deletingFeatureUpdateObject = new model.featureUpdate();

        featureUpdateModel.BrandId.subscribe(function (BrandId) {
            if (!isDataLoad()) {
                operationsList([]);
                if (BrandId != null && BrandId != "") {
                    getOperationsList(BrandId);
                } else {
                    getAllOperationsList();
                }
            }

        });

        function compositionComplete() {
            session.validateInput();
        }

        // Reveal the bindable properties and functions
        var vm = {
            activate: activate,
            goBack: goBack,
            title: 'Feature Updates',
            //checkForLLA: checkForLLA,
            canDeactivate: canDeactivate,
            canActivate: canActivate,
            featureUpdateModel: featureUpdateModel,
            featureUpdatesList: featureUpdatesList,
            brandsList: brandsList,
            operationsList: operationsList,
            saveObject: saveObject,
            deleteObject: deleteObject,
            deletObjectConfirmed: deletObjectConfirmed,
            updateObject: updateObject,
            addNewObject: addNewObject,
            editObject: editObject,
            closeDialog: closeDialog,
            videoForUpload: videoForUpload,
            videoForUploadChanged: videoForUploadChanged,
            docForUpload: docForUpload,
            docForUploadChanged: docForUploadChanged,
            isEdit: isEdit,
            router: router,
            currentVideo: ko.observable(""),
            currentDocument: ko.observable(""),
            controlsReset: controlsReset,
            compositionComplete: compositionComplete
        };
        vm.errors = ko.validation.group([vm]);
        return vm;



        function videoForUploadChanged(obj, evnt) {
            if (evnt.currentTarget.files != undefined && evnt.currentTarget.files != null && evnt.currentTarget.files.length > 0) {
                vm.videoForUpload = evnt.currentTarget.files[0];
                featureUpdateModel.VideoFileUrl(featureUpdatesPath + vm.videoForUpload.name);
                console.log(vm.videoForUpload);
            }
        }

        function docForUploadChanged(obj, evnt) {
            if (evnt.currentTarget.files != undefined && evnt.currentTarget.files != null && evnt.currentTarget.files.length > 0) {
                vm.docForUpload = evnt.currentTarget.files[0];
                featureUpdateModel.DocumentFileUrl(featureUpdatesPath + vm.docForUpload.name);
                console.log(vm.docForUpload);
            }
        }

        function canActivate() {
            getAllOperationsList();
            return true;
        }

        function activate() {
            session.showTopSearchControl(true);
            featureUpdatesList([]);
            getBrandsList();
            getUserRoles();
            getFeatureUpdates();
            //getAllOperationsList();
            isDataLoad(true);
        }


        function getBrandsList() {
            if (brandsList() != undefined)
                return;
            var url = config.getAllBrandsUrl;
            return https.get(url).done(function (data) {
                if (data != null) {
                    brandsList(data);
                }
            });
        }

        function getOperationsList(brandId) {
            operationsList([]);
            var url = config.getBrandOperationsUrl;
            return https.get(url, { brandId: brandId }).done(function (data) {
                if (data != null) {
                    operationsList(data);
                }
            });
        }

        function getAllOperationsList() {
            operationsList([]);
            if (session.OperationsList() != undefined) {
                operationsList(session.OperationsList());
                return;
            }
            var url = config.getAllOperationsUrl;
            return https.get(url).done(function (data) {
                if (data != null) {
                    operationsList(data);
                    session.OperationsList(data);
                }
            });
        }

        function getUserRoles() {
            userRolesList([]);
            var url = config.getUserOperationRoleNamesUrl;
            userRoleModel.reset();
            userRolesList([]);
            return https.get(url, { UserId: session.userId, OperationId: session.defaultOpertionId() }).done(function (data) {
                if (data != null) {
                    for (var i = 0; i < data.length; i++) {
                        var role = new roleModel.userRole();
                        role.userId(data[i].userId);
                        role.operationId(data[i].operationId);
                        role.roleId(data[i].roleId);
                        role.roleName(data[i].roleName);
                        userRolesList.push(role);
                    }
                }
            });
        }


        function getFeatureUpdates() {
            isDataLoad(true);
            featureUpdatesList([]);
            console.log('getAllFeatureUpdates called');
            var url = config.getAllFeatureUpdatesUrl;
            featureUpdateModel.reset();
            return https.get(url)
                .done(function (data) {
                    if (data != null) {
                        for (var i = 0; i < data.length; i++) {
                            var featureUpdate = new model.featureUpdate();
                            featureUpdate.Id(data[i].Id);
                            featureUpdate.BrandId(data[i].BrandId);
                            featureUpdate.OperationId(data[i].OperationId);
                            featureUpdate.IsNetSellersUser(data[i].IsNetSellersUser);
                            featureUpdate.IsEmployee(data[i].IsEmployee);
                            featureUpdate.Name(data[i].Name);
                            featureUpdate.Description(data[i].Description);
                            featureUpdate.VideoFileUrl(data[i].VideoFileUrl);
                            featureUpdate.DocumentFileUrl(data[i].DocumentFileUrl);
                            featureUpdate.DateAdded(data[i].DateAdded);
                            featureUpdate.IsActive(data[i].IsActive);
                            featureUpdatesList.push(featureUpdate);
                        }
                    }
                }).always(function (data) {
                    featureUpdatesList = featureUpdatesList.reverse();
                    featureUpdatesList.toFirstPage();
                    //setFiles(data);
                    isDataLoad(false);
                    session.isAjaxPost(true);
                }).fail(function () {

                });
        }


        function canDeactivate() {
            featureUpdatesList([]);
            //featureUpdateModel.reset();
            return true;
        }

        function savetoDB(featureUpdateModel) {
            var url = config.saveFeatureUpdateUrl;
            return https.post(url,
                {
                    Id: featureUpdateModel.Id(),
                    BrandId: featureUpdateModel.BrandId(),
                    OperationId: featureUpdateModel.OperationId(),
                    IsNetSellersUser: featureUpdateModel.IsNetSellersUser(),
                    IsEmployee: featureUpdateModel.IsEmployee(),
                    IsActive: featureUpdateModel.IsActive(),
                    Name: featureUpdateModel.Name(),
                    Description: featureUpdateModel.Description(),
                    VideoFileUrl: featureUpdateModel.VideoFileUrl(),
                    DocumentFileUrl: featureUpdateModel.DocumentFileUrl(),
                    IsActive: featureUpdateModel.IsActive()
                }).then(function (data) {
                    if (!isDelete() && (vm.videoForUpload.lastModified || vm.docForUpload.lastModified)) {
                        var formData = new FormData();
                        var videoType = ['x-ms-wmv'];
                        var docType = ['vnd.openxmlformats-officedocument.wordprocessingml.document'];
                        var errors = [];
                        if (vm.videoForUpload.lastModified) {
                            if (-1 == $.inArray(vm.videoForUpload.type.split('/')[1], videoType)) {
                                $('#tbVideo').val("");
                                errors.push('Please enter valid photo file. Only .wmv allowed.');
                            }

                            else
                                formData.append("video", vm.videoForUpload);
                        }

                        if (vm.docForUpload.lastModified) {
                            if (-1 == $.inArray(vm.docForUpload.type.split('/')[1], docType)) {
                                $('#tbDocument').val("");
                                errors.push('Please enter valid document file. Only .docx allowed.');
                            }

                            else
                                formData.append("document", vm.docForUpload);
                        }


                        if (!vm.videoForUpload && !vm.docForUpload) {
                            errors.push('Please provide a Video / Document file input.');
                        }

                        if (errors.length > 0) {
                            alert.warning(errors.join("<br/>"));
                            //resetPage();
                            return;
                        }


                        formData.append("userId", session.userId);

                        $.ajax({
                            url: config.saveFeatureUpdateFilesUrl,
                            type: 'POST',
                            data: formData,
                            cache: false,
                            contentType: false,
                            processData: false,
                            beforeSend: function (req) {
                                if (sessionStorage["accessToken"] || localStorage["accessToken"] != null)
                                    req.setRequestHeader('Authorization',
                                        'Bearer ' + sessionStorage["accessToken"] || localStorage["accessToken"]);
                            }
                        }).fail(function (data) {
                            alert.error("Error processing your request!");
                            //resetPage();
                        }).done(function () {
                            alert.success("Your request was successfully processed.");
                        });

                    }

                    isDelete(false);
                    featureUpdatesList([]);
                    featureUpdateModel.reset();
                    controlsReset();
                    getBrandsList();
                    operationsList([]);
                    getFeatureUpdates();
                });
        }

        function controlsReset() {
            $('#fuDocument').val("");
            $('#fuVideo').val("");
            videoForUpload("");
            docForUpload("");
        }


        function saveObject() {
            var errors = [];
            if ((featureUpdateModel.VideoFileUrl() == null || featureUpdateModel.VideoFileUrl() === "") &&
                (featureUpdateModel.DocumentFileUrl() == null || featureUpdateModel.DocumentFileUrl() === "")) {
                errors.push('Please provide a Video / Document file input.');
                alert.warning(errors.join("<br/>"));
                return false;
            }
            if (vm.objectErrors().length > 0) {
                vm.objectErrors.showAllMessages();
                setTimeout(function () {
                    $('.modal-body', '#mdCreateFeatureUpdate').scrollTop(500);
                }, 300);
                return false;
            }

            _objectUsed = new model.featureUpdate();
            _objectUsed.update(featureUpdateModel);
            savetoDB(_objectUsed);
            $("#mdCreateFeatureUpdate").modal('hide');

        }

        function updateObject() {
            var errors = [];
            if ((featureUpdateModel.VideoFileUrl() == null || featureUpdateModel.VideoFileUrl() === "") &&
                (featureUpdateModel.DocumentFileUrl() == null || featureUpdateModel.DocumentFileUrl() === "")) {
                errors.push('Please provide a Video / Document file input.');
                alert.warning(errors.join("<br/>"));
                return false;
            }
            _objectUsed = new model.featureUpdate();
            _objectUsed.update(featureUpdateModel);
            if (!featureUpdateModel.isValid()) {
                return false;
            }
            savetoDB(_objectUsed);
            $("#mdCreateFeatureUpdate").modal('hide');
            isEdit(false);
            lastBrandId = "";
        }

        function addNewObject() {
            featureUpdateModel.reset();
            getBrandsList();
            getAllOperationsList();
            $("#mdCreateFeatureUpdate").modal('show');
            isEdit(false);
            vm.objectErrors = ko.validation.group([featureUpdateModel]);
            vm.objectErrors.showAllMessages(false);
        }

        function editObject(item, event) {
            vm.featureUpdateModel.reset();
            vm.featureUpdateModel.Id(item.Id());
            vm.featureUpdateModel.BrandId(item.BrandId());
            vm.featureUpdateModel.Name(item.Name());
            vm.featureUpdateModel.Description(item.Description());
            vm.featureUpdateModel.VideoFileUrl(item.VideoFileUrl());
            vm.featureUpdateModel.DocumentFileUrl(item.DocumentFileUrl());
            vm.currentVideo(item.VideoFileUrl());
            vm.currentDocument(item.DocumentFileUrl());
            vm.featureUpdateModel.IsNetSellersUser(item.IsNetSellersUser());
            vm.featureUpdateModel.IsEmployee(item.IsEmployee());
            vm.featureUpdateModel.DateAdded(item.DateAdded());
            vm.featureUpdateModel.IsActive(item.IsActive());
            if (item.BrandId() != null && item.BrandId() != "") {
                Q.when(getOperationsList(item.BrandId())
                    .then(function () {
                        vm.featureUpdateModel.OperationId(item.OperationId());
                    }));
            } else {
                getAllOperationsList();
            }

            $("#mdCreateFeatureUpdate").modal('show');
            isEdit(true);
            vm.objectErrors = ko.validation.group([featureUpdateModel]);
            vm.objectErrors.showAllMessages(false);
        }

        function deleteObject(item, event) {
            $("#mdDeleteFeatureUpdateItem").modal('show');
            isDelete(true);
            _deletingFeatureUpdateObject.reset();
            _deletingFeatureUpdateObject.Id(item.Id());
            _deletingFeatureUpdateObject.BrandId(item.BrandId());
            _deletingFeatureUpdateObject.OperationId(item.OperationId());
            _deletingFeatureUpdateObject.Name("");
            _deletingFeatureUpdateObject.Description(item.Description());
            _deletingFeatureUpdateObject.DocumentFileUrl(item.DocumentFileUrl());
            _deletingFeatureUpdateObject.VideoFileUrl(item.VideoFileUrl());
            _deletingFeatureUpdateObject.IsNetSellersUser(item.IsNetSellersUser());
            _deletingFeatureUpdateObject.IsEmployee(item.IsEmployee());
            _deletingFeatureUpdateObject.DateAdded(item.DateAdded());
            _deletingFeatureUpdateObject.IsActive(item.IsActive());
        }

        function deletObjectConfirmed() {
            savetoDB(_deletingFeatureUpdateObject);
            featureUpdateModel.reset();
            $("#mdDeleteFeatureUpdateItem").modal('hide');
            isDelete(false);
        }

        function closeDialog() {
            $("#mdCreateFeatureUpdate").modal('hide');
            isEdit(false);
            isDelete(false);
            lastBrandId = "";
            featureUpdateModel.reset();
            controlsReset();
        }


        function goBack(complete) {
            router.navigateBack();
        }
    });
