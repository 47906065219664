define('viewmodels/foreclosureSearch/foreclosureSearch',["knockout", 'plugins/http', 'global/session', 'config', 'utlities', 'plugins/router'],
    function (ko, https, session, config, utlities, router) {
        var vm = {
            activate: activate,
            session: session,
            compositionComplete: compositionComplete
        }
        return vm;

        function activate() {
            var redirectUrl = session.foreclosureSearchUrl();
            session.CheckPopUpBlock(redirectUrl);
            //window.open(redirectUrl, '_blank');
            router.navigateBack();
        }

        function compositionComplete() {
            session.validateInput();
        }
    });
