define('viewmodels/propertySearchModule/recentRequests',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'config', 'alertNotification', 'recentRequestsModel', 'propertyProfileService', 'propertyProfileModel', 'koPaging', 'nameValue', 'propertySearchCommon', 'moment'],
    function (app, router, $, ko, validation, Q, https, session, config, alerter, model, propertyProfileService, propertyProfile, koPaging, nameValue, propertySearchCommon, moment) {


        var selectedProperty = ko.observable(undefined);
        var serviceNames;
        //BKFS Reports Start
        var propertyProfileModel = new propertyProfile.PropertyProfile();
        var reportPreparedForModel = new propertyProfile.ReportPreparedFor();
        var emailPropertyProfileReportModel = new propertyProfile.EmailPropertyProfileReport();
        var showMailDialog = false;
        //BKFS Reports End

        var property_APN = ko.observableArray();
        var property_Owner = ko.observable();
        var property_Address = ko.observable();
        var property_City = ko.observableArray();
        var property_State = ko.observable();
        var property_Zip = ko.observable();
        var isCustomizeYourReportPopUp = ko.observable(false);

        var searchResults = ko.observableArray([]);
        searchResults.extend({
            paged: {
                pageSize: 10,
                pageGenerator: 'default'
            }
        });

        function scrollTop() {
            $(window).scrollTop(0);
        }

        function canActivate() {
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return false;
            }
            return true;
        }

        function activate() {
            searchResults([]);
            console.log('VM - Activated');
            showMailDialog = false;
            getServiceTypes();
            getServiceNames();

        }

        function canDeactivate() {
            clearSearch();
            searchResults([]);
            return true;
        }

        var searchModel = new model.SearchCriteria();
        var customSettings = new model.CustomSettings();

        function clearSearch() {
            searchResults([]);
            vm.searchModel.selectedCount(vm.searchModel.counts()[0]);
            if (vm.searchModel.serviceTypes() != undefined && vm.searchModel.serviceTypes() != null && vm.searchModel.serviceTypes().length > 0)
                vm.searchModel.selectedService(vm.searchModel.serviceTypes()[0].Key);
        }

        function searchOrders() {

            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return;
            }



            var url = config.getReportProductsUrl;
            var request = {
                UserId: session.userId,
                Count: vm.searchModel.selectedCount(),
                ServiceId: vm.searchModel.selectedService(),
                OperationId: session.defaultOpertionId(),
                BrandId: session.brandId
            };
            searchResults([]);
            https.post(url, request)
                .then(function (data) {
                    if (data != undefined && data != null && data.length > 0) {
                        console.log(data);
                        var result = loadResultToKOArray(data);
                        searchResults(result);
                        if (result != undefined && result != null && result.length > 0) {
                            searchResults.toFirstPage();
                        }
                    }
                })
                .fail(function (data) {
                    console.log(data);
                });
            $('#mdEmailPropertyProfileReport').modal('hide');
        }

        function loadResultToKOArray(data) {
            var results = [];

            for (var i = 0; i < data.length; i++) {

                result = new model.SearchResult();
                result.Address(data[i].Address)
                result.City(data[i].City)
                result.State(data[i].State)
                result.Zip(data[i].Zip)

                result.Owner(data[i].Owner)
                result.APN(data[i].APN)
                result.DocNum(data[i].DocNum)
                result.Requested(data[i].Requested)

                result.Fips(data[i].Fips)
                result.ProfileId(data[i].ProfileId)
                result.Custom(data[i].Custom)
                result.ServiceId(data[i].ServiceId)
                result.ServiceName(getServiceName(data[i].ServiceId));
                result.DocLink(data[i].DocLink);
                result.Settings(data[i].Settings);

                results.push(result);
            }
            return results;
        }

        function getServiceTypes() {

            var url = config.getServiceTypesUrl;

            https.get(url, {
                UserId: session.userId,
                OperationId: session.defaultOpertionId(),
                format: 'json'
            })
                .then(function (data) {
                    console.log(data);
                    vm.searchModel.serviceTypes(data);
                    vm.searchModel.serviceTypes.unshift({ Key: -1, Value: "All Requests" });
                    vm.searchModel.selectedService(vm.searchModel.serviceTypes()[0].Key);
                })
                .fail(function (data) {
                    console.log(data);
                });

        }

        function getServiceNames() {

            var url = config.getServiceNamesUrl;

            https.get(url)
                .then(function (data) {
                    serviceNames = data;
                    searchOrders();
                })
                .fail(function (data) {
                    console.log(data);
                });

        }

        function getServiceName(id) {
            if (serviceNames && serviceNames.length > 0) {
                return $.map(serviceNames, function (obj) {
                    if (obj.Key == id) {
                        return obj.Value;
                    }
                });
            }
        }

        function showDetails(data) {
            console.log(data);
            if (data) {
                $('#mdEmailPropertyProfileReport').modal('hide');
                if (data.ServiceId() == 87 || data.ServiceId() == 85 || data.ServiceId() == 92 || data.ServiceId() == 89) {
                    session.CheckPopUpBlock(data.DocLink(), null, "** Reports open in a new window. Please disable your popup blocker and try again.");
                    //window.open(data.DocLink());
                }
                else if (data.ServiceId() == 84) {
                    showMailDialog = true;
                    property_APN(data.APN);
                    property_Owner(data.Owner);
                    property_Address(data.Address);
                    property_City(data.City);
                    property_State(data.State);
                    property_Zip(data.Zip);
                    selectedProperty(data);
                    getReport();
                }
            }
        }

        function showCustomSettings(data) {
            if (data && data.Settings) {
                customSettings.update(data.Settings());
                $('#mdEmailPropertyProfileReport').modal('hide');
                $('#dialogCustomSettings').modal('show');
            }

        }

        //function showPropertyProfilePopUp() {
        //    $('#mdPropertyProfile').modal('show');
        //}

        //-------------------------------------------------------------------------------------------------------------------------------------
        //BKFS Reports Start

        reportPreparedForModel.pname.subscribe(function (value) {
            if (propertyProfileModel.enableReportPreparedFor()) {
                console.log("In subscribe : pname");
                RebuildReportURLs("pname", value, true, true);
            }
        });

        reportPreparedForModel.pcompany.subscribe(function (value) {
            if (propertyProfileModel.enableReportPreparedFor()) {
                console.log("In subscribe : pcompany");
                RebuildReportURLs("pcompany", value, true, true);
            }
        });

        reportPreparedForModel.paddress1.subscribe(function (value) {
            if (propertyProfileModel.enableReportPreparedFor()) {
                console.log("In subscribe : paddress1");
                RebuildReportURLs("paddress1", value, true, true);
            }
        });

        reportPreparedForModel.paddress2.subscribe(function (value) {
            if (propertyProfileModel.enableReportPreparedFor()) {
                console.log("In subscribe : paddress2");
                RebuildReportURLs("paddress2", value, true, true);
            }
        });

        reportPreparedForModel.pcsz.subscribe(function (value) {
            if (propertyProfileModel.enableReportPreparedFor()) {
                console.log("In subscribe : pcsz");
                RebuildReportURLs("pcsz", value, true, true);
            }
        });

        function ChangeProfileURL() {
            var int = 0;
            $.each(propertyProfileModel.availableSections(), function (index, data) {
                if (data.CheckBoxValue() === true) {
                    int = int + data.Value();
                }
            });
            RebuildReportURLs("mask", int, false, true);
            return true;
        }

        function RebuildReportURLs(queryStringParameter, value, includeIndividualPages, includeReports) {
            if (value != null && value != "") {
                if (includeIndividualPages) {
                    if (propertyProfileModel.availableSections().length > 0) {
                        for (var i = 0; i < propertyProfileModel.availableSections().length; i++) {
                            if (propertyProfileModel.availableSections()[i].ProfileURL() != null && propertyProfileModel.availableSections()[i].ProfileURL() != "") {
                                propertyProfileModel.availableSections()[i].ProfileURL(replaceURLParameters(propertyProfileModel.availableSections()[i].ProfileURL(), queryStringParameter, value));
                                //propertyProfileModel.availableSections()[i].ProfileURL(propertyProfileModel.availableSections()[i].ProfileURL() + "&" + queryStringParameter + "=" + value);
                            }
                        }
                    }
                }
                if (includeReports) {
                    if (propertyProfileModel.availableReportTypes().length > 0) {
                        for (var i = 0; i < propertyProfileModel.availableReportTypes().length; i++) {
                            if (propertyProfileModel.availableReportTypes()[i].ProfileURL() != null && propertyProfileModel.availableReportTypes()[i].ProfileURL() != "") {
                                //propertyProfileModel.availableReportTypes()[i].ProfileURL(propertyProfileModel.availableReportTypes()[i].ProfileURL() + "&" + queryStringParameter + "=" + value);
                                propertyProfileModel.availableReportTypes()[i].ProfileURL(replaceURLParameters(propertyProfileModel.availableReportTypes()[i].ProfileURL(), queryStringParameter, value));
                            }
                        }
                    }
                }
            }
        }

        function replaceURLParameters(url, queryStringParameter, value) {
            var vars = [], hash;
            var urlWithoutParams = url.slice(0, url.indexOf('?'));
            var finalURLParams = "";
            var replaced = false;
            var hashes = url.slice(url.indexOf('?') + 1).split('&');
            for (var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                if (hash[0] === queryStringParameter) {
                    hash[1] = value;
                    replaced = true;
                }
                if (i > 0)
                    finalURLParams = finalURLParams + "&" + hash[0] + "=" + hash[1];
                else
                    finalURLParams = hash[0] + "=" + hash[1];
            }
            if (!replaced) {
                finalURLParams = finalURLParams + "&" + queryStringParameter + "=" + value;
            }
            return urlWithoutParams + "?" + finalURLParams;
        }

        function getProfileCustomSettingsForUser(userId) {
            var url = config.getProfileCustomSettingsForUserUrl;
            return https.get(url, {
                userId: userId,
                format: 'json'
            })
        }

        function getReport() {
            $('#mdEmailPropertyProfileReport').modal('hide');
            $('#mdPropertyProfile').modal('hide');
            if (session.userId != null) {
                //getProfileCustomSettingsForUser(session.userId).done(function (data) {
                //    if (data != null) {
                console.log('open profile report modal');
                reportPreparedForModel.reset();
                propertyProfileService.getPropertyProfileInfo(selectedProperty().Fips(), selectedProperty().APN(), selectedProperty().ProfileId()).done(function (result) {
                    //propertyProfileModel = result;
                    propertyProfileModel.resultStatus(result.ResultStatus);
                    propertyProfileModel.profileId(result.ProfileId);
                    propertyProfileModel.property(result.Property);
                    propertyProfileModel.availableSections(propertyProfileService.loadToKOArray(result.AvailableSections).slice(0));
                    propertyProfileModel.availableReportTypes(propertyProfileService.loadToKOArray(result.AvailableReportTypes).slice(0));
                    //propertyProfileModel.assignedCredits(selectedReport.UsageLimitAssigned);
                    //propertyProfileModel.usedCredits(selectedReport.UsageCountUsed);
                    propertyProfileModel.enableReportPreparedFor(result.EnableReportPreparedForSection);
                    session.selectedProfLanguage.langageCode(result.ProfileLanguagePreference.Key);
                    var lancCde = '';
                    if (session.selectedProfLanguage.langageCode() == null || session.selectedProfLanguage.langageCode() == '')
                        lancCde = '-'
                    else
                        lancCde = session.selectedProfLanguage.langageCode();
                    var ctr = $('.prof');
                    ctr.removeClass('prof btn btn-primary');
                    $('[data-lac=' + lancCde + ']').addClass('prof btn btn-primary');
                    propertyProfileService.savePropertyProfileLanguage(lancCde);

                }).then(showPropertyProfilePopUp).fail(function () {
                    alerter.error('Error getting Property Profile Info');
                });
                //    }
                //    else {
                //        console.log('Custom Comparable Settings has not been enabled');
                //        alerter.error('Custom Comparable Settings has not been enabled');
                //        return;
                //    }
                //});
            }
            else {
                console.log('User Id is null');
                alerter.error('User not logged in');
                return;
            }
        }

        function showPropertyProfilePopUp() {
            console.log(selectedProperty());
            //console.log(propertyProfileModel.profileId());
            //console.log(propertyProfileModel.availableReportTypes());
            //console.log(propertyProfileModel.availableSections());
            $('#mdEmailPropertyProfileReport').modal('hide');
            $('#mdPropertyProfile').modal('show');
        }

        //function propertyProfilePopup() {
        //    $('#mdCustomComparables').modal('show');

        //}

        function saveDefaultSetting() {
            var selected = new Array();
            $.each(propertyProfileModel.availableSections(), function (index, data) {
                if (data.CheckBoxValue() === true) {
                    selected.push(data.Value);
                }
            });
            propertyProfileService.setReportCustomizationForUser(selected)
                .done(function (result) {
                    alerter.success('Customize Your Report settings updated successfully.');
                }).fail(function () {
                    alerter.error('Error Updating Customize Your Report settings');
                });
        }



        function EmailPropertyProfileReportPopUp(data, event) {
            if (data.Name() != null && data.ProfileURL() != null && data.Name() != '' && data.ProfileURL() != '') {
                emailPropertyProfileReportModel.reset();
                emailPropertyProfileReportModel.selectedReport(data.Name());
                emailPropertyProfileReportModel.selectedReportURL(data.ProfileURL());
                showEmailPropertyProfileReports();
            }
        }

        function showEmailPropertyProfileReports() {
            if (showMailDialog)
                $('#mdEmailPropertyProfileReport').modal('show');
        }

        function SendEmailPropertyProfileReport() {
            if (vm.emailPropertyProfileReportModelError != null && vm.emailPropertyProfileReportModelError != 'undefined' && vm.emailPropertyProfileReportModelError().length > 0) {
                vm.emailPropertyProfileReportModelError.showAllMessages();
                return;
            }

            var url = config.emailPropertyProfileReportUrl;
            var request = {
                "UserId": session.userId,
                "OperationId": session.defaultOpertionId(),
                "EmailAddress": emailPropertyProfileReportModel.emailAddresses(),
                "EmailSubject": emailPropertyProfileReportModel.emailSubject(),
                "EmailMessage": emailPropertyProfileReportModel.emailMessage(),
                "ReadReceipt": emailPropertyProfileReportModel.readReceipt(),
                "ReportURL": emailPropertyProfileReportModel.selectedReportURL(),
                "ReportName": emailPropertyProfileReportModel.selectedReport(),
                "SelectedProperty": {
                    "UserId": session.userId,
                    "Apn": selectedProperty().APN(),
                    "Fips": selectedProperty().Fips(),
                    "Address1": selectedProperty().Address(),
                    "Address2": "",
                    "Zip": selectedProperty().Zip(),
                    "City": selectedProperty().City(),
                    "State": selectedProperty().State(),
                    "Zip4": "",
                    "Owner": selectedProperty().Owner(),
                },
            };

            return https.post(url, request)
                .done(function (data) {
                    $('#mdEmailPropertyProfileReport').modal('hide');
                    if (data != null) {
                        if (data.HasErrors == false) {
                            alerter.success("Email Sent", "Email Property Profile Report");
                        }
                        else {
                            alerter.error(data.Messages[0].Message, "Email Property Profile Report");
                            //alerter.error('Error while sending email', "Email Property Profile Report");
                        }
                    }
                    else {
                        alerter.error('Internal Server Error', "Email Property Profile Report");
                    }
                });
        }

        //BKFS End
        //-------------------------------------------------------------------------------------------------------------------------------------



        //var selectedProperty = new model.SearchResult();


        var vm = {
            canActivate: canActivate,
            activate: activate,
            canDeactivate: canDeactivate,
            searchModel: searchModel,
            serviceTypes: searchModel.serviceTypes,
            selectedService: ko.observable(""),
            clearSearch: clearSearch,
            searchOrders: searchOrders,
            searchResults: searchResults,
            showDetails: showDetails,
            propertyProfileModel: propertyProfileModel,
            selectedProperty: selectedProperty,
            customSettings: customSettings,
            reportPreparedForModel: reportPreparedForModel,
            saveDefaultSetting: saveDefaultSetting,
            showCustomSettings: showCustomSettings,
            ChangeProfileURL: ChangeProfileURL,
            property_APN: property_APN,
            property_Owner: property_Owner,
            property_Address: property_Address,
            property_City: property_City,
            property_State: property_State,
            property_Zip: property_Zip,
            isCustomizeYourReportPopUp: isCustomizeYourReportPopUp,
            emailPropertyProfileReportModel: emailPropertyProfileReportModel,
            EmailPropertyProfileReportPopUp: EmailPropertyProfileReportPopUp,
            showEmailPropertyProfileReports: showEmailPropertyProfileReports,
            SendEmailPropertyProfileReport: SendEmailPropertyProfileReport,
            getSelectedProfileCss: ko.computed(function () { return session.getSelectedProfileCss(); }),
            setLanguagePreferance: propertySearchCommon.setLanguagePreferance,
            moment: moment,
            scrollTop: scrollTop,
            compositionComplete: function () {
                session.validateInput();
                $('#custName').eq(0).focus();
            }
        };
        vm.emailPropertyProfileReportModelError = ko.validation.group([vm.emailPropertyProfileReportModel]);
        return vm;

    });
