define('vipServicesModel',['knockout', 'plugins/http', 'global/session', 'config', 'utlities'],
    function (ko, https, session, config, utlities) {
        function vipServices() {
            var self = this;
            var self = this;
            self.vipServiceList = ko.observableArray([
            { key: "1", value: "VIP Services" },
            { key: "2", value: "Contact Information" },
            { key: "3", value: "Delivery" },
            { key: "4", value: "Interests" },
            ]);
            self.vipService = ko.observable("")
            self.vipServiceView = ko.observable("")
            self.reset = function () {
            }

        }
        function vipServiceProfile() {
            var self = this;
            self.Name = ko.observable("");
            self.Email = ko.observable("");
            self.Phone = ko.observable("").extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid phone number',
                }
            });
            self.Cell = ko.observable("").extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid cell number',
                }
            });
            self.Fax = ko.observable("").extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid fax number',
                }
            });
            self.Address = ko.observable("");
            self.City = ko.observable("");
            self.StatusUpdates = ko.observable("");
            self.Zip = ko.observable("");
            self.StateId = ko.observable("").extend({ required: { message: 'State is required.' } });
            self.ClientContact = ko.observable("");
            self.CourtesyCall = ko.observable("");
            self.MailHUD = ko.observable("");
            self.Signings = ko.observable("");
            self.UserId = ko.observable("");
            self.stateList = ko.observableArray([]);
            self.allStateList = ko.observableArray([]);

            utlities.getStatesListWithStateId().done(function (result) {
                self.stateList([]);
                self.stateList(result);

                self.allStateList([]);
                self.allStateList(result);
            });
            
        }
        function vipServicesContacts() {
            var self = this;
            self.ContactId = ko.observable("");
            self.UserId = ko.observable("");
            self.Order = ko.observable("");
            self.Cell = ko.observable("");
            self.Type = ko.observable("").extend({ required: { message: 'Contact method  is required.' } });
            self.Time = ko.observable("").extend({ required: { message: 'Contact time  is required.' } });
            self.Weekdays = ko.observable("");
            self.PhoneData = ko.observable("").extend({
                phoneUS: {
                    params: true,
                    message: 'Please specify a valid phone number',
                }
            });
            self.MailData = ko.observable("").extend({ email: { message: 'Invalid Email.' } });
            self.Sun = ko.observable(true);
            self.Mon = ko.observable(true);
            self.Tue = ko.observable(true);
            self.Wed = ko.observable(true);
            self.Thu = ko.observable(true);
            self.Fri = ko.observable(true);
            self.Sat = ko.observable(true);
            self.reset = function () {
                self.ContactId("");
                self.UserId("");
                self.Order("");
                self.Cell("");
                self.Type("");
                self.Time("");
                self.Weekdays("");
                self.PhoneData("");
                self.MailData("");
                self.Sun(true);
                self.Mon(true);
                self.Tue(true);
                self.Wed(true);
                self.Thu(true);
                self.Fri(true);
                self.Sat(true);
                //self.Type.clearError();
                //self.Time.clearError();
            }
        }
        function vipDelivery() {
            var self = this;
            self.address = ko.observable("");
            self.zip = ko.observable("");
            self.city = ko.observable("");
            self.state = ko.observable("");
            self.deliverytime = ko.observable("7:00");
            self.timeampm = ko.observable("");
            self.stateList = ko.observableArray([]);
            self.state = ko.observable("")
            utlities.getStatesListWithStateId().done(function (result) {
                self.stateList([]);
                self.stateList(result);
                self.stateList.unshift({
                    Value: "-1", Key: "Select a State"
                });
            });
            self.deliverytimeList = ko.observableArray([
             { key: "12:00", value: "12:00" },
             { key: "12:30", value: "12:30" },
             { key: "1:00", value: "1:00" },
             { key: "1:30", value: "1:30" },
            { key: "2:00", value: "2:00" },
            { key: "2.30", value: "2:30" },
            { key: "3:00", value: "3:00" },
            { key: "3:30", value: "3:30" },
            { key: "4:00", value: "4:00" },
            { key: "4:30", value: "4:30" },
             { key: "5:00", value: "5:00" },
            { key: "5:30", value: "5:30" },
            { key: "6:00", value: "6:00" },
            { key: "6:30", value: "6:30" },
             { key: "7:00", value: "7:00" },
            { key: "7:30", value: "7:30" },
             { key: "8:00", value: "8:00" },
            { key: "8:30", value: "8:30" },
            { key: "9:00", value: "9:00" },
            { key: "9:30", value: "9:30" },
             { key: "10:00", value: "10:00" },
            { key: "10:30", value: "10:30" },
            { key: "11:00", value: "11:00" },
            { key: "11:30", value: "11:30" },
            ]);

            self.time = ko.computed(function () {
                return self.deliverytime() + self.timeampm();
            });
            self.timeampmList = ko.observableArray([
             { key: "AM", value: "AM" },
             { key: "PM", value: "PM" },

            ]);

            self.reset = function () {
                self.address("");
                self.zip("");
                self.city("");
                self.state("-1");
                self.deliverytime("7:00");
                self.timeampm("");
            }
        }
        function vipDeliveryDetail() {
            var self = this;
            self.deliveryList = ko.observableArray([]);
            self.deliveryId = ko.observable("");
            self.fullAddress = ko.observable("");
        }
        function category() {
            var self = this;
            self.categoryId = ko.observable("");
            self.name = ko.observable("");
            self.description = ko.observable("");
            self.interests = ko.observableArray([]);
        }

        function vipInterest() {
            var self = this;
            self.name = ko.observable("");
            self.description = ko.observable("");
            self.isSelected = ko.observable("");
            self.value = ko.observable("");
        }

        function userInterest() {
            var self = this;
            self.userId = ko.observable("");
            self.interestId = ko.observable("");
        }
        function vipInterestCollection() {
            var self = this;
            self.userId = ko.observable("");
            self.vipCategories = ko.observableArray([]);
        }
        var vm = {
            vipServiceProfile: vipServiceProfile,
            vipServices: vipServices,
            vipDelivery: vipDelivery,
            vipServicesContacts: vipServicesContacts,
            vipDeliveryDetail: vipDeliveryDetail,
            vipInterestCollection:vipInterestCollection,
            category: category,
            vipInterest: vipInterest,
            userInterest: userInterest
        }

        return vm;
    });
