define('customerInformationModel',["knockout", "modelMetaData"],
    function (ko, modelMetaData) {
        function CustomerInformation() {
            var self = this;

            self.viewName = "orderModule/_customerInformation.html"
            self.title = "Customer Information"
            self.ModelId = "CustomerInformation"
            self.index = ko.observable(-1)

            self.orderDate = ko.observable("")
            self.salesRep = ko.observable("")
            self.fullName = ko.observable("")
            self.email = ko.observable("")
            self.company = ko.observable("")
            self.address = ko.observable("")
            self.phon = ko.observable("")
            self.ext = ko.observable("")
            self.fax = ko.observable("")

            self.update = function (data) {
                if (data) {
                    self.index(data.index());
                    self.orderDate(data.orderDate());
                    self.salesRep(data.salesRep());
                    self.fullName(data.county());
                    self.email(data.email());
                    self.company(data.company());
                    self.address(data.address());
                    self.phon(data.phon());
                    self.ext(data.ext());
                    self.fax(data.fax());
                }

            };

            self.reset = function () {
                self.orderDate("");
                self.salesRep("");
                self.fullName("");
                self.email("");
                self.company("")
                self.address("")
                self.phon("")
                self.ext("")
                self.fax("")
                ko.validation.group(self).showAllMessages(false);
            }

            self.isValid = function () {
                return ko.validation.group(self).length == 0;
            }

            self.GetModelData = function () {
                self.modelData = new modelMetaData.modelData();
                self.modelData.title(self.title);
                self.modelData.properties.push(new modelMetaData.propertyData().create("Order Date", self.orderDate()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Sales Rep", self.salesRep()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Full Name", self.fullName()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Email", self.email()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Company", self.company()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Address", self.address()));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Phone", modelMetaData.getUSPhoneFormatWithExtn(self.phon(), self.ext(), '')));
                self.modelData.properties.push(new modelMetaData.propertyData().create("Fax", modelMetaData.getUSPhoneFormatWithExtn(self.fax(),'','')));
            }
        }
    
        return CustomerInformation;
    });
