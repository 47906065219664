define('viewmodels/legalGlossary/legalGlossary',['durandal/app', 'plugins/router', 'jquery', 'knockout', 'validate', 'knockout.validation', 'plugins/http', 'global/session', 'config', 'alertNotification', 'legalGlossaryModel', 'utlities'],
    function (app, router, $, ko, validate, validation, https, session, config, alert, legalGlossaryModel, utlities) {
        var legalGlossary = new legalGlossaryModel.legalGlossary();

        function activate() {

            getlegalGlossary(session.brandId);
        }

        function getlegalGlossary(brandId) {
            var url = config.getlegalGlossaryUrl;
            https.get(url, {
                brandId: brandId,
                format: 'json'
            })
                .then(function (data) {
                    legalGlossary.legalGlossaryList(data);
                });
        }

        function goTop() {
            window.location.href = "#TOP";
        }

        function compositionComplete() {
            session.validateInput();
        }

        var vm = {
            activate: activate,
            legalGlossaryModel: legalGlossary,
            goTop: goTop,
            compositionComplete: compositionComplete
        }
        return vm;

    });
