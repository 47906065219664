define('viewmodels/orderModule/viewOnlineOrderDetail',['plugins/router', 'knockout', 'durandal/app', 'plugins/http', 'global/session', 'viewOnlineModel', 'config', 'nameValue', 'nameList', 'moment', 'alertNotification', 'koPaging'],
    function (router, ko, app, https, session, model, config, nameValue, nameList, moment, alerter, koPaging) {

        var selectedOrder = ko.observable('');
        var onlineOrderDetailsModel = new model.OnlineOrderDetails();

        function activate(OrderId) {
            AfterLoad();
            selectedOrder(parseInt(OrderId));
            return getOrderDetail(selectedOrder()).done(function (result) {
                onlineOrderDetailsModel.name(result.Name)
                onlineOrderDetailsModel.orderNumber(selectedOrder());
                onlineOrderDetailsModel.sections(loadToKOArray(result.Sections))
            })

        }
        function getOrderDetail(selectedItem) {
            var url = config.getOfflineOrderDetailsUrl;
            return https.get(url, {
                orderId: selectedItem,
                format: 'json'
            });
        }
        function AfterLoad() {
            if ($('#hdnIsLogin').val() == "true") {
                $('#page-wrapper').attr('style', 'margin-left:220!important');
            }
        }
        //load to local array.
        function loadToKOArray(webAPICollectionObject) {
            var mappedLocalKOArray = [];
            var value;

            for (var j = 0; j < webAPICollectionObject.length; j++) {
                var lst = new nameList();

                var mappedSectionDataArray = [];
                for (var i = 0; i < webAPICollectionObject[j].Data.length; i++) {
                    var item = new nameValue(); //nameValue = a common model

                    item.Name(webAPICollectionObject[j].Data[i].Name);
                    value = webAPICollectionObject[j].Data[i].Value;
                    value = (value == 'True') ? 'Yes' : (value == 'False') ? 'No' : value;
                    item.Value(value);
                    mappedSectionDataArray.push(item);
                }

                lst.Name(webAPICollectionObject[j].Name);
                lst.List.push(mappedSectionDataArray);
                mappedLocalKOArray.push(lst);
            }



            return mappedLocalKOArray;
        }

        function itemClicked(index) {

            var id = "div" + index;
            var el = document.getElementById(id);
            if (el != null) {
                if (el.style.display != 'none') {
                    el.style.display = 'none';
                }
                else {
                    el.style.display = '';
                }
            }

            changeimage(id.replace('div', 'img'), "arrow_up.gif", "arrow_down.gif");
        }

        function changeimage(id, sMinus, sPlus) {
            var img = document.getElementById(id);
            if (img != null) {
                var bExpand = img.src.indexOf(sPlus) >= 0;
                if (!bExpand)
                    img.src = '/Content/images/' + sPlus;
                else
                    img.src = '/Content/images/' + sMinus;
            }
        }
        function printOrderDetail() {

            var print = document.getElementById("print");
            print.style.display = 'none';
            var backbtn = document.getElementById("btnBack");
            backbtn.style.display = 'none';

            window.print();
            print.style.display = 'block';
            backbtn.style.display = 'block';
        }
        function goBack() {
            router.navigate('#viewOnlineOrders');
        }

        function canDeactivate() {
            session.clearOrderForPropertyAddress();
            return true;
        }

        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            selectedOrder: selectedOrder,
            onlineOrderDetailsModel: onlineOrderDetailsModel,
            getOrderDetail: getOrderDetail,
            loadToKOArray: loadToKOArray,
            itemClicked: itemClicked,
            changeimage: changeimage,
            printOrderDetail: printOrderDetail,
            goBack: goBack
        }
        return vm;

    })



;
