define('models/order/principalsBuyers',["knockout"],
    function (ko) {
        function principalsBuyers() {
            var self = this;
            self.buyerFirstName = ko.observable("")
            self.buyerLastName = ko.observable("")
            self.buyerWorkPhone = ko.observable("")
            self.workPhoneExt = ko.observable("")
            self.buyerHomePhone = ko.observable("")
            self.homePhoneExt = ko.observable("")
            self.buyerAddress = ko.observable("")
            self.buyerZipCode = ko.observable("")
            self.buyerCity = ko.observable("")
            self.buyerState = ko.observable("")
            self.buyerEmail = ko.observable("")
            self.coBuyerFirstName = ko.observable("")
            self.cobuyerLastName = ko.observable("")

        }

        var vm = {
            principalsBuyers: principalsBuyers
        }

        return vm;
    });
