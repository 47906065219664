//TODO: Inject dependencies

define('viewmodels/orderModule/chicagoTitleOrder',['plugins/router', 'durandal/app', 'jquery', 'knockout', 'validate', 'iCheck', 'config', 'knockout.validation', 'Q', 'plugins/http', 'global/session', 'customerInformationModel', 'chicagoTitleOrderModels', 'commonOrderModel', 'commonDialogModel', 'utlities', 'alertNotification'],
    function (router, app, $, ko, validate, iCheck, config, validation, Q, https, session, CustomerInformation, chicagoTitleOrderModels, commonOrderModel, commonDialog, utlities, alert) {
        // Internal properties and functions
        var customerInformationModel = new CustomerInformation();
        var submitOrderModel = new commonOrderModel.SubmitOrder();

        var orderInformationModel = new chicagoTitleOrderModels.OrderInformation();
        var referralInformationModel = new chicagoTitleOrderModels.ReferralInformation();
        var typeOfPolicyModel = new chicagoTitleOrderModels.TypeOfPolicy();
        var propertyInformationModel = new chicagoTitleOrderModels.PropertyInformation();

        // add remaining models
        var commonDialogModel = new commonDialog("orderSubmitDialog");
        var orderSubmitted = ko.observable(false);
        var orderTitle = ko.observable("Chicago Title Order");

        var currentView = ko.observable('');
        var currentOrderModels = ko.observableArray([]);
        var currentViewIndex = ko.observable(-1);
        var currentOrderModel = ko.observable();
        var selectedOrderType = ko.observable(0);
        var selectedOrderTypeName = ko.observable('');
        var userProfile = ko.observableArray();
        var propertyInfoPopulateFromSession = ko.observable(false);

        var recentSearches = ko.observable(undefined);
        var recentSearchSelectedAPN = ko.observable("");
        var propertyInfoPopulateFromRecentSearch = ko.observable(false);

        propertyInformationModel.propertyZip.subscribe(function (Zip) {
            if (Zip != undefined && Zip != '' && Zip.length == 5) {
                return utlities.getZipInfo(Zip).done(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.StateAbbr != null || data.City != null) {
                            propertyInformationModel.propertyCity(data.City);
                            propertyInformationModel.propertyState(data.StateAbbr);
                            if (propertyInfoPopulateFromSession() === true && session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null && session.orderForPropertyAddress.city() != undefined && session.orderForPropertyAddress.city() != null && session.orderForPropertyAddress.city() != "") {
                                propertyInformationModel.propertyCity(session.orderForPropertyAddress.city());
                                propertyInfoPopulateFromSession(false);
                            }
                            else if (propertyInfoPopulateFromRecentSearch() === true) {
                                propertyInfoPopulateFromRecentSearch(false);
                            }
                            else {
                            }
                        }
                        else {
                            //session.isAjaxPost(false);
                            alert.error("Invalid Zip.");
                            propertyInformationModel.propertyZip('');
                            propertyInformationModel.propertyCity('');
                            propertyInformationModel.propertyState('');
                            propertyInfoPopulateFromSession(false);
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                });
            }
        });

        currentOrderModel.subscribe(function (model) {
            vm.validationErrors = ko.validation.group([model]).extend({
                notify: 'always'
            });
            currentView(model.viewName);
            session.handleFocusOnTabChange(model.ModelId, model.viewName);
            selectedOrderTypeName(model.title);
        });

        currentViewIndex.subscribe(function (index) {
            currentOrderModel(currentOrderModels()[index]);
        });


        // Reveal the bindable properties and functions
        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            compositionComplete: compositionComplete,
            goBack: goBack,
            title: 'Chicago Title Order',
            orderTitle: orderTitle,
            currentView: currentView,
            currentOrderModels: currentOrderModels,
            currentViewIndex: currentViewIndex,
            currentOrderModel: currentOrderModel,
            selectedOrderTypeName: selectedOrderTypeName,
            moveNext: moveNext,
            movePrevious: movePrevious,
            submitOrder: submitOrder,
            navigateButtonClick: navigateButtonClick,
            userProfile: userProfile,

            customerInformationModel: customerInformationModel,
            orderInformationModel: orderInformationModel,
            referralInformationModel: referralInformationModel,
            typeOfPolicyModel: typeOfPolicyModel,
            propertyInformationModel: propertyInformationModel,
            commonDialogModel: commonDialogModel,
            submitOrderModel: submitOrderModel,

            getUSPhoneFormat: utlities.getUSPhoneFormat,
            getUSPhoneFormatWithExtn: utlities.getUSPhoneFormatWithExtn,
            session: session,
            recentSearches: recentSearches,
            recentResult_click: recentResult_click,
            recentSearchSelectedAPN: recentSearchSelectedAPN,
            clearSelectedProperty: clearSelectedProperty
        };

        return vm;

        function activate(orderType) {
            recentSearches(session.getRecentSearchesFromLocalStorage());
            orderSubmitted(false);
            InitView(orderType);
            currentViewIndex(0);
            session.isBusy(false);

            return getCustomerInfo(session.userId, session.defaultOpertionId());


        }



        function compositionComplete(a, b, c) {
            //setTimeout(function () {
            //    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            //        console.log("tab changed");
            //        handleFocusOnTabChange();
            //    });
            //}, 20);

            //$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            //    //console.log(e);
            //    //console.log(e.target.attributes.href.value);
            //    ////target.attributes[4].value
            //    var target = e.target.attributes.href.value;
            //    //$('#' + target + ' input')[0].focus();
            //    $('input', '.viewSection').eq(0).focus();
            //})
            //    $('input', '.viewSection').eq(0).focus();

            session.handleFocusOnTabChange(customerInformationModel.ModelId, customerInformationModel.viewName);

            if (session.orderForPropertyAddress != undefined && session.orderForPropertyAddress != null) {
                propertyInfoPopulateFromSession(true);
                //propertyInformationModel.presentOwner(session.orderForPropertyAddress.owner());
                propertyInformationModel.propertyAddress(session.orderForPropertyAddress.address());
                propertyInformationModel.propertyZip(session.orderForPropertyAddress.zip())
            }
        }

        function InitView(orderType) {
            selectedOrderType(parseInt(orderType));
            setupOrder(selectedOrderType());
        }

        function canDeactivate() {
            if (!session.hasLoggedOut() && !orderSubmitted()) {
                var title = 'Cancel Order?';
                var msg = 'Navigate away and cancel the order? ';
                session.isLogoutVisible(false);
                return app.showMessage(msg, title, ['Yes', 'No '])
                    .then(function (selectedOption) {
                        if (selectedOption === 'Yes') {
                            // Call some cancel function
                            recentSearchSelectedAPN('');
                            session.clearOrderForPropertyAddress();
                            propertyInformationModel.reset();
                        }
                        session.isLogoutVisible(true);
                        return selectedOption;
                    });
            }
            else {
                recentSearchSelectedAPN('');
                session.clearOrderForPropertyAddress();
                propertyInformationModel.reset();
                return true;
            }
        };

        function recentResult_click(result) {
            recentSearchSelectedAPN(result.APN);
            propertyInfoPopulateFromSession(false);
            propertyInformationModel.propertyAddress(result.Address);
            propertyInformationModel.propertyCity(result.City);

            if (propertyInformationModel.propertyZip() != result.ZIP.slice(0, 5)) {
                propertyInfoPopulateFromRecentSearch(true);
                propertyInformationModel.propertyZip(result.ZIP.slice(0, 5));
            }
            else
                propertyInfoPopulateFromRecentSearch(false);
        }
        function clearSelectedProperty() {
            recentSearchSelectedAPN('');
            propertyInformationModel.resetPropertyAddress();
        }

        function moveNext() {
            // commented for development, this need to be removed.
            vm.validationErrors = ko.validation.group(currentOrderModels()[currentViewIndex()]);
            if (vm.validationErrors().length > 0) {
                vm.validationErrors.showAllMessages();
                return;
            }
            currentViewIndex(currentViewIndex() + 1);
            // handleFocusOnTabChange();
            if (currentViewIndex() == currentOrderModels().length - 1)
                buildPreviewModel();
        }

        function movePrevious() {
            currentViewIndex(currentViewIndex() - 1);
            //handleFocusOnTabChange();
        }

        function navigateButtonClick(item, event) {
            if (item.index > currentViewIndex()) {

                for (var i = 0; i < item.index; i++) {
                    // commented for development, this need to be removed.
                    vm.validationErrors = ko.validation.group(currentOrderModels()[i]);
                    if (vm.validationErrors().length > 0) {
                        vm.validationErrors.showAllMessages();
                        return;
                    }
                }
            }
            currentViewIndex(item.index);
            if (currentViewIndex() == currentOrderModels().length - 1)
                buildPreviewModel();
        }

        function setupOrder(orderType) {
            currentOrderModels([]);
            currentOrderModels.push(customerInformationModel);
            currentOrderModels.push(orderInformationModel);
            currentOrderModels.push(referralInformationModel);
            currentOrderModels.push(typeOfPolicyModel);
            currentOrderModels.push(propertyInformationModel);
            currentOrderModels.push(submitOrderModel);
            resetModels();
        }

        function resetModels() {
            for (var i = 0; i < currentOrderModels().length; i++) {
                currentOrderModels()[i].index = i;
                currentOrderModels()[i].reset();
            }
        }

        function buildPreviewModel() {
            for (var i = 0; i < currentOrderModels().length; i++) {
                if (currentOrderModels()[i].GetModelData) {
                    currentOrderModels()[i].GetModelData();
                }
            }
        }

        function submitOrder() {
            session.isBusy(true);
            var url = config.submitChicagoTitleOrderUrl;
            var request = {

                //Order Model Information -- Required
                "BrandId": session.brandId,
                "OrderId": "-1",
                "UserId": session.userId,
                "ServiceId": "65",
                "OperationId": session.defaultOpertionId(),
                "CreateDate": new Date(),
                "PropertyAddress": propertyInformationModel.propertyAddress,
                "PropertyCity": propertyInformationModel.propertyCity,
                "PropertyState": propertyInformationModel.propertyState,
                "PropertyZip": propertyInformationModel.propertyZip,
                //Order Model Information -- Fill If Present
                "BackendSystem": "",
                "EscrowOfficerId": "",
                "TitleOfficerId": "",
                "IsBackendOrder": "",
                "PaymentId": "",
                // Customer Information
                "SalesRep": customerInformationModel.salesRep,
                "UserFullName": customerInformationModel.fullName,
                "UserEmail": customerInformationModel.email,
                "UserCompanyName": customerInformationModel.company,
                "UserAddress":
                {
                    "Address1": customerInformationModel.address(),
                    "Address2": "",
                    "City": "",
                    "County": "",
                    "State": "",
                    "Zip": "",
                    "Zip4": ""
                },

                "UserPhone":
                {
                    "PhoneNumber": customerInformationModel.phon(),
                    "PhoneType": "Business",
                    "Extension": customerInformationModel.ext()
                },

                "UserFax":
                {
                    "PhoneNumber": customerInformationModel.fax(),
                    "PhoneType": "Fax",
                    "Extension": ""
                },

                //Order Information
                "EscrowNumber": orderInformationModel.escrowNumber,
                "OrderNumber": orderInformationModel.orderNumber,

                //Referral Information
                "ReferralType": referralInformationModel.referralType,
                "ListingOffice": referralInformationModel.listingOffice,
                "ListingAgentName": referralInformationModel.listingOfficeAgentName,
                "SellingOffice": referralInformationModel.sellingOffice,
                "SellingAgentName": referralInformationModel.sellingOfficeAgentName,

                //Policy Type Information
                "PolicyType": typeOfPolicyModel.policyType,
                "PolicyAmount": typeOfPolicyModel.policyAmount().replace(',', ''),
                "WithEndorsementNumber": typeOfPolicyModel.withEndorsementNumber,
                "PolicyEndorsement": typeOfPolicyModel.policyEndorsement,
                "PolicyEndorsementAmount": typeOfPolicyModel.endorsementAmount().replace(',', ''),

                //Property Information
                "PropertyDescription": propertyInformationModel.propertyDescription,
                "PropertyType": propertyInformationModel.typeOfProperty,
                "PresentOwner": propertyInformationModel.presentOwner,
                "NewOwner": propertyInformationModel.newOwner,
                "LenderNameAddressAttn": propertyInformationModel.lenderInfo,
                "SpecialInstructions": propertyInformationModel.specialInstructions
            };

            https.post(url, request)
                .then(function (data) {
                    if (data.HasErrors == false) {
                        orderSubmitted(true);
                        commonDialogModel.IsOrderDialog(true);
                        commonDialogModel.orderId(data.OrderId);
                        if (data.IntendedRecpients)
                            commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                        commonDialogModel.show("Order Submission Status", "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                    }
                    else {
                        orderSubmitted(false);
                        session.isBusy(false);
                        var msgSuccess = "";
                        var msgError = "";

                        for (var i = 0; i < data.Messages.length; i++) {
                            if (data.Messages[i].Message != "" && data.Messages[i].Level == 500) {
                                //alert.error(data.Messages[i].ExceptionMessage, orderTitle());
                                //alert.error('Internal Error. Please contact Administrator.');
                                if (msgError.trim().indexOf(data.Messages[i].Message) == -1) {
                                    msgError = msgError + data.Messages[i].Message + "\n";
                                }
                            }
                            else {
                                msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                            }
                        }
                        //alert.error(msgSuccess, orderTitle());
                        //alert.error(msgError, orderTitle());
                        if (msgSuccess.length > 0) {
                            orderSubmitted(true);
                            commonDialogModel.IsOrderDialog(true);
                            commonDialogModel.orderId(data.OrderId);
                            msgError = "<div style='color:red;font-weight:bold;white-space:pre-wrap;'>" + msgError + "</div> \n";
                            commonDialogModel.messagehtml(msgError);
                            if (data.IntendedRecpients)
                                commonDialogModel.IntendedRecepients(data.IntendedRecpients);
                            commonDialogModel.show("Order Submission Status",  "Should you have any questions, please contact your " + session.brandName() + " Sales Executive.");
                        }
                        else if (msgError.length > 0) {
                            alert.error(msgError, orderTitle());
                        }

                    }
                })
                .fail(function (data) {
                    var error = JSON.parse(data.responseText)
                    console.log(error);
                    session.isBusy(false);
                });
        }

        function goBack(complete) {
            router.navigateBack();
        }



        function getUserInfoByEmail(email) {
            var url = config.getUserInfoByEmailUrl;
            return https.get(url, {
                email: email,
                format: 'json'
            });
        }

        function getCustomerInfo(userId, opertionId) {
            return utlities.getOperationUserInfo(userId, opertionId).done(function (data) {
                var currdate = new Date();
                currdate = (currdate.getMonth() + 1) + '/' + currdate.getDate() + '/' + currdate.getFullYear();
                customerInformationModel.orderDate(currdate);
                utlities.getManagerFullName(data.OperationId, data.UserId).done(function (result) {
                    customerInformationModel.salesRep(result);
                });
                customerInformationModel.fullName(data.FirstName + String.fromCharCode(160) + data.LastName);
                customerInformationModel.email(data.Email);
                customerInformationModel.company(data.Company);
                customerInformationModel.address(data.StreetAddress1 + String.fromCharCode(160) + data.StreetAddress2 + String.fromCharCode(160) + data.City + ',' + String.fromCharCode(160) + data.State + String.fromCharCode(160) + data.Zip);
                customerInformationModel.phon(data.PhoneWork);
                customerInformationModel.ext(data.PhoneWorkExt);
                customerInformationModel.fax(data.PhoneFax);
            });
        }

        function getZipInfo(zip) {
            var url = config.getZipInfoUrl;
            return https.get(url, {
                zipCode: zip,
                format: 'json'
            });
        }
    });
