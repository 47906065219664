define('orderForPropertyAddressModel',["knockout"],
    function (ko) {
        function placeAnOrderForProperty() {
            var self = this;
            self.owner = ko.observable("");
            self.address = ko.observable("");
            self.zip = ko.observable("");
            self.city = ko.observable("");
            self.county = ko.observable("");
            self.fips = ko.observable("");
            self.state = ko.observable("");
            self.apn = ko.observable("");
            self.addressInURL = ko.observable(false);

            self.updateFromURL = function (data) {
                if (data) {
                    //if (data.owner != undefined && data.owner != null)
                    //    self.owner(data.owner);
                    if (data.Property.Address != undefined && data.Property.Address != null)
                        self.address(data.Property.Address);
                    if (data.Property.ZIP != undefined && data.Property.ZIP != null)
                        self.zip(data.Property.ZIP.slice(0, 5));
                    if (data.Property.City != undefined && data.Property.City != null)
                        self.city(data.Property.City);
                    if (data.Property.County != undefined && data.Property.County != null)
                        self.county(data.Property.County);
                    //if (data.fips != undefined && data.fips != null)
                    //    self.fips(data.fips);
                    if (data.Property.State != undefined && data.Property.State != null)
                        self.state(data.Property.State);
                    if (data.Property.APN != undefined && data.Property.APN != null)
                        self.apn(data.Property.APN);
                    self.addressInURL(true);
                }
            }

            self.updateFromSearch = function (data) {
                if (data) {
                    if (data.Owner != undefined && data.Owner != null)
                        self.owner(data.Owner);
                    if (data.Address != undefined && data.Address != null)
                        self.address(data.Address);
                    if (data.ZIP != undefined && data.ZIP != null)
                        self.zip(data.ZIP.slice(0, 5));
                    if (data.City != undefined && data.City != null)
                        self.city(data.City);
                    if (data.County != undefined && data.County != null)
                        self.county(data.County);
                    if (data.FIPS != undefined && data.FIPS != null)
                        self.fips(data.FIPS);
                    if (data.State != undefined && data.State != null)
                        self.state(data.State);
                    if (data.APN != undefined && data.APN != null)
                        self.apn(data.APN);
                    self.addressInURL(false);
                }
            }

            self.reset = function () {
                self.owner("");
                self.address("");
                self.zip("");
                self.city("");
                self.county("");
                self.fips("");
                self.state("");
                self.apn("");
                self.addressInURL(false);
            }
        }

        var vm = {
            placeAnOrderForProperty: placeAnOrderForProperty
        }
        return vm;
    });
