define('viewmodels/externalSiteRedirect/ngPremLeadsSlsRedirect',["knockout", 'plugins/http', 'global/session', 'config', 'utlities', 'plugins/router', 'durandal/app'],
    function (ko, https, session, config, utlities, router, app) {
        var vm = {
            session: session,
            canActivate: function () {
                getNgPremLeadSlsURL().done(function (data) {
                    //if (session.slsEnabled()) {
                    //    if (data && !data.HasErrors && (data.LoginURL !== undefined || data.LoginURL !== null) && data.LoginURL.length > 0) {
                    //        session.CheckPopUpBlock(data.LoginURL); { }
                    //    }
                    //    else {
                    //        app.showMessage('Required URL is not configured. Please contact Administrator.', 'URL not available', ['OK']);
                    //    }
                    //} else {
                    if (data && !data.HasErrors && (data.LoginUrl !== undefined || data.LoginUrl !== null) && data.LoginUrl.length > 0) {
                        session.CheckPopUpBlock(data.LoginUrl);
                    } else {
                        app.showMessage('Required URL is not configured. Please contact Administrator.', 'URL not available', ['OK']);
                    }
                    //}
                });
                return false;
            }
        };

        function getNgPremLeadSlsURL() {
            
            //if (session.slsEnabled()) {
            //    return https.get(config.getAccessTitleProAccountSls, {
            //        operationId: session.defaultOpertionId(),
            //        brandId: session.brandId,
            //        format: 'json'
            //    });
            //} else {
            return https.get(config.getAccessPremLeadSlsInfo, {
                operationId: session.defaultOpertionId(),
                brandId: session.brandId,
                format: 'json'
            });
            //}

        }

        return vm;
    });
