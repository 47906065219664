define('viewmodels/userModule/changePassword',['durandal/app', 'knockout', 'changePasswordModel', 'global/session', 'alertNotification', 'shell', 'services/security', 'plugins/http', 'config', 'passwordMeter'],
    function (app, ko, model, session, alerter, shell, security, https, config, passwordMeter)
    {
        var passwordModel = new model.PasswordModel();

        var passwordChange = ko.observable(false);
        var enableRoute = ko.observable(false);
        var pluginFocus = ko.observable(false);
        //Do not remove this as this is used for shared View _changePassword.html for 1st Change Password
        //Fix For Bug : 79144
        var isFirstTimeLogin = ko.observable(true);

        pluginFocus.subscribe(function (newValue)
        {
            if (newValue == true)
            {
                setPlugins();
            }
        });

        function activate()
        {
            //add any startup statement
            getUserId();
        }

        function canDeactivate()
        {
            return enableRoute();
        }

        function getUserId()
        {
            https.get()
        }

        function cancelPwdChange()
        {
            passwordModel.reset();
            resetPasswordMeter();
        }

        function resetPasswordMeter()
        {
            $('#newPassword').keyup();
            $('#newPassword').trigger('blur');
        }

        function cancelPwdChangeGoToLogin()
        {
            passwordModel.reset();
            resetPasswordMeter();
            enableRoute(true);
            logoutCurrentSession();
        }

        function logoutCurrentSession()
        {
            session.hasLoggedOut(true);
            shell.logout();
            $("#mdLoading").modal('hide');
            session.isAjaxPost(false);
            $("div.modal-backdrop.fade.out").removeClass("out");
            $("div.modal-backdrop.fade").addClass("in");
        }

        function updatePassword()
        {

            if (!passwordModel.isValid())
            {
                passwordModel.errors.showAllMessages();
                return;
            }
            ChangePassword();
        }

        function ChangePassword()
        {
            var request = {
                "OldPassword": passwordModel.currentPassword(),
                "NewPassword": passwordModel.newPassword(),
                "ConfirmPassword": passwordModel.confirmPassword(),
                "UserId": session.userId
            };
            security.changePassword(ko.toJSON(request))
                .then(function (data)
                {
                    if (data != undefined && data != null && data.HasErrors == false)
                    {
                        alerter.success('Saved Successfully. Relogin with new password');
                    }
                    else
                    {
                        var msgSuccess = "";
                        var msgError = "";

                        for (var i = 0; i < data.Messages.length; i++)
                        {
                            if (data.Messages[i].Message != "" && data.Messages[i].Level == 500)
                            {
                                if (msgError.trim().indexOf(data.Messages[i].Message) == -1)
                                {
                                    msgError = msgError + data.Messages[i].Message + "\n";
                                }
                            }
                            else
                            {
                                msgSuccess = msgSuccess + data.Messages[i].Message + "\n";
                            }
                        }
                        alerter.warning('Saved Successfully.Relogin with new password' + ' But, ' + msgError);
                    }
                    passwordModel.reset();
                    console.log('Relogin user with new password');
                    enableRoute(true);
                    setTimeout(function () { logoutCurrentSession(); }, 8000);
                })
                .fail(function (data)
                {
                    var message = toErrorString(data);
                    alerter.error(message);
                    passwordModel.reset();
                    //logoutCurrentSession();
                });
        }

        function toErrorString(data)
        {
            var errors, items;
            if (data.responseJSON.ModelState)
            {

                for (var key in data.responseJSON.ModelState)
                {
                    items = data.responseJSON.ModelState[key];
                    if (items.length)
                    {
                        errors = items.join(",");
                    }
                }
            }

            if (errors)
                return errors;

            if (data && data.responseJSON && data.responseJSON.Message)
            {
                errors = data.responseJSON.Message;
                return errors;
            }

            if (errors === undefined)
            {
                errors = "An error occurred when trying to process your request";
            }

            return errors;
        }

        function compositionComplete()
        {
            console.log('compositionComplete  called');
            session.validateInput();
            setPlugins();

            $('#newPassword').password({
                shortPass: 'The password is too short',
                badPass: 'Weak; Try combining upper & lower case letters, numbers & special characters',
                goodPass: 'Medium; Try combining upper & lower case letters, numbers & special characters',
                strongPass: 'Strong password',
                containsUsername: 'The password contains the username',
                enterPass: 'Type your password',
                showPercent: false,
                showText: true, // shows the text tips
                animate: true, // whether or not to animate the progress bar on input blur/focus
                animateSpeed: 'fast', // the above animation speed
                username: false, // select the username field (selector or jQuery instance) for better password checks
                usernamePartialMatch: true, // whether to check for username partials
                minimumLength: 8 // minimum password length (below this threshold, the score is 0)
            });
        }

        function bindingComplete()
        {
            console.log('bindingComplete called');
            setPlugins();
        }
        function setPlugins()
        {
            $('[data-toggle="tooltip"]').tooltip();
        }
        var vm = {
            activate: activate,
            canDeactivate: canDeactivate,
            passwordModel: passwordModel,
            updatePassword: updatePassword,
            cancelPwdChange: cancelPwdChange,
            passwordChange: passwordChange,
            pluginFocus: pluginFocus,
            enableTooltip: setPlugins,
            compositionComplete: compositionComplete,
            bindingComplete: bindingComplete,
            isFirstTimeLogin: isFirstTimeLogin,
            cancelPwdChangeGoToLogin: cancelPwdChangeGoToLogin,
        };

        return vm;

    });
